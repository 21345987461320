// Components
import { CopyBlock, Icon, Title } from 'marvel-components'

// Misc
import { useState } from 'react'
import styled from 'styled-components'

interface Props {
  title: string
  content: string | JSX.Element
  id: string
}

const AccordionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid ${props => props.theme.colors.borderColor};
`

const ContentWrapper = styled.div<{ isVisible: boolean }>`
  display: ${({ isVisible }) => (isVisible ? 'flex' : 'none')};
  padding: 0 0 1.5rem 0;
`

const AccordionTitle = styled.button`
  appearance: none;
  border: none;
  background: none;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0;
  cursor: pointer;
`

const StyledTitle = styled(Title)`
  padding: 1.25rem 0;
`

const StyledIcon = styled(Icon)`
  padding-left: 0.5rem;
`

export const Accordion = ({ title, content, id }: Props) => {
  const [isOpen, setIsOpen] = useState(false)
  return (
    <AccordionWrapper>
      <AccordionTitle
        onClick={() => setIsOpen(!isOpen)}
        aria-controls={`${id}-content`}
        aria-expanded={isOpen}
        id={`${id}-title`}
      >
        <StyledTitle
          isBold
          isCentralised={false}
          isCapitalised={false}
          size='medium-large'
        >
          {title}
        </StyledTitle>
        <StyledIcon icon={isOpen ? 'chevron-up' : 'chevron-down'} />
      </AccordionTitle>
      <ContentWrapper
        isVisible={isOpen}
        id={`${id}-content`}
        aria-labelledby={`${id}-title`}
        role={isOpen ? 'alert' : undefined}
      >
        {typeof content === 'string' ? (
          <CopyBlock>{content}</CopyBlock>
        ) : (
          content
        )}
      </ContentWrapper>
    </AccordionWrapper>
  )
}
