import { createSlice } from '@reduxjs/toolkit'

// Utils
import ticketingApi from 'modules/ticketing/services/ticketingApi'
import { createAPIThunk } from 'modules/ticketing/shared/actions'

// Types
import { AppState } from 'modules/types'

// Actions
type FetchPaymentMethodsResponse = {
  paymentMethods: any
}
type PaymentPlatform = 'sw' | 'lv' | 'ticketing'

export const fetchPaymentMethods = createAPIThunk<
  FetchPaymentMethodsResponse,
  PaymentPlatform
>('marvel/ticketing/FETCH_PAYMENT_METHODS', (platform: PaymentPlatform) =>
  ticketingApi.fetchAll(`/payment-methods/`, { params: { platform } })
)

// Reducer
type PaymentState = {
  status: 'pending' | 'loading' | 'finished'
  paymentMethods?: Object
}

const initialState = { status: 'pending' } as PaymentState
const payment = createSlice({
  name: 'auth',
  initialState: initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(fetchPaymentMethods.pending, state => {
      state.status = 'loading'
      state.paymentMethods = undefined
    })
    builder.addCase(fetchPaymentMethods.fulfilled, (state, action) => {
      if (action.payload) {
        state.status = 'finished'
        state.paymentMethods = action.payload.data
      }
    })
  }
})

// Actions
export const paymentActions = {
  fetchPaymentMethods,
  ...payment.actions
}

// Selectors
export const paymentSelectors = {
  selectPaymentMethods: (state: AppState) =>
    state.ticketing.payment.paymentMethods,
  selectPaymentMethodsLoaded: (state: AppState) =>
    state.ticketing.payment.status === 'finished'
}

export default payment.reducer
