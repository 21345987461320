// Utils
import moment, { Duration } from 'moment'
import React, { useState } from 'react'
import styled from 'styled-components'
import { fadeIn, fadeOut } from 'utils'
import { useInterval } from 'utils/useInterval'

const SlideshowWrapper = styled.div`
  position: relative;
`

const SlideshowImage = styled.img<{ visible: boolean; fadeDuration: number }>`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  visibility: ${props => (props.visible ? 'visible' : 'hidden')};
  animation: ${props => (props.visible ? fadeIn : fadeOut)}
    ${props => props.fadeDuration}s linear;
  transition: visibility ${props => props.fadeDuration}s linear;
`

type Props = {
  interval: Duration
  sources: string[]
  fadeDuration?: Duration
}

const Slideshow: React.FC<Props> = ({
  interval,
  sources,
  fadeDuration = moment.duration().add(0.6, 'seconds'),
  ...props
}) => {
  const [currentIndex, setCurrentIndex] = useState<number>(0)

  useInterval(
    () => {
      if (currentIndex + 1 >= sources.length) {
        setCurrentIndex(0)
      } else {
        setCurrentIndex(currentIndex + 1)
      }
    },
    interval,
    [setCurrentIndex, currentIndex, sources, interval]
  )

  if (sources.length === 0) {
    return null
  }

  return (
    <SlideshowWrapper {...props}>
      {sources.map((src, i) => (
        <SlideshowImage
          key={i}
          src={src}
          visible={i === currentIndex}
          fadeDuration={fadeDuration.asSeconds()}
        />
      ))}
    </SlideshowWrapper>
  )
}

export { Slideshow }
