import React from 'react'

// Components
import { Title, ListItem, List, WithLoader } from 'marvel-components'

// Selectors
import { siteSelectors } from 'modules/hospitality/site'

// Misc
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import { media } from 'utils'
import { useHistory, useRouteMatch } from 'react-router-dom'

const SitesListContainerWrapper = styled.div`
  box-sizing: border-box;
  padding: 1.5rem 0 2.5rem 0;
  ${media.tablet} {
    padding: 1rem 0 1.5rem 0;
    margin-top: 0;
  }
`

const SiteListDetails = styled.div`
  padding: 0 2%;
  ${media.tablet} {
    padding: 0 3%;
  }
  ${media.phone} {
    padding: 0 5%;
  }
`

const SitesListWrapper = WithLoader(styled.div`
  margin: 1rem 0;
`)

const SiteListContainer: React.FC = () => {
  // Selectors
  const sites = useSelector(siteSelectors.selectAll)
  const isLoading = useSelector(siteSelectors.selectIsLoading)

  // Routing
  const history = useHistory()
  const { url } = useRouteMatch()

  const handleSiteClick = (siteId: EntityId) => {
    history.push(`${url}/${siteId}/occasion`)
  }

  return (
    <SitesListContainerWrapper>
      <SiteListDetails>
        <Title
          isCapitalised={false}
          isCentralised={false}
          isBold={true}
          size="medium"
        >
          Please select your venue:
        </Title>
      </SiteListDetails>
      <SitesListWrapper isLoading={isLoading}>
        <List>
          {sites.map((site) => {
            return (
              <ListItem
                key={site.id}
                id={site.id}
                title={site.name}
                backgroundColor={'#111'}
                textColor={'#fff'}
                onClick={handleSiteClick}
                light={false}
              />
            )
          })}
        </List>
      </SitesListWrapper>
    </SitesListContainerWrapper>
  )
}

export { SiteListContainer }
