import { useAppDispatch } from 'app/reduxStore'
import { ModalPayload } from 'modules/overlay'
import { openModal } from 'modules/overlay/actions'
import moment from 'moment'
import { useEffect } from 'react'
import { useIsIdle } from './useIsIdle'

const SHOW_INACTIVITY_MODAL_AFTER_SECONDS: number = 60 * 2

type Args = {
  payload: ModalPayload
  openAfterSeconds?: number
  shouldOpen?: boolean
}

const useInactivityModal = ({
  payload,
  openAfterSeconds = SHOW_INACTIVITY_MODAL_AFTER_SECONDS,
  shouldOpen = true
}: Args) => {
  const dispatch = useAppDispatch()
  const isIdle = useIsIdle(moment.duration(openAfterSeconds, 'seconds'))

  useEffect(() => {
    if (isIdle && shouldOpen) {
      dispatch(
        openModal({
          ...payload
        })
      )
    }
  }, [isIdle])
}

export { useInactivityModal }
