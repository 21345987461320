import { createEntityAdapter, createSelector } from '@reduxjs/toolkit'
import { AppState } from 'modules'
import { Venue } from '../shared/types'

export const venueAdapter = createEntityAdapter<Venue>()

const { selectAll, selectEntities, selectById } = venueAdapter.getSelectors()

export const selectVenueState = (state: AppState) => state.selfServe.venue

export const selectVenueEntities = createSelector(
  selectVenueState,
  selectEntities
)

export const selectVenues = createSelector(selectVenueState, selectAll)

export const selectVenue = selectById

export const selectIsVenueLoading = (state: AppState) =>
  state.selfServe.venue.status === 'pending' ||
  state.selfServe.venue.status === 'idle'
