import { GeneralAdmission } from './generalAdmission'

export const Tours = {
  ...GeneralAdmission,
  colors: {
    bestAvailablePerformanceColor: '#FC9232'
  },
  orderSuccess: {
    showHospitality: true,
    showCecilBeatonBar: true
  }
}
