import backgroundAsset from './assets/oz_sidebar.jpg'
const Oz = {
  colors: {
    mainColor: '#ffffff',
    mainColorComplement: '#111111',
    performanceHoverColor: '#FFED00',
    bestAvailableBannerColor: '#FFED00',
    bestAvailableBannerColorComplement: '#111111',
    numberFilterSelectorColor: '#ffffff',
    numberFilterSelectorCopyColor: '#111111',
    priceSliderHandleColor: '#000444',
    mainBackgroundColor: '#000444',
    lwBrandColor: '#ffffff',
    lwBrandColorComplement: '#111111'
  },
  themeBackgroundAsset: backgroundAsset,
  themeBackgroundColour: '#000444',
  orderSuccess: {
    showHospitality: true,
    showCecilBeatonBar: true
  }
}

export { Oz }
