// Types
import { NavItem } from 'shared-types'

export const smartWaiterRoutes: NavItem[] = [
  {
    match: '/smart-waiter/venue/:venueId/area/:salesAreaId/',
    path: '/smart-waiter/venue/:venueId/area/:salesAreaId/service',
    name: 'Pre-Show / Interval'
  },
  {
    match:
      '/smart-waiter/venue/:venueId/area/:salesAreaId/service/:serviceId/menu/:menuId/section',
    path:
      '/smart-waiter/venue/:venueId/area/:salesAreaId/service/:serviceId/menu/:menuId/section',
    name: 'Menu'
  }
]
