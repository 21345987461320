// Components
import { MainMenu, Title } from 'marvel-components'

// Actions
import { contentActions } from 'modules/self-serve/content/contentSlice'

// Selectors
import { selectNavigationItems } from 'modules/self-serve/content/selectors'

// Misc
import { useAppDispatch } from 'app/reduxStore'
import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import { media } from 'utils'

const MainMenuWrapper = styled.div`
  padding: 1rem 1.5rem 0 1.5rem;
  ${media.tablet} {
    padding: 2rem 1rem 0 1rem;
  }
`

const StyledMainMenu = styled(MainMenu)`
  padding: 1rem 0 0 0;
  border-bottom: 1px solid ${props => props.theme.colors.borderColor};
  margin-bottom: 0;
`

const MainMenuContainer = () => {
  const navItems = useSelector(selectNavigationItems)
  const dispatch = useAppDispatch()

  useEffect(() => {
    dispatch(contentActions.fetchNavigationItems())
  }, [dispatch])

  return (
    <MainMenuWrapper>
      <Title
        isCapitalised={false}
        isCentralised={false}
        isBold={true}
        size='large'
      >
        Where to next?
      </Title>
      <StyledMainMenu navItems={navItems} />
    </MainMenuWrapper>
  )
}

export { MainMenuContainer }
