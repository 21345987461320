import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { BannerType } from 'shared-types'
import { getBannerText, getBannerTitle, getBannerType } from './selectors'

type Payload = {
  title: string
  text: string
  bannerType: BannerType
}

type State = {
  title?: string
  text?: string
  bannerType?: BannerType
}

const initialState: State = {}

const bannerSlice = createSlice({
  name: 'banner',
  initialState,
  reducers: {
    setBannerContent: (state, action: PayloadAction<Payload>) => {
      state.title = action.payload.title
      state.text = action.payload.text
      state.bannerType = action.payload.bannerType
    },
    clearBannerContent: state => {
      state.title = undefined
      state.text = undefined
      state.bannerType = undefined
    }
  }
})

export const bannerActions = {
  ...bannerSlice.actions
}

export const bannerSelectors = {
  getBannerTitle,
  getBannerText,
  getBannerType
}

export default bannerSlice.reducer
