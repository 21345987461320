// Misc
import { createSlice } from '@reduxjs/toolkit'
import moment from 'moment'

// Actions
import { fetchDeliveryOptions } from './actions'

// Selectors
import {
  deliveryOptionAdapter,
  selectDefaultDeliveryOptionId,
  selectDelieveryOptionLastFetchDate,
  selectDeliveryOption,
  selectDeliveryOptionDescription,
  selectDeliveryOptions,
  selectIsDeliveryOptionLoaded,
  selectIsDeliveryOptionLoading,
  selectDeliveryOptionStatus
} from './selectors'

type Status = 'idle' | 'pending' | 'fulfilled' | 'rejected'

type State = {
  status: Status
  lastFetch?: string
}

const initialState = deliveryOptionAdapter.getInitialState<State>({
  status: 'idle'
})

const deliveryOptionSlice = createSlice({
  name: 'delivery-option',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(fetchDeliveryOptions.pending, state => {
        state.status = 'pending'
      })
      .addCase(fetchDeliveryOptions.fulfilled, (state, action) => {
        deliveryOptionAdapter.setAll(state, action.payload.data.deliveryOptions)
        state.status = 'fulfilled'
        state.lastFetch = moment().utc().format()
      })
      .addCase(fetchDeliveryOptions.rejected, state => {
        state.status = 'rejected'
      })
  }
})

export const deliveryOptionActions = {
  fetchDeliveryOptions,
  ...deliveryOptionSlice.actions
}

export const deliveryOptionSelectors = {
  selectIsDeliveryOptionLoading,
  selectIsDeliveryOptionLoaded,
  selectDefaultDeliveryOptionId,
  selectDelieveryOptionLastFetchDate,
  selectDeliveryOption,
  selectDeliveryOptionDescription,
  selectDeliveryOptions,
  selectDeliveryOptionStatus
}

export default deliveryOptionSlice.reducer
