import React from 'react'
import styled from 'styled-components/macro'
import { Formik } from 'formik'
import * as Yup from 'yup'

import { loginValidator, passwordValidator } from '../validationSchemas'

// Components
import { Button, FormInput, FormLayout } from 'marvel-components'

export interface LoginFormValues {
  login: string
  password: string
}

interface Props {
  onSubmit: (values: LoginFormValues) => void
  onForgottenPassword: () => void
}

const FormWrapper = styled.div`
  display: flex;
  flex-direction: column;
`
const LoginWrapper = styled.div`
  flex: 1 0 auto;
`
const SubmitWrapper = styled.div`
  margin: 1rem 0;
`

const LoginForm: React.FC<Props> = ({ onSubmit, onForgottenPassword }) => {
  return (
    <Formik
      initialValues={{
        login: '',
        password: ''
      }}
      onSubmit={(values, { setSubmitting }) => {
        return onSubmit && onSubmit(values)
      }}
      validationSchema={Yup.object().shape({
        login: loginValidator,
        password: passwordValidator
      })}
    >
      {({ isSubmitting }) => (
        <FormLayout>
          <FormWrapper>
            <LoginWrapper>
              <FormInput
                name="login"
                type="text"
                placeholder="Email address / Username"
                autocomplete="username"
              />
              <FormInput
                name="password"
                type="password"
                placeholder="Password"
                autocomplete="password"
              />
            </LoginWrapper>
            <Button
              text="Forgot your password?"
              buttonType="link-button"
              onClick={onForgottenPassword}
              size="extra-small"
            />
            <SubmitWrapper>
              <Button
                type="submit"
                isBlock={true}
                text="Login"
                size="small"
                isLoading={isSubmitting}
              />
            </SubmitWrapper>
          </FormWrapper>
        </FormLayout>
      )}
    </Formik>
  )
}

export { LoginForm }
