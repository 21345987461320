import React from 'react'
import { MainMenuNavigationItem } from 'shared-types'
import styled from 'styled-components'
import { media } from 'utils'

const MenuContent = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  padding: 0 5% 5rem 5%;
  margin-bottom: 8rem;
`
const MenuButton = styled.a`
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-family: ${props => props.theme.font.button};
  font-size: 1.5rem;
  text-align: left;
  text-transform: uppercase;
  text-decoration: none;
  letter-spacing: 0.24rem;
  background: none;
  border: 2px solid #d7d7d7;
  border-radius: 5px;
  width: 100%;
  margin: 0 0 2rem 0;
  padding: 1rem 5%;
  box-sizing: border-box;

  ${media.tablet} {
    width: 100%;
    margin: 0 0 3rem 0;
    padding: 4rem 5%;
  }
`

const MenuButtonIconWrapper = styled.div`
  margin: 0 0 0 2rem;
`

const MenuButtonIcon = styled.img`
  display: block;
  width: 8rem;
`

type Props = {
  navItems: MainMenuNavigationItem[]
}

const MainMenu: React.FC<Props> = ({ navItems, ...props }) => {
  return (
    <MenuContent {...props}>
      {navItems.map(navItem => (
        <MenuButton key={navItem.id} href={navItem.link}>
          {navItem.title}
          <MenuButtonIconWrapper>
            <MenuButtonIcon src={navItem.image} />
          </MenuButtonIconWrapper>
        </MenuButton>
      ))}
    </MenuContent>
  )
}

export { MainMenu }
