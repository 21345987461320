import React from 'react'

// Components
import { BrandLogo, MainMenuNavButton } from 'marvel-components'

// Utils
import { usePlatform } from 'utils'

const BrandLogoContainer: React.FC = () => {
  const platform = usePlatform()

  return (
    <BrandLogo
      address={'Catherine Street, London WC2B 5JF'}
      venue={'Theatre Royal Drury Lane'}
      logoName={'trdl_logo.png'}
      backgroundColor={'#111'}
      lwLogoLink={
        platform === 'kiosk' ? '/hospitality' : 'https://thelane.co.uk/'
      }
      showLogoLink={'/hospitality'}
      renderNavButton={
        platform === 'kiosk' ? () => <MainMenuNavButton /> : undefined
      }
    />
  )
}

export { BrandLogoContainer }
