import { Terminal } from 'shared-types'
import selfServeApi from 'modules/self-serve/services/selfServeApi'
import { schema } from 'normalizr'
import { createAPIThunk } from 'modules/self-serve/shared/actions'

const terminalSchema = new schema.Entity('terminals')

type FetchTerminalResponse = {
  terminals: Record<number, Terminal>
}

export const fetchTerminals = createAPIThunk<FetchTerminalResponse>(
  'marvel/self-serve/FETCH_TERMINAL',
  () => selfServeApi.get('/self-serve/terminal/'),
  [terminalSchema]
)
