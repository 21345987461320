import React from 'react'

// Types
import { AppState } from 'modules/types'

// Modules
import { getArea, getFirstSeatWidth } from 'modules/seat-map/selectors'

// Components
import { SeatMapArea } from 'marvel-components'
import { SectorContainer } from './SectorContainer'

// Misc
import { useSelector } from 'react-redux'

type Props = {
  areaId: string
  performanceId: string
  toolTipTarget: any
}

const SEAT_SIZE = 10

const AreaContainerNM: React.FC<Props> = ({
  areaId,
  performanceId,
  toolTipTarget
}) => {
  // Selectors
  const area = useSelector((state: AppState) => getArea(state, areaId))
  const firstSeatWidth = useSelector((state: AppState) =>
    getFirstSeatWidth(state, areaId)
  )
  const relativeSale = SEAT_SIZE / firstSeatWidth

  if (!area) return null
  return (
    <SeatMapArea area={area} relativeSale={relativeSale}>
      {area.sectors.map((sectorId) => (
        <SectorContainer
          key={`sector-${sectorId}`}
          sectorId={sectorId}
          performanceId={performanceId}
          toolTipTarget={toolTipTarget}
        />
      ))}
    </SeatMapArea>
  )
}

export const AreaContainer = React.memo(AreaContainerNM)
