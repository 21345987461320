import { combineReducers } from 'redux'

// Types
import {
  SET_PRICE_PERFORMANCE_FILTER,
  SET_PERFORMANCE_FILTER,
  SetPricePerformanceAction,
  SetPerformanceFilterAction
} from './types'
import { PerformanceFilter } from 'shared-types'

import {
  ADD_SEAT,
  REMOVE_SEAT,
  SET_SEATS,
  TRIGGER_RESERVATION
} from '../basket/types'

const performanceFilters = (
  state: PerformanceFilter[] = ['all'],
  action: SetPerformanceFilterAction
) => {
  switch (action.type) {
    case SET_PERFORMANCE_FILTER:
      return action.payload.length > 0
        ? action.payload
        : (['all'] as PerformanceFilter[])
    default:
      return state
  }
}

const lowerPricePerformanceFilter = (
  state: number | null = null,
  action: SetPricePerformanceAction
) => {
  switch (action.type) {
    case SET_PRICE_PERFORMANCE_FILTER:
      return action.payload.lowerValue
    default:
      return state
  }
}

const upperPricePerformanceFilter = (
  state: number | null = null,
  action: SetPricePerformanceAction
) => {
  switch (action.type) {
    case SET_PRICE_PERFORMANCE_FILTER:
      return action.payload.upperValue
    default:
      return state
  }
}

const hasUserUpdatedBasket = (state = false, action: any) => {
  switch (action.type) {
    case ADD_SEAT:
    case REMOVE_SEAT:
    case SET_SEATS:
    case TRIGGER_RESERVATION:
      return true
    default:
      return state
  }
}

export default combineReducers({
  performanceFilters,
  lowerPricePerformanceFilter,
  upperPricePerformanceFilter,
  hasUserUpdatedBasket
})
