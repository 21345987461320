import styled from 'styled-components/macro'
import { darken } from 'utils'

const FormDivider = styled.div<{ isLine?: boolean }>`
  font-family: ${(props) => props.theme.font.copy};
  font-size: 0.75rem;
  text-transform: uppercase;
  color: ${(props) => darken(props.theme.colors.borderColor, 30)};
  margin: ${(props) => (props.isLine ? '2rem 0 1.5rem 0' : '1rem 0 1rem 0')};
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: 1;

  ::before,
  ::after {
    content: '';
    border: ${(props) => (props.isLine ? '1px solid' : 'none')};
    border-color: ${(props) => darken(props.theme.colors.borderColor, 20)};
    border-bottom: 0;
    flex: 1 0 2em;
  }
  ::before {
    border-right: 0;
    margin-right: 1em;
  }
  ::after {
    border-left: 0;
    margin-left: 1em;
  }
`

export { FormDivider }
