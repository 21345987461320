import { createSlice } from '@reduxjs/toolkit'
import { venueAdapter } from './selectors'
import { fetchVenues } from './actions'

type AdditionalState = {
  status: 'idle' | 'pending' | 'fulfilled' | 'rejected'
}

const initialState = venueAdapter.getInitialState<AdditionalState>({
  status: 'idle'
})

const venueSlice = createSlice({
  name: 'venue',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(fetchVenues.fulfilled, (state, action) => {
        venueAdapter.setAll(state, action.payload.venues)
        state.status = 'fulfilled'
      })
      .addCase(fetchVenues.rejected, state => {
        venueAdapter.removeAll(state)
        state.status = 'rejected'
      })
      .addCase(fetchVenues.pending, state => {
        state.status = 'pending'
      })
  }
})

export const venueActions = {
  fetchVenues,
  ...venueSlice.actions
}

export default venueSlice.reducer
