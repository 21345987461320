import React from 'react'
import styled from 'styled-components/macro'

// Components
import { Title, Checkbox } from 'marvel-components'

// Actions
import { setPerformanceFilter } from 'modules/interaction/actions'

// Selectors
import { selectPerformanceFilters } from 'modules/interaction/selectors'
import {
  getIsBestAvailablePresent,
  getIsDiscountPresent,
  getIsAccessPresent
} from 'modules/ticketing/performance/selectors'

// Misc
import { useSelector, useDispatch } from 'react-redux'
import { media, usePlatform } from 'utils'

// Types
import { PerformanceFilter } from 'shared-types'

type Filter = {
  filter: PerformanceFilter
  label: string
  filterToRemove?: PerformanceFilter[]
  reset?: boolean
}

const PerformanceFilterWrapper = styled.div<{ platform?: string }>`
  padding-top: 1rem;
  ${media.tablet} {
    padding-bottom: 1rem;
    margin-bottom: ${props => (props.platform === 'kiosk' ? '4rem' : '0')};
    background: ${props =>
      props.platform === 'kiosk'
        ? props.theme.themeBackgroundColour
          ? props.theme.themeBackgroundColour
          : props.theme.colors.mainBackgroundColor
        : 'none'};
  }
`

const PerformanceFilters = styled.div`
  padding: 0 1rem 1rem 1rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  ${media.tablet} {
    flex-direction: row;
    justify-content: space-evenly;
    flex-wrap: wrap;
  }
`

const FilterCheckWrapper = styled.div`
  ${media.tablet} {
    margin: 0 0.5rem;
  }
`

const PerformanceFilterContainer: React.FC = () => {
  // Actions
  const dispatch = useDispatch()
  const platform = usePlatform()
  // Filters
  // ------------------------------------------
  const handleSetPerformanceFilters = (
    isSelected: boolean,
    filter: PerformanceFilter,
    filterToRemove?: PerformanceFilter[]
  ) => {
    if (filter === 'all') {
      dispatch(setPerformanceFilter(['all']))
    } else {
      const existingFilters = filters.filter(f => f !== 'all')

      if (isSelected) {
        dispatch(
          setPerformanceFilter([
            ...existingFilters.filter(f => !filterToRemove?.includes(f)),
            filter
          ])
        )
      } else {
        dispatch(
          setPerformanceFilter(existingFilters.filter(f => filter !== f))
        )
      }
    }
  }
  // Price Filter
  // ------------------------------------------
  /* const handleSetPricePerformanceFilters = (
    lowerValue: number,
    upperValue: number
  ) =>
    lowerValue &&
    upperValue &&
    dispatch(
      setPricePerformanceFilters({
        lowerValue,
        upperValue
      })
    ) */

  // Selectors
  const filters = useSelector(selectPerformanceFilters)
  const isBestAvailablePresent = useSelector(getIsBestAvailablePresent)
  const isDiscountPresent = useSelector(getIsDiscountPresent)
  const isAccessPresent = useSelector(getIsAccessPresent)
  /*   const ticketPrices = useSelector(getTicketPricesForAllPerformances)
  const lowerPricePerformanceFilter = useSelector(
    selectLowerPricePerformanceFilter
  )
  const upperPricePerformanceFilter = useSelector(
    selectUpperPricePerformanceFilter
  ) */

  const checkIsFilterSelected = (filter: PerformanceFilter) =>
    filters.includes(filter)

  const filtersAvailable: Filter[] = [
    {
      filter: 'all',
      label: 'All Performances'
    },
    {
      filter: 'afternoon',
      label: 'Afternoon',
      filterToRemove: ['evening']
    },
    {
      filter: 'evening',
      label: 'Evening',
      filterToRemove: ['afternoon']
    },
    {
      filter: 'weekend',
      label: 'Weekend',
      filterToRemove: ['weekday']
    },
    {
      filter: 'weekday',
      label: 'Weekday',
      filterToRemove: ['weekend']
    }
  ]
  return (
    <PerformanceFilterWrapper platform={platform}>
      <Title isSidebar={true}>Filter Performances</Title>
      <PerformanceFilters>
        {filtersAvailable.map(({ filter, label, filterToRemove }) => (
          <FilterCheckWrapper key={filter}>
            <Checkbox
              key={filter}
              label={label}
              isPadded={true}
              isChecked={checkIsFilterSelected(filter)}
              onChange={isSelected =>
                handleSetPerformanceFilters(isSelected, filter, filterToRemove)
              }
              checkboxTheme={'light'}
            />
          </FilterCheckWrapper>
        ))}
        {isAccessPresent && (
          <FilterCheckWrapper key={'access'}>
            <Checkbox
              label={'Access Performances'}
              isPadded={true}
              isChecked={checkIsFilterSelected('access')}
              onChange={isSelected =>
                handleSetPerformanceFilters(isSelected, 'access')
              }
              checkboxTheme={'light'}
              isThemed
            />
          </FilterCheckWrapper>
        )}
        {isBestAvailablePresent && (
          <FilterCheckWrapper key={'best-available'}>
            <Checkbox
              label={'Best tickets at the best price'}
              isPadded={true}
              isChecked={checkIsFilterSelected('best-available')}
              onChange={isSelected =>
                handleSetPerformanceFilters(isSelected, 'best-available')
              }
              checkboxTheme={'best-available'}
              isThemed
            />
          </FilterCheckWrapper>
        )}
        {isDiscountPresent && (
          <FilterCheckWrapper key={'discount'}>
            <Checkbox
              label={'Discount applies to these performances'}
              isPadded={true}
              isChecked={checkIsFilterSelected('discount')}
              onChange={isSelected =>
                handleSetPerformanceFilters(isSelected, 'discount')
              }
              checkboxTheme={'discount'}
            />
          </FilterCheckWrapper>
        )}
      </PerformanceFilters>
    </PerformanceFilterWrapper>
  )
}
export { PerformanceFilterContainer }
