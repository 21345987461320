import React, { useEffect } from 'react'
import styled from 'styled-components'

//Components
import {
  Title,
  CopyBlock,
  Link,
  ExchangeForm,
  ExchangeFormValues,
  WithLoader
} from 'marvel-components'

// Form
import { FormikHelpers } from 'formik'

// Utils
import {
  media,
  orderExchangeSuccessRoute,
  getExchangeTextForEvent
} from 'utils'

// Redux
import { useDispatch, useSelector } from 'react-redux'

// Actions
import { performanceActions } from 'modules/ticketing/performance/performanceSlice'
import { createExchange } from 'modules/basket/actions'

// Selectors
import {
  selectFilteredPerformances,
  selectIsLoadingPerformances
} from 'modules/ticketing/performance/selectors'
import { selectOrderReferenceNumber } from 'modules/basket/selectors'
import { eventDetailsSelectors } from 'modules/ticketing/event'

// Misc
import { useHistory } from 'react-router-dom'
import { eventIds } from 'utils'

type Props = {
  orderId: string
  eventId?: string
}

const ExchangeWrapper = WithLoader(
  styled.div`
    box-sizing: border-box;
    padding: 1.5rem 1.5rem 3.5rem 1.5rem;
    background: ${props => props.theme.colors.secondaryBackgroundColor};
    ${media.tablet} {
      padding: 1rem 1rem 3.5rem 1rem;
      margin-top: 0;
    }
  `
)

const ExchangeContainer: React.FC<Props> = ({ orderId, eventId }) => {
  const dispatch = useDispatch()
  const history = useHistory()

  // Selectors
  const performances = useSelector(selectFilteredPerformances)
  const isLoading = useSelector(selectIsLoadingPerformances)
  const bookingReference = useSelector(selectOrderReferenceNumber)
  const showName = useSelector(eventDetailsSelectors.selectEventName)

  // Fetch Performances & Event Details
  // ------------------------------------------
  useEffect(() => {
    dispatch(
      performanceActions.fetchPerformancesForExchange({
        orderId
      })
    )
  }, [dispatch, orderId])

  const handleExchangeFormSubmit = (
    values: ExchangeFormValues,
    actions: FormikHelpers<ExchangeFormValues>
  ) => {
    dispatch(
      createExchange({
        setSubmitting: actions.setSubmitting,
        values: {
          ...values,
          originalCard: values.refundType === 'originalCard',
          transactionNumber: orderId,
          bookingReference: bookingReference,
          performanceIds:
            values.refundRequested || values.noOptions
              ? []
              : values.performanceIds,
          voucherRequested: values.refundRequested
            ? false
            : values.voucherRequested,
          noOptions: values.refundRequested ? false : values.noOptions
        },
        onSuccessCallback: () =>
          history.push(orderExchangeSuccessRoute(orderId))
      })
    )
  }

  return (
    <ExchangeWrapper isLoading={isLoading}>
      <Title
        isCapitalised={false}
        isCentralised={false}
        isBold={true}
        size='large'
      >
        Can’t make the new date?
      </Title>
      <CopyBlock>
        {getExchangeTextForEvent(showName, eventId)}
        <p>
          <Link
            text={`Visit this page for more information.`}
            href='https://lwtheatres.co.uk/faq/'
            target='_blank'
            isUnderlined={true}
            size={'copy'}
          />
        </p>
      </CopyBlock>
      <ExchangeForm
        performances={performances}
        onSubmit={handleExchangeFormSubmit}
        showName={showName}
        availabilityThreshold={
          eventId === eventIds.production.backToTheFuture ? 550 : 200
        }
      />
    </ExchangeWrapper>
  )
}

export { ExchangeContainer }
