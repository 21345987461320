import { createSlice, createEntityAdapter } from '@reduxjs/toolkit'

// Utils
import hospitalityApi from 'services/hospitalityApi'
import { createAPIThunk } from 'modules/smart-waiter/shared/actions'
import { sitesEntity } from 'services/hospitalitySchemas'

// Types
import { Site } from 'shared-types'

type SitesResponse = {
  sites?: { [key: string]: Site }
}
// Actions

export const fetchAll = createAPIThunk<SitesResponse>(
  'marvel/hospitality/FETCH_SITES',
  () => hospitalityApi.fetchAll('/site/'),
  sitesEntity
)

// Reducer

export const sitesAdapter = createEntityAdapter<Site>({
  sortComparer: (a, b) => a.name.localeCompare(b.name)
})

const siteSlice = createSlice({
  name: 'sites',
  initialState: sitesAdapter.getInitialState({
    loading: 'idle'
  }),
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchAll.pending, (state, action) => {
      state.loading = 'pending'
    })
    builder.addCase(fetchAll.fulfilled, (state, action) => {
      if (action.payload.sites) {
        sitesAdapter.setAll(state, action.payload.sites)
      }
      state.loading = 'idle'
    })
  }
})

export default siteSlice.reducer
