// Misc
import { createSelector } from '@reduxjs/toolkit'
import moment from 'moment'

// Types
import { AppState } from 'modules/types'

// Adapters
import { venuesAdapter } from './reducers'

// Selectors
import { serviceSelectors } from 'modules/smart-waiter/service'
import { basketSelectors } from 'modules/smart-waiter/basket'
import { parseZonalMenu } from 'utils'
import { menuSelectors } from '../menu'
import { venueSelectors } from '../venue'

export const { selectById, selectIds, selectEntities, selectAll, selectTotal } =
  venuesAdapter.getSelectors<AppState>(state => state.smartWaiter.venues)

export const selectIsVenueListLoading = (state: AppState) =>
  state.smartWaiter.venues.loading === 'pending'

export const selectAreas = (state: AppState) =>
  state.smartWaiter.venues.areas.map(area => ({
    value: area.zonalId,
    label: area.name,
    id: area.id
  }))

export const makeSelectServiceWithVenueId = () =>
  createSelector(
    [selectById, serviceSelectors.selectAll],
    (venue, services) => {
      return venue
        ? services.filter(service => venue.services.includes(service.id))
        : []
    }
  )

export const makeSelectMissingMenuWithVenueId = () =>
  createSelector(
    [
      selectById,
      menuSelectors.selectAll,
      basketSelectors.selectMenuIdsInBasket,
      venueSelectors.selectedSelectedTime
    ],
    (venue, menus, menuIdsInBasket, selectedTime) => {
      return venue
        ? menus.filter(menu => {
            const menuType = parseZonalMenu(menu.name)
            return (
              !menuIdsInBasket.includes(menu.id) &&
              moment
                .utc()
                .add(menuType.cutOff, 'minutes')
                .isBefore(moment.utc(selectedTime))
            )
          })
        : []
    }
  )

export const selectSalesAreaForVenue = (
  state: AppState,
  venueId: number,
  salesAreaId: number
) => {
  const venue = selectById(state, venueId)
  if (venue) {
    return venue.salesAreas.find(salesArea => salesArea.id === salesAreaId)
  }
}

export const selectSelectedVenueId = (state: AppState) =>
  state.smartWaiter.venues.selectedVenue

export const selectVenueName = createSelector(selectById, venue => venue?.name)

export const selectSalesAreaName = createSelector(
  selectSalesAreaForVenue,
  salesArea => salesArea?.name
)

export const selectSelectedAreaId = (state: AppState) =>
  state.smartWaiter.venues.selectedAreaId
export const selectedSelectedTime = (state: AppState) =>
  state.smartWaiter.venues.selectedTime
