// Utils
import {
  createEntityAdapter,
  createSelector,
  createSlice
} from '@reduxjs/toolkit'
import ticketingApi from 'modules/ticketing/services/ticketingApi'
import { createAPIThunk } from 'modules/ticketing/shared/actions'
import { schema } from 'normalizr'

// Types
import { SeatAvailability } from 'shared-types'
import { RootState } from 'app/reduxStore'

const seatAvailabilitySchema = new schema.Entity<SeatAvailability>(
  'availability',
  undefined,
  {
    idAttribute: value => `${value.areaId}-${value.id}`
  }
)
const availabilitySchema = [seatAvailabilitySchema]

type AvailabilityRequest = {
  performanceId: string
  promoCode?: string | null
}
type AvailabilityResponse = {
  availability?: { [key: string]: SeatAvailability }
}

// Actions
const fetchAvailability = createAPIThunk<
  AvailabilityResponse,
  AvailabilityRequest
>(
  'marvel/ticketing/FETCH_AVAILABILITY',
  (data, contextId, authToken) =>
    ticketingApi.fetchAll(
      `/performance/${data.performanceId}/availability/`,
      {
        ...(data.promoCode && { promoCode: data.promoCode })
      },
      contextId,
      authToken
    ),
  availabilitySchema
)

const availabilityAdapter = createEntityAdapter<SeatAvailability>({
  selectId: seatAvailability =>
    `${seatAvailability.areaId}-${seatAvailability.id}`
})

const availabilitySlice = createSlice({
  name: 'availability',
  initialState: availabilityAdapter.getInitialState({
    status: 'idle'
  }),
  reducers: {},
  extraReducers: builder => {
    builder.addCase(fetchAvailability.pending, state => {
      availabilityAdapter.removeAll(state)
      state.status = 'pending'
    })
    builder.addCase(fetchAvailability.fulfilled, (state, action) => {
      if (action.payload.data.availability) {
        availabilityAdapter.setAll(state, action.payload.data.availability)
      }
      state.status = 'idle'
    })
  }
})

// Actions
export const availabilityActions = {
  fetchAvailability
}

// Selectors
const entitySelectors = {
  ...availabilityAdapter.getSelectors<RootState>(
    state => state.ticketing.availability
  )
}
const selectIsAvailable = createSelector(
  [entitySelectors.selectById],
  availability => availability?.status === 1
)
export const availabilitySelectors = {
  ...entitySelectors,
  selectIsAvailable,
  selectIsLoading: (state: RootState) =>
    state.ticketing.availability.status !== 'idle'
}

// Reducer
export default availabilitySlice.reducer
