import { createSlice } from '@reduxjs/toolkit'
import { eventAdapter } from './selectors'
import { fetchEvents } from './actions'

type AdditionalState = {
  status: 'idle' | 'pending' | 'fulfilled' | 'rejected'
}

const initialState = eventAdapter.getInitialState<AdditionalState>({
  status: 'idle'
})

const eventSlice = createSlice({
  name: 'event',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(fetchEvents.fulfilled, (state, action) => {
        eventAdapter.setAll(state, action.payload.events)
        state.status = 'fulfilled'
      })
      .addCase(fetchEvents.rejected, state => {
        eventAdapter.removeAll(state)
        state.status = 'rejected'
      })
      .addCase(fetchEvents.pending, state => {
        state.status = 'pending'
      })
  }
})

export const eventActions = {
  fetchEvents,
  ...eventSlice.actions
}

export default eventSlice.reducer
