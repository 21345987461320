const Cinderella = {
  colors: {
    mainBackgroundColor: '#12017E',
    bestAvailablePerformanceColor: '#E6007E',
    bestAvailablePerformanceColorComplement: '#ffffff',
    mainColor: '#E6007E',
    fadedHighlightColor: '#f1f1f1',
    mainColorComplement: '#ffffff',
    lwBrandColor: '#ffffff',
    lwBrandColorComplement: '#111111',
    numberFilterSelectorColor: '#ffffff',
    numberFilterSelectorCopyColor: '#111111',
    priceSliderHandleColor: '#E6007E',
    bestAvailableBannerColor: '#181818',
    bestAvailableBannerColorComplement: '#ffffff',
    sidebarCopyColor: '#ffffff',
    gradientValueColor: '#ff94cf',
    gradientPremiumColor: '#E6007E',
    gradientTextColor: '#ffffff'
  },
  themeBackgroundColour: '#111DFF',
  orderSuccess: {
    showHospitality: true,
    showCecilBeatonBar: true
  }
}

export { Cinderella }
