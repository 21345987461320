import { createAsyncThunk } from '@reduxjs/toolkit'
import { bannerActions } from 'modules/banner'
import { normalize, schema } from 'normalizr'
import contentfulApi from '../services/contentfulApi'
import { ContentfulGenreEntity, Genre } from '../shared/types'

const genreSchema = new schema.Entity('genres', undefined)

type FetchGenreResponse = {
  genres: Record<string, Genre>
}

export const fetchGenres = createAsyncThunk<FetchGenreResponse, undefined>(
  'marvel/self-serve/FETCH_GENRES',
  async (_, { dispatch, rejectWithValue }) => {
    try {
      const genres = (await contentfulApi.fetchMany({
        contentType: 'genre'
      })) as ContentfulGenreEntity[]

      if (!genres) {
        throw new Error('Failed to fetch genres')
      }

      const genresData = genres.map(genre => {
        return {
          ...genre.fields,
          id: genre.sys.id,
          name: genre.fields.name
        }
      })

      const normalized = normalize(genresData, [genreSchema])
      return normalized.entities as FetchGenreResponse
    } catch (err) {
      dispatch(
        bannerActions.setBannerContent({
          bannerType: 'error',
          title: 'Please Note',
          text: 'Failed to fetch genres.'
        })
      )
      return rejectWithValue(err)
    }
  }
)
