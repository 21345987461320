import axios from 'axios'
import { APIResponse } from 'shared-types'

const API_ROOT = process.env.REACT_APP_API_ROOT

const buildAxios = () => {
  const axiosInstance = axios.create({
    baseURL: `${API_ROOT}`,
    headers: {
      'Content-Type': 'application/json'
    }
  })
  axiosInstance.defaults.timeout = 25000
  return axiosInstance
}

const ax = buildAxios()

const smartWaiterApi = {
  async fetchAll(url: string) {
    const result = await ax.get<APIResponse>(url)
    return result.data
  },
  async create(url: string, body: any) {
    const result = await ax.post<APIResponse>(url, JSON.stringify(body))
    return result.data
  },
  async update(url: string, body: any) {
    const result = await ax.put<APIResponse>(url, JSON.stringify(body))
    return result.data
  }
}

export default smartWaiterApi
