import { useAppDispatch } from 'app/reduxStore'
import { genreActions, genreSelectors } from 'modules/self-serve/genre'
import { Genre } from 'modules/self-serve/shared/types'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import { compareStrings } from 'utils/sorting'

const GenreButtonsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: calc(100%);
  overflow: scroll;
  padding: 1rem 0;
  &:before {
    content: '';
    background: transparent;
    display: block;
    min-width: 4rem;
  }
  &:after {
    content: '';
    background: transparent;
    display: block;
    min-width: 4rem;
  }
  ::-webkit-scrollbar {
    display: none;
  }

  -ms-overflow-style: none;
  scrollbar-width: none;
`

const GenreButton = styled.div<{
  isSelected: boolean
  isLeftMost?: boolean
  isRightMost?: boolean
}>`
  flex-grow: 1;
  flex-shrink: 0;
  width: 15%;
  margin-left: ${props => (props.isLeftMost ? '0' : '1%')};
  margin-right: ${props => (props.isRightMost ? '0' : '1%')};
  padding: 0.6rem 0;
  font-family: ${props => props.theme.font.header};
  font-size: 0.9rem;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 0.2rem;
  border: 2px solid #c4c4c4;
  border-radius: 4px;
  background: ${props => (props.isSelected ? '#D3D3D3' : 'none')};
  cursor: pointer;
`

const EventGenreContainer = () => {
  const dispatch = useAppDispatch()

  const genres = useSelector(genreSelectors.selectGenres)
  const selectedGenres = useSelector(genreSelectors.selectSelectedGenres)

  const handleGenreClick = (genre?: Genre) => {
    if (genre) {
      if (selectedGenres.includes(genre.id)) {
        dispatch(
          genreActions.selectGenres(
            selectedGenres.filter(id => id !== genre.id)
          )
        )
      } else {
        dispatch(genreActions.selectGenres([...selectedGenres, genre.id]))
      }
    } else {
      dispatch(genreActions.selectGenres([]))
    }
  }

  return (
    <GenreButtonsWrapper>
      <GenreButton
        onClick={() => handleGenreClick()}
        isSelected={selectedGenres.length === 0}
        isLeftMost={true}
      >
        All
      </GenreButton>
      {genres
        .sort((a, b) => compareStrings(a.name, b.name))
        .map((genre, i) => (
          <GenreButton
            key={i}
            onClick={() => handleGenreClick(genre)}
            isSelected={selectedGenres.includes(genre.id)}
            isRightMost={i === genres.length - 1}
          >
            {genre.name}
          </GenreButton>
        ))}
    </GenreButtonsWrapper>
  )
}

export { EventGenreContainer }
