// Types
import { AppState } from 'modules/types'

// Adapters
import { createSelector } from '@reduxjs/toolkit'
import { sitesAdapter } from './siteSlice'

// Selectors
export const {
  selectById,
  selectIds,
  selectEntities,
  selectAll,
  selectTotal
} = sitesAdapter.getSelectors<AppState>(state => state.hospitality.sites)

export const selectIsLoading = (state: AppState) =>
  state.hospitality.sites.loading === 'pending'

export const selectSiteName = createSelector(selectById, site => site?.name)
