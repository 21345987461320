import { kioskAdapter } from 'modules/self-serve/kiosk/kioskSlice'
import { AppState } from 'modules/index'
import { createSelector } from '@reduxjs/toolkit'

export const {
  selectEntities: selectKioskEntities,
  selectAll: selectKiosks,
  selectById: selectKioskById,
  selectTotal: selectTotalKiosks,
  selectIds: selectKioskIds
} = kioskAdapter.getSelectors<AppState>(state => state.selfServe.kiosk)

export const selectKioskStatus = (state: AppState) =>
  state.selfServe.kiosk.status

export const selectKioskAccessToken = (state: AppState) =>
  state.selfServe.kiosk.accessToken

export const selectSelectedKioskId = (state: AppState) =>
  state.selfServe.kiosk.selectedKioskId || 0

export const selectSelectedKiosk = createSelector(
  [selectSelectedKioskId, selectKioskEntities],
  (kioskId, entities) => entities[kioskId]
)

export const selectPaymentRequestStatus = (state: AppState) =>
  state.selfServe.kiosk.paymentStatus

export const selectIsKioskLoading = (state: AppState) =>
  state.selfServe.kiosk.status === 'pending'
