import { keyframes } from 'styled-components/macro'

export const rotate = keyframes`
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
`

export const fadeIn = keyframes`
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
`

export const fadeOut = keyframes`
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
`

export const pulse = keyframes`
  0% {
    stroke-width: 3px;
    stroke-opacity: 1;
    transform: scale(0.3);
  }
  100% {
    stroke-width: 0;
    stroke-opacity: 0;
    transform: scale(2);
  }
`

export const transitionIn = keyframes`
  0% {
    transform: translateY(-50px);
    opacity: 0;
  }
  80% {
    transform: translateY(0);
  }
  100% {
    opacity: 1;
  }
`
