const Lehman = {
  colors: {
    mainColor: '#608A91',
    mainColorComplement: '#ffffff',
    performanceHoverColor: '#94bad1',
    bestAvailableBannerColor: '#B88E1A',
    bestAvailableBannerColorComplement: '#111111',
    numberFilterSelectorColor: '#ffffff',
    numberFilterSelectorCopyColor: '#111111',
    priceSliderHandleColor: '#608A91',
    mainBackgroundColor: '#1C3341'
  },
  themeBackgroundColour: '#1C3341',
  orderSuccess: {
    showHospitality: true,
    showCecilBeatonBar: true
  }
}

// themeBackgroundColour: '#608A91'

export { Lehman }
