// Utils
import { createSelector } from '@reduxjs/toolkit'

// Types
import { AppState } from 'modules/types'

// Adapters
import { occasionsAdapter } from './occasionSlice'

// Selectors
export const { selectById, selectIds, selectEntities, selectAll, selectTotal } =
  occasionsAdapter.getSelectors<AppState>(state => state.hospitality.occasions)

export const selectIsLoading = (state: AppState) =>
  state.hospitality.occasions.loading === 'pending'

export const selectOccasionName = createSelector(
  selectById,
  occasion => occasion?.name
)
