import React, { useState, useEffect } from 'react'
import styled, { withTheme, DefaultTheme, css } from 'styled-components/macro'
import ReactSwitch from 'react-switch'

export interface SwitchProps {
  isChecked?: boolean
  label?: string
  isLabelVisible?: boolean
  theme: DefaultTheme
  name?: string
  onChange?: (isChecked: boolean) => void
}

const Label = styled.span(props => {
  const {
    theme: {
      font: { copy }
    }
  } = props

  return css`
    font-family: ${copy};
    font-size: 0.75rem;
    margin-left: 0.5rem;
    color: inherit;
  `
})

const SwitchWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  flex: 1 0 0;
  align-items: center;
`

export const Switch = withTheme((props: SwitchProps) => {
  const {
    label,
    isLabelVisible = true,
    theme: {
      colors: { activeColor }
    },
    isChecked = false,
    name,
    onChange
  } = props

  const [isCheckedState, setChecked] = useState(isChecked)

  useEffect(() => {
    if (onChange) onChange(isCheckedState)
  }, [isCheckedState, name, onChange])

  return (
    <SwitchWrapper>
      <ReactSwitch
        checked={isCheckedState}
        onChange={() => setChecked(!isCheckedState)}
        onColor={activeColor}
        aria-checked={isCheckedState}
        aria-label={label}
      />
      {label && isLabelVisible && <Label>{label}</Label>}
    </SwitchWrapper>
  )
})
