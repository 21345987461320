export const mediaQuery = (maxWidth: number) => `(max-width: ${maxWidth}px)`
export const DESKTOP_BREAKPOINT = 1299
export const NARROWDESKTOP_BREAKPOINT = 1249
// Tablet breakpoint updated from 995 as part of the kiosk updates
export const TABLET_BREAKPOINT = 1081
export const MOBILE_BREAKPOINT = 600

export const media = {
  custom: mediaQuery,
  desktop: `@media ${mediaQuery(DESKTOP_BREAKPOINT)}`,
  narrowdesktop: `@media ${mediaQuery(NARROWDESKTOP_BREAKPOINT)}`,
  tablet: `@media ${mediaQuery(TABLET_BREAKPOINT)}`,
  phone: `@media ${mediaQuery(MOBILE_BREAKPOINT)}`
}
