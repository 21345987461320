import React, { memo, ReactNode } from 'react'
import { Sector } from 'shared-types'
import { SeatMapRowLabel } from './SeatMapRowLabel'

interface Props {
  sector: Sector
  children?: ReactNode
}

export const SeatMapSector: React.FC<Props> = memo(({ sector, children }) => {
  const { angle: sectorAngle = 0, height, width, x, y, rowLabels, id } = sector

  return (
    <g
      transform={`
        translate(${x}, ${y})
        rotate(${sectorAngle}, ${width / 2}, ${height / 2})
      `}
      data-description={`sector-${id}`}
    >
      {children}
      {rowLabels.map((rowLabel, index) => (
        <SeatMapRowLabel
          key={`row-label-${id}-${index}`}
          angle={sectorAngle}
          x={rowLabel.x}
          y={rowLabel.y}
          width={rowLabel.width}
          height={rowLabel.height}
          text={rowLabel.text}
          fontSize={rowLabel.fontSize}
        />
      ))}
    </g>
  )
})
