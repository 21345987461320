import React from 'react'

// Components
import { FormError } from 'marvel-components'
// Misc
import { useField, ErrorMessage } from 'formik'
import styled from 'styled-components'

interface Props {
  name: string
  label: string
  value: string
}
const FormCheckboxWrapper = styled.div<{ noPadding?: boolean }>`
  position: relative;
  padding-bottom: 1.25rem;
`
const Label = styled.label`
  font-family: ${(props) => props.theme.font.copy};
  font-size: 0.875rem;
  color: ${(props) => props.theme.colors.copyColor};
  line-height: 1.5;
  display: flex;
  align-items: flex-start;
`

const RadioInput = styled.input`
  margin-right: 0.5rem;
  line-height: 1.5;
  margin-top: 0.2rem;
`

const FormRadio: React.FC<Props> = ({ name, label, value }) => {
  const [field] = useField(name)
  return (
    <FormCheckboxWrapper>
      <ErrorMessage {...field} component={FormError} />
      <Label>
        <RadioInput {...field} type="radio" name={name} value={value} />
        {label}
      </Label>
    </FormCheckboxWrapper>
  )
}

export { FormRadio }
