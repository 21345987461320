import { useEffect } from 'react'

// Components
import { Link } from 'marvel-components'
import { lighten, media } from 'utils'

// Misc
import styled from 'styled-components'
import { useLocation } from 'react-router-dom'

interface Item {
  title: string
  id: string
}

interface Props {
  items: Item[]
  isLoading?: boolean
}

const AnchorWrapper = styled.div`
  position: sticky;
  top: 0;
  display: flex;
  flex-direction: row;
  background: ${props => lighten(props.theme.colors.borderColor, 10)};
  padding: 0.25rem 0.75rem;
  z-index: 1;
  flex-wrap: wrap;
`

const StyledLink = styled(Link)`
  padding: 0.5rem 1rem;
  background: ${props => props.theme.colors.borderColor};
  border-radius: 0.25rem;
  margin: 0.25rem 0.25rem;
  text-transform: uppercase;
  white-space: nowrap;
  display: block;

  ${media.phone} {
    width: calc(50% - 0.5rem);
    text-align: center;
    padding: 0.5rem 0;
    font-size: 0.75rem;
  }
`

const AnchorMenu = ({ items, isLoading = false }: Props) => {
  const location = useLocation()

  useEffect(() => {
    const hash = location.hash.substring(1)
    const element = document.getElementById(hash)
    if (!isLoading) {
      element && element.scrollIntoView(true)
    }
  }, [location, isLoading])

  return (
    <AnchorWrapper>
      {items.map(item => (
        <StyledLink
          key={`link-${item.title}`}
          href={`#${item.id}`}
          text={item.title}
          target='_self'
          size='medium'
        />
      ))}
    </AnchorWrapper>
  )
}
export { AnchorMenu }
