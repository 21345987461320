import React from 'react'

// Components
import { FormCheckbox, Link } from 'marvel-components'

// Utils
import styled from 'styled-components/macro'
import { Field } from 'formik'

const TermsAndConditionsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  line-height: 1.5;
`

type Props = {
  name: string
  isRequired?: boolean
}

const TermsAndConditionsField: React.FC<Props> = ({ name, isRequired }) => {
  return (
    <TermsAndConditionsWrapper>
      <Field
        name={name}
        label={
          'I accept the LW Theatres Terms and Conditions of sale and acknowledge that children under 4 years of age (including babes in arms) will not be admitted into the auditorium.'
        }
        isLabelVisible={true}
        size={'small'}
        checkboxTheme={'dark'}
        isRequired={isRequired}
        component={FormCheckbox}
      >
        {'I accept the LW Theatres '}
        <Link
          text='Terms and Conditions'
          href='https://lwtheatres.co.uk/terms-conditions/'
          size='small'
          isUnderlined={true}
        />
        {
          ' of sale and acknowledge that children under 4 years of age (including babes in arms) will not be admitted into the auditorium.'
        }
      </Field>
    </TermsAndConditionsWrapper>
  )
}

export { TermsAndConditionsField }
