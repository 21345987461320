import { createSlice } from '@reduxjs/toolkit'
import { Question } from '../shared/types'
import { fetchFaq } from './actions'

type State = {
  status: 'idle' | 'pending' | 'fulfilled' | 'rejected'
  title: string
  description?: string
  questions: Question[]
}

const initialState: State = {
  status: 'idle',
  title: '',
  questions: []
}

const faqSlice = createSlice({
  name: 'faq',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(fetchFaq.pending, state => {
        state.status = 'pending'
      })
      .addCase(fetchFaq.fulfilled, (state, action) => {
        state.status = 'fulfilled'
        state.title = action.payload.title
        state.description = action.payload.description
        state.questions = action.payload.questions
      })
      .addCase(fetchFaq.rejected, state => {
        state.status = 'rejected'
      })
  }
})

export const faqActions = {
  fetchFaq,
  ...faqSlice.actions
}

export default faqSlice.reducer
