import React from 'react'
import { media } from 'utils'
import styled from 'styled-components'

import moment from 'moment'

// Components
import { Title, Button, CopyBlock } from 'marvel-components'

// Actions
import { closeModal } from 'modules/overlay/actions'
import { menuItemActions } from 'modules/smart-waiter/menu-item'

// Utils
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router'

export interface ConfirmAgeOverlayContainerProps {
  // Overlay
  productId: EntityId
  cancelUrl: string
  age: number
}

const ConfirmAgeWrapper = styled.div`
  padding: 1rem;
  max-width: 23.75rem;

  ${media.tablet} {
    width: 70vw;
  }
`

const ConfirmAgeTitleWrapper = styled.span`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`

const TitleAgeWrapper = styled.span`
  margin-left: 0.5rem;
  width: 18px;
  height: 18px;
  line-height: 15px;
  border-radius: 9px;
  border: 2px solid #999;
  box-sizing: border-box;
  color: #999;
  font-size: 11px;
  text-align: center;
  font-family: ${(props) => props.theme.font.header};
`

const StyledButton = styled(Button)`
  margin-top: 1rem;
`

const CopyBold = styled.span`
  font-family: ${(props) => props.theme.font.header};
`

const ConfirmAgeCopyWrapper = styled.div`
  padding: 1rem 0;
`

const ConfirmAgeOverlayContainer: React.FC<ConfirmAgeOverlayContainerProps> = ({
  productId,
  cancelUrl,
  age
}) => {
  const dispatch = useDispatch()
  const history = useHistory()

  const handleConfirmAgeClick = () => {
    dispatch(menuItemActions.setMinimumAge(age))
    dispatch(closeModal())
  }
  const handleCancelConfirmAgeClick = () => {
    dispatch(closeModal())
    history.push(cancelUrl)
  }
  return (
    <ConfirmAgeWrapper>
      <ConfirmAgeTitleWrapper>
        <Title size="large" isBold={true}>
          Age Restrictions
        </Title>
        <TitleAgeWrapper>{age}</TitleAgeWrapper>
      </ConfirmAgeTitleWrapper>
      <ConfirmAgeCopyWrapper>
        <CopyBlock isCentralised={true}>
          By adding this product you are confirming that you and the intended
          consumer were born on or before{' '}
          <CopyBold>
            {moment().subtract(age, 'years').format('Do MMMM YYYY')}
          </CopyBold>
          . Proof of age may be required when the product is collected.
          Customers who are unable to provide a valid ID on request{' '}
          <CopyBold>will not receive and will not be refunded</CopyBold> for the
          alcohol ordered.{' '}
        </CopyBlock>
      </ConfirmAgeCopyWrapper>
      <StyledButton
        text="Continue"
        onClick={handleConfirmAgeClick}
        isBlock={true}
        icon="check"
      />
      <StyledButton
        text="Cancel"
        onClick={handleCancelConfirmAgeClick}
        isBlock={true}
        icon="times"
        buttonType="danger"
        size="small"
      />
    </ConfirmAgeWrapper>
  )
}
export { ConfirmAgeOverlayContainer }
