import styled, { css } from 'styled-components/macro'
import { media } from 'utils'

export type TitleSize = 'small' | 'medium' | 'medium-large' | 'large' | 'xlarge'

interface Props {
  readonly isCapitalised?: boolean
  readonly isCentralised?: boolean
  readonly isPadded?: boolean
  readonly isBold?: boolean
  readonly isMultiline?: boolean
  readonly size?: TitleSize
  readonly isSidebar?: boolean
}

const titleSize = css<Props>`
  ${({ size }) => {
    switch (size) {
      case 'small':
        return css`
          font-size: 0.75rem;
          padding: 1.25rem 0;
        `
      case 'medium':
        return css`
          font-size: 1rem;
          padding: 0.5rem 0;
        `
      case 'medium-large':
        return css`
          font-size: 0.875rem;
          padding: 0.5rem 0;
        `
      case 'large':
        return css`
          font-size: 1.125rem;
          padding: 0.5rem 0;
          ${media.phone} {
            font-size: 1rem;
          }
        `
      case 'xlarge':
        return css`
          font-size: 1.5rem;
          padding: 0.5rem 0;
          ${media.phone} {
            font-size: 1.25rem;
          }
        `
    }
  }}
`

const Title = styled.span<Props>`
  display: block;
  text-transform: ${({ isCapitalised }) =>
    isCapitalised ? 'uppercase' : 'initial'};
  text-align: ${({ isCentralised }) => (isCentralised ? 'center' : 'initial')};
  color: ${({ isSidebar, theme }) =>
    isSidebar ? theme.colors.sidebarCopyColor : theme.colors.headingColor};
  font-family: ${({ isBold, theme }) =>
    isBold ? theme.font.header : theme.font.copy};
  line-height: ${({ isMultiline }) => (isMultiline ? '1.5' : '1.15')};
  ${titleSize}
  ${({ isPadded = true }) => !isPadded && 'padding: 0;'}
`

Title.defaultProps = {
  size: 'small',
  isCapitalised: true,
  isCentralised: true
}

export { Title }
