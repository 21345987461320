import { useState, useEffect } from 'react'
import { TABLET_BREAKPOINT, mediaQuery } from 'utils'

export const useIsNarrowViewport = (): boolean => {
  const [isNarrowViewport, setIsNarrowViewport] = useState(false)

  useEffect(() => {
    const matchMediaQuery = window.matchMedia(mediaQuery(TABLET_BREAKPOINT))

    const onWidthChange = () => {
      matchMediaQuery && setIsNarrowViewport(matchMediaQuery.matches)
    }

    matchMediaQuery.addListener(onWidthChange)
    onWidthChange()

    return () => {
      matchMediaQuery && matchMediaQuery.removeListener(onWidthChange)
    }
  }, [])

  return isNarrowViewport
}
