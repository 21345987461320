// Components
import { Card } from 'marvel-components'

// Selectors
import { eventSelectors } from 'modules/self-serve/event'
import { genreSelectors } from 'modules/self-serve/genre'
import { venueSelectors } from 'modules/self-serve/venue'

// Misc
import { useState } from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'
import { calendarRoute } from 'utils'
import { compareStrings } from 'utils/sorting'

const StyledCard = styled(Card)<{ size: number }>`
  width: ${props => props.size}px;
  /* height: ${props => props.size}px; */
  background: ${props =>
    props.theme.colors.venueSecondaryBackgroundColor} !important;
  color: ${props => props.theme.colors.venueSecondaryCopyColor} !important;
`

const GridWrapper = styled.div<{ itemSize: number; gap: number }>`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(3, ${props => props.itemSize}px);
  grid-gap: ${props => props.gap}px;
  justify-content: left;
  margin-bottom: 5rem;
`

const EmptyGridWrapper = styled.div`
  padding: 5rem 0;
  text-align: center;
`

const EmptyGridText = styled.div`
  font-family: ${props => props.theme.font.copy};
  font-size: 1.25rem;
  line-height: 1.2;
`

type Props = {
  venueId: string
  itemsPerRow?: number
  itemGap?: number
}

const EventGridContainer: React.FC<Props> = ({
  venueId,
  itemsPerRow = 3,
  itemGap = 15
}) => {
  const history = useHistory()
  const [ref, setRef] = useState<HTMLDivElement | null>()

  const venues = useSelector(venueSelectors.selectVenueEntities)
  const selectedGenres = useSelector(genreSelectors.selectSelectedGenres)
  const events = useSelector(eventSelectors.selectEvents)
    .filter(event => event.venue === venueId)
    .filter(event =>
      selectedGenres.reduce<boolean>(
        (res, genre) => res && (event.genres?.includes(genre) || false),
        true
      )
    )
    .filter(event => event.public)
    .sort((a, b) => compareStrings(a.title, b.title))

  const parentWidth = ref?.getBoundingClientRect().width || 0
  const itemWidth = (parentWidth - itemGap * (itemsPerRow - 1)) / itemsPerRow

  const viewEventDetails = (eventId?: string) => {
    eventId && history.push(calendarRoute(eventId))
  }

  return (
    <>
      {events.length > 0 ? (
        <GridWrapper
          ref={ref => setRef(ref)}
          itemSize={itemWidth}
          gap={itemGap}
        >
          {events.map(event => {
            const venue = event.venue ? venues[event.venue] : null
            return venue ? (
              <StyledCard
                key={`${event.id}`}
                title={event.title}
                imageUrl={event.image}
                size={itemWidth}
                value={event.externalId}
                onClick={viewEventDetails}
              >
                {event.date} | {venue.title}
              </StyledCard>
            ) : null
          })}
        </GridWrapper>
      ) : (
        <EmptyGridWrapper>
          <EmptyGridText>
            There are no events coming up that match the selected genre.
            <br />
            Please try selecting another genre filter.
          </EmptyGridText>
        </EmptyGridWrapper>
      )}
    </>
  )
}

export { EventGridContainer }
