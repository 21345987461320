import React, { ReactNode } from 'react'
import styled, { css } from 'styled-components/macro'
import { FormattedPrice } from 'utils'
import { HandleType } from './'

interface Props {
  name: HandleType
  label: number | null
  position: number
  dimension: number
  children: ReactNode
  onMouseDown: (event: React.MouseEvent, handleName: HandleType) => void
  onTouchStart: (event: React.TouchEvent, handleName: HandleType) => void
  style: { [s: string]: string }
  isLabelVisible?: boolean
}

const HandlePrice = styled.div((props) => {
  const {
    theme: {
      colors: { sidebarCopyColor }
    }
  } = props

  return css`
    position: absolute;
    pointer-events: none;
    top: -1.625rem;
    left: 50%;
    transform: translateX(-50%);
    font-size: 0.625rem;
    color: ${sidebarCopyColor};
  `
})

const HandleWrapper = styled.div<{
  dimension: number
}>((props) => {
  const {
    theme: {
      colors: { fadedHighlightColor, copyColor },
      font: { copy }
    },
    dimension
  } = props

  return css`
    will-change: left, right;
    position: absolute;
    bottom: 0;
    width: ${dimension}px;
    height: ${dimension}px;
    background: #ffffff;
    font-family: ${copy};
    border-radius: 0.125rem;
    user-select: none;
    outline: none;
    color: ${copyColor};
    cursor: pointer;

    box-shadow: 0 0 0 0.1px rgba(50, 50, 93, 0.01),
      0 0.125rem 5px rgba(50, 50, 93, 0.4),
      0 1px 0.125rem rgba(50, 50, 93, 0.05);

    :hover,
    :focus {
      background: ${fadedHighlightColor};
    }

    :active {
      background: ${fadedHighlightColor};
    }
  `
})

export const Handle = (props: Props) => {
  const {
    label,
    children,
    dimension,
    name,
    onMouseDown,
    onTouchStart,
    style,
    isLabelVisible = true
  } = props
  return (
    <HandleWrapper
      style={{
        ...style
        // ...(!isLabelVisible && { background: 'red' })
      }}
      dimension={dimension}
      onMouseDown={(e) => onMouseDown(e, name)}
      onTouchStart={(e) => onTouchStart(e, name)}
    >
      {children}
      {isLabelVisible && (
        <HandlePrice>
          {label && (
            <FormattedPrice isZeroShown={true} amount={label} currency="GBP" />
          )}
        </HandlePrice>
      )}
    </HandleWrapper>
  )
}
