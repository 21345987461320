import React from 'react'
import styled, { css } from 'styled-components/macro'

// Components
import { Button, Icon, ToolTip } from 'marvel-components'
import { Performance, Tag } from 'shared-types'

// Misc
import moment from 'moment'
import {
  FormattedPrice,
  darken,
  media,
  usePlatform,
  useIsNarrowViewport
} from 'utils'

interface Props {
  performance: Performance
  onBookClick?: (id: string) => void
}

const AvailabilityWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 87%;
  height: 100%;
  border-right: dotted 2px #ddd;
  box-sizing: border-box;

  ${media.tablet} {
    width: 85%;
  }

  ${media.phone} {
    width: 80%;
  }
`

const AvailabilityBackground = styled.div.attrs<{
  isSoldOut: boolean
  width: string
}>(props => ({
  style: {
    width: props.isSoldOut ? '0' : props.width,
    minWidth: props.isSoldOut ? '0' : '0.5%'
  }
}))<{
  isSoldOut: boolean
  width: string
}>`
  position: relative;
  top: 0;
  left: 0;
  height: 100%;
  background: ${props => props.theme.colors.availabilityBarColor};
`

const PerformanceListItemWrapper = styled.div<{
  isDiscounted: boolean
  tags?: Tag[]
  platform?: string
}>`
    position: relative;
    display: flex;
    flex-direction: row;
    cursor: 'pointer';
    border: 1px solid ${props => props.theme.colors.borderColor};
    background: #fff;
    color: ${props => props.theme.colors.headingColor};
    padding: 1.5rem 0 1.5rem 0;
    margin: 0.75rem 0;
    font-family: ${props => props.theme.font.header};
    align-items: center;
    box-sizing: border-box;

    ${props =>
      props.isDiscounted &&
      css`
        border-bottom: 5px solid
          ${props => props.theme.colors.discountedPerformanceColor};
      `}
    
      ${props =>
        props.tags &&
        props.tags.find(tag => tag.name === 'Targeted Event') &&
        css`
          border-bottom: 5px solid
            ${props => props.theme.colors.bestAvailablePerformanceColor};
        `}

    ${media.tablet} {
      padding: ${props => (props.platform === 'kiosk' ? '1.5rem 0' : '1rem 0')};
      align-items: center;
    }

    ${media.phone} {
      padding: .75rem 0;
      margin: 0.5rem 0;
    }

  `

const TitleWrapper = styled.div`
  display: flex;
`

const NotesIconWrapper = styled.div`
  cursor: pointer;
`

const PerformanceDetailsWrapper = styled.div<{ platform?: string }>`
  width: 85%;
  padding-left: 2%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  font-family: ${props => props.theme.font.header};
  flex-wrap: wrap;
  position: relative;

  ${media.tablet} {
    font-size: ${props => (props.platform === 'kiosk' ? '1rem' : '0.75rem')};
    width: 83%;
    padding-left: 2%;
  }
  ${media.phone} {
    font-size: 0.675rem;
    width: 78%;
    padding-left: 2%;
  }
`

const PerformanceCTAWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 9%;
  padding: 0 2%;
  ${media.tablet} {
    width: 11%;
  }
  ${media.phone} {
    width: 16%;
  }
`

const PerformanceCTA = styled(Button)`
  border: 1px solid;
  border-color: ${props => darken(props.theme.colors.mainColor, 15)};
`

const PerformancePriceWrapper = styled.div<{ platform?: string }>`
  color: ${props => props.theme.colors.copyColor};
  margin-top: 0.5rem;
  display: flex;
  font-family: ${props => props.theme.font.copy};
  font-size: 0.875rem;
  ${media.tablet} {
    font-size: ${props => (props.platform === 'kiosk' ? '0.9rem' : '0.625rem')};
  }
`

const SoldOutWrapper = styled.span`
  text-transform: uppercase;
  text-align: center;
  font-size: 0.75rem;
  ${media.tablet} {
    margin-top: 0.5rem;
  }
`

const LimitedAvailabilityLabel = styled.span`
  margin-left: 0.5rem;
  color: ${props => props.theme.colors.alertColor};
`

const FromWrapper = styled.span`
  margin-right: 0.3rem;
`

const PerformanceListItemNM: React.FC<Props> = ({
  performance,
  onBookClick
}) => {
  const {
    id,
    startDate,
    minTicketPrice,
    isSoldOut = false,
    isDiscounted = false,
    percentageAvailable = 0,
    tags,
    notes
  } = performance

  const handleBookNowClick = () => onBookClick && onBookClick(id)
  const platform = usePlatform()
  const isNarrowViewport = useIsNarrowViewport()

  return (
    <PerformanceListItemWrapper
      isDiscounted={isDiscounted}
      tags={tags}
      platform={platform}
    >
      <AvailabilityWrapper>
        <AvailabilityBackground
          isSoldOut={isSoldOut}
          width={`${percentageAvailable * 100}%`}
        ></AvailabilityBackground>
      </AvailabilityWrapper>
      <PerformanceDetailsWrapper platform={platform}>
        <TitleWrapper>
          {moment.utc(startDate).format('dddd Do MMMM YYYY, h:mm a').toString()}

          {notes && (
            <ToolTip content={notes}>
              <NotesIconWrapper>
                <Icon icon='info-circle' />
              </NotesIconWrapper>
            </ToolTip>
          )}
        </TitleWrapper>
        {!isSoldOut && (
          <PerformancePriceWrapper platform={platform}>
            <FromWrapper>From</FromWrapper>
            <FormattedPrice
              amount={minTicketPrice}
              currency='GBP'
              isDecimalsVisible={true}
            />
            {percentageAvailable < 0.1 && (
              <>
                <LimitedAvailabilityLabel>
                  Limited Availability
                </LimitedAvailabilityLabel>
              </>
            )}
          </PerformancePriceWrapper>
        )}
      </PerformanceDetailsWrapper>
      <PerformanceCTAWrapper>
        {!isSoldOut ? (
          <PerformanceCTA
            text='Book'
            onClick={handleBookNowClick}
            size={
              platform === 'kiosk' && isNarrowViewport ? undefined : 'small'
            }
            buttonType='sidebar'
          />
        ) : (
          <SoldOutWrapper>Sold Out</SoldOutWrapper>
        )}
      </PerformanceCTAWrapper>
    </PerformanceListItemWrapper>
  )
}

export const PerformanceListItem = React.memo(PerformanceListItemNM)
