// Components
import { Button, CopyBlock, Title } from 'marvel-components'

// Actions
import { closeModal } from 'modules/overlay/actions'

// Misc
import moment from 'moment'
import React from 'react'
import { useAppDispatch } from 'app/reduxStore'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'
import { media, kioskMainMenu, useDelay } from 'utils'

export type InactivityContainerProps = {
  redirectAfterSeconds?: number
}

const InactivityWrapper = styled.div`
  padding: 2rem;
  max-width: 23.75rem;

  ${media.tablet} {
    width: 70vw;
  }
`

const InactivityTitleWrapper = styled.span`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`

const StyledButton = styled(Button)`
  margin-top: 1rem;
`

const StyledLinkButton = styled(StyledButton)`
  justify-content: center;
`

const InactivityCopyWrapper = styled.div`
  padding: 1rem 0;
`

const KioskInactivityOverlayContainer: React.FC<InactivityContainerProps> = ({
  redirectAfterSeconds = 60
}) => {
  const dispatch = useAppDispatch()
  const history = useHistory()

  const handleCancelRedirect = () => {
    dispatch(closeModal())
  }

  const handleRedirect = () => {
    dispatch(closeModal())
    history.push(kioskMainMenu())
  }

  useDelay(() => {
    dispatch(closeModal())
    handleRedirect()
  }, moment.duration().add(redirectAfterSeconds, 'seconds'))

  return (
    <InactivityWrapper>
      <InactivityTitleWrapper>
        <Title size='large' isBold={true}>
          Are you still shopping?
        </Title>
      </InactivityTitleWrapper>
      <InactivityCopyWrapper>
        <CopyBlock isCentralised={true}>
          We’ll automatically take you back to the Main Menu in{' '}
          {redirectAfterSeconds} seconds unless you tell us you need more time.
        </CopyBlock>
      </InactivityCopyWrapper>
      <StyledButton
        text="I'm still shopping"
        onClick={handleCancelRedirect}
        isBlock={true}
      />
      <StyledLinkButton
        text='Back to main menu'
        onClick={handleRedirect}
        isBlock={true}
        buttonType='link-button'
        size='small'
      />
    </InactivityWrapper>
  )
}

export { KioskInactivityOverlayContainer }
