import React, { ReactNode } from 'react'
import styled from 'styled-components/macro'
import { media, usePlatform } from 'utils'

type Props = {
  children: ReactNode
  noPadding?: boolean
  platform?: string
}

const ListWrapper = styled.div<{
  noPadding: boolean
  platform?: string
}>`
  box-sizing: border-box;
  width: 100%;
  padding: ${props => (props.noPadding ? '0' : '0 2%')};
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  flex-wrap: wrap;
  ${media.tablet} {
    flex-direction: ${props => (props.platform === 'kiosk' ? 'row' : 'column')};
    padding: ${props => (props.noPadding ? '0' : '0 3%')};
  }
  ${media.phone} {
    flex-direction: column;
    padding: ${props => (props.noPadding ? '0' : '0 5%')};
    margin-bottom: 4.5rem;
  }
`

export const List = (props: Props) => {
  const { children, noPadding = false } = props
  const platform = usePlatform()
  return (
    <ListWrapper platform={platform} noPadding={noPadding}>
      {children}
    </ListWrapper>
  )
}
