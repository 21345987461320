import React from 'react'

// Components
import { ListItem } from 'marvel-components'

// Selectors
import { productSelectors } from 'modules/smart-waiter/product'

// Misc
import { useSelector } from 'react-redux'

// Types
import { AppState } from 'modules/types'

interface Props {
  id: EntityId
  onProductSelect: (productId: EntityId) => void
}

const ProductListItemContainer: React.FC<Props> = ({ id, onProductSelect }) => {
  const product = useSelector((state: AppState) =>
    productSelectors.selectById(state, id)
  )
  const handleProductClick = (productId: EntityId) => {
    if (product) {
      onProductSelect(productId)
    }
  }
  if (product) {
    return (
      <ListItem
        id={id}
        title={product.name}
        description={product.description}
        minimumCustomerAge={product.minimumCustomerAge}
        onClick={handleProductClick}
      />
    )
  }
  return <></>
}

export { ProductListItemContainer }
