import React from 'react'
import styled from 'styled-components'
import { media } from 'utils'

// Components
import { Input } from 'marvel-components'

// Misc
import { FormattedPrice } from 'utils'

interface Props {
  id: number
  onSelect: (id: number) => void
  onDeselect?: (id: number) => void
  isChecked: boolean
  name: string
  showSelect: boolean
  groupName: string
  type: 'radio' | 'checkbox'
  price?: number
  isDisabled?: boolean
  isNested?: boolean
  choicePrice?: boolean
}

const OptionListItemWrapper = styled.div<{
  isNested: boolean
  isDisabled: boolean
}>`
  font-family: ${props => props.theme.font.copy};
  display: flex;
  flex-direction: row;
  font-size: 1rem;
  justify-content: space-between;
  margin: 0 0 1.5rem 0;
  padding: ${props => (props.isNested ? '1.5rem 2% 0 2%' : '1.5rem 2% 0 2%')};
  border-top: 1px solid ${props => props.theme.colors.borderColor};
  color: ${props =>
    props.isDisabled
      ? props.theme.colors.secondaryColor
      : props.theme.colors.headingColor};
  &:first-child {
    border-top: none;
    padding: 0.5rem 2% 0 2%;
  }
  ${media.tablet} {
    &:first-child {
      padding: 0 3%;
    }
    margin: 1.75rem 0;
    padding: 0 3%;
    border-top: none;
  }
  ${media.phone} {
    &:first-child {
      padding: 0 5%;
    }
    margin: 1.75rem 0;
    padding: 0 5%;
    border-top: none;
  }
`

const UnavailableBanner = styled.span`
  margin-left: 0.25rem;
`

const OptionListItemDetails = styled.div`
  flex: display;
  flex-direction: row;
  align-items: center;
`

const OptionListItemLabel = styled.div`
  color: ${props => props.theme.colors.copyColor};
`

const StyledInput = styled(Input)`
  margin-right: 0.5rem;
  font-size: 2rem;
`

const ChoicePriceWrapper = styled.div`
  display: flex;
  flex-direction: row;
`

const OptionListItem: React.FC<Props> = ({
  id,
  groupName,
  type,
  onSelect,
  onDeselect,
  isChecked,
  name,
  price,
  showSelect,
  isDisabled = false,
  isNested = false,
  choicePrice = false
}) => {
  const handleChange: React.ChangeEventHandler<HTMLInputElement> = event => {
    if (!isDisabled) {
      isChecked
        ? onDeselect && onDeselect(parseInt(event.target.value))
        : onSelect(parseInt(event.target.value))
    }
  }
  return (
    <OptionListItemWrapper isDisabled={isDisabled} isNested={isNested}>
      <OptionListItemDetails>
        {showSelect && (
          <StyledInput
            type={type}
            name={groupName}
            value={id}
            checked={isChecked}
            onChange={handleChange}
          />
        )}
        {name}
        {isDisabled && <UnavailableBanner>- Unavailable</UnavailableBanner>}
      </OptionListItemDetails>
      <OptionListItemLabel>
        {!isDisabled && (
          <>
            {!!(price && price > 0) && (
              <>
                {choicePrice ? (
                  <ChoicePriceWrapper>
                    (+
                    <FormattedPrice
                      amount={price}
                      currency="GBP"
                      isFreeShown={false}
                    />
                    )
                  </ChoicePriceWrapper>
                ) : (
                  <FormattedPrice
                    amount={price}
                    currency="GBP"
                    isFreeShown={false}
                  />
                )}
              </>
            )}
          </>
        )}
      </OptionListItemLabel>
    </OptionListItemWrapper>
  )
}

export { OptionListItem }
