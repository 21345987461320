import React from 'react'
import styled from 'styled-components'

// Selectors
import { selectAllPriceLevels } from 'modules/ticketing/performance/selectors'

// Components
import { Title } from 'marvel-components'

// Containers
import { TicketItemContainer } from './TicketItemContainer'

// Misc
import { useTypedSelector } from 'modules'
import { media } from 'utils'

interface Props {
  title: string
  performanceId: string
  areaId: string
}

const TicketItemsWrapper = styled.div`
  margin-top: 1rem;
  ${media.tablet} {
    margin-top: 0.5rem;
  }
`

const TicketAreaContainer: React.FC<Props> = ({
  performanceId,
  title,
  areaId
}) => {
  const priceLevels = useTypedSelector(selectAllPriceLevels)

  return (
    <>
      <Title
        isCapitalised={false}
        isCentralised={false}
        isBold={true}
        size="large"
      >
        {title}
      </Title>
      <TicketItemsWrapper>
        {priceLevels?.map((priceLevel) =>
          priceLevel.priceTypes.map((priceType) => (
            <TicketItemContainer
              key={`${priceLevel.id}:${priceType.id}`}
              areaId={areaId}
              priceLevelId={priceLevel.id}
              priceTypeId={priceType.id}
              name={priceType.name}
              performanceId={performanceId}
              price={priceType.total}
              capacityAvailable={priceLevel.capacityAvailable}
            />
          ))
        )}
      </TicketItemsWrapper>
    </>
  )
}

export { TicketAreaContainer }
