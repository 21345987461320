// Misc
import smartWaiterApi from 'services/smartWaiterApi'
import { createAPIThunk } from 'modules/smart-waiter/shared/actions'

// Types
import { Venue, Service, ZImage } from 'shared-types'
import { venuesEntity } from 'services/schemas'

type AreasResponse = { id: number; name: string; zonalId: number }[]

type VenueResponse = {
  venues?: { [key: string]: Venue }
  services?: { [key: string]: Service }
  images?: { [key: string]: ZImage }
}

export const fetchVenues = createAPIThunk<VenueResponse>(
  'marvel/smart-waiter/FETCH_VENUES',
  () => smartWaiterApi.fetchAll('/sw/venue/'),
  venuesEntity
)

export const fetchAreasForSalesArea = createAPIThunk<AreasResponse, EntityId>(
  'marvel/smart-waiter/FETCH_AREAS',
  (salesAreaId: EntityId) =>
    smartWaiterApi.fetchAll(`/sw/area/?zonal_sales_area_id=${salesAreaId}`)
)

export const fetchAreasForVenue = createAPIThunk<AreasResponse, EntityId>(
  'marvel/smart-waiter/FETCH_VENUE_AREAS',
  (venueId: EntityId) =>
    smartWaiterApi.fetchAll(`/sw/area/?zonal_venue_id=${venueId}`)
)
