import React, { useEffect } from 'react'

// Selectors
import { productSelectors } from 'modules/smart-waiter/product'
import { basketSelectors } from 'modules/smart-waiter/basket'

// Actions
import { basketActions } from 'modules/smart-waiter/basket'

// Components
import { Divider, OptionListItem } from 'marvel-components'

// Misc
import { useSelector, useDispatch } from 'react-redux'
import styled from 'styled-components'

// Types
import { AppState } from 'modules/types'

interface Props {
  productId: number
  isNested?: boolean
}

const OptionListGroupWrapper = styled.div`
  margin: 0 0 1rem 0;
`

const ProductChoicesContainer: React.FC<Props> = ({
  productId,
  isNested = false
}) => {
  const dispatch = useDispatch()

  // Selectors
  const product = useSelector((state: AppState) =>
    productSelectors.selectById(state, productId)
  )
  const choices = useSelector(
    productSelectors.selectProductsForChoiceId(productId)
  )
  const selectedProducts = useSelector(
    basketSelectors.selectSelectedProductsForChoiceId(productId)
  )

  // Set initial choices
  useEffect(() => {
    if (product && choices.length > 0) {
      const defaultChoices = choices.filter(
        (choice) => product.defaults && product.defaults.includes(choice.eposId)
      )
      defaultChoices.forEach((choice) =>
        dispatch(
          basketActions.addChoice({
            id: choice.id,
            parentId: productId,
            eposId: choice.eposId,
            price: choice.portions[0].price,
            portionId: choice.portions[0].id,
            quantity: 1
          })
        )
      )
    }
  }, [product, dispatch, choices, productId])

  const handleAddChoice = (id: number) => {
    const choice = choices.find((c) => c.id === id)
    if (choice && product) {
      if (product.maximum === 1) {
        dispatch(
          basketActions.setChoice({
            id: choice.id,
            parentId: productId,
            eposId: choice.eposId,
            price: choice.portions[0].price,
            portionId: choice.portions[0].id,
            quantity: 1
          })
        )
      } else {
        dispatch(
          basketActions.addChoice({
            id: choice.id,
            parentId: productId,
            eposId: choice.eposId,
            price: choice.portions[0].price,
            portionId: choice.portions[0].id,
            quantity: 1
          })
        )
      }
    }
  }

  const handleRemoveChoice = (id: number) => {
    product &&
      dispatch(
        basketActions.removeChoice({
          parentId: productId,
          id
        })
      )
  }

  if (product) {
    return (
      <OptionListGroupWrapper>
        <Divider>{product.name}</Divider>
        {choices &&
          choices.map((option) => (
            <OptionListItem
              key={option.id}
              groupName={`choice-${product.id}`}
              type={
                product.maximum && product.maximum === 1 ? 'radio' : 'checkbox'
              }
              showSelect={true}
              id={option.id}
              name={option.name}
              isChecked={
                !!selectedProducts.find(
                  (selectedProduct) => selectedProduct.id === option.id
                )
              }
              onSelect={handleAddChoice}
              onDeselect={handleRemoveChoice}
              price={option.portions && option.portions[0].price}
              isNested={isNested}
              choicePrice={true}
            />
          ))}
        {selectedProducts.map(
          (selectedProduct) =>
            selectedProduct.portions &&
            selectedProduct.portions[0].choices.map((id) => (
              <ProductChoicesContainer
                isNested={true}
                key={id}
                productId={id}
              />
            ))
        )}
      </OptionListGroupWrapper>
    )
  }
  return <></>
}
export { ProductChoicesContainer }
