import React, { useContext } from 'react'
import { ThemeContext } from 'styled-components/macro'

// Selectors
import { venueSelectors } from 'modules/smart-waiter/venue'

// Components
import { BrandLogo, MainMenuNavButton } from 'marvel-components'

// Misc
import { usePlatform } from 'utils'
import { useSelector } from 'react-redux'

const BrandLogoContainer: React.FC = () => {
  const platform = usePlatform()
  const theme = useContext(ThemeContext)
  const siteId = useSelector(venueSelectors.selectSelectedVenueId)
  return (
    <BrandLogo
      address={theme.themeVenueAddress}
      venue={theme.themeVenueName}
      logoName={theme.themeVenueLogo}
      backgroundColor={theme.colors.venueLogoBackgroundColor}
      lwLogoLink={`/smart-waiter/venue/${siteId}`}
      showLogoLink={`/smart-waiter/venue/${siteId}`}
      renderNavButton={
        platform === 'kiosk' ? () => <MainMenuNavButton /> : undefined
      }
    />
  )
}

export { BrandLogoContainer }
