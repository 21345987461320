import { combineReducers } from 'redux'
import moment from 'moment'
import * as actions from './actions'
import { RoutineAction } from 'modules/shared'
import { SeatMapState } from './types'

const initialState: SeatMapState = {
  byId: null,
  loading: false,
  lastFetch: null
}

const byId = (state = initialState.byId, action: RoutineAction) => {
  switch (action.type) {
    case actions.fetchSeatMap.TRIGGER:
    case actions.fetchSeatMap.FAILURE:
      return state
    case actions.fetchSeatMap.SUCCESS:
      return action.payload.response.result as string
    default:
      return state
  }
}

const loading = (state = initialState.loading, action: RoutineAction) => {
  switch (action.type) {
    case actions.fetchSeatMap.TRIGGER:
      return true
    case actions.fetchSeatMap.SUCCESS:
    case actions.fetchSeatMap.FAILURE:
    case actions.fetchSeatMap.FULFILL:
      return false
    default:
      return state
  }
}

const lastFetch = (state = initialState.lastFetch, action: RoutineAction) => {
  switch (action.type) {
    case actions.fetchSeatMap.SUCCESS:
      return moment().utc().format()
    default:
      return state
  }
}

export default combineReducers({
  byId,
  loading,
  lastFetch
})
