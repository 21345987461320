import React from 'react'

// Components
import { PrimaryButton, StickyFooter } from 'marvel-components'

// Selectors
import { basketSelectors } from 'modules/smart-waiter/basket'

// Misc

import { useSelector } from 'react-redux'

interface Props {
  // CTA Button
  ctaText: string
  onCheckoutClick: () => void
}

const CheckoutButtonContainer: React.FC<Props> = ({
  ctaText,
  onCheckoutClick
}) => {
  // Selectors
  const isBasketEmpty = useSelector(basketSelectors.selectIsBasketEmpty)
  const basketTotal = useSelector(basketSelectors.selectBasketTotal)

  return (
    <StickyFooter>
      <PrimaryButton
        amount={basketTotal}
        isDisabled={isBasketEmpty}
        onClick={onCheckoutClick}
        text={ctaText}
        icon={'lock'}
      />
    </StickyFooter>
  )
}
export { CheckoutButtonContainer }
