import { useAppDispatch } from 'app/reduxStore'
import { useEffect } from 'react'

// Components
import {
  FormManageAuthentication,
  ManageAuthenticationFormValues
} from 'marvel-components/Form/FormManageAuthentication'

// Actions
import { createOrderToken } from 'modules/basket/actions'

// Selectors
import { useSelector } from 'react-redux'
import { basketActions, basketSelectors } from 'modules/basket'
import { bannerActions } from 'modules/banner'

// Misc
import styled from 'styled-components/macro'
import { Redirect } from 'react-router-dom'

const AuthenticationWrapper = styled.div`
  flex-grow: 1;
  padding: 0 1rem 0 1rem;
  max-width: 30rem;
  display: flex;
  align-items: center;
`

type Props = {
  transactionGuid?: string
}
const ManageAuthenticationContainer = ({ transactionGuid }: Props) => {
  const dispatch = useAppDispatch()

  const loading = useSelector(basketSelectors.selectBasketLoading)
  const loaded = useSelector(basketSelectors.selectBasketLoaded)
  const token = useSelector(basketSelectors.selectOrderToken)
  const orderStatus = useSelector(basketSelectors.selectOrderStatus)

  const handleSubmit = (values: ManageAuthenticationFormValues) => {
    dispatch(createOrderToken(values))
  }

  useEffect(() => {
    if (loaded && !token) {
      dispatch(
        bannerActions.setBannerContent({
          bannerType: 'error',
          title: 'Please Note',
          text:
            'Unable to locate booking with provided details, please check and try again'
        })
      )
    }

    if (orderStatus === 'failure') {
      dispatch(
        bannerActions.setBannerContent({
          bannerType: 'error',
          title: 'Please Note',
          text: 'Unable to fetch order details, please try again later'
        })
      )
    }
  }, [dispatch, loaded, token, orderStatus])

  useEffect(() => {
    if (token) {
      dispatch(basketActions.fetchOrderFromToken({ token }))
    }
  }, [dispatch, token])

  if (token && orderStatus === 'success') {
    return <Redirect to={`/manage-order/${token}`} />
  }

  return (
    <AuthenticationWrapper>
      <FormManageAuthentication
        transactionGuid={transactionGuid}
        onSubmit={handleSubmit}
        isSubmitting={loading || orderStatus === 'loading'}
      />
    </AuthenticationWrapper>
  )
}

export { ManageAuthenticationContainer }
