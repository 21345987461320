import React, { useState, useEffect } from 'react'

// Components
import { BookingProtect } from 'marvel-components'

// Selectors
import { basketSelectors } from 'modules/basket'

// Misc
import { useSelector, useDispatch } from 'react-redux'
import { updateReservation } from 'modules/basket/actions'
import { useParams } from 'react-router-dom'

const BookingProtectContainer: React.FC = () => {
  const { eventId } = useParams<{ eventId: string }>()

  // Actions
  const dispatch = useDispatch()

  // Selectors
  const bookingProtectTotal = useSelector(
    basketSelectors.selectBookingProtectTotal
  )
  const bookingProtectOnReservation = useSelector(
    basketSelectors.selectIsBookingProtectActive
  )
  const reservationGuid = useSelector(basketSelectors.selectReservationGuid)

  // State
  const [bookingProtectActive, setBookingProtectActive] = useState(false)
  const [bookingProtectConfirmed, setBookingProtectConfirmed] = useState(false)

  // Actions
  const handleBookingProtectSelectedAction = (status: boolean) => {
    setBookingProtectActive(status)
  }
  const handleBookingProtectConfirmedAction = (status: boolean) => {
    setBookingProtectConfirmed(status)
  }

  useEffect(() => {
    if (
      (bookingProtectActive && bookingProtectConfirmed) !==
      bookingProtectOnReservation
    ) {
      if (reservationGuid) {
        dispatch(
          updateReservation({
            transactionGuid: reservationGuid,
            basket: {
              bookingProtectActive:
                bookingProtectActive && bookingProtectConfirmed
            },
            eventId
          })
        )
      }
    }
  }, [
    bookingProtectActive,
    bookingProtectConfirmed,
    bookingProtectOnReservation,
    reservationGuid,
    eventId,
    dispatch
  ])

  useEffect(() => {
    if (bookingProtectOnReservation) {
      setBookingProtectActive(true)
      setBookingProtectConfirmed(true)
    }
  }, [bookingProtectOnReservation, dispatch])

  return (
    <BookingProtect
      bookingProtectTotal={bookingProtectTotal}
      isBookingProtectSelected={bookingProtectActive}
      isBookingProtectConfirmed={bookingProtectConfirmed}
      onBookingProtectSelected={handleBookingProtectSelectedAction}
      onBookingProtectConfirmed={handleBookingProtectConfirmedAction}
    />
  )
}

export { BookingProtectContainer }
