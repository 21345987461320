import React from 'react'
import { useTypedSelector } from 'modules'

// Selectors
import { getRow } from 'modules/seat-map/selectors'

// Components
import { SeatContainer } from './SeatContainer'

interface Props {
  rowId: string
  performanceId: string
  sectorAngle: number
  toolTipTarget: any
}

const RowContainerNM: React.FC<Props> = ({
  rowId,
  performanceId,
  toolTipTarget,
  sectorAngle
}) => {
  const row = useTypedSelector((state) => getRow(state, rowId))
  return (
    <>
      {row?.seats.map((seatId) => (
        <SeatContainer
          key={seatId}
          seatId={seatId}
          sectorAngle={sectorAngle}
          performanceId={performanceId}
          toolTipTarget={toolTipTarget}
        />
      ))}
    </>
  )
}

export const RowContainer = React.memo(RowContainerNM)
