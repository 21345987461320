import { Duration } from 'moment'
import { useEffect } from 'react'

export const useDelay = (
  callback: () => void,
  delayFor: Duration,
  dependencies: any[] = []
) => {
  useEffect(() => {
    const timeout = setTimeout(callback, delayFor.asMilliseconds())
    return () => clearTimeout(timeout)
  }, [delayFor, callback, ...dependencies])
}
