import { AppState } from 'modules/types'
import { createSelector } from 'reselect'
import { optinAdapter } from './optinSlice'

const optinAdapterSelectors = optinAdapter.getSelectors()

export const selectOptinState = (state: AppState) => state.hospitality.optin

export const selectOptins = createSelector(
  selectOptinState,
  optinAdapterSelectors.selectAll
)
