// Actions
import { kioskActions } from 'modules/self-serve/kiosk/kioskSlice'

// Components
import {
  FormKioskAuthentication,
  KioskFormValues
} from 'marvel-components/Form/FormKioskAuthentication/FormKioskAuthentication'

// Selectors
import {
  selectKioskAccessToken,
  selectKiosks,
  selectKioskStatus
} from 'modules/self-serve/kiosk/selectors'
import { selectAvailableTerminals } from 'modules/self-serve/terminal/selectors'

// Misc
import { useAppDispatch } from 'app/reduxStore'
import { useHistory } from 'react-router'
import { useSelector } from 'react-redux'
import { useEffect, useState } from 'react'
import styled from 'styled-components/macro'

const AuthenticationWrapper = styled.div`
  flex-grow: 1;
  padding: 0 3rem 0 3rem;
  max-width: 30rem;
`

const AuthenticationContainer = () => {
  const dispatch = useAppDispatch()
  const history = useHistory()
  const status = useSelector(selectKioskStatus)
  const accessToken = useSelector(selectKioskAccessToken)

  const kiosks = useSelector(selectKiosks)
  const terminals = useSelector(selectAvailableTerminals)

  const [submittingCallback, setSubmittingCallback] = useState<
    ((isSubmitting: boolean) => void) | null
  >(null)

  useEffect(() => {
    if (status === 'fulfilled' && accessToken) {
      history.push({
        pathname: '/kiosk/menu',
        search: history.location.search
      })
    }

    if (submittingCallback && status !== 'pending') {
      submittingCallback(false)
    }
  }, [history, status, accessToken, submittingCallback])

  const handleKioskSelect = (id: number) => {
    dispatch(kioskActions.selectKiosk(id))
  }

  const handleSubmit = (
    values: KioskFormValues,
    setSubmiting: (isSubmitting: boolean) => void
  ) => {
    setSubmiting(true)
    setSubmittingCallback(setSubmiting)
    values.kiosk &&
      values.terminal &&
      dispatch(
        kioskActions.authenticateKiosk({
          kioskId: values.kiosk.value,
          secret: values.secret,
          terminalId: values.terminal.value
        })
      )
  }

  return (
    <AuthenticationWrapper>
      <FormKioskAuthentication
        onSubmit={handleSubmit}
        onKioskSelect={handleKioskSelect}
        kiosks={kiosks}
        terminals={terminals}
      />
    </AuthenticationWrapper>
  )
}

export { AuthenticationContainer }
