import React, { useContext } from 'react'

// Selectors
import { getSeat, getViewFromSeatUrl } from 'modules/seat-map/selectors'
import {
  selectActivePerformanceDate,
  selectIsPriceLevelSelected
} from 'modules/ticketing/performance/selectors'
import { availabilitySelectors } from 'modules/ticketing/availability/availabilitySlice'
import {
  selectSeatTypeById,
  selectPriceLevelById
} from 'modules/ticketing/performance/selectors'
import {
  selectBookingFlow,
  selectIsSeatInBasket
} from 'modules/basket/selectors'
import { eventDetailsSelectors } from 'modules/ticketing/event'

// Actions
import {
  addSeat,
  addSeatBlock,
  removeSeat,
  removeSeatBlock
} from 'modules/basket/actions'

// Components
import { SeatMapSeat } from 'marvel-components'

// Misc
import { useDispatch, useSelector } from 'react-redux'
import { useTypedSelector, useShallowEqualSelector } from 'modules'
import { ThemeContext } from 'styled-components'
import moment from 'moment'

// Types
import { TicketWithSeat } from 'shared-types'
import { openModal } from 'modules/overlay/actions'
import {
  trackAddToBasketTicket,
  trackRemoveFromBasketTicket,
  usePlatform
} from 'utils'

type Props = {
  seatId: string
  performanceId: string
  sectorAngle: number
  toolTipTarget: any
}

const SeatContainerNM: React.FC<Props> = ({
  seatId,
  performanceId,
  sectorAngle,
  toolTipTarget
}) => {
  // Dispatch
  const dispatch = useDispatch()

  // Context
  const theme = useContext(ThemeContext)

  const platform = usePlatform()

  // Selectors
  const seat = useShallowEqualSelector(state => getSeat(state, seatId))

  const isAvailable = useTypedSelector(state =>
    availabilitySelectors.selectIsAvailable(state, seatId)
  )
  const isSelected = useTypedSelector(
    state => seat && selectIsSeatInBasket(state, seat.id, seat.areaId)
  )
  const seatType = useTypedSelector(
    state => seat && selectSeatTypeById(state, seat.seatTypeGuid)
  )
  const priceLevel = useTypedSelector(
    state => seat && selectPriceLevelById(state, seat.priceLevelGuid)
  )
  const isPriceLevelDisabled = useTypedSelector(
    state => seat && selectIsPriceLevelSelected(state, seat.priceLevelGuid)
  )
  const imageUrl = useTypedSelector(state => getViewFromSeatUrl(state, seatId))

  const bookingFlow = useSelector(selectBookingFlow)

  const eventName = useSelector(eventDetailsSelectors.selectEventName)
  const eventId = useSelector(eventDetailsSelectors.selectEventDetailsId)
  const performanceDate = useSelector(selectActivePerformanceDate)

  // Actions
  const selectSeat = (ticket: TicketWithSeat) => {
    theme.sociallyDistanced
      ? dispatch(addSeatBlock(ticket))
      : dispatch(addSeat(ticket))
  }
  const deselectSeat = (ticket: TicketWithSeat) => {
    theme.sociallyDistanced
      ? dispatch(removeSeatBlock(ticket))
      : dispatch(removeSeat(ticket))
  }

  const handleSeatInteraction = (isTouch: boolean) => {
    if (priceLevel && isAvailable && seat) {
      const ticket = {
        id: seat.id,
        areaId: seat.areaId,
        performanceId: performanceId,
        priceLevelId: priceLevel.id,
        priceTypeId: priceLevel.priceTypes[0].id
      }

      const date = moment.utc(performanceDate)
      if (!isSelected) {
        trackAddToBasketTicket({
          id: eventId,
          name: eventName,
          price: priceLevel.priceTypes[0].total,
          performanceDate: date.format('YYYY-MM-DD'),
          performanceTime: date.format('H-mm'),
          platform,
          bookingFlow
        })

        if (
          priceLevel.priceTypes.length > 1 ||
          (isTouch && (seatType?.note || imageUrl))
        ) {
          dispatch(
            openModal({
              variant: 'seat',
              props: {
                seatId: seatId
              }
            })
          )
        } else {
          selectSeat(ticket)
        }
      } else {
        deselectSeat(ticket)
        trackRemoveFromBasketTicket({
          id: eventId,
          name: eventName,
          price: priceLevel.priceTypes[0].total,
          performanceDate: date.format('YYYY-MM-DD'),
          performanceTime: date.format('H-mm'),
          platform,
          bookingFlow
        })
      }
    }
  }

  const handleOnClick = () => {
    handleSeatInteraction(false)
  }

  const handleOnTouch = () => {
    handleSeatInteraction(true)
  }

  return (
    <>
      {seat && (
        <SeatMapSeat
          id={seat.id}
          areaId={seat.areaId}
          x={seat.x}
          y={seat.y}
          height={seat.height}
          width={seat.width}
          angle={seat.angle}
          sectorAngle={sectorAngle}
          hidden={seat.hidden}
          seatType={seatType}
          isDisabled={!isAvailable}
          isSelected={isSelected}
          isPriceLevelDisabled={isPriceLevelDisabled}
          background={priceLevel?.background}
          onClick={handleOnClick}
          onTouch={handleOnTouch}
          toolTipTarget={toolTipTarget}
        />
      )}
    </>
  )
}

export const SeatContainer = React.memo(SeatContainerNM)
