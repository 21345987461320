import backgroundAsset from './assets/bttf_sidebar.jpg'
export const BackToTheFuture = {
  colors: {
    mainBackgroundColor: '#192C8B',
    bestAvailablePerformanceColor: '#dd332d',
    bestAvailablePerformanceColorComplement: '#ffffff',
    mainColor: '#dd332d',
    fadedHighlightColor: '#f1f1f1',
    mainColorComplement: '#ffffff',
    lwLogoSlashColor: '#ffffff',
    lwBrandColor: '#ffffff',
    lwBrandColorComplement: '#111111',
    numberFilterSelectorColor: '#ffffff',
    numberFilterSelectorCopyColor: '#111111',
    priceSliderHandleColor: '#dd332d',
    bestAvailableBannerColor: '#ffd500',
    bestAvailableBannerColorComplement: '#111',
    sidebarCopyColor: '#ffffff',
    gradientTextColor: '#ffffff',
    performanceHoverColor: '#b8c3ff'
  },
  themeBackgroundColour: '#192C8B',
  themeBackgroundAsset: backgroundAsset,
  orderSuccess: {
    showHospitality: true,
    showCecilBeatonBar: true
  }
}
