import basketReducer from './reducers'
import { History } from 'history'
// Selected Seats
// ----------------------------
export const ADD_SEAT = 'marvel/basket/ADD_SEAT'
export const ADD_SEAT_BLOCK = 'marvel/basket/ADD_SEAT_BLOCK'
export const REMOVE_SEAT = 'marvel/basket/REMOVE_SEAT'
export const REMOVE_SEAT_BLOCK = 'marvel/basket/REMOVE_SEAT_BLOCK'

export const CLEAR_SEATS = 'marvel/basket/CLEAR_SEATS'
export const SET_SEATS = 'marvel/basket/SET_SEATS'
export const ACCEPT_SEATS = 'marvel/basket/ACCEPT_SEATS'
export const ACCEPT_SEAT_RESTRICTION = 'marvel/basket/ACCEPT_SEAT_RESTRICTION'
export const ADD_GIFT_VOUCHER_TO_ORDER =
  'marvel/basket/ADD_GIFT_VOUCHER_TO_ORDER'
export const FETCH_RESERVATION = 'FETCH_RESERVATION'

// GA Tickets
export const ADD_TICKET = 'marvel/basket/ADD_TICKET'
export const REMOVE_TICKET = 'marvel/basket/REMOVE_TICKET'
export const REMOVE_TICKET_LINE_ITEM = 'marvel/basket/REMOVE_TICKET_LINE_ITEM'
// Best available
// ----------------------------
export const INCREMENT_BEST_AVAILABLE = 'marvel/basket/INCREMENT_BEST_AVAILABLE'
export const DECREMENT_BEST_AVAILABLE = 'marvel/basket/DECREMENT_BEST_AVAILABLE'

export const CLEAR_BEST_AVAILABLE = 'marvel/basket/CLEAR_BEST_AVAILABLE'

// Selected Products
// ----------------------------
export const ADD_PRODUCT = 'marvel/basket/ADD_PRODUCT'
export const REMOVE_PRODUCT = 'marvel/basket/REMOVE_PRODUCT'
export const REMOVE_PRODUCT_LINE_ITEM = 'marvel/basket/REMOVE_PRODUCT_LINE_ITEM'
export const SET_PRODUCTS = 'marvel/basket/SET_PRODUCTS'
export const CLEAR_PRODUCTS = 'marvel/basket/CLEAR_PRODUCTS'

// Reservation
// ----------------------------
export const TRIGGER_RESERVATION = 'marvel/basket/TRIGGER_RESERVATION'
export const CREATE_RESERVATION = 'marvel/basket/CREATE_RESERVATION'
export const UPDATE_RESERVATION = 'marvel/basket/UPDATE_RESERVATION'
export const CLEAR_RESERVATION_ON_SERVER =
  'marvel/basket/CLEAR_RESERVATION_ON_SERVER'
export const CLEAR_RESERVATION = 'marvel/basket/CLEAR_RESERVATION'

export const EXPIRE_REDIRECT = 'marvel/basket/EXPIRE_REDIRECT'
export const CREATE_PRODUCT_RESERVATION =
  'marvel/basket/CREATE_PRODUCT_RESERVATION'

// Order
// ----------------------------
export const FETCH_ORDER = 'marvel/basket/FETCH_ORDER'
export const FETCH_ORDER_FROM_TOKEN = 'marvel/basket/FETCH_ORDER_FROM_TOKEN'
export const CREATE_ORDER = 'marvel/basket/CREATE_ORDER'
export const CREATE_ORDER_TOKEN = 'marvel/basket/CREATE_ORDER_TOKEN'
export const POLL_FOR_ORDER_UPDATE = 'marvel/basket/POLL_FOR_ORDER_UPDATE'
export const STOP_POLL_FOR_ORDER_UPDATE =
  'marvel/basket/STOP_POLL_FOR_ORDER_UPDATE'

// Checkout
// ----------------------------
export const UPDATE_PAYMENT_DETAILS = 'marvel/basket/UPDATE_PAYMENT_DETAILS'
export const CLEAR_PAYMENT_DETAILS = 'marvel/basket/CLEAR_PAYMENT_DETAILS'

export const CREATE_EXCHANGE = 'marvel/basket/CREATE_EXCHANGE'

// External Customer Id
// ----------------------------
export const SET_EXTERNAL_CUSTOMER_ID = 'marvel/basket/SET_EXTERNAL_CUSTOMER_ID'

// Booking Flow
export const SET_BOOKING_FLOW = 'marvel/basket/SET_BOOKING_FLOW'

// Reservation
// ----------------------------

export interface BestAvailablePayload {
  performanceId: string
  quantity: number
}

export interface TriggerReservationPayload {
  performanceId?: string
  onSuccessCallback?: () => void
  makeReservation: boolean
}

export interface TriggerReservationAction {
  type: typeof TRIGGER_RESERVATION
  payload: TriggerReservationPayload
}

// Transaction
// ----------------------------

export interface ExpireRedirectPayload {
  history: History
  eventId: string
}

export interface ExpireRedirectAction {
  type: typeof EXPIRE_REDIRECT
  payload: ExpireRedirectPayload
}

// External Session
// ----------------------------

export type ExternalCustomerIdAction = {
  type: typeof SET_EXTERNAL_CUSTOMER_ID
  payload: string
}

export type BasketState = ReturnType<typeof basketReducer>
