import moment from 'moment'
import { Moment } from 'moment'
import { useState } from 'react'
import styled from 'styled-components'
import { useInterval } from 'utils/useInterval'
import { media } from 'utils'

const DaySeatsCountdownWrapper = styled.div`
  font-size: 2.5rem;
  font-family: ${props => props.theme.font.header};
  color: ${props => props.theme.colors.sidebarCopyColor};
  ${media.phone} {
    font-size: 1.5rem;
  }
`
const StyledCopyBlockText = styled.p`
  color: ${props => props.theme.colors.sidebarCopyColor};
`

type Props = {
  expiryDate: Moment
  onExpiry: () => void
  title: string
}

const DaySeatsCountdown: React.FC<Props> = ({
  expiryDate,
  onExpiry,
  title
}) => {
  const [time, setTime] = useState<Moment>(moment().utc())
  const [isExpired, setIsExpired] = useState<boolean>(false)
  const [dateDiff, setDateDiff] = useState(
    moment(expiryDate.diff(moment().utc()))
  )
  const [hourDiff, setHourDiff] = useState(
    expiryDate.diff(moment().utc(), 'hours')
  )
  const [dayDiff, setDayDiff] = useState(
    expiryDate.diff(moment().utc(), 'days')
  )

  useInterval(
    () => {
      const now = moment().utc()
      setTime(now)
      setDateDiff(moment(expiryDate.diff(now)))
      setHourDiff(expiryDate.diff(now, 'hours'))
      setDayDiff(expiryDate.diff(now, 'days'))
      if (time.isAfter(expiryDate) && !isExpired) {
        setIsExpired(true)
        onExpiry()
      }
    },
    moment.duration().add(1, 'second'),
    [setTime, setIsExpired, expiryDate, isExpired]
  )

  return (
    <>
      <StyledCopyBlockText>
        {dayDiff > 0
          ? `Access to ${title} opens on:`
          : `Access to ${title} opens in:`}
      </StyledCopyBlockText>
      <DaySeatsCountdownWrapper>
        {time.isBefore(expiryDate) && (
          <>
            {dayDiff > 0 ? (
              <>
                {`${expiryDate
                  .local()
                  .format('dddd Do MMM YYYY')} at ${expiryDate.format(
                  'HH:mma'
                )}`}
              </>
            ) : (
              <>
                {hourDiff > 0 && `${String(hourDiff).padStart(1, '0')} hours `}
                {dateDiff.format('mm [mins] ss [secs]')}
              </>
            )}
          </>
        )}
      </DaySeatsCountdownWrapper>
    </>
  )
}

export { DaySeatsCountdown }
