import interactionReducer from './reducers'
import { PerformanceFilter } from 'shared-types'

export const SET_PERFORMANCE_FILTER =
  'marvel/interaction/SET_PERFORMANCE_FILTER'
export const SET_TOTAL_REQUESTED_SEATS =
  'marvel/interaction/SET_TOTAL_REQUESTED_SEATS'
export const SELECT_PRICE_LEVEL = 'marvel/interaction/SELECT_PRICE_LEVEL'
export const SELECT_PRICE_LEVELS = 'marvel/interaction/SELECT_PRICE_LEVELS'
export const DESELECT_PRICE_LEVEL = 'marvel/interaction/DESELECT_PRICE_LEVEL'
export const SET_PRICE_PERFORMANCE_FILTER =
  'marvel/interaction/SET_PRICE_PERFORMANCE_FILTER'

export interface SetPerformanceFilterAction {
  type: typeof SET_PERFORMANCE_FILTER
  payload: PerformanceFilter[]
}

export interface RequestedSeatPayload {
  total: number
}

export interface SetTotalRequestedSeatsAction {
  type: typeof SET_TOTAL_REQUESTED_SEATS
  payload: RequestedSeatPayload
}

export type InteractionState = ReturnType<typeof interactionReducer>

export interface PriceLevelPayload {
  priceLevelId: string
}

export interface SelectPriceLevelAction {
  type: typeof SELECT_PRICE_LEVEL
  payload: PriceLevelPayload
}

export interface DeselectPriceLevelAction {
  type: typeof DESELECT_PRICE_LEVEL
  payload: PriceLevelPayload
}

export interface SetPriceLevelsPayload {
  priceLevelIds: string[]
}

export interface SetPriceLevelsAction {
  type: typeof SELECT_PRICE_LEVELS
  payload: SetPriceLevelsPayload
}

export type PriceLevelActions =
  | SetPriceLevelsAction
  | SelectPriceLevelAction
  | DeselectPriceLevelAction

export interface SetPricePerformanceAction {
  type: typeof SET_PRICE_PERFORMANCE_FILTER
  payload: {
    upperValue: number
    lowerValue: number
  }
}
