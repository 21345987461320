// Components
import { Layout, WithLoader } from 'marvel-components'

// Containers
import {
  BrandLogoContainer,
  OrderSummaryContainer,
  BasketContainer,
  FooterNavContainer,
  ManageContainer,
  ManageContactDetailsContainer
} from 'containers/Ticketing'

// Misc
import React, { useRef, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useAppDispatch } from 'app/reduxStore'
import { Redirect, useParams } from 'react-router-dom'

// Actions
import { faqActions, faqSelectors } from 'modules/ticketing/manage-orders'
import { basketActions, basketSelectors } from 'modules/basket'
import { eventDetailsActions } from 'modules/ticketing/event'

const ManageLayout = WithLoader(Layout)

const ManageOrderView: React.FC = () => {
  const dispatch = useAppDispatch()
  const faqRef = useRef<HTMLSpanElement>(null)
  const { orderToken: token } = useParams<{ orderToken: string }>()

  // Selectors
  const order = useSelector(basketSelectors.selectOrder)
  const faqStatus = useSelector(faqSelectors.selectFaqStatus)
  const orderStatus = useSelector(basketSelectors.selectOrderStatus)

  // Actions
  useEffect(() => {
    dispatch(faqActions.fetchFaq())
  }, [dispatch])

  useEffect(() => {
    if (!order && token && orderStatus === 'idle') {
      dispatch(basketActions.fetchOrderFromToken({ token }))
    }
  }, [dispatch, token, order, orderStatus])

  useEffect(() => {
    if (order && order.basket && order.basket.ticketReservations.length > 0) {
      dispatch(
        eventDetailsActions.fetchEventDetails({
          eventId: order.basket.ticketReservations[0].eventId
        })
      )
    }
  }, [dispatch, order])

  if (orderStatus === 'failure') {
    return <Redirect to='/order/manage' />
  }

  return (
    <ManageLayout
      isLoading={faqStatus === 'idle' || faqStatus === 'pending'}
      renderBrandLogo={<BrandLogoContainer />}
      renderSidebarTop={<OrderSummaryContainer />}
      renderMainTop={<ManageContactDetailsContainer faqRef={faqRef} />}
      renderMain={<ManageContainer faqRef={faqRef} />}
      renderSidebarBottom={
        <BasketContainer ctaText='Continue' ctaIcon='lock' />
      }
      renderFooter={<FooterNavContainer />}
    />
  )
}

export { ManageOrderView }
