import React from 'react'
import styled from 'styled-components/macro'
import { Link } from 'react-router-dom'

//Components
import { Icon } from 'marvel-components'

type Props = {
  name: string
  to: string
  isLast?: boolean
  current?: boolean
}

const BreadcrumbNavLinkWrapper = styled.span`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-right: 0.125rem;
`

const BreadcrumbNavLinkItem = styled(Link)<{ $isCurrent: boolean }>`
  color: ${(props) =>
    props.$isCurrent
      ? props.theme.colors.headingColor
      : props.theme.colors.secondaryColor};
  font-family: ${(props) => props.theme.font.copy};
  font-size: 0.75rem;
  text-decoration: none;
  margin-right: 0.125rem;
  &:hover {
    color: ${(props) =>
      props.$isCurrent
        ? props.theme.colors.headingColor
        : props.theme.colors.copyColor};
    text-decoration: underline;
  }
`

const BreadcrumbNavIconWrapper = styled.span`
  fill: ${(props) => props.theme.colors.performanceHoverColor};
  line-height: 0;
`

export const BreadcrumbNavLink: React.FC<Props> = ({
  name,
  to,
  isLast = false,
  current = false
}) => {
  return (
    <BreadcrumbNavLinkWrapper>
      <BreadcrumbNavLinkItem $isCurrent={current} to={to}>
        {name}
      </BreadcrumbNavLinkItem>
      {!isLast && (
        <BreadcrumbNavIconWrapper>
          <Icon icon={'chevron-right'} size="xs" />
        </BreadcrumbNavIconWrapper>
      )}
    </BreadcrumbNavLinkWrapper>
  )
}
