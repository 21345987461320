import React from 'react'

// Components
import { Button } from 'marvel-components'

// Misc
import styled, { css } from 'styled-components/macro'
import { media, FormattedPrice, usePlatform, useIsNarrowViewport } from 'utils'

import { IconName } from 'shared-types'

interface Props {
  text: string
  onClick: () => void
  icon?: IconName
  amount?: number
  isDisabled?: boolean
}

const BookNowWrapper = styled.div<{
  isDisabled: boolean
}>`
  padding: 1rem 1rem 2rem 1rem;
  position: relative;
  ${props => props.isDisabled && `visibility: hidden;`}
  ${media.phone} {
    position: fixed;
    left: 0;
    bottom: 0;
    right: 0;
    border-top: 1px solid #fff;
    padding: 1rem 1rem 1.5rem 1rem;
    background: rgba(0, 0, 0, 0.8);
    z-index: 3;
  }
`

const StyledFormattedPriceWrapper = styled.div`
  margin-left: 0.4rem;
  display: block;
`
const ButtonTextWrapper = styled.div<{ showAmount: boolean }>`
  margin-left: 0.4rem;
  ${props =>
    props.showAmount &&
    css`
      :before {
        content: '-';
        margin: 0 0.4rem 0 0;
      }
    `}
`

const PrimaryButton: React.FC<Props> = ({
  amount,
  isDisabled = false,
  text,
  icon,
  onClick
}) => {
  const platform = usePlatform()
  const isNarrowViewport = useIsNarrowViewport()
  return (
    <BookNowWrapper isDisabled={isDisabled}>
      <Button
        isBlock={true}
        icon={icon}
        isLoading={false}
        onClick={onClick}
        isDisabled={isDisabled}
        buttonType='sidebar'
        size={platform === 'kiosk' && isNarrowViewport ? 'xlarge' : undefined}
      >
        <>
          {!!amount && (
            <StyledFormattedPriceWrapper>
              <FormattedPrice
                currency='GBP'
                amount={amount}
                isBold={true}
                isZeroShown={false}
              />
            </StyledFormattedPriceWrapper>
          )}
          <ButtonTextWrapper showAmount={!!amount}>{text}</ButtonTextWrapper>
        </>
      </Button>
    </BookNowWrapper>
  )
}
export { PrimaryButton }
