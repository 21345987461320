import React from 'react'

// Containers
import { BreadcrumbContainer } from '../../BreadcrumbContainer'

// Components
import { Title, ListItem, List, WithLoader } from 'marvel-components'

// Selectors
import { venueSelectors } from 'modules/smart-waiter/venue'
import { imageSelectors } from 'modules/smart-waiter/image'

// Misc
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import { media } from 'utils'
import { useHistory, useRouteMatch } from 'react-router-dom'
import { smartWaiterRoutes } from '../SmartWaiterRoutes'

// Types
import { ZImage } from 'shared-types'

const VenuesListContainerWrapper = styled.div`
  box-sizing: border-box;
  padding: 1.5rem 0 2.5rem 0;
  ${media.tablet} {
    padding: 1rem 0 1.5rem 0;
    margin-top: 0;
    min-height: calc(100vh - 109px);
  }
`

const VenueListDetails = styled.div`
  padding: 0 2%;
  ${media.tablet} {
    padding: 0 3%;
  }
  ${media.phone} {
    padding: 0 5%;
  }
`

const VenuesListWrapper = WithLoader(styled.div`
  margin: 1rem 0;
`)

const VenueListContainer: React.FC = () => {
  // Selectors
  const venues = useSelector(venueSelectors.selectAll)
  const images = useSelector(imageSelectors.selectEntities)
  const isLoading = useSelector(venueSelectors.selectIsVenueListLoading)

  // Routing
  const history = useHistory()
  const { url } = useRouteMatch()

  const handleVenueClick = (venueId: EntityId) => {
    history.push(`${url}/${venueId}`)
  }

  return (
    <VenuesListContainerWrapper>
      <VenueListDetails>
        <BreadcrumbContainer routes={smartWaiterRoutes} />
        <Title
          isCapitalised={false}
          isCentralised={false}
          isBold={true}
          size='medium'
        >
          Please select your venue:
        </Title>
      </VenueListDetails>
      <VenuesListWrapper isLoading={isLoading}>
        <List>
          {venues.map(venue => {
            const venueImages = venue.images.map(
              imageId => images[imageId] as ZImage
            )
            return (
              <ListItem
                key={venue.id}
                id={venue.id}
                title={venue.name}
                backgroundColor={'#111'}
                textColor={'#fff'}
                onClick={handleVenueClick}
                icon={true}
                isDisabled={!venue.isActive}
                imageUrl={venueImages[0] ? venueImages[0].url : undefined}
                light={false}
              />
            )
          })}
        </List>
      </VenuesListWrapper>
    </VenuesListContainerWrapper>
  )
}

export { VenueListContainer }
