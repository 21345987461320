import * as contentful from 'contentful'

const contentfulClient = contentful.createClient({
  space: process.env.REACT_APP_NAVIGATION_SPACE_ID!,
  accessToken: process.env.REACT_APP_NAVIGATION_CONTENT_DELIVERY_ACCESS_TOKEN!
})

type FetchOneQuery = {
  include?: number
}

type FetchManyQuery = {
  contentType: 'navigationList' | 'navigationItem'
  include?: number
  limit?: number
  skip?: number
}

const navigationApi = {
  async fetchOne(id: string, query?: FetchOneQuery) {
    const entry = await contentfulClient.getEntry(id, query)
    return entry.fields
  },
  async fetchMany(query?: FetchManyQuery) {
    const entries = await contentfulClient.getEntries({
      content_type: query?.contentType,
      include: query?.include,
      limit: query?.limit,
      skip: query?.skip
    })
    return entries.items.map(entry => entry.fields)
  }
}

export default navigationApi
