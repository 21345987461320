// Containers
import { useAppDispatch } from 'app/reduxStore'
import {
  BasketContainer,
  BrandLogoContainer,
  SeatMapControlContainer,
  SelectTicketContainer,
  FooterNavContainer
} from 'containers/Ticketing'
// Components
import { Layout } from 'marvel-components'
import { AppState } from 'modules'

// Actions
import { fetchSeatMap } from 'modules/seat-map/actions'
import { availabilityActions } from 'modules/ticketing/availability/availabilitySlice'

// Selectors
import { eventDetailsSelectors } from 'modules/ticketing/event'
import { performanceActions } from 'modules/ticketing/performance/performanceSlice'
import { selectPerformanceById } from 'modules/ticketing/performance/selectors'

// Misc
import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useParams, useHistory } from 'react-router-dom'
import { preorderProductsRoute, eventBannerImage } from 'utils'

type Props = {
  eventId: string
  promoCode: string
}

const DaySeatTicketsView: React.FC<Props> = ({ eventId, promoCode }) => {
  const dispatch = useAppDispatch()
  const { performanceId } = useParams<{ performanceId: string }>()
  const history = useHistory()

  // Selectors
  const eventName = useSelector(eventDetailsSelectors.selectEventName)
  const performance = useSelector((state: AppState) =>
    selectPerformanceById(state, performanceId)
  )

  // Set the current performance
  useEffect(() => {
    dispatch(performanceActions.setCurrentPerformance(performanceId))
  }, [dispatch, performanceId])

  // Fetch Seat Map/Performance/Seat Availability
  // ------------------------------------------
  useEffect(() => {
    dispatch(fetchSeatMap({ performanceId }))
  }, [performanceId, dispatch])

  useEffect(() => {
    dispatch(
      availabilityActions.fetchAvailability({
        performanceId,
        promoCode
      })
    )
  }, [performanceId, promoCode, dispatch])

  useEffect(() => {
    dispatch(
      performanceActions.fetchPerformance({
        performanceId,
        promoCode
      })
    )
  }, [performanceId, promoCode, dispatch])

  const handleReservationSuccess = () => {
    return history.push(preorderProductsRoute(eventId, performanceId))
  }

  return (
    <Layout
      pageTitle={`Choose your tickets - ${eventName} | LW Theatres`}
      renderBrandLogo={<BrandLogoContainer showLogin />}
      renderMain={
        <SelectTicketContainer
          performanceId={performanceId}
          seatingType={performance?.isSoldOut ? 'DaySeatsSoldOut' : 'DaySeats'}
          banner={eventBannerImage(eventId)}
        />
      }
      renderSidebarBottom={
        <>
          {!performance?.isSoldOut ? (
            <>
              <SeatMapControlContainer showPriceFilter={false} />
              <BasketContainer
                onReservationSuccess={handleReservationSuccess}
              />
            </>
          ) : null}
        </>
      }
      renderFooter={<FooterNavContainer />}
    />
  )
}

export { DaySeatTicketsView }
