import {
  LWTheme,
  Frozen,
  SOR,
  Phantom,
  Cinderella,
  Joseph,
  Matilda,
  SociallyDistanced,
  BackToTheFuture,
  Patp,
  Hospitality,
  SmartWaiter,
  CinderellaSd,
  JosephSd,
  TheatreRoyalDruryLane,
  GillianLynneTheatre,
  TheLondonPalladium,
  AdelphiTheatre,
  CambridgeTheatre,
  HerMajestysTheatre,
  Tours,
  BeautyAndTheBeast,
  JackAndTheBeanstalk,
  LionWitchWardrobe,
  O2Lounge,
  Lehman,
  Oz,
  CrazyForYou,
  PeterPan,
  Satse
} from 'themes'
import { eventIds, media } from 'utils'
import { DefaultTheme } from 'styled-components'
import { Module } from 'utils/useModule'
import { siteIds } from 'utils/site-ids'
import { css } from 'styled-components/macro'

export const getTheme = (
  eventId?: string | null,
  siteId?: EntityId,
  module: Module = 'ticketing',
  occasionId?: string | null
) => {
  // Theme Override
  // ---------------------------------
  let themeOverride: any
  if (module === 'ticketing') {
    switch (eventId) {
      // Frozen
      case eventIds.development.frozen:
      case eventIds.production.frozen:
        themeOverride = Frozen
        break
      // SOR
      case eventIds.development.schoolOfRock:
      case eventIds.production.schoolOfRock:
        themeOverride = SOR
        break
      // Phantom
      case eventIds.development.phantomNew:
      case eventIds.production.phantomNew:
      case eventIds.development.phantom:
      case eventIds.production.phantom:
        themeOverride = Phantom
        break
      // Cinderella
      case eventIds.development.cinderella:
      case eventIds.production.cinderella:
        themeOverride = Cinderella
        break
      // Cinderella Socially Distanced
      case eventIds.development.cinderellaSd:
      case eventIds.production.cinderellaSd:
        themeOverride = CinderellaSd
        break
      // Joseph
      case eventIds.development.joseph:
      case eventIds.production.joseph:
        themeOverride = Joseph
        break
      case eventIds.development.josephSd:
      case eventIds.production.josephSd:
        themeOverride = JosephSd
        break
      // Matilda
      case eventIds.development.matilda:
      case eventIds.production.matilda:
        themeOverride = Matilda
        break
      // Back To The Future
      case eventIds.development.backToTheFuture:
      case eventIds.production.backToTheFuture:
        themeOverride = BackToTheFuture
        break
      // Panto At The Palladium
      case eventIds.development.patp:
      case eventIds.production.patp:
      case eventIds.development.specialPanto:
      case eventIds.production.specialPanto:
        themeOverride = Patp
        break
      // Beauty and the Beast
      case eventIds.development.beautyAndTheBeast:
      case eventIds.production.beautyAndTheBeast:
        themeOverride = BeautyAndTheBeast
        break
      // Jack and the Beanstalk
      case eventIds.development.jackAndTheBeanstalk:
      case eventIds.production.jackAndTheBeanstalk:
        themeOverride = JackAndTheBeanstalk
        break
      // Lion the Witch and the Wardrobe
      case eventIds.development.lionWitchWardrobe:
      case eventIds.production.lionWitchWardrobe:
        themeOverride = LionWitchWardrobe
        break
      // O2 Priority Lounge
      case eventIds.development.backToTheFutureO2Lounge:
      case eventIds.production.backToTheFutureO2Lounge:
      case eventIds.development.frozenO2Lounge:
      case eventIds.production.frozenO2Lounge:
      case eventIds.development.cinderellaO2Lounge:
      case eventIds.production.cinderellaO2Lounge:
      case eventIds.development.o2Lounge:
      case eventIds.production.o2Lounge:
        themeOverride = O2Lounge
        break
      // Lehman Trilogy
      case eventIds.development.lehman:
      case eventIds.production.lehman:
        themeOverride = Lehman
        break
      // Wizard of Oz
      case eventIds.development.oz:
      case eventIds.production.oz:
        themeOverride = Oz
        break
      // Crazy for You
      case eventIds.development.crazyForYou:
      case eventIds.production.crazyForYou:
        themeOverride = CrazyForYou
        break
      // Peter Pan
      case eventIds.development.peterPan:
      case eventIds.production.peterPan:
        themeOverride = PeterPan
        break
      // Peter Pan
      case eventIds.development.satse:
      case eventIds.production.satse:
        themeOverride = Satse
        break
      // GA - no GA events at present other than tours but a GA theme exists
      // Tours
      case eventIds.development.trdlTours:
      case eventIds.production.trdlTours:
        themeOverride = Tours
        break
      // Socially Distanced
      case eventIds.development.elizabethDay:
      case eventIds.production.elizabethDay:
      case eventIds.development.ranulph:
      case eventIds.production.ranulph:
      case eventIds.development.songs:
      case eventIds.production.songs:
      case eventIds.development.wenger:
      case eventIds.production.wenger:
      case eventIds.development.graham:
      case eventIds.production.graham:
      case eventIds.development.russellBrand:
      case eventIds.production.russellBrand:
      case eventIds.development.rickWakeman:
      case eventIds.production.rickWakeman:
      case eventIds.development.danielSloss:
      case eventIds.production.danielSloss:
      case eventIds.development.andrewCotter:
      case eventIds.production.andrewCotter:
      case eventIds.development.van:
      case eventIds.production.van:
      case eventIds.development.dawnFrench:
      case eventIds.production.dawnFrench:
      case eventIds.development.hair:
      case eventIds.production.hair:
      case eventIds.development.houseAndGarage:
      case eventIds.production.houseAndGarage:
      case eventIds.development.midgeUre:
      case eventIds.production.midgeUre:
      case eventIds.development.dualers:
      case eventIds.production.dualers:
      case eventIds.development.ministryOfSound:
      case eventIds.production.ministryOfSound:
      case eventIds.development.bond:
      case eventIds.production.bond:
      case eventIds.development.philWang:
      case eventIds.production.philWang:
      case eventIds.development.leeMead:
      case eventIds.production.leeMead:
      case eventIds.development.hereComeBoys:
      case eventIds.production.hereComeBoys:
      case eventIds.development.keepTheReceipts:
      case eventIds.production.keepTheReceipts:
        themeOverride = SociallyDistanced
        break
    }
  } else if (module === 'hospitality') {
    themeOverride = Hospitality
  } else if (module === 'smart-waiter') {
    themeOverride = SmartWaiter
    switch (siteId) {
      case siteIds.development.theatreRoyalDruryLane:
      case siteIds.production.theatreRoyalDruryLane:
        themeOverride = TheatreRoyalDruryLane
        break
      case siteIds.development.gillianLynneTheatre:
      case siteIds.production.gillianLynneTheatre:
        themeOverride = GillianLynneTheatre
        break
      case siteIds.development.theLondonPalladium:
      case siteIds.production.theLondonPalladium:
        themeOverride = TheLondonPalladium
        break
      case siteIds.development.adelphiTheatre:
      case siteIds.production.adelphiTheatre:
        themeOverride = AdelphiTheatre
        break
      case siteIds.development.cambridgeTheatre:
      case siteIds.production.cambridgeTheatre:
        themeOverride = CambridgeTheatre
        break
      case siteIds.development.herMajestysTheatre:
      case siteIds.production.herMajestysTheatre:
        themeOverride = HerMajestysTheatre
        break
    }
  }

  let theme: DefaultTheme = {
    ...LWTheme,
    ...themeOverride,
    colors: {
      ...LWTheme.colors,
      ...(themeOverride && themeOverride.colors && { ...themeOverride.colors })
    },
    font: {
      ...LWTheme.font,
      ...(themeOverride && themeOverride.font && { ...themeOverride.font })
    },
    ...(themeOverride &&
      themeOverride.themeIcon && { themeIcon: themeOverride.themeIcon }),
    ...(themeOverride &&
      themeOverride.themeBackgroundAsset && {
        themeBackgroundAsset: themeOverride.themeBackgroundAsset
      }),
    ...(themeOverride &&
      themeOverride.themeBackgroundColour && {
        themeBackgroundColour: themeOverride.themeBackgroundColour
      }),
    showAvailability: themeOverride && themeOverride.showAvailability
  }

  return theme
}

export const themedBackground = css<{ platform?: string }>`
  background: ${props => props.theme.themeBackgroundColour}
    url(${props => props.theme.themeBackgroundAsset});
  background-repeat: no-repeat;
  background-size: 625px;
  ${media.tablet} {
    background-size: 1081px;
    background-repeat: no-repeat;
    background: ${props =>
      props.platform === 'kiosk'
        ? 'white'
        : `props.theme.themeBackgroundColour url(props.theme.themeBackgroundAsset)`};
  }
  ${media.phone} {
    background-size: 775px;
  }
`
