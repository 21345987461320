import React, { useEffect, useRef, useState } from 'react'

// Components
import { FormDivider, Divider } from 'marvel-components'

// Utils
import styled from 'styled-components/macro'
import AdyenCheckout from '@adyen/adyen-web'
import '@adyen/adyen-web/dist/adyen.css'

// Types
import { ExpressCheckoutValues } from 'shared-types'

interface Props {
  onPaymentSubmit: (state: any) => void
  onShouldShowExpressPayment: () => Promise<boolean>
  onExpressPayAuthorise: (values: ExpressCheckoutValues) => void
  amount: number
  includeAddress?: boolean
  paymentMethods: any
}

const ExpressWrapper = styled.div<{ hidden: boolean }>`
  display: ${props => (props.hidden ? 'none' : 'block')};
`
const PaymentTypeWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
`

const GooglePaymentContainer = styled.div<{ hidden: boolean }>`
  display: ${props => (props.hidden ? 'none' : 'block')};
  width: 100%;
  .gpay-button.long {
    width: 100%;
    min-width: none;
  }
  .gpay-button.plain {
    width: 100%;
    min-width: none;
  }
`

const ApplePayContainer = styled.div<{ hidden: boolean }>`
  display: ${props => (props.hidden ? 'none' : 'inline-block')};
  width: 100%;
  .adyen-checkout__applepay__button {
    width: 100%;
    min-width: none;
  }
`

const ExpressCheckout: React.FC<Props> = ({
  onExpressPayAuthorise,
  onShouldShowExpressPayment,
  onPaymentSubmit,
  amount,
  includeAddress = true,
  paymentMethods
}) => {
  const adyenCheckout = useRef<any>()
  const googlePayComponent = useRef<any>()
  const googlePay = useRef<any>()

  const applePayComponent = useRef<any>()
  const applePay = useRef<any>()

  const [hasGooglePay, setHasGooglePay] = useState(false)
  const [hasApplePay, setHasApplePay] = useState(false)

  useEffect(() => {
    if (!adyenCheckout.current) {
      adyenCheckout.current = new AdyenCheckout({
        environment: process.env.REACT_APP_ADYEN_ENVIRONMENT,
        clientKey: process.env.REACT_APP_ADYEN_CLIENT_KEY,
        paymentMethodsResponse: paymentMethods
      })
      console.log(adyenCheckout.current.paymentMethodsResponse)
    }
  }, [])

  useEffect(() => {
    if (window.ApplePaySession && window.location.protocol === 'https:') {
      const merchantName = process.env.REACT_APP_MERCHANT_NAME
      const merchantIdentifier = process.env.REACT_APP_APPLE_PAY_MERCHANT_ID
      /*  const applePaySessionUrl =
        process.env.REACT_APP_APPLE_PAY_SESSION_URL || '' */
      const handleApplePayClick = async (resolve: any, reject: any) => {
        const show = await onShouldShowExpressPayment()
        show ? resolve() : reject()
      }
      // const handleValidateMerchant = async (
      //   resolve: any,
      //   reject: any,
      //   validationURL: string
      // ) => {
      //   try {
      //     const response = await axios.post(applePaySessionUrl, {
      //       hostDomain: window.location.hostname,
      //       displayName: merchantName,
      //       merchantIdentifier: merchantIdentifier,
      //       url:
      //         'https://apple-pay-gateway.apple.com/paymentservices/paymentSession'
      //     })
      //     resolve(response.data.data)
      //   } catch (error) {
      //     reject()
      //   }
      // }
      const handleApplePayAuthorized = (
        resolve: any,
        reject: any,
        event: ApplePayJS.ApplePayPaymentAuthorizedEvent
      ) => {
        const { shippingContact } = event.payment
        const telephoneNumber = shippingContact?.phoneNumber
          ?.replace('+', '00')
          .replace(/ +/g, '')
        onExpressPayAuthorise({
          firstName: shippingContact?.givenName ?? 'FirstName',
          lastName: shippingContact?.familyName ?? 'LastName',
          email: shippingContact?.emailAddress ?? 'info@lwtheatres.co.uk',
          telephoneNumber: telephoneNumber ?? '00000000000',
          address: {
            addressLine1: shippingContact?.addressLines?.[0],
            addressLine2: shippingContact?.addressLines?.[1],
            addressLine3: shippingContact?.addressLines?.[2],
            country: shippingContact?.country,
            province: shippingContact?.administrativeArea,
            city: shippingContact?.locality,
            postcode: shippingContact?.postalCode
          }
        })
        resolve()
      }
      const handleOnChange = (state: any, component: any) => {
        if (state.isValid) {
          onPaymentSubmit && onPaymentSubmit(state.data)
        }
      }
      if (adyenCheckout.current) {
        const applepay = adyenCheckout.current.create('applepay', {
          amount: {
            currency: 'GBP',
            value: amount
          },
          countryCode: 'GB',

          buttonType: 'plain',
          buttonColor: 'black',
          requiredShippingContactFields: [
            'postalAddress',
            'phone',
            'name',
            'email'
          ],
          onClick: handleApplePayClick,

          onAuthorized: handleApplePayAuthorized,
          onChange: handleOnChange
        })

        applepay
          .isAvailable()
          .then(() => {
            applepay.mount(applePay.current)
            applePayComponent.current = applePay
            setHasApplePay(true)
          })
          .catch((e: any) => {
            console.log(e)
          })
      }
    }
  }, [amount])

  useEffect(() => {
    if (!window.ApplePaySession) {
      const merchantName = process.env.REACT_APP_MERCHANT_NAME
      const gatewayMerchantId = process.env.REACT_APP_GATEWAY_MERCHANT_ID
      const merchantId = process.env.REACT_APP_GOOGLE_MERCHANT_ID
      const environment = process.env.REACT_APP_ADYEN_ENVIRONMENT?.toUpperCase()
      const handleGooglePayClick = async (resolve: any, reject: any) => {
        const show = await onShouldShowExpressPayment()
        show ? resolve() : reject('Form not Valid')
      }
      const handleGooglePayAuthorized = (
        data: google.payments.api.PaymentData
      ) => {
        const { shippingAddress, email } = data
        if (
          shippingAddress &&
          shippingAddress.name &&
          shippingAddress.phoneNumber
        ) {
          const [firstName, ...second] = shippingAddress.name.split(' ')
          const lastName = second.length > 0 ? second.join(' ') : 'None'
          const telephoneNumber = shippingAddress.phoneNumber
            .replace('+', '00')
            .replace(/ +/g, '')
          onExpressPayAuthorise({
            firstName,
            lastName,
            email: email ?? 'info@lwtheatres.co.uk',
            telephoneNumber,
            address: {
              addressLine1: shippingAddress.address1,
              addressLine2: shippingAddress.address2,
              addressLine3: shippingAddress.address3,
              country: shippingAddress.countryCode,
              province: shippingAddress.administrativeArea,
              city: shippingAddress.locality,
              postcode: shippingAddress.postalCode
            }
          })
        }
      }
      const handleOnChange = (state: any, component: any) => {
        if (state.isValid) {
          onPaymentSubmit && onPaymentSubmit(state.data)
        }
      }

      if (adyenCheckout.current) {
        const googlepay = adyenCheckout.current.create('paywithgoogle', {
          environment: environment,
          amount: {
            currency: 'GBP',
            value: amount
          },
          configuration: {
            gatewayMerchantId: gatewayMerchantId,
            merchantName: merchantName,
            ...(environment === 'LIVE' && { merchantId: merchantId })
          },
          buttonColor: 'black',
          buttonSizeMode: 'fill',
          buttonType: 'plain',
          emailRequired: true,
          shippingAddressRequired: includeAddress,
          shippingAddressParameters: {
            phoneNumberRequired: true
          },
          onAuthorized: handleGooglePayAuthorized,
          onChange: handleOnChange,
          onClick: handleGooglePayClick
        })
        googlepay.isAvailable().then(() => {
          if (googlePay.current) {
            googlepay.mount(googlePay.current)
            googlePayComponent.current = googlepay
            setHasGooglePay(true)
          }
        })
      }
    }
  }, [includeAddress, amount])

  return (
    <ExpressWrapper hidden={!hasApplePay && !hasGooglePay}>
      <FormDivider isLine={false}>Express Checkout</FormDivider>
      <Divider isRounded={true} isPadded={false}>
        <PaymentTypeWrapper>
          <GooglePaymentContainer
            ref={googlePay}
            id='google-pay'
            hidden={!hasGooglePay || hasApplePay}
          />
          <ApplePayContainer
            ref={applePay}
            id='applepay-container'
            hidden={!hasApplePay}
          />
        </PaymentTypeWrapper>
      </Divider>
      <FormDivider isLine={true}>Or Pay with Credit / Debit Card</FormDivider>
    </ExpressWrapper>
  )
}

export { ExpressCheckout }
