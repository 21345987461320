import React, { useEffect } from 'react'

// Components
import { Layout } from 'marvel-components'

// Containers
import {
  OrderSummaryContainer,
  ExchangeContainer,
  ExchangeContainerExpired,
  BrandLogoContainer
} from 'containers/Ticketing'

// Selectors
import { selectOrderEventId } from 'modules/basket/selectors'

// Utils
import { eventIds, trackExchangeView } from 'utils'
import moment from 'moment'

// Misc
import { useSelector } from 'react-redux'
import { useParams } from 'react-router'
import { eventDetailsSelectors } from 'modules/ticketing/event'

const ExchangeView: React.FC = () => {
  const { orderId } = useParams<{ orderId: string }>()

  const eventId = useSelector(selectOrderEventId)
  const eventName = useSelector(eventDetailsSelectors.selectEventName)

  // Analytics - Track Page
  // ------------------------------------------
  useEffect(() => {
    if (eventName) {
      trackExchangeView({
        eventName
      })
    }
  }, [eventName])

  const isExchangeWindowClosed = () => {
    switch (eventId) {
      case eventIds.development.joseph:
      case eventIds.production.joseph:
        return moment.utc().isAfter(moment.utc('2020-07-03 13:00:00.000'))
      case eventIds.development.backToTheFuture:
      case eventIds.production.backToTheFuture:
        return moment.utc().isAfter(moment.utc('2021-04-20 10:00:00.000'))
      case eventIds.development.frozen:
      case eventIds.production.frozen:
        return moment.utc().isAfter(moment.utc('2021-05-11 07:00:00.000'))
      case eventIds.development.matilda:
      case eventIds.production.matilda:
        return moment.utc().isAfter(moment.utc('2021-06-02 07:00:00.000'))
      case eventIds.development.phantomNew:
      case eventIds.production.phantomNew:
        return moment.utc().isAfter(moment.utc('2021-06-02 07:00:00.000'))
      case eventIds.development.cinderella:
      case eventIds.production.cinderella:
      case eventIds.development.cinderellaSd:
      case eventIds.production.cinderellaSd:
        return moment.utc().isAfter(moment.utc('2021-08-27 07:00:00.000'))
      default:
        return false
    }
  }
  return (
    <Layout
      pageTitle={`LW Ticket Exchange | LW Theatres`}
      renderBrandLogo={<BrandLogoContainer />}
      renderSidebarTop={
        isExchangeWindowClosed() ? null : <OrderSummaryContainer />
      }
      renderMain={
        isExchangeWindowClosed() ? (
          <ExchangeContainerExpired eventId={eventId} />
        ) : (
          <ExchangeContainer eventId={eventId} orderId={orderId} />
        )
      }
    />
  )
}

export { ExchangeView }
