import { takeLatest } from 'redux-saga/effects'
import * as actions from './actions'
import * as api from 'services/api'
import * as shared from 'modules/shared'

export function* watcherFetchSeatMap() {
  yield takeLatest(actions.fetchSeatMap.TRIGGER, fetchSeatMap)
}

export const fetchSeatMap = shared.sharedOperations.fetchEntityNonBlocking.bind(
  null,
  actions.fetchSeatMap,
  api.fetchSeatMap
)
