import { createAsyncThunk } from '@reduxjs/toolkit'
import { bannerActions } from 'modules/banner'
import moment from 'moment'
import { normalize, schema } from 'normalizr'
import contentfulApi from '../services/contentfulApi'
import {
  ContentfulEventCollectionEntity,
  EventCollection
} from '../shared/types'

const eventCollectionSchema = new schema.Entity('eventCollections', undefined)

type FetchEventCollectionsResponse = {
  eventCollections: Record<string, EventCollection>
}

export const fetchEventCollections = createAsyncThunk<
  FetchEventCollectionsResponse,
  undefined
>(
  'marvel/self-serve/FETCH_EVENT_COLLECTIONS',
  async (_, { dispatch, rejectWithValue }) => {
    try {
      const eventCollections = (await contentfulApi.fetchMany({
        contentType: 'eventCollection',
        include: 1
      })) as ContentfulEventCollectionEntity[]

      if (!eventCollections) {
        throw new Error('Failed to fetch event collections')
      }

      const eventCollectionsData = eventCollections.map(eventCollection => {
        return {
          ...eventCollection.fields,
          id: eventCollection.sys.id,
          title: eventCollection.fields.title,
          events: eventCollection.fields.events
            .filter(event => {
              if (!event.fields.endDate) return true
              const endDate = moment(event.fields.endDate)
              return endDate.utc() >= moment().utc()
            })
            .map(event => event.sys.id)
        }
      })

      const normalized = normalize(eventCollectionsData, [
        eventCollectionSchema
      ])
      return normalized.entities as FetchEventCollectionsResponse
    } catch (err) {
      dispatch(
        bannerActions.setBannerContent({
          bannerType: 'error',
          title: 'Please Note',
          text: 'Failed to fetch event collections.'
        })
      )
      return rejectWithValue(err)
    }
  }
)
