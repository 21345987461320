import React from 'react'

// Components
import { PricedItem, Title, Button } from 'marvel-components'

// Misc
import styled from 'styled-components'

// Types
import { TicketLineItem } from 'shared-types'

type Props = {
  tickets: TicketLineItem[]
  onRemoveTicketClick?: (ticket: TicketLineItem) => void
}

const TicketItemWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
  align-items: flex-start;
`

const TicketTitle = styled(Title)`
  padding: 0;
`

const BasketTicketItems: React.FC<Props> = ({
  tickets,
  onRemoveTicketClick
}) => {
  return (
    <>
      {tickets.map((ticket, idx) => {
        const { quantity, name, price } = ticket
        const handleRemoveClick = () => {
          onRemoveTicketClick && onRemoveTicketClick(ticket)
        }
        return (
          <PricedItem
            key={`priced-item-${idx}`}
            price={price && quantity ? price * quantity : 0}
            isFreeShown={true}
          >
            <TicketItemWrapper>
              <TicketTitle
                isCapitalised={false}
                isCentralised={false}
                size='medium-large'
                isPadded={false}
                isMultiline={true}
                isSidebar={true}
              >
                {`${name} x ${quantity}`}
              </TicketTitle>
              <Button
                buttonType='link-button'
                size='extra-small'
                isUnderlined={false}
                onClick={handleRemoveClick}
                text='Remove'
                isSidebar={true}
              />
            </TicketItemWrapper>
          </PricedItem>
        )
      })}
    </>
  )
}

export { BasketTicketItems }
