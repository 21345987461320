import React, { useMemo } from 'react'

import styled from 'styled-components'
import { media } from 'utils'

// Containers
import { BreadcrumbContainer } from '../../BreadcrumbContainer'

// Components
import { Title, ListItem, List } from 'marvel-components'

// Selectors
import { venueSelectors } from 'modules/smart-waiter/venue'

// Misc
import { useSelector } from 'react-redux'
import { useHistory, useRouteMatch, Redirect } from 'react-router-dom'
import { smartWaiterRoutes } from '../SmartWaiterRoutes'

// Types
import { AppState } from 'modules/types'

const ServiceContainerWrapper = styled.div`
  box-sizing: border-box;
  padding: 1.5rem 0 2.5rem 0;
  ${media.tablet} {
    padding: 1rem 0 1.5rem 0;
    margin-top: 0;
  }
`

const ServiceListDetails = styled.div`
  padding: 0 2%;
  ${media.tablet} {
    padding: 0 3%;
  }
  ${media.phone} {
    padding: 0 5%;
  }
`

const ServiceListWrapper = styled.div`
  margin: 1rem 0;
`

const ServiceListContainer: React.FC = () => {
  // Routing
  const history = useHistory()
  const {
    url,
    params: { venueId }
  } = useRouteMatch<{ venueId: string }>()

  // Selectors
  const selectServicesByVenueId = useMemo(
    () => venueSelectors.makeSelectServiceWithVenueId(),
    []
  )
  const services = useSelector((state: AppState) =>
    selectServicesByVenueId(state, venueId)
  )

  // Actions
  const handleServiceClick = (serviceId: EntityId) => {
    history.push(`${url}/${serviceId}/menu`)
  }

  if (services && services.length === 1) {
    return <Redirect to={`${url}/${services[0].id}/menu`} />
  }

  return (
    <ServiceContainerWrapper>
      <ServiceListDetails>
        <BreadcrumbContainer routes={smartWaiterRoutes} />
        <Title
          isCapitalised={false}
          isCentralised={false}
          isBold={true}
          size='medium'
        >
          Are you ordering for pre-show or for the interval?
        </Title>
      </ServiceListDetails>
      <ServiceListWrapper>
        <List>
          {services.map(service => (
            <ListItem
              key={service.id}
              title={service.name}
              id={service.id}
              stacked
              onClick={handleServiceClick}
              icon={true}
            />
          ))}
        </List>
      </ServiceListWrapper>
    </ServiceContainerWrapper>
  )
}

export { ServiceListContainer }
