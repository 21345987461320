import React from 'react'
import styled, { css } from 'styled-components/macro'
import { LegendItem } from './LegendItem'
import { fadeIn, media } from 'utils'
import { LegendItems } from 'shared-types'

interface Props {
  items?: LegendItems
  selectItem: (itemIds: string[]) => void
  deselectItem: (itemIds: string[]) => void
}

const LegendWrapper = styled.div(props => {
  const {
    theme: {
      colors: { borderColor }
    }
  } = props

  return css`
    display: flex;
    position: absolute;
    left: 50%;
    bottom: 2rem;
    background: rgba(255, 255, 255, 0.9);
    max-width: 95%;
    justify-content: center;
    border-radius: 0.25rem;
    transform: translate(-50%, 0);
    box-shadow: 0 1px 4px 0 #949494;
    flex-direction: row;
    flex-wrap: wrap;
    width: fit-content;
    animation: ${fadeIn} 0.5s linear;
    user-select: none;
    padding: 0.125rem 0.25rem;
    border-top-left-radius: 2rem;
    border-bottom-left-radius: 2rem;
    border-top-right-radius: 2rem;
    border-bottom-right-radius: 2rem;

    ${media.desktop} {
      flex-wrap: wrap;
      max-width: 75%;
      min-width: 75%;
      border-radius: 3rem;
      padding: 0.125rem 1.5rem;
    }

    ${media.tablet} {
      width: 100%;
      overflow-x: scroll;
      flex-wrap: nowrap;
      bottom: 0;
      box-shadow: none;
      max-width: 100%;
      border-top: 2px solid ${borderColor};
      border-bottom: 2px solid ${borderColor};
      border-radius: 0;
      justify-content: flex-start;
      background: rgba(255, 255, 255, 1);
      padding: 0.125rem 0;
    }

    ${media.phone} {
      justify-content: flex-start;
    }
  `
})

export const Legend: React.FC<Props> = ({
  items,
  selectItem,
  deselectItem
}) => {
  const handleSelectLegendItem = (priceLevelIds: string[]) => {
    selectItem([...priceLevelIds])
  }

  const handleDeSelectLegendItem = (priceLevelIds: string[]) => {
    deselectItem([...priceLevelIds])
  }
  if (items) {
    const priceLevelLegends = Object.values(items).map((legendItem, index) => {
      const {
        background,
        priceLevelIds,
        price,
        originalPrice,
        isFiltered,
        note,
        icon
      } = legendItem

      return (
        <LegendItem
          index={index}
          key={`legend-item-${background}`}
          priceLevelIds={priceLevelIds}
          price={price}
          originalPrice={originalPrice}
          isDisabled={isFiltered}
          note={note}
          icon={icon}
          backgroundColor={background}
          onSelectItem={handleSelectLegendItem}
          onRemoveItem={handleDeSelectLegendItem}
        />
      )
    })

    return priceLevelLegends.length === 0 ? null : (
      <LegendWrapper>{priceLevelLegends}</LegendWrapper>
    )
  } else {
    return null
  }
}
