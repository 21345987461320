import React, { useRef, useEffect } from 'react'

// Actions
import { closeModal } from 'modules/overlay/actions'
import { orderActions } from 'modules/hospitality/order'

// Utils
import { useDispatch } from 'react-redux'
import styled from 'styled-components/macro'
import AdyenCheckout from '@adyen/adyen-web'

// Types
import { PaymentChallengeResponse, PaymentChallenge } from 'shared-types'

export interface ThreeDSHospitalityProps {
  challenge: PaymentChallenge
  orderId: string
}

const ActionContainer = styled.div`
  width: 100%;
  height: 100%;
  .adyen-checkout__3ds2-device-fingerprint {
    width: 100%;
    height: 100%;
  }
  .adyen-checkout__threeds2__challenge {
    width: 100%;
    height: 100%;
  }
  .adyen-checkout__spinner__wrapper {
    align-items: center;
    display: flex;
    height: 100%;
    justify-content: center;
  }
`

const ThreeDSContainerHospitality: React.FC<ThreeDSHospitalityProps> = ({
  challenge,
  orderId
}) => {
  const dispatch = useDispatch()
  const challengeContainer = useRef<any>()
  const adyenCheckout = useRef<any>()

  useEffect(() => {
    if (challenge) {
      const handleOnAdditionalDetails = (state: {
        data: PaymentChallengeResponse
      }) => {
        dispatch(
          orderActions.updateOrder({
            orderId: orderId,
            paymentDetails: state.data
          })
        )
        dispatch(closeModal())
      }

      const configuration = {
        locale: 'en_US',
        environment: process.env.REACT_APP_ADYEN_ENVIRONMENT,
        clientKey: process.env.REACT_APP_ADYEN_CLIENT_KEY,
        onAdditionalDetails: handleOnAdditionalDetails
      }

      adyenCheckout.current = new AdyenCheckout(configuration)

      const threeDSConfiguration = {
        size: '04'
      }

      adyenCheckout.current
        .createFromAction(challenge, threeDSConfiguration)
        .mount(challengeContainer.current)
    }
  }, [dispatch, challenge, orderId])

  return <ActionContainer ref={challengeContainer} />
}

export { ThreeDSContainerHospitality }
