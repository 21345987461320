// Components
import { Slideshow } from 'marvel-components'

// Actions
import { contentActions } from 'modules/self-serve/content/contentSlice'

// Selectors
import { selectScreenSaverSlides } from 'modules/self-serve/content/selectors'

// Utils
import { useAppDispatch } from 'app/reduxStore'
import moment from 'moment'
import { useEffect } from 'react'
import styled from 'styled-components'
import { useSelector } from 'react-redux'
import { kioskMainMenu, useIsIdle } from 'utils'
import { Redirect } from 'react-router-dom'

const SCREEN_SAVER_INTERVAL_SECONDS = 10

const ScreenSaverWrapper = styled.div`
  width: 100%;
  height: 100vh;
`

const StyledSlideshow = styled(Slideshow)`
  width: 100%;
  height: 100%;
`

const ScreenSaverView = () => {
  const dispatch = useAppDispatch()
  const isIdle = useIsIdle(null, true)
  const screenSaverSlides = useSelector(selectScreenSaverSlides)

  useEffect(() => {
    dispatch(contentActions.fetchScreenSaverSlides())
  }, [dispatch])

  if (!isIdle) {
    return <Redirect to={kioskMainMenu()} />
  }

  return (
    <ScreenSaverWrapper>
      <StyledSlideshow
        interval={moment
          .duration()
          .add(SCREEN_SAVER_INTERVAL_SECONDS, 'seconds')}
        sources={screenSaverSlides.map(slide => slide.url)}
      />
    </ScreenSaverWrapper>
  )
}

export { ScreenSaverView }
