import React from 'react'
import styled from 'styled-components'
import { media } from 'utils'

// Containers
import { BreadcrumbContainer } from '../../BreadcrumbContainer'

// Components
import { Title, ListItem, List, WithLoader } from 'marvel-components'

// Selectors
import { menuSectionSelectors } from 'modules/smart-waiter/menu-section'

// Misc
import { useSelector } from 'react-redux'
import { useHistory, useRouteMatch } from 'react-router-dom'
import { smartWaiterRoutes } from '../SmartWaiterRoutes'

const MenuListContainerWrapper = styled.div`
  box-sizing: border-box;
  padding: 1.5rem 0 2.5rem 0;
  ${media.tablet} {
    padding: 1rem 0 1.5rem 0;
    margin-top: 0;
  }
`

const MenuSectionDetails = styled.div`
  padding: 0 2%;
  ${media.tablet} {
    padding: 0 3%;
  }
  ${media.phone} {
    padding: 0 5%;
  }
`

const MenuListListWrapper = WithLoader(styled.div`
  margin: 1rem 0;
`)

const MenuSectionListContainer: React.FC = () => {
  // Selectors
  const menuSections = useSelector(menuSectionSelectors.selectAll)
  const isLoading = useSelector(menuSectionSelectors.isLoading)

  // Routing
  const history = useHistory()
  const { url } = useRouteMatch()

  // Actions
  const handleMenuClick = (id: string | number) => {
    history.push(`${url}/${id}/product`)
  }

  return (
    <MenuListContainerWrapper>
      <MenuSectionDetails>
        <BreadcrumbContainer routes={smartWaiterRoutes} />
        <Title
          isCapitalised={false}
          isCentralised={false}
          isBold={true}
          size="medium"
        >
          What would you like to order?
        </Title>
      </MenuSectionDetails>
      <MenuListListWrapper isLoading={isLoading}>
        <List>
          {menuSections.map((section) => (
            <ListItem
              key={section.id}
              title={section.name}
              id={section.id}
              onClick={handleMenuClick}
              icon={true}
              imageUrl={section.imageUrl}
            />
          ))}
        </List>
      </MenuListListWrapper>
    </MenuListContainerWrapper>
  )
}

export { MenuSectionListContainer }
