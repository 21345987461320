// Components
import { Title, Icon, Button } from 'marvel-components'

// Misc
import styled from 'styled-components/macro'
import { RefObject } from 'react'
import { media } from 'utils'

type Props = {
  name: string
  email: string
  faqRef?: RefObject<HTMLSpanElement> | null
}

const ManageContactDetailsWrapper = styled.div`
  padding: 1.5rem 1.5rem 0 1.5rem;
  display: flex;
  flex-direction: column;
  ${media.tablet} {
    padding: 1.5rem 1rem 1rem 1rem;
    background: ${props =>
      props.theme.themeBackgroundColour
        ? props.theme.themeBackgroundColour
        : props.theme.colors.mainBackgroundColor};
  }
  ${media.phone} {
    padding: '0 1rem 1rem 1rem';
  }
`
const ManageContactDetailsTitle = styled(Title)`
  margin: 0;
  padding-bottom: 0.5rem;
  color: ${props => props.theme.colors.copyColor};
  ${media.tablet} {
    color: ${props => props.theme.colors.sidebarCopyColor};
  }
`

const Faq = styled.div`
  display: flex;
  align-items: center;
  color: ${props => props.theme.colors.copyColor};
  fill: ${props => props.theme.colors.copyColor};
  border-bottom: 1px solid ${props => props.theme.colors.borderColor};
  padding-bottom: 1.5rem;
  ${media.tablet} {
    color: ${props => props.theme.colors.sidebarCopyColor};
    fill: ${props => props.theme.colors.sidebarCopyColor};
    button {
      color: ${props => props.theme.colors.sidebarCopyColor};
    }
  }
`

const StyledIcon = styled(Icon)`
  margin-right: 0.25rem;
`

const Text = styled.span`
  font-family: ${props => props.theme.font.copy};
  color: ${props => props.theme.colors.copyColor};
  font-size: 0.875rem;
  ${media.tablet} {
    color: ${props => props.theme.colors.sidebarCopyColor};
  }
`

const Email = styled(Text)`
  font-size: 0.875rem;
  margin-bottom: 1rem;
`

const ManageContactDetails = ({ name, email, faqRef }: Props) => {
  // Actions
  const handleFaqLinkClick = () =>
    faqRef?.current?.scrollIntoView({ behavior: 'smooth', block: 'center' })

  return (
    <ManageContactDetailsWrapper>
      <ManageContactDetailsTitle
        isCapitalised={false}
        isBold
        size='large'
        isCentralised={false}
      >
        {name}
      </ManageContactDetailsTitle>
      <Email>{email}</Email>
      <Faq>
        <StyledIcon prefix='far' icon='question-circle' />
        <Text>
          Need help?{' '}
          <Button
            buttonType='link-button'
            onClick={handleFaqLinkClick}
            text='See the FAQ'
            isUnderlined={true}
          />
        </Text>
      </Faq>
    </ManageContactDetailsWrapper>
  )
}
export { ManageContactDetails }
