import discountReducer from './reducers'
export const SET_DISCOUNT_CODE = 'marvel/discount/SET_DISCOUNT_CODE'
export const SET_PROMO_CODE = 'marvel/discount/SET_PROMO_CODE'
export const SET_LOTTERY_TOKEN = 'marvel/discount/SET_LOTTERY_TOKEN'

export const FETCH_DISCOUNTED_PERFORMANCE =
  'marvel/discount/FETCH_DISCOUNTED_PERFORMANCE'

export interface CodePayload {
  code: string
}

export interface SetDiscountCodeAction {
  type: typeof SET_DISCOUNT_CODE
  payload: CodePayload
}

export interface SetPromoCodeAction {
  type: typeof SET_PROMO_CODE
  payload: CodePayload
}

export interface SetLotteryTokenAction {
  type: typeof SET_LOTTERY_TOKEN
  payload: CodePayload
}

export type DiscountState = ReturnType<typeof discountReducer>
