import { createAction } from 'redux-actions'
import { PerformanceFilter } from 'shared-types'
import { SET_PERFORMANCE_FILTER, SET_PRICE_PERFORMANCE_FILTER } from './types'

export const setPerformanceFilter = createAction<PerformanceFilter[]>(
  SET_PERFORMANCE_FILTER
)
export const setPricePerformanceFilters = createAction(
  SET_PRICE_PERFORMANCE_FILTER
)
