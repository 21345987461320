import React from 'react'
import styled from 'styled-components/macro'
import { Form, Formik } from 'formik'
import { FormInput, Button } from 'marvel-components'
import * as Yup from 'yup'

import { loginValidator } from '../validationSchemas'

export interface ForgottenPasswordFormValues {
  login: string
}

interface Props {
  onSubmit: (values: ForgottenPasswordFormValues) => Promise<any>
  onReturnToLogin: () => void
}

const FormWrapper = styled.div`
  display: flex;
  flex-direction: column;
`
const LoginWrapper = styled.div`
  flex: 1 0 auto;
`

const SubmitWrapper = styled.div`
  margin-top: 2rem;
  margin-bottom: 1rem;
`

const ForgottenPasswordForm: React.FC<Props> = ({
  onSubmit,
  onReturnToLogin
}) => {
  return (
    <Formik
      initialValues={{ login: '' }}
      onSubmit={onSubmit}
      validationSchema={Yup.object().shape({
        login: loginValidator
      })}
    >
      {({ isSubmitting }) => (
        <Form>
          <FormWrapper>
            <LoginWrapper>
              <FormInput
                name="login"
                type="text"
                placeholder="Email address / Username"
                autocomplete="username"
              />
            </LoginWrapper>
            <Button
              text="Return to login"
              buttonType="link-button"
              onClick={onReturnToLogin}
              size="extra-small"
            />
            <SubmitWrapper>
              <Button
                type="submit"
                isBlock={true}
                text="Submit"
                size="small"
                isLoading={isSubmitting}
              />
            </SubmitWrapper>
          </FormWrapper>
        </Form>
      )}
    </Formik>
  )
}
export { ForgottenPasswordForm }
