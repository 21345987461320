import React, { useState } from 'react'

// Components
import {
  CopyBlock,
  DaySeatsCountdown,
  DaySeatsHeader,
  Link,
  PerformanceListItem,
  Title,
  WithLoader
} from 'marvel-components'

// Containers
import { LoginButtonContainer } from 'containers/Ticketing'

// Selectors
import {
  selectIsLoadingPerformances,
  selectPerformancesForRange
} from 'modules/ticketing/performance/selectors'

// Utils
import { useSelector } from 'react-redux'
import styled, { css } from 'styled-components'
import { useTypedSelector } from 'modules'
import { eventBannerImage, media } from 'utils'
import {
  daySeatsSoldOutCopy,
  daySeatTitle,
  daySeatWaitingText,
  daySeatEventWindow
} from 'utils/day-seat-assets'
import moment, { Moment } from 'moment'

const DaySeatsCopy = styled.div`
  color: ${props => props.theme.colors.copyColor};
  font-family: ${props => props.theme.font.copy};
  font-size: 0.875rem;
`

const themedBackground = css`
  background: ${props => props.theme.themeBackgroundColour}
    url(${props => props.theme.themeBackgroundAsset});
  background-repeat: no-repeat;
  background-size: cover;
  ${media.tablet} {
    background-size: 1081px;
    background-repeat: no-repeat;
    background: ${props => props.theme.themeBackgroundColour}
      url(${props => props.theme.themeBackgroundAsset});
  }
  ${media.phone} {
    background-size: 775px;
  }
`

const DaySeatsCountdownMessageBanner = styled.div`
  padding: 3rem 0;
  width: 100%;
  ${props =>
    props.theme.themeBackgroundColour
      ? themedBackground
      : `background: ${props.theme.colors.mainBackgroundColor};`}
  margin: 0 0 1.5rem 0;
  display: flex;
  justify-content: center;
  align-items: center;
  ${media.tablet} {
    padding: 1rem 5%;
    width: 90%;
  }
`

const DaySeatsListWrapper = WithLoader(styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  padding: 0 1.25rem 1.25rem 1.25rem;
  margin-top: 1rem;

  ${media.tablet} {
    padding: 0 1rem 2rem 1rem;
  }
`)

const DaySeatsCountdownWrapper = styled.div`
  box-sizing: border-box;
  padding: 1rem 1.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  ${media.tablet} {
    padding: 1rem 0;
    margin-top: 0;
  }
`

const DaySeatsCountdownMessageBody = styled.div`
  margin-bottom: 1rem;
  width: 600px;
  ${media.narrowdesktop} {
    width: 500px;
  }
  ${media.tablet} {
    width: 500px;
  }
  ${media.phone} {
    width: 100%;
  }
`

const StyledCopyBlock = styled(CopyBlock)`
  color: ${props => props.theme.colors.sidebarCopyColor};
`

const StyledTitle = styled(Title)`
  color: ${props => props.theme.colors.sidebarCopyColor};
`

const StyledCopyBlockText = styled.p`
  color: ${props => props.theme.colors.sidebarCopyColor};
`

const DaySeatsCountdownBanner = styled.img`
  width: 600px;
  margin: 2rem 0;
  ${media.narrowdesktop} {
    width: 500px;
  }
  ${media.tablet} {
    width: 500px;
  }
  ${media.phone} {
    width: 100%;
  }
`

const DaySeatsSelectPerformanceWrapper = styled.div``

type Props = {
  bookingWindowStart: Moment
  bookingWindowClose: Moment
  onPerformanceClick: (performanceId: string) => void
  eventId?: string
}

const DaySeatsListContainer: React.FC<Props> = ({
  bookingWindowStart,
  bookingWindowClose,
  onPerformanceClick,
  eventId
}) => {
  const [currentTime, setCurrentTime] = useState(moment().utc())

  let windowDates = daySeatEventWindow(eventId)
  let startDate = windowDates[0]
  let endDate = windowDates[1]

  const filteredPerformances = useTypedSelector(state =>
    selectPerformancesForRange(state, startDate, endDate)
  )
  const isLoading = useSelector(selectIsLoadingPerformances)
  const soldOut =
    filteredPerformances.filter(performance => performance.isSoldOut !== true)
      .length === 0

  return (
    <DaySeatsListWrapper isLoading={isLoading}>
      {currentTime.isBefore(bookingWindowStart) ||
      currentTime.isAfter(bookingWindowClose) ? (
        <>
          <DaySeatsCountdownWrapper>
            <DaySeatsCountdownMessageBanner>
              <DaySeatsCountdownMessageBody>
                <StyledCopyBlock textSize='large' isCentralised>
                  <StyledTitle
                    isCapitalised={false}
                    isCentralised={true}
                    isBold={true}
                    size='xlarge'
                  >
                    {daySeatTitle(eventId)}
                  </StyledTitle>

                  <DaySeatsCountdown
                    expiryDate={bookingWindowStart}
                    title={daySeatTitle(eventId)}
                    onExpiry={() => {
                      setCurrentTime(moment())
                    }}
                  />
                  <StyledCopyBlockText>
                    {daySeatWaitingText(eventId)}
                  </StyledCopyBlockText>
                  <DaySeatsCountdownBanner src={eventBannerImage(eventId)} />
                  <StyledCopyBlockText>
                    <Link
                      text={`Go to the LW Theatres website`}
                      href='https://lwtheatres.co.uk'
                      isUnderlined={true}
                      size='copy'
                      isSidebar={true}
                    />
                  </StyledCopyBlockText>
                </StyledCopyBlock>
              </DaySeatsCountdownMessageBody>
            </DaySeatsCountdownMessageBanner>
          </DaySeatsCountdownWrapper>
        </>
      ) : (
        <>
          <DaySeatsSelectPerformanceWrapper>
            <DaySeatsHeader rightButton={<LoginButtonContainer />} />
            <Title
              isCapitalised={false}
              isCentralised={false}
              isBold={true}
              size='xlarge'
            >
              {daySeatTitle(eventId)}
            </Title>
            <Title
              isCapitalised={false}
              isCentralised={false}
              isBold={true}
              size='medium'
            >
              Please select your performance
            </Title>
            {soldOut
              ? eventId && (
                  <DaySeatsCopy>{daySeatsSoldOutCopy(eventId)}</DaySeatsCopy>
                )
              : filteredPerformances.map(performance => (
                  <PerformanceListItem
                    performance={performance}
                    onBookClick={onPerformanceClick}
                    key={`${performance.id}`}
                  />
                ))}
          </DaySeatsSelectPerformanceWrapper>
        </>
      )}
    </DaySeatsListWrapper>
  )
}

export { DaySeatsListContainer }
