import React, { ReactNode } from 'react'
import styled, { css } from 'styled-components/macro'
import { media, lighten } from 'utils'

// Components
import { Title, CopyBlock } from 'marvel-components'

type Props = {
  title: string
  copy: ReactNode
  image?: string
  isThemed?: boolean
}

const SeatMapOverlayWrapper = styled.div`
  box-sizing: border-box;
  padding: 0 1.5rem;
  background: ${props => props.theme.colors.secondaryBackgroundColor};
  margin-top: 1.5rem;
  display: flex;
  justify-content: center;
  align-items: center;

  ${media.tablet} {
    padding: 1rem 5%;
    margin-top: 0;
  }
`

const themedBackground = css`
  background: ${props => props.theme.themeBackgroundColour}
    url(${props => props.theme.themeBackgroundAsset});
  background-repeat: no-repeat;
  background-size: cover;
  ${media.tablet} {
    background-size: 1081px;
    background-repeat: no-repeat;
    background: ${props => props.theme.themeBackgroundColour}
      url(${props => props.theme.themeBackgroundAsset});
  }
  ${media.phone} {
    background-size: 775px;
  }
`

const SeatMapOverlayMessageWrapper = styled.div<{ isThemed: boolean }>`
  padding: 0.5rem 5rem 1.5rem 5rem;
  ${props =>
    props.isThemed
      ? props.theme.themeBackgroundColour
        ? themedBackground
        : `background: ${props.theme.colors.mainBackgroundColor};`
      : `background: ${lighten(props.theme.colors.borderColor, 10)}`};
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 60%;
  margin-top: 1rem;
  ${media.tablet} {
    margin-top: 0;
    width: 90%;
    padding: 1.5rem 5%;
  }
`

const SeatMapOverlayMessageHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 1rem;
  justify-content: center;
  ${media.phone} {
    margin-bottom: 0;
  }
`
const SeatMapOverlayImageWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
  width: 100%;
`
const SeatMapOverlayImage = styled.img`
  width: 500px;
  margin: 2rem 0;
  ${media.narrowdesktop} {
    width: 400px;
  }
  ${media.tablet} {
    width: 400px;
  }
  ${media.phone} {
    width: 100%;
  }
`

const StyledTitle = styled(Title)<{ isThemed: boolean }>`
  color: ${props =>
    props.isThemed
      ? props.theme.colors.sidebarCopyColor
      : props.theme.colors.headingColor};
`

const SeatMapOverlayMessageCopyWrapper = styled.div`
  width: 100%;
`

const SeatMapOverlay: React.FC<Props> = ({
  title,
  copy,
  image,
  isThemed = false
}) => {
  return (
    <SeatMapOverlayWrapper>
      <SeatMapOverlayMessageWrapper isThemed={isThemed}>
        <SeatMapOverlayImageWrapper>
          <SeatMapOverlayImage src={image} />
        </SeatMapOverlayImageWrapper>
        <SeatMapOverlayMessageCopyWrapper>
          <SeatMapOverlayMessageHeader>
            <StyledTitle
              isCapitalised={false}
              isCentralised={true}
              isBold={true}
              size='large'
              isThemed={isThemed}
            >
              {title}
            </StyledTitle>
          </SeatMapOverlayMessageHeader>
          <CopyBlock isCentralised={true}>{copy}</CopyBlock>
        </SeatMapOverlayMessageCopyWrapper>
      </SeatMapOverlayMessageWrapper>
    </SeatMapOverlayWrapper>
  )
}

export { SeatMapOverlay }
