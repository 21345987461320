import React from 'react'
import styled, { css } from 'styled-components/macro'
import { lighten, FormattedPrice, media } from 'utils'
import { Icon } from 'marvel-components'

import { SegmentedControlItemProps } from './SegmentedControl'

interface Props extends SegmentedControlItemProps {
  onDeliveryOptionChange: (deliveryOptionId: string) => void
  isTempSelected?: boolean
  isStacked?: boolean
}

const SegmentedControlWrapper = styled.div<{
  isSelected: boolean
  width?: number
  isFixedHeight?: boolean
  isStacked?: boolean
}>(props => {
  const {
    theme: {
      colors: { borderColor, activeColor }
    },
    isSelected,
    width,
    isFixedHeight = true,
    isStacked
  } = props

  return css`
    margin: 0 0.5rem 0 0.5rem;
    padding: 0.625rem;
    display: flex;
    border: 0.0625rem solid ${borderColor};
    flex-direction: column;
    align-items: center;
    border-radius: 0.25rem;
    box-sizing: border-box;
    position: relative;
    min-height: ${isFixedHeight ? '100px' : 'initial'};
    cursor: pointer;
    background: #fff;
    ${media.phone} {
      margin: 0 0.25rem 0 0.25rem;
      padding: 0.5rem;
    }
    ${width &&
      css`
        width: ${width}%;
      `}

    :first-child {
      margin: 0 0.5rem 0 0;
      ${media.phone} {
        margin: 0 0.25rem 0 0;
      }
    }

    :last-child {
      margin: 0 0 0 0.5rem;
      ${media.phone} {
        margin: 0 0 0 0.25rem;
      }
    }

    ${isStacked &&
      css`
        width: 100%;
        margin: 0.25rem 0;
        ${media.phone} {
          margin: 0.5rem 0;
        }
        :first-child {
          margin: 0.25rem 0.5rem 0.25rem 0;
          ${media.phone} {
            margin: 0.5rem 0;
          }
        }
        :last-child {
          margin: 0.25rem 0 0.25rem 0.5rem;
          ${media.phone} {
            margin: 0.5rem 0;
          }
        }
      `}

    ${isSelected &&
      css`
        background: ${lighten(activeColor, 30)};

        :before {
          content: '';
          position: absolute;
          width: 100%;
          height: 2px;
          top: 0;
          left: 0;
          background: ${activeColor};
        }
      `}
  `
})

const OptionTextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  ${media.phone} {
    margin-top: 0.5rem;
  }
`

const OptionTitleWrapper = styled.div`
  width: 100%;
  justify-content: space-between;
  display: flex;
  flex-direction: row;
  font-size: 0.75rem;

  ${media.tablet} {
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
`

const OptionTitle = styled.span(props => {
  const {
    theme: {
      font: { header }
    }
  } = props

  return css`
    line-height: 1.2;
    ${media.tablet} {
      font-family: ${header};
    }
  `
})

const OptionPriceWrapper = styled.div`
  ${media.tablet} {
    margin-top: 0.25rem;
  }
`

const SegmentedTextWrapper = styled.span(props => {
  const {
    theme: {
      font: { header }
    }
  } = props

  return css`
    font-family: ${header};
    display: flex;
    padding: 0.25rem 0;
    font-size: 1rem;
    ${media.desktop} {
      font-size: 0.875rem;
    }
    ${media.tablet} {
      font-size: 0.75rem;
    }
    ${media.phone} {
      font-size: 0.875rem;
    }
  `
})

const SegmentedTextIconWrapper = styled.span`
  margin-right: 0.25rem;
  ${media.phone} {
    margin-right: 0.125rem;
  }
`

const IconWrapper = styled.div(props => {
  const {
    theme: {
      colors: { mainBackgroundColorComplement }
    }
  } = props

  return css`
    background: ${mainBackgroundColorComplement};
    position: absolute;
    top: 0.8rem;
    right: 0.8rem;
    border-radius: 2rem;
    fill: #8dd7a9;
    width: 1.2rem;
    height: 1.2rem;
    line-height: 1.3rem; /* Due to dodgy font awesome tick */
    text-align: center;
    font-size: 0.75rem;

    ${media.narrowdesktop} {
      width: 1rem;
      height: 1rem;
      font-size: 0.675rem;
      line-height: 1.15rem; /* Due to dodgy font awesome tick */
    }
  `
})

const SpinnerIconWrapper = styled.div`
  position: absolute;
  top: 0.87rem;
  right: 0.685rem;
  fill: #000;
  text-align: center;
  font-size: 1.2rem;
  ${media.narrowdesktop} {
    top: 0.8rem;
    right: 0.7rem;
    font-size: 1rem;
  }
`

const OptionImageWrapper = styled.img`
  max-width: 5.5rem;
  width: 5.5rem;
  margin: 0 0 1rem 0;

  ${media.tablet} {
    margin: 0;
  }
  ${media.phone} {
    max-width: 3.5rem;
  }
`
const DescriptionWrapper = styled.div(props => {
  const {
    theme: {
      colors: { copyColor }
    }
  } = props

  return css`
    margin-top: 0.75rem;
    font-size: 0.75rem;
    color: ${copyColor};
    line-height: 1.25;

    ${media.tablet} {
      display: none;
    }
  `
})

export const SegmentedControlItem = (props: Props) => {
  const {
    id,
    name,
    price = 0,
    onDeliveryOptionChange,
    isSelected = false,
    imageUrl,
    description,
    width,
    isTempSelected,
    icon,
    iconPrefix,
    isStacked = false
  } = props

  return (
    <SegmentedControlWrapper
      onClick={() => onDeliveryOptionChange(id)}
      isSelected={isSelected}
      width={width}
      isFixedHeight={!icon}
      isStacked={isStacked}
    >
      {isSelected && (
        <IconWrapper>
          <Icon icon={'check'} />
        </IconWrapper>
      )}
      {isTempSelected && (
        <SpinnerIconWrapper>
          <Icon icon={'circle-notch'} isSpinning />
        </SpinnerIconWrapper>
      )}
      {/* Show Iconised Text */}
      {icon && (
        <SegmentedTextWrapper>
          <SegmentedTextIconWrapper>
            <Icon icon={icon} prefix={iconPrefix} padding="default" />
          </SegmentedTextIconWrapper>
          <OptionTitle>{name}</OptionTitle>
        </SegmentedTextWrapper>
      )}
      {!icon && (
        <>
          {imageUrl && <OptionImageWrapper src={imageUrl} alt={name} />}
          <OptionTextWrapper>
            <OptionTitleWrapper>
              <OptionTitle>{name}</OptionTitle>
              <OptionPriceWrapper>
                <FormattedPrice
                  isFreeShown={true}
                  amount={price}
                  currency="GBP"
                />
              </OptionPriceWrapper>
            </OptionTitleWrapper>
            {description && (
              <DescriptionWrapper
                dangerouslySetInnerHTML={{ __html: description }}
              />
            )}
          </OptionTextWrapper>
        </>
      )}
    </SegmentedControlWrapper>
  )
}
