import { useCallback, useEffect, useState } from 'react'

// Reference: https://reactjs.org/docs/hooks-faq.html#how-can-i-measure-a-dom-node
const useClientRect = <T extends HTMLElement>(): [
  DOMRect,
  (node: T) => void
] => {
  const [rect, setRect] = useState<DOMRect>(new DOMRect(0, 0, 0, 0))
  const [node, setNode] = useState<T | null>(null)

  const updateRect = useCallback(() => {
    node && setRect(node.getBoundingClientRect())
  }, [node, setRect])

  const ref = useCallback(
    (node: T) => {
      setNode(node)
      updateRect()
    },
    [setNode, updateRect]
  )

  useEffect(() => {
    window.addEventListener('resize', updateRect)
    return () => window.removeEventListener('resize', updateRect)
  }, [ref, updateRect])

  return [rect, ref]
}

export { useClientRect }
