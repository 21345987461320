import React from 'react'

// Components
import { CalendarConsumer } from './Calendar'
import { Icon, ToolTip } from 'marvel-components'

// Types
import { Performance, Tag } from 'shared-types'

// Misc
import styled, { css } from 'styled-components/macro'
import moment from 'moment'
import { darken, media, lighten, FormattedPrice, usePlatform } from 'utils'

interface Props extends Performance {
  onPerformanceClick: (performanceId: string) => void
  platform?: string
}

const PerformanceTileWrapper = styled.div<{
  isDisabled?: boolean
  isSmallCalendar?: boolean
  isDiscounted?: boolean
  tags?: Tag[]
  platform?: string
  isSelected: boolean
}>(props => {
  const {
    theme: {
      colors: {
        copyColor,
        secondaryBackgroundColorComplement,
        bestAvailablePerformanceColor,
        bestAvailablePerformanceColorComplement,
        discountedPerformanceColor,
        discountedPerformanceColorComplement,
        performanceHoverColor
      }
    },
    isSmallCalendar,
    isSelected,
    isDiscounted,
    tags,
    platform
  } = props

  let background = lighten(secondaryBackgroundColorComplement, 52)
  let color = copyColor
  let hoverColor = darken(color, 5)
  let tileHoverColor = performanceHoverColor

  if (tags && tags.find(tag => tag.name === 'Targeted Event')) {
    background = bestAvailablePerformanceColor
    color = bestAvailablePerformanceColorComplement
    hoverColor = bestAvailablePerformanceColorComplement
    tileHoverColor = darken(bestAvailablePerformanceColor, 10)
  }

  if (isDiscounted) {
    background = discountedPerformanceColor
    color = discountedPerformanceColorComplement
    hoverColor = discountedPerformanceColorComplement
  }

  return css`
    position: relative;
    z-index: 0;
    right: 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
    width: 100%;
    border-radius: 0.1rem;
    padding: 0.375rem;
    margin-bottom: 0.3125rem;
    box-sizing: border-box;
    background: ${background};
    font-size: 0.75rem;
    cursor: pointer;
    color: ${color};
    line-height: 1.25;
    outline: none;
    border: ${isSelected ? '1px solid' : 'none'};
    border-color: ${color};
    align-items: center;

    :hover {
      background: ${tileHoverColor};
    }

    :focus {
      background: ${darken(background, 5)};
    }

    :hover > * {
      color: ${hoverColor};
    }

    ${media.tablet} {
      font-size: 0.55rem;
      align-items: center;
    }

    ${media.tablet} {
      :before {
        content: none;
      }

      display: flex;
      flex-direction: column;
      padding: 0.25rem 0;
      font-size: ${platform === 'kiosk' ? '1rem' : '0.625rem'};
      text-align: center;
      width: 90%;
      box-sizing: border-box;
      line-height: 1.25;
    }
    ${media.phone} {
      font-size: 0.5rem;
    }

    ${isSmallCalendar &&
    css`
      :before {
        content: '';
      }
      display: flex;
      align-items: center;
      flex-direction: column;
      text-align: center;
      width: 100%;
      box-sizing: border-box;
      line-height: 1.25;
      font-size: 0.5rem;
      padding: 0.375rem 0;

      ${media.tablet} {
        font-size: ${platform === 'kiosk' ? '0.75rem' : '0.55rem'};
        width: 100%;
      }
    `}
  `
})

const TimeLabel = styled.span<{
  isSelected: boolean
  tags?: Tag[]
  isDiscounted?: boolean
  isSmallCalendar?: boolean
}>(props => {
  const {
    theme: {
      colors: {
        mainColor,
        copyColor,
        bestAvailablePerformanceColorComplement,
        discountedPerformanceColorComplement
      },
      font: { copy, header }
    },
    isSelected,
    isSmallCalendar,
    tags,
    isDiscounted
  } = props

  let color = darken(copyColor, 10)

  if (tags && tags.find(tag => tag.name === 'Targeted Event')) {
    color = bestAvailablePerformanceColorComplement
  }

  if (isDiscounted) {
    color = discountedPerformanceColorComplement
  }

  return css`
    position: relative;
    color: ${color};
    justify-content: space-between;
    font-family: ${isSelected ? header : copy};
    margin-right: 0.25rem;
    display: flex;

    ${media.desktop} {
      flex-direction: column;
    }

    ${media.tablet} {
      margin-right: 0.125rem;
      flex-direction: row;
      :hover > {
        color: ${darken(mainColor, 20)};
      }
    }
    ${media.phone} {
      flex-direction: column;
      margin-right: 0;
    }
    ${isSmallCalendar &&
    css`
      margin-right: 0;
      flex-direction: column;
    `}
  `
})

const NotesIconWrapper = styled.div`
  margin-left: 0.25rem;

  ${media.desktop} {
    margin-left: 0;
  }
`

const SoldOutWrapper = styled.span<{ isSmallCalendar?: boolean }>(props => {
  const { isSmallCalendar } = props
  return css`
    text-transform: uppercase;
    text-align: center;
    font-size: 0.625rem;

    ${media.tablet} {
      font-size: 0.5rem;
    }

    ${isSmallCalendar &&
    css`
      font-size: 0.55rem;
    `}
  `
})

const StyledFormattedPrice = styled(FormattedPrice)`
  margin: 0 0.125rem;
`

export const PerformanceTile: React.FC<Props> = ({
  id,
  startDate,
  onPerformanceClick,
  isSoldOut = true,
  minTicketPrice,
  isDiscounted = false,
  tags,

  // Notes
  notes
}) => {
  const className = `calendar-performance-tile-${id}`

  const handlePerformanceClick = (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => {
    e.preventDefault()
    !isSoldOut && onPerformanceClick && onPerformanceClick(id)
  }

  const handleKeyUp = (e: React.KeyboardEvent<HTMLDivElement>) => {
    const { keyCode } = e

    if (keyCode === 13) {
      !isSoldOut && onPerformanceClick && onPerformanceClick(id)
    }
  }

  const isHidden = tags && tags.find(tag => tag.name === 'Hide')
  const platform = usePlatform()

  return (
    <>
      {!isHidden && (
        <CalendarConsumer>
          {calendarContext => (
            <PerformanceTileWrapper
              className={className}
              onClick={handlePerformanceClick}
              isSmallCalendar={calendarContext.isSmallCalendar}
              tags={tags}
              isDiscounted={isDiscounted}
              tabIndex={0}
              onKeyUp={handleKeyUp}
              platform={platform}
              isSelected={id === calendarContext.selectedPerformanceId}
            >
              <TimeLabel
                isSelected={id === calendarContext.selectedPerformanceId}
                tags={tags}
                isDiscounted={isDiscounted}
                isSmallCalendar={calendarContext.isSmallCalendar}
              >
                {moment.utc(startDate).format('h:mma')}
                {notes && (
                  <ToolTip content={notes}>
                    <NotesIconWrapper>
                      <Icon icon='info-circle' />
                    </NotesIconWrapper>
                  </ToolTip>
                )}
              </TimeLabel>
              {!isSoldOut ? (
                <StyledFormattedPrice
                  amount={minTicketPrice}
                  currency='GBP'
                  isDecimalsVisible={true}
                />
              ) : (
                <SoldOutWrapper
                  isSmallCalendar={calendarContext.isSmallCalendar}
                >
                  Sold Out
                </SoldOutWrapper>
              )}
            </PerformanceTileWrapper>
          )}
        </CalendarConsumer>
      )}
    </>
  )
}
