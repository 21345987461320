import axios from 'axios'
import { APIResponse } from 'shared-types'

const axiosInstance = axios.create({
  baseURL: `${process.env.REACT_APP_API_ROOT}/`
})

const selfServeApi = {
  async get(url: string, params?: any, accessToken?: string) {
    const result = await axiosInstance.get<APIResponse>(url, {
      params,
      headers: {
        'Content-Type': 'application/json',
        ...(accessToken && { Authorization: `Bearer ${accessToken}` })
      }
    })
    return result.data
  },
  async post(url: string, data?: any, accessToken?: string) {
    const result = await axiosInstance.post<APIResponse>(url, data, {
      headers: {
        'Content-Type': 'application/json',
        ...(accessToken && { Authorization: `Bearer ${accessToken}` })
      }
    })
    return result.data
  }
}

export default selfServeApi
