import React from 'react'

// Components
import { SeatTitle, Icon, Button, LazyImage } from 'marvel-components'

// Selectors
import {
  selectSeatWithTickets,
  getViewFromSeatUrl
} from 'modules/seat-map/selectors'
// Utils
import { FormattedPrice, getSeatDisplay, fadeIn, media } from 'utils'
import styled from 'styled-components/macro'

// Types
import { TicketWithSeat } from 'shared-types'
import { useTypedSelector } from 'modules'

const SeatOverlayWrapper = styled.div`
  padding: 0.5rem 0.25rem;
  background-color: #fff;
  animation: ${fadeIn} 0.2s;
  ${media.tablet} {
    padding: 1rem;
  }
`

const SeatOverlayHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  font-family: ${props => props.theme.font.header};
  color: ${props => props.theme.colors.headingColor};
`

const PriceWrapper = styled.div`
  display: flex;
  font-size: 1rem;
  line-height: 1.2;
`

const PriceSeparator = styled.span`
  margin-left: 0.2rem;
  margin-right: 0.2rem;
`

const SeatOverlayNotes = styled.div<{ messageBackground: string }>`
  background-color: ${props => props.messageBackground};
  width: 96%;
  padding: 0.35rem 2%;
  color: #111111;
  border-radius: 0.1rem;
  display: flex;
  flex-direction: row;
  font-size: 0.75rem;
  text-transform: none;
  margin-top: 0.625rem;
`

const SeatOverlayNotesCopy = styled.span`
  margin-left: 0.25rem;
`

const StyledButton = styled(Button)`
  margin-top: 1rem;
`

type Props = {
  seatId: string
  priceTypeId?: string
  onRemove?: (seat: TicketWithSeat) => void
}

const SeatToolTipContainer: React.FC<Props> = ({
  seatId,
  priceTypeId,
  onRemove
}) => {
  const seatDetail = useTypedSelector(state =>
    selectSeatWithTickets(state, seatId)
  )
  const imageUrl = useTypedSelector(state => getViewFromSeatUrl(state, seatId))

  if (!seatDetail) return null
  const seatDisplay = getSeatDisplay(seatDetail.seatType)

  const handleRemoveSeat = (priceTypeId: string) => {
    onRemove &&
      onRemove({
        id: seatDetail.id,
        areaId: seatDetail.areaId,
        priceLevelId: seatDetail.priceLevel.id,
        priceTypeId: priceTypeId,
        performanceId: seatDetail.performanceId
      })
  }

  const renderHeaderPrice = () => {
    if (priceTypeId) {
      return (
        <FormattedPrice
          amount={
            seatDetail.priceLevel.priceTypes.find(
              priceType => priceType.id === priceTypeId
            )?.total ?? 0
          }
          currency='GBP'
        />
      )
    } else if (seatDetail.priceLevel.priceTypes.length === 1) {
      return (
        <FormattedPrice
          amount={seatDetail.priceLevel.priceTypes[0].total}
          currency='GBP'
        />
      )
    } else if (seatDetail.priceLevel.priceTypes.length > 1) {
      const priceTypeTotals = seatDetail.priceLevel.priceTypes.map(
        priceType => priceType.total
      )

      return (
        <>
          <FormattedPrice
            amount={Math.min(...priceTypeTotals)}
            currency='GBP'
          />
          <PriceSeparator>-</PriceSeparator>
          <FormattedPrice
            amount={Math.max(...priceTypeTotals)}
            currency='GBP'
          />
        </>
      )
    } else {
      return null
    }
  }

  return (
    <SeatOverlayWrapper>
      <SeatOverlayHeader>
        <SeatTitle
          areaName={seatDetail.areaName}
          seatName={seatDetail.seatName}
          rowName={seatDetail.rowName}
          priceLevelColor={seatDetail.priceLevel.background}
        />
        <PriceWrapper>{renderHeaderPrice()}</PriceWrapper>
      </SeatOverlayHeader>
      {imageUrl && (
        <LazyImage
          url={imageUrl}
          alt={`View from seat for seat ${seatDetail.seatName} row ${seatDetail.rowName}`}
        />
      )}
      {seatDisplay?.icon && seatDisplay?.note && (
        <SeatOverlayNotes messageBackground={seatDisplay.background}>
          <Icon icon={seatDisplay.messageIcon} />
          <SeatOverlayNotesCopy>{seatDisplay.note}</SeatOverlayNotesCopy>
        </SeatOverlayNotes>
      )}
      {onRemove && priceTypeId && (
        <StyledButton
          text='Remove Seat'
          onClick={() => handleRemoveSeat(priceTypeId)}
          isBlock={true}
          icon='trash-alt'
          size='small'
        />
      )}
    </SeatOverlayWrapper>
  )
}

export { SeatToolTipContainer }
