import { AppState } from 'modules/types'

export const selectPerformanceFilters = (state: AppState) =>
  state.interactions.performanceFilters

export const selectLowerPricePerformanceFilter = (state: AppState) =>
  state.interactions.lowerPricePerformanceFilter

export const selectUpperPricePerformanceFilter = (state: AppState) =>
  state.interactions.upperPricePerformanceFilter

export const getHasUserUpdatedBasket = (state: AppState) =>
  state.interactions.hasUserUpdatedBasket
