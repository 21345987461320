import React, { useEffect } from 'react'

// Views
import { PaymentView, KioskPaymentView } from 'views/Ticketing'

// Actions
import { fetchReservation } from 'modules/basket/actions'

// Selectors
import {
  getContextId,
  selectTransactionState,
  selectReservationGuid,
  selectBasketIdle,
  selectBasketLoaded
} from 'modules/basket/selectors'

// Misc
import { useDispatch, useSelector } from 'react-redux'
import {
  useParams,
  useRouteMatch,
  Redirect,
  Switch,
  Route
} from 'react-router-dom'

// Utils
import { orderSummaryRoute, calendarRoute } from 'utils'

type Props = {
  eventId: string
}
const CheckoutView: React.FC<Props> = ({ eventId }) => {
  // Routing
  const { transactionGuid } = useParams<{ transactionGuid: string }>()
  const { path } = useRouteMatch()

  // Selectors
  const dispatch = useDispatch()
  const contextId = useSelector(getContextId)
  const reservationGuid = useSelector(selectReservationGuid)
  const reservationIdle = useSelector(selectBasketIdle)
  const reservationLoaded = useSelector(selectBasketLoaded)
  const transactionState = useSelector(selectTransactionState)

  // Fetch Reservation if refreshing the page
  useEffect(() => {
    if (contextId && reservationIdle) {
      dispatch(
        fetchReservation({
          transactionGuid
        })
      )
    }
  }, [dispatch, contextId, transactionGuid, reservationIdle])

  // Redirect if transaction is confirmed
  // --------------------------------------
  if (transactionState === 'CommittedConfirmed') {
    return (
      <Redirect
        to={{
          ...orderSummaryRoute(transactionGuid),
          search: '?ga=true' // Track GA purchase
        }}
      />
    )
  } else if ((reservationLoaded && !reservationGuid) || !contextId) {
    return <Redirect to={calendarRoute(eventId)} />
  }

  return (
    <Switch>
      <Route path={`${path}/checkout`}>
        <PaymentView eventId={`${eventId}`} />
      </Route>
      <Route path={`${path}/kiosk-payment`}>
        <KioskPaymentView eventId={`${eventId}`} />
      </Route>
    </Switch>
  )
}
export { CheckoutView }
