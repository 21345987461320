import React from 'react'
import styled, { css } from 'styled-components/macro'
import {
  FacebookShareButton,
  TwitterShareButton,
  FacebookIcon,
  TwitterIcon
} from 'react-share'
import { media } from 'utils'

interface Props {
  socialMediaUrl?: string
  twitterTitle?: string
  twitterHashtags?: string[]
}

const shareButton = css`
  color: #fff;
  fill: #fff;
  display: flex;
  flex-direction: row;
  padding: 0.75rem 1rem 0.75rem 0.5rem;
  outline: none;
  cursor: pointer;
  align-items: center;
  border-radius: 0.1rem;
  justify-content: center;
  text-align: center;
  border: 0;
  font-size: 0.875rem;
`

const FacebookLink = styled(FacebookShareButton)`
  background-color: #3b5998;
  ${shareButton}
`

const StyledFacebookIcon = styled(FacebookIcon)`
  margin-right: 0.5rem;
`

const StyledTwitterIcon = styled(TwitterIcon)`
  margin-right: 0.5rem;
`

const TwitterLink = styled(TwitterShareButton)`
  background-color: #00aced;
  margin-left: 1rem;
  ${shareButton}

  ${media.tablet} {
    margin-left: 0;
    margin-top: 0.5rem;
  }
`

const SocialMediaWrapper = styled.div`
  display: flex;
  flex-direction: row;
  font-family: ${(props) => props.theme.font.header};
  text-transform: uppercase;
  font-size: 0.75rem;
  margin: 1.5rem 0;

  ${media.tablet} {
    flex-direction: column;
  }
`

export const SocialMedia = (props: Props) => {
  const { socialMediaUrl, twitterTitle = '', twitterHashtags = [] } = props

  return (
    <SocialMediaWrapper>
      {socialMediaUrl && (
        <FacebookLink url={socialMediaUrl} resetButtonStyle={false}>
          {/* <Icon icon="facebook-f" prefix="fab" padding="default" /> */}
          <StyledFacebookIcon size="32" iconFillColor="white" />
          Share on Facebook
        </FacebookLink>
      )}
      {socialMediaUrl && (
        <TwitterLink
          url={socialMediaUrl}
          title={twitterTitle}
          hashtags={twitterHashtags}
          resetButtonStyle={false}
        >
          <StyledTwitterIcon size="32" iconFillColor="white" />
          Share on Twitter
        </TwitterLink>
      )}
    </SocialMediaWrapper>
  )
}
