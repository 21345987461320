import React, { ReactNode } from 'react'
import styled, { css } from 'styled-components/macro'
import { FormattedPrice, media } from 'utils'

interface Props {
  children: ReactNode
  price?: number
  isBold?: boolean
  isFreeShown?: boolean
  isZeroShown?: boolean
}

const PricedItemWrapper = styled.div<{ isBold?: boolean }>(props => {
  const {
    theme: {
      colors: { sidebarCopyColor },
      font: { copy, header }
    },
    isBold = false
  } = props

  return css`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 0;
    color: ${sidebarCopyColor};
    font-family: ${isBold ? header : copy};
    align-items: flex-start;
    line-height: 1.5;
    font-size: 0.875rem;
    ${media.phone} {
    }
  `
})

const FormattedPriceWrapper = styled.div`
  margin-left: 2rem;
  line-height: 1.5;
  font-size: 0.875rem;
  flex: 0 0 auto;
`

export const PricedItem = (props: Props) => {
  const {
    children,
    price,
    isBold = false,
    isFreeShown = false,
    isZeroShown = false
  } = props
  return (
    <PricedItemWrapper isBold={isBold}>
      <div>{children}</div>
      {price !== undefined && (
        <FormattedPriceWrapper>
          <FormattedPrice
            amount={price}
            currency='GBP'
            isFreeShown={isFreeShown}
            isZeroShown={isZeroShown}
          />
        </FormattedPriceWrapper>
      )}
    </PricedItemWrapper>
  )
}
