import { createSlice } from '@reduxjs/toolkit'

import { resetHospitality } from 'modules/hospitality/shared'

// Utils
import hospitalityApi from 'services/hospitalityApi'
import { createAPIThunk } from 'modules/hospitality/shared'

// Types
import {
  OptinResponse,
  HospitalityOrder,
  PaymentChallenge,
  PaymentChallengeResponse
} from 'shared-types'
import { getKioskAccessToken } from 'modules/self-serve/shared/utils'

interface CreateOrderPayload {
  siteId: EntityId
  occasionId: EntityId
  menuIds: EntityId[]
  adults: number
  children?: number
  firstName: string
  lastName: string
  email: string
  telephoneNumber: string
  date: string
  amount: number
  consent: { [key: string]: boolean }
  payment?: any
  optins?: OptinResponse[]
}

interface UpdateOrderPayload {
  orderId: EntityId
  paymentDetails?: PaymentChallengeResponse
  payment?: any
}

interface OrderResponse extends HospitalityOrder {
  action: PaymentChallenge
}

const createOrder = createAPIThunk<OrderResponse, CreateOrderPayload>(
  'marvel/hospitality/CREATE_ORDER',
  orderData =>
    hospitalityApi.create(`/order/`, orderData, getKioskAccessToken())
)
const updateOrder = createAPIThunk<OrderResponse, UpdateOrderPayload>(
  'marvel/hospitality/UPDATE_ORDER',
  orderData =>
    hospitalityApi.update(`/order/${orderData.orderId}/`, {
      paymentDetails: orderData.paymentDetails,
      payment: orderData.payment
    })
)
const fetchOrder = createAPIThunk<OrderResponse, string>(
  'marvel/hospitality/FETCH_ORDER',
  orderId => hospitalityApi.fetchAll(`/order/${orderId}/`)
)

interface OrderState {
  loading: string
  order?: HospitalityOrder
  action?: PaymentChallenge
}

const initialState: OrderState = {
  loading: 'idle'
}

const orderSlice = createSlice({
  name: 'order',
  initialState: initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(createOrder.pending, state => {
        state.order = undefined
        state.action = undefined
        state.loading = 'loading'
      })
      .addCase(createOrder.fulfilled, (state, action) => {
        state.order = action.payload
        state.action = action.payload.action
        state.loading = 'idle'
      })
      .addCase(updateOrder.fulfilled, (state, action) => {
        state.order = action.payload
        state.action = action.payload.action
        state.loading = 'idle'
      })
      .addCase(createOrder.rejected, state => {
        state.order = undefined
        state.action = undefined
        state.loading = 'idle'
      })
      .addCase(updateOrder.rejected, state => {
        state.action = undefined
        state.loading = 'idle'
      })
      .addCase(fetchOrder.pending, state => {
        state.order = undefined
        state.loading = 'loading'
      })
      .addCase(fetchOrder.fulfilled, (state, action) => {
        state.order = action.payload
        state.loading = 'idle'
      })
      .addCase(resetHospitality, state => initialState)
  }
})

// Actions
export const orderActions = { createOrder, updateOrder, fetchOrder }

export default orderSlice.reducer
