import { menuItemAdapter } from 'modules/smart-waiter/menu-item'

// Selectors
import { menuSectionSelectors } from 'modules/smart-waiter/menu-section'

// Misc
import { createSelector } from '@reduxjs/toolkit'

// Types
import { AppState } from 'modules/types'

const {
  selectById,
  selectIds,
  selectEntities,
  selectAll,
  selectTotal
} = menuItemAdapter.getSelectors<AppState>(
  (state) => state.smartWaiter.menuItems
)

const selectItemsBySectionId = (sectionId: EntityId) =>
  createSelector(
    [
      (state: AppState) => menuSectionSelectors.selectById(state, sectionId),
      (state: AppState) => selectAll(state)
    ],
    (section, menuItems) => {
      if (section) {
        const { items = [] } = section
        return menuItems.filter((item) => items.includes(item.id))
      }
      return []
    }
  )
const isLoading = (state: AppState) =>
  state.smartWaiter.menuItems.loading === 'pending'

const selectMinimumAge = (state: AppState) =>
  state.smartWaiter.menuItems.minimumAge

export const menuItemSelectors = {
  selectById,
  selectItemsBySectionId,
  selectIds,
  selectEntities,
  selectAll,
  selectTotal,
  isLoading,
  selectMinimumAge
}
