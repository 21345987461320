import React, { useEffect } from 'react'

// Containers
import { BreadcrumbContainer } from '../../BreadcrumbContainer'

// Components
import { Title, ListItem, List, WithLoader } from 'marvel-components'

// Selectors
import { venueSelectors } from 'modules/smart-waiter/venue'

// Actions
import { resetSW } from 'modules/smart-waiter/shared/actions'

// Misc
import { useSelector, useDispatch } from 'react-redux'
import styled from 'styled-components'
import { media } from 'utils'
import { useHistory, useRouteMatch } from 'react-router-dom'
import { smartWaiterRoutes } from '../SmartWaiterRoutes'

// Types
import { AppState } from 'modules/types'

const SalesAreaListContainerWrapper = styled.div`
  box-sizing: border-box;
  padding: 1.5rem 0 2.5rem 0;
  ${media.tablet} {
    padding: 1rem 0 1.5rem 0;
    margin-top: 0;
    min-height: calc(100vh - 109px);
  }
`

const SalesAreaListDetails = styled.div`
  padding: 0 2%;
  ${media.tablet} {
    padding: 0 3%;
  }
  ${media.phone} {
    padding: 0 5%;
  }
`

const SalesAreaListWrapper = WithLoader(styled.div`
  margin: 1rem 0;
`)

type Props = {
  venueId: EntityId
}

const SalesAreaListContainer: React.FC<Props> = ({ venueId }) => {
  const dispatch = useDispatch()

  // Selectors
  const venue = useSelector((state: AppState) =>
    venueSelectors.selectById(state, venueId)
  )
  const isLoading = useSelector(venueSelectors.selectIsVenueListLoading)

  // Routing
  const history = useHistory()
  const { url } = useRouteMatch()

  // Hooks
  useEffect(() => {
    dispatch(resetSW())
  }, [dispatch])

  // Action Handlers
  const handleSalesAreaClick = (salesAreaId: EntityId) => {
    history.push(`${url}/${salesAreaId}/service`)
  }

  return (
    <SalesAreaListContainerWrapper>
      <SalesAreaListDetails>
        <BreadcrumbContainer routes={smartWaiterRoutes} />
        <Title
          isCapitalised={false}
          isCentralised={false}
          isBold={true}
          size='medium'
        >
          Please select the bar you wish to order from:
        </Title>
      </SalesAreaListDetails>
      <SalesAreaListWrapper isLoading={isLoading}>
        <List>
          {venue &&
            venue.salesAreas.map(salesArea => {
              return (
                <ListItem
                  key={salesArea.id}
                  id={salesArea.id}
                  title={salesArea.name}
                  onClick={handleSalesAreaClick}
                  light={true}
                />
              )
            })}
        </List>
      </SalesAreaListWrapper>
    </SalesAreaListContainerWrapper>
  )
}

export { SalesAreaListContainer }
