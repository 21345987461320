import { schema } from 'normalizr'

// Types
import { HospitalityArea, HospitalitySlot, Occasion, Site } from 'shared-types'

export const siteEntity = new schema.Entity<Site>('sites')
export const sitesEntity = [siteEntity]

export const areaEntity = new schema.Entity<HospitalityArea>('areas')
export const areasEntity = [areaEntity]

export const occasionEntity = new schema.Entity<Occasion>('occasions')
export const occasionsEntity = [occasionEntity]

export const slotEntity = new schema.Entity<HospitalitySlot>(
  'slots',
  undefined,
  { idAttribute: 'date' }
)
export const slotsEntity = [slotEntity]

export const menuEntity = new schema.Entity<Occasion>('menus')
export const menusEntity = [menuEntity]
