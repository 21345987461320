import { AppState } from 'modules'
import { createSelector } from 'reselect'
import { navigationItemAdapter, screenSaverSlideAdapter } from './contentSlice'

export const selectContentStatus = (state: AppState) =>
  state.selfServe.content.status

const navigationItemSelectors = navigationItemAdapter.getSelectors()

export const selectNavigationItemsState = (state: AppState) =>
  state.selfServe.content.navigationItems

export const selectNavigationItems = createSelector(
  selectNavigationItemsState,
  navigationItemSelectors.selectAll
)

const screenSaverSlideSelectors = screenSaverSlideAdapter.getSelectors()

export const selectScreenSaverSlidesState = (state: AppState) =>
  state.selfServe.content.screenSaverSlides

export const selectScreenSaverSlides = createSelector(
  selectScreenSaverSlidesState,
  screenSaverSlideSelectors.selectAll
)
