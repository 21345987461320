import { Link } from 'marvel-components'
import moment from 'moment'
import styled from 'styled-components'
import { calendarRoute } from 'utils'
import { eventIds } from './event-ids'

const DEFAULT_BOOKING_WINDOW_START_HOUR = 10 // UTC
const DEFAULT_BOOKING_WINDOW_END_HOUR = 12 // UTC

const StyledLink = styled(Link)`
  color: inherit;
`

export const daySeatTitle = (eventId?: string) => {
  switch (eventId) {
    case eventIds.development.phantomNew:
    case eventIds.production.phantomNew:
      return 'The Phantom of the Opera Day Seats'
    case eventIds.development.beautyAndTheBeast:
    case eventIds.production.beautyAndTheBeast:
      return 'Beauty and the Beast Magical Mondays'
    case eventIds.development.lionWitchWardrobe:
    case eventIds.production.lionWitchWardrobe:
      return 'Fantastic Fridays'
    default:
      return 'Frozen Magical Mondays'
  }
}

export const daySeatBookingWindow = (eventId?: string) => {
  const now = moment.utc()
  switch (eventId) {
    case eventIds.development.frozen:
    case eventIds.production.frozen:
      let openDay = 1
      let closeDay = 1
      let openHour = 12 // UTC
      let closeHour = 18 // UTC
      let today = moment.utc().day()
      let openTime = moment
        .utc()
        .day(openDay)
        .hour(openHour)
        .minute(0)
        .second(0)
      let closeTime = moment
        .utc()
        .day(closeDay)
        .hour(closeHour)
        .minute(0)
        .second(0)
      let nextOpen = moment
        .utc()
        .day(openDay + 7)
        .hour(openHour)
        .minute(0)
        .second(0)
      let nextClose = moment
        .utc()
        .day(closeDay + 7)
        .hour(closeHour)
        .minute(0)
        .second(0)
      if (today <= closeDay) {
        // Today is the same day as the booking window
        return now > closeTime ? [nextOpen, nextClose] : [openTime, closeTime]
      }
      return [nextOpen, nextClose]

    case eventIds.development.beautyAndTheBeast:
    case eventIds.production.beautyAndTheBeast:
      openDay = 1
      closeDay = 1
      openHour = 12 // UTC
      closeHour = 14 // UTC
      today = moment.utc().day()
      openTime = moment.utc().day(openDay).hour(openHour).minute(0).second(0)
      closeTime = moment.utc().day(closeDay).hour(closeHour).minute(0).second(0)
      nextOpen = moment
        .utc()
        .day(openDay + 7)
        .hour(openHour)
        .minute(0)
        .second(0)
      nextClose = moment
        .utc()
        .day(closeDay + 7)
        .hour(closeHour)
        .minute(0)
        .second(0)
      if (today <= closeDay) {
        // Today is the same day as the booking window
        return now > closeTime ? [nextOpen, nextClose] : [openTime, closeTime]
      }
      return [nextOpen, nextClose]

    case eventIds.development.lionWitchWardrobe:
    case eventIds.production.lionWitchWardrobe:
      openDay = 5
      closeDay = 5
      openHour = 12 // UTC
      closeHour = 18 // UTC
      today = moment.utc().day()
      openTime = moment.utc().day(openDay).hour(openHour).minute(0).second(0)
      closeTime = moment.utc().day(closeDay).hour(closeHour).minute(0).second(0)
      nextOpen = moment
        .utc()
        .day(openDay + 7)
        .hour(openHour)
        .minute(0)
        .second(0)
      nextClose = moment
        .utc()
        .day(closeDay + 7)
        .hour(closeHour)
        .minute(0)
        .second(0)
      if (today <= closeDay) {
        // Today is the same day as the booking window
        return now > closeTime ? [nextOpen, nextClose] : [openTime, closeTime]
      }
      return [nextOpen, nextClose]

    default:
      const openDate = moment
        .utc()
        .hour(DEFAULT_BOOKING_WINDOW_START_HOUR)
        .minute(0)
        .second(0)
      const closeDate = moment
        .utc()
        .hour(DEFAULT_BOOKING_WINDOW_END_HOUR)
        .minute(0)
        .second(0)
      if (now > closeDate) {
        return [openDate.add(1, 'day'), closeDate.add(1, 'day')]
      }
      return [openDate, closeDate]
  }
}

export const daySeatEventWindow = (eventId?: string) => {
  const now = moment.utc()
  switch (eventId) {
    case eventIds.development.frozen:
    case eventIds.production.frozen:
    case eventIds.development.beautyAndTheBeast:
    case eventIds.production.beautyAndTheBeast:
      let openDay = 1
      let closeDay = 7
      let today = now.day()
      let openTime = moment().utc().day(openDay).startOf('day')
      let closeTime = moment().utc().day(closeDay).endOf('day')
      let nextOpen = moment()
        .utc()
        .day(openDay + 7)
        .startOf('day')
      let nextClose = moment()
        .utc()
        .day(closeDay + 7)
        .endOf('day')

      if (today <= closeDay) {
        // Today is the same day as the booking window
        return now > closeTime ? [nextOpen, nextClose] : [openTime, closeTime]
      }
      return [nextOpen, nextClose]
    case eventIds.development.lionWitchWardrobe:
    case eventIds.production.lionWitchWardrobe:
      openDay = 1
      closeDay = 7
      nextOpen = moment()
        .utc()
        .day(openDay + 7)
        .startOf('day')
      nextClose = moment()
        .utc()
        .day(closeDay + 7)
        .endOf('day')
      return [nextOpen, nextClose]
    default:
      const openDate = moment.utc().startOf('day')
      const closeDate = moment.utc().endOf('day')
      if (now > closeDate) {
        return [openDate.add(1, 'day'), closeDate.add(1, 'day')]
      }
      return [openDate, closeDate]
  }
}

export const daySeatWaitingText = (eventId?: string) => {
  switch (eventId) {
    case eventIds.development.phantomNew:
    case eventIds.production.phantomNew:
      return 'Day Seats can be booked between 10am - 12pm (BST) on the day of the show.'
    case eventIds.development.frozen:
    case eventIds.production.frozen:
      return 'Frozen Magical Mondays can be booked from 12 noon (BST) every Monday for the week ahead'
    case eventIds.development.beautyAndTheBeast:
    case eventIds.production.beautyAndTheBeast:
      return 'Beauty and the Beast Magical Mondays can be booked from 12 noon (BST) every Monday for the week ahead'
    case eventIds.development.lionWitchWardrobe:
    case eventIds.production.lionWitchWardrobe:
      return 'Fantastic Friday tickets can be booked between 12 noon - 6pm (BST) every Friday for performances the following week.’'
    default:
      return 'Day Seats can be booked between 10am - 12pm (BST) on the day of the show.'
  }
}

export const daySeatCopy = (eventId?: string) => {
  switch (eventId) {
    case eventIds.development.phantomNew:
    case eventIds.production.phantomNew:
      return (
        <>
          <p>
            Please use the plus and minus buttons to the left, or below if
            you're on a mobile device, to select up to 2 day seat tickets.
          </p>
          <p>
            You will automatically be assigned the best seats available at point
            of booking. Unfortunately we will not be able to exchange the seats
            you have been assigned.
          </p>
          <p>
            After completing the day seat purchase you will receive a booking
            confirmation. Your tickets will be sent separately by email.
          </p>
        </>
      )
    case eventIds.development.lionWitchWardrobe:
    case eventIds.production.lionWitchWardrobe:
      return (
        <>
          <p>
            Please use the plus and minus buttons to the left, or below if
            you're on a mobile device, to select up to 6 tickets.
          </p>
          <p>
            You will automatically be assigned the best seats available at point
            of booking. Unfortunately we will not be able to exchange the seats
            you have been assigned.
          </p>
          <p>
            After completing the day seat purchase you will receive a booking
            confirmation. Your tickets will be sent separately by email.
          </p>
        </>
      )
    default:
      return (
        <>
          <p>
            Please use the plus and minus buttons to the left, or below if
            you're on a mobile device, to select up to 4 day seat tickets.
          </p>
          <p>
            You will automatically be assigned the best seats available at point
            of booking. Unfortunately we will not be able to exchange the seats
            you have been assigned.
          </p>
          <p>
            After completing the purchase you will receive a booking
            confirmation. Your tickets will be sent separately by email.
          </p>
        </>
      )
  }
}

export const daySeatsSoldOutCopy = (eventId: string) => {
  switch (eventId) {
    case eventIds.development.phantomNew:
    case eventIds.production.phantomNew:
      return (
        <p>
          All Day Seats have now sold out but standard tickets from £22.50 are
          still available. To check availability please{' '}
          <StyledLink
            text={`click here.`}
            href={calendarRoute(eventId).pathname}
            isUnderlined={true}
            size={'copy'}
          />
        </p>
      )
    case eventIds.development.beautyAndTheBeast:
    case eventIds.production.beautyAndTheBeast:
      return (
        <>
          <p>
            Unfortunately we have now sold out of Magical Monday seats for
            Beauty and the Beast.
          </p>
          <p>
            If you are still looking for some Disney magic we have limited seats
            for Frozen The Musical (Theatre Royal Drury Lane) available.{' '}
            <StyledLink
              text={`Click here for Frozen Magical Monday tickets.`}
              href='https://booking.lwtheatres.co.uk/event/2c62fa9a-9025-ea11-a9ca-064c84cbe4ea/day-seats'
              isUnderlined={true}
              size={'copy'}
            />
            .
          </p>
          <p>
            To book standard tickets for Beauty and the Beast, please{' '}
            <StyledLink
              text={`click here.`}
              href={calendarRoute(eventId).pathname}
              isUnderlined={true}
              size={'copy'}
            />
            .
          </p>
        </>
      )
    case eventIds.development.lionWitchWardrobe:
    case eventIds.production.lionWitchWardrobe:
      return (
        <p>
          Fantastic Friday tickets for next week have now sold out. To book
          standard tickets for The Lion, the Witch and the Wardrobe, please{' '}
          <StyledLink
            text={`click here.`}
            href={calendarRoute(eventId).pathname}
            isUnderlined={true}
            size={'copy'}
          />
        </p>
      )
    default:
      return (
        <p>
          Frozen Magical Monday has now sold out. To book standard tickets for
          Frozen, please{' '}
          <StyledLink
            text={`click here.`}
            href={calendarRoute(eventId).pathname}
            isUnderlined={true}
            size={'copy'}
          />
        </p>
      )
  }
}

export const daySeatPromoCode = (eventId?: string) => {
  switch (eventId) {
    case eventIds.development.phantomNew:
    case eventIds.production.phantomNew:
      return 'RUSH21'
    case eventIds.development.frozen:
    case eventIds.production.frozen:
      return 'disneydayseats21'
    case eventIds.development.beautyAndTheBeast:
    case eventIds.production.beautyAndTheBeast:
      return 'beautydayseats22'
    case eventIds.development.lionWitchWardrobe:
    case eventIds.production.lionWitchWardrobe:
      return 'liondayseats22'
    default:
      return
  }
}
