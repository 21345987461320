import React from 'react'

// Components
import { Link } from 'marvel-components'

// Misc
import { media, darken } from 'utils'
import styled from 'styled-components'

// Types
import { NavItem } from 'shared-types'

const SummaryFooter = styled.div`
  font-family: ${props => props.theme.font.copy};
  margin-top: auto;
  justify-content: center;
  display: flex;
  flex-direction: row;
  padding-bottom: 1rem;

  ${media.tablet} {
    justify-content: center;
    background: #fff;
    padding: 1rem 0;
  }
`

const SummaryFooterItem = styled.span`
  margin-right: 0.5rem;
`

const LinkWrapper = styled.div`
  ${media.tablet} {
    a {
      color: ${props => darken(props.theme.colors.borderColor, 20)};
    }
  }
`

const defaultFooterItems: NavItem[] = [
  {
    name: 'Terms and Conditions',
    path: 'https://lwtheatres.co.uk/smart-waiter-terms-conditions/'
  },
  { name: 'FAQ', path: 'https://lwtheatres.co.uk/faq/' },
  { name: 'Privacy Policy', path: 'https://lwtheatres.co.uk/privacy-policy/' }
]

type Props = {
  items?: NavItem[]
}
const SWFooterNav: React.FC<Props> = ({ items = defaultFooterItems }) => {
  return (
    <SummaryFooter>
      {items.map((item, key) => (
        <SummaryFooterItem key={key}>
          <LinkWrapper>
            <Link
              text={item.name}
              href={item.path}
              size="extra-small"
              isSidebar={true}
            />
          </LinkWrapper>
        </SummaryFooterItem>
      ))}
    </SummaryFooter>
  )
}

export { SWFooterNav }
