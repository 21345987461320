import backgroundAsset from './assets/lwatw_bg.jpg'
export const LionWitchWardrobe = {
  colors: {
    mainBackgroundColor: '#3368c5',
    mainColor: '#ffed00',
    mainColorComplement: '#111',
    lwBrandColor: '#ffffff',
    lwBrandColorComplement: '#111111',
    bestAvailableBannerColor: '#c4007a',
    bestAvailableBannerColorComplement: '#fff',
    bestAvailablePerformanceColor: '#c4007a',
    numberFilterSelectorColor: '#ffffff',
    priceSliderHandleColor: '#ffed00',
    numberFilterSelectorCopyColor: '#111111'
  },
  themeBackgroundAsset: backgroundAsset,
  themeBackgroundColour: '#3368c5',
  orderSuccess: {
    showHospitality: true,
    showCecilBeatonBar: true
  }
}
