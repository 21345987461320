import React, { useState } from 'react'

// Components
import { Title, CopyBlock, Button, Checkbox } from 'marvel-components'

// Selectors
import { eventDetailsSelectors } from 'modules/ticketing/event'
import {
  selectActivePerformance,
  selectActivePerformanceId
} from 'modules/ticketing/performance/selectors'
import { selectRestrictedSeatsInBasket } from 'modules/basket/selectors'

// Actions
import { closeModal } from 'modules/overlay/actions'
import { createOrder } from 'modules/basket/actions'

// Misc
import styled from 'styled-components/macro'
import moment from 'moment'
import { useSelector, useDispatch } from 'react-redux'

// Utils
import { media, FormattedPrice, selectTicketsRoute } from 'utils'
import { useHistory } from 'react-router-dom'

const ConfirmRestrictionOverlayWrapper = styled.div`
  padding: 2rem;
  ${media.phone} {
    padding: 1rem;
  }
`

const RestrictionWrapper = styled.div`
  padding: 0.625rem 0;
`

const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  color: ${props => props.theme.colors.headingColor};
`

const CheckboxWrapper = styled.div`
  color: ${props => props.theme.colors.alertColor};
`

const StyledButton = styled(Button)`
  margin-top: 1rem;
`

export type ConfirmRestrictionsModalProps = {
  order: any
  customer: any
}

const ConfirmRestrictionsModalContainer: React.FC<ConfirmRestrictionsModalProps> = ({
  order,
  customer
}) => {
  // Actions
  const dispatch = useDispatch()
  const history = useHistory()

  // Selectors
  const eventTitle = useSelector(eventDetailsSelectors.selectEventName)
  const eventId = useSelector(eventDetailsSelectors.selectEventDetailsId)
  const performanceId = useSelector(selectActivePerformanceId)
  const activePerformance = useSelector(selectActivePerformance)
  const restrictedSeats = useSelector(selectRestrictedSeatsInBasket)
  // Local State
  const [accepted, setAccepted] = useState([] as string[])

  // Actions
  const handleConfirmAndBuy = () => {
    dispatch(createOrder({ order, customer }))
    dispatch(closeModal())
  }
  const handleSelectDifferentSeats = () => {
    if (eventId) {
      history.push(selectTicketsRoute(eventId, performanceId))
    }
    dispatch(closeModal())
  }

  const handleCheckSeatRestriction = (seatId: string, value: boolean) => {
    value
      ? setAccepted([...accepted, seatId])
      : setAccepted(accepted.filter(id => seatId !== id))
  }

  return (
    <ConfirmRestrictionOverlayWrapper>
      <Title size='large' isCapitalised={false} isBold={true}>
        Before you confirm
      </Title>
      <CopyBlock textSize='medium'>
        {`Please tick the checkboxes to confirm that you understand the seat warnings`}
      </CopyBlock>
      <Title
        isBold={true}
        isCapitalised={false}
        isCentralised={false}
        size='medium'
      >
        {eventTitle}
      </Title>
      <Title
        isCapitalised={false}
        isCentralised={false}
        isPadded={false}
        size='medium-large'
      >
        {moment
          .utc(activePerformance?.startDate)
          .format('dddd Do MMM YYYY, h:mm a')
          .toString()}
      </Title>
      {restrictedSeats.map(seat => {
        const handleOnChangeRestriction = (value: boolean) => {
          handleCheckSeatRestriction(seat.seatId, value)
        }
        return (
          <RestrictionWrapper key={`restriction-${seat.areaId}-${seat.id}`}>
            <TitleWrapper>
              <Title
                isCapitalised={false}
                isCentralised={false}
                size='medium'
              >{`${seat.areaName} ${seat.seatName}`}</Title>
              <FormattedPrice amount={seat.price} currency='GBP' />
            </TitleWrapper>
            <CheckboxWrapper>
              <Checkbox
                checkboxTheme='alert'
                isChecked={accepted.includes(seat.seatId)}
                label={seat.seatType?.note ?? ''}
                onChange={handleOnChangeRestriction}
              />
            </CheckboxWrapper>
          </RestrictionWrapper>
        )
      })}
      <StyledButton
        icon='lock'
        text='Confirm and Buy'
        onClick={handleConfirmAndBuy}
        isBlock={true}
        isDisabled={accepted.length !== restrictedSeats.length}
      />
      <StyledButton
        text='Choose Different Seats'
        onClick={handleSelectDifferentSeats}
        isBlock={true}
        icon='arrow-left'
        size='small'
        buttonType='secondary'
      />
    </ConfirmRestrictionOverlayWrapper>
  )
}

export { ConfirmRestrictionsModalContainer }
