import moment from 'moment'
import { useCallback, useEffect, useState } from 'react'
import styled from 'styled-components'
import {
  SwipeDirectionCallback,
  useClientRect,
  useInterval,
  useSwipeDirection
} from 'utils'

const SpotlightWrapper = styled.div`
  position: relative;
  overflow: hidden;
`

const SpotlightContent = styled.div<{ offset?: number }>`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: space-between;
  transform: translateX(-${props => props.offset || 0}px);
  transition: transform 250ms ease;
  width: 100%;
  height: 100%;
`

const SpotlightItem = styled.div<{ isFullWidth: boolean }>`
  flex: 0 0 ${props => (props.isFullWidth ? 100 : 90)}%;
  height: 100%;
`

const SpotlightDotList = styled.div`
  position: absolute;
  z-index: 1;
  width: 100%;
  bottom: 0;
  padding: 1rem 0 1rem 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`

const SpotlightDot = styled.div<{ isSelected: boolean }>`
  height: 8px;
  width: 8px;
  background: rgba(255, 255, 255, ${props => (props.isSelected ? 0.7 : 0.0)});
  border: 1px solid rgba(255, 255, 255, 0.7);
  border-radius: 50%;
  margin: 0 0.4rem;
`

const SlideImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`

const SlideContent = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`

const SlideTextBackground = styled.div`
  position: absolute;
  left: 0;
  top: 50%;
  height: 50%;
  background: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.9));
  z-index: 1;
  width: 100%;
`

const SlideText = styled.div`
  position: absolute;
  left: 0;
  bottom: 3.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: end;
  text-align: center;
  color: #ffffff;
  z-index: 2;
  width: 100%;
`

const SlideTitle = styled.h1`
  font-family: ${props => props.theme.font.header};
  margin: 0.25rem 0;
  font-size: 1.8rem;
  font-weight: normal;
`

const SlideDescription = styled.p`
  font-family: ${props => props.theme.font.copy};
  margin: 0;
`

type Slide = {
  imageUrl: string
  title?: string
  description?: string
  onClick?: () => void
}

type SpotlightProps = {
  slides: Slide[]
  interval?: number
}

const Spotlight: React.FC<SpotlightProps> = ({
  slides,
  interval = 10,
  ...props
}) => {
  const [currentIndex, setCurrentIndex] = useState(0)
  const [offset, setOffset] = useState(0)
  const [itemRect, itemRef] = useClientRect<HTMLDivElement>()
  const [parentRect, parentRef] = useClientRect<HTMLDivElement>()

  const isSingleSlide = slides.length <= 1

  const next = useCallback(() => {
    setCurrentIndex((currentIndex + 1) % slides.length)
  }, [setCurrentIndex, currentIndex, slides])

  const previous = useCallback(() => {
    const targetIndex = (currentIndex - 1) % slides.length
    setCurrentIndex(targetIndex < 0 ? targetIndex + slides.length : targetIndex)
  }, [setCurrentIndex, currentIndex, slides])

  const handleDotClick = (e: React.MouseEvent, index: number) => {
    e.stopPropagation()
    e.preventDefault()
    setCurrentIndex(index)
  }

  const handleSwipe = useCallback<SwipeDirectionCallback>(
    horizontal => {
      horizontal && horizontal === 'right' ? previous() : next()
    },
    [previous, next]
  )

  const [handleTouchStart, handleTouchMove] = useSwipeDirection(handleSwipe)

  useEffect(() => {
    setCurrentIndex(0)
  }, [slides])

  useEffect(() => {
    if (isSingleSlide) {
      setOffset(0)
    } else {
      setOffset(itemRect.width * (currentIndex + 1) - parentRect.width / 20)
    }
  }, [itemRect, parentRect, currentIndex, isSingleSlide])

  useInterval(next, moment.duration(interval, 'seconds'), [currentIndex, next])

  if (slides.length === 0) {
    return null
  }

  const renderSlide = (
    slide: Slide,
    index?: number,
    ref?: (node: HTMLDivElement) => void
  ) => {
    if (!slide) return null
    return (
      <SpotlightItem
        isFullWidth={isSingleSlide}
        ref={ref}
        key={index}
        onClick={slide.onClick}
      >
        <SlideContent>
          <SlideImage src={slide.imageUrl} />
          <SlideTextBackground />
          <SlideText>
            <SlideTitle>{slide.title}</SlideTitle>
            <SlideDescription>{slide.description}</SlideDescription>
          </SlideText>
        </SlideContent>
      </SpotlightItem>
    )
  }

  return (
    <SpotlightWrapper
      ref={parentRef}
      onTouchStart={handleTouchStart}
      onTouchMove={handleTouchMove}
      {...props}
    >
      <SpotlightContent offset={offset}>
        {!isSingleSlide && renderSlide(slides[slides.length - 1], -1)}
        {slides.map((slide, i) => renderSlide(slide, i))}
        {!isSingleSlide && renderSlide(slides[0], slides.length + 1, itemRef)}
      </SpotlightContent>
      {slides.length > 1 && (
        <SpotlightDotList>
          {slides.map((_, i) => (
            <SpotlightDot
              key={i}
              onClick={(e: React.MouseEvent) => handleDotClick(e, i)}
              isSelected={i === currentIndex}
            />
          ))}
        </SpotlightDotList>
      )}
    </SpotlightWrapper>
  )
}

export { Spotlight }
