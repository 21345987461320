import ReactGA from 'react-ga'
import ReactGA4 from 'react-ga4'

// Utils
import {
  slugify,
  setCheckoutAction,
  addProduct,
  setPurchaseAction,
  clearDataLayer,
  pushTag
} from './utils'
import { purchaseGA4, addToCartGA4 } from './utilsGA4'

// Types
import { Dictionary } from '@reduxjs/toolkit'
import {
  TrackSmartWaiterVenuePayload,
  TrackSmartWaiterSalesAreaPayload,
  TrackSmartWaiterServicePayload,
  TrackSmartWaiterMenuPayload,
  TrackSmartWaiterCheckoutPayload,
  TrackSmartWaiterOrderPayload,
  TrackSmartWaiterViewItemPayload,
  EcommerceTagItem,
  TrackSmartWaiterAddPayment
} from './types'
import { ECAddProductGA4 } from './typesGA4'
import { ZProduct, SWLineItem } from 'shared-types'

// Step 1 - Pick Venue
export const trackSmartWaiterVenue = (action: TrackSmartWaiterVenuePayload) => {
  const { venueName } = action
  const venueSlug = slugify(venueName)
  const url = `/smart-waiter/venue/${venueSlug}`

  // Analytics
  ReactGA.pageview(url)
}

// Step 2 Pick Sales Area
export const trackSmartWaiterSalesArea = (
  action: TrackSmartWaiterSalesAreaPayload
) => {
  const { venueName, salesAreaName } = action
  const venueSlug = slugify(venueName)
  const salesAreaSlug = slugify(salesAreaName)
  const url = `/smart-waiter/venue/${venueSlug}/area/${salesAreaSlug}`

  // Analytics
  ReactGA.pageview(url)
}

export const trackSmartWaiterViewItem = ({
  bookingFlow,
  platform,
  item: { id, name, price, datetime }
}: TrackSmartWaiterViewItemPayload) => {
  clearDataLayer()
  pushTag({
    module: 'smart-waiter',
    event: 'view_item',
    purchase_flow: bookingFlow,
    ecommerce: {
      currency: 'GBP',
      items: [
        {
          item_id: id,
          item_name: name,
          currency: 'GBP',
          ...(datetime && {
            item_category: datetime
          }),
          item_category4: platform,
          item_category5: 'Smart Waiter',
          item_variant: '',
          quantity: 1,
          price
        }
      ]
    }
  })
}

export const trackSmartWaiterAddToCart = ({
  bookingFlow,
  item: { id, name, datetime, price, quantity }
}: TrackSmartWaiterViewItemPayload) => {
  clearDataLayer()
  pushTag({
    module: 'smart-waiter',
    event: 'add_to_cart',
    purchase_flow: bookingFlow,
    ecommerce: {
      currency: 'GBP',
      value: price,
      items: [
        {
          item_id: id,
          item_name: name,
          currency: 'GBP',
          ...(datetime && {
            item_category: datetime
          }),
          item_category4: 'browser',
          item_category5: 'Smart Waiter',
          item_variant: '',
          price,
          quantity: quantity ?? 1
        }
      ]
    }
  })
}

export const trackSmartWaiterRemoveFromCart = ({
  bookingFlow,
  item: { id, name, datetime, price, quantity }
}: TrackSmartWaiterViewItemPayload) => {
  clearDataLayer()

  pushTag({
    module: 'smart-waiter',
    event: 'remove_from_cart',
    purchase_flow: bookingFlow,
    ecommerce: {
      currency: 'GBP',
      value: price,
      items: [
        {
          item_id: id,
          item_name: name,
          currency: 'GBP',
          ...(datetime && {
            item_category: datetime
          }),
          item_category4: 'browser',
          item_category5: 'Smart Waiter',
          item_variant: '',
          price,
          quantity: quantity ?? 1
        }
      ]
    }
  })
}

export const trackSmartWaiterService = (
  action: TrackSmartWaiterServicePayload
) => {
  const { venueName, salesAreaName, serviceName } = action
  const venueSlug = slugify(venueName)
  const salesAreaSlug = slugify(salesAreaName)
  const serviceSlug = slugify(serviceName)
  const url = `/smart-waiter/venue/${venueSlug}/area/${salesAreaSlug}/service/${serviceSlug}`

  // Analytics
  ReactGA.pageview(url)
}

export const trackSmartWaiterMenu = (action: TrackSmartWaiterMenuPayload) => {
  const { venueName, salesAreaName, serviceName, menuName } = action
  const venueSlug = slugify(venueName)
  const salesAreaSlug = slugify(salesAreaName)
  const serviceSlug = slugify(serviceName)
  const menuSlug = slugify(menuName)
  const url = `/smart-waiter/venue/${venueSlug}/area/${salesAreaSlug}/service/${serviceSlug}/menu/${menuSlug}`

  // Analytics
  ReactGA.pageview(url)
}

export const trackSmartWaiterBasket = ({
  venueName,
  salesAreaName,
  basket,
  products
}: TrackSmartWaiterCheckoutPayload) => {
  // GA
  addSmartWaiterProducts(basket, products, venueName, salesAreaName)
  setCheckoutAction(1)

  const venueSlug = slugify(venueName)
  const salesAreaSlug = slugify(salesAreaName)
  const url = `/smart-waiter/venue/${venueSlug}/area/${salesAreaSlug}/basket`

  clearDataLayer()

  const items: EcommerceTagItem[] = []
  Object.keys(basket).forEach(serviceId => {
    basket[serviceId].forEach(item => {
      items.push({
        item_id: String(item.id),
        item_name: products[item.id]?.name || `${item.id}`,
        price: item.price,
        quantity: item.quantity,
        item_variant: '',
        item_category: `${venueName}/${salesAreaName}`,
        item_category4: 'browser',
        item_category5: 'Smart Waiter'
      })
    })
  })

  pushTag({
    module: 'smart-waiter',
    event: 'begin_checkout',
    purchase_flow: 'New Purchase',
    ecommerce: {
      currency: 'GBP',
      items
    }
  })

  // Analytics
  ReactGA.pageview(url)
}

export const trackSmartWaiterAddPaymentInfo = ({
  bookingFlow,
  paymentType = 'credit_card',
  price = 0,
  items
}: TrackSmartWaiterAddPayment) => {
  clearDataLayer()

  pushTag({
    module: 'smart-waiter',
    event: 'add_payment_info',
    purchase_flow: bookingFlow,
    ecommerce: {
      currency: 'GBP',
      payment_type: paymentType,
      value: price,
      items: items.map(item => ({
        item_id: item.id,
        item_name: item.name,
        currency: 'GBP',
        ...(item.datetime && {
          item_category: item.datetime
        }),
        item_category4: 'browser',
        item_category5: 'Smart Waiter',
        item_variant: '',
        price: item.price,
        quantity: item.quantity ?? 1
      }))
    }
  })
}

export const trackSmartWaiterOrderSuccess = (
  action: TrackSmartWaiterOrderPayload
) => {
  const { venueName, salesAreaName, order, basket, products } = action
  const referenceNumber = order.id
  const orderTotal = order.baskets.reduce<number>(
    (total, current) => (total += current.total),
    0
  )

  // GA
  addSmartWaiterProducts(basket, products, venueName, salesAreaName)
  setPurchaseAction(referenceNumber, orderTotal)

  const venueSlug = slugify(venueName)
  const salesAreaSlug = slugify(salesAreaName)
  const orderSlug = slugify(`${order.id}`)
  const url = `/smart-waiter/venue/${venueSlug}/area/${salesAreaSlug}/order/${orderSlug}`

  // Analytics
  ReactGA.pageview(url)

  clearDataLayer()

  const pushTagItems: EcommerceTagItem[] = []
  Object.keys(basket).forEach(serviceId => {
    basket[serviceId].forEach(item => {
      pushTagItems.push({
        item_id: String(item.id),
        item_name: products[item.id]?.name || `${item.id}`,
        price: item.price,
        quantity: item.quantity,
        item_variant: '',
        item_category: `${venueName}/${salesAreaName}`,
        item_category4: 'browser',
        item_category5: 'Smart Waiter'
      })
    })
  })

  pushTag({
    event: 'purchase',
    purchase_flow: 'New Purchase',
    ecommerce: {
      transaction_id: referenceNumber,
      affiliation: 'Marvel',
      value: orderTotal,
      currency: 'GBP',
      tax: 0,
      shipping: 0,
      items: pushTagItems
    }
  })
}

const addSmartWaiterProducts = (
  basket: Record<EntityId, SWLineItem[]>,
  products: Dictionary<ZProduct>,
  venueName: string,
  salesAreaName: string
) => {
  const items: ECAddProductGA4[] = []

  Object.keys(basket).forEach(serviceId => {
    basket[serviceId].map(item =>
      addProduct({
        id: `${item.id}`,
        name: products[item.id]?.name || `${item.id}`,
        category: `${venueName}/${salesAreaName}`,
        price: item.price,
        quantity: item.quantity,
        variant: products[item.id]?.portions.filter(
          portion => portion.id === item.portionId
        )[0]?.name,
        dimension10: 'New Purchase'
      })
    )
  })

  addToCartGA4(items)
}
