import { useCallback, useState } from 'react'

export type SwipeDirectionCallback = (
  horizontal?: 'left' | 'right',
  vertical?: 'up' | 'down'
) => void

const useSwipeDirection = (
  callback: SwipeDirectionCallback,
  distanceThreshold: number = 5
) => {
  const [x, setX] = useState<number | null>(null)
  const [y, setY] = useState<number | null>(null)

  const handleTouchStart = useCallback(
    (e: React.TouchEvent) => {
      e.stopPropagation()
      e.preventDefault()

      if (e.touches.length > 0) {
        setX(e.touches.item(0).clientX)
        setY(e.touches.item(0).clientY)
      }
    },
    [setX, setY]
  )

  const handleTouchMove = useCallback(
    (e: React.TouchEvent) => {
      e.stopPropagation()
      e.preventDefault()

      if (x != null && y != null && e.touches.length > 0) {
        const touchX = e.touches.item(0).clientX
        const touchY = e.touches.item(0).clientY
        const deltaX = touchX - x
        const deltaY = touchY - y

        callback(
          deltaX > distanceThreshold
            ? 'right'
            : deltaX < -distanceThreshold
            ? 'left'
            : undefined,
          deltaY > distanceThreshold
            ? 'up'
            : deltaY < -distanceThreshold
            ? 'down'
            : undefined
        )

        setX(null)
        setY(null)
      }
    },
    [callback, distanceThreshold, x, y]
  )

  return [handleTouchStart, handleTouchMove]
}

export { useSwipeDirection }
