// Misc
import { getDeliveryIcon } from 'utils'
import { createEntityAdapter } from '@reduxjs/toolkit'

// Types
import { AppState } from 'modules/types'
import { DeliveryOption } from 'shared-types'

export const deliveryOptionAdapter = createEntityAdapter<DeliveryOption>()

const { selectById } = deliveryOptionAdapter.getSelectors()

export const selectDeliveryOptionStatus = (state: AppState) =>
  state.ticketing.deliveryOption.status

export const selectIsDeliveryOptionLoading = (state: AppState) =>
  !state.ticketing.deliveryOption.lastFetch ||
  state.ticketing.deliveryOption.status === 'pending'

export const selectIsDeliveryOptionLoaded = (state: AppState) =>
  !!state.ticketing.deliveryOption.lastFetch

export const selectDeliveryOption = (
  state: AppState,
  deliveryOptionId: EntityId
): DeliveryOption | undefined => {
  const deliveryOption = selectById(
    state.ticketing.deliveryOption,
    deliveryOptionId
  )
  if (deliveryOption) {
    return {
      ...deliveryOption,
      imageUrl: getDeliveryIcon(deliveryOption.name)
    }
  }
}

export const selectDeliveryOptionDescription = (
  state: AppState,
  deliveryOptionId: EntityId
) => selectById(state.ticketing.deliveryOption, deliveryOptionId)

export const selectDeliveryOptions = (state: AppState) =>
  state.ticketing.deliveryOption.ids
    .reduce((items: DeliveryOption[], id) => {
      const deliveryItem = selectDeliveryOption(state, id)
      if (deliveryItem) items.push(deliveryItem)
      return items
    }, [])
    .sort(
      (deliveryOptionA, deliveryOptionB) =>
        deliveryOptionA.ordinal &&
        deliveryOptionB.ordinal &&
        deliveryOptionA.ordinal - deliveryOptionB.ordinal
    )

export const selectDelieveryOptionLastFetchDate = (state: AppState) =>
  state.ticketing.deliveryOption.lastFetch

export const selectDefaultDeliveryOptionId = (state: AppState) => {
  const deliveryOptions = selectDeliveryOptions(state)
  if (deliveryOptions.length > 0) {
    const freeDelivery = deliveryOptions.filter(option => option.price === 0)
    return freeDelivery.length > 0 ? freeDelivery[0].id : deliveryOptions[0].id
  }
  return null
}
