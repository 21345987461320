import { Redirect, useHistory } from 'react-router'
import { useAppDispatch } from 'app/reduxStore'
import { kioskActions } from 'modules/self-serve/kiosk/kioskSlice'

const LogoutView = () => {
  const history = useHistory()
  const dispatch = useAppDispatch()

  dispatch(kioskActions.logout())

  return (
    <Redirect
      to={{
        pathname: '/kiosk/authenticate',
        search: history.location.search
      }}
    />
  )
}

export { LogoutView }
