import React from 'react'
import styled from 'styled-components'

interface Props {
  children: string
  trimLength?: number
  isTrimmed?: boolean
}

const StyledMore = styled.span`
  text-decoration: underline;
  cursor: pointer;
  color: #000;
`

export const WithTrim = <P extends object>(
  Component: React.ComponentType<P>
): React.FC<P & Props> => ({
  children,
  trimLength = 20,
  isTrimmed = true,
  ...props
}) => {
  const shouldTrim = children.length > trimLength && isTrimmed
  const trimText = shouldTrim
    ? `${children.substr(0, trimLength).trim()}...`
    : children

  return (
    <Component {...(props as P)}>
      {trimText} {shouldTrim && <StyledMore>more</StyledMore>}
    </Component>
  )
}
