const O2Lounge = {
  colors: {
    mainBackgroundColor: '#003097',
    bestAvailablePerformanceColor: '#007ecb',
    mainColor: '#ffffff',
    mainColorComplement: '#000a5e',
    numberFilterSelectorColor: '#000a5e',
    numberFilterSelectorCopyColor: '#ffffff',
    priceSliderHandleColor: '#003097',
    bestAvailableBannerColor: '#007ecb',
    bestAvailableBannerColorComplement: '#ffffff',
    sidebarCopyColor: '#ffffff'
  },
  orderSuccess: {
    showHospitality: true,
    showCecilBeatonBar: true
  }
}

export { O2Lounge }

/*
Pantone Colours: 

2747 - #000a5e
286 - #003097
2925 - #007ecb
298 - #00a0e5
317 - #94dce3
white - #ffffff
*/
