// Misc
import { createEntityAdapter } from '@reduxjs/toolkit'

// Types
import { AppState } from 'modules/types'
import { EventDetails } from 'shared-types'

export const eventDetailsAdapter = createEntityAdapter<EventDetails>()

const { selectById } = eventDetailsAdapter.getSelectors()

export const selectEventDetailsId = (state: AppState) =>
  state.ticketing.eventDetails.currentId || ''

export const selectIsLoaded = (state: AppState) =>
  state.ticketing.eventDetails.status === 'fulfilled'

export const selectEventDetails = (state: AppState) => {
  const eventDetailsId = selectEventDetailsId(state)
  if (eventDetailsId)
    return selectById(state.ticketing.eventDetails, eventDetailsId)
}

export const selectVenue = (state: AppState) => selectEventDetails(state)?.venue

export const selectVenueAddress = (state: AppState) =>
  selectVenue(state)?.address

export const selectVenueName = (state: AppState) => selectVenue(state)?.name
export const selectSwVenueId = (state: AppState) => selectVenue(state)?.swId
export const selectEventName = (state: AppState) =>
  selectEventDetails(state)?.name
export const selectEventImageUrl = (state: AppState) =>
  selectEventDetails(state)?.imageUrl ?? ''

export const selectLogoUrl = (state: AppState) =>
  selectEventDetails(state)?.logoName ?? undefined

export const selectLogoBackground = (state: AppState) =>
  selectEventDetails(state)?.logoBackground ?? undefined

export const selectSocialMedia = (state: AppState) =>
  selectEventDetails(state)?.socialMedia

export const selectSocialMediaUrl = (state: AppState) =>
  selectSocialMedia(state)?.socialMediaUrl

export const selectTwitterTitle = (state: AppState) =>
  selectSocialMedia(state)?.twitterTitle

export const selectTwitterHashTags = (state: AppState) =>
  selectSocialMedia(state)?.twitterHashtags

export const selectIsSmartWaiterEnabled = (state: AppState) => {
  const eventDetails = selectEventDetails(state)
  return eventDetails && !!eventDetails.smartWaiterEnabled
}
