import { createEntityAdapter, createSelector } from '@reduxjs/toolkit'
import { AppState } from 'modules'
import { Genre } from '../shared/types'

export const genreAdapter = createEntityAdapter<Genre>()

const { selectAll, selectEntities } = genreAdapter.getSelectors()

export const selectGenreState = (state: AppState) => state.selfServe.genre

export const selectGenres = createSelector(selectGenreState, selectAll)

export const selectGenreEntities = createSelector(
  selectGenreState,
  selectEntities
)

export const selectIsGenresLoading = (state: AppState) =>
  state.selfServe.genre.status === 'idle' ||
  state.selfServe.genre.status === 'pending'

export const selectSelectedGenres = (state: AppState) =>
  state.selfServe.genre.selectedGenres
