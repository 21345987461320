import React, { useEffect } from 'react'

import styled from 'styled-components'
import { media, parseLiveResOccasion } from 'utils'

// Components
import { Title, ListItem, List } from 'marvel-components'

// Selectors
import { occasionSelectors } from 'modules/hospitality/occasion'

// Actions
import { resetHospitality } from 'modules/hospitality/shared'
// Misc
import { useSelector, useDispatch } from 'react-redux'
import { useHistory, useRouteMatch } from 'react-router-dom'

const OccasionContainerWrapper = styled.div`
  box-sizing: border-box;
  padding: 1.5rem 0 2.5rem 0;
  ${media.tablet} {
    padding: 1rem 0 1.5rem 0;
    margin-top: 0;
  }
`

const OccasionListDetails = styled.div`
  padding: 0 2%;
  ${media.tablet} {
    padding: 0 3%;
  }
  ${media.phone} {
    padding: 0 5%;
  }
`

const OccasionListWrapper = styled.div`
  margin: 1rem 0;
`

type Props = {
  siteId: string
}
const OccasionListContainer: React.FC<Props> = ({ siteId }) => {
  // Routing
  const history = useHistory()
  const { url } = useRouteMatch()
  const dispatch = useDispatch()

  // Actions
  useEffect(() => {
    dispatch(resetHospitality())
  })

  // Selectors
  const occasions = useSelector(occasionSelectors.selectAll)

  const handleOccasionClick = (occasionId: EntityId) => {
    history.push(`${url}/${occasionId}/select-date`)
  }

  return (
    <OccasionContainerWrapper>
      <OccasionListDetails>
        <Title
          isCapitalised={false}
          isCentralised={false}
          isBold={true}
          size='medium'
        >
          Please choose an occasion to book:
        </Title>
      </OccasionListDetails>
      <OccasionListWrapper>
        <List>
          {occasions.map(occasion => {
            const occasionType = parseLiveResOccasion(occasion.name)
            return (
              occasion.id !== '8276fbc4-303e-48f4-8b3e-ac40d30a5c09' && (
                <ListItem
                  key={occasion.id}
                  title={occasion.name}
                  id={occasion.id}
                  stacked
                  onClick={handleOccasionClick}
                  largeIcon
                  imageUrl={occasionType.icon}
                  icon={true}
                  isDisabled={!occasion.available}
                />
              )
            )
          })}
        </List>
      </OccasionListWrapper>
    </OccasionContainerWrapper>
  )
}

export { OccasionListContainer }
