import backgroundAsset from './assets/crazy_for_you_sidebar.jpg'
const CrazyForYou = {
  colors: {
    mainColor: '#f3c317',
    mainColorComplement: '#111111',
    performanceHoverColor: '#f3c317',
    numberFilterSelectorColor: '#ffffff',
    numberFilterSelectorCopyColor: '#111111',
    priceSliderHandleColor: '#000169',
    mainBackgroundColor: '#000169',
    lwBrandColor: '#ffffff',
    lwBrandColorComplement: '#111111'
  },
  themeBackgroundColour: '#000169',
  themeBackgroundAsset: backgroundAsset,
  orderSuccess: {
    showHospitality: true,
    showCecilBeatonBar: true
  }
}

export { CrazyForYou }
