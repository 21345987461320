import React from 'react'
import styled from 'styled-components/macro'
import { Route, Switch } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { ThemeProvider } from 'styled-components/macro'
import CookieConsent from 'react-cookie-consent'

// Views
import { KioskView } from 'views/Kiosk'
import {
  EventDetailsView,
  ManageAuthenticationView,
  ManageOrderView,
  OrderView
} from 'views/Ticketing'
import { HomeView } from 'views/Hospitality'
import { SmartWaiterHomeView } from 'views/SmartWaiter'

// Shared Containers
import {
  ModalContainer,
  BannerContainer,
  MaintenanceContainer
} from 'containers'

// Misc
import {
  getTheme,
  usePlatform,
  media,
  setSessionScope,
  themedBackground
} from 'utils'
import moment from 'moment'

// Selectors
import { eventDetailsSelectors } from 'modules/ticketing/event'
import useModule from 'utils/useModule'
import { venueSelectors } from 'modules/smart-waiter/venue'

const AppBackgroundWrapper = styled.div<{ platform?: string }>`
  display: flex;
  flex-direction: column;
  ${props =>
    props.theme.themeBackgroundColour
      ? themedBackground
      : `background: ${props.theme.colors.mainBackgroundColor};`}
  ${media.tablet} {
    ${props =>
      props.theme.themeBackgroundColour
        ? themedBackground
        : `background: ${
            props.platform === 'kiosk'
              ? 'white'
              : props.theme.colors.mainBackgroundColor
          };`}
  }
`

const SkipLink = styled.a`
  font-family: ${props => props.theme.font.copy};
  position: absolute;
  top: -40px;
  left: 0;
  background: #000000;
  color: white;
  padding: 8px;
  z-index: 100;

  :focus {
    top: 0;
  }
`

// Time to turn maintenance screen on or off - be careful here of BST vs UTC! UTC is an hour behind British Summer Time.
const now = moment.utc()
const maintenanceOn =
  now.isAfter(moment.utc('2022-05-25 02:00:00.000')) &&
  now.isBefore(moment.utc('2022-05-25 06:00:00.000'))

const AppContainer: React.FC = () => {
  const eventId = useSelector(eventDetailsSelectors.selectEventDetailsId)
  const siteId = useSelector(venueSelectors.selectSelectedVenueId)
  const platform = usePlatform()
  const module = useModule()

  React.useEffect(() => {
    setSessionScope(platform)
  }, [platform])

  return (
    <ThemeProvider theme={getTheme(eventId, siteId, module)}>
      <AppBackgroundWrapper platform={platform}>
        <SkipLink className='skip-link' href='#maincontent'>
          Skip to main
        </SkipLink>
        <Switch>
          {maintenanceOn && (
            <Route path='/'>
              <MaintenanceContainer />
            </Route>
          )}
          {/* Views */}
          <Route path='/kiosk'>
            <KioskView />
          </Route>
          <Route path={'/event/:eventId'}>
            <EventDetailsView />
          </Route>
          <Route
            exact
            path={[`/order/manage`, `/order/:transactionGuid/manage`]}
          >
            <ManageAuthenticationView />
          </Route>
          <Route exact path='/manage-order/:orderToken'>
            <ManageOrderView />
          </Route>
          <Route path='/order/:orderId'>
            <OrderView />
          </Route>
          <Route path='/hospitality'>
            <HomeView />
            {/* <MaintenanceContainer /> */}
          </Route>
          <Route path='/smart-waiter'>
            <>
              {platform === 'kiosk' ? null : (
                <CookieConsent
                  location='bottom'
                  buttonText='OK'
                  cookieName='DWTFCookieDesktop'
                  style={{
                    background: '#fff',
                    fontSize: '13px',
                    justifyContent: 'center',
                    fontFamily: 'axiformaregular',
                    color: '#111'
                  }}
                  buttonStyle={{
                    fontSize: '13px',
                    color: '#fff',
                    fontFamily: 'axiformaregular',
                    borderRadius: '2px',
                    backgroundColor: '#111',
                    marginTop: '0'
                  }}
                >
                  This site uses cookies. By continuing to browse the site you
                  are agreeing to our use of cookies.{' '}
                  <a
                    href='https://lwtheatres.co.uk/privacy-policy/'
                    rel='noopener noreferrer'
                    target='_blank'
                  >
                    Read more
                  </a>
                  .
                </CookieConsent>
              )}

              <SmartWaiterHomeView />
            </>
          </Route>

          {/* <Redirect from="/" to={eventRoute()} /> */}
        </Switch>
        <BannerContainer />
        <ModalContainer />
      </AppBackgroundWrapper>
    </ThemeProvider>
  )
}

export { AppContainer }
