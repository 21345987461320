import { combineReducers } from 'redux'
import content from 'modules/self-serve/content/contentSlice'
import terminal from 'modules/self-serve/terminal/terminalSlice'
import kiosk from 'modules/self-serve/kiosk/kioskSlice'
import event from 'modules/self-serve/event/eventSlice'
import eventCollection from 'modules/self-serve/event-collection/eventCollectionSlice'
import venue from 'modules/self-serve/venue/venueSlice'
import venuePage from 'modules/self-serve/venue-page/venuePageSlice'
import genre from 'modules/self-serve/genre/genreSlice'

const selfServe = combineReducers({
  content,
  terminal,
  kiosk,
  event,
  eventCollection,
  venue,
  venuePage,
  genre
})

export type SelfServeState = ReturnType<typeof selfServe>

export default selfServe
