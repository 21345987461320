// Misc
import { createSelector } from '@reduxjs/toolkit'
import moment from 'moment'

// Types
import { AppState } from 'modules/types'
import { Transaction, TransactionItem } from 'shared-types'

export const selectOrder = (state: AppState) => state.hospitality.order.order
export const selectPaymentChallenge = (state: AppState) =>
  state.hospitality.order.action
export const selectIsOrderLoading = (state: AppState) =>
  state.hospitality.order.loading === 'loading'
export const selectPaymentIsLoading = (state: AppState) =>
  selectIsOrderLoading(state) || !!selectPaymentChallenge(state)

export const selectSelectedDateAndTime = createSelector(
  [selectOrder],
  (order) => {
    if (order) {
      const dateM = moment.utc(order.event.date)
      const timeM = moment.utc(order.event.time, 'HH:mm')
      return moment.utc({
        date: dateM.date(),
        month: dateM.month(),
        year: dateM.year(),
        hour: timeM.hour(),
        minutes: timeM.minutes()
      })
    }
  }
)

export const selectBasketSummary = createSelector([selectOrder], (order) => {
  if (order) {
    const event = order.event
    return {
      groups: [
        {
          id: event.occasionId,
          name: event.occasionName,
          items: event.menus.reduce((items, menu) => {
            const date = moment(event.date).format('dddd Do MMM YYYY')
            items.push({
              id: menu.id,
              title: menu.name,
              detail: `${date}, ${event.time}`,
              subtitle: 'Adult',
              price: 0,
              quantity: event.adults,
              ...(event.children > 0 && {
                choices: [
                  {
                    id: menu.id,
                    title: 'Child',
                    price: 0,
                    quantity: event.children
                  }
                ]
              })
            })
            return items
          }, [] as TransactionItem[])
        }
      ],
      total: order.total,
      subtotals: [
        {
          amount: order.total,
          description: 'Subtotal'
        }
      ]
    } as Transaction
  }
  return { groups: [], subtotals: [], total: 0 }
})
