import React, { useContext } from 'react'
import styled, { ThemeContext } from 'styled-components/macro'

interface Props {
  x: number
  y: number
  width: number
  height: number
  text: string
}

const Text = styled.text`
  -webkit-font-smoothing: auto;
  -moz-osx-font-smoothing: auto;
  fill: #fff;
  font-family: ${(props) => props.theme.font.header};
  font-size: 1.125rem;
  font-weight: 200; /* Firefox fix*/
`

const SeatMapAnnotationNM: React.FC<Props> = ({
  x,
  y,
  width,
  height,
  text
}) => {
  const themeContext = useContext(ThemeContext)
  const {
    font: { copy },
    colors: { mainBackgroundColor, mainBackgroundColorComplement }
  } = themeContext
  const xTranslate = (2000 - width) / 2
  return (
    <g transform={`translate(${xTranslate} 0)`}>
      <rect
        x={x}
        y={y}
        width={width}
        height={height}
        fill={mainBackgroundColor}
      />
      <Text
        x={width / 2}
        y={height / 2} // Note: Positioned from center of string
        alignmentBaseline="central"
        textAnchor="middle"
        fontFamily={copy}
        fontSize="25"
        letterSpacing="1.5"
        fill={mainBackgroundColorComplement}
      >
        {text.toUpperCase()}
      </Text>
    </g>
  )
}

export const SeatMapAnnotation = React.memo(SeatMapAnnotationNM)
