// Components
import { WithLoader } from 'marvel-components'

// Containers
import { VenueHeaderContainer } from 'containers/Kiosk/VenueHeaderContainer'
import { VenueTitleContainer } from 'containers/Kiosk/VenueTitleContainer'
import { EventGenreContainer } from 'containers/Kiosk/EventGenreContainer'
import { EventGridContainer } from 'containers/Kiosk/EventGridContainer'
import { FooterContainer } from 'containers/FooterContainer'

// Selectors
import { eventSelectors } from 'modules/self-serve/event'
import { genreSelectors } from 'modules/self-serve/genre'

// Misc
import styled from 'styled-components'
import { useSelector } from 'react-redux'
import { useRouteMatch } from 'react-router-dom'

const Wrapper = WithLoader(styled.div`
  background: ${props => props.theme.colors.venuePrimaryBackgroundColor};
  color: ${props => props.theme.colors.venuePrimaryCopyColor};
  min-height: 100vh;
  z-index: 0;
  display: flex;
  flex-direction: column;
`)

const Content = styled.div`
  padding: 0 4rem 3rem 4rem;
`

const GridWrapper = styled.div`
  margin-top: 3rem;
`

const VenueEventsView = () => {
  const {
    params: { venueId }
  } = useRouteMatch<{ venueId: string }>()

  const isEventsLoading = useSelector(eventSelectors.selectIsEventsLoading)
  const isGenresLoading = useSelector(genreSelectors.selectIsGenresLoading)

  return (
    <Wrapper isLoading={isEventsLoading || isGenresLoading}>
      <VenueHeaderContainer venueId={venueId}>
        <VenueTitleContainer venueId={venueId} />
      </VenueHeaderContainer>
      <EventGenreContainer />
      <Content>
        <GridWrapper>
          <EventGridContainer venueId={venueId} />
        </GridWrapper>
      </Content>

      <FooterContainer />
    </Wrapper>
  )
}

export { VenueEventsView }
