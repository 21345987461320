import React from 'react'

// Components
import { Legend } from 'marvel-components'

// Selectors
import {
  selectFilteredPriceLevelIds,
  selectAvailablePriceLevelIds,
  selectLegendItems
} from 'modules/ticketing/performance/selectors'

// Actions
import { priceLevelActions } from 'modules/ticketing/performance/priceLevelSlice'

// Misc
import { useDispatch, useSelector } from 'react-redux'

const LegendContainer: React.FC = () => {
  const dispatch = useDispatch()

  //Selectors
  const filteredPriceLevels = useSelector(selectFilteredPriceLevelIds)
  const priceLevelIds = useSelector(selectAvailablePriceLevelIds)
  const legendItems = useSelector(selectLegendItems)

  const handleSelectItem = (ids: string[]) => {
    dispatch(priceLevelActions.removePriceLevelFilters(ids))
  }

  const handleDeselectItem = (ids: string[]) => {
    // if no price level filter all prices and only select the one clicked
    if (filteredPriceLevels.length + ids.length === priceLevelIds.length) {
      dispatch(priceLevelActions.setPriceLevelFilters([]))
    } else {
      filteredPriceLevels.length === 0
        ? dispatch(
            priceLevelActions.setPriceLevelFilters(
              priceLevelIds.filter((id) => !ids.includes(id))
            )
          )
        : dispatch(priceLevelActions.addPriceLevelFilters(ids))
    }
  }

  return (
    <Legend
      items={legendItems}
      selectItem={handleSelectItem}
      deselectItem={handleDeselectItem}
    />
  )
}

export { LegendContainer }
