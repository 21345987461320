// Components
import { FormWrapper, Title, Link } from 'marvel-components'

// Misc
import { Form, Formik } from 'formik'
import styled from 'styled-components'
import * as Yup from 'yup'

// Assets
import LWLogo from 'assets/lw_theatres_white.png'

const ManageFormLayout = styled(Form)`
  display: flex;
  flex-direction: column;
  border: 1px solid #fff;
  border-radius: 8px;
  padding: 1rem 2rem 2rem 2rem;
`

const FormTitle = styled(Title)`
  margin-bottom: 1rem;
`

const LogoImageWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 2rem;
  width: 5rem;
`

const LogoWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`

const LogoImage = styled.img`
  width: 100%;
`

export interface ManageAuthenticationFormValues {
  lastName: string
  transactionNumber: string
}

type Props = {
  onSubmit: (values: ManageAuthenticationFormValues) => void
  isSubmitting: boolean
  transactionGuid?: string
}

const ManageOrderFormValidator = Yup.object().shape({
  lastName: Yup.string().required('Please enter the last name on the booking.'),
  transactionNumber: Yup.number()
    .max(9999999, 'The transaction number cannot be more than 7 digits')
    .when('transactionGuid', {
      is: undefined,
      then: Yup.number()
        .integer('Please enter a valid number')
        .required('Please enter the 7 digit transaction number')
    })
})

export const FormManageAuthentication = ({
  onSubmit,
  isSubmitting,
  transactionGuid
}: Props) => {
  return (
    <FormWrapper>
      <LogoWrapper>
        <LogoImageWrapper>
          <LogoImage src={LWLogo} />
        </LogoImageWrapper>
      </LogoWrapper>
      <Formik
        initialValues={{
          lastName: '',
          transactionNumber: '',
          transactionGuid: transactionGuid
        }}
        onSubmit={values => onSubmit(values)}
        validationSchema={ManageOrderFormValidator}
      >
        {({ values }) => (
          <ManageFormLayout>
            <FormTitle
              size='medium'
              isCentralised={true}
              isSidebar={true}
              isCapitalised={false}
            >
              This is an old booking link, please visit the following page for
              more information:
            </FormTitle>
            <Title
              size='medium'
              isCentralised={true}
              isSidebar={true}
              isCapitalised={false}
            >
              <Link
                text={`Go to LW Theatres FAQ`}
                href='https://lwtheatres.co.uk/faq/'
                isUnderlined={true}
                size='copy'
                isSidebar={true}
              />
            </Title>
          </ManageFormLayout>
        )}
      </Formik>
    </FormWrapper>
  )
}
