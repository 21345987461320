import React from 'react'

// Components
import { TransactionSummary } from 'marvel-components'

// Selectors
import {
  selectTransactionSummary,
  selectOrderReferenceNumber,
  selectAllItemCount
} from 'modules/basket/selectors'

// Misc
import { useSelector } from 'react-redux'

type Props = {
  hideOnMobile?: boolean
  inOwnView?: boolean
}

const OrderSummaryContainer: React.FC<Props> = ({
  hideOnMobile = false,
  inOwnView = false
}) => {
  // Selectors
  const transactionSummary = useSelector(selectTransactionSummary)
  const transactionNumber = useSelector(selectOrderReferenceNumber)
  const basketItemCount = useSelector(selectAllItemCount)

  if (basketItemCount > 0) {
    return null
  }
  return (
    <TransactionSummary
      summary={transactionSummary}
      title={`Your Booking Reference: ${transactionNumber}`}
      hideOnMobile={hideOnMobile}
      inOwnView={inOwnView}
      isOrderSuccess={true}
    />
  )
}

export { OrderSummaryContainer }
