export const Joseph = {
  colors: {
    mainBackgroundColor: '#000000',
    bestAvailablePerformanceColor: '#eac94f',
    bestAvailablePerformanceColorComplement: '#111111',
    mainColor: '#eac94f',
    fadedHighlightColor: '#f1f1f1',
    mainColorComplement: '#111111',
    lwBrandColor: '#ffffff',
    lwBrandColorComplement: '#111111',
    numberFilterSelectorColor: '#ffffff',
    numberFilterSelectorCopyColor: '#111111',
    priceSliderHandleColor: '#eac94f',
    performanceHoverColor: '#f7e397'
  },
  themeBackgroundColour: '#000000'
}
