import * as contentful from 'contentful'

const contentfulClient = contentful.createClient({
  space: process.env.REACT_APP_SPACE_ID!,
  accessToken: process.env.REACT_APP_CONTENT_DELIVERY_ACCESS_TOKEN!
})

type FetchOneQuery = {
  include?: number
}

type FetchManyQuery = {
  contentType: 'venue' | 'event' | 'eventCollection' | 'venuePage' | 'genre'
  include?: number
  limit?: number
  skip?: number
  fields?: { [key: string]: any }
}

const contentfulApi = {
  async fetchOne(id: string, query?: FetchOneQuery) {
    const entry = await contentfulClient.getEntry(id, query)
    return entry.fields
  },
  async fetchMany(query?: FetchManyQuery) {
    const fields: { [key: string]: any } = {}
    Object.keys(query?.fields || {}).forEach(key => {
      if (query && query.fields) {
        fields[`fields.${key}`] = query?.fields[key]
      }
    })

    const entries = await contentfulClient.getEntries({
      content_type: query?.contentType,
      include: query?.include,
      limit: query?.limit,
      skip: query?.skip,
      ...fields
    })
    return entries.items.map(entry => entry)
  }
}

export default contentfulApi
