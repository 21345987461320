import React, { ReactNode } from 'react'
import styled, { css } from 'styled-components/macro'

import { media } from 'utils'

export type TextSize = 'extra-small' | 'small' | 'medium' | 'large'

interface Props {
  children: ReactNode
  textSize?: TextSize
  isCentralised?: boolean
  isSidebar?: boolean
  isAlert?: boolean
}

const Copy = styled.span<{
  textSize: TextSize
  isCentralised?: boolean
  isSidebar?: boolean
  isAlert?: boolean
}>((props) => {
  const {
    theme: {
      colors: { copyColor, mainColor, alertColor },
      font: { copy }
    },
    textSize,
    isCentralised,
    isSidebar,
    isAlert
  } = props

  let fontSize = '1rem'
  let phoneFontSize = '1rem'

  switch (textSize) {
    case 'extra-small':
      fontSize = '0.625rem'
      phoneFontSize = '0.625rem'
      break
    case 'small':
      fontSize = '0.75rem'
      phoneFontSize = '0.75rem'
      break
    case 'medium':
      fontSize = '0.875rem'
      phoneFontSize = '0.875rem'
      break
    case 'large':
      fontSize = '1.2rem'
      phoneFontSize = '0.875rem'
      break
  }

  return css`
    color: ${isAlert ? alertColor : isSidebar ? mainColor : copyColor};
    font-family: ${copy};
    font-size: ${fontSize};
    display: block;
    text-align: ${isCentralised ? 'center' : 'initial'};
    line-height: 1.5;
    ${media.phone} {
      font-size: ${phoneFontSize};
    }
  `
})

export const CopyBlock = (props: Props) => {
  const {
    children,
    textSize = 'medium',
    isCentralised = false,
    isSidebar = false,
    isAlert = false
  } = props

  return (
    <Copy
      textSize={textSize}
      isCentralised={isCentralised}
      isSidebar={isSidebar}
      isAlert={isAlert}
    >
      {children}
    </Copy>
  )
}
