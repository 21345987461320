import { schema } from 'normalizr'
import { EventDetails } from 'shared-types'
import ticketingApi from '../services/ticketingApi'
import { createAPIThunk } from '../shared/actions'

const eventDetailsSchema = new schema.Entity('eventDetails')

type FetchEventDetailsRequest = {
  eventId: string
}

type FetchEventDetailsResponse = {
  eventDetails: Record<EntityId, EventDetails>
}

export const fetchEventDetails = createAPIThunk<
  FetchEventDetailsResponse,
  FetchEventDetailsRequest
>(
  'marvel/ticketing/FETCH_EVENT_DETAILS',
  (args, contextId, authToken) =>
    ticketingApi.fetchAll(`/event/${args.eventId}/`, {}, contextId, authToken),
  eventDetailsSchema
)
