import React, { useContext } from 'react'
import ReactSelect from 'react-select'
import { ValueType, ActionMeta, Styles } from 'react-select'
import { ThemeContext, DefaultTheme } from 'styled-components'
import { lighten, darken } from 'utils'

export interface SelectOption {
  label: string
  value: any
}
export type SelectOptionValue = ValueType<SelectOption, false>

export interface SelectProps {
  options: SelectOption[]
  value?: SelectOption
  defaultValue?: SelectOption
  name?: string
  placeholder?: string
  selectedOption?: SelectOptionValue
  onChange: (value: SelectOptionValue) => void
  isDisabled?: boolean
}

export const getCustomStyles = (theme: DefaultTheme) => {
  const {
    colors: { borderColor, copyColor },
    font: { copy }
  } = theme
  const customStyles: Styles<SelectOption, false> = {
    valueContainer: provided => ({
      ...provided,
      minWidth: '200px',
      fontFamily: `${copy}`,
      borderRadius: '0.125rem'
    }),
    indicatorSeparator: () => ({
      display: 'none'
    }),
    dropdownIndicator: (provided, state) => ({
      ...provided,
      color: state.isFocused ? 'hsl(0,0%,20%)' : 'hsl(0,0%,50%)',
      '&:hover': { color: 'hsl(0,0%,20%)' }
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isFocused ? `${lighten(borderColor, 10)}` : 'none',
      color: `${copyColor}`,
      fontFamily: `${copy}`
    }),
    control: (provided, state) => ({
      ...provided,
      borderRadius: '0.125rem',
      boxShadow: state.isFocused ? 'none' : '0 0 0 1px borderColor',
      borderColor: state.isFocused
        ? `${darken(borderColor, 40)}`
        : `${borderColor}`,
      '&:hover': {
        borderColor: `${darken(borderColor, 40)}`
      }
    }),
    singleValue: provided => ({
      ...provided,
      color: `${copyColor}`
    }),
    menu: provided => ({
      ...provided,
      borderRadius: '0.125rem',
      marginTop: '0.25rem'
    }),
    placeholder: provided => ({
      ...provided,
      color: `${lighten(copyColor, 40)}`,
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      width: '95%'
    }),
    noOptionsMessage: provided => ({
      ...provided,
      fontFamily: `${copy}`
    }),
    loadingMessage: provided => ({
      ...provided,
      fontFamily: `${copy}`
    })
  }
  return customStyles
}

const Select: React.FC<SelectProps> = ({
  options,
  name,
  value,
  placeholder,
  selectedOption,
  defaultValue,
  isDisabled,
  onChange
}) => {
  const theme = useContext(ThemeContext)

  const handleOnChange = (
    value: SelectOptionValue,
    action: ActionMeta<SelectOption>
  ) => {
    if (action.action === 'select-option') {
      onChange && onChange(value)
    }
  }
  return (
    <ReactSelect
      onChange={handleOnChange}
      aria-label={placeholder}
      isSearchable={false}
      placeholder={placeholder}
      options={options}
      value={value}
      defaultValue={defaultValue}
      styles={getCustomStyles(theme)}
      isDisabled={isDisabled}
    />
  )
}

export { Select }
