// Components
import { Link } from 'marvel-components'

// Types
import { NavItem } from 'shared-types'

// Utils
import styled from 'styled-components'
import { media } from 'utils'

// Assets
import lwLightLogo from 'assets/lw_theatres_white.png'

const Footer = styled.footer<{ sticky?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: ${props => props.theme.colors.kioskSecondaryBackgroundColorComplement};
  background: ${props => props.theme.colors.kioskSecondaryBackgroundColor};
  padding: 3rem 4rem;
  z-index: 1;
  position: ${props => (props.sticky ? 'fixed' : 'static')};
  bottom: 0;
  margin-top: auto;
  width: calc(100% - 8rem);
`

const FooterLogo = styled.img`
  width: 6rem;
  color: ${props => props.theme.colors.kioskSecondaryBackgroundColorComplement};
  ${media.tablet} {
    width: 6rem;
  }
`

const FooterItemWrapper = styled.div`
  display: flex;
  flex-direction: row;
`

const FooterItem = styled(Link)`
  font-family: ${props => props.theme.font.header};
  font-size: 1.1em;
  text-decoration: none;
  margin-right: 2rem;
  &:last-child {
    margin-right: 0;
  }
`

const footerItems: NavItem[] = [
  {
    name: 'Terms and Conditions',
    path: 'https://lwtheatres.co.uk/terms-conditions/'
  },
  { name: 'FAQ', path: 'https://lwtheatres.co.uk/faq/' },
  { name: 'Privacy Policy', path: 'https://lwtheatres.co.uk/privacy-policy/' }
]

type Props = {
  sticky?: boolean
}

const FooterContainer: React.FC<Props> = ({ sticky }) => {
  return (
    <Footer sticky={sticky}>
      <FooterLogo src={lwLightLogo} />
      <FooterItemWrapper>
        {footerItems.map((navItem, i) => (
          <FooterItem
            text={navItem.name}
            isUnstyled={true}
            key={i}
            href={navItem.path}
          />
        ))}
      </FooterItemWrapper>
    </Footer>
  )
}

export { FooterContainer }
