import React from 'react'
import styled, { css } from 'styled-components'

// Components
import { Button } from 'marvel-components'

// Types
import { SelectPerformanceViewTypes } from 'shared-types'

// Misc
import { media, usePlatform } from 'utils'

interface Props {
  selectedView: SelectPerformanceViewTypes
  onViewChange: (view: SelectPerformanceViewTypes) => void
  isFooter?: boolean
  listTitle?: 'Availability' | 'List'
}

const PerformanceToggleWrapper = styled.div<{ isFooter?: boolean }>(props => {
  const {
    theme: {
      colors: { borderColor },
      showAvailability = true
    },
    isFooter = false
  } = props
  return css`
    display: ${showAvailability ? `flex` : `none`};
    border: 1px solid ${borderColor};
    padding: 0.25rem;
    border-radius: 2rem;
    background: #fff;
    height: 2.375rem;
    width: 100%;

    ${isFooter &&
      css`
        width: 100%;
      `}
  `
})

const StyledButton = styled(Button)`
  margin-right: 0.5rem;

  :last-child {
    margin-right: 0;
  }
`

const PerformanceToggleFooter = styled.div<{ platform?: string }>`
  display: none;

  ${media.tablet} {
    display: flex;
    position: fixed;
    bottom: ${props => (props.platform === 'kiosk' ? '3.5rem' : '0.5rem')};
    left: 1rem;
    right: 1rem;
    z-index: 1;
  }
`

interface ConditionalWrapperProps {
  isFooter: boolean
  children: JSX.Element
  platform?: string
}

const ConditionalWrapper = (props: ConditionalWrapperProps) =>
  props.isFooter ? (
    <PerformanceToggleFooter platform={props.platform}>
      {props.children}
    </PerformanceToggleFooter>
  ) : (
    props.children
  )

const PerformanceToggle: React.FC<Props> = ({
  onViewChange,
  selectedView,
  isFooter = false,
  listTitle = 'Availability'
}) => {
  const handleCalendarClick = () => onViewChange('calendar')
  const handleAvailabilityClick = () => onViewChange('list')
  const platform = usePlatform()
  return (
    <ConditionalWrapper isFooter={isFooter} platform={platform}>
      <PerformanceToggleWrapper isFooter={isFooter}>
        <StyledButton
          text='Calendar'
          buttonType='rounded'
          icon='calendar-alt'
          size='small'
          onClick={handleCalendarClick}
          isDisabled={selectedView === 'calendar'}
          isBlock={true}
        />
        <StyledButton
          text={listTitle}
          buttonType='rounded'
          icon='align-left'
          size='small'
          onClick={handleAvailabilityClick}
          isDisabled={selectedView === 'list'}
          isBlock={true}
        />
      </PerformanceToggleWrapper>
    </ConditionalWrapper>
  )
}

export { PerformanceToggle }
