import { createEntityAdapter, createSelector } from '@reduxjs/toolkit'
import { AppState } from 'modules'
import { Event } from '../shared/types'

export const eventAdapter = createEntityAdapter<Event>()

const { selectAll, selectEntities } = eventAdapter.getSelectors()

export const selectEventState = (state: AppState) => state.selfServe.event

export const selectEvents = createSelector(selectEventState, selectAll)

export const selectEventEntities = createSelector(
  selectEventState,
  selectEntities
)

export const selectIsEventsLoading = (state: AppState) =>
  state.selfServe.event.status === 'idle' ||
  state.selfServe.event.status === 'pending'
