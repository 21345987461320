import React from 'react'
import styled, { css } from 'styled-components'
import { media, FormattedPrice } from 'utils'
import { Link, Switch, Checkbox } from 'marvel-components'
import BookingProtectLogo from 'assets/booking_protect_logo.png'

interface Props {
  bookingProtectTotal: number

  isBookingProtectSelected: boolean
  isBookingProtectConfirmed: boolean

  onBookingProtectSelected: (isBookingProtectSelected: boolean) => void
  onBookingProtectConfirmed: (isBookingProtectConfirmed: boolean) => void
}

const BookingProtectWrapper = styled.div(props => {
  const {
    theme: {
      font: { copy },
      colors: { borderColor }
    }
  } = props

  return css`
    font-family: ${copy};
    background: #fff;
    border: 0.0625rem solid ${borderColor};
    display: flex;
    flex-direction: column;
    padding: 1.5rem;
    margin: 0.75rem 0;
    align-items: flex-start;
    position: relative;
    ${media.phone} {
      border: none;
      padding: 1rem;
      margin: 0 0 1rem 0;
      position: relative;
    }
  `
})

const SwitchWrapper = styled.div`
  ${media.phone} {
    position: absolute;
    right: 1rem;
  }
`

const BookingProtectContent = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  ${media.phone} {
    align-items: flex-start;
  }
`

const BookingProtectCopy = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 90%;
  flex: 9 0 0;
  margin-right: 1rem;

  ${media.tablet} {
    margin-right: 0;
  }
  ${media.phone} {
    max-width: 100%;
    flex: 1 auto 0;
  }
`

const BookingProtectTitle = styled.span`
  font-size: 1rem;
  line-height: 1.5;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  ${media.phone} {
    flex-direction: row;
    font-size: 1rem;
    width: 82%;
    padding: 0.25rem 0;
  }
`

const BookingProtectText = styled.span(props => {
  const {
    theme: {
      colors: { copyColor }
    }
  } = props

  return css`
    font-size: 0.75rem;
    margin-top: 0.75rem;
    line-height: 1.5;
    color: ${copyColor};
  `
})

const BookingProtectConfirmWrapper = styled.div`
  margin-top: 1rem;
`

const BookingProtectLogoWrapper = styled.div`
  width: 12rem;
  margin-bottom: 1rem;
`

const BookingProtectLogoImage = styled.img`
  width: 100%;
`

const WarningLabel = styled.div(props => {
  const {
    theme: {
      colors: { alertColor },
      font: { copy }
    }
  } = props

  return css`
    margin-top: 0.5rem;
    color: ${alertColor};
    font-size: 0.625rem;
    font-family: ${copy};
    white-space: nowrap;
  `
})

export const BookingProtect = (props: Props) => {
  const {
    bookingProtectTotal,

    isBookingProtectSelected,
    isBookingProtectConfirmed,

    onBookingProtectSelected,
    onBookingProtectConfirmed
  } = props
  if (bookingProtectTotal === 0) {
    return null
  }

  return (
    <BookingProtectWrapper>
      <BookingProtectContent>
        <BookingProtectCopy>
          <BookingProtectLogoWrapper>
            <BookingProtectLogoImage
              src={BookingProtectLogo}
            ></BookingProtectLogoImage>
          </BookingProtectLogoWrapper>
          {bookingProtectTotal && (
            <BookingProtectTitle>
              Add Booking Protect for&nbsp;
              <FormattedPrice amount={bookingProtectTotal} currency='GBP' />
            </BookingProtectTitle>
          )}
          <BookingProtectText>
            By adding on refund protection, you can apply for a full refund if
            you or anyone in your party can’t attend the show. This will cover
            you in the event of adverse weather, breakdowns, illnesses and lots
            more. Please read the {''}
            <Link
              text='full Policy here'
              href='https://documents.bookingprotect.com/LWT/p/TKT/eng/Termsandconditionsv3.pdf'
              size='small'
              isUnderlined={true}
            />
            . Click the switch to add to your order.
          </BookingProtectText>
        </BookingProtectCopy>
        <SwitchWrapper>
          <Switch
            isChecked={isBookingProtectSelected}
            onChange={isBookingProtect =>
              isBookingProtectSelected !== isBookingProtect &&
              onBookingProtectSelected(isBookingProtect)
            }
          />
        </SwitchWrapper>
      </BookingProtectContent>

      {isBookingProtectSelected && (
        <BookingProtectConfirmWrapper>
          <Checkbox
            label='I have read and understood the Policy'
            isChecked={isBookingProtectConfirmed}
            onChange={isBookingProtectConfirmed =>
              onBookingProtectConfirmed(isBookingProtectConfirmed)
            }
          />
        </BookingProtectConfirmWrapper>
      )}

      {isBookingProtectSelected && !isBookingProtectConfirmed && (
        <WarningLabel>
          Please indicate that you have read and understood the Policy
        </WarningLabel>
      )}
    </BookingProtectWrapper>
  )
}
