import * as Yup from 'yup'
import { phoneRegExp, passwordRegExp } from 'utils'

export const firstNameValidator = Yup.string()
  .label('First name')
  .min(2)
  .max(25)
  .required('Please enter your first name.')

export const lastNameValidator = Yup.string()
  .label('Last name')
  .min(2)
  .max(25)
  .required('Please enter your last name.')

export const emailValidator = Yup.string()
  .email('Please enter a valid email address.')
  .required('Email address is required.')

export const telephoneNumberValidator = Yup.string()
  .trim()
  .required('Please enter your mobile number')
  .matches(phoneRegExp, 'Phone number is not valid')

export const addressValidator = Yup.object().shape({
  addressLine1: Yup.string().required('Address line one is required.'),
  city: Yup.string().required('City is required.'),
  country: Yup.string().required('Country is required.'),
  postcode: Yup.string().required('Postcode is required.')
})

export const isPaymentValidValidator = Yup.bool().oneOf(
  [true],
  'Please ensure payment details are correct'
)

export const isAcceptTermsValidator = Yup.bool().oneOf(
  [true],
  'Please indicate that you accept the Terms and Conditions'
)

export const loginValidator = Yup.string().required(
  'Email address / Username is required.'
)

export const passwordValidator = Yup.string().required(
  'Please enter a valid password.'
)

export const createPasswordValidator = Yup.string()
  .required('Please enter a valid password.')
  .min(8, 'Please ensure your password is at least 8 characters long.')
  .matches(
    passwordRegExp,
    'Must be at least 8 characters long & include numbers & letters'
  )
