import React from 'react'
import styled, { css } from 'styled-components'

const AvailabilityLegendWrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
`
const AvailabilityLabels = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 0.5rem;
`

const AvailabilityLabel = styled.span((props) => {
  const {
    theme: {
      font: { copy }
    }
  } = props
  return css`
    font-family: ${copy};
    font-size: 0.5rem;
  `
})

const Gradient = styled.div`
  width: 8.125rem;
  height: 0.625rem;
`

export const AvailabilityLegend = () => {
  return (
    <AvailabilityLegendWrapper>
      <Gradient />
      <AvailabilityLabels>
        <AvailabilityLabel>Value Seats</AvailabilityLabel>
        <AvailabilityLabel>Premium Seats</AvailabilityLabel>
      </AvailabilityLabels>
    </AvailabilityLegendWrapper>
  )
}
