import React from 'react'

// Components
import { ToolTip } from 'marvel-components'
// Misc
import styled, { css } from 'styled-components/macro'
import { FormattedPrice, media, usePlatform } from 'utils'

// Types
import { IconName } from 'shared-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { IconPrefix } from '@fortawesome/fontawesome-svg-core'

interface Props {
  index?: number
  priceLevelIds: string[]
  price: number
  originalPrice?: number
  backgroundColor: string
  onSelectItem: (ids: string[]) => void
  onRemoveItem: (ids: string[]) => void
  isDisabled: boolean
  icon?: IconName
  iconPrefix?: IconPrefix
  note?: string
  platform?: string
}

const OriginalPrice = styled.div`
  text-decoration: line-through;
  font-size: 0.625rem;
  margin-right: 0.25rem;
  opacity: 0.8;
`

const HighlightedPrice = styled.div`
  color: ${props => props.theme.colors.alertColor};
`

const LegendItemWrapper = styled.button<{
  isDisabled: boolean
  platform?: string
}>`
  display: flex;
  align-items: center;
  font-family: ${props => props.theme.font.copy};
  color: ${props => props.theme.colors.copyColor};
  font-size: 0.75rem;
  padding: 0.5rem;
  cursor: pointer;
  transition: filter 0.4s, opacity 0.4s;
  border: none;
  outline: none;
  background: ${props => props.theme.colors.fadedHighlightColor};
  margin: 0.25rem 0.125rem;
  border-top-left-radius: 3rem;
  border-bottom-left-radius: 3rem;
  border-top-right-radius: 3rem;
  border-bottom-right-radius: 3rem;
  min-width: 5rem;
  justify-content: center;
  border: 1px solid #ccc;

  ${media.tablet} {
    min-width: auto;
    min-width: initial;
    font-size: ${props => (props.platform === 'kiosk' ? '1rem' : '0.75rem')};
  }

  ${props =>
    props.isDisabled &&
    css`
      opacity: 0.8;
      background: transparent;
    `}
`

const PriceColourRoundel = styled.div<{
  backgroundColor: string
  isDisabled: boolean
  isHighlighted?: boolean
  platform?: string
}>`
  background: ${props =>
    !props.isDisabled ? props.backgroundColor : '#e8e8e8'};

  border-radius: 50%;
  display: block;
  width: 0.875rem;
  height: 0.875rem;
  min-width: 0.875rem;
  min-height: 0.875rem;
  margin-right: 0.375rem;
  position: relative;
  ${media.tablet} {
    width: ${props => (props.platform === 'kiosk' ? '1.2rem' : '0.875rem;')};
    height: ${props => (props.platform === 'kiosk' ? '1.2rem' : '0.875rem;')};
  }
  ${props =>
    props.isHighlighted &&
    css`
      border: 2px solid ${props => props.theme.colors.alertColor};
    `}
`

const StyledIcon = styled(FontAwesomeIcon)(props => {
  return css`
    && {
      color: #fff;
    }
  `
})

export const LegendItem = (props: Props) => {
  const {
    index,
    price,
    originalPrice,
    backgroundColor,
    onSelectItem,
    onRemoveItem,
    priceLevelIds,
    isDisabled,
    note,
    icon,
    iconPrefix = 'fas'
  } = props

  const className = `legend-item-${index}`

  const handleOnClick = () => {
    isDisabled
      ? onSelectItem && onSelectItem(priceLevelIds)
      : onRemoveItem && onRemoveItem(priceLevelIds)
  }
  const platform = usePlatform()

  return (
    <ToolTip content={note} disabled={!note}>
      <LegendItemWrapper
        className={className}
        onClick={handleOnClick}
        isDisabled={isDisabled}
        platform={platform}
      >
        <PriceColourRoundel
          backgroundColor={backgroundColor}
          isDisabled={isDisabled}
          isHighlighted={!!originalPrice}
          platform={platform}
        >
          {icon && iconPrefix && (
            <StyledIcon icon={[iconPrefix, icon]} size='xs' />
          )}
        </PriceColourRoundel>
        {originalPrice && (
          <>
            <OriginalPrice>
              <FormattedPrice amount={originalPrice} currency='GBP' />
            </OriginalPrice>
            <HighlightedPrice>
              <FormattedPrice amount={price} currency='GBP' />
            </HighlightedPrice>
          </>
        )}
        {!originalPrice && <FormattedPrice amount={price} currency='GBP' />}
      </LegendItemWrapper>
    </ToolTip>
  )
}
