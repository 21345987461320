import React, { ReactNode } from 'react'
import styled from 'styled-components/macro'
import ReactModal from 'react-modal'
import { Icon } from 'marvel-components'

// Misc
import { media, usePlatform } from 'utils'

interface Props {
  children: ReactNode
  onCloseOverlay: () => void
  isOverlayOpen: boolean
  width?: string
  height?: string
  isCloseVisible?: boolean
  shouldCloseOnEsc?: boolean
  shouldCloseOnOverlayClick?: boolean
  platform?: string
  isOverFlowVisible?: boolean
  isDark?: boolean
}

const CloseButton = styled.div<{ platform?: string }>`
  position: absolute;
  background: none;
  outline: none;
  right: 4px;
  top: 6px;
  z-index: 1;
  cursor: pointer;
  fill: #999;
  ${media.tablet} {
    background: ${props => (props.platform === 'kiosk' ? '#fff' : 'none')};
    right: ${props => (props.platform === 'kiosk' ? '10px' : '4px')};
    top: ${props => (props.platform === 'kiosk' ? '12px' : '6px')};
    border-radius: ${props => (props.platform === 'kiosk' ? '50%' : '0')};
    padding: ${props => (props.platform === 'kiosk' ? '8px 4px' : '0')};
    box-shadow: ${props =>
      props.platform === 'kiosk' ? '0px 1px 6px 0px rgba(0,0,0,0.59)' : 'none'};
  }
`

ReactModal.setAppElement('#root')

export const Modal: React.FC<Props> = ({
  isOverlayOpen,
  children,
  onCloseOverlay,
  height,
  width,
  isCloseVisible = true,
  shouldCloseOnEsc = true,
  shouldCloseOnOverlayClick = true,
  isOverFlowVisible = true,
  isDark = false
}) => {
  const handleCloseModal = () => {
    onCloseOverlay()
  }
  const platform = usePlatform()
  return (
    <ReactModal
      isOpen={isOverlayOpen}
      onRequestClose={handleCloseModal}
      shouldCloseOnEsc={shouldCloseOnEsc}
      shouldCloseOnOverlayClick={shouldCloseOnOverlayClick}
      style={{
        overlay: {
          backgroundColor: 'rgba(0, 0, 0, 0.75)',
          zIndex: 2
        },
        content: {
          top: '50%',
          left: '50%',
          right: 'auto',
          bottom: 'auto',
          marginRight: '-50%',
          transform: 'translate(-50%,-50%)',
          outline: '0',
          overflow: `${isOverFlowVisible ? 'auto' : 'hidden'}`,
          height: `${height ? height : `auto`}`, //45vw
          width: `${width ? width : `auto`}`, // '80vw',
          maxHeight: '95%',
          maxWidth: '95%',
          borderRadius: '0',
          border: 'none',
          padding: '0',
          background: `${isDark ? '#000' : '#fff'}`
        }
      }}
    >
      {isCloseVisible && (
        <CloseButton platform={platform} onClick={handleCloseModal}>
          <Icon icon='times' size={platform === 'kiosk' ? '2x' : '1x'} />
        </CloseButton>
      )}
      {children}
    </ReactModal>
  )
}
