import React, { useContext } from 'react'
import { DefaultTheme } from 'styled-components/macro'
import { ThemeContext } from 'styled-components/macro'

interface Props {
  x: number
  y: number
  fontSize: number
  width: number
  height: number
  angle: number
  text: string
}

const SeatMapRowLabelNM: React.FC<Props> = ({
  x,
  y,
  fontSize,
  width,
  height,
  angle,
  text
}) => {
  const themeContext = useContext<DefaultTheme>(ThemeContext)
  const adjustedX = x + width / 2
  const adjustedY = y + height / 2
  return (
    <>
      <g transform={`rotate(${180 - angle}, ${adjustedX}, ${adjustedY})`}>
        <text
          alignmentBaseline="central"
          textAnchor="middle"
          x={adjustedX}
          y={adjustedY}
          fontFamily={themeContext.font.copy}
          fontSize={fontSize}
        >
          {text.toUpperCase()}
        </text>
      </g>
    </>
  )
}

export const SeatMapRowLabel = React.memo(SeatMapRowLabelNM)
