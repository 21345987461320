import React, { useEffect } from 'react'

// Components
import { Layout } from 'marvel-components'

// Container
import {
  SmartWaiterContainer,
  SmartWaiterOfflineContainer,
  VenueListContainer,
  BrandLogoContainer
} from 'containers/SmartWaiter'

// View
import { VenueView } from 'views/SmartWaiter'

// Actions
import { fetchVenues } from 'modules/smart-waiter/venue/actions'

// Misc
import { Route, Switch, useRouteMatch } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { useInactivityModal, usePlatform } from 'utils'

const SmartWaiterHomeView: React.FC = () => {
  // Routing
  const { path } = useRouteMatch()
  const dispatch = useDispatch()
  const platform = usePlatform()

  // Fetch Data - venue data is used for all views
  useEffect(() => {
    dispatch(fetchVenues())
  }, [dispatch])

  useInactivityModal({
    shouldOpen: platform === 'kiosk',
    payload: {
      variant: 'kiosk-inactivity'
    }
  })

  return (
    <Switch>
      <Route exact strict path={`${path}`}>
        <SmartWaiterContainer />
      </Route>
      <Route exact path={`${path}/venue`}>
        <Layout
          pageTitle={`Select Venue | LW Theatres`}
          renderBrandLogo={<BrandLogoContainer />}
          renderMain={<VenueListContainer />}
        />
      </Route>
      <Route path={`${path}/venue/:venueId`}>
        <VenueView />
      </Route>
      <Route exact path={`${path}/offline`}>
        <SmartWaiterOfflineContainer messageType={'offline'} />
      </Route>
      <Route exact path={`${path}/interval-closed`}>
        <SmartWaiterOfflineContainer messageType={'interval'} />
      </Route>
    </Switch>
  )
}

export { SmartWaiterHomeView }
