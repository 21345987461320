import React from 'react'

// Components
import { BreadcrumbNavLink } from './BreadcrumbNavLink'

// Types
import { NavItem } from 'shared-types'

// Misc
import styled from 'styled-components/macro'
import { media } from 'utils'

interface Props {
  items: NavItem[]
}

const BreadcrumbWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin-bottom: 1rem;
  ${media.phone} {
    margin-bottom: 1rem;
  }
`

export const BreadcrumbNav: React.FC<Props> = ({ items }) => {
  return (
    <BreadcrumbWrapper>
      {items.map((item, key) => {
        const { name, path, current = false } = item
        return (
          <BreadcrumbNavLink
            key={key}
            name={name}
            to={path}
            isLast={key + 1 === items.length}
            current={current}
          />
        )
      })}
    </BreadcrumbWrapper>
  )
}
