// Actions
import { bannerActions } from 'modules/banner'

// Utils
import { createAction, createAsyncThunk } from '@reduxjs/toolkit'
import { AxiosError } from 'axios'
import { normalize } from 'normalizr'

// Types
import { APIResponse, APIError } from 'shared-types'
import { Schema } from 'normalizr'
import { AppDispatch } from 'app/reduxStore'
import { AppState } from 'modules/types'

export const resetSW = createAction('marvel/smart-waiter/RESET')

type AsyncThunkConfig = {
  state?: AppState
  dispatch?: AppDispatch
  extra?: unknown
  rejectValue?: unknown
}

export const createAPIThunk = <Returned, ThunkArg = void>(
  typePrefix: string,
  apiCall: (arg: ThunkArg) => Promise<APIResponse>,
  normalizeSchema?: Schema
) =>
  createAsyncThunk<Returned, ThunkArg, AsyncThunkConfig>(
    typePrefix,
    async (arg: ThunkArg, { dispatch, rejectWithValue }) => {
      try {
        const response = await apiCall(arg)
        if (normalizeSchema) {
          const normalized = normalize<any, Returned>(
            response.data,
            normalizeSchema
          )
          return normalized.entities
        }
        return response.data as Returned
      } catch (err) {
        let error: AxiosError<APIError> = err
        let message = err.message
          ? `The following error occurred: ${err.message}<br/>Please try again.`
          : 'An unknown error occurred, please try again.'
        if (error.response) {
          message = error.response.data._meta.message
        }
        dispatch(
          bannerActions.setBannerContent({
            bannerType: 'error',
            title: 'Please Note',
            text: message
          })
        )
        return rejectWithValue(message)
      }
    }
  )
