import { RouteProps } from 'shared-types'

// Anything in this list, should not be forwarded from
// one route to another
const searchParamsToDiscard = [
  'error3d', // Added from 3ds1 redirect to the /order/xxx route, it's not used elsewhere
  'token',
  'performanceId',
  'ga'
]

const urlParams = (url: string, bypassDiscard = false): RouteProps => {
  // Filter out search parameters
  const searchParams = new URLSearchParams(window.location.search)
  if (!bypassDiscard) {
    for (const paramName of searchParamsToDiscard) {
      if (searchParams.get(paramName) !== null) {
        searchParams.delete(paramName)
      }
    }
  }
  const searchParamsStr = searchParams.toString()

  return {
    pathname: url,
    search: `?${searchParamsStr}`
  }
}

// Select Performance
// -----------------------------------------
export const performanceRoute = () => urlParams(`/`)

export const eventRoute = () => urlParams(`/event/`)

export const calendarRoute = (eventId: string) => urlParams(`/event/${eventId}`)

export const performanceListRoute = (eventId: string) => {
  return urlParams(`/event/${eventId}/performance-list`)
}

export const availabilityRoute = (eventId: string) =>
  urlParams(`/event/${eventId}/availability`)

// Select Ticket
// -----------------------------------------
export const selectTicketsRoute = (eventId: string, performanceId: string) =>
  urlParams(`/event/${eventId}/performance/${performanceId}`)

export const selectDaySeatsRoute = (eventId: string) =>
  urlParams(`/event/${eventId}/day-seats`)

export const selectDaySeatTicketsRoute = (
  eventId: string,
  performanceId: string
): RouteProps =>
  urlParams(`/event/${eventId}/day-seats/performance/${performanceId}`)

// Preorder Products
// -----------------------------------------
export const preorderProductsRoute = (eventId: string, performanceId: string) =>
  urlParams(`/event/${eventId}/performance/${performanceId}/products`)

// Checkout
// -----------------------------------------
export const checkoutRoute = (eventId: string, transactionGuid: string) =>
  urlParams(`/event/${eventId}/reservation/${transactionGuid}/checkout`, true)

export const kioskPaymentRoute = (eventId: string, transactionGuid: string) =>
  urlParams(`/event/${eventId}/reservation/${transactionGuid}/kiosk-payment`)

// Order Summary
// -----------------------------------------
export const orderSummaryRoute = (transactionGuid: string) =>
  urlParams(`/order/${transactionGuid}/success`)

// Manage order
// -----------------------------------------
export const manageOrderRoute = (orderId: string) =>
  urlParams(`/order/${orderId}/manage`)

// Order Exchange
// -----------------------------------------
export const orderExchangeSuccessRoute = (orderId: string) =>
  urlParams(`/order/${orderId}/exchange/success`)

// Kiosk
// -----------------------------------------
export const kioskAuthentication = () => '/kiosk/authenticate'

export const kioskMainMenu = () => '/kiosk/menu'

export const kioskScreenSaver = () => '/kiosk/screen-saver'

// Venue
// -----------------------------------------
export const venueHome = (venueId: string) =>
  urlParams(`/kiosk/venue/${venueId}`)

export const venueEvents = (venueId: string) =>
  urlParams(`/kiosk/venue/${venueId}/event`)

// Smart Waiter
// -----------------------------------------
export const smartWaiterVenue = (venueId: string) =>
  urlParams(`/smart-waiter/venue/${venueId}`)
