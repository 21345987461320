import React from 'react'
import { useField, FieldHookConfig, ErrorMessage } from 'formik'
import styled, { css } from 'styled-components/macro'
import { lighten, darken } from 'utils'
import { Icon, FormError, Input } from 'marvel-components'

type InputVariations = 'email' | 'password' | 'text' | 'tel' | 'number'

interface Props {
  type: InputVariations
  isDisabled?: boolean
  isRequired?: boolean
  placeholder: string
  autocomplete?: string
  mobileNarrow?: boolean
  readOnly?: boolean
  helpText?: JSX.Element
}

const FormInputWrapper = styled.div(props => {
  const {
    theme: {
      font: { input }
    }
  } = props

  return css`
    position: relative;
    display: flex;
    flex: 1 0 auto;
    flex-direction: column;
    box-sizing: border-box;
    font-family: ${input};
    padding-bottom: 1.5rem;
  `
})

const ErrorLabel = styled(FormError)(props => {
  const {
    theme: {
      colors: { alertColor }
    }
  } = props

  return css`
    + ${InputWrapper} {
      border: 0.0625rem solid ${alertColor};
    }
  `
})

const InputWrapper = styled.div<{ isDisabled: boolean }>(props => {
  const {
    isDisabled,
    theme: {
      colors: { borderColor }
    }
  } = props

  return css`
    display: flex;
    position: relative;
    align-items: center;
    flex: 1 0 auto;
    border-radius: 0.125rem;
    border: 1px solid ${borderColor};
    background: #ffffff;

    :focus-within {
      border: 0.0625rem solid ${darken(borderColor, 40)};
    }

    ${isDisabled &&
    css`
      border: 0.0625rem solid ${lighten(borderColor, 10)};
    `}
  `
})

const ErrorIcon = () => {
  return (
    <ErrorIconWrapper>
      <Icon icon='exclamation-circle' />
    </ErrorIconWrapper>
  )
}

const ErrorIconWrapper = styled.div(props => {
  const {
    theme: {
      colors: { alertColor }
    }
  } = props
  return css`
    fill: ${alertColor};
    right: 0.5rem;
    position: absolute;
    top: 0.65rem;
  `
})

const HelpTextWrapper = styled.div`
  margin-top: 0.5rem;
`

export const FormInput: React.FC<Props & FieldHookConfig<string | number>> = ({
  type,
  isDisabled = false,
  isRequired = false,
  placeholder,
  autocomplete,
  mobileNarrow = false,
  readOnly = false,
  helpText,
  ...fieldProps
}) => {
  const [field, meta] = useField(fieldProps)
  const { name } = field

  return (
    <>
      <FormInputWrapper>
        {meta.touched && meta.error ? (
          <ErrorMessage {...field} component={ErrorLabel} />
        ) : null}
        <InputWrapper isDisabled={isDisabled}>
          <Input
            {...field}
            type={type}
            placeholder={`${placeholder}${isRequired ? ' *' : ''}`}
            isDisabled={isDisabled}
            aria-label={name}
            autoComplete={autocomplete}
            mobileNarrow={mobileNarrow}
            readOnly={readOnly}
          />
          {meta.touched && meta.error ? (
            <ErrorMessage {...field} component={ErrorIcon} />
          ) : null}
        </InputWrapper>
        {helpText ? <HelpTextWrapper>{helpText}</HelpTextWrapper> : null}
      </FormInputWrapper>
    </>
  )
}
