import React, { useEffect } from 'react'
import styled, { css } from 'styled-components/macro'
import { media } from 'utils'
import { Icon } from 'marvel-components'
import { BannerType, IconName } from 'shared-types'
import shadow from 'assets/shadow.png'

interface Props {
  bannerType?: BannerType
  title?: string
  text?: string
  onCloseBanner: () => void
  isModalOpen?: boolean
}

const BannerPosition = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: row;
  pointer-events: none;
  z-index: 3;
`

const BannerSpacer = styled.div`
  flex: 1 0 0;
  width: 22.4rem;
  min-width: 22.4rem;
  max-width: 22.4rem;
  ${media.tablet} {
    display: none;
  }
`

const CLOSE_BANNER_TIMEOUT = 5000

const BannerWrapper = styled.div<{ bannerColor: string; isModalOpen: boolean }>(
  props => {
    const {
      theme: {
        font: { copy }
      },
      bannerColor,
      isModalOpen
    } = props

    return css`
      position: relative;
      display: flex;
      flex: 3 0 0;
      box-sizing: border-box;
      flex-direction: column;
      display: flex;
      font-family: ${copy};
      animation: transitionIn 0.2s ease forwards;
      width: calc(100% - 22.4rem);
      min-width: calc(100% - 22.4rem);
      max-width: calc(100% - 22.4rem);

      ${isModalOpen &&
      css`
        width: 100%;
        min-width: 100%;
        max-width: 100%;
      `}

      @keyframes transitionIn {
        0% {
          transform: translateY(-3.125rem);
          opacity: 0;
        }
        80% {
          transform: translateY(0);
        }
        100% {
          opacity: 1;
        }
      }

      :before {
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        background: ${bannerColor};
        left: 0;
        top: 0;
        opacity: 0.95;
        z-index: -1;
      }

      :after {
        content: '';
        position: absolute;
        bottom: -0.3125rem;
        left: 0;
        right: 0;
        height: 0.5rem;
        background: url(${shadow}) repeat-x;
      }
      ${media.tablet} {
        max-width: 100%;
        width: 100%;
      }
    `
  }
)

const BannerContentWrapper = styled.div`
  padding: 1rem 2rem 1.5rem 2rem;
  pointer-events: initial;

  ${media.tablet} {
    padding: 1rem;
  }
`

const BannerTitle = styled.div<{ fillColor: string }>(props => {
  const { fillColor } = props

  return css`
    display: flex;
    flex-direction: row;

    fill: ${fillColor};

    ${media.tablet} {
      margin-left: 0;
    }
  `
})

const BannerTitleText = styled.span(props => {
  const {
    theme: {
      colors: { headingColor }
    }
  } = props
  return css`
    color: ${headingColor};
    margin-bottom: 0.5rem;
  `
})

const BannerCopy = styled.span(props => {
  const {
    theme: {
      colors: { copyColor }
    }
  } = props
  return css`
    color: ${copyColor};
    font-size: 0.875rem;
  `
})

const CloseButton = styled.button(props => {
  return css`
    position: absolute;
    padding: 0;
    margin: 0;
    right: 1rem;
    top: 1rem;
    background: none;
    border: none;
    outline: none;
    cursor: pointer;
  `
})

export const Banner = (props: Props) => {
  const { title, text, onCloseBanner, bannerType, isModalOpen = false } = props

  let iconType: IconName = 'exclamation-circle'
  let bannerColor = '#f2dede'
  let fillColor = '#a94442'

  switch (bannerType) {
    case 'information':
      iconType = 'info-circle'
      bannerColor = '#d9edf7'
      fillColor = '#31708f'
      break
    case 'success':
      iconType = 'check-circle'
      bannerColor = '#dff0d8'
      fillColor = '#3c763d'
      break
  }

  useEffect(() => {
    const handler = (event: KeyboardEvent) => {
      event.key === 'Escape' && onCloseBanner()
    }
    window.addEventListener('keydown', handler)
    return () => {
      window.removeEventListener('keydown', handler)
    }
  }, [onCloseBanner])

  useEffect(() => {
    if (bannerType && text !== '') {
      const timer = setTimeout(onCloseBanner, CLOSE_BANNER_TIMEOUT)
      return () => clearTimeout(timer)
    }
  }, [onCloseBanner, bannerType, text])

  if (!bannerType || !text) {
    return null
  }

  return (
    <BannerPosition className='position'>
      {!isModalOpen && <BannerSpacer />}
      <BannerWrapper bannerColor={bannerColor} isModalOpen={isModalOpen}>
        <BannerContentWrapper>
          <BannerTitle fillColor={fillColor}>
            <Icon padding='default' icon={iconType} />
            <BannerTitleText>{title}</BannerTitleText>
          </BannerTitle>
          <BannerCopy dangerouslySetInnerHTML={{ __html: text }} />
          <CloseButton onClick={() => onCloseBanner()}>
            <Icon icon={'times'} />
          </CloseButton>
        </BannerContentWrapper>
      </BannerWrapper>
    </BannerPosition>
  )
}
