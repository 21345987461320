// Misc
import { createSlice, createEntityAdapter } from '@reduxjs/toolkit'

// Actions
import { fetchMenu } from 'modules/smart-waiter/menu/actions'

// Types
import { ZProduct } from 'shared-types'

export const productsAdapter = createEntityAdapter<ZProduct>()

const productSlice = createSlice({
  name: 'products',
  initialState: productsAdapter.getInitialState({
    loading: 'idle'
  }),
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchMenu.pending, (state, action) => {
      state.loading = 'pending'
    })
    builder.addCase(fetchMenu.fulfilled, (state, action) => {
      if (action.payload.products) {
        productsAdapter.upsertMany(state, action.payload.products)
      }
      state.loading = 'idle'
    })
  }
})

export default productSlice.reducer
