import overlayReducer from './reducers'

import {
  ThreeDSOverlayProps,
  ConfirmClearBasketOverlayContainerProps,
  InactivityContainerProps as KioskOrderSuccessInactivityContainerProps,
  KioskExternalPageOverlayContainerProps,
  MediaOverlayContainerProps
} from 'containers/Overlay'
import {
  ProductItemContainerProps,
  LoginProps,
  ThreeDSTicketingProps,
  SeatModalProps,
  ConfirmRestrictionsModalProps
} from 'containers/Ticketing'
import { ThreeDSHospitalityProps } from 'containers/Hospitality'
import {
  ThreeDSSmartWaiterProps,
  ConfirmAgeOverlayContainerProps
} from 'containers/SmartWaiter'
import { AlternativeVenueProps } from 'containers/Overlay/AlternativeVenueOverlayContainer'

export const CLOSE_MODAL = 'marvel/overlay/CLOSE_MODAL'
export const OPEN_MODAL = 'marvel/overlay/OPEN_MODAL'

type ProductModal = {
  variant: 'product'
  props: ProductItemContainerProps
}
type MediaModal = {
  variant: 'media'
  props: MediaOverlayContainerProps
}
type LoginModal = {
  variant: 'login'
  props: LoginProps
}
type ConfirmAgeModal = {
  variant: 'confirm-age'
  props: ConfirmAgeOverlayContainerProps
}
type ThreeDSModal = {
  variant: 'three-ds'
  props: ThreeDSOverlayProps
}
type ThreeDSTicketingModal = {
  variant: 'three-ds-ticketing'
  props: ThreeDSTicketingProps
}
type ThreeDSHospitalityModal = {
  variant: 'three-ds-hospitality'
  props: ThreeDSHospitalityProps
}
type ThreeDSSmartWaiterModal = {
  variant: 'three-ds-smart-waiter'
  props: ThreeDSSmartWaiterProps
}
type ClearBasketModal = {
  variant: 'clear-basket'
  props: ConfirmClearBasketOverlayContainerProps
}
type SeatModal = {
  variant: 'seat'
  props: SeatModalProps
}
type SeatRestrictions = {
  variant: 'seat-restrictions'
  props: ConfirmRestrictionsModalProps
}
type KioskOrderSuccessInactivityModal = {
  variant: 'kiosk-inactivity'
  props?: KioskOrderSuccessInactivityContainerProps
}
type KioskConfirmClearBasketModal = {
  variant: 'kiosk-clear-basket'
}
type KioskExternalPageModal = {
  variant: 'kiosk-external-page'
  props: KioskExternalPageOverlayContainerProps
}
type AlternativeVenueModal = {
  variant: 'alternative-venue'
  props: AlternativeVenueProps
}

export type ModalPayload =
  | ProductModal
  | LoginModal
  | ConfirmAgeModal
  | ThreeDSModal
  | ThreeDSTicketingModal
  | ThreeDSHospitalityModal
  | ThreeDSSmartWaiterModal
  | ClearBasketModal
  | SeatModal
  | SeatRestrictions
  | KioskOrderSuccessInactivityModal
  | KioskConfirmClearBasketModal
  | KioskExternalPageModal
  | AlternativeVenueModal
  | MediaModal

export interface OpenModal {
  type: typeof OPEN_MODAL
  payload: ModalPayload
}
export interface CloseModal {
  type: typeof CLOSE_MODAL
}

export type ModalActions = OpenModal | CloseModal

export type OverlayState = ReturnType<typeof overlayReducer>
