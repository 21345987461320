import React from 'react'
import styled from 'styled-components/macro'

// Components
import { Button, CountdownTimer } from 'marvel-components'

// Selectors
import { selectBasketExpiry } from 'modules/basket/selectors'

// Misc
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import moment from 'moment'

// Actions
import { expireRedirect } from 'modules/basket/actions'

const BookingHeaderWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 1rem;
`

const BookingHeaderContainer: React.FC = () => {
  const history = useHistory()
  const dispatch = useDispatch()

  //Selectors
  const expiresAt = useSelector(selectBasketExpiry)
  const countdownMs = moment(expiresAt).diff(moment(), 'ms')

  // Actions
  const handleExpiryRedirect = () => {
    dispatch(expireRedirect())
  }
  const handleFinalCall = () => {}
  const handleBackClick = () => {
    history.goBack()
  }

  return (
    <BookingHeaderWrapper>
      <Button
        text='Back'
        buttonType='link-button'
        icon='arrow-left'
        onClick={handleBackClick}
      />
      {expiresAt && (
        <CountdownTimer
          countdownMs={countdownMs}
          onExpiry={handleExpiryRedirect}
          warningOffsetMs={60 * 1000}
          onWarning={handleFinalCall}
        />
      )}
    </BookingHeaderWrapper>
  )
}
export { BookingHeaderContainer }
