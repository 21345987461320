import { createEntityAdapter, createSlice } from '@reduxjs/toolkit'
import { SeatType } from 'shared-types'
import { fetchPerformance } from './actions'

export const seatTypeAdapter = createEntityAdapter<SeatType>()

const initialState = seatTypeAdapter.getInitialState()

const seatTypeSlice = createSlice({
  name: 'seatType',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchPerformance.fulfilled, (state, action) => {
      action.payload.data.seatTypes &&
        seatTypeAdapter.setAll(state, action.payload.data.seatTypes)
    })
  }
})

export const seatTypeActions = {
  ...seatTypeSlice.actions
}

export default seatTypeSlice.reducer
