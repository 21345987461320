import backgroundAsset from './assets/phantom_sidebar.jpg'
export const Phantom = {
  colors: {
    lwBrandColor: '#ffffff',
    lwBrandColorComplement: '#111111',
    mainBackgroundColor: '#0b0c1f'
  },
  themeBackgroundColour: '#0b0c1f',
  themeBackgroundAsset: backgroundAsset,
  orderSuccess: {
    showHospitality: true,
    showCecilBeatonBar: true
  }
}
