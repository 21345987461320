import React from 'react'
import styled from 'styled-components/macro'
import { useFormikContext } from 'formik'

// Components
import { Title, FormSelect } from 'marvel-components'

const FormSection = styled.div`
  margin-top: 2rem;
`
const StyledTitle = styled(Title)`
  margin-bottom: 0.5rem;
`

type SelectTimeFieldProps = {
  eventTimes: { value: string; label: string }[]
  name: string
}
const SelectTimeField = ({ eventTimes, name }: SelectTimeFieldProps) => {
  const { setFieldValue } = useFormikContext()

  React.useEffect(() => {
    if (eventTimes.length === 1) {
      setFieldValue(name, eventTimes[0])
    }
  }, [eventTimes, setFieldValue, name])

  return (
    <>
      {eventTimes.length > 1 ? (
        <FormSection>
          <StyledTitle
            isCapitalised={false}
            isCentralised={true}
            isBold={false}
            size='medium'
          >
            What time is the performance?
          </StyledTitle>
          <FormSelect
            name={name}
            placeholder='Select your performance time'
            options={eventTimes}
          />
        </FormSection>
      ) : (
        <FormSection>
          <StyledTitle
            isCapitalised={false}
            isCentralised={true}
            isBold={false}
            size='medium'
          >
            For the following performance:
          </StyledTitle>
          <Title
            isCapitalised={false}
            isCentralised={true}
            isBold={true}
            size='xlarge'
            isMultiline={true}
          >
            <span>{eventTimes[0]?.label}</span>
          </Title>
        </FormSection>
      )}
    </>
  )
}

export { SelectTimeField }
