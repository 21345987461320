import { createSlice } from '@reduxjs/toolkit'

// Utils
import ticketingApi from 'modules/ticketing/services/ticketingApi'
import { createAPIThunk } from 'modules/ticketing/shared/actions'

// Types
import { AuthUser } from 'shared-types'
import { AppState } from 'modules/types'

type AuthRequest = {
  login: string
  password: string
}

type ResetPasswordRequest = {
  login: string
}

// Actions
const login = createAPIThunk<AuthUser, AuthRequest>(
  'marvel/ticketing/LOGIN',
  (data, contextId, authToken) =>
    ticketingApi.create(
      `/auth/login/`,
      {
        login: data.login,
        password: data.password
      },
      contextId,
      authToken
    )
)

const forgotPassword = createAPIThunk<AuthUser, ResetPasswordRequest>(
  'marvel/ticketing/FORGOT_PASSWORD',
  (data, contextId, authToken) =>
    ticketingApi.create(
      `/auth/reset-password/`,
      { login: data.login },
      contextId,
      authToken
    )
)

// Reducer

type AuthState = {
  status: 'pending' | 'loading' | 'finished'
  currentUser?: AuthUser
}
const initialState = { status: 'pending' } as AuthState
const authSlice = createSlice({
  name: 'auth',
  initialState: initialState,
  reducers: {
    logout(state) {
      state.currentUser = undefined
      state.status = 'pending'
    }
  },
  extraReducers: builder => {
    builder.addCase(login.pending, state => {
      state.status = 'loading'
      state.currentUser = undefined
    })
    builder.addCase(login.fulfilled, (state, action) => {
      if (action.payload) {
        state.status = 'finished'
        state.currentUser = action.payload.data
      }
    })
  }
})

// Actions
export const authActions = {
  login,
  forgotPassword,
  ...authSlice.actions
}

// Selectors
export const authSelectors = {
  selectIsLoggedIn: (state: AppState) => !!state.ticketing.auth.currentUser,
  selectUser: (state: AppState) => state.ticketing.auth.currentUser
}

export default authSlice.reducer
