import React, { useEffect } from 'react'

// Views
import { ProductView } from 'views/SmartWaiter'

// Containers
import {
  BrandLogoContainer,
  CheckoutButtonContainer,
  MenuSectionListContainer,
  BasketSummaryContainer,
  MenuContainer
} from 'containers/SmartWaiter'

// Components
import { Layout } from 'marvel-components'

// Actions
import { menuActions, menuSelectors } from 'modules/smart-waiter/menu'

// Misc
import { useDispatch } from 'react-redux'
import { Route, Switch, useRouteMatch, useParams } from 'react-router-dom'
import { trackSmartWaiterMenu } from 'utils'
import { useTypedSelector } from 'modules'
import { venueSelectors } from 'modules/smart-waiter/venue'
import { serviceSelectors } from 'modules/smart-waiter/service'

type Props = {
  onCheckoutClick: () => void
}

const MenuView: React.FC<Props> = ({ onCheckoutClick }) => {
  const dispatch = useDispatch()

  // Routing
  const { path } = useRouteMatch()

  // Routing
  const { venueId, salesAreaId, serviceId, menuId } = useParams<{
    venueId: string
    salesAreaId: string
    serviceId: string
    menuId: string
  }>()

  // Selectors
  const venueName = useTypedSelector(state =>
    venueSelectors.selectVenueName(state, venueId)
  )
  const salesAreaName = useTypedSelector(state =>
    venueSelectors.selectSalesAreaName(
      state,
      parseInt(venueId),
      parseInt(salesAreaId)
    )
  )
  const serviceName = useTypedSelector(state =>
    serviceSelectors.selectServiceName(state, serviceId)
  )
  const menuName = useTypedSelector(state =>
    menuSelectors.selectMenuName(state, menuId)
  )

  // Actions
  useEffect(() => {
    dispatch(menuActions.fetchMenu({ venueId, salesAreaId, serviceId, menuId }))
  }, [dispatch, venueId, serviceId, menuId, salesAreaId])

  // Analytics
  useEffect(() => {
    if (menuName && salesAreaName && serviceName && venueName) {
      trackSmartWaiterMenu({
        menuName,
        salesAreaName,
        serviceName,
        venueName
      })
    }
  }, [menuName, salesAreaName, serviceName, venueName])

  return (
    <Switch>
      <Route exact path={path}>
        <Layout
          pageTitle={`Select Menu Section | LW Theatres`}
          renderBrandLogo={<BrandLogoContainer />}
          renderSidebarTop={<BasketSummaryContainer hideOnMobile={true} />}
          renderSidebarBottom={
            <CheckoutButtonContainer
              ctaText='Continue to Basket'
              onCheckoutClick={onCheckoutClick}
            />
          }
          renderMain={<MenuSectionListContainer />}
        />
      </Route>
      <Route exact path={`${path}/:sectionId/product`}>
        <Layout
          pageTitle={`Select Menu Item | LW Theatres`}
          renderBrandLogo={<BrandLogoContainer />}
          renderSidebarTop={<BasketSummaryContainer hideOnMobile={true} />}
          renderSidebarBottom={
            <CheckoutButtonContainer
              ctaText='Continue to Basket'
              onCheckoutClick={onCheckoutClick}
            />
          }
          renderMain={<MenuContainer />}
        />
      </Route>
      <Route exact path={`${path}/:sectionId/product/:productId`}>
        <ProductView />
      </Route>
    </Switch>
  )
}

export { MenuView }
