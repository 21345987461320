import React from 'react'
import styled from 'styled-components/macro'
import { media, FormattedPrice, lighten, usePlatform } from 'utils'
import { Title, NumberSelector } from 'marvel-components'

interface Props {
  price: number
  name: string
  totalSelected: number
  maxQuantity: number
  onIncrement: () => void
  onDecrement: () => void
  isSoldOut?: boolean
  platform?: string
}

const TicketItemWrapper = styled.div<{
  isSoldOut: boolean
  platform?: string
}>`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 1.5rem 0;
  background: ${props =>
    props.isSoldOut
      ? lighten(props.theme.colors.borderColor, 15)
      : lighten(props.theme.colors.borderColor, 10)};
  border-radius: 0.125rem;
  margin: 0.75rem 0;
  align-items: center;

  ${media.narrowdesktop} {
    flex-direction: column;

    :nth-child(odd) {
      margin-right: 0;
    }

    :nth-child(even) {
      margin-left: 0;
    }
  }

  ${media.tablet} {
    padding: ${props => (props.platform === 'kiosk' ? '2.5rem 0' : '1.5rem 0')};
    flex-direction: ${props => (props.platform === 'kiosk' ? 'row' : 'column')};
  }

  ${media.phone} {
    padding: 1rem 0;
  }
`

const TicketTitleWrapper = styled.div<{ platform?: string }>`
  width: 63%;
  min-width: 63%;
  max-width: 63%;
  margin-left: 3%;
  margin-right: 2%;

  ${media.narrowdesktop} {
    width: 94%;
    min-width: 94%;
    max-width: 94%;
    margin: 0 3% 1.5rem 3%;
  }
  ${media.tablet} {
    width: ${props => (props.platform === 'kiosk' ? '63%' : '90%')};
    min-width: ${props => (props.platform === 'kiosk' ? '63%' : '90%')};
    max-width: ${props => (props.platform === 'kiosk' ? '63%' : '90%')};
    margin: ${props =>
      props.platform === 'kiosk' ? '0 2% 0 3%' : '0 5% 1.5rem 5%;'};
  }
  ${media.phone} {
    margin: 0 5% 1rem 5%;
  }
`

const TicketControlsWrapper = styled.div<{ platform?: string }>`
  width: 29%;
  min-width: 29%;
  max-width: 29%;
  margin-right: 3%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  ${media.narrowdesktop} {
    width: 94%;
    min-width: 94%;
    max-width: 94%;
    margin: 0 3%;
    justify-content: space-between;
  }
  ${media.tablet} {
    width: ${props => (props.platform === 'kiosk' ? '29%' : '90%')};
    min-width: ${props => (props.platform === 'kiosk' ? '29%' : '90%')};
    max-width: ${props => (props.platform === 'kiosk' ? '29%' : '90%')};
    margin: ${props => (props.platform === 'kiosk' ? '0 3% 0 0' : '0 5%;')};
    justify-content: ${props =>
      props.platform === 'kiosk' ? 'flex-end' : 'space-between'};
  }
`

const TicketTitle = styled(Title)<{ isSoldOut: boolean }>`
  margin-right: 0;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  opacity: ${props => (props.isSoldOut ? 0.5 : 1)};
  ${media.phone} {
    font-size: 0.875rem;
  }
`

const TicketPriceWrapper = styled.div<{ isSoldOut: boolean }>`
  font-size: 1.25rem;
  opacity: ${props => (props.isSoldOut ? 0.5 : 1)};
  text-decoration: ${props => (props.isSoldOut ? 'line-through' : 'none')};
  width: 30%;
  max-width: 30%;
  min-width: 30%;
  ${media.tablet} {
    font-size: 1.125rem;
  }
  ${media.phone} {
    width: 50%;
    min-width: 50%;
    max-width: 50%;
    font-size: 1rem;
  }
`

const SoldOutWrapper = styled.div`
  width: 60%;
  max-width: 60%;
  min-width: 60%;
  margin-left: 10%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: ${props => props.theme.font.header};
  font-size: 1.2rem;
  padding: 1rem 0;
  ${media.tablet} {
    justify-content: flex-end;
    align-items: flex-end;
  }
  ${media.phone} {
    width: 50%;
    min-width: 50%;
    max-width: 50%;
    margin-left: 0;
    font-size: 1rem;
  }
`

const TicketNumberWrapper = styled.div`
  width: 60%;
  max-width: 60%;
  min-width: 60%;
  margin-left: 10%;
  ${media.tablet} {
    margin-left: 0;
  }
  ${media.phone} {
    width: 50%;
    min-width: 50%;
    max-width: 50%;
  }
`

const TicketItem: React.FC<Props> = ({
  name,
  price,
  totalSelected,
  maxQuantity,
  onIncrement,
  onDecrement,
  isSoldOut = false
}) => {
  const handleIncrement = () => {
    onIncrement && onIncrement()
  }
  const handleDecrement = () => {
    onDecrement && onDecrement()
  }
  const platform = usePlatform()
  return (
    <TicketItemWrapper isSoldOut={isSoldOut} platform={platform}>
      <TicketTitleWrapper platform={platform}>
        <TicketTitle
          size={'large'}
          isPadded={false}
          isCapitalised={false}
          isCentralised={false}
          isBold={true}
          isSoldOut={isSoldOut}
        >
          {name}
        </TicketTitle>
      </TicketTitleWrapper>

      <TicketControlsWrapper platform={platform}>
        <TicketPriceWrapper isSoldOut={isSoldOut}>
          <FormattedPrice amount={price || 0} currency='GBP' />
        </TicketPriceWrapper>
        {isSoldOut ? (
          <SoldOutWrapper>Sold out</SoldOutWrapper>
        ) : (
          <TicketNumberWrapper>
            <NumberSelector
              value={totalSelected}
              numberSelectorSize={'large'}
              decreaseDisabled={totalSelected === 0}
              increaseDisabled={totalSelected === maxQuantity}
              onIncrementClick={handleIncrement}
              onDecrementClick={handleDecrement}
              isThemed={false}
            />
          </TicketNumberWrapper>
        )}
      </TicketControlsWrapper>
    </TicketItemWrapper>
  )
}

export { TicketItem }
