import React, { useRef, useEffect, useState, ReactNode } from 'react'
import styled from 'styled-components/macro'
// import { debounce } from 'lodash'

// Components
import { Zoom } from './Zoom'

// Misc
import panzoom, { PanZoom } from 'panzoom'

import { media, usePlatform } from 'utils'

// const PANZOOM_DEBOUNCE = 100

interface Props {
  children: ReactNode
  platform?: string
}

const SeatMapWrapper = styled.div<{ isVisible?: boolean }>`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0rem;
  width: 100%;
  opacity: ${props => (props.isVisible ? 1 : 0)};
  ${media.tablet} {
    bottom: 3rem;
  }
`

const SVGWrapper = styled.div<{ platform: string }>`
  width: 100%;
  height: 90%;
  position: relative;
  cursor: pointer;
  transition: opacity 0.4s;
  ${media.tablet} {
    height: 100%;
  }
`

const SVGMap = styled.svg`
  &:focus {
    outline: 0;
  }
`

const SeatMapDrawing: React.FC<Props> = ({ children }) => {
  const [zoom] = useState(0)
  const svgRef = useRef<SVGSVGElement>(null)
  const seatMapRef = useRef<SVGGElement>(null)
  const pz = useRef<PanZoom>()
  const platform = usePlatform()

  // Settings
  const minZoom = 0.1
  const maxZoom = platform === 'kiosk' ? 4 : 2
  const zoomSpeed = 1.75

  useEffect(() => {
    if (seatMapRef.current) {
      pz.current = panzoom(seatMapRef.current, {
        minZoom: minZoom,
        maxZoom: maxZoom,
        autocenter: true,
        bounds: true,
        boundsPadding: 0.02,
        zoomDoubleClickSpeed: zoomSpeed,
        onTouch: e => true
      })
    }
    return () => {
      if (pz.current) {
        pz.current.dispose()
      }
    }
  }, [minZoom, maxZoom])

  const handleZoom = (ratio: number) => {
    if (svgRef.current && pz.current) {
      const { width, height } = svgRef.current.getBoundingClientRect()
      pz.current.smoothZoom(width / 2, height / 2, ratio)
    }
  }
  const handleZoomIn = (e: React.MouseEvent) => {
    handleZoom(zoomSpeed)
  }
  const handleZoomOut = (e: React.MouseEvent) => {
    handleZoom(1 / zoomSpeed)
  }

  return (
    <SeatMapWrapper isVisible={true}>
      <SVGWrapper platform={platform}>
        <SVGMap
          ref={svgRef}
          // width='2000'
          // height='2000'
          height='100%'
          width='100%'
          preserveAspectRatio='xMinYMin meet'
          viewBox='0 0 2000 2000'
        >
          <g ref={seatMapRef} transform='scale(0.5)'>
            <g ref={seatMapRef}>
              {/* <rect x='0' y='0' width='100%' height='100%' fill='red' /> */}
              {children}
            </g>
          </g>
        </SVGMap>
      </SVGWrapper>
      <Zoom
        text='Zoom'
        onZoomIn={handleZoomIn}
        isZoomInDisabled={zoom === maxZoom}
        onZoomOut={handleZoomOut} // = 1 / 1.75
        isZoomOutDisabled={zoom === minZoom}
      />
    </SeatMapWrapper>
  )
}

export { SeatMapDrawing }
