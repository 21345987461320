import { createEntityAdapter, createSlice } from '@reduxjs/toolkit'
import { MainMenuNavigationItem, ScreenSaverSlide } from 'shared-types'
import { fetchNavigationItems, fetchScreenSaverSlides } from './actions'

export const navigationItemAdapter = createEntityAdapter<
  MainMenuNavigationItem
>()

export const screenSaverSlideAdapter = createEntityAdapter<ScreenSaverSlide>()

const navigationItemsInitialState = navigationItemAdapter.getInitialState()
const screenSaverSlidesInitialState = screenSaverSlideAdapter.getInitialState()

type AdditionalState = {
  navigationItems: typeof navigationItemsInitialState
  screenSaverSlides: typeof screenSaverSlidesInitialState
  status: 'idle' | 'pending' | 'fulfilled' | 'rejected'
}

const initialState: AdditionalState = {
  navigationItems: navigationItemsInitialState,
  screenSaverSlides: screenSaverSlidesInitialState,
  status: 'idle'
}

const contentSlice = createSlice({
  name: 'mainMenu',
  initialState: initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(fetchNavigationItems.pending, state => {
        state.status = 'pending'
        navigationItemAdapter.removeAll(state.navigationItems)
      })
      .addCase(fetchNavigationItems.fulfilled, (state, action) => {
        state.status = 'fulfilled'
        action.payload.navigationItems &&
          navigationItemAdapter.setAll(
            state.navigationItems,
            action.payload.navigationItems
          )
      })
      .addCase(fetchNavigationItems.rejected, state => {
        state.status = 'rejected'
      })
      .addCase(fetchScreenSaverSlides.pending, state => {
        state.status = 'pending'
        screenSaverSlideAdapter.removeAll(state.screenSaverSlides)
      })
      .addCase(fetchScreenSaverSlides.fulfilled, (state, action) => {
        state.status = 'fulfilled'
        action.payload.screenSaverSlides &&
          screenSaverSlideAdapter.setAll(
            state.screenSaverSlides,
            action.payload.screenSaverSlides
          )
      })
      .addCase(fetchScreenSaverSlides.rejected, state => {
        state.status = 'rejected'
      })
  }
})

export const contentActions = {
  fetchNavigationItems,
  fetchScreenSaverSlides,
  ...contentSlice.actions
}

export default contentSlice.reducer
