// Components
import { Button, CopyBlock, Title } from 'marvel-components'

// Actions
import { closeModal } from 'modules/overlay/actions'

// Misc
import React from 'react'
import styled from 'styled-components'
import { useAppDispatch } from 'app/reduxStore'
import { useHistory } from 'react-router-dom'
import { media } from 'utils'
import { venueHome } from 'utils/routes'

const AlternativeVenueWrapper = styled.div`
  padding: 2rem;
  max-width: 23.75rem;

  ${media.tablet} {
    width: 70vw;
    max-width: 70vw;
  }
`

const TitleWrapper = styled.span`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`

const StyledButton = styled(Button)`
  margin-top: 1rem;
`

const StyledLinkButton = styled(StyledButton)`
  justify-content: center;
`

const CopyWrapper = styled.div`
  padding: 1rem 0;
`

export type AlternativeVenueProps = {
  venueId: string
}

const AlternativeVenueOverlayContainer: React.FC<AlternativeVenueProps> = ({
  venueId
}) => {
  const dispatch = useAppDispatch()
  const history = useHistory()

  const handleCancel = () => {
    dispatch(closeModal())
  }

  const handleRedirect = () => {
    dispatch(closeModal())
    history.push(venueHome(venueId))
  }

  return (
    <AlternativeVenueWrapper>
      <TitleWrapper>
        <Title size='large' isBold={true}>
          You’re heading to a different venue
        </Title>
      </TitleWrapper>
      <CopyWrapper>
        <CopyBlock isCentralised={true}>
          We’re about to show you what’s happening at a different venue to the
          venue you’re in right now
        </CopyBlock>
      </CopyWrapper>
      <StyledButton
        text='Got it, take me there'
        onClick={handleRedirect}
        isBlock={true}
      />
      <StyledLinkButton
        text='Cancel'
        onClick={handleCancel}
        isBlock={true}
        buttonType='link-button'
        size='small'
      />
    </AlternativeVenueWrapper>
  )
}

export { AlternativeVenueOverlayContainer }
