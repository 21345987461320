// Misc
import styled from 'styled-components/macro'
import { media, FormattedPrice, lighten } from 'utils'
import QRCode from 'qrcode.react'

// Components
import { Title, CopyBlock, Icon } from 'marvel-components'

// Types
import { BarcodeStatus } from 'shared-types'

interface Props {
  showName: string
  date: string
  seat?: string
  price: number
  barcode: {
    status: BarcodeStatus
    code?: string
    daysUntilAvailable?: number
  }
}

const Card = styled.div`
  background: ${props => props.theme.colors.secondaryBackgroundColor};
  border: 1px solid ${props => lighten(props.theme.colors.borderColor, 10)};
  box-sizing: border-box;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.09);
  border-radius: 4px;
  padding: 1.5rem;
  display: flex;
  justify-content: space-between;
  margin-bottom: 1.5rem;

  ${media.phone} {
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 1.5rem 1rem 1.75rem 1rem;
  }
`

const BarCodeContainer = styled.div<{ status: BarcodeStatus }>`
  height: 170px;
  width: 170px;
  border-radius: 6px;
  background: #e8e8e8;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  ${props =>
    props.status === 'unavailable'
      ? `border: 3px dashed ${props.theme.colors.borderColor}; padding: 1.5rem;`
      : ''}
`

const UnavailableText = styled.span`
  color: ${props => lighten(props.theme.colors.copyColor, 20)};
  text-align: center;
  font-family: ${props => props.theme.font.copy};
  font-size: 0.875rem;
  line-height: 1.5;
`

const CheckedIn = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
`

const IconWrapper = styled.div`
  background: ${props => props.theme.colors.activeColor};
  border-radius: 50%;
  fill: ${props => props.theme.colors.activeColorComplement};
  width: 3rem;
  height: 3rem;
  line-height: 1.6rem;
  font-size: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 1rem;
`

const DetailsContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: flex-start;
  ${media.phone} {
    align-items: center;
  }
`

const SeatAndPrice = styled.div`
  display: flex;
  margin: 0.5rem 0 1rem;
  ${media.phone} {
  }
`

const Divider = styled.div`
  color: ${props => props.theme.colors.copyColor};
  padding: 0 1.2rem;
  font-size: 0.875rem;
  line-height: 1.5;
`

const TicketCard = ({ showName, price, date, seat, barcode }: Props) => {
  return (
    <>
      <Card>
        <DetailsContainer>
          <Title isCapitalised={false} size='medium' isBold>
            {showName}
          </Title>
          <CopyBlock>{date}</CopyBlock>
          <SeatAndPrice>
            {seat && (
              <>
                <CopyBlock>{seat}</CopyBlock>
                <Divider>|</Divider>
              </>
            )}
            <CopyBlock>
              <FormattedPrice amount={price || 0} currency='GBP' />
            </CopyBlock>
          </SeatAndPrice>
        </DetailsContainer>
        <BarCodeContainer status={barcode.status}>
          {barcode.status === 'unavailable' && barcode.daysUntilAvailable ? (
            <UnavailableText>
              Ticket barcode will appear here in {barcode.daysUntilAvailable}{' '}
              days
            </UnavailableText>
          ) : barcode.status === 'Scanned' ? (
            <CheckedIn>
              <IconWrapper>
                <Icon icon={'check'} />
              </IconWrapper>
              <CopyBlock>Checked In</CopyBlock>
            </CheckedIn>
          ) : barcode.code ? (
            <QRCode size={170} value={barcode.code} />
          ) : (
            <UnavailableText>
              Ticket barcode will appear here in 24 hours before your event
            </UnavailableText>
          )}
        </BarCodeContainer>
      </Card>
    </>
  )
}

export { TicketCard }
