// Selectors
import { productSelectors } from 'modules/smart-waiter/product'
import { menuSelectors } from 'modules/smart-waiter/menu'

// Misc
import { createSelector } from '@reduxjs/toolkit'
import { parseZonalMenu } from 'utils'

// Types
import { AppState } from 'modules/types'
import {
  SWLineItem,
  SWProduct,
  Transaction,
  TransactionItem,
  ZProduct
} from 'shared-types'

// Current Selected Item
export const selectCurrentItem = (state: AppState) =>
  state.smartWaiter.basket.currentItem

export const selectSelectedPortionId = (state: AppState, id: EntityId) =>
  state.smartWaiter.basket.currentItem
    ? state.smartWaiter.basket.currentItem.portionId
    : undefined

export const selectQuantity = (state: AppState, id: EntityId) =>
  state.smartWaiter.basket.currentItem
    ? state.smartWaiter.basket.currentItem.quantity
    : 0

export const selectSelectedChoices = (state: AppState) =>
  state.smartWaiter.basket.choicesById

export const selectSelectedChoiceIds = (state: AppState, id: EntityId) =>
  state.smartWaiter.basket.choicesById[id]
    ? state.smartWaiter.basket.choicesById[id].map(choice => choice.id)
    : []

export const selectPortionForProduct = (productId: EntityId) =>
  createSelector(
    [
      (state: AppState) => productSelectors.selectById(state, productId),
      (state: AppState) => selectSelectedPortionId(state, productId)
    ],
    (product, selectedPortionId) => {
      if (product) {
        return product.portions.find(
          portion => portion.id === selectedPortionId
        )
      }
    }
  )

export const selectSelectedProductsForChoiceId = (productId: number) =>
  createSelector(
    [
      (state: AppState) => selectSelectedChoiceIds(state, productId),
      (state: AppState) => productSelectors.selectAll(state)
    ],
    (choiceIds, products) =>
      products.filter(product => choiceIds.includes(product.id))
  )

export const selectCurrentItemTotal = createSelector(
  [selectCurrentItem, selectSelectedChoices, productSelectors.selectEntities],
  (selectedItem, selectedChoices, productEntities) => {
    const sumChoices = (choiceId: number) =>
      (selectedChoices[choiceId] || []).reduce((choiceTotal, item) => {
        const product = productEntities[item.id]
        if (product) {
          const portion = product.portions.find(
            portion => portion.id === item.portionId
          )
          if (portion) {
            choiceTotal += (portion.choices || []).reduce(
              (total, choiceId) => (total += sumChoices(choiceId)),
              portion.price
            )
          }
        }
        return choiceTotal
      }, 0)

    if (selectedItem) {
      const product = productEntities[selectedItem.id]
      if (product) {
        const portion = product.portions.find(
          portion => portion.id === selectedItem.portionId
        )
        if (portion) {
          const choiceTotal = portion.choices.reduce(
            (total, choiceId) => (total += sumChoices(choiceId)),
            portion.price
          )
          return choiceTotal * selectedItem.quantity
        }
      }
    }
    return 0
  }
)

export const selectCurrentItemForBasket = createSelector(
  [selectCurrentItem, selectSelectedChoices, productSelectors.selectEntities],
  (selectedItem, selectedChoices, productEntities) => {
    const getChoices = (choiceId: number): SWProduct[] =>
      (selectedChoices[choiceId] || []).map(item => {
        const product = productEntities[item.id]
        if (product) {
          const portion = product.portions.find(
            portion => portion.id === item.portionId
          )
          if (portion) {
            return {
              ...item,
              price: portion.price,
              choices: portion.choices.reduce((choices, id) => {
                return [...getChoices(id), ...choices]
              }, [] as SWProduct[])
            }
          }
        }
        return item
      })

    if (selectedItem) {
      const product = productEntities[selectedItem.id]
      if (product) {
        const portion = product.portions.find(
          portion => portion.id === selectedItem.portionId
        )
        if (portion) {
          return {
            ...selectedItem,
            price: portion.price,
            choices: portion.choices.reduce((choices, choiceId) => {
              return [...getChoices(choiceId), ...choices]
            }, [] as SWProduct[])
          }
        }
      }
    }
  }
)

// Basket
export const selectIsBasketEmpty = (state: AppState) =>
  Object.values(state.smartWaiter.basket.itemsByMenuId).reduce(
    (t, items) => t + items.length,
    0
  ) === 0

export const selectItemsInBasket = (state: AppState) =>
  Object.values(state.smartWaiter.basket.itemsByMenuId).reduce(
    (t, items) => [...t, ...items],
    []
  )

export const selectBasket = (state: AppState) =>
  state.smartWaiter.basket.itemsByMenuId

export const selectMenuIdsInBasket = (state: AppState) =>
  Object.keys(state.smartWaiter.basket.itemsByMenuId).map(id => parseInt(id))

export const selectMenusInBasket = createSelector(
  [selectMenuIdsInBasket, menuSelectors.selectAll],
  (menuIds, menus) => menus.filter(menu => menuIds.includes(menu.id))
)

export const selectItemsForReservation = createSelector(
  [selectBasket, menuSelectors.selectEntities],
  (basket, menus) => {
    const parseItem = (item: SWProduct): SWProduct => {
      return {
        id: item.id,
        eposId: item.eposId,
        portionId: item.portionId,
        quantity: item.quantity,
        choices: item.choices
          ? item.choices.map(choice => parseItem(choice))
          : []
      }
    }
    return Object.keys(basket).map(menuId => {
      return {
        serviceId: 1,
        menuName: menus[menuId]?.name ?? 'No Menu',
        items: basket[menuId].map(item => parseItem(item))
      }
    })
  }
)

export const selectBasketTotal = createSelector(
  [selectItemsInBasket],
  lineItems => {
    const sumChoices = (lineItems: SWProduct[] | SWLineItem[]): number => {
      return (lineItems as SWProduct[]).reduce(
        (total, item) =>
          total +
          ((item.price || 0) + (item.choices ? sumChoices(item.choices) : 0)) *
            item.quantity,
        0
      )
    }
    return sumChoices(lineItems)
  }
)

export const selectBasketSummary = createSelector(
  [
    selectBasket,
    menuSelectors.selectEntities,
    productSelectors.selectEntities,
    selectBasketTotal
  ],
  (basket, menus, products, total) => {
    const parseItem = (
      item: SWProduct,
      parentQuantity: number
    ): TransactionItem => {
      const product = products[item.id] as ZProduct
      const portion = product.portions.find(
        portion => item.portionId === portion.id
      )
      return {
        id: item.id,
        title: product.name,
        subtitle: portion ? portion.name : '',
        price:
          (portion?.price ?? item.price ?? 0) * item.quantity * parentQuantity,
        quantity: item.quantity * parentQuantity,
        choices: (item.choices || []).map(choice =>
          parseItem(choice, item.quantity * parentQuantity)
        )
      }
    }

    return Object.keys(basket)
      .sort((i, x) => parseInt(x) - parseInt(i))
      .reduce(
        (summary, menuId) => {
          const menu = menus[menuId]
          if (menu) {
            const group = {
              id: menu.id,
              name: parseZonalMenu(menu.name).title,
              items: basket[menuId].reduce((items, item) => {
                const product = products[item.id]
                if (product) {
                  const portion = product.portions.find(
                    portion => item.portionId === portion.id
                  )
                  items.push({
                    id: item.uid,
                    title: product.name,
                    subtitle: portion ? portion.name : '',
                    price: (portion?.price ?? item.price ?? 0) * item.quantity,
                    quantity: item.quantity,
                    choices: (item.choices || []).map(choice =>
                      parseItem(choice, item.quantity)
                    )
                  })
                }
                return items
              }, [] as TransactionItem[])
            }
            return {
              ...summary,
              groups: [...summary.groups, group]
            }
          }
          return summary
        },
        { groups: [], total: total, subtotals: [] } as Transaction
      )
  }
)
