import { combineReducers } from 'redux'
import * as actions from './actions'
import { createReservation } from './actions'
import { Action, PayloadAction } from '@reduxjs/toolkit'

import {
  ACCEPT_SEATS,
  ADD_PRODUCT,
  ADD_SEAT,
  ADD_TICKET,
  CLEAR_BEST_AVAILABLE,
  CLEAR_PAYMENT_DETAILS,
  CLEAR_PRODUCTS,
  CLEAR_RESERVATION,
  DECREMENT_BEST_AVAILABLE,
  ExternalCustomerIdAction,
  INCREMENT_BEST_AVAILABLE,
  REMOVE_PRODUCT,
  REMOVE_PRODUCT_LINE_ITEM,
  REMOVE_SEAT,
  REMOVE_TICKET,
  REMOVE_TICKET_LINE_ITEM,
  SET_EXTERNAL_CUSTOMER_ID,
  TRIGGER_RESERVATION
} from './types'

import { RoutineAction } from 'modules/shared'
import { BaseAction } from 'redux-actions'
import {
  BaseTicket,
  Order,
  Ticket,
  TicketWithSeat,
  BookingFlow
} from 'shared-types'
import * as store from 'store'

import { authActions } from 'modules/ticketing/auth/authSlice'
import { fetchDeliveryOptions } from 'modules/ticketing/delivery-option/actions'

// External Session
// ----------------------------------------------
const externalCustomerId = (
  state: string | null = null,
  action: ExternalCustomerIdAction
) => {
  switch (action.type) {
    case SET_EXTERNAL_CUSTOMER_ID:
      if (action.payload) {
        store.set('externalCustomerId', action.payload)
        return action.payload
      } else {
        return state
      }
    default:
      return state
  }
}

// ContextID
// ----------------------------------------------
const contextId = (state = null, action: any) => {
  if (
    action.payload &&
    action.payload.response &&
    action.payload.response.pagination &&
    action.payload.response.pagination.contextId
  ) {
    return action.payload.response.pagination.contextId
  }

  if (
    action.payload &&
    action.payload.pagination &&
    action.payload.pagination.contextId
  ) {
    return action.payload.pagination.contextId
  }

  switch (action.type) {
    case authActions.logout.type:
      return null
    default:
      return state
  }
}

// Booking Flow - New Booking vs Upsell
const bookingFlow = (state: BookingFlow = 'New Purchase', action: Action) => {
  if (actions.setBookingFlow.match(action)) {
    return action.payload
  }
  return state
}

// Selected Seats - user selected tickets
// ----------------------------------------------

type GenericTicket = BaseTicket & Partial<TicketWithSeat>
export const compareTickets = (t: GenericTicket, t1: GenericTicket) =>
  t.performanceId === t1.performanceId &&
  t.areaId === t1.areaId &&
  t.priceLevelId === t1.priceLevelId &&
  t.priceTypeId === t1.priceTypeId &&
  t?.id === t1?.id

const incrementTicket = (ticket: BaseTicket, tickets: Ticket[]) => {
  const existingItem = tickets.find(t => compareTickets(t, ticket))

  const newTickets = tickets.filter(t => !compareTickets(t, ticket))

  const newItem = existingItem
    ? { ...existingItem, quantity: existingItem.quantity + 1 }
    : { ...ticket, quantity: 1 }
  return [...newTickets, newItem]
}

const decrementTicket = (ticket: BaseTicket, tickets: Ticket[]) => {
  const existingItem = tickets.find(t => compareTickets(t, ticket))
  const newTickets = tickets.filter(t => !compareTickets(t, ticket))

  return [
    ...newTickets,
    ...(existingItem && existingItem.quantity > 1
      ? [{ ...existingItem, quantity: existingItem.quantity - 1 }]
      : [])
  ]
}

const removeTicketLineItem = (ticket: BaseTicket, tickets: Ticket[]) =>
  tickets.filter(t => !compareTickets(t, ticket))

type CreateAction = ReturnType<typeof createReservation.success>

const isRoutine = <T extends Action>(
  action: Action<unknown>,
  type: string
): action is T => action.type === type

const selectedSeats = (
  state: TicketWithSeat[] = [],
  action: Action | CreateAction
) => {
  switch (action.type) {
    case ADD_SEAT:
      if (actions.addSeat.match(action)) {
        return [...state, action.payload]
      }
      return state
    case REMOVE_SEAT:
      if (actions.removeSeat.match(action)) {
        const ticket = action.payload || state[state.length - 1]
        return state.filter(t => !compareTickets(t, ticket))
      }
      return state
    case CLEAR_RESERVATION:
      return []
    case actions.createReservation.SUCCESS:
      if (isRoutine<CreateAction>(action, actions.createReservation.SUCCESS)) {
        const tickets: TicketWithSeat[] = action.payload.response.data.basket.ticketReservations
          .filter(
            (ticket: GenericTicket) => ticket?.seatingType === 'ReservedSeating'
          )
          .map((ticket: TicketWithSeat) => ({
            areaId: ticket.areaId,
            performanceId: ticket.performanceId,
            priceTypeId: ticket.priceTypeId,
            priceLevelId: ticket.priceLevelId,
            id: ticket.id
          }))
        return tickets
      }
      return state
    default:
      return state
  }
}

const selectedTickets = (
  state: Ticket[] = [],
  action: Action | CreateAction
) => {
  switch (action.type) {
    case ADD_TICKET:
      if (actions.addTicket.match(action)) {
        return incrementTicket(action.payload, state)
      }
      return state
    case REMOVE_TICKET:
      if (actions.removeTicket.match(action)) {
        return decrementTicket(action.payload, state)
      }
      return state
    case REMOVE_TICKET_LINE_ITEM:
      if (actions.removeTicketLineItem.match(action)) {
        return removeTicketLineItem(action.payload, state)
      }
      return state
    case CLEAR_RESERVATION:
      return []
    case actions.createReservation.SUCCESS:
      if (isRoutine<CreateAction>(action, actions.createReservation.SUCCESS)) {
        const tickets: Ticket[] = action.payload.response.data.basket.ticketReservations.filter(
          (ticket: GenericTicket) => ticket?.seatingType === 'GeneralAdmission'
        )
        return tickets.reduce(
          (tickets, ticket) =>
            incrementTicket(
              {
                areaId: ticket.areaId,
                performanceId: ticket.performanceId,
                priceTypeId: ticket.priceTypeId,
                priceLevelId: ticket.priceLevelId
              },
              tickets
            ),
          [] as Ticket[]
        )
      }
      return state
    default:
      return state
  }
}

// Best Available - recommended tickets
// ----------------------------------------------
const bestAvailable = (state: number = 0, action: BaseAction) => {
  switch (action.type) {
    case INCREMENT_BEST_AVAILABLE:
      return state + 1
    case DECREMENT_BEST_AVAILABLE:
      return Math.max(0, state - 1)

    case CLEAR_BEST_AVAILABLE:
    case CLEAR_RESERVATION:
    case ADD_SEAT:
    case REMOVE_SEAT:
    case ACCEPT_SEATS:
      return 0

    default:
      return state
  }
}

// Selected Products
// ----------------------------------------------
const selectedProducts = (
  state: { [key: string]: number } = {},
  action: PayloadAction<string> | CreateAction
) => {
  const itemId = action.payload
  switch (action.type) {
    case ADD_PRODUCT:
      return {
        ...state,
        [itemId]: (state[itemId] || 0) + 1
      }
    case REMOVE_PRODUCT:
      const qty = (state[itemId] || 0) - 1
      const copy = { ...state }
      if (qty > 0) copy[itemId] = qty
      else delete copy[itemId]
      return copy
    case REMOVE_PRODUCT_LINE_ITEM:
      const { [itemId]: omit, ...newState } = state
      return newState

    case CLEAR_PRODUCTS:
    case CLEAR_RESERVATION:
      return {}
    default:
      return state
  }
}

// Transaction
// ----------------------------------------------
const currentTransaction = (
  state: Order | null = null,
  action: RoutineAction
) => {
  switch (action.type) {
    case actions.createReservation.SUCCESS:
    case actions.updateReservation.SUCCESS:
    case actions.fetchReservation.SUCCESS:
    case actions.addGiftVoucherToOrder.SUCCESS:
      return action.payload.response.data as Order
    case CLEAR_RESERVATION:
      return null

    default:
      return state
  }
}

// Transaction State
const transactionState = (
  state: null | Order['state'] = null,
  action: RoutineAction
) => {
  switch (action.type) {
    case actions.createReservation.SUCCESS:
    case actions.updateReservation.SUCCESS:
    case actions.fetchReservation.SUCCESS:
    case actions.createOrder.SUCCESS:
    case actions.fetchOrder.SUCCESS:
    case actions.addGiftVoucherToOrder.SUCCESS:
    case actions.updatePaymentDetails.SUCCESS:
      const order = action.payload.response.data as Order
      return order.state
    default:
      return state
  }
}

// Transaction State
const transactionId = (state: null | string = null, action: RoutineAction) => {
  switch (action.type) {
    case actions.createReservation.SUCCESS:
    case actions.updateReservation.SUCCESS:
    case actions.fetchReservation.SUCCESS:
      const order = action.payload.response.data as Order
      return order.transactionGuid
    case CLEAR_RESERVATION: // The extra methods are needed to in case the reservation id is no longer valid
    case authActions.logout.type:
    case actions.createReservation.FAILURE:
    case actions.updateReservation.FAILURE:
    case actions.fetchReservation.FAILURE:
    case fetchDeliveryOptions.rejected.type:
      return null
    default:
      return state
  }
}

// Transaction State
const expiresAt = (state: null | string = null, action: RoutineAction) => {
  switch (action.type) {
    case actions.createReservation.SUCCESS:
      const order = action.payload.response.data as Order
      return state ?? order.expiresAt
    case CLEAR_RESERVATION:
      return null
    default:
      return state
  }
}

// Order
// ----------------------------------------------
const order = (state: Order | null = null, action: RoutineAction) => {
  switch (action.type) {
    case actions.fetchOrderFromToken.SUCCESS:
    case actions.fetchOrder.SUCCESS:
    case actions.createOrder.SUCCESS:
    case actions.updatePaymentDetails.SUCCESS:
      return action.payload.response.data as Order
    case actions.createReservation.SUCCESS:
      return null
    default:
      return state
  }
}

// Status
// ----------------------------------------------

const loading = (state: string = 'idle', action: BaseAction) => {
  switch (action.type) {
    case CLEAR_RESERVATION:
      return 'idle'
    case INCREMENT_BEST_AVAILABLE:
    case DECREMENT_BEST_AVAILABLE:
      return 'pending'
    case TRIGGER_RESERVATION:
    case actions.createReservation.TRIGGER:
    case actions.updateReservation.TRIGGER:
    case actions.fetchReservation.TRIGGER:
    case actions.createOrderToken.TRIGGER:
      return 'loading'
    case ADD_SEAT:
    case ADD_PRODUCT:
    case ADD_TICKET:
    case REMOVE_SEAT:
    case REMOVE_PRODUCT:
    case REMOVE_TICKET:
      return 'pending'
    case actions.createReservation.FULFILL:
    case actions.updateReservation.FULFILL:
    case actions.fetchReservation.FULFILL:
    case actions.createOrderToken.FULFILL:
      return 'loaded'
    default:
      return state
  }
}

// Gift Voucher Loading
// ----------------------------------------------
const giftVoucherLoading = (state: string = 'idle', action: RoutineAction) => {
  switch (action.type) {
    case CLEAR_RESERVATION:
    case actions.addGiftVoucherToOrder.SUCCESS:
    case actions.addGiftVoucherToOrder.FULFILL:
      return 'idle'
    case actions.addGiftVoucherToOrder.TRIGGER:
      return 'loading'
    default:
      return state
  }
}

// Checkout Details
// ----------------------------------------------

const paymentAction = (state: any = null, action: RoutineAction) => {
  switch (action.type) {
    case actions.createOrder.SUCCESS:
    case actions.updatePaymentDetails.SUCCESS:
      return action.payload.response.data.action ?? null

    case CLEAR_RESERVATION:
    case CLEAR_PAYMENT_DETAILS:
    case actions.updatePaymentDetails.FAILURE:
      return null

    default:
      return state
  }
}

// Order
// ----------------------------------------------
const isOrdering = (state: boolean = false, action: BaseAction) => {
  switch (action.type) {
    case actions.createOrder.TRIGGER:
    case actions.updatePaymentDetails.TRIGGER:
      return true
    case actions.createOrder.FULFILL:
    case actions.updatePaymentDetails.FULFILL:
      return false

    default:
      return state
  }
}

// Order Token
// ----------------------------------------------
const orderToken = (state: string | null = null, action: RoutineAction) => {
  switch (action.type) {
    case actions.createOrderToken.SUCCESS:
      return action.payload.response.data.token as string
    case actions.fetchOrder.FAILURE:
      return null
    default:
      return state
  }
}

const orderStatus = (
  state: 'idle' | 'loading' | 'success' | 'failure' = 'idle',
  action: RoutineAction
) => {
  switch (action.type) {
    case actions.fetchOrderFromToken.REQUEST:
      return 'loading'
    case actions.fetchOrderFromToken.SUCCESS:
      return 'success'
    case actions.fetchOrderFromToken.FAILURE:
      return 'failure'
    default:
      return state
  }
}

export default combineReducers({
  contextId,
  expiresAt,
  bookingFlow,
  transactionId,
  selectedSeats,
  selectedTickets,
  bestAvailable,
  selectedProducts,
  currentTransaction,
  order,
  orderToken,
  orderStatus,
  paymentAction,
  loading,
  giftVoucherLoading,
  isOrdering,
  externalCustomerId,
  transactionState
})
