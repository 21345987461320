import styled from 'styled-components'

const TileWrapper = styled.div<{
  size?: number
  borderColor?: string
  backgroundUrl?: string
}>`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: ${props => props.size ?? 256}px;
  height: ${props => props.size ?? 256}px;
  border-radius: 14px;
  border-width: 3px;
  border-style: solid;
  border-color: ${props => props.borderColor ?? '#000000'};
  background-color: #ffffff;
  background-image: url("${props => props.backgroundUrl}");
  background-repeat: repeat;
  box-shadow: 0px 6px 8px rgba(0, 0, 0, 0.11);
  font-family: ${props => props.theme.font.copy};
  text-transform: uppercase;
  text-align: center;
  cursor: pointer;
`

const TileIcon = styled.img`
  width: 5rem;
`

const TileTitle = styled.p<{ fontSize: number }>`
  font-family: ${props => props.theme.font.header};
  padding: 0 2.5rem;
  margin: 1.5rem 0 0 0;
  letter-spacing: 0.1rem;
  line-height: 1.2;
  font-size: ${props => props.fontSize}px;
`

type Props = {
  size?: number
  label?: string
  value?: string
  onClick?: (value?: string) => void
  backgroundUrl?: string
  iconUrl?: string
  borderColor?: string
}

const DEFAULT_TILE_SIZE = 256
const DEFAULT_FONT_SIZE = 18

const Tile: React.FC<Props> = ({
  size = 230,
  label,
  value,
  onClick,
  iconUrl,
  backgroundUrl,
  borderColor,
  ...props
}) => {
  const fontSize = (size * DEFAULT_FONT_SIZE) / DEFAULT_TILE_SIZE

  const handleClick = (e: React.MouseEvent) => {
    e.preventDefault()
    onClick && onClick(value)
  }

  return (
    <TileWrapper
      size={size}
      borderColor={borderColor}
      backgroundUrl={backgroundUrl}
      onClick={handleClick}
      {...props}
    >
      {iconUrl && <TileIcon src={iconUrl} />}
      {label && <TileTitle fontSize={fontSize}>{label}</TileTitle>}
    </TileWrapper>
  )
}

export { Tile }
