import { createSlice, createEntityAdapter } from '@reduxjs/toolkit'

// Utils
import hospitalityApi from 'services/hospitalityApi'
import { createAPIThunk } from 'modules/smart-waiter/shared/actions'
import { occasionsEntity } from 'services/hospitalitySchemas'

// Types
import { Occasion } from 'shared-types'

type OccasionsResponse = {
  occasions?: { [key: string]: Occasion }
}

// Actions
export const fetchOccasionsForSite = createAPIThunk<OccasionsResponse, string>(
  'marvel/hospitality/FETCH_OCCASIONS',
  (siteId: string) => hospitalityApi.fetchAll(`/site/${siteId}/occasions/`),
  occasionsEntity
)

// Reducer

export const occasionsAdapter = createEntityAdapter<Occasion>({
  sortComparer: (a, b) => a.name.localeCompare(b.name)
})

const occasionsSlice = createSlice({
  name: 'occasions',
  initialState: occasionsAdapter.getInitialState({
    loading: 'idle'
  }),
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchOccasionsForSite.pending, (state, action) => {
      state.loading = 'pending'
    })
    builder.addCase(fetchOccasionsForSite.fulfilled, (state, action) => {
      if (action.payload.occasions) {
        occasionsAdapter.setAll(state, action.payload.occasions)
      }
      state.loading = 'idle'
    })
  }
})

export default occasionsSlice.reducer
