// Misc
import { createSlice } from '@reduxjs/toolkit'

// Actions
import { fetchEventDetails } from 'modules/ticketing/event/actions'

// Selectors
import {
  eventDetailsAdapter,
  selectEventDetails,
  selectEventDetailsId,
  selectEventName,
  selectEventImageUrl,
  selectIsSmartWaiterEnabled,
  selectLogoBackground,
  selectLogoUrl,
  selectSocialMedia,
  selectSocialMediaUrl,
  selectTwitterHashTags,
  selectTwitterTitle,
  selectVenue,
  selectVenueAddress,
  selectVenueName,
  selectIsLoaded,
  selectSwVenueId
} from './selectors'

type Status = 'idle' | 'pending' | 'fulfilled' | 'rejected'

type State = {
  status: Status
  currentId?: string
}

const initialState: State = {
  status: 'idle'
}

const eventDetailsSlice = createSlice({
  name: 'event-details',
  initialState: eventDetailsAdapter.getInitialState<State>(initialState),
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(fetchEventDetails.pending, state => {
        state.status = 'pending'
      })
      .addCase(fetchEventDetails.fulfilled, (state, action) => {
        eventDetailsAdapter.upsertMany(state, action.payload.data.eventDetails)
        if (action.payload.data.eventDetails) {
          state.currentId = Object.keys(action.payload.data.eventDetails)[0]
        }
        state.status = 'fulfilled'
      })
      .addCase(fetchEventDetails.rejected, state => {
        state.status = 'rejected'
      })
  }
})

export const eventDetailsActions = {
  fetchEventDetails,
  ...eventDetailsSlice.actions
}

export const eventDetailsSelectors = {
  selectEventDetails,
  selectIsLoaded,
  selectEventDetailsId,
  selectEventName,
  selectEventImageUrl,
  selectIsSmartWaiterEnabled,
  selectLogoBackground,
  selectLogoUrl,
  selectSocialMedia,
  selectSocialMediaUrl,
  selectTwitterHashTags,
  selectTwitterTitle,
  selectVenue,
  selectVenueAddress,
  selectVenueName,
  selectSwVenueId
}

export default eventDetailsSlice.reducer
