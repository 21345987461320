import { combineReducers } from 'redux'
import * as actions from './actions'
import { RoutineAction } from 'modules/shared'
import { createReservation } from 'modules/basket/actions'

const byId = (state: string[] = [], action: RoutineAction) => {
  switch (action.type) {
    case actions.fetchPrompts.TRIGGER:
    case actions.fetchPrompts.FAILURE:
    case actions.fetchPromptsPostOrder.TRIGGER:
    case actions.fetchPromptsPostOrder.FAILURE:
      return []
    case actions.fetchPrompts.SUCCESS:
    case actions.fetchPromptsPostOrder.SUCCESS:
      return action.payload.response.result.productPrompts as string[]
    default:
      return state
  }
}

const loading = (state = 'idle', action: RoutineAction) => {
  switch (action.type) {
    case createReservation.SUCCESS:
    case actions.fetchPrompts.TRIGGER:
    case actions.fetchPromptsPostOrder.TRIGGER:
      return 'pending'
    case actions.fetchPrompts.FULFILL:
    case actions.fetchPromptsPostOrder.FULFILL:
      return 'idle'
    default:
      return state
  }
}

const messages = (state = [], action: RoutineAction) => {
  switch (action.type) {
    case actions.fetchPrompts.TRIGGER:
    case actions.fetchPrompts.FAILURE:
      return []
    case actions.fetchPrompts.SUCCESS:
      return action.payload.response.result.messagePrompts
    default:
      return state
  }
}

export default combineReducers({
  byId,
  loading,
  messages
})
