// Components
import { CopyBlock, WithLoader } from 'marvel-components'

// Containers
import {
  FooterNavContainer,
  ManageAuthenticationContainer
} from 'containers/Ticketing'

// misc
import styled from 'styled-components'
import { useParams } from 'react-router-dom'

const AuthenticationWrapper = WithLoader(styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100vh;
  width: 100vw;
  background-color: ${props => props.theme.colors.mainBackgroundColor};
  margin-bottom: 2rem;
`)

const ManageAuthenticationView = () => {
  // Selectors
  const { transactionGuid } = useParams<{ transactionGuid?: string }>()
  return (
    // TODO hook up the isLoading to an actual value
    <AuthenticationWrapper isLoading={false}>
      <ManageAuthenticationContainer transactionGuid={transactionGuid} />
      <FooterNavContainer />
      <CopyBlock textSize='extra-small' isSidebar>
        © LW Theatres 2021
      </CopyBlock>
    </AuthenticationWrapper>
  )
}

export { ManageAuthenticationView }
