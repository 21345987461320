import { selectId, selectById } from '../entities'

// Misc
import { createSelector } from '@reduxjs/toolkit'

// Types
import { AppState } from 'modules/types'
import { Prompt, Product, ProductType } from 'shared-types'

export const selectIsLoading = (state: AppState) =>
  state.prompts.loading !== 'idle'

export const selectPromptEntities = (state: AppState) =>
  state.entities.prompts ?? {}
export const selectProductEntities = (state: AppState) =>
  state.entities.products ?? {} // No initial state for entities

export const selectPromptIds = (state: AppState) => state.prompts.byId
export const selectPromptById = createSelector(
  selectPromptEntities,
  selectId,
  selectById
)
export const selectProductById = createSelector(
  selectProductEntities,
  selectId,
  (products, id) => {
    const product = products[id] as Product

    return {
      ...product,
      maxQuantity: Math.min(product.availableSeatCount, product.maxQuantity),
      media: {
        mediaType: 'image',
        description: product.description,
        thumbnailUrl: product.imageUrl,
        mediaUrl: product.imageUrl
      }
    } as Product
  }
)

export const selectAllPrompts = createSelector(
  selectPromptIds,
  selectPromptEntities,
  (ids, prompts) => ids.map(id => prompts[id] as Prompt)
)

export const selectFilteredProducts = createSelector(
  selectPromptIds,
  selectPromptEntities,
  (promptIds, promptEntities) => {
    const filteredProducts: Record<ProductType, string[]> = {
      product: [],
      hospitality: [],
      event: [],
      optional: []
    }

    promptIds.map(promptId => {
      const prompt = promptEntities[promptId]
      const category = prompt?.category

      return prompt?.products.map(productId => {
        if (category) {
          filteredProducts[category].push(productId)
        } else {
          filteredProducts['optional'].push(productId)
        }
        return filteredProducts
      })
    })

    return filteredProducts
  }
)

export const getPromptMessage = (state: AppState) =>
  state.prompts.messages
    .map((messagePrompt: { message: string }) => messagePrompt.message)
    .join('\n')

export const selectArePromptsAvailable = createSelector(
  selectIsLoading,
  selectPromptIds,
  (loading, ids) => loading || ids.length > 0
)
