import React from 'react'

// Components
import { Icon, Link } from 'marvel-components'

// Misc
import { FormattedPrice } from 'utils'
import styled from 'styled-components'
import { media, usePlatform } from 'utils'

type Props = {
  id: EntityId
  title: string
  adultPrice?: number
  childPrice?: number
  description?: string
  isDisabled?: boolean
  imageUrl?: string
  backgroundColor?: string
  textColor?: string
  stacked?: boolean
  light?: boolean
  largeIcon?: boolean
  url?: string
  centered?: boolean
  isSelected?: boolean
  onSelect?: (id: EntityId) => void
  platform?: string
}

const SelectableListItemWrapper = styled.div<{
  backgroundColor: string
  textColor: string
  stacked: boolean
  light: boolean
  icon: boolean
  largeIcon: boolean
  isDisabled: boolean
  centered: boolean
  isSelected: boolean
  platform?: string
}>`
  display: flex;
  flex-direction: ${props => (props.stacked ? 'column' : 'row')};
  align-items: center;
  justify-content: ${props => (props.centered ? 'center' : 'flex-start')};
  flex-wrap: nowrap;
  background: ${props =>
    props.isDisabled
      ? props.theme.colors.borderColor
      : props.isSelected
      ? '#a9e8c1'
      : props.backgroundColor};
  color: ${props =>
    props.isDisabled ? props.theme.colors.secondaryColor : props.textColor};
  font-family: ${props => props.theme.font.copy};
  border-radius: 2px;
  padding: ${props =>
    props.largeIcon ? '2rem 1.5%' : props.icon ? '1rem 1.5%' : '1.25rem 1.25%'};
  width: 49%;
  margin: 0.5rem 0;
  cursor: ${props => (props.isDisabled ? 'not-allowed' : 'pointer')};
  border: ${props => (props.light ? '1px solid' : 'none')};
  border-color: ${props =>
    props.light ? props.theme.colors.borderColor : 'none'};
  box-sizing: border-box;
  position: relative;
  box-shadow: ${props =>
    props.isDisabled
      ? 'none'
      : 'rgba(0, 0, 0, 0.1) 0px 2px 3px 0px, rgba(0, 0, 0, 0.06) 0px 10px 15px 0px'};
  ${media.tablet} {
    width: ${props => (props.platform === 'kiosk' ? '49%' : '100%')};
  }
  ${media.phone} {
    padding: ${props =>
      props.largeIcon ? '1.5rem 2%' : props.icon ? '0.75rem 5%' : '1.25rem 3%'};
    width: 100%;
  }
  &&:before {
    content: '';
    position: absolute;
    width: 100%;
    height: 2px;
    top: 0;
    left: 0;
    background: ${props => (props.isSelected ? '#35965a' : 'transparent')};
  }
`

const SelectableListItemIconWrapper = styled.div<{
  stacked: boolean
  largeIcon: boolean
  centered: boolean
}>`
  max-width: ${props => (props.centered ? '100%' : '94%')};
  margin-right: ${props => (props.stacked ? '0' : '1rem')};
  margin-bottom: ${props => (props.stacked ? '2rem' : '0')};
  display: flex;
  align-items: center;
  ${media.phone} {
    max-width: ${props => (props.centered ? '100%' : '90%')};
    margin-bottom: ${props => (props.stacked ? '1.5rem' : '0')};
    margin-right: ${props => (props.stacked ? '0' : '1rem')};
  }
`

const IconWrapper = styled.div`
  background: #fff;
  position: absolute;
  top: 1.25rem;
  right: 1rem;
  border-radius: 2rem;
  fill: #8dd7a9;
  width: 1.2rem;
  height: 1.2rem;
  line-height: 1.3rem; /* Due to dodgy font awesome tick */
  text-align: center;
  font-size: 0.75rem;

  ${media.narrowdesktop} {
    width: 1rem;
    height: 1rem;
    font-size: 0.675rem;
    line-height: 1.15rem; /* Due to dodgy font awesome tick */
  }
`

const SelectableListItemIcon = styled.img`
  width: 100%;
`

const SelectableListItemDetailsWrapper = styled.div<{ centered: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: ${props => (props.centered ? 'center' : 'flex-start')};
  max-width: ${props => (props.centered ? '100%' : '94%')};
  ${media.phone} {
    max-width: ${props => (props.centered ? '100%' : '90%')};
  }
`

const SelectableListItemTitleWrapper = styled.span`
  display: flex;
  flex-direction: row;
  align-items: center;
`

const SelectableListItemTitle = styled.span<{ platform?: string }>`
  font-size: 1rem;
  font-family: ${props => props.theme.font.header};
  ${media.tablet} {
    font-size: ${props => (props.platform === 'kiosk' ? '1.5rem' : '1rem')};
  }
`

const UnavailableBanner = styled.span`
  margin-left: 0.25rem;
`

const SelectableListItemTitleLabelWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 0.5rem;
`

const SelectableListItemTitleLabel = styled.span`
  font-size: 0.75rem;
  color: ${props => props.theme.colors.copyColor};
  display: flex;
  flex-direction: row;
`

const SelectableListItemTitleLabelValue = styled.span`
  margin-left: 0.2rem;
`

const SelectableListItemTitleLabelSeparator = styled.span`
  margin-left: 0.2rem;
  margin-right: 0.2rem;
`

const SelectableListItemDescription = styled.span`
  font-family: ${props => props.theme.font.copy};
  margin: 0.75rem 0 0 0;
  font-size: 0.75rem;
  line-height: 1.25;
`

const SelectableListItemUrl = styled.span`
  font-family: ${props => props.theme.font.copy};
  color: ${props => props.theme.colors.copyColor};
  margin: 0.75rem 0 0 0;
  font-size: 0.625rem;
  line-height: 1.25;
  display: flex;
  flex-direction: row;
`

const SelectableListItemUrlLink = styled(Link)`
  margin-right: 0.25rem;
`

const SelectableListItemUrlLinkIcon = styled(Icon)``

const SelectableListItem: React.FC<Props> = ({
  id,
  isDisabled = false,
  imageUrl,
  backgroundColor = '#fff',
  textColor = '#000',
  stacked = false,
  light = true,
  largeIcon = false,
  title,
  description,
  adultPrice = 0,
  childPrice = 0,
  url,
  centered = false,
  isSelected = false,
  onSelect
}) => {
  const handleSelect = (e: React.MouseEvent) => {
    e.preventDefault()
    !isDisabled && onSelect && onSelect(id)
  }
  const platform = usePlatform()
  return (
    <SelectableListItemWrapper
      backgroundColor={backgroundColor}
      textColor={textColor}
      stacked={stacked}
      light={light}
      icon={!!imageUrl}
      isDisabled={isDisabled}
      largeIcon={largeIcon}
      centered={centered}
      isSelected={isSelected}
      onClick={handleSelect}
      platform={platform}
    >
      {isSelected && !largeIcon && (
        <IconWrapper>
          <Icon icon={'check'} />
        </IconWrapper>
      )}
      {imageUrl && (
        <SelectableListItemIconWrapper
          stacked={stacked}
          largeIcon={largeIcon}
          centered={centered}
        >
          <SelectableListItemIcon src={imageUrl} />
        </SelectableListItemIconWrapper>
      )}
      <SelectableListItemDetailsWrapper centered={centered}>
        <SelectableListItemTitleWrapper>
          <SelectableListItemTitle platform={platform}>
            {title}
            {isDisabled && <UnavailableBanner>- Unavailable</UnavailableBanner>}
          </SelectableListItemTitle>
        </SelectableListItemTitleWrapper>
        {adultPrice > 0 && (
          <SelectableListItemTitleLabelWrapper>
            <SelectableListItemTitleLabel>
              <FormattedPrice currency='GBP' amount={adultPrice} />
              <SelectableListItemTitleLabelValue>
                per Adult
              </SelectableListItemTitleLabelValue>
            </SelectableListItemTitleLabel>
            {childPrice > 0 && (
              <SelectableListItemTitleLabel>
                <SelectableListItemTitleLabelSeparator>
                  |
                </SelectableListItemTitleLabelSeparator>
                <FormattedPrice currency='GBP' amount={childPrice} />
                <SelectableListItemTitleLabelValue>
                  per Child
                </SelectableListItemTitleLabelValue>
              </SelectableListItemTitleLabel>
            )}
          </SelectableListItemTitleLabelWrapper>
        )}
        {description && (
          <SelectableListItemDescription>
            {description}
          </SelectableListItemDescription>
        )}
        {url && (
          <SelectableListItemUrl>
            <SelectableListItemUrlLink
              href={url}
              target='_blank'
              text={'See sample menu '}
            />
            <SelectableListItemUrlLinkIcon icon={'external-link-alt'} />
          </SelectableListItemUrl>
        )}
      </SelectableListItemDetailsWrapper>
    </SelectableListItemWrapper>
  )
}

export { SelectableListItem }
