import React from 'react'

// Misc
import styled from 'styled-components/macro'

type Props = {
  key: number
}

const ListTextWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
`

const ListTextContent = styled.div`
  font-family: ${(props) => props.theme.font.copy};
  padding: 0 2%;
  color: ${(props) => props.theme.colors.copyColor};
  font-size: 0.875rem;
  width: 100%;
`

export const ListText: React.FC<Props> = ({ children }) => {
  return (
    <ListTextWrapper>
      <ListTextContent>{children}</ListTextContent>
    </ListTextWrapper>
  )
}
