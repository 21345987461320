import styled from 'styled-components/macro'
import { ErrorMessage, FieldProps } from 'formik'
import { Checkbox, FormError, CheckboxProps } from 'marvel-components'

interface Props extends FieldProps, CheckboxProps {}

const FormCheckboxWrapper = styled.div<{ noPadding?: boolean }>`
  position: relative;
  padding-bottom: 1.4rem;
`

export const FormCheckbox = (props: Props) => {
  const {
    field,
    form,
    label,
    children,
    isLabelVisible,
    size = 'large',
    checkboxTheme = 'dark',
    isRequired
  } = props

  const { name } = field
  const { setFieldValue } = form

  return (
    <FormCheckboxWrapper>
      <ErrorMessage {...field} component={FormError} />
      <Checkbox
        {...field}
        isRequired={isRequired}
        isChecked={field.value}
        label={label}
        aria-label={label}
        isLabelVisible={isLabelVisible}
        size={size}
        checkboxTheme={checkboxTheme}
        onChange={(isChecked: boolean) => {
          setFieldValue(name, isChecked)
        }}
      >
        {children}
      </Checkbox>
    </FormCheckboxWrapper>
  )
}
