import React, { useEffect } from 'react'

// Components
import { FooterNav, Layout } from 'marvel-components'

// Views
import { PaymentView } from './PaymentView'

// Containers
import {
  BrandLogoContainer,
  OrderContainer,
  OrderSummaryContainer
} from 'containers/Hospitality'
import { MainMenuContainer } from 'containers/Kiosk'

// Actions
import { orderActions } from 'modules/hospitality/order'

// Selectors
import { siteSelectors } from 'modules/hospitality/site'
import { orderSelectors } from 'modules/hospitality/order'

// Misc
import { Route, Switch, useRouteMatch } from 'react-router'
import { useDispatch, useSelector } from 'react-redux'
import { useQuery } from 'utils/hooks'
import { trackHospitalityOrderSuccess, usePlatform } from 'utils'
import { useTypedSelector } from 'modules'
import { selectBookingFlow } from 'modules/basket/selectors'

const OrderView: React.FC = () => {
  const dispatch = useDispatch()
  const query = useQuery()

  // Routing
  const {
    path,
    params: { siteId, orderId }
  } = useRouteMatch<{
    siteId: string
    orderId: string
  }>()

  // Selectors
  const order = useSelector(orderSelectors.selectOrder)
  const siteName = useTypedSelector(state =>
    siteSelectors.selectSiteName(state, siteId)
  )
  const platform = usePlatform()
  const bookingFlow = useSelector(selectBookingFlow)

  const handleCallToActionClick = (e: React.MouseEvent) => {
    window.location.href = 'https://thelane.co.uk/during-the-day'
  }

  useEffect(() => {
    if (order && order.status === 'PAID' && siteName) {
      trackHospitalityOrderSuccess({
        order,
        siteName,
        platform,
        bookingFlow
      })
    }
  }, [order, siteName, platform, bookingFlow])

  useEffect(() => {
    if (!order) {
      orderId && dispatch(orderActions.fetchOrder(orderId))
    }
  }, [dispatch, orderId, order])

  return (
    <Switch>
      <Route exact path={`${path}`}>
        <Layout
          pageTitle={`Order Confirmation - Hospitality | LW Theatres`}
          renderBrandLogo={<BrandLogoContainer />}
          renderSidebarTop={<OrderSummaryContainer />}
          renderSidebarBottom={<FooterNav />}
          renderMainTop={
            <OrderContainer
              error3d={query.get('error3d')}
              callToActionText='Explore The Lane'
              onCallToActionClick={handleCallToActionClick}
            />
          }
          renderMain={platform === 'kiosk' ? <MainMenuContainer /> : undefined}
        />
      </Route>
      <Route exact path={`${path}/payment`}>
        <PaymentView />
      </Route>
    </Switch>
  )
}
export { OrderView }
