import React from 'react'
import styled, { css } from 'styled-components/macro'

// Components
import { Icon } from 'marvel-components'

// Misc
import { darken, media, fadeIn, usePlatform } from 'utils'
import moment from 'moment'

interface Props {
  isSmallCalendar?: boolean
  showPreviousButton: boolean
  showNextButton: boolean
  onPreviousClick(callback?: () => void): void
  onNextClick(callback?: () => void): void
  month?: Date
  platform?: string
}

const NavBarWrapper = styled.div<{
  isSmallCalendar: boolean
  platform?: string
}>(props => {
  const { isSmallCalendar, platform } = props

  return css`
    height: 2rem;
    width: ${isSmallCalendar ? '12.5rem' : '15.625rem'};
    display: flex;
    justify-content: ${isSmallCalendar ? 'center' : 'space-between'};
    margin: ${isSmallCalendar ? '0 auto' : '0'};
    ${media.tablet} {
      width: ${platform === 'kiosk'
        ? '22rem'
        : isSmallCalendar
        ? '12.5rem'
        : '15.625rem'};
      margin-top: ${platform === 'kiosk' ? '1rem' : '0'};
    }
  `
})

const NavButton = styled.button<{
  isSmallCalendar?: boolean
  label: string
  isDisabled: boolean
  platform?: string
}>(props => {
  const {
    theme: {
      colors: { secondaryBackgroundColor }
    },
    isSmallCalendar,
    label,
    isDisabled,
    platform
  } = props

  return css`
    position: relative;
    cursor: pointer;
    color: ${darken(secondaryBackgroundColor, 80)};
    border: none;
    background: none;
    padding: 0;
    font-size: 1.625rem;

    pointer-events: ${isDisabled ? 'none' : 'initial'};
    opacity: ${isDisabled ? '0.4' : '1'};

    ${media.tablet} {
      font-size: ${platform === 'kiosk' ? '2rem' : '1.25rem'};
    }

    ${isSmallCalendar &&
      css`
        font-size: 1.25rem;
      `}

    &:hover {
      color: ${darken(secondaryBackgroundColor, 90)};
    }

    ${isDisabled &&
      css`
      &:hover:before {
        content: '';
        position: absolute;
        left: 50%;
        top: 100%;
        border-left: 0.25rem solid #0000;
        border-right: 0.25rem solid #0000;
        border-bottom: 0.25rem solid #000;
        transform: translateX(-0.25rem);
        opacity: 0;
        animation: 0.5s ${fadeIn} 0.5s forwards;
      }

      &:hover:after {
        content: "${label}";
        position: absolute;
        top: calc(100% + 0.25rem);
        left: 50%;
        font-size: 0.75rem;
        transform: translateX(-50%);
        background: #000;
        color: #fff;
        padding: 0.5rem;
        border-radius: 0.25rem;
        opacity: 0;
        animation: 0.5s ${fadeIn} 0.5s forwards;
      }
      `}

    :focus {
      outline: none;
    }
  `
})

const CaptionWrapper = styled.div<{
  isSmallCalendar?: boolean
  platform?: string
}>(props => {
  const {
    theme: {
      colors: { secondaryBackgroundColorComplement },
      font: { header }
    },
    isSmallCalendar = false,
    platform
  } = props

  return css`
    padding: 0;
    margin: ${isSmallCalendar
      ? '0.35rem auto 0.5rem auto'
      : '0.3rem auto 0.5rem auto'};
    color: ${darken(secondaryBackgroundColorComplement, 20)};
    font-family: ${header};
    font-size: ${isSmallCalendar ? '1rem' : '1.25rem'};
    text-align: center;

    ${media.narrowdesktop} {
      font-size: 1rem;
    }

    ${media.tablet} {
      font-size: ${platform === 'kiosk' ? '1.5rem' : '1rem'};
      padding: ${platform === 'kiosk' ? '0.3rem 0' : '0.5rem 0'};
      margin-top: 0;
    }
  `
})

export const DateNavigator = (props: Props) => {
  const {
    onPreviousClick,
    onNextClick,
    isSmallCalendar = false,
    showNextButton,
    showPreviousButton,
    month
  } = props

  const handlePreviousClick = (e: React.MouseEvent) => {
    e.preventDefault()
    onPreviousClick()
  }
  const handleNextClick = (e: React.MouseEvent) => {
    e.preventDefault()
    onNextClick()
  }
  const platform = usePlatform()

  return (
    <NavBarWrapper isSmallCalendar={isSmallCalendar} platform={platform}>
      <NavButton
        label={'Previous Month'}
        aria-label={'Previous Month'}
        onClick={handlePreviousClick}
        isSmallCalendar={isSmallCalendar}
        isDisabled={!showPreviousButton}
        platform={platform}
      >
        <Icon icon={'chevron-left'} />
      </NavButton>

      <CaptionWrapper isSmallCalendar={isSmallCalendar} platform={platform}>
        {month && `${moment.months()[month.getMonth()]} ${month.getFullYear()}`}
      </CaptionWrapper>

      <NavButton
        label={'Next Month'}
        aria-label={'Next Month'}
        onClick={handleNextClick}
        isSmallCalendar={isSmallCalendar}
        isDisabled={!showNextButton}
        platform={platform}
      >
        <Icon icon={'chevron-right'} />
      </NavButton>
    </NavBarWrapper>
  )
}
