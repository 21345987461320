import { combineReducers } from 'redux'
import { CLOSE_MODAL, OPEN_MODAL } from './types'
import { ModalActions, ModalPayload } from './types'

const isModalOpen = (state = false, action: ModalActions) => {
  switch (action.type) {
    case OPEN_MODAL:
      return true
    case CLOSE_MODAL:
      return false
    default:
      return state
  }
}

const modal = (state: ModalPayload | null = null, action: ModalActions) => {
  switch (action.type) {
    case OPEN_MODAL:
      return action.payload
    case CLOSE_MODAL:
      return null
    default:
      return state
  }
}

export default combineReducers({
  isModalOpen,
  modal
})
