import { createEntityAdapter, createSlice } from '@reduxjs/toolkit'
import { Terminal } from 'shared-types'
import { fetchTerminals } from 'modules/self-serve/terminal/actions'

export const terminalAdapter = createEntityAdapter<Terminal>()

type AdditionalState = {
  status: 'idle' | 'pending' | 'fulfilled' | 'rejected'
}

const additionalState: AdditionalState = { status: 'idle' }

const initialState = terminalAdapter.getInitialState(additionalState)

const terminalSlice = createSlice({
  name: 'terminal',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(fetchTerminals.pending, state => {
        state.status = 'pending'
      })
      .addCase(fetchTerminals.fulfilled, (state, action) => {
        state.status = 'fulfilled'
        terminalAdapter.setAll(state, action.payload.data.terminals)
      })
      .addCase(fetchTerminals.rejected, state => {
        state.status = 'rejected'
      })
  }
})

export const terminalActions = {
  fetchTerminals
}

export default terminalSlice.reducer
