// Adapters
import { servicesAdapter } from './reducers'

// Selectors
import { menuSelectors } from 'modules/smart-waiter/menu'

// Types
import { AppState } from 'modules/types'
import { Menu } from 'shared-types'
// Utils
import { createSelector } from 'reselect'

export const {
  selectById,
  selectIds,
  selectEntities,
  selectAll,
  selectTotal
} = servicesAdapter.getSelectors<AppState>(state => state.smartWaiter.services)

export const selectIsVenueListLoading = (state: AppState) =>
  state.smartWaiter.venues.loading === 'pending'

export const selectMenusForServiceId = createSelector(
  [selectById, menuSelectors.selectEntities],
  (service, menuEntities) =>
    ((service && service.menus) || []).reduce((results: Menu[], id) => {
      const menu = menuEntities[id]
      if (menu) {
        results.push(menu)
      }
      return results
    }, [])
)

export const selectServiceName = createSelector(
  selectById,
  service => service?.name
)
