import { eventIds } from 'utils'

// Assets
import PreShowIcon from 'assets/pre-show-icon.svg'
import IntervalIcon from 'assets/interval-icon.svg'

import AfternoonTeaIcon from 'assets/hospitality_TRDL_AfternoonTea.png'
import CecilBeatonIcon from 'assets/hospitality_TRDL_Cecils.png'
import TerraceIcon from 'assets/hospitality_TRDL_Terrace.png'
import TableBookingIcon from 'assets/hospitality_table_booking2.png'

// Delivery Assets
import mobileIcon from 'assets/mobile-icon.svg'
import printAtHomeIcon from 'assets/print-icon.svg'
import postIcon from 'assets/post-icon.svg'
import collectIcon from 'assets/collect-icon.svg'

// Types
import { SeatDisplay, SeatType } from 'shared-types'

const exchangeText = {
  joseph: (showName?: string) => (
    <>
      <p>
        Don’t worry, you can request an exchange here or opt for a {showName}{' '}
        Credit Voucher.
      </p>
      <p>
        Complete the form below by 3rd July to apply for a free ticket exchange,
      </p>
      <p>
        A member of our customer relations team will be in contact with you
        within 10 working days to finalise your booking. Please note that until
        that time, any exchange is not confirmed.
      </p>
    </>
  ),
  cinderella: () => (
    <>
      <p>
        Don’t worry, you can request an exchange here or opt for a Cinderella
        Credit Voucher.
      </p>
      <p>Please complete the form below within 28 days.</p>
      <p>
        A member of our customer relations team will be in contact with you
        within <b>20 working days</b> to finalise your booking. Please note that
        until that time, any exchange is not confirmed. Exchange requests will
        be processed in order of preferred performance date and we have held a
        number of seats from sale for each performance to help accommodate them.
      </p>
      <p>
        To help you with your ticket exchange, we have identified performances
        that have tickets available that are similar to your original booking.
        Please select up to 3 performances that you would like to exchange your
        tickets into.
      </p>
    </>
  ),
  default: (showName?: string) => (
    <>
      <p>
        Don’t worry, you can request an exchange here or opt for a {showName}{' '}
        Credit Voucher.
      </p>
      <p>
        Complete the form below within 28 days to apply for a free ticket
        exchange. The expiry date can be found in your new confirmation email.
      </p>
      <p>
        A member of our customer relations team will be in contact with you
        within 10 working days to finalise your booking. Please note that until
        that time, any exchange is not confirmed.
      </p>
    </>
  )
}

const getExchangeTextForEvent = (showName?: string, eventId?: string) => {
  switch (eventId) {
    case eventIds.production.joseph:
    case eventIds.development.joseph:
      return exchangeText.joseph(showName)
    case eventIds.production.cinderella:
    case eventIds.production.cinderellaSd:
    case eventIds.development.cinderella:
    case eventIds.development.cinderellaSd:
      return exchangeText.cinderella()
    default:
      return exchangeText.default(showName)
  }
}

type SWMenu = {
  title: string
  icon: typeof PreShowIcon | typeof IntervalIcon
  cutOff: number
}

const preShow: SWMenu = {
  title: 'Pre-Show Menu',
  icon: PreShowIcon,
  cutOff: 120
}
const interval: SWMenu = {
  title: 'Interval Menu',
  icon: IntervalIcon,
  cutOff: 5
}
const intervalTwo: SWMenu = {
  title: 'Second Interval Menu',
  icon: IntervalIcon,
  cutOff: -90
}

const parseZonalMenu = (menuName: string) => {
  if (menuName.toLowerCase().search('interval 2') > 0) {
    return intervalTwo
  } else if (menuName.toLowerCase().search('interval') > 0) {
    return interval
  }
  return preShow
}

type HPService = {
  title: string
  icon: typeof PreShowIcon | typeof IntervalIcon
}

const afternoonTea: HPService = {
  title: 'Afternoon Tea Reservation',
  icon: AfternoonTeaIcon
}
const tableBooking: HPService = {
  title: 'Table Booking',
  icon: TableBookingIcon
}
const cecilBeaton: HPService = {
  title: 'Cecil Beaton Table Reservation',
  icon: CecilBeatonIcon
}
const terraceTable: HPService = {
  title: 'Terrace Table Reservation',
  icon: TerraceIcon
}

const parseLiveResOccasion = (occasionName: string) => {
  switch (occasionName) {
    case 'Afternoon Tea Reservation':
      return afternoonTea
    case 'Table Booking':
      return tableBooking
    case 'Cecil Beaton Table Reservation':
      return cecilBeaton
    case 'Terrace Table Reservation':
      return terraceTable
    default:
      return tableBooking
  }
}

const getDeliveryIcon = (deliveryOptionName: string) => {
  switch (deliveryOptionName) {
    case 'E-Ticket':
      return printAtHomeIcon
    case 'Collect From Theatre':
      return collectIcon
    case 'Print at Home':
      return printAtHomeIcon
    case 'Post (to UK addresses only)':
      return postIcon
    case 'Mobile Ticket ':
      return mobileIcon
    case 'Mobile Ticket':
      return mobileIcon
    case 'Post (UK Only)':
      return postIcon
    case 'E-Ticket.':
      return printAtHomeIcon
  }
}

const BLUE_MESSAGE = '#d9edf7'
const YELLOW_MESSAGE = '#ffd500'

const getSeatDisplay = (seatType?: SeatType) => {
  if (seatType) {
    switch (seatType.icon) {
      case 'star':
        return {
          messageIcon: 'star',
          icon: 'star',
          background: BLUE_MESSAGE,
          iconPrefix: 'fas',
          note: seatType?.note
        } as SeatDisplay
      case 'warning':
        return {
          messageIcon: 'bell',
          icon: 'bell',
          iconPrefix: 'fas',
          background: YELLOW_MESSAGE,
          note: seatType.note
        } as SeatDisplay
      case 'accessible':
        return {
          messageIcon: 'wheelchair',
          icon: 'wheelchair',
          iconPrefix: 'fas',
          background: BLUE_MESSAGE,
          note: seatType.note
        } as SeatDisplay
      case 'information':
        return {
          messageIcon: 'info-circle',
          icon: 'circle',
          iconPrefix: 'far',
          background: YELLOW_MESSAGE,
          note: seatType.note
        } as SeatDisplay
      case 'user':
        return {
          messageIcon: 'info-circle',
          icon: 'user-circle',
          iconPrefix: 'far',
          background: BLUE_MESSAGE,
          note: seatType.note
        } as SeatDisplay
      default:
        return {
          messageIcon: 'info-circle',
          icon: 'info-circle',
          iconPrefix: 'fas',
          background: YELLOW_MESSAGE,
          note: seatType.note
        } as SeatDisplay
    }
  }
}

export {
  getExchangeTextForEvent,
  parseZonalMenu,
  parseLiveResOccasion,
  getDeliveryIcon,
  getSeatDisplay
}
