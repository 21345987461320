import { PropsWithChildren } from 'react'
import styled from 'styled-components'
import { media } from 'utils'

const CardWrapper = styled.div`
  display: flex;
  flex-direction: column;
  overflow: auto;
  background: ${props => props.theme.colors.secondaryBackgroundColor};
  color: ${props => props.theme.colors.copyColor};
  justify-content: flex-start;
  cursor: pointer;
`

const CardImage = styled.img`
  object-fit: cover;
  flex: 1 1 auto;
  width: 100%;
  min-height: 256px;
  max-height: 256px;
  ${media.tablet} {
    min-height: 199px;
    max-height: 199px;
  }
`

const CardContent = styled.div`
  padding: 1rem;
  text-align: center;
`

const CardTitle = styled.h1`
  font-size: 1rem;
  margin: 0 0 0.25rem 0;
  text-align: center;
  font-family: ${props => props.theme.font.header};
  height: 1.25rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-weight: normal;
`
const CardDetails = styled.p`
  font-family: ${props => props.theme.font.copy};
  font-size: 0.675rem;
  height: 1rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin: 0.5rem 0 0 0;
`

type Props = {
  title: string
  imageUrl: string
  value?: string
  onClick?: (value?: string) => void
}

const Card: React.FC<PropsWithChildren<Props>> = ({
  title,
  imageUrl,
  value,
  onClick,
  children,
  ...props
}) => {
  const handleClick = (e: React.MouseEvent) => {
    e.preventDefault()
    onClick && onClick(value)
  }

  return (
    <CardWrapper onClick={onClick && handleClick} {...props}>
      <CardImage src={imageUrl} />
      <CardContent>
        <CardTitle>{title}</CardTitle>
        <CardDetails>{children}</CardDetails>
      </CardContent>
    </CardWrapper>
  )
}

export { Card }
