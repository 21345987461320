import React from 'react'
import styled from 'styled-components'
import { Button } from 'marvel-components'
import { usePlatform } from 'utils'

import { media, useIsNarrowViewport } from 'utils'

interface Props {
  onLoginClick: () => void
  onLogoutClick: () => void
  isUserLoggedIn: boolean
  isSmall?: boolean
}

const StyledButton = styled(Button)`
  ${media.tablet} {
    background: ${props => props.theme.colors.lwBrandColor};
    color: ${props => props.theme.colors.lwBrandColorComplement};
    fill: ${props => props.theme.colors.lwBrandColorComplement};
  }
`

export const Login = (props: Props) => {
  const { onLoginClick, onLogoutClick, isUserLoggedIn, isSmall = false } = props
  const platform = usePlatform()
  const isNarrowViewport = useIsNarrowViewport()

  return (
    <StyledButton
      onClick={isUserLoggedIn ? onLogoutClick : onLoginClick}
      text={isUserLoggedIn ? 'Logout' : 'Login'}
      icon={isUserLoggedIn ? 'sign-out-alt' : 'user'}
      size={
        platform === 'kiosk' && isNarrowViewport
          ? undefined
          : isSmall
          ? 'extra-small'
          : 'small'
      }
      buttonType={'rounded'}
    />
  )
}
