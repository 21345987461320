// Selectors
import { selectKioskAccessToken } from 'modules/self-serve/kiosk/selectors'

// Types
import { Platform } from 'shared-types'

// Misc
import { useSelector } from 'react-redux'

export const usePlatform = (): Platform => {
  const kioskAccessToken = useSelector(selectKioskAccessToken)
  return kioskAccessToken ? 'kiosk' : 'browser'
}
