import { combineReducers } from 'redux'
import {
  SET_DISCOUNT_CODE,
  SET_PROMO_CODE,
  SET_LOTTERY_TOKEN,
  SetDiscountCodeAction,
  SetPromoCodeAction
} from './types'
import * as actions from './actions'

const byId = (state = null, action: any) => {
  switch (action.type) {
    case actions.fetchDiscountedPerformance.TRIGGER:
    case actions.fetchDiscountedPerformance.FAILURE:
      return null
    case actions.fetchDiscountedPerformance.SUCCESS:
      return action.payload.response.result
    default:
      return state
  }
}

const discountCode = (state = null, action: SetDiscountCodeAction | any) => {
  switch (action.type) {
    case SET_DISCOUNT_CODE:
      return action.payload.code
    default:
      return state
  }
}

const promoCode = (state = null, action: SetPromoCodeAction | any) => {
  switch (action.type) {
    case SET_PROMO_CODE:
      return action.payload.code
    default:
      return state
  }
}

const lotteryToken = (state = null, action: SetPromoCodeAction | any) => {
  switch (action.type) {
    case SET_LOTTERY_TOKEN:
      return action.payload.code
    default:
      return state
  }
}

export default combineReducers({
  byId,
  discountCode,
  promoCode,
  lotteryToken
})
