import { createAsyncThunk } from '@reduxjs/toolkit'
import { bannerActions } from 'modules/banner'
import { normalize, schema } from 'normalizr'
import contentfulApi from '../services/contentfulApi'
import { ContentfulVenueEntity, Venue } from '../shared/types'

const venueSchema = new schema.Entity('venues', undefined)

type FetchVenueResponse = {
  venues: Record<string, Venue>
}

export const fetchVenues = createAsyncThunk<FetchVenueResponse, undefined>(
  'marvel/self-serve/FETCH_VENUES',
  async (_, { dispatch, rejectWithValue }) => {
    try {
      const venues = (await contentfulApi.fetchMany({
        contentType: 'venue'
      })) as ContentfulVenueEntity[]

      if (!venues) {
        throw new Error('Failed to fetch venues')
      }

      const venuesData: Venue[] = venues.map(venue => {
        return {
          ...venue.fields,
          id: venue.sys.id,
          order: venue.fields.order,
          title: venue.fields.title,
          externalId: venue.fields.externalId,
          accentColor: venue.fields.accentColor,
          logo: venue.fields.logo?.fields.file.url,
          pattern: venue.fields.pattern?.fields.file.url
        }
      })

      const normalized = normalize(venuesData, [venueSchema])
      return normalized.entities as FetchVenueResponse
    } catch (err) {
      dispatch(
        bannerActions.setBannerContent({
          bannerType: 'error',
          title: 'Please Note',
          text: 'Failed to fetch venues.'
        })
      )
      return rejectWithValue(err)
    }
  }
)
