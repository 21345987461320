// Misc
import { schema } from 'normalizr'

// Types
import { DeliveryOption } from 'shared-types'

// Actions
import ticketingApi from '../services/ticketingApi'
import { createAPIThunk } from '../shared/actions'

const deliveryOptionSchema = new schema.Entity('deliveryOptions')

type FetchDeliveryOptionsRequest = {
  transactionGuid: string
}

type FetchDeliveryOptionsResponse = {
  deliveryOptions: Record<string, DeliveryOption>
}

export const fetchDeliveryOptions = createAPIThunk<
  FetchDeliveryOptionsResponse,
  FetchDeliveryOptionsRequest
>(
  'marvel/ticketing/FETCH_DELIVERY_OPTIONS',
  (data, contextId, authToken) =>
    ticketingApi.fetchAll(
      `/reservation/${data.transactionGuid}/delivery/`,
      {},
      contextId,
      authToken
    ),
  [deliveryOptionSchema]
)
