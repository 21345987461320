import React from 'react'
import styled, { css } from 'styled-components/macro'
import { media } from 'utils'
import { WeekdayElementProps } from 'react-day-picker'

interface Props extends WeekdayElementProps {
  isSmallCalendar: boolean
}

const WeekdayHeaderWrapper = styled.div`
  display: table-cell;
  padding: 1.5rem 0 0.5rem 0;
  font-size: 0.75rem;
  font-weight: 500;
  text-transform: uppercase;
  text-align: center;
  color: #666;
`

const WeekdayTitleSmall = styled.span<{ isSmallCalendar?: boolean }>(props => {
  const { isSmallCalendar } = props

  return css`
    display: none;
    ${media.tablet} {
      display: block;
    }

    ${isSmallCalendar &&
      css`
        display: block;
      `}
  `
})

const WeekdayTitle = styled.span`
  display: block;
  ${media.tablet} {
    display: none;
  }
`

export const WeekdayHeader = (props: Props) => {
  const { weekday, weekdaysLong, localeUtils, locale, isSmallCalendar } = props

  let title
  let shortTitle

  if (weekdaysLong) {
    title = weekdaysLong[weekday]
    shortTitle = title.charAt(0)
  } else {
    title = localeUtils.formatWeekdayLong(weekday, locale)
    shortTitle = title.charAt(0)
  }

  return (
    <WeekdayHeaderWrapper role="columnheader">
      {!isSmallCalendar && <WeekdayTitle>{title}</WeekdayTitle>}
      <WeekdayTitleSmall isSmallCalendar={isSmallCalendar}>
        {shortTitle}
      </WeekdayTitleSmall>
    </WeekdayHeaderWrapper>
  )
}
