import backgroundAsset from './assets/jatb_sidebar.jpg'
export const JackAndTheBeanstalk = {
  colors: {
    mainBackgroundColor: '#176f93',
    mainColor: '#83aacb',
    mainColorComplement: '#111',
    lwBrandColor: '#ffffff',
    lwBrandColorComplement: '#111111',
    performanceHoverColor: '#b3d7e6',
    bestAvailableBannerColor: '#ffd500',
    bestAvailableBannerColorComplement: '#111'
  },
  themeBackgroundAsset: backgroundAsset,
  themeBackgroundColour: '#176f93',
  orderSuccess: {
    showHospitality: true,
    showCecilBeatonBar: true
  }
}
