import React, { MouseEvent, TouchEvent } from 'react'
import styled from 'styled-components/macro'
import { pulse } from 'utils'
import { SeatType } from 'shared-types'
import { SeatToolTipContainer } from 'containers/Ticketing'
import { ToolTip } from 'marvel-components'

interface Props {
  id: number
  areaId: string
  x: number
  y: number
  height: number
  width: number
  angle: number
  sectorAngle: number
  hidden?: boolean
  background?: string
  isDisabled?: boolean
  isPriceLevelDisabled?: boolean
  isSelected?: boolean
  seatType?: SeatType
  toolTipTarget: any

  // Interaction
  onClick?: () => void
  onTouch?: () => void
}

const DISABLED_SEAT_BACKGROUND = '#e8e8e8'

const SelectedIcon = styled.svg`
  pointer-events: none;
  fill: #1e1e1e;
`

const SeatWrapper = styled.g`
  will-change: fill;
  transition: fill 0.2s;
  outline: none;

  .pulse {
    animation: ${pulse} 1s linear infinite;
    stroke: #000000;
  }

  :hover {
    opacity: 0.8;
  }
`

const SelectedIconPath = styled.path`
  @keyframes checkmark {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  animation: checkmark 0.3s ease-in-out;
`

const StyledCircle = styled.circle`
  &:focus {
    outline: 0;
  }
`

const SeatMapSeatNM: React.FC<Props> = ({
  id,
  areaId,
  x,
  y,
  height,
  width,
  angle,
  sectorAngle,
  hidden,
  isDisabled = false,
  isPriceLevelDisabled = false,
  isSelected = false,
  seatType = null,
  background = DISABLED_SEAT_BACKGROUND,
  toolTipTarget,
  onClick,
  onTouch
}) => {
  const radius = width / 2
  const backgroundColor =
    isDisabled || isPriceLevelDisabled ? DISABLED_SEAT_BACKGROUND : background
  const handleOnClick = (e: MouseEvent) => {
    onClick && onClick()
  }
  const handleOnTouch = (e: TouchEvent) => {
    // On touch is cancelled by panzoom but touch end isn't so can be used for mobile selection.
    onTouch && onTouch()
  }
  const rotateAngle = 180 - angle - sectorAngle

  if (hidden) {
    return null
  }

  return (
    <SeatWrapper
      key={`seat-${areaId}-${id}`}
      tabIndex={0}
      fill={backgroundColor}
      fillOpacity={1}
      transform={`translate(${x}, ${y}) rotate(${angle})`}
      onClick={handleOnClick}
      onTouchEnd={handleOnTouch}
    >
      {/* Is Selected */}
      {isSelected && (
        <circle cx={radius} cy={radius} r={radius + 1} fill='#000000'>
          <animate
            attributeName='r'
            begin='0s'
            dur='1s'
            repeatCount='indefinite'
            from={radius + 1}
            to={radius + 3}
          />
          <animate
            attributeName='opacity'
            values='1;0;'
            dur='1s'
            repeatCount='indefinite'
          />
        </circle>
      )}

      {/* Background Circle */}
      {isDisabled ? (
        <circle cx={radius} cy={radius} r={isSelected ? radius + 1 : radius} />
      ) : (
        <ToolTip
          singleton={toolTipTarget}
          touch={['hold', 250]}
          $unthemed={true}
          disabled={isPriceLevelDisabled}
          content={<SeatToolTipContainer seatId={`${areaId}-${id}`} />}
        >
          <StyledCircle
            cx={radius}
            cy={radius}
            r={isSelected ? radius + 1 : radius}
          />
        </ToolTip>
      )}
      {/* Acessible Icons */}
      {!isSelected &&
        !isDisabled &&
        seatType &&
        seatType.icon === 'accessible' && (
          <SelectedIcon width={width} height={height} viewBox={`0 0 512 512`}>
            <SelectedIconPath
              fill='#ffffff'
              fillOpacity={1}
              d='M496.101 385.669l14.227 28.663c3.929 7.915.697 17.516-7.218 21.445l-65.465 32.886c-16.049 7.967-35.556 1.194-43.189-15.055L331.679 320H192c-15.925 0-29.426-11.71-31.679-27.475C126.433 55.308 128.38 70.044 128 64c0-36.358 30.318-65.635 67.052-63.929 33.271 1.545 60.048 28.905 60.925 62.201.868 32.933-23.152 60.423-54.608 65.039l4.67 32.69H336c8.837 0 16 7.163 16 16v32c0 8.837-7.163 16-16 16H215.182l4.572 32H352a32 32 0 0 1 28.962 18.392L438.477 396.8l36.178-18.349c7.915-3.929 17.517-.697 21.446 7.218zM311.358 352h-24.506c-7.788 54.204-54.528 96-110.852 96-61.757 0-112-50.243-112-112 0-41.505 22.694-77.809 56.324-97.156-3.712-25.965-6.844-47.86-9.488-66.333C45.956 198.464 0 261.963 0 336c0 97.047 78.953 176 176 176 71.87 0 133.806-43.308 161.11-105.192L311.358 352z'
              transform={`rotate(${rotateAngle} 256,256) translate(90,60) scale(0.7) `}
            ></SelectedIconPath>
          </SelectedIcon>
        )}

      {/* Warning Icon */}
      {!isSelected && !isDisabled && seatType && seatType.icon === 'warning' && (
        <SelectedIcon width={width} height={height} viewBox={`0 0 410 512`}>
          <SelectedIconPath
            fill='#ffffff'
            fillOpacity={1}
            d='M224 512c35.32 0 63.97-28.65 63.97-64H160.03c0 35.35 28.65 64 63.97 64zm215.39-149.71c-19.32-20.76-55.47-51.99-55.47-154.29 0-77.7-54.48-139.9-127.94-155.16V32c0-17.67-14.32-32-31.98-32s-31.98 14.33-31.98 32v20.84C118.56 68.1 64.08 130.3 64.08 208c0 102.3-36.15 133.53-55.47 154.29-6 6.45-8.66 14.16-8.61 21.71.11 16.4 12.98 32 32.1 32h383.8c19.12 0 32-15.6 32.1-32 .05-7.55-2.61-15.27-8.61-21.71z'
            transform={`rotate(${rotateAngle} 256,256) translate(50,50) scale(0.7)`}
          ></SelectedIconPath>
        </SelectedIcon>
      )}

      {/* Information Icon */}
      {!isSelected &&
        !isDisabled &&
        seatType &&
        seatType.icon === 'information' && (
          <SelectedIcon width={width} height={height} viewBox={`0 0 512 512`}>
            <SelectedIconPath
              fill='#ffffff'
              fillOpacity={1}
              d='M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm0 448c-110.5 0-200-89.5-200-200S145.5 56 256 56s200 89.5 200 200-89.5 200-200 200z'
              transform={`rotate(${rotateAngle}, 256,256) translate(50,50) scale(0.8)`}
            ></SelectedIconPath>
          </SelectedIcon>
        )}

      {/* Hospitality Icon */}
      {!isSelected && !isDisabled && seatType && seatType.icon === 'star' && (
        <SelectedIcon width={width} height={height} viewBox={`0 0 576 512`}>
          <SelectedIconPath
            fill='#ffffff'
            fillOpacity={1}
            d='M259.3 17.8L194 150.2 47.9 171.5c-26.2 3.8-36.7 36.1-17.7 54.6l105.7 103-25 145.5c-4.5 26.3 23.2 46 46.4 33.7L288 439.6l130.7 68.7c23.2 12.2 50.9-7.4 46.4-33.7l-25-145.5 105.7-103c19-18.5 8.5-50.8-17.7-54.6L382 150.2 316.7 17.8c-11.7-23.6-45.6-23.9-57.4 0z'
            transform={`rotate(${rotateAngle} 288,256) translate(85,60) scale(0.7)`}
          ></SelectedIconPath>
        </SelectedIcon>
      )}

      {/* User Circle Icon - Gate_UP seat shape in SRO */}
      {!isSelected && !isDisabled && seatType && seatType.icon === 'user' && (
        <SelectedIcon width={width} height={height} viewBox={`0 0 512 512`}>
          <SelectedIconPath
            fill='#ffffff'
            fillOpacity={1}
            d='M248 104c-53 0-96 43-96 96s43 96 96 96 96-43 96-96-43-96-96-96zm0 144c-26.5 0-48-21.5-48-48s21.5-48 48-48 48 21.5 48 48-21.5 48-48 48zm0-240C111 8 0 119 0 256s111 248 248 248 248-111 248-248S385 8 248 8zm0 448c-49.7 0-95.1-18.3-130.1-48.4 14.9-23 40.4-38.6 69.6-39.5 20.8 6.4 40.6 9.6 60.5 9.6s39.7-3.1 60.5-9.6c29.2 1 54.7 16.5 69.6 39.5-35 30.1-80.4 48.4-130.1 48.4zm162.7-84.1c-24.4-31.4-62.1-51.9-105.1-51.9-10.2 0-26 9.6-57.6 9.6-31.5 0-47.4-9.6-57.6-9.6-42.9 0-80.6 20.5-105.1 51.9C61.9 339.2 48 299.2 48 256c0-110.3 89.7-200 200-200s200 89.7 200 200c0 43.2-13.9 83.2-37.3 115.9z'
            transform={`rotate(${rotateAngle} 256,256) translate(50,50) scale(0.8)`}
          ></SelectedIconPath>
        </SelectedIcon>
      )}

      {/* Tick Icon */}
      {isSelected && (
        <SelectedIcon width={width} height={height} viewBox={`0 0 30 30`}>
          <SelectedIconPath
            fill='currentColor'
            d='M20.285 2l-11.285 11.567-5.286-5.011-3.714 3.716 9 8.728 15-15.285z'
            transform={`rotate(${rotateAngle} 15, 15) translate(3, 3)`}
          />
        </SelectedIcon>
      )}
    </SeatWrapper>
  )
}

export const SeatMapSeat = React.memo(SeatMapSeatNM)
