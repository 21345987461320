import React from 'react'

//Components
import { Title } from 'marvel-components'

// Assets
import MaintenanceLogo from 'assets/lw_theatres_white_large.png'

// Misc
import styled from 'styled-components'
import { media } from 'utils'

const MaintenanceWrapper = styled.div`
  height: 100%;
  min-height: 100vh;
  width: 100%;
  background: #111111;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
`

const MaintenanceSplashWrapper = styled.div`
  width: 500px;
  min-height: 90vh;
  height: 100%;
  margin: 3rem 0 0 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  ${media.phone} {
    width: 90%;
    justify-content: flex-start;
  }
`
const MaintenanceSplashImage = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 0 0 0 0;
  ${media.phone} {
    width: 90%;
  }
`
const MaintenanceSplashImageImage = styled.img`
  width: 150px;
  margin: 0 0 3rem 0;
  ${media.phone} {
    width: 50%;
  }
`

const OfflineTextArea = styled.div``

const OfflineTitle = styled(Title)`
  color: #fff;
  line-height: 1.25;
`

const MaintenanceFooter = styled.div`
  margin: 5rem 0 0 0;
  padding: 0 0 2rem 0;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  font-size: 0.85rem;
  ${media.phone} {
    width: 90%;
  }
`

const SummaryFooter = styled.div`
  font-family: ${props => props.theme.font.copy};
  margin-top: 2rem;
  color: ${props => props.theme.colors.borderColor};
`

const MaintenanceContainer: React.FC = () => {
  return (
    <MaintenanceWrapper>
      <MaintenanceSplashWrapper>
        <MaintenanceSplashImage>
          <MaintenanceSplashImageImage src={MaintenanceLogo} />
        </MaintenanceSplashImage>
        <OfflineTextArea>
          <OfflineTitle size='large' isCapitalised={false} isBold={true}>
            <p>
              We are currently experiencing technical difficulties, if you are
              trying to make an Afternoon Tea booking, please email{' '}
              <a href='mailto:table.reservation@lwtheatres.co.uk'>
                table.reservation@lwtheatres.co.uk
              </a>
            </p>
            <p>Thank you for your understanding.</p>
          </OfflineTitle>
        </OfflineTextArea>

        <MaintenanceFooter>
          <SummaryFooter>LW Theatres © 2023</SummaryFooter>
        </MaintenanceFooter>
      </MaintenanceSplashWrapper>
    </MaintenanceWrapper>
  )
}

export { MaintenanceContainer }
