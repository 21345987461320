import { createAsyncThunk } from '@reduxjs/toolkit'
import contentfulApi from '../services/contentfulApi'
import { Faq } from '../shared/types'

type ContentfulFaq = {
  title: string
  description?: string
  questions: {
    fields: {
      question: string
      answer: string
    }
    sys: {
      id: string
    }
  }[]
}

export const fetchFaq = createAsyncThunk<Faq, undefined>(
  'marvel/manage-order/FETCH_FAQ',
  async (_, { rejectWithValue }) => {
    try {
      const faq = (await contentfulApi.fetchOne(
        process.env.REACT_APP_MANAGE_ORDER_FAQ_ENTRY_ID!,
        {
          include: 2
        }
      )) as ContentfulFaq

      if (!faq) {
        throw new Error('Failed to fetch the navigation list')
      }

      return {
        id: '1',
        title: faq.title,
        description: faq.description,
        questions: faq.questions.map(contentfulQuestion => ({
          id: contentfulQuestion.sys.id,
          question: contentfulQuestion.fields.question,
          answer: contentfulQuestion.fields.answer
        }))
      }
    } catch (err) {
      return rejectWithValue(err)
    }
  }
)
