import { configureStore } from '@reduxjs/toolkit'
import createSagaMiddleware from 'redux-saga'
import rootReducer, { rootSaga } from 'modules'
import { useDispatch } from 'react-redux'
import * as store from 'store'
//Error tracking
import * as Sentry from '@sentry/react'
import { getSession } from 'utils/session'

const sentryReduxEnhancer = Sentry.createReduxEnhancer({})

const sagaMiddleware = createSagaMiddleware()

const session = getSession()
const contextId = session?.contextId
const transactionId = session?.transactionId
const expiresAt = session?.expiresAt
const externalCustomerId: string | undefined = store.get('externalCustomerId')
const bookingFlow = session?.bookingFlow
const preloadedState = {
  basket: {
    contextId: contextId,
    externalCustomerId: externalCustomerId,
    transactionId: transactionId,
    expiresAt: expiresAt,
    bookingFlow: bookingFlow
  }
}

export const reduxStore = configureStore({
  reducer: rootReducer,
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware().concat(sagaMiddleware),
  devTools: process.env.NODE_ENV !== 'production',
  enhancers: [sentryReduxEnhancer],
  preloadedState: preloadedState
})
sagaMiddleware.run(rootSaga)

if (process.env.NODE_ENV !== 'production' && module.hot) {
  module.hot.accept(['../modules'], () => {
    reduxStore.replaceReducer(rootReducer)
  })
}

export type RootState = ReturnType<typeof reduxStore.getState>
export type AppDispatch = typeof reduxStore.dispatch
export const useAppDispatch = () => useDispatch<AppDispatch>()
