// Types
import { createSelector } from '@reduxjs/toolkit'
import { AppState } from 'modules/types'

// Adapters
import { menusAdapter } from './reducers'

export const {
  selectById,
  selectIds,
  selectEntities,
  selectAll,
  selectTotal
} = menusAdapter.getSelectors<AppState>(state => state.smartWaiter.menus)

export const selectIsMenuListLoading = (state: AppState) =>
  state.smartWaiter.menus.loading === 'pending'

export const selectMenuName = createSelector(selectById, menu => menu?.name)
