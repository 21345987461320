import Tippy from '@tippyjs/react'
import styled from 'styled-components/macro'

const ToolTip = styled(Tippy)<{ $unthemed?: boolean }>`
  display: ${props => (props.disabled ? 'none' : 'block')};
  position: relative;
  background-color: ${props => props.theme.colors.performanceHoverColor};
  background-color: ${props =>
    props.$unthemed ? '#fff' : props.theme.colors.performanceHoverColor};
  color: ${props => props.theme.colors.copyColor};
  border-radius: 0.2rem;
  font-family: ${props => props.theme.font.copy};
  font-size: 0.75rem;
  line-height: 1.4;
  outline: 0;
  transition-property: 'transform, visibility, opacity';
  box-shadow: 0 0 1.25rem rgba(0, 0, 0, 0.4);
  border: ${props => (props.$unthemed ? '1px solid #bababa' : 'none')};
  min-width: ${props => (props.$unthemed ? '345px' : 'none')};
  max-width: ${props => (props.$unthemed ? '345px' : 'none')};
  width: ${props => (props.$unthemed ? '345px' : 'none')};
  &[data-placement^='top'] > .tippy-arrow {
    bottom: 0;
    &::before {
      bottom: -7px;
      left: 0;
      border-width: 8px 8px 0;
      border-top-color: initial;
      transform-origin: center top;
    }
  }
  &[data-placement^='bottom'] > .tippy-arrow {
    top: 0;
    &::before {
      top: -7px;
      left: 0;
      border-width: 0 8px 8px;
      border-bottom-color: initial;
      transform-origin: center bottom;
    }
  }
  &[data-placement^='left'] > .tippy-arrow {
    right: 0;
    &::before {
      border-width: 8px 0 8px 8px;
      border-left-color: initial;
      right: -7px;
      transform-origin: center left;
    }
  }
  &[data-placement^='right'] > .tippy-arrow {
    left: 0;
    &::before {
      left: -7px;
      border-width: 8px 8px 8px 0;
      border-right-color: initial;
      transform-origin: center right;
    }
  }
  &[data-inertia][data-state='visible'] {
    transition-timing-function: cubic-bezier(0.54, 1.5, 0.38, 1.11);
  }
  &[data-animation='fade'][data-state='hidden'] {
    opacity: 0;
  }
  .tippy-arrow {
    width: 16px;
    height: 16px;
    color: ${props =>
      props.$unthemed ? '#fff' : props.theme.colors.performanceHoverColor};
    &::before {
      content: '';
      position: absolute;
      border-color: transparent;
      border-style: solid;
    }
  }
  .tippy-content {
    position: relative;
    padding: 5px 9px;
    z-index: 1;
  }
`

export { ToolTip }
