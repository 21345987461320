import React from 'react'
import styled, { css } from 'styled-components/macro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  library,
  IconPrefix,
  SizeProp
} from '@fortawesome/fontawesome-svg-core'
import { rotate } from 'utils'
import { IconName, PaddingOptions } from 'shared-types'
import {
  faAlignLeft,
  faList,
  faLock,
  faGhost,
  faChevronLeft,
  faChevronRight,
  faChevronDown,
  faChevronUp,
  faPlus,
  faMinus,
  faCircleNotch,
  faTimesCircle,
  faCheck,
  faGripLinesVertical,
  faRandom,
  faArrowLeft,
  faArrowRight,
  faSort,
  faCartPlus,
  faExclamationCircle,
  faTimes,
  faInfoCircle,
  faCheckCircle,
  faSearch,
  faTrashAlt,
  faPlay,
  faCalendarAlt,
  faChartBar,
  faExclamationTriangle,
  faUser,
  faInfo,
  faSignOutAlt,
  faWheelchair,
  faStar,
  faTicketAlt,
  faCreditCard,
  faExternalLinkAlt,
  faBell
} from '@fortawesome/free-solid-svg-icons'

import {
  faCircle,
  faQuestionCircle,
  faUserCircle,
  faPlayCircle
} from '@fortawesome/free-regular-svg-icons'

import {
  faTwitter,
  faFacebookF,
  faApple,
  faGoogle
} from '@fortawesome/free-brands-svg-icons'
interface Props {
  icon?: IconName
  padding?: PaddingOptions
  isCentre?: boolean
  isSpinning?: boolean
  prefix?: IconPrefix
  size?: SizeProp
}

library.add(
  faAlignLeft,
  faTwitter,
  faFacebookF,
  faList,
  faLock,
  faGhost,
  faChevronUp,
  faChevronLeft,
  faChevronRight,
  faChevronDown,
  faPlus,
  faMinus,
  faCircleNotch,
  faTimesCircle,
  faCheck,
  faGripLinesVertical,
  faRandom,
  faArrowLeft,
  faArrowRight,
  faSort,
  faCartPlus,
  faExclamationCircle,
  faTimes,
  faInfoCircle,
  faCheckCircle,
  faSearch,
  faTrashAlt,
  faPlay,
  faCalendarAlt,
  faChartBar,
  faExclamationTriangle,
  faUser,
  faInfo,
  faSignOutAlt,
  faWheelchair,
  faStar,
  faCircle,
  faTicketAlt,
  faApple,
  faGoogle,
  faCreditCard,
  faExternalLinkAlt,
  faQuestionCircle,
  faBell,
  faUserCircle,
  faPlayCircle
)

const IconWrapper = styled.div<{
  isCentre: boolean
  isSpinning: boolean
  padding: PaddingOptions
}>(props => {
  const { isCentre, isSpinning, padding } = props

  let paddingSize = '0.25rem'

  switch (padding) {
    case 'none':
      paddingSize = '0'
      break
    case 'small':
      paddingSize = '0.125rem'
      break
  }

  return css`
    margin-right: ${paddingSize};
    vertical-align: middle;
    pointer-events: none;

    ${isCentre &&
    css`
      position: absolute;
      left: 0;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
      margin: auto;
    `}

    path {
      pointer-events: none;
      fill: inherit;
    }

    svg {
      pointer-events: none;
      ${isSpinning &&
      css`
        animation: ${rotate} 1s linear infinite;
      `}
    }
  `
})

export const Icon = (props: Props) => {
  const {
    icon = 'ghost',
    isSpinning,
    padding = 'none',
    isCentre,
    prefix = 'fas',
    size = '1x'
  } = props

  return (
    <IconWrapper
      padding={padding}
      isCentre={isCentre !== undefined ? isCentre : false}
      isSpinning={isSpinning !== undefined ? isSpinning : false}
      {...props}
    >
      <FontAwesomeIcon
        size={size}
        icon={[prefix as IconPrefix, icon as IconName]}
        fixedWidth={true}
      />
    </IconWrapper>
  )
}
