import React, { useEffect } from 'react'

import styled from 'styled-components'
import {
  media,
  usePlatform,
  useIsNarrowViewport,
  trackHospitalityViewItem
} from 'utils'

// Components
import {
  Title,
  SelectableListItem,
  List,
  WithLoader,
  Button,
  Icon,
  ToolTip
} from 'marvel-components'

// Selectors
import { menuSelectors, menuActions } from 'modules/hospitality/menu'
import {
  reservationActions,
  reservationSelectors
} from 'modules/hospitality/reservation'
import { occasionSelectors } from 'modules/hospitality/occasion'

// Actions
import { resetHospitality } from 'modules/hospitality/shared'

// Misc
import { useSelector, useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'

// Types
import { AppState } from 'modules/types'
import { selectBookingFlow } from 'modules/basket/selectors'

const SelectMenuContainerWrapper = styled.div<{ platform?: string }>`
  box-sizing: border-box;
  padding: 1.5rem 0 2.5rem 0;
  ${media.tablet} {
    padding: 1rem 0 1.5rem 0;
    margin-top: 0;
    min-height: ${props => (props.platform === 'kiosk' ? '65vh' : 'auto')};
  }
`

const SelectMenuDetails = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0 2%;
  ${media.tablet} {
    padding: 0 3%;
  }
  ${media.phone} {
    padding: 0 5%;
  }
`

const SelectMenuHeader = styled.div`
  padding: 0 2%;
  display: flex;
  flex-direction: column;
  ${media.tablet} {
    padding: 0 3%;
  }
  ${media.phone} {
    padding: 0 5%;
  }
`
const OccasionDate = styled.span`
  font-family: ${props => props.theme.font.copy};
  font-size: 0.875rem;
`

const OccasionParty = styled.span`
  font-family: ${props => props.theme.font.copy};
  margin-top: 0.5rem;
  font-size: 0.875rem;
`

const OccasionDetailsEdit = styled(Button)`
  font-family: ${props => props.theme.font.copy};
  font-size: 0.75rem;
  margin-top: 0.5rem;
`

const SelectMenuListWrapper = WithLoader(
  styled.div<{ platform?: string }>`
    margin: 0 0 1rem 0;
    ${media.tablet} {
      margin-top: ${props => (props.platform === 'kiosk' ? '1.5rem' : '0')};
    }
  `
)

const InfoWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${props => props.theme.colors.copyColor};
  border-radius: 50%;
  fill: #ffffff;
  width: 1rem;
  height: 1rem;
  font-size: 0.6rem;
  line-height: 1rem;
  margin-left: 0.4rem;
`

const InfoTooltipContentWrapper = styled.p``

type Props = {
  siteId: string
  occasionId: string
  adults: number
  children: number
  selectedDateString?: string
  selectedTimeslot?: string
}

const SelectMenuContainer: React.FC<Props> = ({
  siteId,
  occasionId,
  adults,
  children,
  selectedDateString,
  selectedTimeslot
}) => {
  const dispatch = useDispatch()
  // Routing
  const history = useHistory()

  // Selectors
  const menus = useSelector(menuSelectors.selectAll)
  const isLoading = useSelector(menuSelectors.selectIsLoading)
  const selectedMenuId = useSelector(reservationSelectors.selectSelectedMenuId)
  const occasion = useSelector((state: AppState) =>
    occasionSelectors.selectById(state, occasionId)
  )
  const reservationDateTime = useSelector(
    reservationSelectors.selectSelectedDateAndTime
  )
  const platform = usePlatform()
  const isNarrowViewport = useIsNarrowViewport()
  const bookingFlow = useSelector(selectBookingFlow)

  // Actions
  useEffect(() => {
    if (selectedDateString && selectedTimeslot) {
      dispatch(
        menuActions.fetchMenusForSite({
          siteId,
          occasionId,
          date: selectedDateString,
          timeslot: selectedTimeslot,
          adults: adults,
          ...(children > 0 && { children: children })
        })
      )
    }
  }, [
    dispatch,
    siteId,
    occasionId,
    selectedDateString,
    selectedTimeslot,
    adults,
    children
  ])

  // Analytics
  useEffect(() => {
    menus.forEach(menu => {
      trackHospitalityViewItem({
        platform,
        bookingFlow,
        item: {
          id: menu.id,
          name: menu.name,
          datetime: reservationDateTime.utc().format('YYYY-MM-DD/HH-mm')
        }
      })
    })
  }, [bookingFlow, menus, reservationDateTime, platform])

  const handleSelectMenu = (menuId: EntityId) => {
    dispatch(reservationActions.selectMenu(menuId))
  }
  const handelUpdateDetails = () => {
    dispatch(resetHospitality())
    history.push(
      `/hospitality/site/${siteId}/occasion/${occasionId}/select-date`
    )
  }

  return (
    <SelectMenuContainerWrapper platform={platform}>
      <SelectMenuHeader>
        <Title
          isCapitalised={false}
          isCentralised={false}
          isBold={true}
          size={platform === 'kiosk' && isNarrowViewport ? 'large' : 'medium'}
        >
          {occasion && occasion.name}
        </Title>
        <OccasionDate>
          {reservationDateTime.utc().format('dddd Do MMM YYYY, HH:mm')}
        </OccasionDate>
        <OccasionParty>{`Adults: ${adults}`}</OccasionParty>
        {children > 0 && (
          <OccasionParty>{`Children: ${children}`}</OccasionParty>
        )}
        <OccasionDetailsEdit
          buttonType='link-button'
          isUnderlined
          onClick={handelUpdateDetails}
        >
          Change details
        </OccasionDetailsEdit>
      </SelectMenuHeader>
      <SelectMenuDetails>
        <Title
          isCapitalised={false}
          isCentralised={false}
          isBold={true}
          size={platform === 'kiosk' && isNarrowViewport ? 'medium' : 'small'}
        >
          Please click on your choice of menu below
        </Title>
        {menus.length > 1 && (
          <ToolTip
            delay={250}
            $unthemed={true}
            content={
              <InfoTooltipContentWrapper>
                Please note, you will only be able to select one menu choice for
                your whole party
              </InfoTooltipContentWrapper>
            }
          >
            <InfoWrapper>
              <Icon icon={'info'} />
            </InfoWrapper>
          </ToolTip>
        )}
      </SelectMenuDetails>
      <SelectMenuListWrapper isLoading={isLoading} platform={platform}>
        <List>
          {menus.map(
            menu =>
              menu.available && (
                <SelectableListItem
                  key={menu.id}
                  id={menu.id}
                  title={menu.name}
                  isDisabled={!menu.available}
                  description={menu.description}
                  adultPrice={menu.depositPerAdult}
                  childPrice={menu.depositPerChild}
                  onSelect={handleSelectMenu}
                  isSelected={menu.id === selectedMenuId}
                  largeIcon={true}
                  stacked={true}
                  imageUrl={menu.imageUrl || undefined}
                />
              )
          )}
        </List>
      </SelectMenuListWrapper>
    </SelectMenuContainerWrapper>
  )
}

export { SelectMenuContainer }
