import { AppState } from 'modules/types'
import { getEntity, selectById, selectId } from '../entities'

import { createSelector } from '@reduxjs/toolkit'

// Types
import { Area, Sector, Row, SeatingType, Seat } from 'shared-types'
import { availabilitySelectors } from 'modules/ticketing/availability/availabilitySlice'

// // New Selectors
import {
  selectActivePerformanceId,
  selectPriceLevelEntities,
  selectSeatTypeEntities,
  selectSelectedPriceLevelIds
} from 'modules/ticketing/performance/selectors'

export const selectSeatMapId = (state: AppState) => state.seatMap.byId
export const selectAllSeatMaps = (state: AppState) => state.entities.seatMap

export const selectSeatMap = createSelector(
  [selectSeatMapId, selectAllSeatMaps],
  (id, seatMaps) => (id ? seatMaps[id] : undefined)
)
export const selectAllAreas = (state: AppState) => state.entities.areas
export const selectAreas = createSelector(
  [selectSeatMap, selectAllAreas],
  (seatMap, areas) => seatMap?.areas.map(areaId => areas[areaId] as Area)
)

export const selectAllRows = (state: AppState) => state.entities.rows

export const selectAllSeatTypes = (state: AppState) =>
  selectSeatTypeEntities(state)

export const getIsLoading = (state: AppState) => state.seatMap.loading
export const getIsLoaded = (state: AppState) => !!state.seatMap.lastFetch

export const getArea = (state: AppState, areaId: string) =>
  getEntity<Area>(state.entities.areas, areaId)

export const getAreaTitle = (state: AppState, areaId: string) =>
  getArea(state, areaId)?.name

export const getSector = (state: AppState, sectorId: string) =>
  getEntity<Sector>(state.entities.sectors, sectorId)

export const getRow = (state: AppState, rowId: string) =>
  getEntity<Row>(state.entities.rows, rowId)

export const selectSeatEntities = (state: AppState) =>
  state.entities.seats ?? {}

export const selectAllSeats = createSelector(
  selectSeatEntities,
  seats => Object.values(seats) as Seat[]
)

export const getSeat = createSelector(selectSeatEntities, selectId, selectById)

export const getRowName = (state: AppState, rowId: string) =>
  getRow(state, rowId)?.name

export const getImages = (state: AppState) => state.entities.images

export const getViewFromSeatUrl = createSelector(
  [getSeat, getImages],
  (seat, images) =>
    seat && seat?.viewFromSeatImageId
      ? images[seat.viewFromSeatImageId]?.url
      : undefined
)

export const getSeatPriceLevelGuid = (state: AppState, seatId: string) =>
  getSeat(state, seatId)?.priceLevelGuid

export const getFirstSeatWidth = createSelector(
  [selectAllSeats, selectId],
  (seats, areaId) => seats.find(seat => seat.areaId === areaId)?.width ?? 0
)

export const getSeatingType = (state: AppState): SeatingType | undefined => {
  const areas = selectSeatMap(state)?.areas || []
  if (areas.length > 0) return getArea(state, areas[0])?.seatingType
}

export const selectBestAreaForPriceLevels = createSelector(
  [
    selectSelectedPriceLevelIds,
    selectSeatEntities,
    availabilitySelectors.selectEntities,
    selectSeatMap
  ],
  (priceLevelIds, seats, availability, seatMap) => {
    if (seats && availability && priceLevelIds && seatMap) {
      const availableSeats = Object.values(seats)
        .filter(
          seat =>
            seat &&
            availability[seat.seatId]?.status === 1 &&
            priceLevelIds.includes(seat.priceLevelGuid)
        )
        .reduce((acc, cur) => {
          cur &&
            (acc[cur.areaId] = (acc[cur.areaId] || (acc[cur.areaId] = 1)) + 1)
          return acc
        }, {} as { [key: string]: number })
      return Object.keys(availableSeats).length > 0
        ? Object.keys(availableSeats).reduce((a, b) =>
            availableSeats[a] > availableSeats[b] ? a : b
          )
        : seatMap.areas[0]
    }
  }
)

export const selectSeatWithTickets = createSelector(
  [
    getSeat,
    selectAllSeatTypes,
    selectAllRows,
    selectAllAreas,
    selectPriceLevelEntities,
    selectActivePerformanceId
  ],
  (seat, seatTypes, rows, areas, priceLevels, performanceId) => {
    if (seat && rows && priceLevels && areas && seatTypes) {
      const row = rows[seat.rowGuid]
      const priceLevel = priceLevels[seat.priceLevelGuid]
      const area = areas[seat.areaId]
      const seatType = seatTypes[seat.seatTypeGuid]
      if (row && priceLevel && area && performanceId) {
        return {
          ...seat,
          rowName: row.name,
          seatName: seat.name,
          areaName: area.name,
          priceLevel: priceLevel,
          seatType: seatType,
          performanceId: performanceId
        }
      }
    }
  }
)
