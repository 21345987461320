import React from 'react'
import styled from 'styled-components/macro'

// Modules
import { closeModal, openModal } from 'modules/overlay/actions'
import { authActions } from 'modules/ticketing/auth/authSlice'
import { bannerActions } from 'modules/banner'

// Components
import {
  Title,
  Button,
  CopyBlock,
  LoginForm,
  ForgottenPasswordForm
} from 'marvel-components'

// Types
import { LoginFormValues, ForgottenPasswordFormValues } from 'marvel-components'

// Misc
import { useAppDispatch } from 'app/reduxStore'
import { identifyCustomer, media } from 'utils'
import lwLogo from 'assets/lw_theatres_black.png'

export interface LoginProps {
  error?: string
  isForgottenPassword?: boolean
}

const LoginOverlayWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 2rem;
  max-width: 25rem;
  min-width: 25rem;

  ${media.phone} {
    padding: 1.5rem;
    max-width: 15rem;
    min-width: 15rem;
  }
`

const LWTheatreLogoWrapper = styled.div`
  margin: 0 auto 1rem auto;
  width: 5rem;
`

const LogoImage = styled.img`
  width: 100%;
`

const FormArea = styled.div`
  margin-top: 1rem;
`

const LoginContainer: React.FC<LoginProps> = ({
  error,
  isForgottenPassword
}) => {
  const dispatch = useAppDispatch()

  const handleLoginSubmit = async (loginValues: LoginFormValues) => {
    identifyCustomer(loginValues.login)
    const actionResult = await dispatch(authActions.login(loginValues))
    if (authActions.login.fulfilled.match(actionResult)) {
      dispatch(closeModal())
    }
  }

  const handleForgottenPasswordSubmit = async (
    forgottenPasswordValues: ForgottenPasswordFormValues
  ) => {
    const actionResult = await dispatch(
      authActions.forgotPassword(forgottenPasswordValues)
    )
    if (authActions.forgotPassword.fulfilled.match(actionResult)) {
      dispatch(
        bannerActions.setBannerContent({
          bannerType: 'information',
          title: 'Password reset sent',
          text: `We've sent you a link to reset your password.`
        })
      )
      dispatch(closeModal())
    }
  }

  const handleForgottenPassword = () => {
    dispatch(
      openModal({
        variant: 'login',
        props: {
          isForgottenPassword: true
        }
      })
    )
  }

  const handleReturnToLogin = () => {
    dispatch(
      openModal({
        variant: 'login',
        props: {}
      })
    )
  }

  const handleCheckoutAsGuestClick = () => dispatch(closeModal())

  return (
    <LoginOverlayWrapper>
      <LWTheatreLogoWrapper>
        <LogoImage src={lwLogo} alt='LW Theatres' />
      </LWTheatreLogoWrapper>
      <Title
        isCapitalised={false}
        size='medium'
        isBold={true}
        isMultiline={true}
      >
        {isForgottenPassword &&
          `Forgot your password? Enter your email address below and you'll be sent a link to reset it.`}
        {!isForgottenPassword && `Login to your account`}
      </Title>
      {error && (
        <CopyBlock isCentralised={true} isAlert>
          {error}
        </CopyBlock>
      )}
      <FormArea>
        {!isForgottenPassword && (
          <LoginForm
            onSubmit={handleLoginSubmit}
            onForgottenPassword={handleForgottenPassword}
          />
        )}
        {isForgottenPassword && (
          <ForgottenPasswordForm
            onSubmit={handleForgottenPasswordSubmit}
            onReturnToLogin={handleReturnToLogin}
          />
        )}
      </FormArea>
      <CopyBlock textSize='extra-small'>
        {`No account? `}
        <Button
          text='Checkout as guest'
          buttonType='link-button'
          size='extra-small'
          onClick={handleCheckoutAsGuestClick}
          isUnderlined
        />
        {` and create an account later`}
      </CopyBlock>
    </LoginOverlayWrapper>
  )
}

export { LoginContainer }
