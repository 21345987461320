import React, { useRef, useEffect } from 'react'

// Actions
import { closeModal } from 'modules/overlay/actions'

// Utils
import { useDispatch } from 'react-redux'
import styled from 'styled-components/macro'
import AdyenCheckout from '@adyen/adyen-web'

// Types
import { PaymentChallengeResponse, PaymentChallenge } from 'shared-types'

export interface ThreeDSOverlayProps {
  onAdditionalDetails?: (state: PaymentChallengeResponse) => void
  challenge: PaymentChallenge
}

const ActionContainer = styled.div`
  width: 100%;
  height: 100%;
  .adyen-checkout__threeds2__challenge {
    width: 100%;
    height: 100%;
  }
`

const ThreeDSOverlayContainer: React.FC<ThreeDSOverlayProps> = ({
  onAdditionalDetails,
  challenge
}) => {
  const dispatch = useDispatch()
  const challengeContainer = useRef<any>()
  const adyenCheckout = useRef<any>()

  useEffect(() => {
    if (challenge) {
      const handleOnAdditionalDetails = (
        state: { data: PaymentChallengeResponse },
        component: any
      ) => {
        onAdditionalDetails && onAdditionalDetails(state.data)
        dispatch(closeModal())
      }
      const configuration = {
        locale: 'en_US',
        environment: process.env.REACT_APP_ADYEN_ENVIRONMENT,
        clientKey: process.env.REACT_APP_ADYEN_CLIENT_KEY,
        onAdditionalDetails: handleOnAdditionalDetails
      }
      adyenCheckout.current = new AdyenCheckout(configuration)
      const threeDSConfiguration = {
        challengeWindowSize: '04'
      }

      adyenCheckout.current
        .createFromAction(challenge, threeDSConfiguration)
        .mount(challengeContainer.current)
    }
  }, [dispatch, onAdditionalDetails, challenge])

  return <ActionContainer ref={challengeContainer} />
}

export { ThreeDSOverlayContainer }
