import React from 'react'
import styled from 'styled-components'

//Form
import { Form, Field, Formik, FieldArray, FormikHelpers } from 'formik'

//Components
import {
  Button,
  FormCheckbox,
  Title,
  CopyBlock,
  Expander,
  FormWrapper,
  FormTextArea,
  Link,
  SegmentedControl,
  FormRadio
} from 'marvel-components'

import { PerformanceListForm } from './PerformanceListForm'

// Misc
import * as Yup from 'yup'

//Types
import { Performance } from 'shared-types'

export interface ExchangeFormValues {
  noOptions: boolean
  comments: string
  refundRequested: boolean
  voucherRequested: boolean
  performanceIds: string[]
  refundType: string
}

type Props = {
  performances: Performance[]
  showName?: string
  availabilityThreshold?: number
  onSubmit: (
    values: ExchangeFormValues,
    actions: FormikHelpers<ExchangeFormValues>
  ) => void
}

const ExchangeFormSection = styled.div`
  margin: 1.5rem 0 0 0;
`

const NoAlternativeDate = styled.div`
  margin-top: 2rem;
`

const ExchangeRefundRadioWrapper = styled.div`
  margin-top: 1rem;
`

const ExchangeRefundRadioNotes = styled.div`
  margin-bottom: 1.5rem;
`

const ExchangeFormAlternativeOptionWrapper = styled.div`
  font-family: ${props => props.theme.font.copy};
  display: flex;
  flex-direction: column;
  margin: 1rem 0 0 0;
  align-items: flex-start;
  position: relative;
`

const ExchangeFormValidator = Yup.object().shape({
  refundRequested: Yup.boolean(),
  refundType: Yup.string().when(['refundRequested'], {
    is: true,
    then: Yup.string()
      .oneOf(['newCard', 'originalCard'])
      .required(
        'Please confirm if you would like a refund to your original card.'
      )
  })
})

const ExchangeForm: React.FC<Props> = ({
  performances,
  onSubmit,
  showName,
  availabilityThreshold = 200
}) => {
  const initialValues: ExchangeFormValues = {
    noOptions: false,
    refundType: '',
    comments: '',
    refundRequested: false,
    voucherRequested: false,
    performanceIds: []
  }

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={ExchangeFormValidator}
    >
      {({ isSubmitting, values, submitForm, setFieldValue }) => {
        const handleRefundClick = () => {
          submitForm()
        }
        const handleRefundOptionChange = () => {
          values.voucherRequested
            ? setFieldValue('voucherRequested', false, false)
            : setFieldValue('voucherRequested', true, false)
        }

        return (
          <FormWrapper>
            <Form>
              <ExchangeFormSection>
                <Title
                  isCapitalised={false}
                  isCentralised={false}
                  isBold={true}
                  size='medium'
                >
                  I wish to apply for:
                </Title>
                <SegmentedControl
                  isReserving={false}
                  isStacked={true}
                  items={[
                    {
                      id: '1',
                      name: 'Exchange for New Performance',
                      icon: 'calendar-alt'
                    },
                    {
                      id: '2',
                      name: 'Credit Voucher',
                      icon: 'ticket-alt'
                    }
                  ]}
                  onSelectItem={handleRefundOptionChange}
                  selectedItemId={values.voucherRequested ? '2' : '1'}
                />
                {!values.voucherRequested ? (
                  <ExchangeFormSection>
                    <Title
                      isCapitalised={false}
                      isCentralised={false}
                      isBold={true}
                      size='medium'
                    >
                      Alternative date options
                    </Title>
                    <CopyBlock>
                      <p>
                        To help you with your ticket exchange, we have
                        identified performances that have tickets available that
                        are similar to your original booking. Please select up
                        to 3 performances that you would like to exchange your
                        tickets into.
                      </p>
                    </CopyBlock>
                    {!values.noOptions && (
                      <FieldArray
                        name='performanceIds'
                        render={arrayHelpers => {
                          return (
                            <PerformanceListForm
                              performances={performances}
                              arrayHelpers={arrayHelpers}
                              availabilityThreshold={availabilityThreshold}
                            />
                          )
                        }}
                      />
                    )}
                    <NoAlternativeDate>
                      <Field
                        name='noOptions'
                        label={`None of these alternatives work for me - tick this box and click the 'Submit form' button below. A member of our customer relations team will be in contact with you within 10 working days to help you pick a new date.`}
                        isLabelVisible={true}
                        size={'medium'}
                        component={FormCheckbox}
                        isChecked={values.noOptions}
                      />
                    </NoAlternativeDate>
                  </ExchangeFormSection>
                ) : (
                  <ExchangeFormSection>
                    <Title
                      isCapitalised={false}
                      isCentralised={false}
                      isBold={true}
                      size='medium'
                    >
                      {`${showName} Credit Voucher`}
                    </Title>
                    <CopyBlock>
                      <p>
                        We will email you a voucher for the full value of your
                        booking, which you’ll be able to use whenever you’re
                        ready. Vouchers are valid for 24 months and redeemable
                        via our website.
                      </p>
                      <p>
                        If this option works best for you at the moment, click
                        the 'Submit Form' button below.
                      </p>
                    </CopyBlock>
                  </ExchangeFormSection>
                )}
              </ExchangeFormSection>

              <ExchangeFormSection>
                <Title
                  isCapitalised={false}
                  isCentralised={false}
                  isBold={true}
                  size='medium'
                >
                  Additional comments
                </Title>
                <CopyBlock>
                  <p>
                    Please include any comments or information about your
                    booking that we should be aware of, such as access
                    requirements, specific seat requirements, etc.
                  </p>
                </CopyBlock>
                <FormTextArea name='comments' />
              </ExchangeFormSection>
              {!values.voucherRequested ? (
                <Link
                  text={`Ticket Exchange Terms and Conditions`}
                  href='https://lwtheatres.co.uk/terms-conditions/'
                  target='_blank'
                  isUnderlined={true}
                  size={'extra-small'}
                />
              ) : null}
              <ExchangeFormSection>
                <Title
                  isCapitalised={false}
                  isCentralised={false}
                  isBold={true}
                  size='medium'
                >
                  Submit your request
                </Title>
                <CopyBlock>
                  <p>
                    A member of the LW customer relations team will be in touch
                    to finalise your request.
                  </p>
                </CopyBlock>
                <Button
                  type='submit'
                  isBlock={true}
                  text='Submit Form'
                  isLoading={isSubmitting}
                  isDisabled={isSubmitting}
                />
              </ExchangeFormSection>
              <ExchangeFormSection>
                <ExchangeFormAlternativeOptionWrapper>
                  <Field
                    name='refundRequested'
                    label={` I am unable to attend any future performance and would like to request a refund`}
                    isLabelVisible={true}
                    size={'large'}
                    component={FormCheckbox}
                    isChecked={values.refundRequested}
                  />
                  <Expander isExpanded={values.refundRequested}>
                    <ExchangeRefundRadioWrapper>
                      <FormRadio
                        name='refundType'
                        value='originalCard'
                        label={`Please issue a full refund to the card used to make my ticket purchase.`}
                      />
                      <FormRadio
                        name='refundType'
                        value='newCard'
                        label={`I would like a refund, but need to be contacted as the Bank or Credit Card account I used to make the booking is now closed or I booked part with vouchers and card.*`}
                      />
                    </ExchangeRefundRadioWrapper>
                    <ExchangeRefundRadioNotes>
                      <CopyBlock textSize='extra-small'>
                        <p>
                          *Please do not write down any account details on this
                          form. A member of our customer services team will be
                          in touch to arrange your refund. Please only select
                          this option if your account is no longer open. If your
                          account is still open, but the card has expired or
                          been lost or stolen, we will still be able to refund
                          you automatically.
                        </p>
                      </CopyBlock>
                    </ExchangeRefundRadioNotes>
                    <Button
                      onClick={handleRefundClick}
                      isBlock={true}
                      text='Request Refund'
                      isLoading={isSubmitting}
                      isDisabled={isSubmitting}
                    />
                  </Expander>
                </ExchangeFormAlternativeOptionWrapper>
              </ExchangeFormSection>
            </Form>
          </FormWrapper>
        )
      }}
    </Formik>
  )
}

export { ExchangeForm }
