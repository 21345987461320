// Misc
import { createSlice, createEntityAdapter } from '@reduxjs/toolkit'

// Actions
import { fetchVenues } from 'modules/smart-waiter/venue/actions'
import { fetchMenus } from 'modules/smart-waiter/menu/actions'

// Types
import { Service } from 'shared-types'

export const servicesAdapter = createEntityAdapter<Service>({
  sortComparer: (a, b) => a.name.localeCompare(b.name)
})

const servicesSlice = createSlice({
  name: 'services',
  initialState: servicesAdapter.getInitialState({
    loading: 'idle'
  }),
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchVenues.pending, (state, action) => {
      state.loading = 'pending'
    })
    builder.addCase(fetchVenues.fulfilled, (state, action) => {
      if (action.payload.services) {
        servicesAdapter.upsertMany(state, action.payload.services)
      }
      state.loading = 'idle'
    })
    builder.addCase(fetchMenus.fulfilled, (state, action) => {
      if (action.payload.menus) {
        const service = state.entities[action.meta.arg.serviceId]
        if (service) {
          service.menus = Object.keys(action.payload.menus)
        }
      }
    })
  }
})

export default servicesSlice.reducer
