import { call, put, select, actionChannel, take } from 'redux-saga/effects'
import { bannerActions } from 'modules/banner'
import { apiRequest } from './actions'
import { AppState } from 'modules/types'

// Fetch Entity non sequentially - don't queue

export function* fetchEntityNonBlocking(entity: any, apiFn: any, action: any) {
  // NOTE: basketSelector.getContextId causes a circular dependency and this module to fail...
  const contextId = yield select((state: AppState) => state.basket.contextId)
  const accessToken = yield select(
    (state: AppState) => state.selfServe.kiosk.accessToken
  )
  yield put(entity.request({ ...action.payload }))
  const apiPayload =
    action.payload &&
    (action.payload.data || action.payload.values || action.payload)
  const { response, error } = yield call(
    apiFn,
    { ...apiPayload },
    contextId,
    accessToken
  )

  if (response) {
    yield put(entity.success({ response, ...action.payload }))

    // Call success callback
    action.payload.onSuccessCallback && action.payload.onSuccessCallback()
  } else {
    yield put(entity.failure({ error, ...action.payload }))

    const { message } = error

    yield put(
      bannerActions.setBannerContent({
        bannerType: 'error',
        title: 'Please Note',
        text: message
      })
    )
    action.payload.onErrorCallback && action.payload.onErrorCallback(message)
  }
  //Handle form request
  action.payload.setSubmitting && action.payload.setSubmitting(false)
  yield put(entity.fulfill({ ...action.payload }))
}

// Fetch Entity
// -------------------------------------------------------------
export function* fetchEntity(entity: any, apiFn: any, action: any) {
  yield put(
    apiRequest({
      entity,
      apiFn,
      action
    })
  )
}

// Create Entity
// -------------------------------------------------------------
export function* createEntity(entity: any, apiFn: any, action: any) {
  yield put(
    apiRequest({
      entity,
      apiFn,
      action
    })
  )
}

// Update Entity
// -------------------------------------------------------------
export function* updateEntity(entity: any, apiFn: any, action: any) {
  yield put(
    apiRequest({
      entity,
      apiFn,
      action
    })
  )
}

// Submit Form
// -------------------------------------------------------------
export function* submitForm(entity: any, apiFn: any, action: any) {
  yield put(
    apiRequest({
      entity,
      apiFn,
      action
    })
  )
}

// Fetch Auth
// -------------------------------------------------------------
export function* fetchAuth(entity: any, apiFn: any, action: any) {
  const contextId = yield select((state: AppState) => state.basket.contextId)
  const accessToken = yield select(
    (state: AppState) => state.selfServe.kiosk.accessToken
  )
  yield put(entity.request({ ...action.payload }))

  const { response, error } = yield call(
    apiFn,
    { ...action.payload },
    contextId,
    accessToken
  )

  if (response) {
    yield put(entity.success({ response, ...action.payload }))

    action.payload.onSuccessCallback &&
      action.payload.onSuccessCallback(response)
  } else {
    yield put(entity.failure({ error, ...action.payload }))

    // const { message } = error

    action.payload.onErrorCallback && action.payload.onErrorCallback()
  }
}

// API Channel
// --------------------------------------------------------------------
export function* watchRequests() {
  // 1- Create a channel for request actions
  const requestChan = yield actionChannel('API_REQUEST')
  while (true) {
    // 2- take from the channel
    const { payload } = yield take(requestChan)
    // 3- Note that we're using a blocking call
    yield call(handleRequest, payload)
  }
}

function* handleRequest(payload: any) {
  const { entity, apiFn, action } = payload
  const contextId = yield select((state: AppState) => state.basket.contextId)
  const accessToken = yield select(
    (state: AppState) => state.selfServe.kiosk.accessToken
  )
  const externalCustomerId = yield select(
    (state: AppState) => state.basket.externalCustomerId
  )

  yield put(entity.request({ ...action.payload }))
  const apiPayload =
    action.payload &&
    (action.payload.data || action.payload.values || action.payload)
  const { response, error } = yield call(
    apiFn,
    { ...apiPayload },
    contextId,
    accessToken,
    externalCustomerId
  )

  if (response) {
    yield put(entity.success({ response, ...action.payload }))

    // Call success callback
    action.payload.onSuccessCallback && action.payload.onSuccessCallback()
  } else {
    yield put(entity.failure({ error, ...action.payload }))

    const { message } = error

    yield put(
      bannerActions.setBannerContent({
        bannerType: 'error',
        title: 'Please Note',
        text: message
      })
    )
    action.payload.onErrorCallback && action.payload.onErrorCallback(message)
  }
  //Handle form request
  action.payload.setSubmitting && action.payload.setSubmitting(false)
  yield put(entity.fulfill({ ...action.payload }))
}
