// Components
import { BookingFooter, PaymentTerminalNotice } from 'marvel-components'

// Actions
import { kioskActions } from 'modules/self-serve/kiosk/kioskSlice'

// Selectors
import { selectPaymentRequestStatus } from 'modules/self-serve/kiosk/selectors'

// Utils
import { useAppDispatch } from 'app/reduxStore'
import moment from 'moment'
import React from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import { useInterval } from 'utils/useInterval'

const PaymentTerminalNoticeWrapper = styled.div`
  padding: 1.5rem 1.5rem 1.5rem 1.5rem;
`

type Props = {
  onPaymentComplete: () => void
  onPaymentFailure: () => void
}

const KioskPaymentContainer: React.FC<Props> = ({
  onPaymentComplete,
  onPaymentFailure
}) => {
  const dispatch = useAppDispatch()

  const paymentStatus = useSelector(selectPaymentRequestStatus)

  useInterval(() => {
    dispatch(kioskActions.fetchPaymentStatus())
  }, moment.duration().add(5, 'seconds'))

  if (paymentStatus === 'fulfilled') {
    dispatch(kioskActions.clearPaymentStatus())
    onPaymentComplete()
  }

  if (paymentStatus === 'rejected') {
    dispatch(kioskActions.clearPaymentStatus())
    onPaymentFailure()
  }

  return (
    <PaymentTerminalNoticeWrapper>
      <PaymentTerminalNotice />
      <BookingFooter />
    </PaymentTerminalNoticeWrapper>
  )
}

export { KioskPaymentContainer }
