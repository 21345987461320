// Components
import { Tile } from 'marvel-components'

// Selectors
import { venueSelectors } from 'modules/self-serve/venue'
import { useSelector } from 'react-redux'

// Misc
import React from 'react'
import styled from 'styled-components'
import { compareNumbers } from 'utils/sorting'
import { useHistory } from 'react-router-dom'
import { venueHome } from 'utils'
import { useAppDispatch } from 'app/reduxStore'
import { openModal } from 'modules/overlay/actions'

const GridWrapper = styled.div<{
  gridGap?: number
  columnCount?: number
  columnWidth: number
}>`
  width: 848px;
  display: grid;
  grid-template-columns: repeat(
    ${props => props.columnCount ?? 'auto-fit'},
    ${props => props.columnWidth}px
  );
  grid-gap: ${props => props.gridGap ?? 16}px;
  justify-content: center;
  margin: 0 auto;
  padding-top: 2rem;
`

type Props = {
  columnCount?: number
  tileSize?: number
  tileGap?: number
}

const VenueTileGridContainer: React.FC<Props> = ({
  columnCount,
  tileSize = 256,
  tileGap = 40
}) => {
  const history = useHistory()
  const venues = useSelector(venueSelectors.selectVenues)
  const dispatch = useAppDispatch()

  const viewVenueHome = (venueId?: string) => {
    if (venueId === '2Mywf3K1AN8eaYlCuJeO9s') {
      history.push(venueHome(venueId))
    } else {
      venueId &&
        dispatch(
          openModal({
            variant: 'alternative-venue',
            props: {
              venueId
            }
          })
        )
    }
  }

  return (
    <GridWrapper
      gridGap={tileGap}
      columnCount={columnCount}
      columnWidth={tileSize}
    >
      {venues
        .sort((a, b) => compareNumbers(a.order, b.order))
        .map((venue, i) => (
          <Tile
            key={i}
            size={tileSize}
            label={venue.title}
            borderColor={venue.accentColor}
            iconUrl={venue.logo}
            backgroundUrl={venue.pattern}
            value={venue.id}
            onClick={viewVenueHome}
          />
        ))}
    </GridWrapper>
  )
}

export { VenueTileGridContainer }
