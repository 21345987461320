import React, { useContext } from 'react'
import ReactAsyncSelect from 'react-select/async'
import { ValueType, ActionMeta } from 'react-select'
import { ThemeContext } from 'styled-components'
import { getCustomStyles } from './Select'

interface AsyncSelectOption {
  label: string
  value: any
}

export interface AsyncSelectProps {
  defaultOptions?: AsyncSelectOption[]
  placeholder?: string

  onChange: (value: ValueType<AsyncSelectOption, false>) => void
  loadOptions: (inputValue: string, callback: any) => void

  onBlur?: () => void

  defaultMenuIsOpen?: boolean
  isInvalid?: boolean
}

export const AsyncSelect = (props: AsyncSelectProps) => {
  const {
    defaultOptions,
    placeholder,
    onChange,
    loadOptions,
    defaultMenuIsOpen = false,
    isInvalid = false,
    onBlur
  } = props
  const theme = useContext(ThemeContext)
  const handleOnChange = (
    value: ValueType<AsyncSelectOption, false>,
    action: ActionMeta<AsyncSelectOption>
  ) => {
    if (action.action === 'select-option') {
      onChange && onChange(value)
    }
  }
  return (
    <ReactAsyncSelect
      onChange={handleOnChange}
      onBlur={() => onBlur && onBlur()}
      isInvalid={isInvalid}
      defaultOptions={defaultOptions}
      defaultMenuIsOpen={defaultMenuIsOpen}
      placeholder={placeholder}
      loadOptions={loadOptions}
      isClearable={true}
      isMulti={false}
      styles={getCustomStyles(theme)}
    />
  )
}
