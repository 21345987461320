import React from 'react'
import styled from 'styled-components/macro'

// Components
import { Title, UpsellBanner, CopyBlock } from 'marvel-components'

// Containers
import { ProductItemContainer } from './ProductItemContainer'

// Modules
import { selectArePromptsAvailable } from 'modules/prompt/selectors'

// Selectors
import { selectFilteredProducts } from 'modules/prompt/selectors'

// Misc
import { useSelector } from 'react-redux'
import { media, usePlatform } from 'utils'
import NoProducts from 'assets/no-products.jpg'

interface Props {
  platform?: string
}

const ProductListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  box-sizing: border-box;
  background: ${props => props.theme.colors.secondaryBackgroundColor};
`

const ProductsWrapper = styled.div<{ platform?: string }>`
  margin-top: 1rem;
  padding-bottom: 1rem;
  margin-bottom: 1.5rem;
  border-bottom: 0.0625rem solid ${props => props.theme.colors.borderColor};
  display: flex;
  flex-wrap: wrap;

  ${media.tablet} {
    flex-direction: column;
  }
  ${media.phone} {
    margin-top: 0.5rem;
  }
`

const StyledTitle = styled(Title)`
  scroll-margin-top: 4rem;

  ${media.phone} {
    scroll-margin-top: 6.5rem;
  }
`

const TitleBlock = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 1.5rem;
`

const PostPurchaseProductsContainer: React.FC<Props> = () => {
  const platform = usePlatform()

  // Selectors
  const filteredProducts = useSelector(selectFilteredProducts)
  const productsAvailable = useSelector(selectArePromptsAvailable)

  const handleOurVenuesClick = () => {
    window.open('https://lwtheatres.co.uk/#theatres', '_blank')
  }

  return (
    <ProductListWrapper>
      <StyledTitle
        id='upgrade-order'
        isCapitalised={false}
        isCentralised={false}
        isBold={true}
        size='large'
      >
        Upgrade Your Order
      </StyledTitle>

      {!productsAvailable && (
        <UpsellBanner
          bannerTitle={'There aren’t any upgrades available right now'}
          bannerDescription={
            'You can read more about what our venues have to offer by clicking the button below'
          }
          bannerCta={'Our Venues'}
          onBannerClick={handleOurVenuesClick}
          bannerImage={NoProducts}
          backgroundColor={'#000'}
          buttonColor={'#fff'}
          buttonCopyColor={'#000'}
          copyColor={'#fff'}
        />
      )}
      {filteredProducts.hospitality.length > 0 && (
        <>
          <TitleBlock>
            <Title
              isCapitalised={false}
              isCentralised={false}
              isBold={true}
              size='large'
            >
              Hospitality Bookings
            </Title>
            <CopyBlock textSize='medium'>
              Upgrade your order with one of our hospitality packages or treat
              yourself to Afternoon Tea.
            </CopyBlock>
          </TitleBlock>
          <ProductsWrapper platform={platform}>
            {filteredProducts.hospitality.map(productId => (
              <ProductItemContainer
                productId={productId}
                isModal={false}
                isFullWidth={true}
                key={productId}
                upsellText='per person'
              />
            ))}
          </ProductsWrapper>
        </>
      )}
      {filteredProducts.product.length > 0 && (
        <>
          <TitleBlock>
            <Title
              isCapitalised={false}
              isCentralised={false}
              isBold={true}
              size='large'
            >
              Merchandise
            </Title>
            <CopyBlock textSize='medium'>
              Pre-order from the fantastic range of show merchandise.
            </CopyBlock>
          </TitleBlock>
          <ProductsWrapper platform={platform}>
            {filteredProducts.product.map(productId => (
              <ProductItemContainer
                productId={productId}
                isModal={false}
                isFullWidth={true}
                key={productId}
                upsellText='per item'
              />
            ))}
          </ProductsWrapper>
        </>
      )}

      {filteredProducts.event.length > 0 && (
        <>
          <TitleBlock>
            <Title
              isCapitalised={false}
              isCentralised={false}
              isBold={true}
              size='large'
            >
              Theatre Tours
            </Title>
            <CopyBlock textSize='medium'>
              Join us on a magical journey through theatrical history at Theatre
              Royal Drury Lane, the oldest theatre on the same site in the
              world.
            </CopyBlock>
          </TitleBlock>
          <ProductsWrapper platform={platform}>
            {filteredProducts.event.map(productId => (
              <ProductItemContainer
                productId={productId}
                isModal={false}
                isFullWidth={true}
                key={productId}
                upsellText='per person'
              />
            ))}
          </ProductsWrapper>
        </>
      )}
      {filteredProducts.optional.length > 0 && (
        <>
          <TitleBlock>
            <Title
              isCapitalised={false}
              isCentralised={false}
              isBold={true}
              size='large'
            >
              Optional extras
            </Title>
          </TitleBlock>
          <ProductsWrapper platform={platform}>
            {filteredProducts.optional.map(productId => (
              <ProductItemContainer
                productId={productId}
                isModal={false}
                isFullWidth={true}
                key={productId}
              />
            ))}
          </ProductsWrapper>
        </>
      )}
    </ProductListWrapper>
  )
}

export { PostPurchaseProductsContainer }
