import React from 'react'

// Components
import { TransactionSummary } from 'marvel-components'

// Selectors
import { orderSelectors } from 'modules/hospitality/order'

// Misc
import { useSelector } from 'react-redux'

type Props = {
  hideOnMobile?: boolean
  inOwnView?: boolean
}

const OrderSummaryContainer: React.FC<Props> = ({
  hideOnMobile = false,
  inOwnView = false
}) => {
  // Selectors
  const basketSummary = useSelector(orderSelectors.selectBasketSummary)

  return (
    <TransactionSummary
      summary={basketSummary}
      hideOnMobile={hideOnMobile}
      inOwnView={inOwnView}
      title='Your Order Summary'
      isFreeShown={false}
      isZeroShown={true}
    />
  )
}

export { OrderSummaryContainer }
