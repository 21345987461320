import React from 'react'
import styled from 'styled-components/macro'
import logo from 'assets/lwtheatre-loading.gif'

interface Props {
  isLoading: boolean
}

const WithLoadingWrapper = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  :before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: #ffffff;
    opacity: 0.6;
  }
`

const LogoWrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

const Logo = styled.img`
  width: 10rem;
`

export const WithLoader = <P extends object>(
  Component: React.ComponentType<P>
): React.FC<P & Props> => ({ isLoading = false, ...props }) => {
  if (!isLoading) return <Component {...(props as P)} />
  return (
    <WithLoadingWrapper>
      <LogoWrapper>
        <Logo src={logo} alt="Loading logo" />
      </LogoWrapper>
    </WithLoadingWrapper>
  )
}
