// Types
import { AppState } from 'modules/types'

// Adapters
import { slotsAdapter } from './slotSlice'
import { createSelector } from '@reduxjs/toolkit'
import {
  selectSelectedCalendarMonth,
  selectSelectedCalendarMonthDate
} from 'modules/hospitality/reservation/selectors'
import moment from 'moment'
import { HospitalitySlot } from 'shared-types'

// Selectors
export const {
  selectById,
  selectIds,
  selectEntities,
  selectAll,
  selectTotal
} = slotsAdapter.getSelectors<AppState>(state => state.hospitality.slots)

export const selectIsLoading = (state: AppState) =>
  state.hospitality.slots.loading === 'pending'

export const selectUnavailableSlots = (
  slots: (HospitalitySlot | undefined)[]
) => slots.filter(slot => !slot?.times?.some(time => time.available))

export const selectSelectedMonthSlots = createSelector(
  selectEntities,
  selectSelectedCalendarMonth,
  (entities, selectedCalendarMonth) => {
    const slots: HospitalitySlot[] = []
    const date = moment(selectedCalendarMonth)
    const iter = date.clone().startOf('month')
    const end = date.clone().endOf('month')

    while (iter.diff(end, 'days') <= 0) {
      const slotId = iter.format('YYYY-MM-DD')
      const slot = entities[slotId]
      slot && slots.push(slot)
      iter.add(1, 'days')
    }

    return slots
  }
)

export const selectSelectedMonthUnavailableSlots = createSelector(
  selectSelectedMonthSlots,
  selectUnavailableSlots
)

export const selectSelectedMonthUnavailableSlotDates = createSelector(
  selectSelectedMonthUnavailableSlots,
  slots =>
    slots
      .filter(slot => slot !== undefined)
      .map(slot => moment(slot?.date).toDate())
)

export const selectSelectedMonthFirstSlot = createSelector(
  selectSelectedCalendarMonthDate,
  selectSelectedMonthSlots,
  (date, slots) => {
    const today = moment.utc()
    const startOfMonth = date.startOf('month')
    const firstSlotDate = startOfMonth < today ? today : startOfMonth
    const slotId = firstSlotDate.format('YYYY-MM-DD')
    return slots.find(slot => slot.date === slotId)
  }
)
