import { combineReducers } from 'redux'

// Slices
import sites from 'modules/hospitality/site'
import areas from 'modules/hospitality/area'
import occasions from 'modules/hospitality/occasion'
import slots from 'modules/hospitality/slot'
import reservation from 'modules/hospitality/reservation'
import menus from 'modules/hospitality/menu'
import order from 'modules/hospitality/order'
import optin from 'modules/hospitality/optin'

const hospitality = combineReducers({
  sites,
  areas,
  occasions,
  menus,
  slots,
  reservation,
  order,
  optin
})
export type HospitalityState = ReturnType<typeof hospitality>

export default hospitality
