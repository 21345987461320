import React from 'react'

// Components
import { TransactionSummary } from 'marvel-components'

// Selectors
import { basketSelectors } from 'modules/smart-waiter/basket'

// Actions
import { basketActions } from 'modules/smart-waiter/basket'

// Misc
import { useSelector, useDispatch } from 'react-redux'

// Types
import { SWOrder } from 'shared-types'
import { trackSmartWaiterRemoveFromCart, usePlatform } from 'utils'
import { selectBookingFlow } from 'modules/basket/selectors'

type Props = {
  hideOnMobile?: boolean
  inOwnView?: boolean
  order?: SWOrder
  showRemoveOption?: boolean
}

const BasketSummaryContainer: React.FC<Props> = ({
  order,
  showRemoveOption = true,
  hideOnMobile = false,
  inOwnView = false
}) => {
  const dispatch = useDispatch()

  // Selectors
  const basketSummary = useSelector(basketSelectors.selectBasketSummary)
  const platform = usePlatform()
  const bookingFlow = useSelector(selectBookingFlow)

  // Actions
  const handleOnRemoveProduct = (itemId: EntityId) => {
    dispatch(basketActions.removeItemFromBasket(itemId))

    // Analytics
    basketSummary.groups.forEach(group => {
      const item = group.items.find(item => item.id === itemId)
      if (item) {
        trackSmartWaiterRemoveFromCart({
          bookingFlow,
          platform,
          item: {
            id: String(item.id),
            name: item.title,
            price: item.price,
            quantity: item.quantity
          }
        })
      }
    })
  }

  return (
    <TransactionSummary
      hideOnMobile={hideOnMobile}
      inOwnView={inOwnView}
      summary={basketSummary}
      onRemoveItem={showRemoveOption ? handleOnRemoveProduct : undefined}
      title={order ? `Your Order Reference ${order.id}` : 'Your Basket Summary'}
    />
  )
}

export { BasketSummaryContainer }
