import React, { useRef, useContext } from 'react'
import { FormattedPrice } from 'utils'
import styled, { css } from 'styled-components/macro'
import { Icon, ToolTip } from 'marvel-components'
import { ThemeContext } from 'styled-components'

interface Props {
  fullValue: number
  discountedValue?: number
}

const TotalHeader = styled.span(props => {
  const {
    theme: {
      font: { header }
    }
  } = props

  return css`
    font-size: 1rem;
    text-transform: uppercase;
    font-family: ${header};
  `
})

const TotalText = styled.span`
  display: flex;
  flex-direction: column;
  padding-bottom: 2px;
`

const TotalValueWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-end;
`

const TotalValue = styled.span<{ strikethrough?: boolean }>(props => {
  const {
    theme: {
      font: { header }
    },
    strikethrough = false
  } = props

  return css`
    font-size: ${strikethrough ? '0.875rem' : '1.625rem'};
    padding-left: 1rem;
    padding-bottom: ${strikethrough ? '0.2rem' : '0'};
    font-family: ${header};
    text-decoration: ${strikethrough ? 'line-through' : 'none'};
    line-height: 1;
  `
})

const TotalTerms = styled.div`
  margin-top: 0.25rem;
  display: flex;
  fill: #fff;
  cursor: pointer;
`

const TotalWrapper = styled.div(props => {
  const {
    theme: {
      colors: { sidebarCopyColor },
      font: { copy }
    }
  } = props

  return css`
    color: ${sidebarCopyColor};
    font-family: ${copy};
    font-size: 0.625rem;
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: space-between;
    padding-bottom: 1rem;
  `
})

const IconWrapper = styled.div(props => {
  const {
    theme: {
      colors: { sidebarCopyColor }
    }
  } = props

  return css`
    > div {
      display: flex;
      margin-left: 0.5rem;
      margin-right: 0.5rem;
      fill: ${sidebarCopyColor};
    }
  `
})

export const TotalTitle = (props: Props) => {
  const terms = useRef<any>()
  const { fullValue, discountedValue = 0 } = props

  const showDiscount = discountedValue > 0
  const theme = useContext(ThemeContext)

  return (
    <TotalWrapper>
      <TotalText ref={terms}>
        <TotalHeader>Total</TotalHeader>
        {theme.basketNote?.title && (
          <TotalTerms>
            {theme.basketNote.title}
            <ToolTip content={theme.basketNote.note}>
              <IconWrapper>
                <Icon padding='none' icon='info-circle' />
              </IconWrapper>
            </ToolTip>
          </TotalTerms>
        )}
      </TotalText>
      <TotalValueWrapper>
        <TotalValue strikethrough={!!showDiscount}>
          <FormattedPrice
            amount={fullValue + discountedValue}
            currency='GBP'
            isDecimalsVisible={true}
            isZeroShown={true}
          />
        </TotalValue>
        {showDiscount && (
          <TotalValue>
            <FormattedPrice
              isZeroShown={true}
              amount={fullValue}
              currency='GBP'
              isDecimalsVisible={true}
            />
          </TotalValue>
        )}
      </TotalValueWrapper>
    </TotalWrapper>
  )
}
