import { BookingFlow } from 'shared-types'
import * as store from 'store'

export type Session = {
  contextId: string
  transactionId: string
  expiresAt: string
  bookingFlow: BookingFlow
}

export const MVL_SESSION = 'MVL_SESSION'

export const updateSessionData = (session: Session) => {
  store.set(MVL_SESSION, {
    ...session
  })
}

export const getSession = (): Session | undefined => store.get(MVL_SESSION)

export const clearSessionData = () => store.remove(MVL_SESSION)
