import React from 'react'

// Components
import { Link } from 'marvel-components'

// Misc
import { media, usePlatform } from 'utils'
import styled from 'styled-components'

// Types
import { NavItem } from 'shared-types'

const SummaryFooter = styled.div<{ paddingBottom?: number }>`
  font-family: ${props => props.theme.font.copy};
  margin-top: auto;
  justify-content: center;
  display: flex;
  flex-direction: row;
  padding-bottom: ${props => props.paddingBottom ?? 1}rem;

  ${media.tablet} {
    justify-content: center;
    padding: 1rem 0;
    padding-bottom: ${props => props.paddingBottom}rem;
    margin-top: 0;
  }
`

const SummaryFooterItem = styled.span`
  margin-right: 0.5rem;
`

const LinkWrapper = styled.div<{ platform?: string }>`
  ${media.tablet} {
    a {
      color: ${props =>
        props.platform === 'kiosk'
          ? '#666'
          : props.theme.colors.sidebarCopyColor};
    }
  }
`

const defaultFooterItems: NavItem[] = [
  {
    name: 'Terms and Conditions',
    path: 'https://lwtheatres.co.uk/terms-conditions/'
  },
  { name: 'FAQ', path: 'https://lwtheatres.co.uk/faq/' },
  { name: 'Privacy Policy', path: 'https://lwtheatres.co.uk/privacy-policy/' }
]

type Props = {
  items?: NavItem[]
  paddingBottom?: number
  platform?: string
}

const FooterNav: React.FC<Props> = ({
  items = defaultFooterItems,
  ...props
}) => {
  const platform = usePlatform()
  return (
    <SummaryFooter {...props}>
      {items.map((item, key) => (
        <SummaryFooterItem key={key}>
          <LinkWrapper platform={platform}>
            <Link
              text={item.name}
              href={item.path}
              size='extra-small'
              isSidebar={true}
            />
          </LinkWrapper>
        </SummaryFooterItem>
      ))}
    </SummaryFooter>
  )
}

export { FooterNav }
