import React, { useEffect, useRef, useContext } from 'react'

// Utils
import styled, { ThemeContext } from 'styled-components/macro'
import { rotate, media, darken } from 'utils'
import AdyenCheckout from '@adyen/adyen-web'
import '@adyen/adyen-web/dist/adyen.css'

const CardInput = styled.div`
  margin-top: 0.5rem;
  ${media.tablet} {
    margin-bottom: 1rem;
  }
  /* SPINNER */
  .adyen-checkout__spinner__wrapper {
    align-items: center;
    display: flex;
    height: 100%;
    justify-content: center;
  }

  .adyen-checkout__spinner {
    animation: ${rotate} 2s infinite linear;
    border: 2px solid ${props => props.theme.colors.borderColor};
    border-radius: 50%;
    border-top-color: transparent;
    height: 28px;
    width: 28px;
  }

  /* INPUT */
  .adyen-checkout__label__text {
    display: none;
  }
  .adyen-checkout__field {
    padding-bottom: 1.5rem;
    position: relative; /* Needed for the error message */
  }
  .adyen-checkout__input {
    border: 1px solid ${props => props.theme.colors.borderColor};
    font-size: 1rem;
    line-height: normal;
    padding: 8px;
    outline: none;
    flex: 1 0 auto;
    background: #fff;
    box-sizing: content-box;
    height: 21px;
    border-radius: 0.125rem;
  }

  .adyen-checkout__input--focus {
    border: 0.0625rem solid
      ${props => darken(props.theme.colors.borderColor, 40)};
    box-shadow: none;
  }

  /* Error State */
  .adyen-checkout__input--error {
    border: 0.0625rem solid ${props => props.theme.colors.alertColor};
  }
  .adyen-checkout-input__inline-validation {
    position: absolute;
    right: 0.5rem;
    top: 1rem;
  }
  .adyen-checkout-input__inline-validation--invalid {
    color: ${props => props.theme.colors.alertColor};
  }
  .adyen-checkout-input__inline-validation--valid {
    color: #4bb543;
  }
  .adyen-checkout__icon {
    vertical-align: middle;
  }
  .adyen-checkout__icon svg {
    fill: currentColor;
  }
  .adyen-checkout__error-text {
    position: absolute;
    left: 0;
    color: ${props => props.theme.colors.alertColor};
    font-size: 0.625rem;
    white-space: nowrap;
    align-items: center;
    display: flex;
    font-weight: 400;
    font-family: ${props => props.theme.font.copy};
  }

  /* Card Field */
  .adyen-checkout__card__cardNumber__brandIcon {
    position: absolute;
    right: 0.5rem;
    top: 0.65rem;
    transform: none;
  }
  /* Form layout */
  .adyen-checkout__card__form {
    display: flex;
    flex-direction: row;
  }
  .adyen-checkout__field {
  }
  .adyen-checkout__field-wrapper {
    display: flex;
    flex-direction: row;
    flex: 1 1 auto;
  }
  .adyen-checkout__input--small {
    flex: 1 0 auto;
  }
  .adyen-checkout__field--cardNumber {
    width: calc(55% - 1.5rem);
    padding-right: 1.5rem;
  }
  .adyen-checkout__card__exp-cvc {
    width: calc(45% - 1.5rem);
  }
  .adyen-checkout__field--expiryDate {
    width: 50%;
    padding-right: 0.75rem;
    margin-right: 0;
  }
  .adyen-checkout__field--securityCode {
    width: 50%;
    padding-left: 0.75rem;
    margin-left: 0;
  }
  .adyen-checkout__card__cardNumber__input,
  .adyen-checkout__card__exp-date__input,
  .adyen-checkout__card__cvc__input {
    width: calc(100% - 18px);
  }
  .adyen-checkout__field-wrapper > .adyen-checkout__field:nth-child(2) {
    margin-left: 0;
  }
  .adyen-checkout__field-wrapper > .adyen-checkout__field:first-child {
    margin-right: 0;
  }
  .adyen-checkout__field__cvc .adyen-checkout__error-text {
    left: 0.75rem;
  }
  ${media.tablet} {
    .adyen-checkout__card__form {
      flex-direction: column;
    }
    .adyen-checkout__field--cardNumber {
      width: 100%;
      padding-right: 0;
    }
    .adyen-checkout__card__exp-cvc {
      width: 100%;
    }
    .adyen-checkout__field {
      padding-bottom: 1.5rem;
      margin-bottom: 0;
    }
    .adyen-checkout__input--focus {
      border: 1px solid ${props => darken(props.theme.colors.borderColor, 40)};
    }
    .adyen-checkout__input--error {
      border: 0.0625rem solid ${props => props.theme.colors.alertColor};
    }
    .adyen-checkout__field__cvc .adyen-checkout__error-text {
      left: 0.75rem;
    }
    .adyen-checkout__input--error {
      border: 0.0625rem solid ${props => props.theme.colors.alertColor};
    }
  }
`

type Props = {
  onPaymentSubmit: (data: any) => void
  paymentMethods: any
  brands?: string[]
}

const CardPayment: React.FC<Props> = ({
  onPaymentSubmit,
  paymentMethods,
  brands
}) => {
  const adyenCheckout = useRef<any>()
  const paymentContainer = useRef<any>()
  const cardComponent = useRef<any>()
  const theme = useContext(ThemeContext)
  const handleOnSubmit = (state: any, component: any) => {}

  const handleOnError = (state: any) => {}

  useEffect(() => {
    if (!adyenCheckout.current) {
      adyenCheckout.current = new AdyenCheckout({
        environment: process.env.REACT_APP_ADYEN_ENVIRONMENT,
        clientKey: process.env.REACT_APP_ADYEN_CLIENT_KEY,
        paymentMethodsResponse: paymentMethods
      })
    }
  })

  useEffect(() => {
    if (adyenCheckout.current && !cardComponent.current) {
      const styles = {
        base: {
          color: theme ? theme.colors.copyColor : 'black',
          fontSize: '1rem',
          fontSmoothing: 'antialiased',
          fontWeight: 400,
          fontFamily: 'Helvetica'
        },
        placeholder: {
          color: theme ? theme.colors.borderColor : 'black',
          fontFamily: 'Helvetica',
          fontWeight: 400
        },
        error: {
          color: theme ? theme.colors.alertColor : 'red'
        }
      }

      const handleOnChange = (state: any, component: any) => {
        // Patched so that result includes apple pay data
        if (state.isValid) {
          onPaymentSubmit && onPaymentSubmit(state.data)
        }
      }

      const component = adyenCheckout.current
        .create('card', {
          styles: styles,
          locale: 'en_GB',
          placeholders: {
            encryptedCardNumber: 'Card Number',
            encryptedExpiryDate: 'MM/YY',
            encryptedSecurityCode: 'CVC'
          },
          onSubmit: handleOnSubmit,
          onChange: handleOnChange,
          onError: handleOnError,
          ...(brands && { brands })
        })
        .mount(paymentContainer.current)
      cardComponent.current = component
    }
  }, [theme, onPaymentSubmit])

  return <CardInput ref={paymentContainer} id='payment-card' />
}

export { CardPayment }
