import React from 'react'
import styled from 'styled-components/macro'

// Components
import { Title, CopyBlock, Icon, Button, WithLoader } from 'marvel-components'

// Selectors
import { orderSelectors } from 'modules/hospitality/order'

// Actions
import { bannerActions } from 'modules/banner'

// Misc
import { media, lighten, FormattedPrice } from 'utils'
import { useSelector, useDispatch } from 'react-redux'
import { useRouteMatch, Redirect } from 'react-router-dom'

type Props = {
  error3d?: string | null
  callToActionText: string
  onCallToActionClick: (e: React.MouseEvent) => void
}

const OrderCompleteWrapper = WithLoader(styled.div`
  box-sizing: border-box;
  padding: 1.5rem 1.5rem 0 1.5rem;
  background: ${props => props.theme.colors.secondaryBackgroundColor};

  ${media.tablet} {
    padding: 1rem 1rem 1.5rem 1rem;
    margin-top: 0;
  }
`)

const SuccessMessageWrapper = styled.div`
  padding: 1.5rem;
  background: ${props => lighten(props.theme.colors.borderColor, 10)};
  margin-bottom: 1.5rem;
`

const SuccessMessageHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 1rem;
`

const IconWrapper = styled.div`
  background: ${props => props.theme.colors.activeColor};
  border-radius: 50%;
  fill: #ffffff;
  width: 1.5rem;
  height: 1.5rem;
  line-height: 1.6rem; /* Note: Due to dodgy awesome font tick */
  text-align: center;
  font-size: 0.875rem;
  margin-left: 0.5rem;
`

const SuccessMessage = styled.div`
  margin-top: 1rem;
`

const OrderDetails = styled.div`
  margin-top: 1rem;
  font-family: ${props => props.theme.font.copy};
  color: ${props => props.theme.colors.copyColor};
  font-size: 0.75rem;
`

const OrderDetailsTitle = styled.span`
  text-transform: uppercase;
  font-family: ${props => props.theme.font.header};
`

const OrderServiceDetails = styled.div`
  margin-bottom: 1rem;
  &&:last-child {
    margin-bottom: 0;
  }
`

const OrderDetailsLineItem = styled.span`
  display: flex;
  flex-direction: row;
  margin-top: 0.5rem;
`

const OrderDetailsAttribute = styled.span`
  margin-right: 0.25rem;
`

const StyledButton = styled(Button)`
  margin-top: 1rem;
`

const OrderContainer: React.FC<Props> = ({
  error3d,
  callToActionText,
  onCallToActionClick
}) => {
  const { url } = useRouteMatch()
  const dispatch = useDispatch()
  const isLoading = useSelector(orderSelectors.selectIsOrderLoading)
  const order = useSelector(orderSelectors.selectOrder)

  const reservationDate = useSelector(orderSelectors.selectSelectedDateAndTime)

  if (order && order.status !== 'PAID') {
    if (error3d) {
      dispatch(
        bannerActions.setBannerContent({
          bannerType: 'error',
          title: 'Hospitality payment error',
          text: error3d
        })
      )
    }
    return <Redirect to={`${url}/payment`} />
  }

  return (
    <OrderCompleteWrapper isLoading={isLoading}>
      <SuccessMessageWrapper>
        {order && order.status === 'PAID' && (
          <>
            <SuccessMessageHeader>
              <Title
                isCapitalised={false}
                isCentralised={false}
                isBold={true}
                size='large'
              >
                Order Complete
              </Title>
              <IconWrapper>
                <Icon icon={'check'} />
              </IconWrapper>
            </SuccessMessageHeader>
            <SuccessMessage>
              <CopyBlock>
                {`Thank you for your ${order.event.occasionName} reservation at ${order.event.siteName}`}
                .
              </CopyBlock>
            </SuccessMessage>
            <SuccessMessage>
              <CopyBlock>{`A receipt with your order details has been emailed to ${order.email}.`}</CopyBlock>
            </SuccessMessage>
            <OrderDetails>
              <OrderServiceDetails>
                <OrderDetailsTitle>
                  {`${order.event.occasionName} Reservation`}
                </OrderDetailsTitle>
                {/* Order is for future date and not fulfilled */}
                <OrderDetailsLineItem>
                  <OrderDetailsAttribute>
                    Reservation Date:
                  </OrderDetailsAttribute>
                  {reservationDate &&
                    reservationDate
                      .utc()
                      .format('dddd Do MMM YYYY, HH:mm')
                      .toString()}
                </OrderDetailsLineItem>

                <OrderDetailsLineItem>
                  <OrderDetailsAttribute>
                    Order Reference:
                  </OrderDetailsAttribute>
                  {order.bookingReference}
                </OrderDetailsLineItem>
                {order.total > 0 && (
                  <OrderDetailsLineItem>
                    <OrderDetailsAttribute>Order total:</OrderDetailsAttribute>
                    <FormattedPrice
                      currency='GBP'
                      amount={order.total}
                      isBold={false}
                    />
                  </OrderDetailsLineItem>
                )}
              </OrderServiceDetails>
            </OrderDetails>
          </>
        )}
      </SuccessMessageWrapper>
      <StyledButton
        isBlock={true}
        text={callToActionText}
        onClick={onCallToActionClick}
      />
    </OrderCompleteWrapper>
  )
}

export { OrderContainer }
