import React from 'react'
import { BrowserRouter as Router } from 'react-router-dom'
import { IntlProvider } from 'react-intl'
import { AppContainer } from 'containers'
import { ScrollToTop } from 'utils'
import './index.css'

const App: React.FC = () => {
  return (
    <IntlProvider locale="en-GB">
      <Router>
        <ScrollToTop />
        <AppContainer />
      </Router>
    </IntlProvider>
  )
}

export default App
