import { createEntityAdapter, createSelector } from '@reduxjs/toolkit'
import { AppState } from 'modules'
import { VenuePage } from '../shared/types'

export const venuePageAdapter = createEntityAdapter<VenuePage>({
  selectId: x => x.venue
})

const {
  selectAll,
  selectEntities,
  selectById
} = venuePageAdapter.getSelectors()

export const selectVenuePageState = (state: AppState) =>
  state.selfServe.venuePage

export const selectVenuePages = createSelector(selectVenuePageState, selectAll)

export const selectVenuePageEntities = createSelector(
  selectVenuePageState,
  selectEntities
)

export const selectVenuePage = selectById

export const selectIsVenuePageLoading = (state: AppState) =>
  state.selfServe.venuePage.status === 'idle' ||
  state.selfServe.venuePage.status === 'pending'
