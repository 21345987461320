import * as store from 'store'

const setKioskAccessToken = (accessToken: string) =>
  store.set('kiosk.accessToken', accessToken)

const getKioskAccessToken = () => store.get('kiosk.accessToken')

const clearKioskAccessToken = () => store.remove('kiosk.accessToken')

const clearContextId = () => store.remove('contextId')

export {
  setKioskAccessToken,
  getKioskAccessToken,
  clearKioskAccessToken,
  clearContextId
}
