import React, { useContext, useEffect } from 'react'

// Components
import { UpsellBanner, Title, WithLoader } from 'marvel-components'

// Containers
import { PostPurchaseProductsContainer } from 'containers/Ticketing'

// Actions
import { fetchPromptsPostOrder } from 'modules/prompt/actions'

// Selectors
import { eventDetailsSelectors } from 'modules/ticketing/event'
import {
  selectOrderTransactionNumber,
  selectOrder
} from 'modules/basket/selectors'

// Misc
import styled, { ThemeContext } from 'styled-components/macro'
import { useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import moment from 'moment'

// Utils
import { media, calendarRoute, manageOrderRoute, smartWaiterVenue } from 'utils'

// Assets
import ToursImage from 'assets/trdl_tours.jpg'
import AfterNoonTeaImage from 'assets/afternoon_tea_upsell_banner.png'
import GardenImage from 'assets/the_garden_upsell_banner.png'
import CecilBeatonBarImage from 'assets/cecil_beaton_upsell_banner.png'
import ManageOrder from 'assets/manage_order_image.jpg'
import SmartWaiter from 'assets/sw_logo.png'

const OrderUpsellsLoader = WithLoader(styled.div`
  padding: 0 1.5rem;
  background: #ffffff;
  ${media.tablet} {
    padding: 0 1rem;
    margin-top: 1rem;
  }
`)

const OrderUpsellsWrapper = styled.div<{ platform?: string }>`
  ${media.tablet} {
    min-height: 68vh;
  }
`

const StyledTitle = styled(Title)`
  scroll-margin-top: 4rem;

  ${media.phone} {
    scroll-margin-top: 6.5rem;
  }
`

const BannerWrapper = styled.div`
  margin-top: 1rem;
  padding-bottom: 1rem;
  margin-bottom: 1.5rem;
  border-bottom: 0.0625rem solid ${props => props.theme.colors.borderColor};
`

type Props = {
  transactionGuid: string
  isLoading: boolean
}

const OrderUpsellsContainer: React.FC<Props> = ({
  transactionGuid,
  isLoading
}) => {
  const history = useHistory()
  const theme = useContext(ThemeContext)
  const dispatch = useDispatch()

  // Selectors
  const transactionNumber = useSelector(selectOrderTransactionNumber)
  const order = useSelector(selectOrder)
  const swId = useSelector(eventDetailsSelectors.selectSwVenueId)

  // Dates
  const performanceDate = moment.utc(
    order?.basket.ticketReservations[0]?.startDate
  )
  const diffDays = performanceDate.diff(moment(), 'days')

  useEffect(() => {
    dispatch(
      fetchPromptsPostOrder({
        transactionGuid
      })
    )
  }, [transactionGuid, dispatch])

  // Actions
  const handleSmartWaiterClick = () => {
    swId && history.push(smartWaiterVenue(`${swId}`))
  }
  const handleTourBookingClick = () => {
    history.push(calendarRoute('e67f2c81-bf36-ea11-a9ca-064c84cbe4ea'))
  }
  const handleAfternoonTeaBooking = () => {
    const url =
      'https://thelane.co.uk/eat-and-drink-venues/grand-saloon-afternoon-tea'
    window.location.href = url
  }
  const handleGardenClick = () => {
    const url = 'https://thelane.co.uk/eat-and-drink-venues/the-garden'
    window.location.href = url
  }
  const handleCecilBeatonBarClick = () => {
    const url = 'https://thelane.co.uk/eat-and-drink-venues/cecil-beaton-bar'
    window.location.href = url
  }
  const handleManageOrderClick = () => {
    history.push(manageOrderRoute(`${transactionNumber}`))
  }
  const {
    orderSuccess: {
      showHospitality,
      showTours,
      showSmartWaiter,
      showCecilBeatonBar,
      showGarden
    }
  } = theme

  return (
    <OrderUpsellsWrapper>
      <OrderUpsellsLoader isLoading={isLoading}>
        <BannerWrapper>
          <StyledTitle
            id='manage-order'
            isCentralised={false}
            isBold={true}
            isCapitalised={false}
            size='large'
          >
            Manage Order
          </StyledTitle>
          <UpsellBanner
            bannerTitle={'Your Tickets '}
            bannerDescription={
              'View your e-tickets, ready to be scanned at the theatre '
            }
            bannerCta={'Your Tickets'}
            onBannerClick={handleManageOrderClick}
            bannerImage={ManageOrder}
            backgroundColor={'#000'}
            buttonColor={'#fff'}
            buttonCopyColor={'#000'}
            copyColor={'#fff'}
          />
        </BannerWrapper>
        <PostPurchaseProductsContainer />
        {showSmartWaiter && (
          <BannerWrapper>
            <StyledTitle
              id='pre-order-drinks'
              isCentralised={false}
              isBold={true}
              isCapitalised={false}
              size='large'
            >
              Pre-Order Food and Beverages
            </StyledTitle>
            <UpsellBanner
              bannerTitle={
                diffDays < 0
                  ? 'Thank you'
                  : diffDays === 0
                  ? 'Order your interval and pre-show drinks now'
                  : 'Come back soon to order your interval and pre-show drinks'
              }
              bannerDescription={
                diffDays < 0
                  ? 'We hope you enjoyed the show'
                  : diffDays === 0
                  ? 'Take a minute to order drinks and treats for your performance using our Smart Waiter service'
                  : 'Head back to this page on the day of the show and take a minute to order drinks and treats for your performance using our Smart Waiter service.'
              }
              bannerIcon={SmartWaiter}
              backgroundColor={'#000'}
              buttonColor={'#fff'}
              buttonCopyColor={'#000'}
              copyColor={'#fff'}
              bannerCta={diffDays === 0 ? 'Order Now' : undefined}
              onBannerClick={handleSmartWaiterClick}
            />
          </BannerWrapper>
        )}
        {(showHospitality || showTours || showGarden || showCecilBeatonBar) && (
          <StyledTitle
            id='explore'
            isCentralised={false}
            isBold={true}
            isCapitalised={false}
            size='large'
          >
            Explore
          </StyledTitle>
        )}
        {showHospitality && (
          <UpsellBanner
            bannerTitle={'Decadent and delicious Afternoon Tea'}
            bannerDescription={
              'Treat yourself and make a day of it with an unforgettable Afternoon Tea, hosted at The Lane'
            }
            bannerCta={'Reserve Now'}
            onBannerClick={handleAfternoonTeaBooking}
            bannerImage={AfterNoonTeaImage}
            backgroundColor={'#FFE6E1'}
            buttonColor={'#000'}
            buttonCopyColor={'#FFE6E1'}
            copyColor={'#000'}
          />
        )}
        {showTours && (
          <UpsellBanner
            bannerTitle={'Theatre Tours'}
            bannerDescription={
              'Take a tour of the iconic Theatre Royal Drury Lane and explore the rich history the venue has to offer.'
            }
            bannerCta={'Book A Tour Now'}
            onBannerClick={handleTourBookingClick}
            bannerImage={ToursImage}
            backgroundColor={'#e6e6e6'}
            buttonColor={'#111'}
            buttonCopyColor={'#fff'}
            copyColor={'#111'}
          />
        )}
        {showGarden && (
          <UpsellBanner
            bannerTitle={'The Garden at The Lane'}
            bannerDescription={`From espressos to espresso martinis. Open daily, no reservation necessary`}
            bannerCta={'Discover More'}
            onBannerClick={handleGardenClick}
            bannerImage={GardenImage}
            backgroundColor={'#6C8068'}
            buttonColor={'#EFE8DB'}
            buttonCopyColor={'#6C8068'}
          />
        )}
        {showCecilBeatonBar && (
          <UpsellBanner
            bannerTitle={`Everyone’s welcome at the Cecil Beaton bar`}
            bannerDescription={
              'Extraordinary cocktails in glamorous surroundings in The Lane.'
            }
            bannerCta={'Discover More'}
            onBannerClick={handleCecilBeatonBarClick}
            bannerImage={CecilBeatonBarImage}
            backgroundColor={'#002733'}
            buttonColor={'#EFE8DB'}
            buttonCopyColor={'#002733'}
          />
        )}
      </OrderUpsellsLoader>
    </OrderUpsellsWrapper>
  )
}

export { OrderUpsellsContainer }
