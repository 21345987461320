import { ReactNode } from 'react'
import styled, { withTheme, DefaultTheme, css } from 'styled-components/macro'
import { Icon } from 'marvel-components'
import { darken, lighten } from 'utils'

export type CheckboxSizes = 'small' | 'medium' | 'large' | 'default'
export type CheckboxTheme =
  | 'light'
  | 'dark'
  | 'alert'
  | 'best-available'
  | 'discount'

export type CheckboxProps = {
  label: string
  theme: DefaultTheme
  children?: ReactNode
  name?: string
  isChecked?: boolean
  isLabelVisible?: boolean
  isThemed?: boolean
  isPadded?: boolean
  size?: CheckboxSizes
  themeIcon?: ReactNode
  onChange?: (isChecked: boolean) => void
  checkboxTheme?: CheckboxTheme
  isRequired?: boolean
}

const CheckboxWrapper = styled.div<{
  isPadded?: boolean
  size: CheckboxSizes
  isRequired?: boolean
}>(props => {
  const { isPadded = false, size, isRequired = false } = props

  let alignment = 'flex-start'

  if (size === 'small') {
    alignment = 'flex-start'
  }

  if (size === 'default') {
    alignment = 'center'
  }

  return css`
    display: flex;
    align-items: ${alignment};
    margin-bottom: ${isPadded ? '1rem' : 0};
    margin-right: 0.1875rem;
    user-select: none;
    cursor: pointer;
    border: ${isRequired ? '1px solid' : 'none'};
    border-color: ${props =>
      lighten(props.theme.colors.mainBackgroundColor, 50)};
    padding: ${isRequired ? '7px 5px' : '0 5px'};
    border-radius: ${isRequired ? '2px' : '0'};
  `
})

const StyledCheckbox = styled.div<{
  size: CheckboxSizes
  checkboxTheme: CheckboxTheme
}>(props => {
  const {
    theme: {
      colors: {
        mainColor,
        copyColor,
        bestAvailablePerformanceColor,
        discountedPerformanceColor,
        sidebarCopyColor
      }
    },
    size,
    checkboxTheme
  } = props

  let dimension
  let fontSize

  switch (size) {
    case 'small':
      dimension = '1rem'
      fontSize = `calc(${dimension} - 0.25rem)`
      break
    case 'medium':
      dimension = '1.5rem'
      fontSize = `calc(${dimension} - 0.5rem)`
      break
    case 'large':
      dimension = '1.875rem'
      fontSize = `calc(${dimension} - 0.5rem)`
      break
    default:
      dimension = '1.5rem'
      fontSize = `calc(${dimension} - 0.5rem)`
      break
  }

  let background
  let border

  switch (checkboxTheme) {
    case 'light':
      background = 'initial'
      border = `${sidebarCopyColor} 0.0625rem solid`
      break
    case 'dark':
    case 'alert':
      background = 'initial'
      border = `${copyColor} 0.0625rem solid`
      break
    case 'best-available':
      background = `${bestAvailablePerformanceColor}`
      border = `${sidebarCopyColor} 0.0625rem solid`
      break
    case 'discount':
      background = `${discountedPerformanceColor}`
      border = `${sidebarCopyColor} 0.0625rem solid`
      break
    default:
      background = mainColor
      border = 'none'
      break
  }

  return css`
    position: relative;
    width: ${dimension};
    height: ${dimension};
    display: inline-block;
    background: ${background};
    border: ${border};
    font-size: ${fontSize};
    border-radius: 0.125rem;
    box-sizing: border-box;
    cursor: pointer;
  `
})

const CheckedIcon = styled.div<{
  isChecked: boolean
  checkboxTheme: CheckboxTheme
}>(props => {
  const {
    theme: {
      colors: { copyColor, sidebarCopyColor }
    },
    isChecked,
    checkboxTheme
  } = props

  let fill = copyColor

  if (checkboxTheme === 'light') {
    fill = sidebarCopyColor
  }

  if (checkboxTheme === 'best-available') {
    fill = sidebarCopyColor
  }
  if (checkboxTheme === 'discount') {
    fill = sidebarCopyColor
  }

  return css`
    display: flex;
    fill: ${fill};
    align-items: center;
    justify-content: center;
    height: 100%;
    transition: opacity 0.2s;
    opacity: ${isChecked ? 1 : 0};
  `
})

const Label = styled.span<{
  checkboxTheme: CheckboxTheme
  size: CheckboxSizes
}>(props => {
  const {
    theme: {
      colors: { copyColor, sidebarCopyColor, alertColor },
      font: { copy }
    },
    checkboxTheme,
    size
  } = props

  let color = copyColor

  switch (checkboxTheme) {
    case 'light':
    case 'best-available':
    case 'discount':
      color = sidebarCopyColor
      break
    case 'alert':
      color = alertColor
      break
  }
  let fontSize
  switch (size) {
    case 'small':
      fontSize = '0.75rem'
      break
    case 'medium':
      fontSize = '0.875rem'
      break
    case 'large':
      fontSize = '1.2rem'
      break
    case 'default':
      fontSize = '0.75rem'
      break
  }

  return css`
    font-family: ${copy};
    font-size: ${fontSize};
    margin-left: 0.5rem;
    color: ${color};
    line-height: 1.5;
  `
})

const HiddenCheckbox = styled.input.attrs({ type: 'checkbox' })(props => {
  const {
    theme: {
      colors: { mainColor }
    }
  } = props

  return css`
    position: absolute;
    opacity: 0;

    :focus {
      + ${StyledCheckbox} {
        border: 0.125rem solid ${darken(mainColor, 30)};
      }
    }
  `
})

const RequiredWrapper = styled.span`
  background-color: ${props => props.theme.colors.mainBackgroundColor};
  padding: 0.2rem 0.4rem;
  border-radius: 0.1rem;
  font-family: ${props => props.theme.font.copy};
  color: ${props => props.theme.colors.mainBackgroundColorComplement};
  font-size: 0.625rem;
  width: 3rem;
  margin: 0;
`

export const Checkbox = withTheme((props: CheckboxProps) => {
  const {
    label,
    children,
    isChecked = false,
    size = 'default',
    isLabelVisible = true,
    isThemed,
    isPadded,
    onChange,
    checkboxTheme = 'dark',
    name,
    theme: { themeIcon },
    isRequired
  } = props

  const onIcon =
    themeIcon !== undefined && isThemed ? (
      <CheckedIcon isChecked={isChecked} checkboxTheme={checkboxTheme}>
        {themeIcon}
      </CheckedIcon>
    ) : (
      <CheckedIcon isChecked={isChecked} checkboxTheme={checkboxTheme}>
        <Icon icon='check' />
      </CheckedIcon>
    )
  const handleOnChange = () => {
    onChange && onChange(!isChecked)
  }

  return (
    <CheckboxWrapper
      size={size}
      onClick={handleOnChange}
      isPadded={isPadded}
      isRequired={isRequired}
    >
      <HiddenCheckbox
        type='checkbox'
        name={name}
        checked={isChecked}
        aria-label={label}
        onChange={handleOnChange}
      />
      <StyledCheckbox size={size} checkboxTheme={checkboxTheme}>
        {onIcon}
      </StyledCheckbox>
      {label && isLabelVisible && (
        <>
          {children ? (
            <Label size={size} checkboxTheme={checkboxTheme}>
              {children}{' '}
              {isRequired && <RequiredWrapper>Required</RequiredWrapper>}
            </Label>
          ) : (
            <Label
              size={size}
              checkboxTheme={checkboxTheme}
              dangerouslySetInnerHTML={{ __html: label }}
            />
          )}
        </>
      )}
    </CheckboxWrapper>
  )
})
