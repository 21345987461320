import React from 'react'
import styled, { css } from 'styled-components/macro'

interface Props {
  areaName: string
  rowName: string
  seatName: string
  priceLevelColor?: string
  isSidebar?: boolean
}

const SeatTitleWrapper = styled.div<{
  priceLevelColor?: string
  isSidebar?: boolean
}>(props => {
  const {
    theme: {
      colors: { headingColor, sidebarCopyColor }
    },
    priceLevelColor,
    isSidebar
  } = props

  return css`
    padding-left: ${priceLevelColor ? `1.375rem` : 0};
    color: ${isSidebar ? sidebarCopyColor : headingColor};
    position: relative;
    font-size: 1rem;
    line-height: 1.2;

    :before {
      content: '';
      width: 1rem;
      height: 1rem;
      border-radius: 50%;
      background: ${priceLevelColor};
      position: absolute;
      left: 0;
      top: 1px;
    }
  `
})

export const SeatTitle: React.FC<Props> = ({
  areaName,
  seatName,
  rowName,
  priceLevelColor,
  isSidebar
}) => {
  return (
    <SeatTitleWrapper priceLevelColor={priceLevelColor} isSidebar={isSidebar}>
      {`${areaName} ${rowName} ${seatName}`}
    </SeatTitleWrapper>
  )
}
