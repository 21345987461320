import React, { useEffect } from 'react'

// Components
import { Layout, FooterNav } from 'marvel-components'
// Container
import {
  BrandLogoContainer,
  OfflineContainer,
  SiteListContainer
} from 'containers/Hospitality'

// Actions
import { siteActions } from 'modules/hospitality/site'

// Misc
import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { SiteView } from './SiteView'
import { useInactivityModal, usePlatform } from 'utils'

// TODO update with live
// const REDIRECT_SITE_ID = '71013955-7c9e-4d93-9689-2898f4092f5e'
// const REDIRECT_OCCASION_ID = 'ec389739-b051-4043-b093-33509615213d'

const REDIRECT_SITE_ID = '71013955-7c9e-4d93-9689-2898f4092f5e'

const HomeView: React.FC = () => {
  // Routing
  const { path } = useRouteMatch()

  const dispatch = useDispatch()

  // Fetch Data - venue data is used for all views
  useEffect(() => {
    dispatch(siteActions.fetchAll())
  }, [dispatch])

  const platform = usePlatform()

  useInactivityModal({
    shouldOpen: platform === 'kiosk',
    payload: {
      variant: 'kiosk-inactivity'
    }
  })

  return (
    <Switch>
      <Route exact path={`${path}`}>
        {/* NOTE: Temporary workaround until other venues go live. */}
        {/* <HomeContainer /> */}
        <Redirect to={`${path}/site/${REDIRECT_SITE_ID}/occasion`} />
      </Route>
      <Route exact path={`${path}/site`}>
        <Layout
          pageTitle={`Select Hospitality Venue | LW Theatres`}
          renderBrandLogo={<BrandLogoContainer />}
          renderMain={<SiteListContainer />}
          renderFooter={<FooterNav />}
        />
      </Route>
      <Route path={`${path}/site/:siteId`}>
        <SiteView />
      </Route>

      <Route exact path={`${path}/offline`}>
        <OfflineContainer />
      </Route>
    </Switch>
  )
}

export { HomeView }
