import React, { useEffect } from 'react'
import styled from 'styled-components/macro'

// Components
import {
  Calendar,
  PerformanceSelectorHeader,
  PerformanceToggle
} from 'marvel-components'
import { WithLoader } from 'marvel-components'
import { LoginButtonContainer } from 'containers/Ticketing'

// Types
import { NavbarElementProps } from 'react-day-picker'
import { SelectPerformanceViewTypes } from 'shared-types'

// Selectors
import {
  selectIsLoadingPerformances,
  selectFilteredPerformances,
  selectActiveMonth,
  selectIsPerformancesLoaded
} from 'modules/ticketing/performance/selectors'
import { eventDetailsSelectors } from 'modules/ticketing/event'
import {
  getExternalCustomerId,
  selectBookingFlow
} from 'modules/basket/selectors'
import { setBookingFlow } from 'modules/basket/actions'

// Misc
import { lighten, media, trackEventView, usePlatform } from 'utils'
import { useSelector, useDispatch } from 'react-redux'
import { performanceActions } from 'modules/ticketing/performance/performanceSlice'
import moment from 'moment'
interface Props {
  eventId: string
  onPerformanceClick: (performanceId: string) => void
  onViewChange: (view: SelectPerformanceViewTypes) => void
  platform?: string
}

const CalendarWrapper = styled.div<{ platform?: string }>`
  ${media.tablet} {
    min-height: 68vh;
  }
`

const CalendarLoadingWrapper = WithLoader(
  styled.div<{ platform?: string }>`
    height: 100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    padding: 0 1.25rem 1.25rem 1.25rem;
    margin-top: 5rem;

    ${media.tablet} {
      padding: 0 1rem 1rem 1rem;
      margin-top: 0;
      min-height: ${props => (props.platform === 'kiosk' ? '68vh' : 'auto')};
    }
  `
)

const CalendarConditions = styled.span`
  display: block;
  color: ${props => lighten(props.theme.colors.copyColor, 10)};
  font-family: ${props => props.theme.font.copy};
  font-size: 0.75rem;
  margin-top: 1.5rem;
  text-align: left;
  margin-bottom: 2rem;
  padding-bottom: 2rem;
  ${media.tablet} {
    text-align: center;
  }
`

const PerformanceCalendarContainer: React.FC<Props> = ({
  eventId,

  //Functions
  onPerformanceClick,
  onViewChange
}) => {
  const dispatch = useDispatch()
  const platform = usePlatform()

  // Selectors
  const eventName = useSelector(eventDetailsSelectors.selectEventName)
  const imageUrl = useSelector(eventDetailsSelectors.selectEventImageUrl)
  const venueName = useSelector(eventDetailsSelectors.selectVenueName)
  const isLoading = useSelector(selectIsLoadingPerformances)
  const isLoaded = useSelector(selectIsPerformancesLoaded)
  const activeMonth = useSelector(selectActiveMonth)
  const filteredPerformances = useSelector(selectFilteredPerformances)
  const externalId = useSelector(getExternalCustomerId)
  const bookingFlow = useSelector(selectBookingFlow)
  // Analytics
  useEffect(() => {
    dispatch(setBookingFlow('New Purchase')) // Reset the booking flow as we are no longer in up-sells
  }, [dispatch])

  useEffect(() => {
    if (
      eventId &&
      eventName &&
      venueName &&
      filteredPerformances.length > 0 &&
      isLoaded
    ) {
      const { startDate } = filteredPerformances[0]
      const date = moment.utc(startDate)
      const performanceDate = date.format('YYYY-MM-DD')
      const performanceTime = date.format('H-mm')
      trackEventView({
        platform,
        eventName,
        eventId,
        venueName,
        view: 'calendar',
        externalId,
        bookingFlow,
        imageUrl,
        performanceDate,
        performanceTime
      })
    }
  }, [
    eventId,
    eventName,
    venueName,
    externalId,
    platform,
    bookingFlow,
    imageUrl,
    isLoaded
  ])

  const handleMonthChange = (date: Date) =>
    dispatch(performanceActions.setSelectedMonth(date.toISOString()))

  return (
    <CalendarWrapper platform={platform}>
      <CalendarLoadingWrapper isLoading={isLoading} platform={platform}>
        <Calendar
          onPerformanceClick={onPerformanceClick}
          data={filteredPerformances}
          selectedPerformanceDate={activeMonth}
          onMonthChange={handleMonthChange}
          renderNavBar={(nav: NavbarElementProps) => {
            return (
              <PerformanceSelectorHeader
                rightButton={<LoginButtonContainer />}
                // View
                onViewChange={onViewChange}
                selectedView='calendar'
                // Date
                {...nav}
              />
            )
          }}
        />
        <CalendarConditions>
          Performance prices, dates, times and cast are subject to change
          without notice. Prices shown are starting prices.
        </CalendarConditions>

        <PerformanceToggle
          onViewChange={onViewChange}
          selectedView={'calendar'}
          isFooter={true}
        />
      </CalendarLoadingWrapper>
    </CalendarWrapper>
  )
}

export { PerformanceCalendarContainer }
