import React from 'react'
import styled, { css } from 'styled-components'

// Components
import { Button } from 'marvel-components'

// Misc
import { media, darken } from 'utils'

// Types
import { Address } from 'shared-types'

interface Props {
  name?: string
  email?: string
  mobileNumber?: string
  address?: Address
  onEditClick: () => void
}

const UserDetailsWrapper = styled.div((props) => {
  const {
    theme: {
      colors: { secondaryBackgroundColor }
    }
  } = props
  return css`
    position: relative;
    padding: 1rem;
    background: ${darken(secondaryBackgroundColor, 3)};
    margin: 1rem 0;
  `
})

const CopyBlockWrapper = styled.div`
  ${media.tablet} {
    width: 70%;
  }
`

const UserDetailsCopyBlock = styled.div((props) => {
  const {
    theme: {
      colors: { copyColor },
      font: { copy }
    }
  } = props
  return css`
    color: ${copyColor};
    font-family: ${copy};
    font-size: 1rem;
    line-height: 1.5;
    ${media.phone} {
      font-size: 0.875rem;
    }
  `
})

const EditDetails = styled.div`
  position: absolute;
  top: 1rem;
  right: 1rem;

  ${media.tablet} {
    /* position: initial;
    display: flex;
    flex-direction: row-reverse; */
  }
`

export const UserDetails: React.FC<Props> = ({
  name,
  email,
  mobileNumber,
  address,
  onEditClick
}) => {
  const parseAddressToString = (address?: Address) => {
    if (address) {
      const {
        addressLine1,
        addressLine2,
        addressLine3,
        city,
        country,
        postcode
      } = address

      return `
      ${addressLine1} 
      ${addressLine2 ? `, ${addressLine2}` : ''} 
      ${addressLine3 ? `, ${addressLine3}` : ''} 
      ${city ? `, ${city}` : ''} 
      ${country ? `, ${country}` : ''}
      ${postcode ? `, ${postcode}` : ''}`
    }
  }
  return (
    <UserDetailsWrapper>
      <EditDetails>
        <Button
          text="Edit details"
          onClick={onEditClick}
          buttonType="link-button"
          size="small"
        />
      </EditDetails>
      <CopyBlockWrapper>
        <UserDetailsCopyBlock>
          {`
        ${name} `}
          <br />
          {`${email !== undefined ? `${email}` : ''} `}
          <br />
          {`
        ${mobileNumber !== undefined ? `${mobileNumber}` : ''}`}
        </UserDetailsCopyBlock>
        <UserDetailsCopyBlock>
          {parseAddressToString(address)}
        </UserDetailsCopyBlock>
      </CopyBlockWrapper>
    </UserDetailsWrapper>
  )
}
