import { createSlice, PayloadAction, nanoid } from '@reduxjs/toolkit'

// Selectors
import * as basketSelectors from './selectors'

// Actions
import { resetSW } from 'modules/smart-waiter/shared/actions'

// Types
import { SWLineItem, SWProduct } from 'shared-types'

interface RemoveChoiceItemPayload {
  id: EntityId
  parentId: EntityId
}

type AddChoiceItemPayload = SWProduct & {
  parentId: EntityId
}

type AddItemPayload = SWLineItem & {
  menuId: EntityId
}

type ChoicesById = Record<EntityId, SWProduct[]>

type ItemsByMenuId = Record<EntityId, SWLineItem[]>

type BasketState = {
  itemsByMenuId: ItemsByMenuId
  minimumAge: number
  currentItem?: SWProduct
  choicesById: ChoicesById
  timeslot?: string
}

const initialState: BasketState = {
  minimumAge: 0,
  itemsByMenuId: {},
  choicesById: {}
}

const basketSlice = createSlice({
  name: 'basket',
  initialState: initialState,
  reducers: {
    addItemToBasket: {
      reducer(state, action: PayloadAction<AddItemPayload>) {
        const { menuId, ...item } = action.payload
        state.itemsByMenuId[menuId]
          ? state.itemsByMenuId[menuId].push(item)
          : (state.itemsByMenuId[menuId] = [item])
      },
      prepare(payload: SWProduct, menuId: EntityId) {
        return {
          payload: {
            ...payload,
            menuId,
            uid: nanoid()
          }
        }
      }
    },
    removeItemFromBasket: (state, action: PayloadAction<EntityId>) => {
      Object.keys(state.itemsByMenuId).forEach(menuId => {
        const filteredItems = state.itemsByMenuId[menuId].filter(
          item => item.uid !== action.payload
        )
        if (filteredItems.length > 0) {
          state.itemsByMenuId[menuId] = filteredItems
        } else {
          delete state.itemsByMenuId[menuId]
        }
      })
    },
    setCurrentItem: (state, action: PayloadAction<SWProduct>) => {
      state.currentItem = {
        ...action.payload
      }
    },
    setPortionId: (
      state,
      action: PayloadAction<{ id: EntityId; portionId: number }>
    ) => {
      const {
        payload: { portionId }
      } = action
      if (state.currentItem) {
        state.currentItem.portionId = portionId
      }
    },
    setQuantity: (
      state,
      action: PayloadAction<{ id: EntityId; quantity: number }>
    ) => {
      const {
        payload: { quantity }
      } = action
      if (state.currentItem) {
        state.currentItem.quantity = quantity
      }
    },
    addChoice: (state, action: PayloadAction<AddChoiceItemPayload>) => {
      const { parentId, ...choice } = action.payload
      state.choicesById[parentId] = [
        ...(state.choicesById[parentId] || []),
        choice
      ]
    },
    removeChoice: (state, action: PayloadAction<RemoveChoiceItemPayload>) => {
      const { parentId, id } = action.payload
      state.choicesById[parentId] = (state.choicesById[parentId] || []).filter(
        choice => choice.id !== id
      )
    },
    setChoice: (state, action: PayloadAction<AddChoiceItemPayload>) => {
      const { parentId, ...choice } = action.payload
      state.choicesById[parentId] = [choice]
    },
    setTimeslot: (state, action: PayloadAction<string | undefined>) => {
      state.timeslot = action.payload
    }
  },
  extraReducers: builder => {
    builder.addCase(resetSW, state => {
      state.minimumAge = 0
      state.choicesById = {}
      state.currentItem = undefined
      state.itemsByMenuId = {}
      state.timeslot = undefined
    })
  }
})

// Actions
export const basketActions = basketSlice.actions

// Selectors
export { basketSelectors }

export default basketSlice.reducer
