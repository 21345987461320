import React, { useEffect } from 'react'
import styled from 'styled-components/macro'

// Types
import { SelectPerformanceViewTypes } from 'shared-types'

// Selectors
import {
  selectIsLoadingPerformances,
  selectPerformancesFilteredByDate,
  selectLastPerformanceDate,
  selectFirstPerformanceDate,
  selectActiveMonth
} from 'modules/ticketing/performance/selectors'
import { eventDetailsSelectors } from 'modules/ticketing/event'
import {
  getExternalCustomerId,
  selectBookingFlow
} from 'modules/basket/selectors'

// Actions
import { performanceActions } from 'modules/ticketing/performance/performanceSlice'
import { setBookingFlow } from 'modules/basket/actions'

// Components
import {
  WithLoader,
  Title,
  PerformanceListItem,
  PerformanceSelectorHeader,
  PerformanceToggle
} from 'marvel-components'

// Containers
import { LoginButtonContainer } from 'containers/Ticketing'

// Misc
import { useSelector, useDispatch } from 'react-redux'
import { media, trackEventView, usePlatform } from 'utils'
import moment from 'moment'

interface Props {
  eventId: string
  onPerformanceClick: (performanceId: string) => void
  onViewChange: (view: SelectPerformanceViewTypes) => void
}

const PerformanceListWrapper = WithLoader(styled.div`
  /* height: 100%; */
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  padding: 0 1.25rem 1.25rem 1.25rem;
  margin-top: 6rem;

  ${media.tablet} {
    padding: 0 1rem 2rem 1rem;
    margin-top: 0;
  }
`)

const EmptyPerformanceList = styled.div`
  margin: 7rem auto;
  font-family: ${props => props.theme.font.copy};
`
const PerformanceListContainer: React.FC<Props> = ({
  eventId,
  onViewChange,
  onPerformanceClick
}) => {
  const platform = usePlatform()
  const dispatch = useDispatch()

  // Selectors
  const isLoading = useSelector(selectIsLoadingPerformances)
  const activeMonth = useSelector(selectActiveMonth)
  const lastPerformanceDate = useSelector(selectLastPerformanceDate)
  const firstPerformanceDate = useSelector(selectFirstPerformanceDate)
  const eventName = useSelector(eventDetailsSelectors.selectEventName)
  const imageUrl = useSelector(eventDetailsSelectors.selectEventImageUrl)
  const venueName = useSelector(eventDetailsSelectors.selectVenueName)
  const filteredPerformancesByDate = useSelector(
    selectPerformancesFilteredByDate
  )
  const externalId = useSelector(getExternalCustomerId)
  const bookingFlow = useSelector(selectBookingFlow)
  const activeMonthMoment = moment(activeMonth)

  // Analytics
  useEffect(() => {
    dispatch(setBookingFlow('New Purchase')) // Reset the booking flow as we are no longer in up-sells
  }, [dispatch])

  useEffect(() => {
    if (
      eventId &&
      eventName &&
      venueName &&
      filteredPerformancesByDate.length > 0
    ) {
      const { startDate } = filteredPerformancesByDate[0]
      const date = moment.utc(startDate)
      const performanceDate = date.format('YYYY-MM-DD')
      const performanceTime = date.format('H-mm')
      trackEventView({
        platform,
        eventName,
        eventId,
        venueName,
        view: 'performance-list',
        externalId,
        bookingFlow,
        imageUrl,
        performanceDate,
        performanceTime
      })
    }
  }, [
    eventId,
    eventName,
    venueName,
    imageUrl,
    externalId,
    platform,
    bookingFlow,
    filteredPerformancesByDate
  ])

  const handleOnPreviousButton = () =>
    dispatch(
      performanceActions.setSelectedMonth(
        activeMonthMoment.subtract(1, 'month').toISOString()
      )
    )

  const handleOnNextButton = () =>
    dispatch(
      performanceActions.setSelectedMonth(
        activeMonthMoment.add(1, 'month').toISOString()
      )
    )

  return (
    <PerformanceListWrapper isLoading={isLoading}>
      <PerformanceSelectorHeader
        // Date
        showPreviousButton={
          firstPerformanceDate
            ? !firstPerformanceDate.isSame(activeMonthMoment, 'month')
            : true
        }
        showNextButton={
          lastPerformanceDate
            ? !lastPerformanceDate.isSame(activeMonthMoment, 'month')
            : true
        }
        onPreviousClick={handleOnPreviousButton}
        onNextClick={handleOnNextButton}
        month={activeMonth}
        rightButton={<LoginButtonContainer />}
        // View
        onViewChange={onViewChange}
        selectedView='list'
      />
      <Title
        isCapitalised={false}
        isCentralised={false}
        isBold={true}
        size='large'
      >
        Select your date and time
      </Title>
      {filteredPerformancesByDate.length === 0 && (
        <EmptyPerformanceList>No Performances Available</EmptyPerformanceList>
      )}
      {filteredPerformancesByDate.map(performance => (
        <PerformanceListItem
          performance={performance}
          onBookClick={onPerformanceClick}
          key={`${performance.id}`}
        />
      ))}

      <PerformanceToggle
        onViewChange={onViewChange}
        selectedView={'list'}
        isFooter={true}
      />
    </PerformanceListWrapper>
  )
}

export { PerformanceListContainer }
