import { AppState } from 'modules/types'

import { getEntity } from '../entities'

export const getDiscountCode = (state: AppState) => state.discount.discountCode
export const getPromoCode = (state: AppState) => state.discount.promoCode
export const getIsDiscounted = (state: AppState) => !!state.discount.byId
export const getLotteryToken = (state: AppState) => state.discount.lotteryToken

export const getDiscountCodeForBasket = (state: AppState) =>
  getLotteryToken(state)
    ? 'LOTT21'
    : getDiscountCode(state) || getPromoCode(state)

export const getDiscountedPrices = (state: AppState) => {
  const discountedPerformance = getEntity(
    state.entities.discountedPerformance,
    state.discount.byId
  )

  return (
    discountedPerformance &&
    discountedPerformance.pricing &&
    discountedPerformance.pricing.map((priceLevel: any) =>
      getEntity(state.entities.discountPriceLevels, priceLevel)
    )
  )
}
