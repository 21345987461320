import React, { ReactNode } from 'react'
import styled from 'styled-components/macro'
import { Title } from 'marvel-components'
import { media } from 'utils'

interface Props {
  title: string
  children: ReactNode
}

const TitledContainerWrapper = styled.div`
  margin: 2rem 0 3rem 0;
  ${media.tablet} {
    margin: 1rem 0 2rem 0;
  }
`

const PaddedContainer = styled.div`
  background: #fff;
  margin-top: 1rem;
`

export const TitledContainer = (props: Props) => {
  const { children, title } = props

  return (
    <TitledContainerWrapper>
      <Title isCapitalised={false} isCentralised={false} size={'large'}>
        {title}
      </Title>
      <PaddedContainer>{children}</PaddedContainer>
    </TitledContainerWrapper>
  )
}
