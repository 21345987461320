import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// Actions
import { resetSW } from 'modules/smart-waiter/shared/actions'
import { bannerActions } from 'modules/banner'

// Utils
import smartWaiterApi from 'services/smartWaiterApi'
import { createAPIThunk } from 'modules/smart-waiter/shared/actions'

// Types
import {
  SWOrder,
  PaymentChallenge,
  PaymentChallengeResponse,
  SWBasket,
  APIError,
  OptinResponse
} from 'shared-types'
import { AppState } from 'modules/types'
import { AxiosError } from 'axios'

interface CreateOrderPayload {
  venueId: EntityId
  salesAreaId: EntityId
  firstName: string
  lastName: string
  email: string
  telephoneNumber: string
  seatName?: string
  rowName?: string
  seatArea?: number
  amount: number
  baskets: SWBasket[]
  payment?: any
  optins?: OptinResponse[]
  performanceDate?: string
}

interface UpdateOrderPayload {
  orderId: EntityId
  paymentDetails?: PaymentChallengeResponse
  payment?: any
}

interface OrderResponse extends SWOrder {
  action?: PaymentChallenge
}

interface PaymentError {
  message: string
  code?: number
}

const fetchSWOrder = createAsyncThunk<
  OrderResponse,
  string,
  { rejectValue: PaymentError }
>('marvel/smart-waiter/FETCH_ORDER', async (orderId, thunkApi) => {
  const data = await smartWaiterApi.fetchAll(`/sw/order/${orderId}/`)
  return data.data as OrderResponse
})

const createSWOrder = createAPIThunk<OrderResponse, CreateOrderPayload>(
  'marvel/smart-waiter/CREATE_ORDER',
  orderData => smartWaiterApi.create(`/sw/order/`, orderData)
)

const updateSWOrder = createAsyncThunk<
  OrderResponse,
  UpdateOrderPayload,
  { state: AppState; rejectValue: PaymentError }
>(
  'marvel/smart-waiter/UPDATE_ORDER',
  async (orderData, { dispatch, rejectWithValue }) => {
    try {
      const data = await smartWaiterApi.update(
        `/sw/order/${orderData.orderId}/`,
        {
          paymentDetails: orderData.paymentDetails,
          payment: orderData.payment
        }
      )
      return data.data as OrderResponse
    } catch (err) {
      const error: AxiosError<APIError> = err
      let message = 'An unknown error occurred, please try again.'
      if (error.response) {
        message = error.response.data._meta.message
      }
      dispatch(
        bannerActions.setBannerContent({
          bannerType: 'error',
          title: 'Please Note',
          text: message
        })
      )
      return rejectWithValue({ message })
    }
  }
)

interface OrderState {
  loading: string
  order?: SWOrder
  action?: PaymentChallenge
}

const initialState: OrderState = {
  loading: 'idle'
}

const orderSlice = createSlice({
  name: 'order',
  initialState: initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(createSWOrder.pending, state => {
        state.order = undefined
        state.action = undefined
        state.loading = 'loading'
      })
      .addCase(createSWOrder.fulfilled, (state, action) => {
        state.order = action.payload
        state.action = action.payload.action
        state.loading = 'idle'
      })
      .addCase(createSWOrder.rejected, state => {
        state.order = undefined
        state.action = undefined
        state.loading = 'idle'
      })
      .addCase(updateSWOrder.pending, state => {
        state.loading = 'loading'
      })
      .addCase(updateSWOrder.rejected, state => {
        state.loading = 'idle'
        state.action = undefined
      })
      .addCase(updateSWOrder.fulfilled, (state, action) => {
        state.order = action.payload
        state.action = action.payload.action
        state.loading = 'idle'
      })
      .addCase(fetchSWOrder.pending, state => {
        state.order = undefined
        state.action = undefined
        state.loading = 'idle'
      })
      .addCase(fetchSWOrder.fulfilled, (state, action) => {
        state.order = action.payload
        state.loading = 'idle'
      })
      .addCase(resetSW, state => {
        state.loading = 'idle'
        state.order = undefined
        state.action = undefined
      })
  }
})

// Actions
export { createSWOrder, updateSWOrder, fetchSWOrder }

// Selectors
export const orderSelectors = {
  selectOrder: (state: AppState) => state.smartWaiter.order.order,
  selectPaymentChallenge: (state: AppState) => state.smartWaiter.order.action,
  selectIsOrderLoading: (state: AppState) =>
    state.smartWaiter.order.loading === 'loading'
}
export default orderSlice.reducer
