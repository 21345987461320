import React, { MouseEvent } from 'react'
import styled from 'styled-components/macro'
import moment from 'moment'
import {
  media,
  FormattedPrice,
  lighten,
  useIsNarrowViewport,
  useIsMobileViewport,
  usePlatform
} from 'utils'
import {
  Title,
  LazyImage,
  NumberSelector,
  Icon,
  WithTrim
} from 'marvel-components'
import { Product } from 'shared-types'
import { CopyBlock } from 'marvel-components/CopyBlock'

export interface ProductSelectorProps {
  product: Product
  totalSelected?: number
  onMediaClick?: (itemId: string) => void
  onIncrementProduct: (itemId: string) => void
  onDecrementProduct: (itemId: string) => void
  onReadMoreClick?: (itemId: string) => void
  isModal?: boolean
  isFullWidth?: boolean
  platform?: string
  upsellText?: string
}

const ProductSelectorWrapper = styled.div<{
  isModal?: boolean
  isFullWidth?: boolean
  isSoldOut: boolean
  platform?: string
}>`
  display: flex;
  flex-direction: row;
  padding: 1rem 1.5%;
  background: ${props => lighten(props.theme.colors.borderColor, 10)};
  background: ${props =>
    props.isSoldOut
      ? lighten(props.theme.colors.borderColor, 15)
      : lighten(props.theme.colors.borderColor, 10)};
  border-radius: 0.125rem;
  width: 46%;
  margin-bottom: 1rem;
  cursor: pointer;

  :nth-child(odd) {
    margin-right: ${props => (props.isFullWidth ? 0 : '1%')};
  }

  :nth-child(even) {
    margin-left: ${props => (props.isFullWidth ? 0 : '1%')};
  }

  ${media.tablet} {
    // flex-direction: ${props =>
      props.platform === 'kiosk' ? 'row' : 'column'};
    // width: ${props => (props.platform === 'kiosk' ? '46%' : '97%')};
    flex-direction: column;
    width: 97%;
    margin: 0 0 1rem 0;
    padding-bottom: 1rem;

    // :nth-child(odd) {
    //   margin-right: ${props => (props.platform === 'kiosk' ? '1%' : '0')};
    // }

    // :nth-child(even) {
    //   margin-left: ${props => (props.platform === 'kiosk' ? '1%' : '0')};
    // }
  }

  ${media.phone} {
    padding: 1rem 5%;
    width: 90%;
  }

  ${props => props.isFullWidth && `width: 100%;`}
  ${props => props.isFullWidth && `margin-left: 0;`}
  ${props => props.isFullWidth && `margin-right: 0;`}

  ${props =>
    props.isModal &&
    `
      width: 90%;
      flex-direction: column;
      padding: 1.5rem 5%;
      margin: 0;

      :nth-child(odd) {
        margin-right: 0%;
      }

      :nth-child(even) {
        margin-left: 0%;
      }
      ${media.tablet} {
        width: 90%;
        padding: 1.5rem 5%;
        margin: 0;

        :nth-child(odd) {
          margin-right: 0%;
        }

        :nth-child(even) {
          margin-left: 0%;
        }
      }
      ${media.phone} {
        width: 90%;
        padding: 1.5rem 5%;
        margin: 0;

        :nth-child(odd) {
          margin-right: 0%;
        }

        :nth-child(even) {
          margin-left: 0%;
        }
      }
    `}
`

const ProductSelectorContentWrapper = styled.div<{ isModal?: boolean }>`
  display: flex;
  width: 100%;
  ${props =>
    props.isModal &&
    `
        height: auto;
        flex-direction: column;
      `}

  ${media.phone} {
    flex-direction: column;
  }
`

const ProductSelectorMedia = styled.div<{
  isModal?: boolean
  isSoldOut: boolean
  platform?: string
}>`
  position: relative;
  display: flex;
  user-select: none;
  width: 26%;
  max-width: 26%;
  flex: 1 0 auto;
  opacity: ${props => (props.isSoldOut ? '0.5' : '1')};

  ${props =>
    props.isModal &&
    `
        margin: 0 0 1rem 0;
        width: 100%;
        max-width: 100%;
      `}

  ${media.desktop} {
    width: 45%;
    max-width: 45%;
    ${props =>
      props.isModal &&
      `
          width: 100%;
          max-width: 100%;
        `}
  }

  ${media.narrowdesktop} {
    width: 40%;
    max-width: 40%;
    ${props =>
      props.isModal &&
      `
          width: 100%;
          max-width: 100%;
        `}
  }

  ${media.tablet} {
    // width: ${props => (props.platform === 'kiosk' ? '40%' : '25%')};
    // max-width: ${props => (props.platform === 'kiosk' ? '40%' : '25%')};
    width: 25%;
    max-width: 25%;
    ${props =>
      props.isModal &&
      `
          width: 100%;
          max-width: 100%;
        `}
  }

  ${media.phone} {
    width: 100%;
    max-width: 100%;
  }

  min-width: 200px;
`

const ProductSelectorProductInfo = styled.div<{
  isModal?: boolean
  platform?: string
}>`
  display: flex;
  flex-direction: column;
  margin-left: 2%;
  flex: 1;

  ${props =>
    props.isModal &&
    `
        margin-left: 0;
        width: 100%;
        min-width: 100%;
        max-width: 100%;
      `}

  ${media.desktop} {
    width: 51%;
    min-width: 51%;
    max-width: 51%;
    ${props =>
      props.isModal &&
      `
          width: 100%;
          min-width: 100%;
          max-width: 100%;
          margin-left: 0;
        `}
  }

  ${media.narrowdesktop} {
    width: 56%;
    min-width: 56%;
    max-width: 56%;
    ${props =>
      props.isModal &&
      `
          width: 100%;
          min-width: 100%;
          max-width: 100%;
          margin-left: 0;
        `}
  }

  ${media.tablet} {
    min-width: initial;
    max-width: initial;
    
    // width: ${props => (props.platform === 'kiosk' ? '55%' : 'initial')};
    // min-width: ${props => (props.platform === 'kiosk' ? '55%' : 'initial')};
    // max-width: ${props => (props.platform === 'kiosk' ? '55%' : 'initial')};
    // margin-left: ${props => (props.platform === 'kiosk' ? '4%' : '2%')};
    ${props =>
      props.isModal &&
      `
          width: 100%;
          min-width: 100%;
          max-width: 100%;
          margin-left: 0;
        `}
  }

  ${media.phone} {
    width: 100%;
    min-width: 100%;
    max-width: 100%;
    margin-left: 0;
    ${props =>
      props.isModal &&
      `
          width: 100%;
          min-width: 100%;
          max-width: 100%;
          margin-left: 0;
        `}
  }
`

const ProductSelectorProductInfoTextWrapper = styled.div<{ isModal?: boolean }>`
  display: flex;
  flex: 1 0 auto;
  flex-direction: column;

  ${props =>
    props.isModal &&
    `
          margin-left: 0;
          width: 100%;
          min-width: 100%;
          max-width: 100%;
        `}
`

const ProductSelectorHeader = styled.div<{ isModal?: boolean }>`
  display: flex;
  flex-direction: row;
  font-family: ${props => props.theme.font.header};
  color: ${props => props.theme.colors.copyColor};
  justify-content: flex-start;
  line-height: 1.2;
  width: 100%;
  height: 1.25rem;
  margin-bottom: 0.5rem;

  ${media.tablet} {
    flex-direction: column;
  }

  ${media.phone} {
    margin-top: 1rem;
  }

  ${props =>
    props.isModal &&
    `
        height: auto;
        max-height: 5rem;
        margin-bottom: 1rem;
      `}
`

const ProductSelectorTitle = styled(Title)<{
  isModal?: boolean
  isSoldOut: boolean
}>`
  margin-right: 0;
  line-height: 1.2;
  text-overflow: ellipsis;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;

  color: ${props =>
    props.isSoldOut
      ? lighten(props.theme.colors.headingColor, 40)
      : props.theme.colors.headingColor};
  ${media.phone} {
    font-size: 0.875rem;
  }

  ${props =>
    props.isModal &&
    `
        height: auto;
        font-size: 1rem;
        ${media.phone} {
          font-size: 1rem;
        }
      `}
`

const ProductSelectorCallToAction = styled.div<{ isModal?: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-top: 0.75rem;

  ${media.narrowdesktop} {
    margin-top: 1rem;
    left: 3%;
    bottom: 1rem;

    ${props =>
      props.isModal &&
      `
          position: relative;
          left: 0;
          bottom: 0;
          width: 100%;
        `}
  }

  ${media.tablet} {
    margin-top: 0.5rem;
    position: relative;
    left: 0;
    bottom: 0;
    width: 100%;
  }

  ${media.phone} {
    width: 100%;
    margin-top: 0.5rem;
    flex-direction: column;
    align-items: start;
  }

  ${props =>
    props.isModal &&
    `
        margin-top: 1rem;
        ${media.tablet} {
          margin-top: 1rem;
        }
        ${media.phone} {
          margin-top: 1rem;
        }
      `}
`

const TotalProductSelectorsAvailable = styled.div<{ isModal?: boolean }>`
  position: absolute;
  display: flex;
  flex-direction: row;
  justify-content: center;
  font-size: 0.675rem;
  fill: ${props => props.theme.colors.copyColor};
  font-family: ${props => props.theme.font.copy};
  color: ${props => props.theme.colors.copyColor};
  user-select: none;
  left: 50%;
  width: 100%;
  transform: translateX(-50%);
  ${media.tablet} {
    bottom: -1rem;
  }
  ${media.phone} {
    display: none;
  }
  ${props =>
    props.isModal &&
    `
          ${media.phone} {
            display: flex;
          }
        `}
`

const TotalProductSelectorsCopy = styled.span`
  margin-left: 0.5rem;
`

const ProductSelectorCopy = WithTrim(
  styled.span<{
    isModal?: boolean
    platform?: string
  }>`
    font-size: 0.75rem;
    line-height: 1.2;
    font-family: ${props => props.theme.font.copy};
    margin-top: 0.25rem;
    overflow: hidden;

    ${props =>
      props.isModal &&
      `
          height: auto;
          margin: 1rem 0  0.5rem 0;
        `}

    ${media.narrowdesktop} {
      font-size: 0.75rem;
      ${props =>
        props.isModal &&
        `
            font-size: 0.75rem;
          `}
    }

    ${media.tablet} {
      font-size: ${props =>
        props.platform === 'kiosk' ? '0.8rem' : '0.75rem'};

      ${props =>
        props.isModal &&
        `
            font-size: 0.75rem;
            height: auto;
          `}
    }

    ${media.phone} {
      margin-top: 0.25rem;
      font-size: 0.75rem;
      position: relative;

      ${props =>
        props.isModal &&
        `
            height: auto;
            white-space: inherit;
            text-overflow: unset;
            overflow: initial;
            font-size: 0.75rem;
          `}
    }
  `
)

const ImageDateOverlay = styled.div`
  position: absolute;
  width: 100%;
  bottom: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.8);
  height: 1.6rem;
  color: #fff;
  font-size: 0.75rem;
  line-height: 1.2;
  font-family: ${props => props.theme.font.header};
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  text-align: center;

  ${media.phone} {
    font-size: 0.625rem;
    height: 1rem;
  }
`

const NumberSelectorWrapper = styled.div<{ isBlock: boolean }>`
  position: relative;
  ${props =>
    props.isBlock &&
    `
        flex: 1;
      `}

  ${media.phone} {
    margin-top: 1rem;
    width: 100%;
  }
`

const PriceWrapper = styled.div`
  color: ${props => props.theme.colors.copyColor};
  padding: 0.75rem;
  border: 1px solid ${props => props.theme.colors.borderColor};
  border-radius: 0.25rem;
  display: flex;
  flex-direction: row;
  width: fit-content;

  ${media.phone} {
    font-size: 0.875rem;
    margin-bottom: 0.5rem;
  }
`

const SoldOutWrapper = styled.div`
  width: 100%;
  padding: 0.5rem 0;
  background: rgba(0, 0, 0, 0.08);
  ${media.phone} {
    padding: 0.25rem 0;
  }
`

const UpsellTextWrapper = styled.div`
  margin-left: 0.25rem;
`

const ButtonWrapper = styled.div`
  display: flex;
  gap: 1rem;
`

const VideoOverlay = styled.div`
  padding-top: 66.66%;
  position: absolute;
  width: 100%;
  top: 0;
  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #000000;
    opacity: 0.3;
    transition: opacity 0.2s;
  }
  &:hover {
    &:before {
      opacity: 0.5;
    }
  }
`

const PlayIcon = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  filter: drop-shadow(0 0 0.2rem #555);
  fill: #fff;
  font-size: 2rem;
`

const PlayCaption = styled.div`
  position: absolute;
  bottom: 10px;
  left: 10px;
  color: #fff;
  font-size: 0.875rem;
  font-family: ${props => props.theme.font.header};
  filter: drop-shadow(0 0 0.75rem #000);
`

export const ProductSelector = (props: ProductSelectorProps) => {
  const {
    product,
    totalSelected = 0,
    onMediaClick,
    onIncrementProduct,
    onDecrementProduct,
    onReadMoreClick,
    isModal = false,
    isFullWidth = true,
    upsellText
  } = props

  const {
    itemId,
    media,
    name,
    total,
    description,
    maxQuantity,
    dateRequired,
    startDate,
    isSoldOut
  } = product

  const handleIncrementClick = () => !isSoldOut && onIncrementProduct(itemId)
  const handleDecrementClick = () => !isSoldOut && onDecrementProduct(itemId)
  const handleReadMoreClick = (e: MouseEvent) =>
    !isSoldOut && onReadMoreClick && onReadMoreClick(itemId)

  // Device
  const isNarrowViewport = useIsNarrowViewport()
  const isMobileViewport = useIsMobileViewport()
  const platform = usePlatform()

  return (
    <ProductSelectorWrapper
      isModal={isModal}
      onClick={handleReadMoreClick}
      title={isModal ? '' : 'Read more'}
      isSoldOut={isSoldOut}
      isFullWidth={isFullWidth}
      platform={platform}
    >
      <ProductSelectorContentWrapper isModal={isModal}>
        <ProductSelectorMedia
          isModal={isModal}
          isSoldOut={isSoldOut}
          platform={platform}
        >
          <LazyImage
            url={media ? media.thumbnailUrl : ''}
            alt={media ? media.description : 'Placeholder'}
          />
          {dateRequired ? (
            <ImageDateOverlay>
              {startDate &&
                moment.utc(startDate).format('ddd D MMM, h:mm a').toString()}
            </ImageDateOverlay>
          ) : null}

          {media && media.mediaType !== 'image' && (
            <VideoOverlay
              onClick={e => {
                e.preventDefault()
                e.stopPropagation()
                media.mediaUrl && onMediaClick && onMediaClick(media.mediaUrl)
              }}
            >
              <PlayCaption>Play Trailer</PlayCaption>
              <PlayIcon>
                <Icon icon='play' />
              </PlayIcon>
            </VideoOverlay>
          )}
        </ProductSelectorMedia>
        <ProductSelectorProductInfo isModal={isModal} platform={platform}>
          <ProductSelectorProductInfoTextWrapper isModal={isModal}>
            <ProductSelectorHeader isModal={isModal}>
              {name && (
                <ProductSelectorTitle
                  isCapitalised={false}
                  isCentralised={false}
                  size={'medium'}
                  isPadded={false}
                  isModal={isModal}
                  isSoldOut={isSoldOut}
                  isBold={true}
                >
                  {name}
                </ProductSelectorTitle>
              )}
            </ProductSelectorHeader>

            {(isModal || isMobileViewport) && total && (
              <ButtonWrapper>
                <PriceWrapper>
                  <FormattedPrice amount={total} currency='GBP' />
                  {upsellText && (
                    <UpsellTextWrapper>
                      <CopyBlock textSize='small'>{upsellText}</CopyBlock>
                    </UpsellTextWrapper>
                  )}
                </PriceWrapper>
              </ButtonWrapper>
            )}

            {description && !isSoldOut && (
              <ProductSelectorCopy
                isTrimmed={!isModal}
                isModal={isModal}
                trimLength={
                  platform === 'kiosk'
                    ? 400
                    : isMobileViewport
                    ? 250
                    : isNarrowViewport
                    ? 130
                    : 500
                }
                platform={platform}
              >
                {description}
              </ProductSelectorCopy>
            )}
          </ProductSelectorProductInfoTextWrapper>
          <ProductSelectorCallToAction isModal={isModal}>
            {!isModal && !isMobileViewport && total && !isSoldOut && (
              <ButtonWrapper>
                <PriceWrapper>
                  <FormattedPrice amount={total} currency='GBP' />
                  {upsellText && (
                    <UpsellTextWrapper>
                      <CopyBlock textSize='small'>{upsellText}</CopyBlock>
                    </UpsellTextWrapper>
                  )}
                </PriceWrapper>
              </ButtonWrapper>
            )}
            {isSoldOut ? (
              <SoldOutWrapper>
                <CopyBlock isCentralised={true}>Sold Out</CopyBlock>
              </SoldOutWrapper>
            ) : (
              <NumberSelectorWrapper isBlock={isModal}>
                <NumberSelector
                  value={totalSelected}
                  numberSelectorSize={isModal ? 'default' : 'small'}
                  decreaseDisabled={totalSelected === 0}
                  increaseDisabled={totalSelected === maxQuantity}
                  onIncrementClick={handleIncrementClick}
                  onDecrementClick={handleDecrementClick}
                  isThemed={false}
                />
                {maxQuantity && maxQuantity === totalSelected && (
                  <TotalProductSelectorsAvailable isModal={isModal}>
                    <Icon icon='info-circle' />
                    <TotalProductSelectorsCopy>
                      {`Max.${maxQuantity} for your order`}
                    </TotalProductSelectorsCopy>
                  </TotalProductSelectorsAvailable>
                )}
              </NumberSelectorWrapper>
            )}
          </ProductSelectorCallToAction>
        </ProductSelectorProductInfo>
      </ProductSelectorContentWrapper>
    </ProductSelectorWrapper>
  )
}
