// Adapters
import { menuSectionsAdapter } from './reducers'

// Types
import { AppState } from 'modules/types'

export const {
  selectById,
  selectIds,
  selectEntities,
  selectAll,
  selectTotal
} = menuSectionsAdapter.getSelectors<AppState>(
  (state) => state.smartWaiter.menuSections
)

export const isLoading = (state: AppState) =>
  state.smartWaiter.menuSections.loading === 'pending'
