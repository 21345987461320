import reducer from './reducers'
import * as optinSelectors from './selectors'
import * as optinActions from './actions'
import * as optinOperations from './operations'

export {
  optinSelectors,
  optinActions,
  optinOperations
}
export default reducer
export * from './types'
