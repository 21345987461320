import React, { useEffect } from 'react'

// Components
import { Layout } from 'marvel-components'

// Containers
import {
  BrandLogoContainer,
  OrderSummaryContainer,
  OrderUpsellsContainer,
  BasketContainer,
  FooterNavContainer,
  OrderSuccessMenu
} from 'containers/Ticketing'

import { MainMenuContainer } from 'containers'

// Selectors
import { eventDetailsSelectors } from 'modules/ticketing/event'
import {
  selectBookingFlow,
  selectOrder,
  selectReservationGuid,
  selectTransactionState
} from 'modules/basket/selectors'
import { promptSelectors } from 'modules/prompt'

// Actions
import { clearReservation, setBookingFlow } from 'modules/basket/actions'

// Utils
import moment from 'moment'
import {
  trackOrderSuccess,
  trackManageOrder,
  usePlatform,
  useInactivityModal,
  checkoutRoute
} from 'utils'

// Misc
import { useSelector, useDispatch } from 'react-redux'
import { Redirect, useHistory, useLocation, useParams } from 'react-router-dom'
import { useQuery } from 'utils/hooks'

const OrderSuccessView: React.FC = () => {
  const platform = usePlatform()
  const dispatch = useDispatch()
  const { pathname } = useLocation()
  const query = useQuery()
  const track = query.get('ga')
  const history = useHistory()

  // Selectors
  const { orderId } = useParams<{ orderId: string }>()

  const eventName = useSelector(eventDetailsSelectors.selectEventName)
  const venueName = useSelector(eventDetailsSelectors.selectVenueName)
  const imageUrl = useSelector(eventDetailsSelectors.selectEventImageUrl)
  const eventId = useSelector(eventDetailsSelectors.selectEventDetailsId)
  const bookingFlow = useSelector(selectBookingFlow)
  const order = useSelector(selectOrder)
  const reservationGuid = useSelector(selectReservationGuid)
  const transactionState = useSelector(selectTransactionState)
  const isEventLoaded = useSelector(eventDetailsSelectors.selectIsLoaded)
  const isPromptsLoading = useSelector(promptSelectors.selectIsLoading)

  const isLoading = isPromptsLoading || !isEventLoaded

  const performanceDate = moment.utc(
    order?.basket.ticketReservations[0]?.startDate
  )

  // Analytics - Track Order
  // ------------------------------------------
  useEffect(() => {
    if (eventName && eventId && venueName && order) {
      if (order.state === 'CommittedConfirmed') {
        if (track) {
          trackOrderSuccess({
            platform,
            eventName,
            eventId,
            venueName,
            performanceDate: `${performanceDate.format('YYYY-MM-DD')}`,
            performanceTime: `${performanceDate.format('H-mm')}`,
            order,
            bookingFlow: bookingFlow,
            imageUrl: imageUrl
          })
          history.replace(pathname)
        } else {
          trackManageOrder()
        }
      }
      if (order.state === 'CommittedConfirmed' && !track) {
        // We are now in the upsell purchase flow
        dispatch(setBookingFlow('Order Success Page Purchase'))
      }
    }
  }, [
    eventName,
    venueName,
    imageUrl,
    eventId,
    order,
    platform,
    track,
    history,
    pathname,
    bookingFlow,
    dispatch,
    performanceDate
  ])

  useEffect(() => {
    dispatch(clearReservation())
  }, [dispatch])

  useInactivityModal({
    shouldOpen: platform === 'kiosk',
    payload: {
      variant: 'kiosk-inactivity'
    }
  })

  // Redirect for up-sell reservation
  if (
    transactionState &&
    transactionState !== 'CommittedConfirmed' &&
    eventId
  ) {
    return <Redirect to={checkoutRoute(eventId, reservationGuid ?? orderId)} />
  }

  return (
    <Layout
      renderBrandLogo={<BrandLogoContainer />}
      renderSidebarTop={!isLoading && <OrderSummaryContainer />}
      renderMainTop={
        <OrderSuccessMenu
          isLoading={isLoading}
          platform={platform}
          performanceDate={performanceDate}
        />
      }
      renderMain={
        platform === 'kiosk' ? (
          <MainMenuContainer />
        ) : (
          <OrderUpsellsContainer
            transactionGuid={orderId}
            isLoading={isLoading}
          />
        )
      }
      renderSidebarBottom={
        <BasketContainer ctaText='Continue' ctaIcon='lock' />
      }
      renderFooter={<FooterNavContainer />}
    />
  )
}

export { OrderSuccessView }
