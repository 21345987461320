import React from 'react'

// Selectors
import { seatMapSelectors } from 'modules/seat-map'

// Containers
import { TicketAreaContainer } from './TicketAreaContainer'
import { LoginButtonContainer } from 'containers/Ticketing'

// Misc
import styled from 'styled-components/macro'
import { media } from 'utils'
import { useSelector } from 'react-redux'

interface Props {
  performanceId: string
}

const TicketListWrapper = styled.div`
  height: 100%;
  box-sizing: border-box;
  padding: 1.5rem;
  display: flex;
  flex-direction: column;
  ${media.tablet} {
    padding: 1.5rem 1rem;
  }
`

const LoginWrapper = styled.div`
  position: absolute;
  top: 1.5rem;
  right: 1.5rem;
  ${media.tablet} {
    display: none;
  }
`

const TicketListContainer: React.FC<Props> = ({ performanceId }) => {
  const areas = useSelector(seatMapSelectors.selectAreas)
  return (
    <TicketListWrapper>
      <LoginWrapper>
        <LoginButtonContainer />
      </LoginWrapper>
      {areas?.map(area => (
        <TicketAreaContainer
          key={area.id}
          title={area.name}
          areaId={area.id}
          performanceId={performanceId}
        />
      ))}
    </TicketListWrapper>
  )
}

export { TicketListContainer }
