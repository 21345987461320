// Components
import { Button, CopyBlock, Title } from 'marvel-components'

// Actions
import { closeModal } from 'modules/overlay/actions'
import { clearReservation } from 'modules/basket/actions'

// Selectors
import { selectReservationGuid } from 'modules/basket/selectors'

// Misc
import React from 'react'
import styled from 'styled-components'
import { useAppDispatch } from 'app/reduxStore'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { media, kioskMainMenu } from 'utils'

const InactivityWrapper = styled.div`
  padding: 2rem;
  max-width: 23.75rem;

  ${media.tablet} {
    width: 70vw;
  }
`

const InactivityTitleWrapper = styled.span`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`

const StyledButton = styled(Button)`
  margin-top: 1rem;
`

const StyledLinkButton = styled(StyledButton)`
  justify-content: center;
`

const InactivityCopyWrapper = styled.div`
  padding: 1rem 0;
`

const KioskConfirmClearBasketOverlayContainer: React.FC = () => {
  const dispatch = useAppDispatch()
  const history = useHistory()

  const reservationGuid = useSelector(selectReservationGuid)

  const handleCancel = () => {
    dispatch(closeModal())
  }

  const handleRedirect = () => {
    dispatch(clearReservation(reservationGuid))
    dispatch(closeModal())
    history.push(kioskMainMenu())
  }

  return (
    <InactivityWrapper>
      <InactivityTitleWrapper>
        <Title size='large' isBold={true}>
          Are you sure?
        </Title>
      </InactivityTitleWrapper>
      <InactivityCopyWrapper>
        <CopyBlock isCentralised={true}>
          If you go back to the menu now, you’ll lose your reservation.
        </CopyBlock>
      </InactivityCopyWrapper>
      <StyledButton
        text="I'm sure, take me to the menu"
        onClick={handleRedirect}
        isBlock={true}
      />
      <StyledLinkButton
        text='Cancel'
        onClick={handleCancel}
        isBlock={true}
        buttonType='link-button'
        size='small'
      />
    </InactivityWrapper>
  )
}

export { KioskConfirmClearBasketOverlayContainer }
