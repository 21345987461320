import React, { useState, useEffect } from 'react'
import styled from 'styled-components/macro'
import { fadeIn } from 'utils'
import logo from 'assets/lw-logo.svg'
import { media } from 'utils'

interface Props {
  url: string
  alt: string
  onClick?: () => void
}

const LazyImageWrapper = styled.div`
  position: relative;
  width: 100%;
  padding-top: 66.66%; /* 3:2 Aspect Ratio */
  overflow: hidden;
`

const ImagePlaceholder = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  padding-top: 66.66%; /* 3:2 Aspect Ratio */
  background: #cccccc;

  &:before {
    content: '';
    background-image: url(${logo});
    background-repeat: no-repeat;
    position: absolute;
    left: 50%;
    top: 50%;
    width: 5.4rem;
    height: 4rem;
    transform: translate(-50%, -50%);
    ${media.narrowdesktop} {
      background-size: contain;
      top: 62%;
      width: 3rem;
    }
    ${media.tablet} {
      width: 5.4rem;
      top: 50%;
    }
    ${media.phone} {
      width: 2.5rem;
      top: 65%;
    }
  }
`

const ImageWrapper = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  opacity: 0;
  animation: ${fadeIn} 0.5s 0.2s linear forwards;
`

export const LazyImage = ({ url, alt, onClick }: Props) => {
  const [isImageLoaded, setImageLoaded] = useState(false)

  useEffect(() => {
    const lazyImage = new Image()

    if (url) {
      lazyImage.onload = () => {
        setImageLoaded(true)
      }
      lazyImage.src = url

      if (lazyImage.complete) {
        setImageLoaded(true)
      }
    }

    return () => {
      lazyImage.onload = () => {}
    }
  }, [url])

  return (
    <LazyImageWrapper>
      {!isImageLoaded && <ImagePlaceholder />}
      {isImageLoaded && (
        <ImageWrapper src={url} alt={alt} onClick={onClick && onClick} />
      )}
    </LazyImageWrapper>
  )
}
