import React from 'react'
import styled from 'styled-components/macro'
import lightLogo from 'assets/lw_theatres_white.png'
import darkLogo from 'assets/lw_theatres_black.png'
import waterMarkLogo from 'assets/lw_theatres_watermark.png'
import { media } from 'utils'

const LogoWrapper = styled.div`
  width: 3.75rem;
  display: flex;
  align-items: center;
  ${media.phone} {
    width: 3rem;
  }
`

const LogoImage = styled.img`
  width: 100%;
`

interface Props {
  isDark?: boolean
  isWaterMark?: boolean
}

export const LWTheatreLogo = (props: Props) => {
  const { isDark = false, isWaterMark = false } = props

  return (
    <LogoWrapper>
      <LogoImage
        src={isDark ? darkLogo : isWaterMark ? waterMarkLogo : lightLogo}
        alt="LW Theatres logo"
      />
    </LogoWrapper>
  )
}
