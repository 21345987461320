import { AppState } from 'modules'

export const selectFaqStatus = (state: AppState) => state.ticketing.faq.status

export const selectFaqTitle = (state: AppState) => state.ticketing.faq.title

export const selectFaqDescription = (state: AppState) =>
  state.ticketing.faq.description

export const selectFaqQuestions = (state: AppState) =>
  state.ticketing.faq.questions
