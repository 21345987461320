import React from 'react'
import styled from 'styled-components'

// Misc
import { media } from 'utils'

interface Props {
  rightButton?: React.ReactNode
}

const DaySeatsHeaderWrapper = styled.div`
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 5rem;
  z-index: 1;
  pointer-events: none;

  ${media.tablet} {
    display: none;
  }
`

const HeaderSpacer = styled.div`
  flex: 1 0 0;
  width: 22.4rem;
  min-width: 22.4rem;
  max-width: 22.4rem;

  ${media.tablet} {
    display: none;
  }
`

const HeaderContent = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  position: relative;
  align-items: center;
  padding: 0 1.25rem;
  justify-content: flex-end;
  pointer-events: auto;

  :before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    z-index: -1;
  }

  ${media.tablet} {
    justify-content: center;
  }
`

const RightButtonWrapper = styled.div`
  display: inline-block;

  ${media.tablet} {
    display: none;
  }
`

const DaySeatsHeader: React.FC<Props> = ({ rightButton }) => {
  return (
    <DaySeatsHeaderWrapper>
      <HeaderSpacer />
      <HeaderContent>
        {rightButton && <RightButtonWrapper>{rightButton}</RightButtonWrapper>}
      </HeaderContent>
    </DaySeatsHeaderWrapper>
  )
}

export { DaySeatsHeader }
