import ReactGA from 'react-ga'
import ReactGA4 from 'react-ga4'

// Utils
import {
  slugify,
  pushTag,
  setCheckoutAction,
  addProduct,
  setPurchaseAction,
  clearDataLayer
} from './utils'

// Types
import {
  TrackHospitalitySitePayload,
  TrackHospitalityOccasionPayload,
  TrackHospitalityCheckoutPayload,
  TrackHospitalityOrderPayload,
  TrackHospitalityViewItemPayload
} from './types'
import { ECAddProductGA4 } from './typesGA4'

// Step 1 Site View
export const trackHospitalitySite = (action: TrackHospitalitySitePayload) => {
  const { siteName } = action
  const siteSlug = slugify(siteName)
  const url = `/hospitality/site/${siteSlug}`

  // Tag Manager Data Layer
  pushTag({
    module: 'hospitality',
    event: 'pageview',
    pageType: 'site',
    siteName: siteSlug
  })

  // Analytics
  ReactGA.pageview(url)
  ReactGA4.send({ hitType: 'pageview', page: url })
}

// Step 2 Pick Occasion
export const trackHospitalityOccasion = (
  action: TrackHospitalityOccasionPayload
) => {
  const { siteName, occasionName } = action
  const siteSlug = slugify(siteName)
  const occasionSlug = slugify(occasionName)
  const url = `/hospitality/site/${siteSlug}/occasion/${occasionSlug}`

  // Tag Manager Data Layer
  pushTag({
    module: 'hospitality',
    event: 'pageview',
    pageType: 'occasion',
    siteName: siteSlug,
    occasionName: occasionName
  })

  ReactGA.pageview(url)
  ReactGA4.send({ hitType: 'pageview', page: url })
}

export const trackHospitalityViewItem = ({
  bookingFlow,
  item: { id, name, datetime }
}: TrackHospitalityViewItemPayload) => {
  clearDataLayer()

  pushTag({
    module: 'hospitality',
    event: 'view_item',
    purchase_flow: bookingFlow,
    ecommerce: {
      currency: 'GBP',
      items: [
        {
          item_id: id,
          item_name: name,
          currency: 'GBP',
          ...(datetime && {
            item_category: datetime
          }),
          item_category4: 'browser',
          item_category5: 'Afternoon Tea',
          item_variant: '',
          quantity: 1
        }
      ]
    }
  })
}

export const trackHospitalityAddToCart = ({
  bookingFlow,
  item: { id, name, datetime, price, quantity }
}: TrackHospitalityViewItemPayload) => {
  clearDataLayer()

  pushTag({
    module: 'hospitality',
    event: 'add_to_cart',
    purchase_flow: bookingFlow,
    ecommerce: {
      currency: 'GBP',
      value: price,
      items: [
        {
          item_id: id,
          item_name: name,
          currency: 'GBP',
          ...(datetime && {
            item_category: datetime
          }),
          item_category4: 'browser',
          item_category5: 'Afternoon Tea',
          item_variant: '',
          price,
          quantity: quantity ?? 1
        }
      ]
    }
  })
}

export const trackHospitalityBasket = ({
  siteName,
  occasionName,
  reservation,
  menu: { id, name, depositPerAdult, depositPerChild },
  platform
}: TrackHospitalityCheckoutPayload) => {
  // GA
  addProduct({
    id,
    name,
    category: `${reservation.selectedDate}/${reservation.selectedTimeslot}`,
    price:
      depositPerAdult * reservation.adults +
      depositPerChild * reservation.children,
    quantity: reservation.adults + reservation.children,
    dimension9: platform,
    dimension10: 'New Purchase'
  })
  setCheckoutAction(1)

  const siteSlug = slugify(siteName)
  const occasionSlug = slugify(occasionName)
  const url = `/hospitality/site/${siteSlug}/occasion/${occasionSlug}/basket`
  // Analytics
  ReactGA.pageview(url)

  clearDataLayer()

  pushTag({
    module: 'hospitality',
    event: 'begin_checkout',
    purchase_flow: 'New Purchase',
    ecommerce: {
      currency: 'GBP',
      items: [
        {
          item_id: id,
          item_name: name,
          currency: 'GBP',
          price:
            depositPerAdult * reservation.adults +
            depositPerChild * reservation.children,
          ...(reservation.selectedDate &&
            reservation.selectedTimeslot && {
              item_category: `${reservation.selectedDate}/${reservation.selectedTimeslot}`
            }),
          item_category4: platform,
          item_category5: 'Afternoon Tea',
          item_variant: '',
          quantity: reservation.adults + reservation.children ?? 1
        }
      ]
    }
  })
  // Tag Manager Data Layer
  /* pushTag({
    module: 'hospitality',
    event: 'pageview',
    pageType: 'checkout',
    siteName: siteSlug,
    occasionName: occasionName
  }) */

  // GA4
  /*   ReactGA4.send({ hitType: 'pageview', page: url })
  beginCheckoutGA4([
    {
      item_id: id,
      item_name: name,
      currency: 'GBP',
      item_category: `${reservation.selectedDate}/${reservation.selectedTimeslot}`,
      price:
        depositPerAdult * reservation.adults +
        depositPerChild * reservation.children,
      quantity: reservation.adults + reservation.children,
      item_category4: platform,
      item_category5: 'New Purchase'
    }
  ]) */
}

export const trackHospitalityAddPaymentInfo = ({
  bookingFlow,
  paymentType = 'credit_card',
  item: { id, name, datetime, price, quantity }
}: TrackHospitalityViewItemPayload & { paymentType: string }) => {
  clearDataLayer()

  pushTag({
    module: 'hospitality',
    event: 'add_payment_info',
    purchase_flow: bookingFlow,
    ecommerce: {
      currency: 'GBP',
      payment_type: paymentType,
      value: price,
      items: [
        {
          item_id: id,
          item_name: name,
          currency: 'GBP',
          ...(datetime && {
            item_category: datetime
          }),
          item_category4: 'browser',
          item_category5: 'Afternoon Tea',
          item_variant: '',
          price,
          quantity: quantity ?? 1
        }
      ]
    }
  })
}

/* 

export const purchaseGA4 = ({
  items,
  reference,
  revenue,
  shipping,
  tax
}: ECPurchaseGA4) =>
  ReactGA4.gtag('event', 'purchase', {
    transaction_id: `${reference}`,
    affiliation: 'Marvel',
    value: `${revenue}`,
    tax: `${tax || 0}`,
    shipping: `${shipping || 0}`,
    currency: 'GBP',
    items
  })
*/

export const trackHospitalityOrderSuccess = ({
  siteName,
  order,
  platform,
  bookingFlow
}: TrackHospitalityOrderPayload) => {
  const referenceNumber = order.bookingReference
  const orderTotal = order.total

  // GA
  order.event.menus.forEach(menu => {
    addProduct({
      id: `${menu.id}`,
      name: menu.name,
      category: `${order.event.date}/${order.event.time}`,
      price: orderTotal,
      quantity: order.event.adults + order.event.children,
      dimension9: platform,
      dimension10: 'New Purchase'
    })
  })

  setPurchaseAction(referenceNumber, order.total)

  const siteSlug = slugify(siteName)
  const url = `/hospitality/site/${siteSlug}/order/success`

  // Analytics
  ReactGA.pageview(url)
  // GA4
  ReactGA4.send({ hitType: 'pageview', page: url })

  const items: ECAddProductGA4[] = order.event.menus.map(menu => ({
    item_id: `${menu.id}`,
    item_name: menu.name,
    currency: 'GBP',
    item_category: `${order.event.date}/${order.event.time}`,
    price: orderTotal,
    quantity: order.event.adults + order.event.children,
    item_category4: platform,
    item_category5: 'Afternoon Tea'
  }))

  clearDataLayer()

  pushTag({
    module: 'hospitality',
    event: 'purchase',
    purchase_flow: bookingFlow,
    ecommerce: {
      transaction_id: referenceNumber,
      affiliation: 'Marvel',
      value: orderTotal,
      currency: 'GBP',
      tax: 0,
      shipping: 0,
      items
    }
  })
  /*  purchaseGA4({
    items,
    reference: referenceNumber,
    revenue: orderTotal
  }) */
}
