import React from 'react'
import styled from 'styled-components'

// Components
import { SeatMapAnnotation, SeatMapDrawing, ToolTip } from 'marvel-components'

// Containers
import { AreaContainer } from './AreaContainer'
import { LegendContainer } from './LegendContainer'
import { LoginButtonContainer } from 'containers/Ticketing'

// Modules
import { selectSeatMap } from 'modules/seat-map/selectors'

// Misc
import { useSelector } from 'react-redux'
import { useSingleton } from '@tippyjs/react'
import { media, usePlatform } from 'utils'

type Props = {
  performanceId: string
  platform?: string
}

const SeatMapLoader = styled.div<{ platform: string }>`
  width: 100%;
  height: 100%;
  position: relative;
  ${media.tablet} {
    min-height: ${props => (props.platform === 'kiosk' ? '65vh' : '50vh')};
  }
`

const LoginWrapper = styled.div`
  position: absolute;
  top: 1rem;
  right: 1.25rem;
  ${media.tablet} {
    display: none;
  }
`
const SeatMapContainer: React.FC<Props> = ({ performanceId }) => {
  const seatMap = useSelector(selectSeatMap)
  // Tool Tips
  const [source, target] = useSingleton()
  const platform = usePlatform()

  return (
    <SeatMapLoader platform={platform}>
      <ToolTip singleton={source} delay={250} $unthemed={true} />
      <SeatMapDrawing>
        {seatMap?.annotations.map(annotation => {
          const { x, y, width, height, text } = annotation
          return (
            <SeatMapAnnotation
              key={`annotation-${text}`}
              x={x}
              y={y}
              width={width}
              height={height}
              text={text}
            />
          )
        })}
        {seatMap?.areas.map(areaId => (
          <AreaContainer
            key={`area-${areaId}`}
            areaId={areaId}
            performanceId={performanceId}
            toolTipTarget={target}
          />
        ))}
      </SeatMapDrawing>
      <LoginWrapper>
        <LoginButtonContainer />
      </LoginWrapper>
      <LegendContainer />
    </SeatMapLoader>
  )
}

export { SeatMapContainer }
