import { Dictionary } from '@reduxjs/toolkit'

export const getEntity = <Entity>(state: Dictionary<Entity>, id: EntityId) =>
  state?.[id]

export const selectId = (_: any, id: EntityId) => id
export const selectById = <Entity>(
  entities: Dictionary<Entity>,
  id: EntityId
) => entities[id]
