import React, { ReactNode } from 'react'
import styled from 'styled-components/macro'

interface Props {
  isExpanded: boolean
  children: ReactNode
}

const Content = styled.div<{ isExpanded: boolean }>`
  max-height: ${(props) => (props.isExpanded ? 'auto' : 0)};
  overflow: hidden;
  width: 100%;
`

export const Expander = (props: Props) => {
  const { isExpanded, children } = props
  return (
    <Content
      aria-expanded={isExpanded}
      aria-hidden={!isExpanded}
      isExpanded={isExpanded}
    >
      {children}
    </Content>
  )
}
