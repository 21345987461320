import React from 'react'
import icon from './assets/matilda_icon.png'
import backgroundAsset from './assets/matilda_sidebar.jpg'
export const Matilda = {
  colors: {
    mainBackgroundColor: '#011f6a',
    lwBrandColor: '#ffffff',
    lwBrandColorComplement: '#111111',
    mainColor: '#267AB5',
    mainColorComplement: '#ffffff',
    numberFilterSelectorColor: '#ffffff',
    numberFilterSelectorCopyColor: '#111111',
    bestAvailablePerformanceColor: '#35965a'
  },
  themeBackgroundAsset: backgroundAsset,
  themeBackgroundColour: '#011e6a',
  themeIcon: <img width={18} src={icon} alt='theme icon' />,
  orderSuccess: {
    showHospitality: true,
    showCecilBeatonBar: true
  }
}
