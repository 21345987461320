import {
  takeLatest,
  select,
  put,
  call,
  all,
  takeLeading,
  debounce
} from 'redux-saga/effects'
import * as api from 'services/api'

// Basket
import * as actions from './actions'
import {
  ADD_PRODUCT,
  ADD_SEAT_BLOCK,
  REMOVE_SEAT_BLOCK,
  TriggerReservationAction
} from './types'

// Modules
import { sharedOperations } from 'modules/shared'
import { basketActions, basketSelectors } from 'modules/basket'
import { bannerActions } from 'modules/banner'
import { promptSelectors } from 'modules/prompt'

// Misc
import { getKioskAccessToken } from 'modules/self-serve/shared/utils'

// Types
import { Action } from 'redux-actions'
import { clearSessionData, updateSessionData } from 'utils/session'
import { AppState } from 'modules/types'

const RESERVATION_DEBOUNCE = 2000 //ms

// Trigger Reservation
// ---------------------------------------------------
export function* watcherTriggerReservation() {
  yield debounce(
    RESERVATION_DEBOUNCE,
    actions.triggerReservation.type,
    triggerReservation
  )
}

function* triggerReservation(action: TriggerReservationAction) {
  const reservation = yield select(basketSelectors.selectReservation)
  yield put(basketActions.createReservation(reservation))
}

// Fetch Reservation
// ---------------------------------------------------
export function* watcherFetchReservation() {
  yield takeLatest(actions.fetchReservation.TRIGGER, fetchReservation)
}

export const fetchReservation = sharedOperations.fetchEntity.bind(
  null,
  actions.fetchReservation,
  api.fetchReservation
)

// Create Reservation
// ---------------------------------------------------
export function* watcherCreateReservation() {
  yield takeLatest(actions.createReservation.TRIGGER, createReservation)
}

export const createReservation = sharedOperations.createEntity.bind(
  null,
  actions.createReservation,
  api.createReservation
)

export function* watcherModifyReservationSuccess() {
  yield takeLatest(actions.createReservation.SUCCESS, updateSession)
  yield takeLatest(actions.updateReservation.SUCCESS, updateSession)
}

function* updateSession() {
  const contextId = yield select((state: AppState) => state.basket.contextId)
  const transactionId = yield select(
    (state: AppState) => state.basket.transactionId
  )
  const expiresAt = yield select((state: AppState) => state.basket.expiresAt)
  const bookingFlow = yield select(
    (state: AppState) => state.basket.bookingFlow
  )
  yield call(updateSessionData, {
    contextId,
    transactionId,
    expiresAt,
    bookingFlow
  })
}
// Update Reservation
// ---------------------------------------------------
export function* watcherUpdateReservation() {
  yield takeLatest(actions.updateReservation.TRIGGER, updateReservation)
}

export const updateReservation = sharedOperations.updateEntity.bind(
  null,
  actions.updateReservation,
  api.updateReservation
)

// Update Product
// ---------------------------------------------------
export function* watcherProductAdded() {
  yield takeLatest(ADD_PRODUCT, addProduct)
}

function* addProduct(action: Action<string>) {
  const { payload: itemId } = action
  const product = yield select(promptSelectors.selectProductById, itemId)

  yield put(
    bannerActions.setBannerContent({
      bannerType: 'success',
      title: 'Woohoo!',
      text: `${product.name} has been added to your basket.`
    })
  )
}

// Social Distanced Seating block
export function* watcherRemoveSeatBlock() {
  yield takeLatest(REMOVE_SEAT_BLOCK, removeSeatBlock)
}

function* removeSeatBlock(action: any) {
  const {
    areaId,
    id,
    performanceId,
    priceLevelId,
    priceTypeId
  } = action.payload
  const seatIds: number[] = yield select(
    basketSelectors.selectSeatBlockToRemove,
    `${areaId}-${id}`
  )
  // Add original seat Id
  seatIds.push(id)
  yield all(
    seatIds.map(id =>
      put(
        actions.removeSeat({
          id,
          areaId,
          performanceId,
          priceLevelId,
          priceTypeId
        })
      )
    )
  )
}

export function* watcherAddSeatBlock() {
  yield takeLatest(ADD_SEAT_BLOCK, addSeatBlock)
}

function* addSeatBlock(action: any) {
  const {
    areaId,
    id,
    performanceId,
    priceLevelId,
    priceTypeId
  } = action.payload
  const seatIds: number[] = yield select(
    basketSelectors.selectSeatBlock,
    `${areaId}-${id}`
  )
  // Add original seat Id
  seatIds.push(id)
  yield all(
    seatIds.map(id =>
      put(
        actions.addSeat({
          id,
          areaId,
          performanceId,
          priceLevelId,
          priceTypeId
        })
      )
    )
  )
  const number = seatIds.length
  yield put(
    bannerActions.setBannerContent({
      bannerType: 'information',
      title: 'Additional Seats Added to Basket',
      text: `Please note that all ${number} seats within your socially distanced seat group have been added to your basket. Groups are limited to 6 people or 2 households.`
    })
  )
}

// Update Order - with gift voucher
// ---------------------------------------------------
export function* watchUpdateOrder() {
  yield takeLatest(actions.addGiftVoucherToOrder.TRIGGER, addGiftVoucherToOrder)
}

const addGiftVoucherToOrder = sharedOperations.updateEntity.bind(
  null,
  actions.addGiftVoucherToOrder,
  api.addGiftVoucherToOrder
)

export function* watchUpdateOrderSuccess() {
  yield takeLatest(
    actions.addGiftVoucherToOrder.SUCCESS,
    addGiftVoucherToOrderSuccess
  )
}

export function* addGiftVoucherToOrderSuccess() {
  yield put(
    bannerActions.setBannerContent({
      bannerType: 'success',
      title: 'Success',
      text: 'Your gift voucher has been added to your order.'
    })
  )
}

// Create Order
// ---------------------------------------------------
export function* watcherCreateOrder() {
  yield takeLatest(actions.createOrder.TRIGGER, createOrder)
}

function* createOrder(action: any) {
  const contextId = yield select(basketSelectors.getContextId)
  const externalCustomerId = yield select(basketSelectors.getExternalCustomerId)
  const accessToken = getKioskAccessToken()
  const entity = actions.createOrder
  const customer = action.payload.customer
  let customerApiCall
  if (customer.customerId) {
    customerApiCall = yield call(
      api.updateCustomer,
      { ...action.payload.customer },
      contextId,
      accessToken
    )
  } else {
    customerApiCall = yield call(
      api.createCustomer,
      { ...action.payload.customer },
      contextId,
      accessToken
    )
  }
  if (customerApiCall.response) {
    yield put(entity.request({ ...action.payload.order }))
    const { response, error } = yield call(
      api.createOrder,
      {
        ...action.payload.order,
        customerGuid: customerApiCall.response.result
      },
      contextId,
      accessToken,
      externalCustomerId
    )
    if (response) {
      yield put(entity.success({ response, ...action.payload.order }))
    } else {
      const { message } = error
      yield put(entity.failure())

      yield put(
        bannerActions.setBannerContent({
          bannerType: 'error',
          title: 'Please Note',
          text: message
        })
      )
    }
  } else {
    const error = customerApiCall.error
    const { message } = error
    yield put(entity.failure())
    yield put(
      bannerActions.setBannerContent({
        bannerType: 'error',
        title: 'Please Note',
        text: message
      })
    )
  }
  yield put(entity.fulfill())
}

// Update Payment Details
// ---------------------------------------------------
export function* watcherUpdatePaymentDetails() {
  yield takeLatest(actions.updatePaymentDetails.TRIGGER, updatePaymentDetails)
}

export const updatePaymentDetails = sharedOperations.updateEntity.bind(
  null,
  actions.updatePaymentDetails,
  api.updatePaymentDetails
)

// Transaction Expiry
// ---------------------------------------------------
export function* watcherExpireRedirect() {
  yield takeLatest(actions.expireRedirect, expireRedirect)
}

export function* expireRedirect(
  action: ReturnType<typeof actions.clearReservation>
) {
  yield put(
    bannerActions.setBannerContent({
      bannerType: 'information',
      title: 'Basket Expired',
      text: `Your order reservation has expired. Please try again.`
    })
  )
}

export function* watcherClearReservation() {
  yield takeLatest(actions.clearReservation, clearReservation)
}

function* clearReservation(
  action: ReturnType<typeof actions.clearReservation>
) {
  const transactionGuid = action.payload
  yield call(clearSessionData)
  if (transactionGuid) {
    yield put(actions.clearReservationOnServer({ transactionGuid }))
  }
}

export function* watcherClearReservationOnServer() {
  yield takeLeading(actions.clearReservationOnServer, clearReservationOnServer)
}

export const clearReservationOnServer = sharedOperations.updateEntity.bind(
  null,
  actions.clearReservationOnServer,
  api.discardReservation
)

// Order Exchange

export function* watcherCreateExchange() {
  yield takeLatest(actions.createExchange, createExchange)
}

export const createExchange = sharedOperations.fetchEntityNonBlocking.bind(
  null,
  actions.createExchange,
  api.createExchange
)

export function* watcherFetchOrder() {
  yield takeLatest(actions.fetchOrder, fetchOrder)
}

export const fetchOrder = sharedOperations.fetchEntity.bind(
  null,
  actions.fetchOrder,
  api.fetchOrder
)

export function* watcherFetchOrderFromToken() {
  yield takeLatest(actions.fetchOrderFromToken, fetchOrderFromToken)
}

export const fetchOrderFromToken = sharedOperations.fetchEntity.bind(
  null,
  actions.fetchOrderFromToken,
  api.fetchOrderFromToken
)

export function* watcherCreateOrderToken() {
  yield takeLatest(actions.createOrderToken, createOrderToken)
}

export const createOrderToken = sharedOperations.createEntity.bind(
  null,
  actions.createOrderToken,
  api.createOrderToken
)
