import React from 'react'
import styled from 'styled-components/macro'

// Components
import { Title, CopyBlock, Icon, Button, WithLoader } from 'marvel-components'

// Selectors
import { orderSelectors } from 'modules/smart-waiter/order'
import { venueSelectors } from 'modules/smart-waiter/venue'

// Misc
import { media, lighten, FormattedPrice } from 'utils'
import { useSelector } from 'react-redux'
import moment from 'moment'

// Types
import { AppState } from 'modules/types'

type Props = {
  onOrderAgainClick: (e: React.MouseEvent) => void
  salesAreaId: number
  venueId: number
  orderError?: string | null
  error3d?: string | null
}

const OrderCompleteWrapper = WithLoader(styled.div`
  box-sizing: border-box;
  padding: 1.5rem 1.5rem 0 1.5rem;
  background: ${props => props.theme.colors.secondaryBackgroundColor};

  ${media.tablet} {
    padding: 1rem 1rem 1.5rem 1rem;
    margin-top: 0;
  }
`)

const SuccessMessageWrapper = styled.div`
  padding: 1.5rem;
  background: ${props => lighten(props.theme.colors.borderColor, 10)};
  margin-bottom: 1.5rem;
`

const SuccessMessageHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 1rem;
`

const IconWrapper = styled.div`
  background: ${props => props.theme.colors.activeColor};
  border-radius: 50%;
  fill: #ffffff;
  width: 1.5rem;
  height: 1.5rem;
  line-height: 1.6rem; /* Note: Due to dodgy awesome font tick */
  text-align: center;
  font-size: 0.875rem;
  margin-left: 0.5rem;
`

const SuccessMessage = styled.div`
  margin-top: 1rem;
`

const OrderDetails = styled.div`
  margin-top: 1rem;
  font-family: ${props => props.theme.font.copy};
  color: ${props => props.theme.colors.copyColor};
  font-size: 0.75rem;
`

const OrderDetailsTitle = styled.span`
  text-transform: uppercase;
  font-family: ${props => props.theme.font.header};
`

const OrderServiceDetails = styled.div`
  margin-bottom: 1rem;
  &&:last-child {
    margin-bottom: 0;
  }
`

const OrderDetailsLineItem = styled.span`
  display: flex;
  flex-direction: row;
  margin-top: 0.5rem;
`

const OrderDetailsAttribute = styled.span`
  margin-right: 0.25rem;
`

const StyledButton = styled(Button)`
  margin-top: 1rem;
`

const OrderContainer: React.FC<Props> = ({
  orderError,
  error3d,
  onOrderAgainClick,
  salesAreaId,
  venueId
}) => {
  const isLoading = useSelector(orderSelectors.selectIsOrderLoading)
  const order = useSelector(orderSelectors.selectOrder)
  const salesArea = useSelector((state: AppState) =>
    venueSelectors.selectSalesAreaForVenue(state, venueId, salesAreaId)
  )

  return (
    <OrderCompleteWrapper isLoading={isLoading}>
      <SuccessMessageWrapper>
        {!!order &&
        order.status !== 'PAYMENT_FAILED' &&
        order.status !== 'FULFILMENT_FAILED' ? (
          <>
            <SuccessMessageHeader>
              <Title
                isCapitalised={false}
                isCentralised={false}
                isBold={true}
                size='large'
              >
                Order Complete
              </Title>
              <IconWrapper>
                <Icon icon={'check'} />
              </IconWrapper>
            </SuccessMessageHeader>
            <SuccessMessage>
              <CopyBlock>
                {`Thank your for your order from the ${
                  salesArea && salesArea.name
                }`}
                .
              </CopyBlock>
            </SuccessMessage>
            <SuccessMessage>
              <CopyBlock>{`A receipt with your order details has been emailed to ${order.email}.`}</CopyBlock>
            </SuccessMessage>
            <OrderDetails>
              {order.baskets.map(basket => {
                return (
                  <OrderServiceDetails key={basket.id}>
                    <OrderDetailsTitle></OrderDetailsTitle>
                    <OrderDetailsLineItem>
                      <OrderDetailsAttribute>
                        Performance Date:
                      </OrderDetailsAttribute>
                      {moment
                        .utc(order.performanceDate)
                        .format('dddd Do MMM YYYY, h:mm a')
                        .toString()}
                    </OrderDetailsLineItem>
                    {basket.uniqueRef && (
                      <OrderDetailsLineItem>
                        <OrderDetailsAttribute>
                          Order Reference:
                        </OrderDetailsAttribute>
                        {basket.uniqueRef}
                      </OrderDetailsLineItem>
                    )}
                    {basket.total > 0 && (
                      <OrderDetailsLineItem>
                        <OrderDetailsAttribute>
                          Order total:
                        </OrderDetailsAttribute>
                        <FormattedPrice
                          currency='GBP'
                          amount={basket.total}
                          isBold={false}
                        />
                      </OrderDetailsLineItem>
                    )}
                  </OrderServiceDetails>
                )
              })}
            </OrderDetails>
          </>
        ) : !error3d ? (
          <>
            <SuccessMessageHeader>
              <Title
                isCapitalised={false}
                isCentralised={false}
                isBold={true}
                size='large'
              >
                Oops, we may have a problem
              </Title>
            </SuccessMessageHeader>
            <CopyBlock>
              {`We're sorry, it looks like there may be a problem with your order. It may have been that your payment hasn't been confirmed yet. Please refresh the page and if you still see this message,  
              please contact us at customer.relations@lwtheatres.co.uk`}
            </CopyBlock>
          </>
        ) : (
          error3d && (
            <>
              <SuccessMessageHeader>
                <Title
                  isCapitalised={false}
                  isCentralised={false}
                  isBold={true}
                  size='large'
                >
                  Oops, we may have a problem
                </Title>
              </SuccessMessageHeader>
              <CopyBlock>
                <span dangerouslySetInnerHTML={{ __html: error3d }}></span>
              </CopyBlock>
              {/* <StyledButton
                text="Try payment again"
                size="small"
                onClick={handleRetryPayment}
              /> */}
            </>
          )
        )}
      </SuccessMessageWrapper>
      <StyledButton
        isBlock={true}
        text='Order Again'
        onClick={onOrderAgainClick}
      />
    </OrderCompleteWrapper>
  )
}

export { OrderContainer }
