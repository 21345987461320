import { createEntityAdapter, createSlice } from '@reduxjs/toolkit'
import { schema } from 'normalizr'
import hospitalityApi from 'services/hospitalityApi'
import { OptinQuestion } from 'shared-types'
import { createAPIThunk } from '../shared'

const optinSchema = new schema.Entity<OptinQuestion>('optins')

type FetchOptinListResponse = {
  optins?: Record<string, OptinQuestion>
}

export const fetchOptins = createAPIThunk<FetchOptinListResponse>(
  'marvel/hospitality/FETCH_OPTINS',
  () => hospitalityApi.fetchAll(`/optin/`),
  [optinSchema]
)

export const optinAdapter = createEntityAdapter<OptinQuestion>()

type AdditionalState = {
  status: 'idle' | 'pending' | 'fulfilled' | 'rejected'
}

const initialState = optinAdapter.getInitialState<AdditionalState>({
  status: 'idle'
})

const optinSlice = createSlice({
  name: 'optin',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(fetchOptins.pending, state => {
        state.status = 'pending'
      })
      .addCase(fetchOptins.fulfilled, (state, action) => {
        state.status = 'fulfilled'
        if (action.payload.optins) {
          optinAdapter.upsertMany(state, action.payload.optins)
        }
      })
      .addCase(fetchOptins.rejected, state => {
        state.status = 'rejected'
      })
  }
})

export const optinActions = {
  fetchOptins,
  ...optinSlice.actions
}

export default optinSlice.reducer
