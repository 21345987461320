import React, { useState } from 'react'
import axios from 'axios'
import _ from 'lodash'
import buildUrl from 'build-url'
import { AsyncSelect } from 'marvel-components'

import { Address } from 'shared-types'

export interface AddressLookupProps {
  isInvalid?: boolean
  onAddressRetrieve: (address: Address | null) => void
  onBlur?: () => void
  isRequired?: boolean
}

type response = any

interface AddressCapture {
  Text: string
}

interface AddressRetrieve {
  Id: string
}

const LOQATE_DEBOUCE = 200
const LOQATE_ADDRESS_API_ROOT =
  process.env.REACT_APP_LOQATE_ADDRESS_API_ROOT || ''
const LOQATE_API_KEY = process.env.REACT_APP_LOQATE_API_KEY || ''

export const AddressLookup = (props: AddressLookupProps) => {
  const { onAddressRetrieve, isInvalid, onBlur, isRequired = false } = props

  const [keyIndex, setKeyIndex] = useState(0)
  const [addresses, setAddresses] = useState([])

  /**
   * fetchLocate is a helper function to handle the api call via axios.
   * @param endpoint
   * @param fetchParams object of type AddressCapture | AddressRetrieve
   * @param successCallback
   */
  const fetchLocate = (
    endpoint: string,
    fetchParams: AddressCapture | AddressRetrieve,
    successCallback: (response: any) => void
  ) => {
    const url = buildUrl(LOQATE_ADDRESS_API_ROOT, {
      path: endpoint,
      queryParams: {
        Key: LOQATE_API_KEY,
        Language: 'en',
        ...fetchParams
      }
    })

    axios
      .get(url)
      .then(response => {
        successCallback(response)
      })
      .catch(error => {
        console.log(error)
      })
  }

  /**
   * findAddress takes a full/partial address string, makes a request to the loqate api and returns the values in json3 format.
   * @param inputValue full/partical string e.g. Lon/London
   * @param callback
   * @param container is the ID of a specific postcode lookup (represented by 'Id' in the response item)
   */
  const findAddress = (
    inputValue: string,
    callback: any,
    container?: string
  ) => {
    const params = {
      Text: inputValue,
      Container: container
    }

    fetchLocate('/Find/v1.10/json3.ws', params, (response: any) => {
      const addresses = response.data && response.data.Items
      const formattedAddresses = addresses.map((address: any) => ({
        ...address,
        label: `${address.Text} ${address.Description}`,
        value: `${address.Text} ${address.Description}`
      }))

      if (addresses.length === 1 && addresses[0].Type !== 'Address') {
        // If last item is not an address, recurse findAddress with Containter set to Id of item
        findAddress(inputValue, callback, addresses[0].Id)
      } else {
        callback(formattedAddresses)
      }
    })
  }

  const debounceInputChange = _.debounce(
    (inputValue: string, callback: any) => {
      findAddress(inputValue, callback)
    },
    LOQATE_DEBOUCE
  )

  /**
   * handleInputChange recieves keydown input from asyn select and cancels/debounces api calls to loqate service.
   */
  const handleInputChange = (inputValue: string, callback: any) => {
    debounceInputChange.cancel()
    debounceInputChange(inputValue, callback)
  }

  /**
   * handleChange is triggered when an option is selected in Async Select.
   * Note that if the selected option is of type Address, the address is retrived and stored in state.
   * If the selected option is of type Postcode, findAddress is called with the selected item's Text as the inputValue and it's Id as Container.
   * To populate the result in async select, the react key is updated triggering a rerender with defaultMenuIsOpen = true
   * @param selectedAddress
   */
  const handleChange = (selectedAddress: any) => {
    // Selected value is null
    if (!selectedAddress) {
      setAddresses([])
      onAddressRetrieve(null)
    }

    // Find address if Postcode
    selectedAddress &&
      selectedAddress.Type !== 'Address' &&
      findAddress(
        selectedAddress.Text,
        (formattedAddresses: any) => {
          setAddresses(formattedAddresses)
          setKeyIndex(keyIndex + 1)
        },
        selectedAddress.Id
      )

    // Retrieve address if Address
    selectedAddress &&
      selectedAddress.Type === 'Address' &&
      fetchLocate(
        '/Retrieve/v1.00/json3.ws',
        { Id: selectedAddress.Id },
        (response: response) => {
          const {
            Line1,
            Line2,
            Line3,
            City,
            PostalCode,
            CountryName,
            Province
          } = response.data && response.data.Items && response.data.Items[0]

          onAddressRetrieve({
            addressLine1: Line1,
            addressLine2: Line2,
            addressLine3: Line3,
            city: City,
            country: CountryName,
            postcode: PostalCode,
            province: Province
          })
        }
      )
  }

  return (
    <AsyncSelect
      isInvalid={isInvalid}
      placeholder={`Address - type to search...${isRequired ? ' *' : ''}`}
      key={`address-lookup-${keyIndex}`}
      onChange={handleChange}
      onBlur={onBlur}
      loadOptions={handleInputChange}
      defaultOptions={addresses}
      defaultMenuIsOpen={addresses.length > 0}
    />
  )
}
