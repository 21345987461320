import React from 'react'
import styled from 'styled-components/macro'

// Components
import { SeatListItem } from './SeatListItem'
import { ToolTip } from 'marvel-components'

// Types
import { SeatLineItem, TicketWithSeat } from 'shared-types'

// Utils
import { useSingleton } from '@tippyjs/react'

type Props = {
  areaTitle: string
  seatLineItems: SeatLineItem[]
  onSeatClick?: (ticket: SeatLineItem) => void
  onRemoveSeatClick?: (ticket: TicketWithSeat) => void
}

const SeatListWrapper = styled.div`
  text-transform: uppercase;
  color: ${(props) => props.theme.colors.sidebarCopyColor};
  font-family: ${(props) => props.theme.font.copy};
  font-size: 0.625rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 1rem;
`

const SeatListTitle = styled.span`
  font-size: 0.875rem;
  flex: 1;
  margin-right: 0.5rem;
`

const List = styled.div`
  display: flex;
  flex-direction: row;
  flex: 4;
  flex-wrap: wrap;
`

const SeatList: React.FC<Props> = ({
  areaTitle,
  seatLineItems,
  onSeatClick,
  onRemoveSeatClick
}) => {
  const [source, target] = useSingleton()
  return (
    <SeatListWrapper>
      <ToolTip
        singleton={source}
        interactive={true}
        interactiveBorder={10}
        delay={100}
        $unthemed={true}
      />
      <SeatListTitle>{areaTitle}</SeatListTitle>
      <List>
        {seatLineItems.map((seatLineItem) => (
          <SeatListItem
            key={seatLineItem.seatId}
            seatLineItem={seatLineItem}
            onClick={onSeatClick}
            onRemoveClick={onRemoveSeatClick}
            target={target}
          />
        ))}
      </List>
    </SeatListWrapper>
  )
}

export { SeatList }
