import {
  createEntityAdapter,
  createSlice,
  PayloadAction
} from '@reduxjs/toolkit'
import { Performance } from 'shared-types'
import {
  fetchPerformance,
  fetchPerformances,
  fetchPerformancesForExchange
} from './actions'

// Adapters
export const performanceAdapter = createEntityAdapter<Performance>()

type Status = 'idle' | 'pending' | 'fulfilled' | 'rejected'

type AdditionalState = {
  fetchOneStatus: Status
  fetchManyStatus: Status
  selectedMonth?: string
  selectedPerformanceId?: string
  lastFetchPerformances?: string
}

const initialState = performanceAdapter.getInitialState<AdditionalState>({
  fetchOneStatus: 'idle',
  fetchManyStatus: 'idle'
})

// Slice
const performanceSlice = createSlice({
  name: 'performance',
  initialState: initialState,
  reducers: {
    setSelectedMonth: (state, action: PayloadAction<string>) => {
      state.selectedMonth = action.payload
    },
    setCurrentPerformance: (state, action: PayloadAction<string>) => {
      state.selectedPerformanceId = action.payload
    }
  },
  extraReducers: builder => {
    builder
      .addCase(fetchPerformances.pending, state => {
        state.fetchManyStatus = 'pending'
      })
      .addCase(fetchPerformances.rejected, state => {
        state.fetchManyStatus = 'rejected'
      })
      .addCase(fetchPerformances.fulfilled, (state, action) => {
        action.payload.data.performances &&
          performanceAdapter.upsertMany(state, action.payload.data.performances)
        state.fetchManyStatus = 'fulfilled'
      })
      .addCase(fetchPerformance.pending, state => {
        state.fetchOneStatus = 'pending'
      })
      .addCase(fetchPerformance.rejected, state => {
        state.fetchOneStatus = 'rejected'
      })
      .addCase(fetchPerformance.fulfilled, (state, action) => {
        action.payload.data.performances &&
          performanceAdapter.upsertMany(state, action.payload.data.performances)
        state.fetchOneStatus = 'idle'
      })
      .addCase(fetchPerformancesForExchange.pending, state => {
        state.fetchManyStatus = 'pending'
      })
      .addCase(fetchPerformancesForExchange.rejected, state => {
        state.fetchManyStatus = 'rejected'
      })
      .addCase(fetchPerformancesForExchange.fulfilled, (state, action) => {
        action.payload.data.performances &&
          performanceAdapter.upsertMany(state, action.payload.data.performances)
        state.fetchManyStatus = 'idle'
      })
  }
})

export const performanceActions = {
  fetchPerformances,
  fetchPerformance,
  fetchPerformancesForExchange,
  ...performanceSlice.actions
}

export default performanceSlice.reducer
