import styled from 'styled-components'
import { VenueTileGridContainer } from './VenueTileGridContainer'

const Wrapper = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  padding-top: 140px; // TODO: Position is hardcoded, maybe find a better way to do this.
  z-index: 10;
  margin-bottom: 4rem;
`

const Content = styled.div`
  background: #f1f1f1;
  border-radius: 13px;
  box-shadow: 0px 4px 9px rgba(0, 0, 0, 0.17);
  z-index: 2;
  padding: 0 2rem 2rem 2rem;
  display: inline-block;
`

type Props = {
  onWrapperClick?: () => void
}

const VenueDropdownContainer: React.FC<Props> = ({ onWrapperClick }) => {
  return (
    <Wrapper onClick={onWrapperClick && onWrapperClick}>
      <Content onClick={e => e.stopPropagation()}>
        <VenueTileGridContainer columnCount={3} tileSize={256} tileGap={30} />
      </Content>
    </Wrapper>
  )
}

export { VenueDropdownContainer }
