import React, { useEffect } from 'react'

// Actions
import {
  deliveryOptionActions,
  deliveryOptionSelectors
} from 'modules/ticketing/delivery-option'
import { fetchOptins } from 'modules/optin/actions'
import { updateReservation } from 'modules/basket/actions'

// Selectors
import {
  getContextId,
  selectDeliveryOptionId,
  getExternalCustomerId,
  selectTransactionState,
  selectBookingFlow,
  selectCurrentReservation
} from 'modules/basket/selectors'
import { eventDetailsSelectors } from 'modules/ticketing/event'
import {
  selectActivePerformanceCapacity,
  selectActivePerformanceDate
} from 'modules/ticketing/performance/selectors'
import { selectDefaultDeliveryOptionId } from 'modules/ticketing/delivery-option/selectors'

// Containers
import {
  BrandLogoContainer,
  CheckoutOptionsContainer,
  PaymentContainer,
  TransactionSummaryContainer
} from 'containers/Ticketing'

// Misc
import { useDispatch, useSelector } from 'react-redux'
import { Redirect, useParams } from 'react-router-dom'

// Utils
import { trackCheckoutPage, kioskPaymentRoute, usePlatform } from 'utils'
import moment from 'moment'
import { useQuery } from 'utils/hooks'
import { bannerActions } from 'modules/banner'
import { FooterNav, Layout } from 'marvel-components'

type Props = {
  eventId: string
}
const PaymentView: React.FC<Props> = ({ eventId }) => {
  // Routing
  const { transactionGuid } = useParams<{ transactionGuid: string }>()
  const query = useQuery()
  const platform = usePlatform()

  // Selectors
  const { selectDeliveryOptionStatus } = deliveryOptionSelectors

  const dispatch = useDispatch()
  const contextId = useSelector(getContextId)
  const eventName = useSelector(eventDetailsSelectors.selectEventName)
  const venueName = useSelector(eventDetailsSelectors.selectVenueName)
  const imageUrl = useSelector(eventDetailsSelectors.selectEventImageUrl)
  const availability = useSelector(selectActivePerformanceCapacity)
  const bookingFlow = useSelector(selectBookingFlow)
  const performanceDate = useSelector(selectActivePerformanceDate)
  const transactionState = useSelector(selectTransactionState)
  const externalId = useSelector(getExternalCustomerId)
  const deliveryOptionStatus = useSelector(selectDeliveryOptionStatus)
  const defaultDeliveryOptionId = useSelector(selectDefaultDeliveryOptionId)
  const deliveryOptionId = useSelector(selectDeliveryOptionId)
  const currentTransaction = useSelector(selectCurrentReservation)
  // --------------------------------
  // Tag Manager
  // ------------------------------------------
  useEffect(() => {
    if (eventId && eventName && venueName) {
      const date = moment.utc(performanceDate)
      trackCheckoutPage({
        platform,
        eventName,
        eventId,
        venueName,
        ...(performanceDate && {
          performanceDate: `${date.format('YYYY-MM-DD')}`
        }),
        ...(performanceDate && { performanceTime: `${date.format('H-mm')}` }),
        order: currentTransaction,
        externalId: externalId,
        bookingFlow: bookingFlow,
        imageUrl: imageUrl,
        availability
      })
    }
  }, [
    eventName,
    venueName,
    imageUrl,
    availability,
    performanceDate,
    eventId,
    externalId,
    platform,
    bookingFlow // Do not include order or it will track multiple page views
  ])

  const error = query.get('error3d')

  useEffect(() => {
    if (error) {
      dispatch(
        bannerActions.setBannerContent({
          bannerType: 'error',
          title: 'There was an error with your payment',
          text: `${error}`
        })
      )
    }
  }, [error, dispatch])

  // Fetch delivery Options & Optins if transaction exists and if it still needs payment
  useEffect(() => {
    if (contextId && transactionGuid && transactionState === 'Created') {
      dispatch(deliveryOptionActions.fetchDeliveryOptions({ transactionGuid }))
      dispatch(fetchOptins({ transactionGuid }))
    }
  }, [transactionGuid, contextId, dispatch, transactionState])

  useEffect(() => {
    if (
      deliveryOptionStatus === 'fulfilled' &&
      defaultDeliveryOptionId &&
      !deliveryOptionId
    ) {
      dispatch(
        updateReservation({
          transactionGuid,
          basket: {
            delivery: {
              id: defaultDeliveryOptionId
            }
          }
        })
      )
    }
  }, [
    transactionGuid,
    deliveryOptionStatus,
    defaultDeliveryOptionId,
    deliveryOptionId,
    dispatch
  ])

  if (transactionState === 'AwaitingKiosk') {
    return <Redirect to={kioskPaymentRoute(eventId, transactionGuid)} />
  }

  return (
    <Layout
      pageTitle={eventName && `Confirm and book - ${eventName} | LW Theatres`}
      renderBrandLogo={<BrandLogoContainer />}
      renderSidebarTop={<TransactionSummaryContainer />}
      renderSidebarBottom={<FooterNav />}
      renderMainTop={<CheckoutOptionsContainer />}
      renderMain={<PaymentContainer />}
    />
  )
}
export { PaymentView }
