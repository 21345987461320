import React, { useEffect } from 'react'

// Components
import {
  Title,
  BookingFooter,
  // Payment,
  FormCheckbox,
  CForm,
  SubmitValues,
  basicInitialValues,
  FormOptin,
  Icon
} from 'marvel-components'
import { Field } from 'formik'

// Containers
import { LoginButtonContainer } from 'containers/Ticketing'

// Actions
import { createOrder } from 'modules/basket/actions'
import { openModal } from 'modules/overlay/actions'
import { paymentActions } from 'modules/ticketing/payment/paymentSlice'

// Selectors
import {
  selectBasketBalanceInPence,
  selectReservationGuid,
  selectPaymentChallenge,
  selectIsProcessingOrder,
  selectRestrictedSeatsInBasket,
  selectCurrentReservation,
  selectBookingFlow
} from 'modules/basket/selectors'
import { selectAllOptins } from 'modules/optin/selectors'
import { authSelectors } from 'modules/ticketing/auth/authSlice'
import { getLotteryToken } from 'modules/discount/selectors'
import { paymentSelectors } from 'modules/ticketing/payment/paymentSlice'
import { eventDetailsSelectors } from 'modules/ticketing/event'
import { selectActivePerformanceDate } from 'modules/ticketing/performance/selectors'
import { getPromoCode } from 'modules/discount/selectors'

// Misc
import styled from 'styled-components/macro'
import { useDispatch, useSelector } from 'react-redux'
import { trackAddPayment } from 'utils/analytics'

// Utils
import axios from 'axios'
import { media, usePlatform } from 'utils'
import { basketSelectors } from 'modules/basket'
import { identifyCustomer } from 'utils'
import moment from 'moment'
import { isIfStatement } from 'typescript'

const CheckoutWrapper = styled.div`
  box-sizing: border-box;
  padding: 0 1.5rem 1.5rem 1.5rem;
  background: ${props => props.theme.colors.secondaryBackgroundColor};
  ${media.tablet} {
    padding: 1rem;
  }
`

const DetailsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

type AdditionalFormValues = {
  optedIn: boolean
  optins: { [key: string]: string }
}

const PaymentContainer: React.FC = () => {
  const dispatch = useDispatch()
  const platform = usePlatform()

  // Fetch Payment Methods
  // ------------------------------------------
  useEffect(() => {
    dispatch(paymentActions.fetchPaymentMethods('ticketing'))
  }, [dispatch])

  const { selectDeliveryOptionId } = basketSelectors

  // Selectors
  const reservationGuid = useSelector(selectReservationGuid)
  const basketTotalInPence = useSelector(selectBasketBalanceInPence)
  const optinQuestions = useSelector(selectAllOptins)
  const userDetails = useSelector(authSelectors.selectUser)
  const paymentChallenge = useSelector(selectPaymentChallenge)
  const isProcessing = useSelector(selectIsProcessingOrder)
  const isDeliveryOptionSelected = useSelector(selectDeliveryOptionId)

  const paymentMethods = useSelector(paymentSelectors.selectPaymentMethods)
  const currentTransaction = useSelector(selectCurrentReservation)
  const restrictedSeats = useSelector(selectRestrictedSeatsInBasket)
  const token = useSelector(getLotteryToken)
  const promoCode = useSelector(getPromoCode)
  const brands = promoCode === 'frozenvisa' ? ['visa'] : undefined

  const bookingFlow = useSelector(selectBookingFlow)
  const performanceDate = useSelector(selectActivePerformanceDate)
  const eventDetails = useSelector(eventDetailsSelectors.selectEventDetails)
  // Actions
  // Show 3ds Modal if needed
  useEffect(() => {
    if (paymentChallenge && reservationGuid) {
      dispatch(
        openModal({
          variant: 'three-ds-ticketing',
          props: {
            transactionGuid: reservationGuid,
            challenge: paymentChallenge
          }
        })
      )
    }
  }, [paymentChallenge, reservationGuid, dispatch])

  const markLotteryTokenAsUsed = () => {
    if (token) {
      axios.post(`https://api.campaigns.lineupnow.com/entry/${token}/redeem/`)
    }
  }
  const handleOnSubmit = (values: SubmitValues<AdditionalFormValues>) => {
    if (platform !== 'kiosk' && !values.paymentData && basketTotalInPence > 0) {
      return
    }
    const date = moment.utc(performanceDate)
    let paymentType = 'credit_card'
    if (values.paymentData) {
      if (values.paymentData.paymentMethod) {
        if (values.paymentData.paymentMethod.brand) {
          paymentType = values.paymentData.paymentMethod.brand
        } else if (values.paymentData.paymentMethod.scheme) {
          paymentType = values.paymentData.paymentMethod.scheme
        }
      }
    }
    if (eventDetails) {
      trackAddPayment({
        platform: platform,
        eventName: eventDetails.name,
        eventId: eventDetails.id,
        venueName: eventDetails.venue.name,
        ...(performanceDate && {
          performanceDate: `${date.format('YYYY-MM-DD')}`
        }),
        ...(performanceDate && { performanceTime: `${date.format('H-mm')}` }),
        order: currentTransaction,
        bookingFlow: bookingFlow,
        paymentType: paymentType,
        imageUrl: ''
      })
    }

    markLotteryTokenAsUsed()
    identifyCustomer(values.email)
    const optins = Object.keys(values.optins).map((key: string) => ({
      id: key,
      value: values.optins[key]
    }))
    const order = {
      transactionGuid: reservationGuid,
      payment: values.paymentData
    }
    const customer = {
      firstName: values.firstName,
      lastName: values.lastName,
      email: values.email,
      telephoneNumber: values.telephoneNumber,
      address: values.address,
      optedIn: values.optedIn,
      optins: optins
    }
    restrictedSeats.length > 0
      ? dispatch(
          openModal({
            variant: 'seat-restrictions',
            props: {
              order,
              customer
            }
          })
        )
      : dispatch(createOrder({ order, customer }))
  }

  const initialValues = {
    optedIn: false,
    optins: {},
    ...basicInitialValues,
    customer: {
      ...basicInitialValues.customer,
      ...userDetails
    }
  }

  return (
    <CheckoutWrapper>
      <DetailsWrapper>
        <Title
          isCapitalised={false}
          isCentralised={false}
          size='medium'
          isBold={true}
          isPadded={true}
        >
          Stay in Touch
        </Title>
        <LoginButtonContainer isSmall />
      </DetailsWrapper>
      <CForm<AdditionalFormValues>
        initialValues={initialValues}
        isLoading={isProcessing}
        onSubmit={handleOnSubmit}
        amount={basketTotalInPence} // Needs to be in minor unit for express
        includeAddress={true}
        showManualAddress={userDetails?.address ? true : false}
        paymentMethods={paymentMethods}
        brands={brands}
        previousSectionsCompleted={!!isDeliveryOptionSelected}
        additionalFields={
          <>
            <Field
              name='optedIn'
              label='Yes, I would like to hear from LW Theatres on shows, pre-sales, experiences and special offers.'
              isLabelVisible={true}
              size={'small'}
              checkboxTheme={'dark'}
              component={FormCheckbox}
            />
            {optinQuestions.map(optin => (
              <Field
                key={`optin-${optin.id}`}
                name={`optins.${optin.id}`}
                label={optin.text}
                isLabelVisible={true}
                size={'small'}
                checkboxTheme={'dark'}
                replaceAnchorsWithLinks={true}
                component={FormOptin}
              />
            ))}
          </>
        }
      />
      <BookingFooter />
    </CheckoutWrapper>
  )
}

export { PaymentContainer }
