import React, { useContext } from 'react'

// Components
import { Title, NumberSelector, PriceSlider } from 'marvel-components'

// Selectors
import {
  selectPriceLevelPrices,
  selectSelectedPrices,
  selectAllPriceLevels,
  selectActivePerformanceId,
  selectActivePerformanceDate
} from 'modules/ticketing/performance/selectors'

import {
  selectIsBestAvailable,
  selectSeatItemCount,
  selectReservationGuid,
  selectNextSeat,
  selectBookingFlow
} from 'modules/basket/selectors'

// Actions
import { priceLevelActions } from 'modules/ticketing/performance/priceLevelSlice'
import {
  incrementBestAvailable,
  decrementBestAvailable,
  triggerReservation,
  removeSeat,
  addSeat,
  clearReservation
} from 'modules/basket/actions'

// Misc
import styled from 'styled-components/macro'
import { useTypedSelector } from 'modules'
import {
  media,
  darken,
  useIsNarrowViewport,
  usePlatform,
  trackAddToBasketTicket,
  trackRemoveFromBasketTicket
} from 'utils'
import { useDispatch, useSelector } from 'react-redux'
import { ThemeContext } from 'styled-components'
import { shouldHideSeatMap } from 'utils/performances'
import moment from 'moment'
import { eventDetailsSelectors } from 'modules/ticketing/event'

const SeatMapControlWrapper = styled.div`
  padding-bottom: 2rem;
  ${media.tablet} {
    padding-bottom: 0;
  }
`

const ControlWrapper = styled.div``

const FilterWrapper = styled.div<{ platform?: string }>`
  margin: 0;
  display: flex;
  flex-direction: column;
  ${media.tablet} {
    margin: 0;
    flex-direction: row;
    align-items: center;
    background: ${props => darken(props.theme.colors.mainBackgroundColor, 10)};
    padding: ${props =>
      props.platform === 'kiosk'
        ? '1.5rem 1rem 1.5rem 0'
        : '0.5rem 1rem 0.5rem 0'};

    ${Title} {
      margin: 0 3rem 0 1rem;
      padding: 0;
      letter-spacing: 1px;
    }
  }

  ${media.phone} {
    ${Title} {
      letter-spacing: 1px;
    }
  }
`
const PriceSliderWrapper = styled(FilterWrapper)`
  ${media.tablet} {
    display: none;
  }
`

const NumberSelectorWrapper = styled.div`
  ${media.tablet} {
    border-right: none;
    background: none;
    width: 40%;
    max-width: 40%;
    margin-left: auto;
  }
`

type Props = {
  showPriceFilter?: boolean
}
const SeatMapControlContainer: React.FC<Props> = ({
  showPriceFilter = true
}) => {
  const dispatch = useDispatch()

  // Context
  const theme = useContext(ThemeContext)

  // Device
  const platform = usePlatform()
  const isNarrowViewport = useIsNarrowViewport()

  // Selectors
  const prices = useTypedSelector(selectPriceLevelPrices)
  const selectedPrices = useTypedSelector(selectSelectedPrices)
  const priceLevels = useTypedSelector(selectAllPriceLevels)
  const seatCount = useSelector(selectSeatItemCount)
  const isBestAvailable = useSelector(selectIsBestAvailable)
  const reservationGuid = useSelector(selectReservationGuid)
  const nextSeat = useSelector(selectNextSeat)
  const performanceId = useSelector(selectActivePerformanceId)
  const bookingFlow = useSelector(selectBookingFlow)
  const eventName = useSelector(eventDetailsSelectors.selectEventName)
  const performanceDate = useSelector(selectActivePerformanceDate)
  const eventId = useSelector(eventDetailsSelectors.selectEventDetailsId)

  // Actions
  const handleTicketIncrement = () => {
    let price = priceLevels[0].priceTypes[0].total
    if (seatCount === 0 || isBestAvailable) {
      dispatch(incrementBestAvailable())
      dispatch(triggerReservation())
    } else if (nextSeat) {
      const priceLevel = priceLevels.find(
        pl => pl.id === nextSeat.priceLevelGuid
      )
      if (priceLevel) {
        price = priceLevel.priceTypes[0].total
        dispatch(
          addSeat({
            id: nextSeat.id,
            areaId: nextSeat.areaId,
            performanceId: performanceId,
            priceLevelId: nextSeat.priceLevelGuid,
            priceTypeId: priceLevel.priceTypes[0].id
          })
        )
      }
    }
    const date = moment.utc(performanceDate)
    trackAddToBasketTicket({
      id: eventId,
      name: eventName,
      price: price,
      performanceDate: date.format('YYYY-MM-DD'),
      performanceTime: date.format('H-mm'),
      platform,
      bookingFlow
    })
  }
  const handleTicketDecrement = () => {
    if (seatCount === 1) {
      // Last seat so clear the reservation
      dispatch(clearReservation(reservationGuid))
    } else if (seatCount === 0 || isBestAvailable) {
      // Best available so de-increment the basket
      dispatch(decrementBestAvailable())
      dispatch(triggerReservation())
    } else {
      // Just remove the last seat
      dispatch(removeSeat())
    }

    const date = moment.utc(performanceDate)
    const priceLevel = priceLevels.find(
      pl => pl.id === nextSeat?.priceLevelGuid
    )
    if (!priceLevel) return

    trackRemoveFromBasketTicket({
      id: eventId,
      name: eventName,
      price: priceLevel.priceTypes[0].total,
      performanceDate: date.format('YYYY-MM-DD'),
      performanceTime: date.format('H-mm'),
      platform,
      bookingFlow
    })
  }

  const handlePriceSliderChange = (lowerValue: number, higherValue: number) => {
    if (higherValue && lowerValue) {
      const selectedPriceLevels = priceLevels.filter(priceLevel => {
        const priceLevelValue = priceLevel.priceTypes[0].total
        return priceLevelValue < lowerValue || priceLevelValue > higherValue
      })
      selectedPriceLevels &&
        dispatch(
          priceLevelActions.setPriceLevelFilters([
            ...selectedPriceLevels.map(priceLevel => priceLevel.id)
          ])
        )
    }
  }

  return (
    <SeatMapControlWrapper>
      <ControlWrapper>
        <FilterWrapper platform={platform}>
          <Title
            isSidebar={true}
            size={
              platform === 'kiosk' && isNarrowViewport ? 'large' : undefined
            }
          >
            Number of Tickets
          </Title>
          <NumberSelectorWrapper>
            <NumberSelector
              value={seatCount}
              decreaseDisabled={seatCount === 0}
              increaseDisabled={false}
              onIncrementClick={handleTicketIncrement}
              onDecrementClick={handleTicketDecrement}
              isSidebar={true}
              hideButtons={!!theme.sociallyDistanced}
            />
          </NumberSelectorWrapper>
        </FilterWrapper>
        {showPriceFilter && !shouldHideSeatMap(performanceId) ? (
          <PriceSliderWrapper>
            <Title isSidebar={true}>Price</Title>
            <PriceSlider
              onChange={handlePriceSliderChange}
              values={prices}
              lowerValue={selectedPrices[0]}
              upperValue={selectedPrices[selectedPrices.length - 1]}
            />
          </PriceSliderWrapper>
        ) : null}
      </ControlWrapper>
    </SeatMapControlWrapper>
  )
}

export { SeatMapControlContainer }
