// Misc
import { createSlice, createEntityAdapter } from '@reduxjs/toolkit'

// Actions
import { fetchMenu } from 'modules/smart-waiter/menu/actions'

// Types
import { MenuSection } from 'shared-types'

export const menuSectionsAdapter = createEntityAdapter<MenuSection>({
  sortComparer: (a, b) => a.sortOrder - b.sortOrder
})

const menuSectionsSlice = createSlice({
  name: 'menuSections',
  initialState: menuSectionsAdapter.getInitialState({
    loading: 'idle'
  }),
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchMenu.pending, (state, action) => {
      state.loading = 'pending'
    })
    builder.addCase(fetchMenu.fulfilled, (state, action) => {
      if (action.payload.sections) {
        menuSectionsAdapter.setAll(state, action.payload.sections)
      }
      state.loading = 'idle'
    })
  }
})

export default menuSectionsSlice.reducer
