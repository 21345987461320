import React, { useContext } from 'react'

// Components
import {
  Icon,
  StickyFooter,
  PrimaryButton,
  Button,
  TotalTitle,
  BasketTicketItems,
  BasketSeatItems,
  BasketProductItems
} from 'marvel-components'

// Selectors
import {
  selectTicketLineItems,
  selectSeatLineItemsByArea,
  selectProductLineItems,
  selectIsBestAvailable,
  selectAllItemCount,
  selectBasketLoading,
  selectBasketTotal,
  selectDiscount,
  selectReservation,
  selectIsBasketDirty,
  selectReservationGuid
} from 'modules/basket/selectors'

// Actions
import {
  clearReservation,
  removeSeat,
  createReservation,
  removeTicketLineItem,
  removeSeatBlock,
  removeProductLineItem
} from 'modules/basket/actions'

// Misc
import styled, { ThemeContext } from 'styled-components/macro'
import { useSelector, useDispatch } from 'react-redux'

// Utils
import { media, fadeIn, darken, transitionIn } from 'utils'

// Types
import { IconName, TicketWithSeat, Ticket } from 'shared-types'

const BasketBackground = styled.div`
  margin-top: auto;
  ${media.tablet} {
    position: relative;
    background: ${props =>
      props.theme.themeBackgroundColour
        ? props.theme.themeBackgroundColour
        : props.theme.colors.mainBackgroundColor};
  }
`

const BasketWrapper = styled.div`
  position: relative;
`

const BestAvailableBannerWrapper = styled.div`
  ${media.phone} {
    padding: 0.5rem 0 0 1rem;
  }
`
const BestAvailableBanner = styled.div`
  background: ${props => props.theme.colors.bestAvailableBannerColor};
  color: ${props => props.theme.colors.bestAvailableBannerColorComplement};
  padding: 0.75rem 1rem;
  font-family: ${props => props.theme.font.header};
  font-size: 0.75rem;
  animation: ${fadeIn} 0.4s ease forwards;
  position: relative;

  ${media.phone} {
    font-size: 0.5rem;
    padding: 0.25rem;
    width: 29%;

    text-align: center;
    border-radius: 0.1rem;
  }
`

const ReservingSpinner = styled.div`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%);
  fill: #fff;
`

const BasketContentWrapper = styled.div<{ isDisabled?: boolean }>`
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 1.5rem 1rem 0 1rem;
  animation: ${transitionIn} 0.3s ease forwards;

  ${props =>
    props.isDisabled &&
    `
      visibility: hidden;
    `}
  ${media.phone} {
    :before {
    }
    padding: 0.75rem 1rem 6rem 1rem;
  }
`
const Break = styled.hr`
  margin: 0 0 1rem 0;
  border-top: 0.0625rem solid
    ${props => darken(props.theme.colors.borderColor, 20)};
  border-bottom: none;
  border-left: none;
  border-right: none;
`
const ClearAllButton = styled(Button)`
  margin: 0 0 1rem 0;
  justify-content: flex-start;
`

type Props = {
  ctaText?: string
  ctaIcon?: IconName
  onReservationSuccess?: () => void
}
const BasketContainer: React.FC<Props> = ({
  onReservationSuccess,
  ctaText = 'Book Now',
  ctaIcon = 'lock'
}) => {
  const dispatch = useDispatch()
  const theme = useContext(ThemeContext)

  // Selectors
  const tickets = useSelector(selectTicketLineItems)
  const seatsByArea = useSelector(selectSeatLineItemsByArea)
  const products = useSelector(selectProductLineItems)
  const isBestAvailable = useSelector(selectIsBestAvailable)
  const basketItemCount = useSelector(selectAllItemCount)
  const isLoading = useSelector(selectBasketLoading)
  const basketTotal = useSelector(selectBasketTotal)
  const reservationGuid = useSelector(selectReservationGuid)
  const basket = useSelector(selectReservation)
  const isBasketDirty = useSelector(selectIsBasketDirty)
  const totalDiscount = useSelector(selectDiscount)

  const handleClearBasket = () => {
    dispatch(clearReservation(reservationGuid))
  }

  const handleRemoveSeat = (seat: TicketWithSeat) => {
    const ticket = {
      id: seat.id,
      areaId: seat.areaId,
      performanceId: seat.performanceId,
      priceLevelId: seat.priceLevelId,
      priceTypeId: seat.priceTypeId
    }
    theme.sociallyDistanced
      ? dispatch(removeSeatBlock(ticket))
      : dispatch(removeSeat(ticket))
  }

  const handleRemoveProduct = (itemId: string) => {
    dispatch(removeProductLineItem(itemId))
  }

  const handleRemoveTicket = (ticket: Ticket) => {
    dispatch(removeTicketLineItem(ticket))
  }

  const handleBookNowClick = () => {
    if (isBasketDirty) {
      dispatch(
        createReservation({
          data: basket,
          onSuccessCallback: () =>
            onReservationSuccess && onReservationSuccess()
        })
      )
    } else {
      onReservationSuccess && onReservationSuccess()
    }
  }
  return (
    <>
      {basketItemCount > 0 && (
        <BasketBackground>
          <StickyFooter>
            <BasketWrapper>
              {isBestAvailable && (
                <BestAvailableBannerWrapper>
                  <BestAvailableBanner>
                    Best in your price range:
                  </BestAvailableBanner>
                </BestAvailableBannerWrapper>
              )}
              {isLoading && (
                <ReservingSpinner>
                  <Icon icon='circle-notch' isSpinning />
                </ReservingSpinner>
              )}
              <BasketContentWrapper isDisabled={isLoading}>
                {tickets.length > 0 && (
                  <>
                    <BasketTicketItems
                      tickets={tickets}
                      onRemoveTicketClick={handleRemoveTicket}
                    />
                    <Break />
                  </>
                )}
                {Object.keys(seatsByArea).length > 0 && (
                  <>
                    <BasketSeatItems
                      seatsByArea={seatsByArea}
                      onRemoveSeatClick={handleRemoveSeat}
                    />
                    <Break />
                  </>
                )}
                {products.length > 0 && (
                  <>
                    <BasketProductItems
                      products={products}
                      onRemoveProduct={handleRemoveProduct}
                    />
                    <Break />
                  </>
                )}
                <ClearAllButton
                  buttonType='link-button'
                  text='Empty Basket'
                  size='extra-small'
                  icon='trash-alt'
                  onClick={handleClearBasket}
                  isSidebar={true}
                  isDisabled={isLoading}
                />
                <TotalTitle
                  fullValue={basketTotal}
                  discountedValue={totalDiscount}
                />
              </BasketContentWrapper>
              <PrimaryButton
                text={ctaText}
                icon={ctaIcon}
                onClick={handleBookNowClick}
                isDisabled={isLoading}
              />
            </BasketWrapper>
          </StickyFooter>
        </BasketBackground>
      )}
    </>
  )
}

export { BasketContainer }
