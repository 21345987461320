import React from 'react'
import { useField, FieldHookConfig } from 'formik'
import styled, { css } from 'styled-components/macro'
import { ErrorMessage } from 'formik'
import { lighten, darken } from 'utils'
import { Icon, FormError } from 'marvel-components'

interface Props {
  rows?: number
  isDisabled?: boolean
  isRequired?: boolean
  placeholder?: string
  autocomplete?: string
  readOnly?: boolean
}

export const TextArea = styled.textarea<{ isDisabled?: boolean }>(props => {
  const {
    theme: {
      colors: { copyColor },
      font: { input }
    },
    isDisabled = false
  } = props

  return css`
    border-radius: 0.125rem;
    border: none;
    font-size: 1rem;
    line-height: normal;
    padding: 0.5rem;
    outline: none;
    font-family: ${input};
    flex: 1 0 auto;
    background: #fff;
    color: ${copyColor};
    resize: none;
    ::placeholder {
      color: ${lighten(copyColor, 40)};
      ${isDisabled &&
        css`
          opacity: 0.4;
        `};
    }

    :-webkit-autofill,
    :-webkit-autofill:hover,
    :-webkit-autofill:focus {
      box-shadow: 0 0 0 50px white inset;
      -webkit-text-fill-color: ${copyColor};
    }
    :read-only {
      color: ${lighten(copyColor, 40)};
    }
  `
})

const FormInputWrapper = styled.div(props => {
  const {
    theme: {
      font: { input }
    }
  } = props

  return css`
    position: relative;
    display: flex;
    flex: 1 0 auto;
    flex-direction: column;
    box-sizing: border-box;
    font-family: ${input};
    padding-bottom: 1.5rem;
  `
})

const ErrorLabel = styled(FormError)(props => {
  const {
    theme: {
      colors: { alertColor }
    }
  } = props

  return css`
    + ${InputWrapper} {
      border: 0.0625rem solid ${alertColor};
    }
  `
})

const InputWrapper = styled.div<{ isDisabled: boolean }>(props => {
  const {
    isDisabled,
    theme: {
      colors: { borderColor }
    }
  } = props

  return css`
    display: flex;
    position: relative;
    align-items: center;
    flex: 1 0 auto;
    border-radius: 0.125rem;
    border: 1px solid ${borderColor};
    background: #ffffff;

    :focus-within {
      border: 0.0625rem solid ${darken(borderColor, 40)};
    }

    ${isDisabled &&
      css`
        border: 0.0625rem solid ${lighten(borderColor, 10)};
      `}
  `
})

const ErrorIcon = () => {
  return (
    <ErrorIconWrapper>
      <Icon icon='exclamation-circle' />
    </ErrorIconWrapper>
  )
}

const ErrorIconWrapper = styled.div(props => {
  const {
    theme: {
      colors: { alertColor }
    }
  } = props
  return css`
    fill: ${alertColor};
    right: 0.5rem;
    position: absolute;
    top: 0.65rem;
  `
})

export const FormTextArea: React.FC<Props & FieldHookConfig<string>> = ({
  rows = 5,
  isDisabled = false,
  isRequired,
  placeholder,
  autocomplete,
  readOnly = false,
  ...fieldProps
}) => {
  const [field, meta] = useField(fieldProps)
  const { name } = field

  return (
    <>
      <FormInputWrapper>
        {meta.touched && meta.error ? (
          <ErrorMessage {...field} component={ErrorLabel} />
        ) : null}
        <InputWrapper isDisabled={isDisabled}>
          <TextArea
            {...field}
            readOnly={readOnly}
            rows={rows}
            placeholder={placeholder}
            isDisabled={isDisabled}
            aria-label={name}
            autoComplete={autocomplete}
          />
          {meta.touched && meta.error ? (
            <ErrorMessage {...field} component={ErrorIcon} />
          ) : null}
        </InputWrapper>
      </FormInputWrapper>
    </>
  )
}
