import React from 'react'

// Components
import { ProductSelector } from 'marvel-components'

// Selectors
import { selectProductById } from 'modules/prompt/selectors'
import {
  selectProductQuantityInBasket,
  selectBookingFlow
} from 'modules/basket/selectors'

// Actions
import { addProduct, removeProduct } from 'modules/basket/actions'
import { openModal } from 'modules/overlay/actions'

// Misc
import { useDispatch, useSelector } from 'react-redux'
import { useTypedSelector } from 'modules'
import {
  trackUpSellAddToBasket,
  trackUpSellRemoveFromBasket,
  usePlatform
} from 'utils'
import { selectActivePerformanceDate } from 'modules/ticketing/performance/selectors'
import moment from 'moment'

export interface ProductItemContainerProps {
  productId: string
  isModal: boolean
  isFullWidth?: boolean
  upsellText?: string
}

const ProductItemContainer: React.FC<ProductItemContainerProps> = ({
  productId,
  isModal,
  isFullWidth = false,
  upsellText
}) => {
  const dispatch = useDispatch()

  // Selectors
  const product = useTypedSelector(state => selectProductById(state, productId))
  const platform = usePlatform()
  const bookingFlow = useSelector(selectBookingFlow)
  const totalSelected = useTypedSelector(state =>
    selectProductQuantityInBasket(state, productId)
  )
  const performanceDate = useSelector(selectActivePerformanceDate)

  const handleIncrementProduct = (itemId: string) => {
    product && dispatch(addProduct(product.itemId))

    const date = moment.utc(performanceDate)
    trackUpSellAddToBasket({
      id: product.id,
      name: product.name,
      price: product.total,
      performanceDate: date.format('YYYY-MM-DD'),
      performanceTime: date.format('H-mm'),
      bookingFlow,
      platform
    })
  }
  const handleDeIncrementProduct = (itemId: string) => {
    product && dispatch(removeProduct(product.itemId))

    const date = moment.utc(performanceDate)
    trackUpSellRemoveFromBasket({
      id: product.id,
      name: product.name,
      price: product.total,
      performanceDate: date.format('YYYY-MM-DD'),
      performanceTime: date.format('H-mm'),
      bookingFlow,
      platform
    })
  }

  const handleReadMoreClick = (itemId: string) => {
    dispatch(
      openModal({
        variant: 'product',
        props: {
          productId: itemId,
          isModal: true
        }
      })
    )
  }

  const handleMediaClick = (mediaUrl: string) => {
    dispatch(
      openModal({
        variant: 'media',
        props: {
          mediaType: 'youtube',
          mediaUrl,
          description: 'test'
        }
      })
    )
  }

  return (
    <>
      {product && (
        <ProductSelector
          product={product}
          onIncrementProduct={handleIncrementProduct}
          onDecrementProduct={handleDeIncrementProduct}
          onReadMoreClick={handleReadMoreClick}
          onMediaClick={handleMediaClick}
          totalSelected={totalSelected}
          isModal={isModal}
          isFullWidth={isFullWidth}
          upsellText={upsellText}
        />
      )}
    </>
  )
}

export { ProductItemContainer }
