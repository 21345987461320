import React from 'react'

// Components
import { Notification, Title, Voucher } from 'marvel-components'

// Containers
import {
  DeliverOptionsContainer,
  BookingProtectContainer,
  BookingHeaderContainer
} from 'containers/Ticketing'

// Selectors
import { getPromptMessage } from 'modules/prompt/selectors'
import {
  selectReservationGuid,
  selectGiftVoucherLoading
} from 'modules/basket/selectors'
import { getPromoCode } from 'modules/discount/selectors'

// Actions
import { addGiftVoucherToOrder } from 'modules/basket/actions'

// Utils
import styled from 'styled-components/macro'
import { media } from 'utils'

// Misc
import { useSelector, useDispatch } from 'react-redux'

const CheckoutOptionsWrapper = styled.div`
  box-sizing: border-box;
  padding: 1.5rem 1.5rem 1rem 1.5rem;
  background: ${props => props.theme.colors.secondaryBackgroundColor};

  ${media.tablet} {
    padding: 1rem;
  }
  ${media.phone} {
    padding: 0;
  }
`
const HeaderWrapper = styled.div`
  ${media.phone} {
    padding: 1rem 1rem 0 1rem;
  }
`
const CheckoutOptionsTitle = styled.span`
  ${media.phone} {
    display: none;
  }
`
const OptionsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0.75rem 0;
  ${media.phone} {
    padding: 0;
  }
`

const CheckoutOptionsContainer: React.FC = () => {
  const dispatch = useDispatch()

  // Selectors
  const promptMessage = useSelector(getPromptMessage)
  const reservationGuid = useSelector(selectReservationGuid)
  const voucherIsLoading = useSelector(selectGiftVoucherLoading)
  const promoCode = useSelector(getPromoCode)
  const hideVoucher = promoCode === 'frozenvisa'

  // Actions
  const handleRedeemClick = (
    code: string,
    onSuccessCallback: () => void,
    onErrorCallback: (errorMessage: string) => void
  ) => {
    dispatch(
      addGiftVoucherToOrder({
        code,
        transactionGuid: reservationGuid,
        onSuccessCallback,
        onErrorCallback
      })
    )
  }
  return (
    <CheckoutOptionsWrapper>
      <HeaderWrapper>
        <BookingHeaderContainer />
      </HeaderWrapper>

      <Notification title={'Booking Info'} message={promptMessage} />
      <CheckoutOptionsTitle>
        <Title
          isCapitalised={false}
          isCentralised={false}
          isBold={true}
          size='medium'
        >
          Order Options
        </Title>
      </CheckoutOptionsTitle>
      <OptionsWrapper>
        <DeliverOptionsContainer />
        <BookingProtectContainer />
        {!hideVoucher && (
          <Voucher
            onRedeemClick={handleRedeemClick}
            isSubmitting={voucherIsLoading}
          />
        )}
      </OptionsWrapper>
    </CheckoutOptionsWrapper>
  )
}

export { CheckoutOptionsContainer }
