import React, {
  PropsWithChildren,
  ReactElement,
  useEffect,
  useRef,
  useState
} from 'react'
import { useDispatch } from 'react-redux'

// Components
import {
  Button,
  CardPayment,
  ExpressCheckout,
  FormAddress,
  FormInput,
  FormRow,
  FormWrapper,
  TermsAndConditionsField,
  Title,
  Icon
} from 'marvel-components'

// Utils
import { Form, Formik, useFormikContext } from 'formik'
import { usePlatform, useIsNarrowViewport } from 'utils'
import { bannerActions } from 'modules/banner'
import * as Yup from 'yup'
import {
  addressValidator,
  emailValidator,
  firstNameValidator,
  isAcceptTermsValidator,
  lastNameValidator,
  telephoneNumberValidator
} from 'marvel-components/Form/validationSchemas'
import styled from 'styled-components/macro'

// Types
import { BaseCustomer, ExpressCheckoutValues } from 'shared-types'

const PaymentFormPlaceholder = styled.span`
  height: 7rem;
  text-align: center;
`

interface InnerProps {
  isLoading: boolean
  amount: number
  onPaymentSubmit: (data: any) => void
  onExpressSubmit: (data: ExpressCheckoutValues) => void
  title?: string
  includeAddress?: boolean
  showManualAddress?: boolean
  additionalFields?: ReactElement
  fieldsOutsideExpress: { [key: string]: string }
  previousSectionsCompleted?: boolean
  paymentMethods: Object
  brands?: string[]
}

const InnerCForm: React.FC<InnerProps> = ({
  onPaymentSubmit,
  onExpressSubmit,
  isLoading,
  amount,
  title,
  includeAddress = false,
  showManualAddress = false,
  additionalFields,
  fieldsOutsideExpress,
  previousSectionsCompleted,
  paymentMethods,
  brands
}) => {
  const {
    validateForm,
    setTouched,
    submitForm,
    setFieldValue,
    submitCount,
    errors
  } = useFormikContext()

  const formRef = useRef<HTMLDivElement>(null)
  const termsFieldName = 'isAcceptTerms'
  const [shouldScrollToError, setShouldScrollToError] = useState(false)
  const platform = usePlatform()
  const isNarrowViewport = useIsNarrowViewport()
  const dispatch = useDispatch()

  useEffect(() => {
    if (errors && formRef.current && shouldScrollToError) {
      if (Object.keys(errors).includes(termsFieldName)) {
        formRef.current.scrollIntoView()
        setShouldScrollToError(false)
      }
    }
  }, [submitCount, errors, termsFieldName, shouldScrollToError])

  useEffect(() => {
    setFieldValue('includeAddress', includeAddress)
  }, [includeAddress])

  const handleShouldShowExpressPayment = async () => {
    // Check if we are ready to show the express pay form
    setFieldValue('expressCheckout', true)
    setTouched(fieldsOutsideExpress, false)
    const errors = await validateForm()
    setShouldScrollToError(true)
    return Object.keys(errors).length <= 0
  }

  const handleExpressPayAuthorise = (values: ExpressCheckoutValues) => {
    onExpressSubmit(values)
    submitForm()
  }

  const handleFormSubmit = (e: React.MouseEvent) => {
    if (!previousSectionsCompleted) {
      dispatch(
        bannerActions.setBannerContent({
          bannerType: 'error',
          title: 'Please select a delivery method',
          text: 'A delivery method has to be selected'
        })
      )
      return
    }
    setFieldValue('expressCheckout', false)
    submitForm()
    setShouldScrollToError(true)
  }

  return (
    <Form>
      <FormWrapper ref={formRef}>
        {title && (
          <Title
            isCapitalised={false}
            isCentralised={false}
            size='medium'
            isBold={true}
          >
            {title}
          </Title>
        )}
        {additionalFields && additionalFields}
        <TermsAndConditionsField name={termsFieldName} isRequired />
        <Title
          isCapitalised={false}
          isCentralised={false}
          size='medium'
          isBold={true}
        >
          Your Details
        </Title>
        {platform !== 'kiosk' && !brands && amount > 0 && (
          <ExpressCheckout
            amount={amount}
            onPaymentSubmit={onPaymentSubmit}
            onShouldShowExpressPayment={handleShouldShowExpressPayment}
            onExpressPayAuthorise={handleExpressPayAuthorise}
            paymentMethods={paymentMethods}
          />
        )}
        <FormRow>
          <FormInput
            name='customer.firstName'
            type='text'
            placeholder='First Name'
            autocomplete='given-name'
            isRequired
          />
          <FormInput
            name='customer.lastName'
            type='text'
            placeholder='Last Name'
            autocomplete='family-name'
            isRequired
          />
        </FormRow>
        <FormInput
          name='customer.email'
          type='email'
          placeholder='Email Address'
          autocomplete='email'
          isRequired
        />
        <FormInput
          name='customer.telephoneNumber'
          isRequired
          type='tel'
          placeholder='Mobile Number'
          autocomplete='tel'
        />
        {includeAddress && (
          <FormAddress
            name='customer.address'
            showManualAddress={showManualAddress}
          />
        )}
        {platform !== 'kiosk' && amount > 0 && (
          <CardPayment
            onPaymentSubmit={onPaymentSubmit}
            paymentMethods={paymentMethods}
            brands={brands}
          />
        )}
        <Button
          isLoading={isLoading}
          isDisabled={isLoading}
          isBlock={true}
          text={
            isLoading
              ? 'Processing'
              : amount > 0
              ? `Confirm & Pay`
              : `Confirm & Book`
          }
          icon='lock'
          onClick={handleFormSubmit}
          size={platform === 'kiosk' && isNarrowViewport ? 'xlarge' : undefined}
        />
      </FormWrapper>
    </Form>
  )
}

const checkoutValidation = Yup.object().shape({
  expressCheckout: Yup.boolean(),
  includeAddress: Yup.boolean(),
  isAcceptTerms: isAcceptTermsValidator,
  customer: Yup.object().when('expressCheckout', {
    is: false,
    then: Yup.object().shape({
      firstName: firstNameValidator,
      lastName: lastNameValidator,
      email: emailValidator,
      telephoneNumber: telephoneNumberValidator,
      address: Yup.object().when('includeAddress', {
        is: true,
        then: addressValidator
      })
    })
  })
})

type CheckoutFormValues<T = {}> = T & {
  isAcceptTerms: boolean
  expressCheckout: boolean
  includeAddress: boolean
  customer: BaseCustomer
}

export type SubmitValues<T> = T &
  BaseCustomer & {
    paymentData?: any
  }

export const basicInitialValues = {
  isAcceptTerms: false,
  expressCheckout: false,
  includeAddress: false,
  customer: {
    firstName: '',
    lastName: '',
    email: '',
    telephoneNumber: ''
  }
}
interface Props<T> {
  onSubmit: (values: SubmitValues<T>) => void
  initialValues: CheckoutFormValues<T>
  isLoading: boolean
  amount: number
  title?: string
  includeAddress?: boolean
  showManualAddress?: boolean
  additionalFields?: ReactElement
  additionalValidation?: any
  previousSectionsCompleted?: boolean
  paymentMethods?: Object
  brands?: string[]
}

const CForm = <AdditionalValues extends object>(
  props: PropsWithChildren<Props<AdditionalValues>>
): ReactElement | null => {
  const {
    onSubmit,
    isLoading,
    amount,
    title,
    initialValues,
    includeAddress,
    showManualAddress,
    additionalFields,
    additionalValidation,
    previousSectionsCompleted = true,
    paymentMethods,
    brands
  } = props

  // Local state
  const [paymentData, setPaymentData] = useState<any>()
  const [expressDetails, setExpressDetails] = useState<ExpressCheckoutValues>()

  const handlePaymentSubmit = (paymentData: any) => {
    setPaymentData({ ...paymentData })
  }

  const handleExpressSubmit = (values: ExpressCheckoutValues) => {
    setExpressDetails({ ...values })
    
  }

  const handleOnSubmit = (values: CheckoutFormValues<AdditionalValues>) => {
    const { customer, ...other } = values
    if (values.expressCheckout) {
      onSubmit({
        ...other,
        ...customer,
        ...expressDetails,
        ...(paymentData && { paymentData })
      })
    } else {
      // Use the form values
      onSubmit({
        ...other,
        ...customer,
        ...(paymentData && { paymentData })
      })
    }
  }
  const fieldsOutsideExpress = Object.keys(initialValues)
    .filter(field => field !== 'customer')
    .reduce(
      (prev, current) => ({
        ...prev,
        [current]: true
      }),
      {}
    )

  return (
    <>
      {!paymentMethods ? (
        <PaymentFormPlaceholder>
          <Icon icon='circle-notch' isSpinning isCentre />
        </PaymentFormPlaceholder>
      ) : (
        <Formik<CheckoutFormValues<AdditionalValues>>
          enableReinitialize
          initialValues={initialValues}
          validationSchema={checkoutValidation.concat(additionalValidation)}
          onSubmit={handleOnSubmit}
        >
          <InnerCForm
            previousSectionsCompleted={previousSectionsCompleted}
            isLoading={isLoading}
            amount={amount}
            title={title}
            onPaymentSubmit={handlePaymentSubmit}
            onExpressSubmit={handleExpressSubmit}
            fieldsOutsideExpress={fieldsOutsideExpress}
            includeAddress={includeAddress}
            showManualAddress={showManualAddress}
            additionalFields={additionalFields}
            paymentMethods={paymentMethods}
            brands={brands}
          />
        </Formik>
      )}
    </>
  )
}

export { CForm }
