// Misc
import { createSlice, createEntityAdapter } from '@reduxjs/toolkit'

// Actions
import { fetchMenus, fetchMenu } from './actions'

// Types
import { Menu } from 'shared-types'

export const menusAdapter = createEntityAdapter<Menu>({
  sortComparer: (a, b) => a.sortOrder - b.sortOrder
})

const menusSlice = createSlice({
  name: 'menus',
  initialState: menusAdapter.getInitialState({
    loading: 'idle'
  }),
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchMenus.pending, (state, action) => {
      state.loading = 'pending'
    })
    builder.addCase(fetchMenus.fulfilled, (state, action) => {
      const { menus = {} } = action.payload
      menusAdapter.upsertMany(state, menus)
      state.loading = 'idle'
    })
    builder.addCase(fetchMenu.fulfilled, (state, action) => {
      if (action.payload.menus) {
        menusAdapter.upsertMany(state, action.payload.menus)
      }
    })
  }
})

export default menusSlice.reducer
