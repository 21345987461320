import { schema, normalize } from 'normalizr'
import { camelizeKeys } from 'humps'
import axios from 'axios'
import buildUrl from 'build-url'
import { ApiError, NetworkError } from 'utils'

const API_ROOT = window.apiRoute || process.env.REACT_APP_API_ROOT

const defaultConfig = (
  method = 'GET',
  body,
  contextId,
  accessToken,
  externalCustomerId
) => {
  let config = {
    method: method,
    headers: {
      ...(method !== 'GET' && { 'Content-Type': 'application/json' }),
      ...(contextId && { 'Context-Id': contextId }),
      ...(externalCustomerId && { 'External-Customer-Id': externalCustomerId }),
      ...(accessToken && { Authorization: `Bearer ${accessToken}` })
    },
    data: body ? JSON.stringify(body) : null
  }
  return config
}

const fullUrl = (endpoint, queryParams) => {
  return buildUrl(API_ROOT, {
    path: endpoint,
    queryParams: queryParams
  })
}

const callApi = async (
  endpoint,
  config = defaultConfig(),
  schema,
  queryParams,
  skipcamelizeKeys = false
) => {
  const url =
    endpoint.indexOf(API_ROOT) === -1
      ? fullUrl(endpoint, queryParams)
      : endpoint

  return axios({ url, ...config })
    .then(
      response => {
        const {
          data: { data, _meta }
        } = response
        const camelizedJson = skipcamelizeKeys
          ? { ...data }
          : camelizeKeys({ ...data })
        const pagination = {
          ...camelizeKeys(_meta)
        }

        if (schema) {
          return {
            ...normalize(camelizedJson, schema),
            pagination: pagination
          }
        }
        return {
          data: camelizedJson,
          pagination
        }
      },
      error => {
        if (error.response) {
          const {
            data: { _meta }
          } = error.response

          return Promise.reject(new ApiError(_meta.message))
        }
        return Promise.reject(new NetworkError())
      }
    )
    .then(
      response => ({ response }),
      error => ({ error })
    )
}

/**
 * Seat Map Schema
 */

const discountPriceLevels = new schema.Entity('discountPriceLevels')

const discountedPerformanceSchema = new schema.Entity('discountedPerformance', {
  pricing: [discountPriceLevels]
})

export const fetchDiscountedPerformance = (data, contextId, accessToken) =>
  callApi(
    `/performance/${data.performanceId}/`,
    defaultConfig('GET', null, contextId, accessToken),
    discountedPerformanceSchema,
    {
      ...(data.discountCode && { discountCode: data.discountCode })
    }
  )

/**
 * Seat Map Schema
 */

const seatSchema = new schema.Entity('seats', undefined, {
  idAttribute: value => `${value.areaId}-${value.id}`,
  processStrategy: (entity, parent) => {
    return {
      ...entity,
      seatId: `${entity.areaId}-${entity.id}`
    }
  }
})

const rowSchema = new schema.Entity('rows', {
  seats: [seatSchema]
})

const sectorSchema = new schema.Entity('sectors', {
  rows: [rowSchema]
})

const imageSchema = new schema.Entity('images')

const areaSchema = new schema.Entity('areas', {
  sectors: [sectorSchema],
  images: [imageSchema]
})

const seatMapSchema = new schema.Entity('seatMap', {
  areas: [areaSchema]
})

export const fetchSeatMap = (data, contextId, accessToken) =>
  callApi(
    `/performance/${data.performanceId}/seating-plan/`,
    defaultConfig('GET', null, contextId, accessToken),
    seatMapSchema
  )

/**
 * Basket
 */

export const createReservation = (
  data,
  contextId,
  accessToken,
  externalCustomerId
) =>
  callApi(
    `reservation/`,
    defaultConfig('POST', data, contextId, accessToken, externalCustomerId)
  )

export const updateReservation = (data, contextId, accessToken) =>
  callApi(`reservation/`, defaultConfig('PUT', data, contextId, accessToken))

export const discardReservation = (data, contextId, accessToken) =>
  callApi(
    `reservation/${data.transactionGuid}/`,
    defaultConfig('DELETE', null, contextId, accessToken)
  )

export const addGiftVoucherToOrder = (data, contextId, accessToken) =>
  callApi(
    `reservation/${data.transactionGuid}/gift-voucher/`,
    defaultConfig('POST', data, contextId, accessToken)
  )

export const createOrder = (data, contextId, accessToken, externalCustomerId) =>
  callApi(
    `order/`,
    defaultConfig('POST', data, contextId, accessToken, externalCustomerId),
    null,
    null,
    true
  )

export const fetchOrder = (data, contextId, accessToken) =>
  callApi(
    `order/${data.transactionGuid ?? data.token}/`,
    defaultConfig('GET', null, contextId || data.contextId, accessToken)
  )

export const fetchOrderFromToken = (data, contextId, accessToken) =>
  callApi(
    `order-token/${data.token}/`,
    defaultConfig('GET', null, contextId || data.contextId, accessToken)
  )

export const createOrderToken = (data, contextId, accessToken) =>
  callApi(
    `order/${data.transactionGuid ?? data.transactionNumber}/token/`,
    defaultConfig(
      'POST',
      { lastName: data.lastName },
      contextId || data.contextId,
      accessToken
    )
  )

export const fetchReservation = (data, contextId, accessToken) =>
  callApi(
    `reservation/${data.transactionGuid}/`,
    defaultConfig('GET', null, contextId || data.contextId, accessToken)
  )

export const updatePaymentDetails = (data, contextId, accessToken) =>
  callApi(
    `order/${data.transactionGuid}/`,
    defaultConfig(
      'PUT',
      { paymentDetails: data.paymentDetails },
      contextId,
      accessToken
    ),
    null,
    null,
    true
  )

/**
 * Delivery Options Schema
 */

const deliveryOptionSchema = new schema.Entity('deliveryOptions')

const deliveryOptionsSchema = [deliveryOptionSchema]

export const fetchDeliveryOptions = (data, contextId, accessToken) => {
  const { transactionGuid } = data

  return (
    transactionGuid &&
    callApi(
      `/reservation/${transactionGuid}/delivery/`,
      defaultConfig('GET', null, contextId, accessToken),
      deliveryOptionsSchema
    )
  )
}

/**
 * Prompt
 */

const productSchema = new schema.Entity(
  'products',
  {},
  {
    idAttribute: value => `${value.performanceId}-${value.priceTypeId}`,
    processStrategy: (entity, parent) => {
      return {
        ...entity,
        itemId: `${entity.performanceId}-${entity.priceTypeId}`,
        promptId: parent.id
      }
    }
  }
)

const promptSchema = new schema.Entity('prompts', {
  products: [productSchema]
})

const promptsSchema = {
  productPrompts: [promptSchema]
}

export const fetchPrompts = (data, contextId, accessToken) =>
  callApi(
    `/reservation/${data.transactionGuid}/prompts/`,
    defaultConfig('GET', null, contextId, accessToken),
    promptsSchema
  )

export const fetchPromptsPostOrder = (data, contextId, accessToken) =>
  callApi(
    `/order/${data.transactionGuid}/prompts/`,
    defaultConfig('GET', null, contextId, accessToken),
    promptsSchema
  )

/**
 * Optin
 */

const optinSchema = new schema.Entity('optins')

const optinsSchema = [optinSchema]

export const fetchOptins = (data, contextId, accessToken) =>
  callApi(
    `/reservation/${data.transactionGuid}/optins/`,
    defaultConfig('GET', null, contextId, accessToken),
    optinsSchema
  )

/**
 * Event Details
 */

const eventDetailsSchema = new schema.Entity('eventDetails')

export const fetchEventDetails = (data, contextId, accessToken) =>
  callApi(
    `/event/${data.eventId}/`,
    defaultConfig('GET', null, contextId, accessToken),
    eventDetailsSchema
  )

/**
 * Auth
 */

const authSchema = new schema.Entity('auth')

export const fetchAuth = (data, contextId, accessToken) => {
  return callApi(
    `/auth/login/`,
    defaultConfig('POST', data.login, contextId, accessToken),
    authSchema
  )
}

export const forgottenPassword = (data, contextId, accessToken) =>
  callApi(
    `/auth/reset-password/`,
    defaultConfig('POST', data.login, contextId, accessToken)
  )

/**
 * Customer
 */

export const createCustomer = (data, contextId, accessToken) => {
  return callApi(
    `/customer/`,
    defaultConfig('POST', data, contextId, accessToken),
    authSchema
  )
}

export const updateCustomer = (data, contextId, accessToken) => {
  return callApi(
    `/customer/${data.customerId}/`,
    defaultConfig('PUT', data, contextId, accessToken),
    authSchema
  )
}

export const createExchange = data => {
  return callApi(`/exchange/`, defaultConfig('POST', data))
}
