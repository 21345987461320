import React, { useState, useEffect, useRef, useCallback } from 'react'
import styled, { css, withTheme, DefaultTheme } from 'styled-components/macro'

// Components
import { Icon } from 'marvel-components'
import { Handle } from './Handle'

// Misc
import { darken } from 'utils'

export type InteractionType = 'parent' | 'mousedown' | 'touchstart'
export type HandleType = 'left' | 'right'

const HANDLE_DIMENSION = 34
const TRACK_HEIGHT = 16
const ANIMATE_SPEED = 0.1

export interface PriceSliderProps {
  theme: DefaultTheme
  values: number[]
  lowerValue: number | null
  upperValue: number | null
  onChange: (lowerValue: number, higherValue: number) => void
}

const SliderWrapper = styled.div`
  position: relative;
  height: 4rem;
  margin: 0 1rem 1rem 1rem;
`

const TrackBackground = styled.div(props => {
  const {
    theme: {
      colors: { mainColor }
    }
  } = props

  return css`
    position: absolute;
    height: ${TRACK_HEIGHT}px;
    left: ${HANDLE_DIMENSION / 2}px;
    right: ${HANDLE_DIMENSION / 2}px;
    bottom: ${(HANDLE_DIMENSION - TRACK_HEIGHT) / 2}px;
    cursor: pointer;
    transition: all 0.2s;
    border-radius: ${TRACK_HEIGHT}px;
    background: ${darken(mainColor, 50)};
  `
})

const TrackForeGround = styled.div(props => {
  const {
    theme: {
      colors: { mainColor }
    }
  } = props

  return css`
    position: absolute;
    height: ${TRACK_HEIGHT}px;
    bottom: ${(HANDLE_DIMENSION - TRACK_HEIGHT) / 2}px;
    background: ${mainColor};
    pointer-events: none;
  `
})

const CheckedIcon = styled.div(props => {
  const {
    theme: {
      colors: { priceSliderHandleColor }
    }
  } = props

  return css`
    display: flex;
    fill: ${priceSliderHandleColor};
    color: ${priceSliderHandleColor};
    align-items: center;
    justify-content: center;
    height: 100%;
    pointer-events: none;
  `
})

const Slider = (props: PriceSliderProps) => {
  const { values, onChange, lowerValue, upperValue } = props

  const trackRef = useRef<HTMLDivElement>(null)
  const isWindowListenersAttached = useRef(false)

  const [trackWidth, setTrackWidth] = useState(0)
  const [leftHandlePosition, setLeftHandlePosition] = useState(0)
  const [rightHandlePosition, setRightHandlePosition] = useState(0)
  const [leftHandleLabel, setLeftHandleLabel] = useState(lowerValue)
  const [rightHandleLabel, setRightHandleLabel] = useState(upperValue)
  const [previousX, setPreviousX] = useState(0)
  const [selectedHandle, setSelectedHandle] = useState<HandleType | null>(null)
  const [eventType, setEventType] = useState<InteractionType | null>(null)
  const [animateHandles, setAnimateHandles] = useState(true)

  /**
   * measureTrack sets the track width state.
   * Note: This is done on each initial interaction for responsive behaviour.
   */
  const measureTrack = () => {
    const { current: TrackBackground } = trackRef
    const trackBoundingRect =
      TrackBackground && TrackBackground.getBoundingClientRect()

    trackBoundingRect && setTrackWidth(trackBoundingRect.width)
  }

  /**
   * Converts a position as percentage to nearest value
   */
  const getPriceFromPercentagePosition = useCallback(
    (position: number) => {
      const priceIndex = Math.min(
        Math.floor((values.length / 100) * position),
        values.length - 1
      )

      return values[priceIndex]
    },
    [values]
  )

  /**
   * normalizeHandlePosition prevents the handle from going out of bounds or overlapping other handle
   * @param position current position
   * @param otherHandlePosition other handle position
   * @param handleWidthAsPercentage width of the handle as a percentage
   */
  const normalizeHandlePosition = (
    position: number,
    otherHandlePosition: number,
    handleWidthAsPercentage: number
  ): number => {
    // Zero Check
    return position < 0
      ? 0
      : // Overlap Check
      position > 100 - (otherHandlePosition + 2 * handleWidthAsPercentage)
      ? 100 - (otherHandlePosition + 2 * handleWidthAsPercentage)
      : position > 100
      ? 100
      : position
  }

  /**
   * Handles initial mouse down event
   * @param event mouse event
   * @param handleName the name of the handle being dragged
   */
  const handleMouseDown = (event: React.MouseEvent, handleName: HandleType) => {
    setPreviousX(event.nativeEvent.pageX)
    setEventType('mousedown')
    interactionStart(handleName)
  }

  /**
   * Handles initial touch event
   * @param event touch event
   * @param handleName the name of the handle being touched
   */
  const handleTouchStart = (
    event: React.TouchEvent,
    handleName: HandleType
  ) => {
    setPreviousX(event.nativeEvent.touches[0].pageX)
    setEventType('touchstart')
    interactionStart(handleName)
  }

  /**
   * Initialises the handle movements
   * @param handleName the name of the handle being touched
   */
  const interactionStart = (handleName: HandleType) => {
    setSelectedHandle(handleName)
    measureTrack()
    setAnimateHandles(false)
    isWindowListenersAttached.current = false
  }

  /**
   * handleTrackClick handles the clicking of the slider track and moves the nearest handle
   * @param event
   */
  const handleTrackClick = (event: React.MouseEvent) => {
    event.persist()

    const { current: TrackBackground } = trackRef
    const trackBoundingRect =
      TrackBackground && TrackBackground.getBoundingClientRect()

    if (trackBoundingRect) {
      const clickX = event.clientX - (trackBoundingRect as DOMRect).x
      const clickPercentage = (100 / trackBoundingRect.width) * clickX
      const clickPrice = getPriceFromPercentagePosition(clickPercentage)

      const isLeftClosest =
        Math.abs(clickPercentage - leftHandlePosition) <
        Math.abs(clickPercentage - (100 - rightHandlePosition))

      if (isLeftClosest) {
        // setLeftHandlePosition(clickPercentage)
        setLeftHandleLabel(clickPrice)
      } else {
        // setRightHandlePosition(100 - clickPercentage)
        setRightHandleLabel(clickPrice)
      }

      setAnimateHandles(true)
      setEventType(null)
    }
  }

  /**
   * Effects
   */

  // Set Handle Positions from parent
  useEffect(() => {
    /**
     * Converts a value to percentage
     */
    const getPercentagePositionFromValue = (
      value: number,
      isLeftHandle: boolean
    ) => {
      let valueIndex = values.indexOf(value)
      valueIndex += isLeftHandle ? 0 : 1

      return valueIndex > 0 ? (100 / values.length) * valueIndex : 0
    }

    /**
     * Returns the closest price to provided value
     * @param value
     */
    const getNearestPrice = (value: number) =>
      values.reduce((prev, curr) =>
        Math.abs(curr - value) < Math.abs(prev - value) ? curr : prev
      )

    if (values.length) {
      const low = lowerValue || Math.min(...values)
      const high = upperValue || Math.max(...values)
      const leftHandleLabel = getNearestPrice(low)
      const rightHandleLabel = getNearestPrice(high)

      const initialLeftHandlePosition = getPercentagePositionFromValue(
        leftHandleLabel,
        true
      )
      const initialRightHandlePosition =
        100 - getPercentagePositionFromValue(rightHandleLabel, false)

      setAnimateHandles(true)
      setLeftHandlePosition(initialLeftHandlePosition)
      setLeftHandleLabel(leftHandleLabel)
      setRightHandlePosition(initialRightHandlePosition)
      setRightHandleLabel(rightHandleLabel)

      setEventType('parent')
    }
  }, [lowerValue, upperValue, values])

  // Add event listeners
  useEffect(() => {
    /**
     * interactionMove manages the movement of the current selected handle.
     * Handles are positioned by percentage from left and right accordingly.
     * InteractionMove uses an initial X position and current X position to calculate percentage change.
     */
    const interactionMove = (e: Event) => {
      e.preventDefault()
      e.stopPropagation()
      let pageX = 0

      if (eventType === 'mousedown') {
        pageX = (e as MouseEvent).pageX
      } else if (eventType === 'touchstart') {
        pageX = (e as TouchEvent).touches[0].pageX
      }

      const deltaX = pageX - previousX
      const deltaPercentage = (100 / trackWidth) * deltaX
      const handleWidthAsPercentage = (100 / trackWidth) * HANDLE_DIMENSION // Note: track is responsive handles are constant

      // Update Left Handle
      if (selectedHandle === 'left') {
        const newLeftHandlePosition = normalizeHandlePosition(
          leftHandlePosition + deltaPercentage,
          rightHandlePosition,
          handleWidthAsPercentage
        )

        // Sets handle position
        setLeftHandlePosition(newLeftHandlePosition)

        // Sets handle label
        const newLeftHandleLabel = getPriceFromPercentagePosition(
          newLeftHandlePosition
        )
        setLeftHandleLabel(newLeftHandleLabel)
      }

      // Update Right Handle
      if (selectedHandle === 'right') {
        const newRightHandlePosition = normalizeHandlePosition(
          rightHandlePosition - deltaPercentage,
          leftHandlePosition,
          handleWidthAsPercentage
        )

        // Sets handle position
        setRightHandlePosition(newRightHandlePosition)

        // Sets handle label
        const newRightHandleLabel = getPriceFromPercentagePosition(
          100 - newRightHandlePosition
        )
        setRightHandleLabel(newRightHandleLabel)
      }
    }

    const interactionEnd = () => {
      // Animate
      setAnimateHandles(true)

      // Remove
      if (eventType === 'mousedown') {
        window.removeEventListener('mousemove', interactionMove)
        window.removeEventListener('mouseup', interactionEnd)
      }

      if (eventType === 'touchstart') {
        window.removeEventListener('touchmove', interactionMove)
        window.removeEventListener('touchend', interactionEnd)
      }

      setEventType(null)
    }

    if (
      isWindowListenersAttached.current === false &&
      eventType === 'mousedown'
    ) {
      window.addEventListener('mousemove', interactionMove)
      window.addEventListener('mouseup', interactionEnd)
      isWindowListenersAttached.current = true
    }

    if (
      isWindowListenersAttached.current === false &&
      eventType === 'touchstart'
    ) {
      window.addEventListener('touchmove', interactionMove, { passive: false })
      window.addEventListener('touchend', interactionEnd)
      isWindowListenersAttached.current = true
    }
  }, [
    selectedHandle,
    previousX,
    eventType,
    getPriceFromPercentagePosition,
    trackWidth,
    leftHandlePosition,
    rightHandlePosition
  ])

  // Emit change
  useEffect(() => {
    if (
      eventType === null &&
      (lowerValue !== leftHandleLabel || upperValue !== rightHandleLabel) &&
      leftHandleLabel &&
      rightHandleLabel
    ) {
      onChange(leftHandleLabel, rightHandleLabel)
    }
  }, [
    leftHandleLabel,
    rightHandleLabel,
    eventType,
    onChange,
    lowerValue,
    upperValue
  ])

  /**
   * Render
   */
  return (
    <SliderWrapper>
      <TrackBackground onClick={e => handleTrackClick(e)} ref={trackRef} />
      <TrackForeGround
        style={{
          left: `${leftHandlePosition}%`,
          right: `${rightHandlePosition}%`,
          transition: `${
            animateHandles
              ? `left ${ANIMATE_SPEED}s, right ${ANIMATE_SPEED}s`
              : 'none'
          }`
        }}
      />
      <Handle
        name='left'
        label={leftHandleLabel}
        isLabelVisible={leftHandleLabel !== rightHandleLabel}
        position={leftHandlePosition}
        dimension={HANDLE_DIMENSION}
        onMouseDown={handleMouseDown}
        onTouchStart={handleTouchStart}
        style={{
          left: `${leftHandlePosition}%`,
          transition: `${animateHandles ? `left ${ANIMATE_SPEED}s` : 'none'}`
        }}
      >
        <CheckedIcon>
          <Icon icon='grip-lines-vertical' />
        </CheckedIcon>
      </Handle>
      <Handle
        name='right'
        label={rightHandleLabel}
        position={rightHandlePosition}
        dimension={HANDLE_DIMENSION}
        onMouseDown={handleMouseDown}
        onTouchStart={handleTouchStart}
        style={{
          right: `${rightHandlePosition}%`,
          transition: `${animateHandles ? `right ${ANIMATE_SPEED}s` : 'none'}`
        }}
      >
        <CheckedIcon>
          <Icon icon='grip-lines-vertical' />
        </CheckedIcon>
      </Handle>
    </SliderWrapper>
  )
}

export const PriceSlider = withTheme(Slider)
