import React from 'react'
import styled from 'styled-components'
import { media, parseZonalMenu } from 'utils'

// Containers
import { BreadcrumbContainer } from '../../BreadcrumbContainer'

// Components
import { Title, ListItem, List, WithLoader } from 'marvel-components'

// Selectors
import { menuSelectors } from 'modules/smart-waiter/menu'
import { serviceSelectors } from 'modules/smart-waiter/service'
import { venueSelectors } from 'modules/smart-waiter/venue'

// Misc
import { useSelector } from 'react-redux'
import { useHistory, useRouteMatch } from 'react-router-dom'
import { smartWaiterRoutes } from '../SmartWaiterRoutes'
import moment from 'moment'

// Types
import { AppState } from 'modules/types'

const MenuListContainerWrapper = styled.div`
  box-sizing: border-box;
  padding: 1.5rem 0 2.5rem 0;
  ${media.tablet} {
    padding: 1rem 0 1.5rem 0;
    margin-top: 0;
  }
`

const MenuListDetails = styled.div`
  padding: 0 2%;
  ${media.tablet} {
    padding: 0 3%;
  }
  ${media.phone} {
    padding: 0 5%;
  }
`

const MenuListListWrapper = WithLoader(styled.div`
  margin: 1rem 0;
`)

const MenuListContainer: React.FC<{ serviceId: string }> = ({ serviceId }) => {
  // Selectors
  const menus = useSelector((state: AppState) =>
    serviceSelectors.selectMenusForServiceId(state, serviceId)
  )
  const isLoading = useSelector(menuSelectors.selectIsMenuListLoading)
  const selectedTime = useSelector(venueSelectors.selectedSelectedTime)

  // Routing
  const history = useHistory()
  const { url } = useRouteMatch()

  const handleMenuClick = (menuId: EntityId) => {
    history.push(`${url}/${menuId}/section`)
  }

  return (
    <MenuListContainerWrapper>
      <MenuListDetails>
        <BreadcrumbContainer routes={smartWaiterRoutes} />
        <Title
          isCapitalised={false}
          isCentralised={false}
          isBold={true}
          size='medium'
        >
          Are you ordering for pre-show or for the interval?
        </Title>
      </MenuListDetails>
      <MenuListListWrapper isLoading={isLoading}>
        <List>
          {menus.map(menu => {
            const menuType = parseZonalMenu(menu.name)
            return (
              <ListItem
                stacked
                largeIcon
                key={menu.id}
                title={menuType.title}
                id={menu.id}
                onClick={handleMenuClick}
                icon={true}
                imageUrl={menuType.icon}
                isDisabled={moment
                  .utc()
                  .add(menuType.cutOff, 'minutes')
                  .isAfter(moment.utc(selectedTime))}
              />
            )
          })}
        </List>
      </MenuListListWrapper>
    </MenuListContainerWrapper>
  )
}

export { MenuListContainer }
