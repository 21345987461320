// Views
import { AuthenticationView, RestrictedView } from 'views/Kiosk'

// Misc
import { Route } from 'react-router-dom'
import { Switch, useRouteMatch } from 'react-router'

const KioskView = () => {
  const { path } = useRouteMatch()

  return (
    <Switch>
      <Route exact path={`${path}/authenticate`}>
        <AuthenticationView />
      </Route>
      <Route path={`${path}`}>
        <RestrictedView />
      </Route>
    </Switch>
  )
}

export { KioskView }
