import React, { useEffect } from 'react'

// Components
import { Layout, FooterNav } from 'marvel-components'

// Containers
import {
  BrandLogoContainer,
  OccasionListContainer
} from 'containers/Hospitality'

// Views
import { OccasionView } from 'views/Hospitality'

// Actions
import { occasionActions } from 'modules/hospitality/occasion'

// Selectors
import { siteSelectors } from 'modules/hospitality/site'

// Misc
import { Route, Switch, useRouteMatch } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { OrderView } from 'views/Hospitality'
import { trackHospitalitySite } from 'utils'
import { useTypedSelector } from 'modules'

const SiteView: React.FC = () => {
  const dispatch = useDispatch()

  // Routing
  const {
    path,
    params: { siteId }
  } = useRouteMatch<{
    siteId: string
  }>()

  // Selectors
  const siteName = useTypedSelector(state =>
    siteSelectors.selectSiteName(state, siteId)
  )
  // Actions
  useEffect(() => {
    dispatch(occasionActions.fetchOccasionsForSite(siteId))
  }, [dispatch, siteId])

  // Analytics
  useEffect(() => {
    siteName && trackHospitalitySite({ siteName })
  }, [siteName])

  return (
    <Switch>
      <Route exact path={`${path}/occasion`}>
        <Layout
          pageTitle={`Select Hospitality Occasion | LW Theatres`}
          renderBrandLogo={<BrandLogoContainer />}
          renderMain={<OccasionListContainer siteId={siteId} />}
          renderFooter={<FooterNav />}
        />
      </Route>
      <Route path={`${path}/order/:orderId`}>
        <OrderView />
      </Route>
      <Route path={`${path}/occasion/:occasionId`}>
        <OccasionView />
      </Route>
    </Switch>
  )
}

export { SiteView }
