import styled from 'styled-components'
import PaymentTerminalIcon from 'assets/self-serve/payment_terminal_icon.png'
import PuffLoader from 'react-spinners/PuffLoader'

// Can be a string as well. Need to ensure each key-value pair ends with ;

const Wrapper = styled.div`
  font-family: ${props => props.theme.font.copy};
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 700px;
  background: #eeeeee;
  border-radius: 1rem;
  padding: 3rem 0 3rem 0;
  justify-content: center;
`

const TitleWrapper = styled.span`
  margin: 0.75rem;
  font-family: ${props => props.theme.font.header};
  font-size: 1.5rem;
`

const TextWrapper = styled.span`
  margin: 0.5rem;
  font-family: ${props => props.theme.font.copy};
  font-size: 1.1rem;
`

const ImageWrapper = styled.div`
  width: 25rem;
  height: 25rem;
  margin: 3rem 0 0 0;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
`

const PuffLoaderWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
`

const StyledPuffLoader = styled(PuffLoader)``

const Image = styled.img`
  width: 12.5rem;
  height: 15.625rem;
`

const PaymentTerminalNotice = () => {
  return (
    <Wrapper>
      <TitleWrapper>Pay Now</TitleWrapper>
      <TextWrapper>Please follow the instructions on the PIN pad</TextWrapper>
      <ImageWrapper>
        <PuffLoaderWrapper>
          <StyledPuffLoader
            color='#333'
            loading={true}
            size={400}
            speedMultiplier={1}
          />
        </PuffLoaderWrapper>
        <Image src={PaymentTerminalIcon} alt={'Payment Terminal'} />
      </ImageWrapper>
    </Wrapper>
  )
}

export { PaymentTerminalNotice }
