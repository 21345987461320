import React from 'react'
import styled from 'styled-components/macro'
import moment from 'moment'

// Components
import { Icon } from 'marvel-components'

interface Props {
  date: moment.Moment
  isLoading: boolean
}

const CountdownWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: ${props => props.theme.font.copy};
  color: ${props => props.theme.colors.mainBackgroundColorComplement};
  background: ${props => props.theme.colors.mainBackgroundColor};
  border-radius: 0.25rem;
  padding: 1rem;
  text-align: center;
  position: relative;
`

const Title = styled.span`
  font-size: 3rem;
  text-transform: uppercase;
`

const Description = styled.span`
  font-size: 0.8125rem;
  text-transform: uppercase;
`

const SpinnerIconWrapper = styled.div`
  fill: #fff;
  font-size: 1.2rem;
`

const Countdown = ({ date, isLoading }: Props) => {
  const diffDays = date.diff(moment(), 'days')

  return (
    <CountdownWrapper>
      {isLoading && (
        <SpinnerIconWrapper>
          <Icon icon={'circle-notch'} isSpinning />
        </SpinnerIconWrapper>
      )}
      {!isLoading && diffDays > 0 && (
        <>
          <Title>{diffDays}</Title>
          <Description>Days until the show</Description>
        </>
      )}
      {!isLoading && diffDays === 0 && (
        <>
          <Title>Today</Title>
          <Description>It's show day</Description>
        </>
      )}
      {!isLoading && diffDays < 0 && (
        <Description>We hope you enjoyed the show!</Description>
      )}
    </CountdownWrapper>
  )
}

export { Countdown }
