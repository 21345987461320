import { takeLatest, put, select } from 'redux-saga/effects'
import { sharedOperations } from 'modules/shared'
import { bannerActions } from 'modules/banner'

import * as api from 'services/api'
import * as actions from './actions'
import { promptSelectors } from '.'

export function* watcherFetchPrompts() {
  yield takeLatest(actions.fetchPrompts.TRIGGER, fetchPrompts)
  yield takeLatest(actions.fetchPromptsPostOrder.TRIGGER, fetchPromptsPostOrder)
  yield takeLatest(actions.fetchPrompts.SUCCESS, showPromptMessages)
}

export const fetchPrompts = sharedOperations.fetchEntity.bind(
  null,
  actions.fetchPrompts,
  api.fetchPrompts
)

export const fetchPromptsPostOrder = sharedOperations.fetchEntity.bind(
  null,
  actions.fetchPromptsPostOrder,
  api.fetchPromptsPostOrder
)

function* showPromptMessages(action: any) {
  const message = yield select(promptSelectors.getPromptMessage)
  if (message) {
    yield put(
      bannerActions.setBannerContent({
        bannerType: 'information',
        title: 'Booking Information',
        text: `${message}`
      })
    )
  }
}
