export const compareStrings = (a: string, b: string) => {
  const left = a.toUpperCase()
  const right = b.toUpperCase()
  if (left === right) return 0
  if (left > right) return 1
  else return -1
}

export const compareNumbers = (left: number, right: number) => {
  if (left === right) return 0
  if (left > right) return 1
  else return -1
}
