import React from 'react'
import styled from 'styled-components'
import { media } from 'utils'

// Components
import {
  Title,
  List,
  WithLoader,
  ListText,
  ListDivider
} from 'marvel-components'

// Containers
import { BreadcrumbContainer } from 'containers'
import { ProductListItemContainer } from 'containers/SmartWaiter'

// Selectors
import { menuItemSelectors } from 'modules/smart-waiter/menu-item'
import { menuSectionSelectors } from 'modules/smart-waiter/menu-section'

// Misc
import { useSelector } from 'react-redux'
import { useHistory, useRouteMatch } from 'react-router-dom'
import { smartWaiterRoutes } from '../SmartWaiterRoutes'

// Types
import { MenuProductItem } from 'shared-types'
import { AppState } from 'modules/types'

const MenuListContainerWrapper = styled.div`
  box-sizing: border-box;
  padding: 1.5rem 0 2.5rem 0;
  ${media.tablet} {
    padding: 1rem 0 1.5rem 0;
    margin-top: 0;
  }
`

const MenuListDetailsWrapper = styled.div`
  padding: 0 2%;
  ${media.tablet} {
    padding: 0 3%;
  }
  ${media.phone} {
    padding: 0 5%;
  }
`

const MenuListListWrapper = WithLoader(styled.div`
  margin: 1rem 0;
`)

const MenuContainer: React.FC = () => {
  // Routing
  const {
    url,
    params: { sectionId }
  } = useRouteMatch<{ sectionId: string }>()

  // Selectors
  const items = useSelector(menuItemSelectors.selectItemsBySectionId(sectionId))
  const menuSection = useSelector((state: AppState) =>
    menuSectionSelectors.selectById(state, sectionId)
  )
  const isLoading = useSelector(menuItemSelectors.isLoading)

  // Routing
  const history = useHistory()

  const handleProductClick = (productId: EntityId) => {
    history.push(`${url}/${productId}`)
  }

  return (
    <MenuListContainerWrapper>
      <MenuListDetailsWrapper>
        <BreadcrumbContainer routes={smartWaiterRoutes} />
        <Title
          isCapitalised={false}
          isCentralised={false}
          isBold={true}
          size='medium'
        >
          {menuSection && menuSection.name}
        </Title>
      </MenuListDetailsWrapper>
      <MenuListListWrapper isLoading={isLoading}>
        <List>
          {items.map(item => {
            switch (item.itemType) {
              case 'subHeader':
                return <ListDivider key={item.id}>{item.text}</ListDivider>
              case 'textField':
                return <ListText key={item.id}>{item.text}</ListText>
              case 'product':
                const productItem = item as MenuProductItem
                return (
                  <ProductListItemContainer
                    id={productItem.productId}
                    key={productItem.id}
                    onProductSelect={handleProductClick}
                  />
                )
              default:
                return null
            }
          })}
        </List>
      </MenuListListWrapper>
    </MenuListContainerWrapper>
  )
}

export { MenuContainer }
