import React, { useEffect, useState } from 'react'

// Components
import { Layout } from 'marvel-components'

// Containers
import {
  BasketContainer,
  BrandLogoContainer,
  SeatMapControlContainer,
  SelectTicketContainer
} from 'containers/Ticketing'

// Actions
import { fetchSeatMap } from 'modules/seat-map/actions'
import { availabilityActions } from 'modules/ticketing/availability/availabilitySlice'
import { performanceActions } from 'modules/ticketing/performance/performanceSlice'
import { bannerActions } from 'modules/banner'
import { setPromoCode, setLotteryToken } from 'modules/discount/actions'

// Selectors
import { eventDetailsSelectors } from 'modules/ticketing/event'

// Utils
import { preorderProductsRoute, calendarRoute } from 'utils'
import { useLocation } from 'react-router-dom'

// Misc
import axios from 'axios'
import { useDispatch, useSelector } from 'react-redux'
import { Redirect, useParams, useHistory } from 'react-router-dom'

const LotteryView: React.FC = () => {
  const dispatch = useDispatch()
  const { eventId } = useParams<{
    eventId: string
  }>()

  const location = useLocation()

  // Selectors
  const eventName = useSelector(eventDetailsSelectors.selectEventName)
  const promoCode = 'LOTT21'
  const urlParams = new URLSearchParams(location.search)
  const token = urlParams.get('token')
  const performanceId = urlParams.get('performanceId')
  const history = useHistory()

  const [tokenState, setTokenState] = useState('loading')

  useEffect(() => {
    axios
      .get(
        `https://api.campaigns.lineupnow.com/performance/${performanceId}/entry/${token}/verify/`
      )
      .then(
        response =>
          response.status === 200
            ? setTokenState('valid')
            : setTokenState('error'),
        error => {
          setTokenState('error')
          if (error.response) {
            const {
              data: { _metadata }
            } = error.response
            dispatch(
              bannerActions.setBannerContent({
                bannerType: 'error',
                title: 'Invalid Entry',
                text: `${_metadata.message}`
              })
            )
          }
        }
      )
  }, [token, performanceId, dispatch])

  // Set promo for day seats
  useEffect(() => {
    eventId &&
      dispatch(
        setPromoCode({
          code: promoCode
        })
      )
  }, [eventId, dispatch, promoCode])

  // Fetch Seat Map/Performance/Seat Availability
  // ------------------------------------------
  useEffect(() => {
    if (performanceId) {
      dispatch(performanceActions.setCurrentPerformance(performanceId))
      dispatch(fetchSeatMap({ performanceId }))
      dispatch(
        availabilityActions.fetchAvailability({
          performanceId,
          promoCode
        })
      )
      dispatch(
        performanceActions.fetchPerformance({
          performanceId,
          promoCode
        })
      )
    }
  }, [performanceId, dispatch])

  // Set Lottery Token
  // ------------------------------------------
  useEffect(() => {
    if (tokenState === 'valid') {
      dispatch(
        setLotteryToken({
          code: token
        })
      )
    }
  }, [token, tokenState, dispatch])

  if (!performanceId) {
    return <Redirect from='/' to={calendarRoute(eventId)} />
  }
  if (tokenState === 'error') {
    return <Redirect from='/' to={calendarRoute(eventId)} />
  }
  const handleReservationSuccess = () => {
    return history.push(preorderProductsRoute(eventId, performanceId))
  }

  if (tokenState === 'valid') {
    return (
      <Layout
        pageTitle={`Select your tickets - ${eventName} | LW Theatres`}
        renderBrandLogo={<BrandLogoContainer showLogin />}
        renderMain={
          <SelectTicketContainer
            performanceId={performanceId}
            seatingType={'Lottery'}
          />
        }
        renderSidebarBottom={
          <>
            <SeatMapControlContainer showPriceFilter={false} />
            <BasketContainer onReservationSuccess={handleReservationSuccess} />
          </>
        }
      />
    )
  }
  return null
}
export { LotteryView }
