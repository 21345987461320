// Actions
import { bannerActions } from 'modules/banner'

// Utils
import { createAsyncThunk } from '@reduxjs/toolkit'
import { AxiosError } from 'axios'
import { normalize } from 'normalizr'

// Types
import { APIResponse, APIError, MetaData } from 'shared-types'
import { Schema } from 'normalizr'
import { AppDispatch, RootState } from 'app/reduxStore'
import { AppState } from 'modules'

type AsyncThunkConfig = {
  state: RootState
  dispatch: AppDispatch
}

type APIReturn<T> = {
  data: T
  pagination: MetaData
}

export const createAPIThunk = <Returned, ThunkArg = void>(
  typePrefix: string,
  apiCall: (
    arg: ThunkArg,
    contextId?: string,
    authToken?: string
  ) => Promise<APIResponse>,
  normalizeSchema?: Schema,
  shouldExecute?: (arg: ThunkArg, state: AppState) => boolean
) =>
  createAsyncThunk<APIReturn<Returned>, ThunkArg, AsyncThunkConfig>(
    typePrefix,
    async (arg: ThunkArg, { getState, dispatch, rejectWithValue }) => {
      const state = getState()
      const contextId = state.basket.contextId
      const authToken = state.selfServe.kiosk.accessToken
      try {
        const response = await apiCall(arg, contextId, authToken)
        if (normalizeSchema) {
          const normalized = normalize<any, Returned>(
            response.data,
            normalizeSchema
          )
          return { data: normalized.entities, pagination: response._meta }
        }
        return { data: response.data, pagination: response._meta } as APIReturn<
          Returned
        >
      } catch (err) {
        let error: AxiosError<APIError> = err
        let message = 'Unknown Error'
        if (error.response) {
          message = error.response.data._meta.message
        }
        dispatch(
          bannerActions.setBannerContent({
            bannerType: 'error',
            title: 'Please Note',
            text: message
          })
        )
        return rejectWithValue(message)
      }
    },
    shouldExecute && {
      condition: (arg, { getState }) => shouldExecute(arg, getState())
    }
  )
