// Misc
import styled from 'styled-components/macro'
import { darken } from 'utils'

const StickyFooter = styled.div`
  margin-top: auto;
  position: relative;

  :before {
    content: '';
    background: ${props => darken(props.theme.colors.mainBackgroundColor, 10)};
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    opacity: 0.75;
  }
`

export { StickyFooter }
