import { useMemo, Fragment } from 'react'
import styled from 'styled-components'

// Components
import { Link, CopyBlock, Title } from 'marvel-components'

const Span = styled.span<{
  isBold?: boolean
  isMonospace?: boolean
  isItalic?: boolean
  isStrikethrough?: boolean
}>`
  font-weight: ${props => (props.isBold ? 'bold' : undefined)};
  font-family: ${props => (props.isMonospace ? 'monospace' : undefined)};
  font-style: ${props => (props.isItalic ? 'italic' : undefined)};
  text-decoration: ${props =>
    props.isStrikethrough ? 'line-through' : undefined};
`

type Token = {
  key: string
  isBold?: boolean
  isItalic?: boolean
  isMonospace?: boolean
  isStrikethrough?: boolean
  children?: Token[]
} & (
  | { type: 'newline' }
  | { type: 'block' }
  | { type: 'text'; value: string }
  | { type: 'heading'; level: 1 | 2 | 3 | 4 | 5 | 6 }
  | { type: 'link'; label: string; href: string }
)

const tokenize = (markdown: string) => {
  const parse = (
    value: string,
    token: Token = { type: 'block', key: 'root' }
  ) => {
    let accum = ''
    let lastFlushIndex = 0

    const flush = (index: number) => {
      if (accum.length > 0) {
        token.children = [
          ...(token.children ?? []),
          {
            type: 'text',
            value: accum,
            key: `${lastFlushIndex}-${lastFlushIndex + accum.length}`
          }
        ]
        accum = ''
      }

      lastFlushIndex = index
    }

    for (let i = 0; i < value.length; i++) {
      const char = value[i]

      switch (char) {
        // Single delimiter modifiers
        case '*':
        case '`': {
          const closingIndex = value.indexOf(char, i + 1)
          if (closingIndex < 0) {
            break
          }

          flush(i)

          token.children = [
            ...(token.children ?? []),
            parse(value.substring(i + 1, closingIndex), {
              type: 'text',
              value: '',
              isItalic: char === '*',
              isMonospace: char === '`',
              key: `${i}-${closingIndex}`
            })
          ]

          i = closingIndex
          continue
        }
        // Double delimiter modifiers
        case '~':
        case '_': {
          const closingIndex = value.indexOf(char + char, i + 2)
          if (closingIndex < 0) {
            break
          }

          flush(i)

          token.children = [
            ...(token.children ?? []),
            parse(value.substring(i + 2, closingIndex), {
              type: 'text',
              value: '',
              isBold: char === '_',
              isStrikethrough: char === '~',
              key: `${i}-${closingIndex}`
            })
          ]

          i = closingIndex + 1
          continue
        }

        // Links
        case '[': {
          const labelClosingIndex = value.indexOf(']', i + 1)
          if (labelClosingIndex < 0) {
            break
          }

          const hrefStartIndex = value.indexOf('(', labelClosingIndex + 1)
          if (hrefStartIndex < 0) {
            break
          }

          const hrefEndIndex = value.indexOf(')', hrefStartIndex + 1)
          if (hrefEndIndex < 0) {
            break
          }

          flush(i)

          const href = value.substring(hrefStartIndex + 1, hrefEndIndex)
          const label = value.substring(i + 1, labelClosingIndex)

          token.children = [
            ...(token.children ?? []),
            {
              type: 'link',
              label,
              href,
              key: `${i}-${hrefEndIndex}`
            }
          ]

          i = hrefEndIndex
          continue
        }

        case '\n':
          flush(i)

          token.children = [
            ...(token.children ?? []),
            {
              type: 'newline',
              key: `${i}`
            }
          ]

          continue

        default:
          break
      }

      accum += char

      if (i === value.length - 1) {
        flush(i)
      }
    }

    return token
  }

  return parse(markdown)
}

const renderToken = (token: Token) => {
  const renderChildren = () =>
    token.children?.map(child => (
      <Fragment key={child.key}>{renderToken(child)}</Fragment>
    ))

  switch (token.type) {
    case 'newline':
      return <br />
    case 'block':
      return <CopyBlock>{renderChildren()}</CopyBlock>
    case 'heading':
      return <Title>{renderChildren()}</Title>
    case 'link':
      return (
        <Link
          isUnderlined
          href={token.href}
          target='_blank'
          text={token.label}
          size='copy'
        />
      )
    case 'text':
      return (
        <Span
          isBold={token.isBold}
          isItalic={token.isItalic}
          isMonospace={token.isMonospace}
          isStrikethrough={token.isStrikethrough}
        >
          {token.value}
          {renderChildren()}
        </Span>
      )
  }
}

type Props = {
  children: string
}

const Markdown = ({ children }: Props) => {
  const token = useMemo(() => tokenize(children), [children])
  return renderToken(token)
}

export { Markdown }
