export const siteIds = {
  development: {
    gillianLynneTheatre: '1',
    theLondonPalladium: '2',
    adelphiTheatre: '3',
    theatreRoyalDruryLane: '4',
    cambridgeTheatre: '5',
    herMajestysTheatre: '7',
    theOtherPalace: '8'
  },
  production: {
    gillianLynneTheatre: '1',
    theLondonPalladium: '2',
    adelphiTheatre: '3',
    theatreRoyalDruryLane: '4',
    cambridgeTheatre: '5',
    herMajestysTheatre: '7',
    theOtherPalace: '8'
  }
}
