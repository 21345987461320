import { createAsyncThunk } from '@reduxjs/toolkit'
import { bannerActions } from 'modules/banner'
import moment from 'moment'
import { normalize, schema } from 'normalizr'
import contentfulApi from '../services/contentfulApi'
import { ContentfulEventEntity, Event } from '../shared/types'

const eventSchema = new schema.Entity('events', undefined)
type FetchEventResponse = {
  events: Record<string, Event>
}

export const fetchEvents = createAsyncThunk<FetchEventResponse, undefined>(
  'marvel/self-serve/FETCH_EVENTS',
  async (_, { dispatch, rejectWithValue }) => {
    try {
      const events = (await contentfulApi.fetchMany({
        contentType: 'event',
        include: 1
      })) as ContentfulEventEntity[]

      if (!events) {
        throw new Error('Failed to fetch events')
      }
      const eventsData = events
        .filter(event => {
          if (!event.fields.endDate) return true
          const endDate = moment(event.fields.endDate)
          return endDate.utc() >= moment().utc()
        })
        .map(event => {
          return {
            ...event.fields,
            id: event.sys.id,
            title: event.fields.title,
            externalId: event.fields.externalId,
            endDate: event.fields.endDate,
            date: event.fields.date,
            image: event.fields.image?.fields.file.url,
            public: event.fields.public,
            venue: event.fields.venue.sys.id,
            genres: (event.fields?.genres ?? []).map(genre => genre.sys.id)
          }
        })

      const normalized = normalize(eventsData, [eventSchema])
      return normalized.entities as FetchEventResponse
    } catch (err) {
      dispatch(
        bannerActions.setBannerContent({
          bannerType: 'error',
          title: 'Please Note',
          text: 'Failed to fetch events.'
        })
      )
      return rejectWithValue(err)
    }
  }
)
