import { eventIds } from './event-ids'

// Assets
import JosephBannerImage from 'assets/joseph2021.jpg'
import CinderellaBannerImage from 'assets/cinderella_banner.jpg'
import FrozenBannerImage from 'assets/frozen_banner.jpg'
import BttfBannerImage from 'assets/BTTF_banner.jpg'
import PhantomBannerImage from 'assets/phantom_banner.jpg'
import MatildaBannerImage from 'assets/matilda_banner.jpg'
import BeautyAndTheBeastBannerImage from 'assets/batb_banner.jpg'
import LionWitchWardrobeBannerImage from 'assets/lwaw_banner.jpg'

export const eventBannerImage = (eventId?: string) => {
  switch (eventId) {
    case eventIds.development.joseph:
    case eventIds.production.joseph:
      return JosephBannerImage
    case eventIds.development.cinderella:
    case eventIds.production.cinderella:
      return CinderellaBannerImage
    case eventIds.development.frozen:
    case eventIds.production.frozen:
      return FrozenBannerImage
    case eventIds.development.backToTheFuture:
    case eventIds.production.backToTheFuture:
      return BttfBannerImage
    case eventIds.development.matilda:
    case eventIds.production.matilda:
      return MatildaBannerImage
    case eventIds.development.phantomNew:
    case eventIds.production.phantomNew:
      return PhantomBannerImage
    case eventIds.development.beautyAndTheBeast:
    case eventIds.production.beautyAndTheBeast:
      return BeautyAndTheBeastBannerImage
    case eventIds.development.lionWitchWardrobe:
    case eventIds.production.lionWitchWardrobe:
      return LionWitchWardrobeBannerImage
    default:
      return
  }
}
