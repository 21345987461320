import React, { useState, useEffect } from 'react'
import styled, { css } from 'styled-components/macro'

// Components
import { DateNavigator, Button, Icon } from 'marvel-components'
import { PerformanceItem } from './PerformanceItem'

// Types
import { Performance } from 'shared-types'

//Utils
import { media } from 'utils'
import moment from 'moment'
import { FieldArrayRenderProps } from 'formik'

const PerformanceListWrapper = styled.div`
  /* height: 100%; */
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  padding: 0;
  margin: 1.5rem 0 1rem 0;

  ${media.tablet} {
    padding: 0;
    margin-top: 0;
    margin-bottom: 1rem;
  }
`
const PerformanceListHeader = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: center;
  margin: 0 0 0.5rem 0;
`

const EmptyPerformanceList = styled.div((props) => {
  const {
    theme: {
      font: { copy }
    }
  } = props
  return css`
    margin: 4rem auto 7rem auto;
    font-family: ${copy};
    color: #666;
    text-align: center;
    width: 80%;
  `
})

const LoadMoreWrapper = styled.span`
  display: flex;
  justify-content: center;
  width: 100%;
`

type Props = {
  performances: Performance[]
  arrayHelpers: FieldArrayRenderProps
  availabilityThreshold: number
}

const PerformanceListForm: React.FC<Props> = ({
  performances,
  arrayHelpers,
  availabilityThreshold
}) => {
  const [firstPerformanceDate, setFirstPerformanceDate] = useState(moment.utc())
  const [lastPerformanceDate, setLastPerformanceDate] = useState(moment.utc())
  const [selectedPerformanceDate, setSelectedPerformanceDate] = useState(
    moment.utc()
  )
  const [limitPerformances, setLimitPerformances] = useState(true)

  const [filterdPerformances, setFilteredPerformances] = useState<
    Performance[]
  >([])

  const { form, push, remove, name } = arrayHelpers

  const handlePerformanceClick = (id: string, isSelected: boolean) => {
    if (isSelected) push(id)
    else {
      const idx = form.values[name].indexOf(id)
      remove(idx)
    }
  }

  const handlePreviousClick = () =>
    setSelectedPerformanceDate(
      moment.utc(selectedPerformanceDate.subtract(1, 'month'))
    )

  const handleNextClick = () =>
    setSelectedPerformanceDate(moment.utc(selectedPerformanceDate.add(1, 'month')))

  useEffect(() => {
    const filterdPerformances = performances.filter(
      (performance) =>
        moment(performance.startDate).isSame(
          selectedPerformanceDate,
          'month'
        ) &&
        moment(performance.startDate).isSame(selectedPerformanceDate, 'year') &&
        performance.capacityAvailable &&
        performance.capacityAvailable > availabilityThreshold
    )
    setFilteredPerformances(
      limitPerformances ? filterdPerformances.slice(0, 5) : filterdPerformances
    )
  }, [
    performances,
    selectedPerformanceDate,
    limitPerformances,
    availabilityThreshold
  ])

  useEffect(() => {
    if (performances.length > 0) {
      setFirstPerformanceDate(moment.utc(performances[0].startDate))
      setLastPerformanceDate(
        moment.utc(performances[performances.length - 1].startDate)
      )
      setSelectedPerformanceDate(moment.utc(performances[0].startDate))
    }
  }, [performances])
  const handleLoadMorePerformances = (e: React.MouseEvent) => {
    e.preventDefault()
    setLimitPerformances(!limitPerformances)
  }
  return (
    <>
      <PerformanceListWrapper>
        <PerformanceListHeader>
          <DateNavigator
            showPreviousButton={
              !firstPerformanceDate.isSame(selectedPerformanceDate, 'month')
            }
            showNextButton={
              !lastPerformanceDate.isSame(selectedPerformanceDate, 'month')
            }
            onPreviousClick={handlePreviousClick}
            onNextClick={handleNextClick}
            month={selectedPerformanceDate.toDate()}
          />
        </PerformanceListHeader>
        {filterdPerformances.length === 0 && (
          <EmptyPerformanceList>
            We're unable to match you with any performance this month. Please
            use the arrows next to the month above to find available
            performances in another month.
          </EmptyPerformanceList>
        )}
        {filterdPerformances.map((performance) => (
          <PerformanceItem
            key={performance.id}
            performance={performance}
            onClick={handlePerformanceClick}
            isSelected={form.values[name].includes(performance.id)}
            fieldName={name}
          />
        ))}
      </PerformanceListWrapper>
      <LoadMoreWrapper>
        <Button
          buttonType={'link-button'}
          isUnderlined
          onClick={handleLoadMorePerformances}
        >
          {`Show ${
            limitPerformances ? 'More' : 'Less'
          } Performances for ${selectedPerformanceDate.format('MMMM')}`}
          <Icon icon={limitPerformances ? 'chevron-down' : 'chevron-up'} />
        </Button>
      </LoadMoreWrapper>
    </>
  )
}

export { PerformanceListForm }
