import { createRoutine } from 'redux-saga-routines'
import { createAction } from '@reduxjs/toolkit'
import {
  ADD_SEAT,
  ADD_SEAT_BLOCK,
  REMOVE_SEAT,
  REMOVE_SEAT_BLOCK,
  ACCEPT_SEATS,
  ACCEPT_SEAT_RESTRICTION,
  ADD_PRODUCT,
  REMOVE_PRODUCT,
  REMOVE_PRODUCT_LINE_ITEM,
  CLEAR_PRODUCTS,
  TRIGGER_RESERVATION,
  CREATE_RESERVATION,
  UPDATE_RESERVATION,
  CLEAR_RESERVATION,
  CLEAR_RESERVATION_ON_SERVER,
  CREATE_PRODUCT_RESERVATION,
  CREATE_ORDER,
  FETCH_ORDER,
  FETCH_ORDER_FROM_TOKEN,
  FETCH_RESERVATION,
  EXPIRE_REDIRECT,
  UPDATE_PAYMENT_DETAILS,
  CLEAR_PAYMENT_DETAILS,
  INCREMENT_BEST_AVAILABLE,
  DECREMENT_BEST_AVAILABLE,
  CLEAR_BEST_AVAILABLE,
  ADD_GIFT_VOUCHER_TO_ORDER,
  CREATE_EXCHANGE,
  ADD_TICKET,
  REMOVE_TICKET,
  REMOVE_TICKET_LINE_ITEM,
  SET_EXTERNAL_CUSTOMER_ID,
  SET_BOOKING_FLOW,
  CREATE_ORDER_TOKEN
} from './types'

import { BaseTicket, BookingFlow, TicketWithSeat } from 'shared-types'

// Reservation
export const triggerReservation = createAction(TRIGGER_RESERVATION)
export const createReservation = createRoutine(CREATE_RESERVATION)

export const updateReservation = createRoutine(UPDATE_RESERVATION)
export const createProductReservation = createAction(CREATE_PRODUCT_RESERVATION)
export const fetchReservation = createRoutine(FETCH_RESERVATION)

// Order
export const createOrder = createRoutine<{ order: any; customer: any }>(
  CREATE_ORDER
)
export const fetchOrder = createRoutine(FETCH_ORDER)
export const fetchOrderFromToken = createRoutine(FETCH_ORDER_FROM_TOKEN)

export const createOrderToken = createRoutine<
  | { lastName: string; transactionGuid: string }
  | { lastName: string; transactionNumber: number | string }
>(CREATE_ORDER_TOKEN)

export const updatePaymentDetails = createRoutine(UPDATE_PAYMENT_DETAILS)
export const clearPaymentDetails = createAction(CLEAR_PAYMENT_DETAILS)
export const addGiftVoucherToOrder = createRoutine(ADD_GIFT_VOUCHER_TO_ORDER)

// Seats
export const addSeat = createAction<TicketWithSeat>(ADD_SEAT)
export const addSeatBlock = createAction<TicketWithSeat>(ADD_SEAT_BLOCK)
export const removeSeat = createAction<TicketWithSeat | undefined>(REMOVE_SEAT)
export const removeSeatBlock = createAction<TicketWithSeat | undefined>(
  REMOVE_SEAT_BLOCK
)
export const acceptSeatRestriction = createAction(ACCEPT_SEAT_RESTRICTION)

// Tickets (GA Seats)
export const addTicket = createAction<BaseTicket>(ADD_TICKET)
export const removeTicket = createAction<BaseTicket>(REMOVE_TICKET)
export const removeTicketLineItem = createAction<BaseTicket>(
  REMOVE_TICKET_LINE_ITEM
)
// Products
export const addProduct = createAction<string>(ADD_PRODUCT)
export const removeProduct = createAction<string>(REMOVE_PRODUCT)
export const removeProductLineItem = createAction<string>(
  REMOVE_PRODUCT_LINE_ITEM
)
export const clearProducts = createAction(CLEAR_PRODUCTS)

// Reservation
export const clearReservation = createAction<string | undefined>(
  CLEAR_RESERVATION
)
export const clearReservationOnServer = createRoutine(
  CLEAR_RESERVATION_ON_SERVER
)

// Order
export const expireRedirect = createAction(EXPIRE_REDIRECT)

// Best Available
export const incrementBestAvailable = createAction(INCREMENT_BEST_AVAILABLE)
export const decrementBestAvailable = createAction(DECREMENT_BEST_AVAILABLE)

export const clearBestAvailable = createAction(CLEAR_BEST_AVAILABLE)
export const acceptSeats = createAction(ACCEPT_SEATS)

// Exchange
export const createExchange = createRoutine(CREATE_EXCHANGE)

// External Customer Id
export const setExternalCustomerId = createAction<string>(
  SET_EXTERNAL_CUSTOMER_ID
)

// Booking flow
export const setBookingFlow = createAction<BookingFlow>(SET_BOOKING_FLOW)
