// Misc
import { createAsyncThunk } from '@reduxjs/toolkit'
import smartWaiterApi from 'services/smartWaiterApi'
import { normalize } from 'normalizr'

// Types
import { Menu, ZProduct, MenuSection, MenuItem } from 'shared-types'
import { menuEntity, menusEntity } from 'services/schemas'

type RequestParams = {
  venueId: string
  salesAreaId: string
  serviceId: string
}
export const fetchMenus = createAsyncThunk(
  'marvel/smart-waiter/FETCH_MENUS',
  async (params: RequestParams) => {
    const data = await smartWaiterApi.fetchAll(
      `/sw/venue/${params.venueId}/sales-area/${params.salesAreaId}/service/${params.serviceId}/menus/`
    )
    const normalized = normalize<
      any,
      {
        menus?: { [key: string]: Menu }
      }
    >(data.data, menusEntity)
    return normalized.entities
  }
)

type DetailRequestParams = RequestParams & { menuId: string }

export const fetchMenu = createAsyncThunk(
  'marvel/smart-waiter/FETCH_MENU_DETAIL',
  async (params: DetailRequestParams) => {
    const data = await smartWaiterApi.fetchAll(
      `/sw/venue/${params.venueId}/sales-area/${params.salesAreaId}/service/${params.serviceId}/menu/${params.menuId}/`
    )
    const normalized = normalize<
      any,
      {
        menus?: { [key: string]: Menu }
        items?: { [key: string]: MenuItem }
        products?: { [key: string]: ZProduct }
        sections?: { [key: string]: MenuSection }
      }
    >(data.data, menuEntity)
    return normalized.entities
  }
)
