import React, { useContext } from 'react'

// Components
import { AnchorMenu, Title, CopyBlock, Countdown } from 'marvel-components'

// Utils
import styled, { ThemeContext } from 'styled-components/macro'
import { media } from 'utils'
import moment from 'moment'

type Props = {
  isLoading: boolean
  performanceDate: moment.Moment
  platform: string
}

const MainTopWrapper = styled.div`
  background: ${props => props.theme.colors.secondaryBackgroundColor};
`

const OrderSuccessWrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin: 0 1.5rem;
  padding: 1.5rem 0;
  border-bottom: 1px solid ${props => props.theme.colors.borderColor};

  ${media.tablet} {
    border-bottom: none;
    flex-direction: column-reverse;
    margin: 0 1rem;
  }
`

const OrderSuccessCopy = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 4%;
  flex: 3;

  ${media.tablet} {
    margin: 1.5rem 0 1rem 0;
  }
`
const OrderSuccessCountdown = styled.div`
  flex: 1;
`

const OrderSuccessMenu = ({ isLoading, performanceDate, platform }: Props) => {
  const theme = useContext(ThemeContext)
  const {
    orderSuccess: {
      showHospitality,
      showTours,
      showSmartWaiter,
      showCecilBeatonBar,
      showGarden
    }
  } = theme
  const showExplore =
    showTours || showHospitality || showCecilBeatonBar || showGarden

  if (isLoading) {
    return null
  }
  return (
    <>
      {platform !== 'kiosk' && (
        <AnchorMenu
          isLoading={isLoading}
          items={[
            {
              title: 'Manage Order',
              id: 'manage-order'
            },
            {
              title: 'Upgrade your order',
              id: 'upgrade-order'
            },
            ...(showSmartWaiter
              ? [
                  {
                    title: 'Pre-order Drinks',
                    id: 'pre-order-drinks'
                  }
                ]
              : []),
            ...(showExplore
              ? [
                  {
                    title: 'Explore',
                    id: 'explore'
                  }
                ]
              : [])
          ]}
        />
      )}
      <MainTopWrapper>
        <OrderSuccessWrapper>
          <OrderSuccessCopy>
            <Title
              isCapitalised={false}
              isCentralised={false}
              isBold={true}
              size='large'
            >
              Time to Get Excited...
            </Title>
            <CopyBlock textSize='medium'>
              {`Your tickets are booked and you're all set. ${
                platform !== 'kiosk'
                  ? 'Come back to this page anytime to upgrade your order, access your tickets and to find out more.'
                  : ''
              }`}
            </CopyBlock>
          </OrderSuccessCopy>
          <OrderSuccessCountdown>
            {performanceDate && (
              <Countdown date={performanceDate} isLoading={isLoading} />
            )}
          </OrderSuccessCountdown>
        </OrderSuccessWrapper>
      </MainTopWrapper>
    </>
  )
}

export { OrderSuccessMenu }
