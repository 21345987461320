import React from 'react'
import styled, { css } from 'styled-components/macro'
import moment, { Moment } from 'moment'
import DayPicker, { NavbarElementProps } from 'react-day-picker'
import { media, usePlatform } from 'utils'
import { CalendarContext, Days } from './types'
import { WeekdayHeader } from './WeekdayHeader'
import { DayTile } from './DayTile'
import { Performance } from 'shared-types'

interface Props {
  data: Performance[]
  selectedPerformanceId?: string
  isSmallCalendar?: boolean
  onPerformanceClick: (performanceId: string) => void
  onMonthChange?: (date: Date) => void
  platform?: string

  // Render Nav Bar
  renderNavBar?: (nav: NavbarElementProps) => JSX.Element

  // Interaction
  selectedPerformanceDate?: Date
}

// Context
const { Provider: CalendarProvider, Consumer } = React.createContext<
  CalendarContext
>({
  isSmallCalendar: false
})

export const CalendarConsumer = Consumer

// Components
export const CalendarWrapper = styled(DayPicker)<{
  CalendarContext?: string
  isSmallCalendar?: boolean
  platform?: string
}>`
  display: inline-block;
  width: 100%;
  outline: 0;
  font-family: ${props => props.theme.font.copy};

  .DayPicker-wrapper {
    position: relative;
    flex-direction: row;
    user-select: none;

    :focus {
      outline: none;
    }
  }

  .DayPicker-Month {
    display: table;
    table-layout: fixed;
    flex: 1;
    margin-top: 0;
    width: 100%;
    border-spacing: 0.75rem;
    user-select: none;

    ${props =>
      props.isSmallCalendar &&
      css`
        border-spacing: 0;
        ${media.tablet} {
          border-spacing: 0;
        }
      `}

    ${media.tablet} {
      border-spacing: 0.15rem;
    }
  }

  .DayPicker-Weekdays {
    display: table-header-group;
  }

  .DayPicker-WeekdaysRow {
    display: table-row;
  }

  .DayPicker-Body {
    display: table-row-group;
  }

  .DayPicker-Week {
    display: table-row;
  }

  .DayPicker-Weekday abbr[title] {
    border-bottom: none;
    text-decoration: none;
  }

  .DayPicker-Day {
    position: relative;
    display: table-cell;
    overflow: hidden;
    box-sizing: border-box;
    vertical-align: top;
    height: 7.125rem;
    width: 10%;
    min-width: 10%;
    border-radius: 0.125rem;
    border: 0.125rem solid transparent;

    cursor: pointer;

    :focus {
      outline: none;
    }

    ${media.tablet} {
      height: ${props => (props.platform === 'kiosk' ? '10.5rem' : '4.875rem')};
    }

    ${props =>
      props.isSmallCalendar &&
      css`
        height: 4.875rem;
        border-radius: 0.1rem;
      `}
  }

  .DayPicker-Day--today {
    border: 0.1rem solid rgba(0, 0, 0, 0.5);
  }

  .DayPicker-Day--disabled {
    cursor: default;
    background: #ffffff;

    .calendar-performance-tile {
      display: none;
    }
  }
`

const formatData = (data: Performance[]) => {
  const performancesByDay: Days = {}
  let firstDate: Moment = data[0] ? moment.utc(data[0].startDate) : moment.utc()
  let lastDate: Moment = data[data.length - 1]
    ? moment.utc(data[data.length - 1].startDate)
    : moment.utc().endOf('month')

  data.reduce((performancesByDay: Days, performance) => {
    const performanceDate = moment(performance.startDate)

    const dayS = performanceDate.startOf('day')
    const dayString = dayS.valueOf()
    if (performancesByDay[dayString]) {
      performancesByDay[dayString].push(performance)
    } else {
      performancesByDay[dayString] = [performance]

      firstDate = performanceDate.isBefore(firstDate)
        ? performanceDate
        : firstDate
      lastDate = performanceDate.isAfter(lastDate) ? performanceDate : lastDate
    }

    return performancesByDay
  }, performancesByDay)

  return { performancesByDay, firstDate, lastDate }
}

// Context
const Calendar: React.FC<Props> = ({
  data,
  selectedPerformanceId,
  isSmallCalendar = false,
  onPerformanceClick,
  renderNavBar,

  // Interaction
  selectedPerformanceDate,

  // Date
  onMonthChange
}) => {
  const { performancesByDay, firstDate, lastDate } = formatData(data)
  const context = { isSmallCalendar, selectedPerformanceId }

  const today = moment.utc()
  const platform = usePlatform()

  return (
    <CalendarProvider value={context}>
      <CalendarWrapper
        platform={platform}
        isSmallCalendar={isSmallCalendar}
        showOutsideDays={true}
        disabledDays={[
          { before: today.toDate() },
          { after: lastDate.toDate() }
        ]}
        month={selectedPerformanceDate}
        firstDayOfWeek={1}
        fromMonth={firstDate.toDate()}
        initialMonth={firstDate.toDate()}
        toMonth={lastDate.toDate()}
        captionElement={<></>}
        navbarElement={renderNavBar}
        weekdayElement={weekday => (
          <WeekdayHeader {...weekday} isSmallCalendar={isSmallCalendar} />
        )}
        renderDay={(date, disabled) => {
          // Correct for times zones that are 12 hours or more - e.g. New Zealand
          const performanceKey = moment(date).startOf('day').valueOf()
          return (
            <DayTile
              date={date}
              onPerformanceClick={onPerformanceClick}
              performances={performancesByDay[performanceKey]}
              isDisabled={disabled.disabled}
            />
          )
        }}
        onMonthChange={onMonthChange}
      />
    </CalendarProvider>
  )
}

export { Calendar }
