import React from 'react'

// Misc
import styled from 'styled-components/macro'
import { media } from 'utils'

export type Props = {
  isCentred?: boolean
  isSpecial?: boolean
  className?: string
  isRounded?: boolean
  isPadded?: boolean
}

const DividerWrapper = styled.span`
  width: 100%;
  margin: 0.5rem 0;
`

const DividerContent = styled.div<{
  isCentred: boolean
  isSpecial: boolean
  isRounded: boolean
  isPadded: boolean
}>`
  background: ${props => (props.isSpecial ? '#111' : '#eee')};
  font-family: ${props => props.theme.font.copy};
  padding: 0.5rem 2%;
  padding: ${props => (props.isPadded ? '0.5rem 2%' : '0')};
  color: ${props => (props.isSpecial ? '#fff' : props.theme.colors.copyColor)};
  font-size: 0.875rem;
  text-transform: uppercase;
  text-align: ${props => (props.isCentred ? 'center' : 'left')};
  border-radius: ${props => (props.isRounded ? '4px' : '0')};
  ${media.tablet} {
    padding: ${props => (props.isPadded ? '0.5rem 3%' : '0')};
  }
  ${media.phone} {
    padding: ${props => (props.isPadded ? '0.5rem 5%' : '0')};
  }
`

const Divider: React.FC<Props> = ({
  children,
  isCentred = false,
  isSpecial = false,
  isRounded = false,
  isPadded = true,
  className
}) => {
  return (
    <DividerWrapper className={className}>
      <DividerContent
        isCentred={isCentred}
        isSpecial={isSpecial}
        isRounded={isRounded}
        isPadded={isPadded}
      >
        {children}
      </DividerContent>
    </DividerWrapper>
  )
}

export { Divider, DividerContent }
