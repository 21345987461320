declare global {
  interface Window {
    dmtrackingobjectname: any
    dmPt: any
  }
}

export type CartPhase = 'CUSTOMER_LOGIN' | 'ORDER_COMPLETE'
export interface CartLineItem {
  sku: string
  name: string
  description: string
  category: string
  other?: {
    [key: string]: string | number
  }
  unitPrice: number
  salePrice?: number
  quantity?: number
  totalPrice: number
  imageUrl: string
  productUrl: string
}
interface CartPayload {
  cartDelay: number
  cartID: string
  cartPhase: CartPhase
  currency: 'GBP'
  grandTotal: number
  cartUrl: string
  lineItems: CartLineItem[]
}

const ProgramId = parseInt(`${process.env.REACT_APP_DOT_MAILER_PROGRAM_ID}`, 10)

const initialize = (trackingId: string) => {
  if (typeof window === 'undefined' || typeof document === 'undefined') {
    return
  }
  if (!window.dmPt) {
    const script = document.createElement('script')
    script.async = true
    script.src = '//static.trackedweb.net/js/_dmptv4.js'
    document.body.appendChild(script)
    window['dmtrackingobjectname'] = 'dmPt'
    window['dmPt'] =
      window['dmPt'] ||
      function () {
        return (window['dmPt'].q = window['dmPt'].q || []).push(arguments)
      }
  }
  if (window.dmPt) window.dmPt('create', trackingId, window.location.hostname)
}

const identify = (email: string) => {
  if (window.dmPt) {
    window.dmPt('track')
    window.dmPt('identify', email)
  }
}

const trackCart = (cart: CartPayload) => {
  if (window.dmPt) {
    window.dmPt('track')
    window.dmPt('cartInsight', {
      programID: ProgramId,
      ...cart
    })
  }
}

const DotMailer = {
  initialize,
  identify,
  trackCart
}
export default DotMailer
