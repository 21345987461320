import React from 'react'

//Components
import { Button, CopyBlock, Title } from 'marvel-components'

// Assets
import HospitalityLogo from 'assets/hospitality_logo.png'

// Misc
import styled from 'styled-components'
import { media } from 'utils'

const HospitalityWrapper = styled.div`
  height: 100%;
  min-height: 100vh;
  width: 100%;
  background: #111111;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
`

const HospitalitySplashWrapper = styled.div`
  width: 400px;
  min-height: 90vh;
  height: 100%;
  margin: 3rem 0 0 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  ${media.phone} {
    width: 90%;
    justify-content: flex-start;
  }
`
const HospitalitySplashImage = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 0 0 0 0;
  ${media.phone} {
    width: 90%;
  }
`
const HospitalitySplashImageImage = styled.img`
  width: 150px;
  margin: 0 0 3rem 0;
  ${media.phone} {
    width: 50%;
  }
`

const OfflineTextArea = styled.div``

const OfflineTitle = styled(Title)`
  color: #fff;
`

const OfflineCopyBlock = styled(CopyBlock)`
  color: #fff;
`

const HospitalityButton = styled(Button)`
  text-transform: none;
  font-family: ${props => props.theme.font.header};
  font-size: 1rem;
`

const HospitalityFooter = styled.div`
  margin: 5rem 0 0 0;
  padding: 0 0 2rem 0;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  font-size: 0.85rem;
  ${media.phone} {
    width: 90%;
  }
`

const SummaryFooter = styled.div`
  font-family: ${props => props.theme.font.copy};
  margin-top: 2rem;
  color: ${props => props.theme.colors.borderColor};
`

const OfflineContainer: React.FC = () => {
  return (
    <HospitalityWrapper>
      <HospitalitySplashWrapper>
        <HospitalitySplashImage>
          <HospitalitySplashImageImage src={HospitalityLogo} />
        </HospitalitySplashImage>
        <OfflineTextArea>
          <OfflineTitle size='large' isCapitalised={false} isBold={true}>
            Hospitality Booking is currently offline
          </OfflineTitle>
          <OfflineCopyBlock isCentralised={true} isSidebar={true}>
            Please try again later
          </OfflineCopyBlock>
        </OfflineTextArea>

        <HospitalityFooter>
          <HospitalityButton buttonType='light' isBlock>
            Visit the FAQ
          </HospitalityButton>
          <SummaryFooter>LW Theatres © 2022</SummaryFooter>
        </HospitalityFooter>
      </HospitalitySplashWrapper>
    </HospitalityWrapper>
  )
}

export { OfflineContainer }
