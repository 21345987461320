// Components
import {
  Title,
  SelectMenuForm,
  SelectMenuFormValues,
  WithLoader
} from 'marvel-components'

// Selectors
import { venueSelectors } from 'modules/smart-waiter/venue'

// Actions
import { venueActions } from 'modules/smart-waiter/venue'

// Utils
import styled from 'styled-components'
import { media } from 'utils'
import moment from 'moment'
import { useHistory, useRouteMatch, Redirect } from 'react-router-dom'

// Misc
import { useDispatch, useSelector } from 'react-redux'

const SelectMenuContainerWrapper = WithLoader(styled.div`
  box-sizing: border-box;
  padding: 1.5rem 0 2.5rem 0;
  ${media.tablet} {
    padding: 1rem 10% 1.5rem 10%;
    margin-top: 0;
    min-height: calc(100vh - 109px);
  }
  ${media.phone} {
    padding: 1rem 0 1.5rem 0;
  }
`)

const SelectMenuDetails = styled.div`
  padding: 0 2%;
  ${media.tablet} {
    padding: 2rem 3% 5rem 3%;
  }
  ${media.phone} {
    padding: 2rem 5% 5rem 5%;
  }
`

const SelectMenuFormWrapper = styled.div`
  margin-top: 1rem;
`

const SelectMenuContainer = () => {
  const areas = useSelector(venueSelectors.selectAreas)
  let matinee = new Date()
  // Set the time to 14:30
  matinee.setHours(14)
  matinee.setMinutes(30)
  matinee.setSeconds(0)
  matinee.setMilliseconds(0)

  let evening = new Date()
  // Set the time to 19:30
  evening.setHours(19)
  evening.setMinutes(30)
  evening.setSeconds(0)
  evening.setMilliseconds(0)

  // Convert to ISO format
  const eventTimes = [
    {
      value: matinee.toISOString(),
      label: 'Matinee'
    },
    {
      value: evening.toISOString(),
      label: 'Evening'
    }
  ]

  //Actions
  const dispatch = useDispatch()

  // Routing
  const history = useHistory()
  const { url } = useRouteMatch()

  const handleOnSubmit = (values: SelectMenuFormValues) => {
    dispatch(venueActions.selectMenu(values))
    history.push(`${url}/area/${values.seatArea?.value}/service`)
  }

  return (
    <SelectMenuContainerWrapper isLoading={false}>
      <SelectMenuDetails>
        <Title
          isCapitalised={false}
          isCentralised={true}
          isBold={false}
          size='medium'
        >
          You're ordering for
        </Title>
        <Title
          isCapitalised={false}
          isCentralised={true}
          isBold={true}
          size='xlarge'
          isMultiline={true}
        >
          <span>
            Today
            <br />
          </span>
          <span>{moment.utc().format('dddd Do MMMM').toString()}</span>
        </Title>
        <SelectMenuFormWrapper>
          <SelectMenuForm
            areas={areas}
            eventTimes={eventTimes}
            onSubmit={handleOnSubmit}
          />
        </SelectMenuFormWrapper>
      </SelectMenuDetails>
    </SelectMenuContainerWrapper>
  )
}

export { SelectMenuContainer }
