import ReactGA from 'react-ga'
import TagManager from 'react-gtm-module'
import { Platform } from 'shared-types'

// Types
import {
  ECAddImpression,
  ECAddProduct,
  TicketingTagPayload,
  TransactionTagPayload,
  HospitalityTagPayload,
  BasketActionTagPayload,
  PartialTicketingTagPayload,
  SmartWaiterTagPayload
} from './types'

export const slugify = (text: string) => {
  return text
    .toString()
    .toLowerCase()
    .replace(/\s+/g, '-') // Replace spaces with -
    .replace(/[^\w\\-]+/g, '') // Remove all non-word chars
    .replace(/--+/g, '-') // Replace multiple - with single -
    .replace(/^-+/, '') // Trim - from start of text
    .replace(/-+$/, '') // Trim - from end of text
}

// Analytics
export const addImpression = (data: ECAddImpression) => {
  ReactGA.plugin.execute('ec', 'addImpression', {
    ...data
  })
}

export const addProduct = (data: ECAddProduct) => {
  ReactGA.plugin.execute('ec', 'addProduct', {
    ...data
  })
}

export const setCheckoutAction = (step: number) => {
  ReactGA.plugin.execute('ec', 'setAction', 'checkout', {
    step
  })
}

export const setSessionScope = (platform: Platform) => {
  ReactGA.set({ dimension9: platform })
}

export const setPurchaseAction = (
  reference: string | number,
  revenue: number,
  shipping?: number,
  tax?: number
) => {
  ReactGA.plugin.execute('ec', 'setAction', 'purchase', {
    id: `${reference}`,
    affiliation: 'Marvel',
    revenue: `${revenue}`,
    tax: `${tax || 0}`,
    shipping: `${shipping || 0}`
  })
}

export const trackAddToCart = (action: ECAddProduct) => {
  addProduct(action)
  ReactGA.plugin.execute('ec', 'setAction', 'add')
  ReactGA.event({
    category: 'UX',
    action: 'click',
    label: 'add to cart'
  })
}

// Tag Manager
export const pushTag = (
  payload:
    | TicketingTagPayload
    | PartialTicketingTagPayload
    | TransactionTagPayload
    | HospitalityTagPayload
    | BasketActionTagPayload
    | SmartWaiterTagPayload
) => {
  TagManager.dataLayer({
    dataLayer: payload
  })
}

export const clearDataLayer = () => {
  TagManager.dataLayer({
    dataLayer: {
      ecommerce: null
    }
  })
}
