import React from 'react'

// Components
import { ToolTip } from 'marvel-components'

// Containers
import { SeatToolTipContainer } from 'containers/Ticketing'

// Utils
import styled, { css } from 'styled-components/macro'
import { isTouchDevice, getSeatDisplay } from 'utils'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

// Types
import { SeatLineItem, TicketWithSeat } from 'shared-types'

type Props = {
  seatLineItem: SeatLineItem
  target: any
  onClick?: (seat: SeatLineItem) => void
  onRemoveClick?: (seat: TicketWithSeat) => void
}

const CircularTile = styled.div<{
  background?: string
}>((props) => {
  const { background } = props
  const {
    theme: {
      colors: { headingColor }
    }
  } = props

  return css`
    position: relative;
    width: 2.5rem;
    height: 2.5rem;
    line-height: 2.5rem;
    text-align: center;
    margin: 0 0.3125rem 0.3125rem 0;
    cursor: pointer;
    background: ${background};
    border-radius: 50%;
    font-size: 1rem;
    color: ${headingColor};

    :hover {
      ${CircularTileRemove} {
        transition: opacity 0.2s;
        opacity: 1;
      }
    }
  `
})

const CircularTileRemove = styled.button`
  fill: #000000;
  position: absolute;
  bottom: -5px;
  right: 0;
  background: none;
  border: none;
  width: 1rem;
  height: 1rem;
  outline: none;
  opacity: 0;
  background: #ffffff;
  border-radius: 50%;
  padding: 0;

  .svg-inline--fa {
    position: absolute;
    top: 0;
    left: 0;
    cursor: pointer;
    width: 100%;
    height: 100%;
  }
`
const SeatIconWrapper = styled.div<{ color: string }>((props) => {
  const { color } = props

  return css`
    background: ${color};
    position: absolute;
    bottom: -5px;
    right: 0;
    border: none;
    width: 1rem;
    height: 1rem;
    outline: none;
    border-radius: 50%;
    display: flex;
    justify-content: center;

    .svg-inline--fa {
      position: absolute;
      top: 0;
      left: 0;
      cursor: pointer;
      width: 100%;
      height: 100%;
    }
  `
})

const StyledIcon = styled(FontAwesomeIcon)((props) => {
  return css`
    && {
      color: #000;
      left: 50%;
      top: 50%;
      transform: translateX(-50%) translateY(-50%);
      width: 0.75rem;
      height: 0.75rem;
    }
  `
})

export const SeatListItem: React.FC<Props> = ({
  seatLineItem,
  onClick,
  onRemoveClick,
  target
}) => {
  const handleClick = () => onClick && onClick(seatLineItem)

  const handleRemoveClick = (event: React.MouseEvent) => {
    event.stopPropagation()
    onRemoveClick && onRemoveClick(seatLineItem)
  }
  const seatDisplay = getSeatDisplay(seatLineItem?.seatType)

  return (
    <ToolTip
      singleton={target}
      content={
        <SeatToolTipContainer
          seatId={seatLineItem.seatId}
          priceTypeId={seatLineItem.priceTypeId}
          onRemove={onRemoveClick}
        />
      }
    >
      <CircularTile background={seatLineItem.background} onClick={handleClick}>
        {seatLineItem.rowName && seatLineItem.rowName.substr(0, 2)}
        {seatLineItem.seatName}
        {seatDisplay?.note && (
          <SeatIconWrapper color={seatDisplay.background}>
            <StyledIcon
              icon={[seatDisplay.iconPrefix, seatDisplay.icon]}
              fixedWidth
            />
          </SeatIconWrapper>
        )}
        {!isTouchDevice && (
          <CircularTileRemove onClick={handleRemoveClick}>
            <FontAwesomeIcon icon={'times-circle'} fixedWidth />
          </CircularTileRemove>
        )}
      </CircularTile>
    </ToolTip>
  )
}
