// Selectors
import { venueSelectors } from 'modules/self-serve/venue'
import { useSelector } from 'react-redux'

// Misc
import { AppState } from 'modules'
import styled from 'styled-components'

const VenueLogoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

const Logo = styled.img`
  width: 5rem;
`

const NameLabel = styled.div`
  font-size: 1.25rem;
  font-family: ${props => props.theme.font.header};
  padding: 0 2rem;
  margin: 1.5rem 0 0 0;
  letter-spacing: 0.1rem;
  line-height: 1.3;
  text-transform: uppercase;
  text-align: center;
  width: 15rem;
`

type Props = {
  venueId: string
}

const VenueLogoContainer: React.FC<Props> = ({ venueId, ...props }) => {
  const venue = useSelector((state: AppState) =>
    venueSelectors.selectVenue(state.selfServe.venue, venueId)
  )

  if (!venue) {
    return null
  }

  return (
    <VenueLogoWrapper {...props}>
      <Logo src={venue.logo} />
      <NameLabel>{venue.title}</NameLabel>
    </VenueLogoWrapper>
  )
}

export { VenueLogoContainer }
