import React from 'react'

// Components
import { PricedItem, TotalTitle, Button, Title, Icon } from 'marvel-components'

// Misc
import styled from 'styled-components/macro'
import { media } from 'utils'

// Types
import { Transaction } from 'shared-types'

type Props = {
  summary: Transaction
  onRemoveItem?: (id: EntityId) => void
  title?: string
  hideOnMobile?: boolean
  inOwnView?: boolean
  isFreeShown?: boolean
  isZeroShown?: boolean
  isOrderSuccess?: boolean
}

const TransactionSummaryWrapper = styled.div<{
  hideOnMobile: boolean
  inOwnView: boolean
}>`
  padding: 1rem;
  display: flex;
  flex-direction: column;
  flex: 1 0 auto;
  position: relative;
  ${media.tablet} {
    padding: 0 1rem 1rem 1rem;
    display: ${props => (props.hideOnMobile ? 'none' : 'flex')};
    background: ${props =>
      props.theme.themeBackgroundColour
        ? props.theme.themeBackgroundColour
        : props.theme.colors.mainBackgroundColor};
  }
  ${media.phone} {
    padding: ${props =>
      props.inOwnView ? '0 1rem 6rem 1rem' : '0 1rem 1rem 1rem'};
  }
`
const TransactionSummaryTitle = styled(Title)`
  padding: 1rem 0 0 0;
`

const TotalWrapper = styled.div`
  padding: 1rem 0 0 0;
`

const ItemButton = styled(Button)`
  justify-content: flex-start;
`
const SectionWrapper = styled.div`
  padding: 1rem 0;
  font-family: ${props => props.theme.font.copy};
  color: ${props => props.theme.colors.mainBackgroundColorComplement};
  border-bottom: 1px solid ${props => props.theme.colors.borderColor};

  ${media.phone} {
    border-bottom: 1px solid ${props => props.theme.colors.borderColor};
  }
`

const SectionTitle = styled(Title)`
  margin: 0 0 0.5rem 0;
  padding: 0;
`
const SectionSubTitle = styled(Title)`
  margin: 0.5rem 0;
  padding: 0;
`
const ItemWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0.5rem 0 0 0;
`
const ItemsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0.5rem 0 0 0;
`
const PriceItemWrapper = styled.div`
  border-bottom: 1px solid ${props => props.theme.colors.borderColor};
  padding: 1rem 0;
`

const ItemTitle = styled.div`
  font-family: ${props => props.theme.font.header};
  padding-bottom: 0rem;
  line-height: 1.25;
  margin-bottom: 0.25rem;
  color: ${props => props.theme.colors.sidebarCopyColor};
`

const ItemDetail = styled.div`
  padding-left: 0;
  color: ${props => props.theme.colors.sidebarCopyColor};
  position: relative;
  font-size: 0.875rem;
  line-height: 1.5;
  margin-bottom: 0.25rem;
  margin-top: 0.25rem;
`

const ItemSubTitle = styled.div`
  padding-left: 0;
  color: ${props => props.theme.colors.sidebarCopyColor};
  position: relative;
  font-size: 0.875rem;
  line-height: 1.5;
`

const TransactionSuccessTitle = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 1rem;
`

const IconWrapper = styled.div`
  background: ${props => props.theme.colors.activeColor};
  border-radius: 50%;
  fill: #ffffff;
  width: 1.5rem;
  height: 1.5rem;
  line-height: 1.6rem; /* Note: Due to dodgy awesome font tick */
  text-align: center;
  font-size: 0.875rem;
  margin-left: 0.5rem;
`

const TransactionSummary: React.FC<Props> = ({
  summary,
  onRemoveItem,
  hideOnMobile = false,
  inOwnView = false,
  title = 'Your Basket Summary',
  isFreeShown = true,
  isZeroShown = false,
  isOrderSuccess = false
}) => {
  return (
    <TransactionSummaryWrapper
      hideOnMobile={hideOnMobile}
      inOwnView={inOwnView}
    >
      {isOrderSuccess && (
        <TransactionSuccessTitle>
          <Title
            isCapitalised={false}
            isCentralised={false}
            isSidebar={true}
            isBold={true}
            size='medium'
          >
            Order Complete
          </Title>
          <IconWrapper>
            <Icon icon={'check'} />
          </IconWrapper>
        </TransactionSuccessTitle>
      )}

      <TransactionSummaryTitle
        isCapitalised={true}
        isCentralised={false}
        isSidebar={true}
      >
        {title}
      </TransactionSummaryTitle>
      {summary.groups.length > 0 && (
        <>
          {summary.groups.map(group => {
            return (
              <SectionWrapper key={group.id}>
                <SectionTitle
                  isCapitalised={true}
                  isBold={true}
                  isSidebar={true}
                  isCentralised={false}
                >
                  {group.name}
                </SectionTitle>
                {group.subtitle && (
                  <SectionSubTitle
                    isCapitalised={false}
                    isBold={false}
                    isSidebar={true}
                    isCentralised={false}
                  >
                    {group.subtitle}
                  </SectionSubTitle>
                )}
                <ItemsWrapper>
                  {group.items.map((item, idx) => {
                    const handelRemoveItem = (e: React.MouseEvent) => {
                      e.preventDefault()
                      onRemoveItem && onRemoveItem(item.id)
                    }
                    return (
                      <ItemWrapper key={idx}>
                        {item.title && <ItemTitle>{item.title}</ItemTitle>}
                        {item.detail && <ItemDetail>{item.detail}</ItemDetail>}
                        <PricedItem
                          key={item.id}
                          price={item.price}
                          isFreeShown={isFreeShown}
                          isZeroShown={isZeroShown}
                        >
                          <ItemSubTitle>
                            {item.quantity} x {item.subtitle}
                          </ItemSubTitle>
                        </PricedItem>
                        {item.choices &&
                          item.choices.map(choice => (
                            <PricedItem
                              key={choice.id}
                              price={choice.price}
                              isFreeShown={isFreeShown}
                              isZeroShown={isZeroShown}
                            >
                              <ItemSubTitle>
                                {choice.quantity} x {choice.title}
                              </ItemSubTitle>
                            </PricedItem>
                          ))}
                        {!!onRemoveItem && (
                          <ItemButton
                            buttonType='link-button'
                            size='extra-small'
                            onClick={handelRemoveItem}
                            text='Remove'
                            isSidebar={true}
                          />
                        )}
                      </ItemWrapper>
                    )
                  })}
                </ItemsWrapper>
              </SectionWrapper>
            )
          })}
          {/* Subtotal */}
          {summary.subtotals.map((item, idx) => (
            <PriceItemWrapper key={idx}>
              <PricedItem
                price={item.amount}
                isFreeShown={isFreeShown}
                isZeroShown={isZeroShown}
              >
                {item.description}
              </PricedItem>
            </PriceItemWrapper>
          ))}

          {/* Total */}
          <TotalWrapper>
            <TotalTitle fullValue={summary.total} discountedValue={0} />
          </TotalWrapper>
        </>
      )}
    </TransactionSummaryWrapper>
  )
}
export { TransactionSummary }
