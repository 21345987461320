// Components
import { Checkbox, CheckboxProps, FormError, Link } from 'marvel-components'

// Misc
import { ErrorMessage, FieldProps } from 'formik'
import React, { ReactNode } from 'react'
import styled from 'styled-components'

interface Props extends FieldProps, CheckboxProps {
  replaceAnchorsWithLinks: boolean
}

const renderLinkFromAnchorSource = (source: string) => {
  const openTagOpenIndex = source.indexOf('<', 0)
  const openTagCloseIndex = source.indexOf('>', openTagOpenIndex)
  const closeTagOpenIndex = source.indexOf('<', openTagCloseIndex)

  const content = source.substr(
    openTagCloseIndex + 1,
    closeTagOpenIndex - openTagCloseIndex - 1
  )
  const attrsString = source
    .substr(openTagOpenIndex, openTagCloseIndex - openTagOpenIndex)
    .replace('<a ', '')
    .split(' ')

  const attrs = attrsString.reduce<any>((obj, attr) => {
    if (attr.indexOf('=') < 0) return
    const [key, value] = attr.trim().split('=')
    return {
      ...obj,
      [key]: value.substr(1, value.length - 2) // Gets rid of the double quotes.
    }
  }, {})

  return <Link href={attrs.href} text={content} isUnderlined={true} />
}

const renderFromStringWithLinks = (text: string): ReactNode => {
  const anchorCloseTagLength = 4 // </a>

  const anchorStartIndex = text.indexOf('<a', 0)
  const anchorEndIndex =
    text.indexOf('</a>', anchorStartIndex) + anchorCloseTagLength
  if (anchorEndIndex < 0 || anchorStartIndex < 0) {
    return <>{text}</>
  }

  const anchorSource = text.substr(
    anchorStartIndex,
    anchorEndIndex - anchorStartIndex
  )
  const leftText = text.substr(0, anchorStartIndex)
  const rightText = text.substr(anchorEndIndex)

  return (
    <>
      {leftText}
      {renderLinkFromAnchorSource(anchorSource)}
      {renderFromStringWithLinks(rightText)}
    </>
  )
}

const FormOptinWrapper = styled.div<{ noPadding?: boolean }>`
  position: relative;
  padding-bottom: 1.4rem;
`

const FormOptin: React.FC<Props> = ({
  field,
  form,
  label,
  isLabelVisible,
  size,
  checkboxTheme,
  replaceAnchorsWithLinks
}) => {
  const { name } = field
  const { setFieldValue } = form
  return (
    <FormOptinWrapper>
      <ErrorMessage {...field} component={FormError} />
      <Checkbox
        {...field}
        isChecked={field.value}
        label={label}
        aria-label={label}
        isLabelVisible={isLabelVisible}
        size={size}
        checkboxTheme={checkboxTheme}
        onChange={(isChecked: boolean) => {
          setFieldValue(name, isChecked)
        }}
      >
        {replaceAnchorsWithLinks ? renderFromStringWithLinks(label) : undefined}
      </Checkbox>
    </FormOptinWrapper>
  )
}

export { FormOptin }
