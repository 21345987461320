import React, { useEffect } from 'react'

// Views
import { DaySeatTicketsView } from 'views/Ticketing'

// Containers
import { BrandLogoContainer, DaySeatsListContainer } from 'containers/Ticketing'

// Components
import { FooterNav, Layout } from 'marvel-components'

// Selectors
import { eventDetailsSelectors } from 'modules/ticketing/event'

// Actions
import { setPromoCode } from 'modules/discount/actions'
import { performanceActions } from 'modules/ticketing/performance/performanceSlice'

// Utils
import { daySeatBookingWindow } from 'utils/day-seat-assets'

// Misc
import { useSelector, useDispatch } from 'react-redux'
import {
  Redirect,
  Route,
  Switch,
  useHistory,
  useRouteMatch
} from 'react-router-dom'
import { selectDaySeatsRoute, selectDaySeatTicketsRoute } from 'utils'
import moment from 'moment'

type Props = {
  eventId: string
  promoCode: string
}

const DaySeatsView: React.FC<Props> = ({ eventId, promoCode }) => {
  const { path } = useRouteMatch()
  const history = useHistory()
  const dispatch = useDispatch()

  const eventName = useSelector(eventDetailsSelectors.selectEventName)

  const handlePerformanceClick = (performanceId: string) => {
    history.push(selectDaySeatTicketsRoute(eventId, performanceId))
  }

  // Set promo for day seats
  useEffect(() => {
    eventId &&
      dispatch(
        setPromoCode({
          code: promoCode
        })
      )
  }, [eventId, dispatch, promoCode])

  // Fetch Performances
  // ------------------------------------------
  useEffect(() => {
    eventId &&
      dispatch(
        performanceActions.fetchPerformances({
          eventId,
          promoCode
        })
      )
  }, [eventId, promoCode, dispatch])

  const now = moment.utc()
  let windowDates = daySeatBookingWindow(eventId)

  let bookingWindowStart = windowDates[0]
  let bookingWindowClose = windowDates[1]

  const showCountdown =
    now.isBefore(bookingWindowStart) || now.isAfter(bookingWindowClose)
  //const showCountdown = true

  return (
    <Switch>
      <Route exact path={`${path}`}>
        <Layout
          pageTitle={`Choose your performance - ${eventName} | LW Theatres`}
          renderBrandLogo={<BrandLogoContainer showLogin />}
          renderMain={
            <DaySeatsListContainer
              bookingWindowStart={bookingWindowStart}
              bookingWindowClose={bookingWindowClose}
              onPerformanceClick={handlePerformanceClick}
              eventId={eventId}
            />
          }
          renderFooter={<FooterNav />}
        />
      </Route>
      <Route exact path={`${path}/performance/:performanceId`}>
        {showCountdown ? (
          <Redirect to={selectDaySeatsRoute(eventId)} />
        ) : (
          <DaySeatTicketsView eventId={eventId} promoCode={promoCode} />
        )}
      </Route>
    </Switch>
  )
}

export { DaySeatsView }
