// Components
import { Icon } from 'marvel-components'

// Selectors
import { venueSelectors } from 'modules/self-serve/venue'
import { useSelector } from 'react-redux'

// Misc
import { AppState } from 'modules'
import styled from 'styled-components'
import { VenueDropdownContainer } from './VenueDropdownContainer'
import { useState } from 'react'

const Wrapper = styled.div`
  font-family: ${props => props.theme.font.header};
  text-align: center;
  text-transform: uppercase;
`

const CallToAction = styled.span`
  font-size: 1rem;
  letter-spacing: 0.3rem;
`

const VenueTitleWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 1rem;
  cursor: pointer;
`

const VenueTitle = styled.h1`
  font-size: 1.6rem;
  margin: 0;
  letter-spacing: 0.3rem;
  font-weight: normal;
`

const Chevron = styled(Icon)`
  margin-left: 0.5rem;
`

type Props = {
  venueId: string
}

const VenueTitleContainer: React.FC<Props> = ({ venueId }) => {
  const [isDropdownVisible, setIsDropdownVisible] = useState(false)

  const venue = useSelector((state: AppState) =>
    venueSelectors.selectVenue(state.selfServe.venue, venueId)
  )

  if (!venue) {
    return null
  }

  const toggleDropdown = () => {
    setIsDropdownVisible(!isDropdownVisible)
  }

  return (
    <Wrapper>
      <CallToAction>What's On</CallToAction>
      <VenueTitleWrapper onClick={toggleDropdown}>
        <VenueTitle>{venue.title}</VenueTitle>
        <Chevron icon='chevron-down' />
      </VenueTitleWrapper>
      {isDropdownVisible && (
        <VenueDropdownContainer onWrapperClick={toggleDropdown} />
      )}
    </Wrapper>
  )
}

export { VenueTitleContainer }
