// Components
import { WithLoader } from 'marvel-components'

// Containers
import { FooterContainer } from 'containers/FooterContainer'

// Selectors
import { useSelector } from 'react-redux'
import { venueSelectors } from 'modules/self-serve/venue'
import { eventCollectionSelectors } from 'modules/self-serve/event-collection'

// Utils
import { Link, useRouteMatch } from 'react-router-dom'
import styled from 'styled-components'
import { venueEvents } from 'utils'
import { AppState } from 'modules'

// Assets
import lwLightLogo from 'assets/lw_logo_white.svg'
import { venuePageSelectors } from 'modules/self-serve/venue-page'
import { EventSpotlightContainer } from 'containers/Kiosk/EventSpotlightContainer'
import { EventCollectionContainer } from 'containers/Kiosk/EventCollectionContainer'
import { VenueTileGridContainer } from 'containers/Kiosk/VenueTileGridContainer'
import { VenueHeaderContainer } from 'containers/Kiosk/VenueHeaderContainer'
import { VenueLogoContainer } from 'containers/Kiosk/VenueLogoContainer'

const WelcomeHeading = styled.h1`
  font-size: 2.5rem;
  font-family: ${props => props.theme.font.header};
  text-align: center;
  margin: 4rem 0;
  font-weight: normal;
`

const Section = styled.section<{
  isDark?: boolean
  slantedTop?: boolean
  slantedBottom?: boolean
}>`
  margin-top: ${props => (props.slantedTop ? '15rem' : '5rem')};
  margin-left: 0;
  margin-right: 0;
  margin-bottom: ${props => (props.slantedBottom ? '15rem' : '5rem')};
  position: relative;
  text-align: center;
  color: ${props =>
    props.isDark
      ? props.theme.colors.venueSecondaryCopyColor
      : props.theme.colors.venuePrimaryCopyColor};
  background: ${props =>
    props.isDark
      ? props.theme.colors.venueSecondaryBackgroundColor
      : props.theme.colors.venuePrimaryBackgroundColor};

  ::before {
    display: ${props => (props.slantedTop ? 'block' : 'none')};
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    background: inherit;
    z-index: -1;
    left: 0;
    bottom: 0;
    transform-origin: left top;
    transform: skewY(-5deg);
  }

  ::after {
    display: ${props => (props.slantedBottom ? 'block' : 'none')};
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    background: inherit;
    z-index: -1;
    left: 0;
    bottom: 0;
    transform-origin: right bottom;
    transform: skewY(-5deg);
  }
`

const SectionTitle = styled.h1<{ align?: 'left' | 'right' | 'center' }>`
  font-size: 1.2rem;
  text-align: ${props => props.align || 'left'};
  text-transform: uppercase;
  font-family: ${props => props.theme.font.header};
  letter-spacing: 0.3rem;
  margin: 2rem 0;
  font-weight: normal;
`

const SectionSubtitle = styled.p<{ align?: 'left' | 'right' | 'center' }>`
  font-size: 1.4rem;
  text-align: ${props => props.align || 'left'};
  font-family: ${props => props.theme.font.copy};
  margin: 3rem 0;
`

const SectionContent = styled.div<{ noPad?: boolean }>`
  padding: ${props => (props.noPad ? '0' : '0 1rem')};
`

const CallToActionButton = styled(Link)`
  display: inline-block;
  background: transparent;
  border-width: 2px;
  border-style: solid;
  border-color: #d7d7d7;
  border-radius: 3px;
  box-shadow: none;
  font-size: 1.2rem;
  font-family: ${props => props.theme.font.header};
  color: #333333;
  text-decoration: none;
  text-transform: uppercase;
  letter-spacing: 0.3rem;
  padding: 1.3rem 12rem;
  &:hover {
    border-color: #999;
    background: #f1f1f1;
    color: #000;
  }
`

const CallToActionSection = styled(Section)`
  margin: 6rem 0 10rem 0;
`

const LWSection = styled(Section)`
  padding: 4rem 0 6rem 0;
`

const LWLogoWrapper = styled.div`
  display: flex;
  justify-content: center;
  height: 80px;
`

const LWLogo = styled.img`
  width: 100px;
`

const LWSummary = styled.h1`
  font-size: 1.8rem;
  font-family: ${props => props.theme.font.header};
  padding: 0 15%;
  margin: 6rem 0;
  line-height: 1.4;
  font-weight: normal;
`

const VenueViewWrapper = WithLoader(styled.div`
  background: ${props => props.theme.colors.venuePrimaryBackgroundColor};
  color: ${props => props.theme.colors.venuePrimaryCopyColor};
  min-height: 100vh;
  z-index: 0;
`)

const TheatresSection = styled(Section)`
  margin: 10rem 0 8rem 0;
`

const VenueHomeView = () => {
  const {
    params: { venueId }
  } = useRouteMatch<{ venueId: string }>()

  const isVenueLoading = useSelector(venueSelectors.selectIsVenueLoading)
  const isEventCollectionLoading = useSelector(
    eventCollectionSelectors.selectIsEventCollectionLoading
  )
  const isVenuePageLoading = useSelector(
    venuePageSelectors.selectIsVenuePageLoading
  )

  const venue = useSelector((state: AppState) =>
    venueSelectors.selectVenue(state.selfServe.venue, venueId)
  )
  const venuePage = useSelector((state: AppState) =>
    venuePageSelectors.selectVenuePage(state.selfServe.venuePage, venueId)
  )

  if (!venuePage || !venue) {
    return null
  }

  return (
    <VenueViewWrapper
      isLoading={
        isVenueLoading || isEventCollectionLoading || isVenuePageLoading
      }
    >
      <VenueHeaderContainer venueId={venueId}>
        <VenueLogoContainer venueId={venueId} />
      </VenueHeaderContainer>

      <WelcomeHeading>Welcome to {venue.title}</WelcomeHeading>

      {venuePage.spotlight && (
        <Section>
          <SectionTitle align='center'>Spotlight</SectionTitle>
          <EventSpotlightContainer eventCollectionId={venuePage.spotlight} />
        </Section>
      )}

      {venuePage.venueFeatured && (
        <Section>
          <SectionContent noPad={true}>
            {venuePage.venueFeatured.map((eventCollectionId, i) => (
              <EventCollectionContainer
                key={i}
                eventCollectionId={eventCollectionId}
                isDark={true}
              />
            ))}
          </SectionContent>
        </Section>
      )}

      <CallToActionSection>
        {venuePage.callToAction && (
          <CallToActionButton to={venueEvents(venueId)}>
            {venuePage.callToAction}
          </CallToActionButton>
        )}
      </CallToActionSection>

      {venuePage.globalFeatured && (
        <LWSection isDark={true} slantedTop={true} slantedBottom={true}>
          <SectionContent noPad={true}>
            <LWLogoWrapper>
              <LWLogo src={lwLightLogo} />
            </LWLogoWrapper>
            <LWSummary>
              Explore what’s on at Andrew Lloyd Webber’s family of theatres in
              London’s West End
            </LWSummary>
            {venuePage.globalFeatured.map((eventCollectionId, i) => (
              <EventCollectionContainer
                key={i}
                eventCollectionId={eventCollectionId}
              />
            ))}
          </SectionContent>
        </LWSection>
      )}

      <TheatresSection>
        <SectionTitle align='center'>Explore our theatres</SectionTitle>
        <SectionSubtitle align='center'>
          See what’s coming up at each of our venues
        </SectionSubtitle>
        <SectionContent>
          <VenueTileGridContainer />
        </SectionContent>
      </TheatresSection>

      <FooterContainer />
    </VenueViewWrapper>
  )
}

export { VenueHomeView }
