import React from 'react'
import styled from 'styled-components/macro'
import { connect } from 'react-redux'
import { media } from 'utils'

// Modules
import { overlayActions } from 'modules/overlay'

// Components
import { Title, Button, CopyBlock } from 'marvel-components'

interface Props {
  // Overlay
  closeOverlay: typeof overlayActions.closeModal
}

const CreateAccountWrapper = styled.div`
  padding: 1rem;
  max-width: 23.75rem;

  ${media.tablet} {
    width: 70vw;
  }
`

const StyledButton = styled(Button)`
  margin-top: 1rem;
`

const CreateAccount = (props: Props) => {
  const { closeOverlay } = props

  const handleCancel = () => {
    closeOverlay()
  }

  return (
    <CreateAccountWrapper>
      <Title size="large">Coming Soon</Title>
      <CopyBlock isCentralised={true}>
        Creating a user account is coming soon.
      </CopyBlock>
      <StyledButton
        text="Close"
        onClick={() => handleCancel()}
        isBlock={true}
        icon="times"
      />
    </CreateAccountWrapper>
  )
}

export const CreateAccountContainer = connect(null, {
  ...overlayActions
})(CreateAccount)
