import React from 'react'

// Components
import { BreadcrumbNav } from 'marvel-components'

// Misc
import { useRouteMatch } from 'react-router-dom'

// Types
import { NavItem } from 'shared-types'

type Props = {
  routes: NavItem[]
}
const BreadcrumbContainer: React.FC<Props> = ({ routes }) => {
  const { path, params } = useRouteMatch<{ [key: string]: string }>()
  const crumbs = routes
    .filter((route) => path.includes(route.match ? route.match : route.path))
    .map((crumb) => ({
      ...crumb,
      current: path === crumb.path,
      path: Object.keys(params).length
        ? Object.keys(params).reduce(
            (path, param) => path.replace(`:${param}`, params[param]),
            crumb.path
          )
        : crumb.path
    }))
  return <BreadcrumbNav items={crumbs} />
}

export { BreadcrumbContainer }
