import ExtendableError from 'es6-error'
import { Error } from './types'

class ApiError extends ExtendableError {
  constructor(
    message = 'There was a problem with your booking. Please try again.'
  ) {
    super(message)
    this.name = 'Please Note'
  }
}

class NetworkError extends ExtendableError {
  errors: Error
  constructor(
    message = 'There was a problem with your booking. Please try again.'
  ) {
    super(message)
    this.errors = { _error: message }
    this.name = 'Please Note'
  }
}

export { ApiError, NetworkError }
