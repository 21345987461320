import backgroundAsset from './assets/batb_sidebar.jpg'
export const BeautyAndTheBeast = {
  colors: {
    mainBackgroundColor: '#05041d',
    mainColor: '#723a99',
    mainColorComplement: '#ffffff',
    lwBrandColor: '#ffffff',
    lwBrandColorComplement: '#111111',
    performanceHoverColor: '#d3b7e0',
    bestAvailableBannerColor: '#d3b7e0',
    bestAvailableBannerColorComplement: '#111111'
  },
  themeBackgroundAsset: backgroundAsset,
  themeBackgroundColour: '#05041d',
  orderSuccess: {
    showHospitality: true,
    showCecilBeatonBar: true
  }
}
