import { schema } from 'normalizr'
import {
  Service,
  ZImage,
  Venue,
  MenuItem,
  Menu,
  MenuSection,
  ZProduct,
  SWOrder
} from 'shared-types'

export const serviceEntity = new schema.Entity<Service>('services')
export const imageEntity = new schema.Entity<ZImage>('images')
export const venueEntity = new schema.Entity<Venue>('venues', {
  services: [serviceEntity],
  images: [imageEntity]
})
export const venuesEntity = [venueEntity]

export const menuItemEntity = new schema.Entity<MenuItem>('items')
export const menuSectionEntity = new schema.Entity<MenuSection>('sections', {
  items: [menuItemEntity]
})
export const productEntity = new schema.Entity<ZProduct>('products')
export const menuEntity = new schema.Entity<Menu>('menus', {
  sections: [menuSectionEntity],
  products: [productEntity]
})
export const menusEntity = [menuEntity]

export const orderEntity = new schema.Entity<SWOrder>('orders')
export const ordersEntity = [orderEntity]
