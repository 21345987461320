import { useHistory } from 'react-router-dom'
import styled from 'styled-components'

// Assets
import mainMenuIcon from 'assets/main_menu_icon.png'
import kioskBackIcon from 'assets/kiosk_back_icon.png'
import { useSelector } from 'react-redux'
import { venueSelectors } from 'modules/self-serve/venue'
import { AppState } from 'modules'
import { kioskMainMenu } from 'utils'

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 3rem 4rem;
  max-width: 100%;
  align-items: flex-start;
`

const HeaderButton = styled.button`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  background: none;
  border: none;
  cursor: pointer;
`

const HeaderButtonIcon = styled.img`
  width: 2.8rem;
  margin-bottom: 0.5rem;
`

const HeaderButtonText = styled.span`
  text-transform: uppercase;
  letter-spacing: 0.1rem;
  font-size: 0.9rem;
  margin-top: 0.5em;
  font-family: ${props => props.theme.font.header};
`

type Props = {
  venueId: string
  children?: React.ReactNode
}

const VenueHeaderContainer: React.FC<Props> = ({ venueId, children }) => {
  const history = useHistory()
  const venue = useSelector((state: AppState) =>
    venueSelectors.selectVenue(state.selfServe.venue, venueId)
  )

  if (!venue) {
    return null
  }

  const handleBackClick = () => {
    history.goBack()
  }

  const handleHomeClick = () => {
    history.push(kioskMainMenu())
  }

  return (
    <Header>
      <HeaderButton onClick={handleBackClick}>
        <HeaderButtonIcon src={kioskBackIcon} />
        <HeaderButtonText>Back</HeaderButtonText>
      </HeaderButton>
      {children}
      <HeaderButton onClick={handleHomeClick}>
        <HeaderButtonIcon src={mainMenuIcon} />
        <HeaderButtonText>Menu</HeaderButtonText>
      </HeaderButton>
    </Header>
  )
}

export { VenueHeaderContainer }
