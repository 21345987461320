import { DefaultTheme } from 'styled-components'
import './fonts.css'

const LWTheme: DefaultTheme = {
  name: 'LW',
  sociallyDistanced: false,
  orderSuccess: {},
  basketNote: {
    title: 'Including restoration levy',
    note:
      'The restoration levy goes directly towards maintaining our beautiful theatres, making sure we can complete essential works to keep them functional.'
  },
  font: {
    copy: 'axiformaregular',
    button: 'axiformabold',
    header: 'axiformabold',
    input: 'axiformaregular',
    kioskHeader: 'tiempossemi'
  },
  colors: {
    headingColor: '#222222',
    copyColor: '#494949',
    borderColor: '#cccccc',

    sidebarCopyColor: '#ffffff',

    activeColor: '#35965a',
    activeColorComplement: '#ffffff',

    mainColor: '#ffffff',
    mainColorComplement: '#323232',

    lwBrandColor: '#111111',
    lwBrandColorComplement: '#ffffff',

    lwButtonColor: '#323232',
    lwButtonTextColor: '#ffffff',

    secondaryColor: '#707070',
    secondaryColorComplement: '#ffffff',

    alertColor: '#c80909',
    alertColorComplement: '#000000',

    mainBackgroundColor: '#323232',
    mainBackgroundColorComplement: '#ffffff',

    secondaryBackgroundColor: '#ffffff',
    secondaryBackgroundColorComplement: '#595959',

    bestAvailablePerformanceColor: '#d59c43',
    bestAvailablePerformanceColorComplement: '#ffffff',

    discountedPerformanceColor: '#35965a',
    discountedPerformanceColorComplement: '#ffffff',

    fadedHighlightColor: '#e6e6e6',

    performanceHoverColor: '#cccccc',

    numberFilterSelectorColor: '#111111',
    numberFilterSelectorCopyColor: '#ffffff',

    priceSliderHandleColor: '#323232',

    bestAvailableBannerColor: '#35965a',
    bestAvailableBannerColorComplement: '#ffffff',

    gradientValueColor: '#999999',
    gradientPremiumColor: '#555555',
    gradientTextColor: '#ffffff',

    availabilityBarColor: '#dedede',

    kioskPrimaryBackgroundColor: '#FFFFFF',
    kioskPrimaryBackgroundColorComplement: '#111111',
    kioskSecondaryBackgroundColor: '#111111',
    kioskSecondaryBackgroundColorComplement: '#FFFFFF',

    venuePrimaryBackgroundColor: '#ffffff',
    venuePrimaryCopyColor: '#111111',
    venueSecondaryBackgroundColor: '#111111',
    venueSecondaryCopyColor: '#ffffff',
    venueLogoBackgroundColor: '#fff'
  }
}
export { LWTheme }
