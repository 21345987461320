import React, { useEffect } from 'react'

// Components
import { BackButton, Layout, SWFooterNav } from 'marvel-components'

// Containers
import {
  BrandLogoContainer,
  CheckoutButtonContainer,
  BasketSummaryContainer,
  CheckoutContainer
} from 'containers/SmartWaiter'

// Actions
import { optinActions } from 'modules/smart-waiter/optin/optinSlice'

// Selectors
import { basketSelectors } from 'modules/smart-waiter/basket'

// Misc
import { useSelector } from 'react-redux'
import { useAppDispatch } from 'app/reduxStore'
import {
  Route,
  Switch,
  useRouteMatch,
  Redirect,
  useHistory
} from 'react-router-dom'
import { trackSmartWaiterBasket } from 'utils'
import { productSelectors } from 'modules/smart-waiter/product'
import { venueSelectors } from 'modules/smart-waiter/venue'
import { useTypedSelector } from 'modules'

const BasketView: React.FC = () => {
  // Routing
  const {
    path,
    params: { venueId, salesAreaId }
  } = useRouteMatch<{ venueId: string; salesAreaId: string }>()

  // Selectors
  const history = useHistory()

  const isBasketEmpty = useSelector(basketSelectors.selectIsBasketEmpty)
  const basket = useSelector(basketSelectors.selectBasket)
  const products = useSelector(productSelectors.selectEntities)
  const venueName = useTypedSelector(state =>
    venueSelectors.selectVenueName(state, venueId)
  )
  const salesAreaName = useTypedSelector(state =>
    venueSelectors.selectSalesAreaName(
      state,
      parseInt(venueId),
      parseInt(salesAreaId)
    )
  )

  // Actions
  const handleBackClick = () => history.goBack()
  const dispatch = useAppDispatch()

  useEffect(() => {
    dispatch(optinActions.fetchOptins())
  }, [dispatch])

  const handleCheckoutClick = () =>
    history.push(
      `/smart-waiter/venue/${venueId}/area/${salesAreaId}/add-service`
    )

  // Analytics
  useEffect(() => {
    if (!isBasketEmpty && salesAreaName && venueName) {
      trackSmartWaiterBasket({
        salesAreaName,
        venueName,
        basket,
        products
      })
    }
  }, [basket, isBasketEmpty, products, salesAreaName, venueName])

  // Redirect on empty basket
  if (isBasketEmpty)
    return (
      <Redirect
        to={`/smart-waiter/venue/${venueId}/area/${salesAreaId}/service`}
      />
    )

  return (
    <Switch>
      <Route exact path={`${path}`}>
        <Layout
          pageTitle={`Review Basket - Smart Waiter | LW Theatres`}
          renderBrandLogo={<BrandLogoContainer />}
          renderSidebarTop={
            <BasketSummaryContainer hideOnMobile={false} inOwnView={true} />
          }
          renderSidebarBottom={
            <CheckoutButtonContainer
              ctaText='Continue to Checkout'
              onCheckoutClick={handleCheckoutClick}
            />
          }
          renderMainTop={<BackButton onBackClick={handleBackClick} />}
        />
      </Route>
      <Route exact path={`${path}/checkout`}>
        <Layout
          pageTitle={`Confirm and Pay - Smart Waiter | LW Theatres`}
          renderBrandLogo={<BrandLogoContainer />}
          renderSidebarTop={<BasketSummaryContainer hideOnMobile={false} />}
          renderSidebarBottom={<SWFooterNav />}
          renderMainTop={<BackButton onBackClick={handleBackClick} />}
          renderMain={
            <CheckoutContainer venueId={venueId} salesAreaId={salesAreaId} />
          }
        />
      </Route>
    </Switch>
  )
}

export { BasketView }
