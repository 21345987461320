import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { resetHospitality } from 'modules/hospitality/shared'

// Types
export interface CurrentItem {
  adults: number
  children: number
  selectedCalendarMonth?: { year: number; month: number }
  selectedDate?: string
  selectedTimeslot?: string
  selectedMenuId?: EntityId
}

interface HospitalityReservation extends CurrentItem {
  selectedDate: string
  selectedTimeslot: string
  selectedMenuId: EntityId
}

type ReservationsByOccasionId = Record<EntityId, HospitalityReservation[]>

interface ReservationState {
  reservationsByOccasionId: ReservationsByOccasionId
  currentReservation: CurrentItem
}

const initialState: ReservationState = {
  reservationsByOccasionId: {},
  currentReservation: {
    adults: 0,
    children: 0,
    selectedCalendarMonth: {
      year: Math.max(new Date().getFullYear()),
      month: Math.max(new Date().getMonth()) // Set Oct as the first date
    }
  }
}
const reservationSlice = createSlice({
  name: 'reservation',
  initialState: initialState,
  reducers: {
    incrementAdults: state => {
      state.currentReservation.adults++
    },
    decrementAdults: state => {
      state.currentReservation.adults--
    },
    setAdults: (state, action: PayloadAction<number>) => {
      state.currentReservation.adults = action.payload
    },
    incrementChildren: state => {
      state.currentReservation.children++
    },
    decrementChildren: state => {
      state.currentReservation.children--
    },
    setChildren: (state, action: PayloadAction<number>) => {
      state.currentReservation.children = action.payload
    },
    selectCalendarMonth: (
      state,
      action: PayloadAction<{ year: number; month: number }>
    ) => {
      state.currentReservation.selectedCalendarMonth = action.payload
    },
    selectDate: (state, action: PayloadAction<string>) => {
      state.currentReservation.selectedDate = action.payload
    },
    selectTime: (state, action: PayloadAction<string>) => {
      state.currentReservation.selectedTimeslot = action.payload
    },
    selectMenu: (state, action: PayloadAction<EntityId>) => {
      state.currentReservation.selectedMenuId = action.payload
    },
    addItemToBasket: (state, action: PayloadAction<EntityId>) => {
      const occasionId = action.payload
      const {
        adults,
        children,
        selectedDate = '',
        selectedMenuId = '',
        selectedTimeslot = ''
      } = state.currentReservation
      state.reservationsByOccasionId[occasionId] = [
        {
          adults,
          children,
          selectedMenuId,
          selectedDate,
          selectedTimeslot
        }
      ]
    }
  },
  extraReducers: builder => {
    builder.addCase(resetHospitality, state => initialState)
  }
})

// Actions
export const reservationActions = reservationSlice.actions

// Reducer
export default reservationSlice.reducer
