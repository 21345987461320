import React from 'react'
import styled from 'styled-components/macro'
import { Button } from 'marvel-components'
import { media, useIsNarrowViewport, usePlatform } from 'utils'

interface Props {
  text: string
  onZoomIn: (event: React.MouseEvent) => void
  onZoomOut: (event: React.MouseEvent) => void
  isZoomInDisabled: boolean
  isZoomOutDisabled: boolean
  platform?: string
}

const ZoomText = styled.span`
  user-select: none;
  text-transform: uppercase;
  margin-bottom: 0.5rem;
`

const ButtonWrapper = styled(Button)<{ platform?: string }>`
  margin-top: 0.4rem;
  border-radius: 50%;
  border: 1px solid ${props => props.theme.colors.borderColor};
  width: 2.55rem;
  height: 2.5rem;
  line-height: normal;
  justify-content: center;
  align-items: center;

  :first-child {
    margin-top: 0;
  }

  ${media.tablet} {
    width: ${props => (props.platform === 'kiosk' ? '4rem' : '2rem')};
    height: ${props => (props.platform === 'kiosk' ? '4rem' : '2rem')};
  }
`

const ZoomWrapper = styled.div<{
  text: string
  platform?: string
}>`
  position: absolute;
  right: 1.25rem;
  font-family: ${props => props.theme.font.copy};
  color: ${props => props.theme.colors.copyColor};
  font-size: 0.625rem;
  text-align: center;
  background: transparent;
  top: 5rem;

  ${media.tablet} {
    top: ${props => (props.platform === 'kiosk' ? '2rem' : '0.5rem')};
    right: ${props => (props.platform === 'kiosk' ? '2rem' : '1rem')};
    bottom: initial;
  }
`

const ZoomControlWrapper = styled.div`
  color: ${props => props.theme.colors.copyColor};
  background: rgba(255, 255, 255, 0.9);
  border-radius: 4rem;
`

const ZoomButtonWrapper = styled.div<{ platform?: string }>`
  border-radius: 30px;
  border: 1px solid ${props => props.theme.colors.borderColor};
  padding: 0.4rem;
  margin-top: 0.35rem;

  ${media.tablet} {
    padding: 0.25rem;
    border-radius: ${props => (props.platform === 'kiosk' ? '50px' : '30px')};
  }
`

const Zoom: React.FC<Props> = ({
  text,
  onZoomIn,
  isZoomInDisabled,
  onZoomOut,
  isZoomOutDisabled
}) => {
  const platform = usePlatform()
  const isNarrowViewport = useIsNarrowViewport()
  return (
    <ZoomWrapper text={text} platform={platform}>
      <ZoomText>{text}</ZoomText>
      <ZoomControlWrapper>
        <ZoomButtonWrapper platform={platform}>
          <ButtonWrapper
            icon='plus'
            size={platform === 'kiosk' && isNarrowViewport ? 'large' : 'small'}
            isDisabled={isZoomInDisabled}
            onClick={onZoomIn}
            buttonType='rounded'
            platform={platform}
          />
          <ButtonWrapper
            icon='minus'
            size={platform === 'kiosk' && isNarrowViewport ? 'large' : 'small'}
            isDisabled={isZoomOutDisabled}
            onClick={onZoomOut}
            buttonType='rounded'
            platform={platform}
          />
        </ZoomButtonWrapper>
      </ZoomControlWrapper>
    </ZoomWrapper>
  )
}

export { Zoom }
