export const Hospitality = {
  basketNote: {
    title: 'Reservation only',
    note: (
      <>
        <p>Please note, this is just a table reservation</p>
      </>
    )
  },
  colors: {
    mainBackgroundColor: '#FAF5F1',
    mainBackgroundColorComplement: '#111111',
    lwLogoSlashColor: '#ffffff',
    lwBrandColor: '#ffffff',
    lwBrandColorComplement: '#111111',
    sidebarCopyColor: '#111'
  }
}
