import { useAppDispatch } from 'app/reduxStore'
import { openModal } from 'modules/overlay/actions'
import React from 'react'
import styled, { css } from 'styled-components'
import { lighten, darken, media, usePlatform } from 'utils'

export type LinkSize = 'extra-small' | 'small' | 'medium' | 'large' | 'copy'

interface Props {
  target?: '_blank' | '_self' | '_parent' | '_top'
  href: string
  text: string
  size?: LinkSize
  isUnderlined?: boolean
  isSidebar?: boolean
  isUnstyled?: boolean
}

const LinkWrapper = styled.a<{
  size: LinkSize
  isUnderlined: boolean
  isSidebar: boolean
}>(props => {
  const {
    theme: {
      colors: { headingColor, sidebarCopyColor },
      font: { copy }
    },
    size,
    isUnderlined,
    isSidebar
  } = props

  let fontSize = '0.875rem'
  let phoneFontSize = '0.875rem'

  switch (size) {
    case 'extra-small':
      fontSize = '0.625rem'
      break
    case 'small':
      fontSize = '0.75rem'
      break
    case 'medium':
      fontSize = '0.8125rem'
      break
    case 'large':
      fontSize = '1.2rem'
      break
    case 'copy':
      fontSize = '0.875rem'
      break
  }

  switch (size) {
    case 'extra-small':
      phoneFontSize = '0.625rem'
      break
    case 'small':
      phoneFontSize = '0.75rem'
      break
    case 'large':
      phoneFontSize = '0.875rem'
      break
    case 'copy':
      phoneFontSize = '0.875rem'
      break
  }

  return css`
    box-sizing: border-box;
    outline: none;
    flex-direction: row;
    position: relative;
    cursor: pointer;
    user-select: none;
    font-size: ${fontSize};
    font-family: ${copy};
    color: ${isSidebar ? sidebarCopyColor : headingColor};
    fill: ${isSidebar ? sidebarCopyColor : headingColor};
    display: inline-flex;
    text-decoration: ${isUnderlined ? 'underline' : 'none'};

    ${media.phone} {
      font-size: ${phoneFontSize};
    }

    :hover,
    :focus {
      text-decoration: underline;
    }

    :active {
      color: ${isSidebar ? sidebarCopyColor : darken(headingColor, 20)};
      fill: ${isSidebar ? sidebarCopyColor : darken(headingColor, 20)};
      text-decoration: underline;
    }

    &[disabled] {
      box-shadow: none;
      cursor: default;
      pointer-events: none;
      color: ${isSidebar
        ? darken(sidebarCopyColor, 20)
        : lighten(headingColor, 20)};
      fill: ${isSidebar
        ? darken(sidebarCopyColor, 20)
        : lighten(headingColor, 20)};
    }
  `
})

const UnstyledLinkWrapper = styled.a``

export const Link: React.FC<Props> = ({
  target = '_blank',
  href,
  text,
  size = 'small',
  isUnderlined = false,
  isSidebar = false,
  isUnstyled = false,
  ...props
}) => {
  const dispatch = useAppDispatch()
  const platform = usePlatform()

  const handleClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.stopPropagation()
    if (platform !== 'kiosk') return true

    e.preventDefault()
    dispatch(
      openModal({
        variant: 'kiosk-external-page',
        props: {
          href
        }
      })
    )
  }

  if (isUnstyled) {
    return (
      <UnstyledLinkWrapper
        href={href}
        target={target}
        onClick={handleClick}
        {...props}
      >
        {text}
      </UnstyledLinkWrapper>
    )
  }

  return (
    <LinkWrapper
      href={href}
      target={target}
      size={size}
      isUnderlined={isUnderlined}
      isSidebar={isSidebar}
      onClick={handleClick}
      {...props}
    >
      {text}
    </LinkWrapper>
  )
}
