import React, { useEffect } from 'react'

// Components
import { Layout } from 'marvel-components'

// Containers
import {
  BasketContainer,
  BrandLogoContainer,
  PrePurchaseProductsContainer
} from 'containers/Ticketing'

// Selectors
import {
  selectSeatAndTicketItemCount,
  selectReservationGuid,
  selectBookingFlow,
  getContextId
} from 'modules/basket/selectors'
import { eventDetailsSelectors } from 'modules/ticketing/event'
import { selectArePromptsAvailable } from 'modules/prompt/selectors'
import {
  selectActivePerformanceCapacity,
  selectActivePerformanceDate
} from 'modules/ticketing/performance/selectors'

// Actions
import { fetchPrompts } from 'modules/prompt/actions'

// Misc
import { useSelector, useDispatch } from 'react-redux'
import { Redirect, useHistory, useParams } from 'react-router'
import {
  checkoutRoute,
  selectTicketsRoute,
  trackPrePurchaseProductView,
  usePlatform
} from 'utils'
import moment from 'moment'

const PrePurchaseProductsView: React.FC = () => {
  const dispatch = useDispatch()
  const { eventId, performanceId } = useParams<{
    eventId: string
    performanceId: string
  }>()
  const history = useHistory()
  const platform = usePlatform()

  // Selectors
  const reservationGuid = useSelector(selectReservationGuid)
  const eventName = useSelector(eventDetailsSelectors.selectEventName)
  const venueName = useSelector(eventDetailsSelectors.selectVenueName)
  const imageUrl = useSelector(eventDetailsSelectors.selectEventImageUrl)
  const availability = useSelector(selectActivePerformanceCapacity)
  const performanceDate = useSelector(selectActivePerformanceDate)
  const arePromptsAvailable = useSelector(selectArePromptsAvailable)
  const itemCount = useSelector(selectSeatAndTicketItemCount)
  const bookingFlow = useSelector(selectBookingFlow)
  const contextId = useSelector(getContextId)
  // Analytics
  // --------------------------------------
  useEffect(() => {
    if (eventName && venueName && performanceDate && arePromptsAvailable) {
      const date = moment.utc(performanceDate)
      trackPrePurchaseProductView({
        platform,
        eventName,
        performanceId,
        eventId,
        venueName,
        imageUrl,
        performanceDate: `${date.format('YYYY-MM-DD')}`,
        performanceTime: `${date.format('H-mm')}`,
        bookingFlow,
        contextId,
        availability
      })
    }
  }, [
    eventName,
    eventId,
    performanceId,
    venueName,
    imageUrl,
    performanceDate,
    arePromptsAvailable,
    platform,
    contextId,
    bookingFlow,
    availability
  ])

  // Fetch Prompts
  // -----------------------------------------------------
  useEffect(() => {
    reservationGuid &&
      dispatch(
        fetchPrompts({
          transactionGuid: reservationGuid
        })
      )
  }, [reservationGuid, dispatch])

  // Redirect to checkout
  // --------------------------------------
  if (!arePromptsAvailable && reservationGuid)
    return <Redirect to={checkoutRoute(eventId, reservationGuid)} />

  if (itemCount <= 0) {
    return <Redirect to={selectTicketsRoute(eventId, performanceId)} />
  }

  const handleReservationSuccess = () => {
    reservationGuid && history.push(checkoutRoute(eventId, reservationGuid))
  }
  return (
    <Layout
      pageTitle={
        eventName && `Add extras to your booking - ${eventName} | LW Theatres`
      }
      renderBrandLogo={<BrandLogoContainer />}
      renderMain={<PrePurchaseProductsContainer />}
      renderSidebarBottom={
        <BasketContainer
          ctaText='Continue'
          ctaIcon='lock'
          onReservationSuccess={handleReservationSuccess}
        />
      }
    />
  )
}

export { PrePurchaseProductsView }
