import { AppState } from 'modules/index'
import { createSelector } from '@reduxjs/toolkit'
import { terminalAdapter } from 'modules/self-serve/terminal/terminalSlice'
import {
  selectKiosks,
  selectSelectedKiosk
} from 'modules/self-serve/kiosk/selectors'

export const {
  selectEntities: selectTerminalEntities,
  selectAll: selectTerminals,
  selectById: selectTerminalById,
  selectTotal: selectTotalTerminals,
  selectIds: selectTerminalIds
} = terminalAdapter.getSelectors<AppState>(state => state.selfServe.terminal)

const selectState = (state: AppState) => state.selfServe.terminal

export const selectTerminalStatus = createSelector(
  selectState,
  state => state.status
)

export const selectAvailableTerminals = createSelector(
  selectKiosks,
  selectTerminals,
  selectSelectedKiosk,
  (kiosks, terminals, selectedKiosk) => {
    const availableTerminals = []
    if (selectedKiosk && selectedKiosk.terminalId) {
      const matchingTerminal = terminals.find(
        terminal => selectedKiosk.terminalId === terminal.id
      )
      if (matchingTerminal) availableTerminals.push(matchingTerminal)
    }

    const pairedTerminalIds = kiosks
      .filter(kiosk => kiosk.terminalId !== null)
      .map(kiosk => kiosk.terminalId)

    terminals.forEach(terminal => {
      if (!pairedTerminalIds.find(id => terminal.id === id)) {
        availableTerminals.push(terminal)
      }
    })

    return availableTerminals
  }
)

export const selectIsTerminalLoading = (state: AppState) =>
  state.selfServe.terminal.status === 'pending'
