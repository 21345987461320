// Types
import { AppState } from 'modules/types'

// Adapters
import { menusAdapter } from './menuSlice'

// Selectors
export const {
  selectById,
  selectIds,
  selectEntities,
  selectAll,
  selectTotal
} = menusAdapter.getSelectors<AppState>((state) => state.hospitality.menus)

export const selectIsLoading = (state: AppState) =>
  state.hospitality.menus.loading === 'pending'
