import React, { useEffect } from 'react'

// Components
import { Icon, Layout } from 'marvel-components'

// Containers
import {
  BasketContainer,
  BrandLogoContainer,
  FooterNavContainer,
  PerformanceSelectContainer,
  SeatMapControlContainer,
  SelectTicketContainer
} from 'containers/Ticketing'

// Actions
import { fetchSeatMap } from 'modules/seat-map/actions'
import { availabilityActions } from 'modules/ticketing/availability/availabilitySlice'
import { performanceActions } from 'modules/ticketing/performance/performanceSlice'
import { fetchDiscountedPerformance } from 'modules/discount/actions'
import { setPromoCode } from 'modules/discount/actions'

// Selectors
import { eventDetailsSelectors } from 'modules/ticketing/event'
import { getSeatingType } from 'modules/seat-map/selectors'
import { getDiscountCode } from 'modules/discount/selectors'
import {
  selectActivePerformanceDate,
  selectActivePerformanceCapacity,
  selectPerformanceNotes
} from 'modules/ticketing/performance/selectors'
import { authSelectors } from 'modules/ticketing/auth/authSlice'
import {
  getExternalCustomerId,
  selectBookingFlow,
  getContextId
} from 'modules/basket/selectors'

// Utils
import {
  media,
  preorderProductsRoute,
  trackPerformanceDetailView,
  usePlatform,
  useIsNarrowViewport
} from 'utils'

// Misc
import styled from 'styled-components/macro'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useParams, useLocation } from 'react-router'
import { useTypedSelector } from 'modules'
import moment from 'moment'

const PerformanceNotes = styled.div`
  display: none;
  flex-direction: row;
  fill: ${props => props.theme.colors.copyColor};
  background: #d9edf7;
  padding: 0.5rem;
  align-items: flex-start;
  justify-content: center;

  ${media.tablet} {
    display: flex;
  }
`

const PerformanceNotesCopy = styled.span`
  font-family: ${props => props.theme.font.copy};
  color: ${props => props.theme.colors.copyColor};
  font-size: 0.625rem;
`

const IconWrapper = styled.div`
  > div {
    display: flex;
    margin-right: 0.5rem;
  }
`

const SelectTicketsWrapper = styled.div<{ platform?: string }>`
  ${media.tablet} {
    min-height: ${props => (props.platform === 'kiosk' ? '65vh' : '50vh')};
  }
`

const SelectTicketsView: React.FC = () => {
  const dispatch = useDispatch()
  const { eventId, performanceId } = useParams<{
    eventId: string
    performanceId: string
  }>()
  const history = useHistory()
  const platform = usePlatform()

  // Routing
  const location = useLocation()
  const urlParams = new URLSearchParams(location.search)
  const promoCode = urlParams.get('promo')

  // Selectors
  const eventName = useSelector(eventDetailsSelectors.selectEventName)
  const imageUrl = useSelector(eventDetailsSelectors.selectEventImageUrl)
  const venueName = useSelector(eventDetailsSelectors.selectVenueName)
  const capacityAvailable = useSelector(selectActivePerformanceCapacity)
  const performanceDate = useSelector(selectActivePerformanceDate)
  const seatingType = useSelector(getSeatingType)

  const discountCode = useSelector(getDiscountCode)

  const performanceNotes = useTypedSelector(state =>
    selectPerformanceNotes(state, performanceId)
  )
  const user = useSelector(authSelectors.selectUser)
  const externalId = useSelector(getExternalCustomerId)
  const bookingFlow = useSelector(selectBookingFlow)
  const contextId = useSelector(getContextId)

  // Analytics
  // --------------------------------------
  useEffect(() => {
    if (eventName && performanceDate && venueName) {
      const date = moment.utc(performanceDate)
      const performanceDateString = date.format('YYYY-MM-DD')
      const performanceTimeString = date.format('H-mm')
      trackPerformanceDetailView({
        platform,
        eventName,
        venueName,
        eventId,
        performanceId,
        performanceDate: performanceDateString,
        performanceTime: performanceTimeString,
        availability: capacityAvailable,
        externalId: externalId,
        bookingFlow,
        imageUrl,
        contextId
      })
    }
  }, [
    eventName,
    performanceDate,
    venueName,
    imageUrl,
    eventId,
    capacityAvailable,
    performanceId,
    externalId,
    platform,
    bookingFlow,
    contextId
  ])

  // Set current performance
  useEffect(() => {
    dispatch(performanceActions.setCurrentPerformance(performanceId))
  }, [performanceId, dispatch])

  // Reset Promo Code to URL to account for lottery and day seats views
  // ------------------------------------------
  useEffect(() => {
    eventId &&
      dispatch(
        setPromoCode({
          code: promoCode
        })
      )
  }, [eventId, promoCode, dispatch])

  // Fetch Seat Map/Performance/Seat Availability
  // ------------------------------------------
  useEffect(() => {
    dispatch(fetchSeatMap({ performanceId }))
  }, [performanceId, dispatch])

  useEffect(() => {
    dispatch(
      availabilityActions.fetchAvailability({
        performanceId,
        promoCode
      })
    )
  }, [performanceId, promoCode, user?.id, dispatch])

  useEffect(() => {
    dispatch(
      performanceActions.fetchPerformance({
        performanceId,
        promoCode,
        noCache: !!user?.id
      })
    )
  }, [performanceId, promoCode, user?.id, dispatch])

  useEffect(() => {
    discountCode &&
      dispatch(
        fetchDiscountedPerformance({
          performanceId,
          discountCode
        })
      )
  }, [performanceId, discountCode, dispatch])

  const isNarrowViewport = useIsNarrowViewport()

  const handleReservationSuccess = () => {
    history.push(preorderProductsRoute(eventId, performanceId))
  }
  return (
    <Layout
      pageTitle={`Select your tickets - ${eventName} | LW Theatres`}
      renderBrandLogo={<BrandLogoContainer showLogin />}
      renderSidebarTop={
        <>
          <PerformanceSelectContainer
            performanceId={performanceId}
            eventId={eventId}
          />
          {performanceNotes && (
            <PerformanceNotes>
              <IconWrapper>
                <Icon padding='none' icon='info-circle' size='xs' />
              </IconWrapper>
              <PerformanceNotesCopy>{performanceNotes}</PerformanceNotesCopy>
            </PerformanceNotes>
          )}
        </>
      }
      renderMain={
        <>
          {platform === 'kiosk' && isNarrowViewport ? (
            <SelectTicketsWrapper>
              <SelectTicketContainer
                performanceId={performanceId}
                seatingType={seatingType ?? seatingType}
              />
              {seatingType === 'ReservedSeating' && <SeatMapControlContainer />}
            </SelectTicketsWrapper>
          ) : isNarrowViewport ? (
            <SelectTicketsWrapper>
              <SelectTicketContainer
                performanceId={performanceId}
                seatingType={seatingType ?? seatingType}
              />
            </SelectTicketsWrapper>
          ) : (
            <SelectTicketContainer
              performanceId={performanceId}
              seatingType={seatingType ?? seatingType}
            />
          )}
        </>
      }
      renderSidebarBottom={
        <>
          {platform === 'kiosk' && isNarrowViewport ? (
            <BasketContainer onReservationSuccess={handleReservationSuccess} />
          ) : (
            <>
              {seatingType === 'ReservedSeating' && <SeatMapControlContainer />}
              <BasketContainer
                onReservationSuccess={handleReservationSuccess}
              />
            </>
          )}
        </>
      }
      renderFooter={<FooterNavContainer />}
    />
  )
}
export { SelectTicketsView }
