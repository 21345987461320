import React, { memo, useContext, ReactNode } from 'react'
import styled, { ThemeContext } from 'styled-components/macro'
import { Area } from 'shared-types'
import {
  scale,
  toSVG,
  rotateDEG,
  translate,
  transform
} from 'transformation-matrix'
interface Props {
  area: Area
  children?: ReactNode
  relativeSale?: number
}

const Text = styled.text`
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: antialiased;
  fill: #333;
  font-family: ${props => props.theme.font.header};
  text-transform: uppercase;
  font-weight: 200; /* Firefox fix */
`

export const SeatMapArea: React.FC<Props> = memo(
  ({
    area: { id, height, width, x, y, name, title, hidden },
    children,
    relativeSale = 1
  }) => {
    const themeContext = useContext(ThemeContext)
    const {
      font: { copy = 'Arial' }
    } = themeContext
    if (hidden) {
      return null
    }

    let containerX = (2000 - width) / 2
    let containerY = y
    let seatsX = x
    let titleX = title?.x ?? 0
    let titleY = title?.y ?? 0

    // switch (id) {
    //   case 'a8b9f311-8ced-4c1b-b1d1-a8d58cc09ae5':
    //     containerY = 0
    //     seatsX = 493
    //     titleX = 0
    //     titleY = 110

    //     break
    //   case 'c5df7724-f43b-45d8-8274-3974fd9dfa82':
    //     containerY = 250
    //     seatsX = 445
    //     titleX = 0
    //     titleY = 300
    //     // relativeSale = 1
    //     break
    //   default:
    //     return null
    // }

    const containerCX = containerX + width / 2
    const containerCY = containerY + height / 2

    //Scale everything to the same seat size
    const scaleMatrix = scale(
      relativeSale,
      relativeSale,
      containerCX,
      containerCY
    )
    const positionMatrix = transform(
      translate(containerX, containerY),
      rotateDEG(180, width / 2, height / 2)
    )
    return (
      <g transform={`${toSVG(scaleMatrix)}`}>
        {title && (
          <g>
            <Text
              fontFamily={copy}
              x={containerCX + titleX}
              y={containerY + titleY}
              textAnchor='middle'
              alignmentBaseline='central'
              fontSize={title.fontSize / relativeSale}
              fontWeight='bold'
              textRendering='optimizeLegibility'
              letterSpacing='1.5'
            >
              {name}
            </Text>
          </g>
        )}
        <g
          data-description={`area-${id}`}
          transform={`${toSVG(positionMatrix)}`}
        >
          {/* To Center an item you need to take the width of the parent minus the width of the child and divide by two. 
          For the areas we need to adjust so that the area is aligned with the left edge of the parent. 
          x = offset - half inner width at 100%
          1) Set the seats so the right edge of seats are align with the right edge of the square - this is becuase of the 180 rotation
          2) Find out half the width of the seats component (need to check in browser and make sure relative scale = 1) of the seating section = at scale 0.5 this is the full width 
          3) The seatX value is the sum of 1) and abs(2) - so ignore minus sign}
          {/* USED TO FIND THE OFFSET NEED TO SET ZOOM SCALE TO 0.5 AND WIDTH/HEIGHT TO 2000*/}
          {/* <rect
            x='0'
            y='0'
            width={width}
            height={height}
            stroke='black'
            fill={'yellow'}
            opacity={'0.5'}
          /> */}
          {/* <g transform={`translate(-20,0)`}>{children}</g> */}
          <g transform={`translate(${width / 2 - seatsX}, 0)`}>{children}</g>
        </g>
      </g>
    )
  }
)
