// Views
import { LogoutView, MainMenuView, ScreenSaverView } from 'views/Kiosk'
import { VenueView } from './VenueView'

// Misc
import React from 'react'
import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom'
import { kioskAuthentication, usePlatform } from 'utils'

const RestrictedView = () => {
  const { path } = useRouteMatch()
  const platform = usePlatform()

  if (platform !== 'kiosk') {
    return <Redirect to={kioskAuthentication()} />
  }

  return (
    <Switch>
      <Route exact path={`${path}/logout`}>
        <LogoutView />
      </Route>
      <Route exact path={`${path}/menu`}>
        <MainMenuView withLoader={true} />
      </Route>
      <Route exact path={`${path}/screen-saver`}>
        <ScreenSaverView />
      </Route>
      <Route path={`${path}/venue`}>
        <VenueView />
      </Route>
    </Switch>
  )
}

export { RestrictedView }
