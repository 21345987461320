import { Kiosk } from 'shared-types'
import selfServeApi from 'modules/self-serve/services/selfServeApi'
import { schema } from 'normalizr'
import { createAPIThunk } from 'modules/self-serve/shared/actions'
import { getKioskAccessToken } from '../shared/utils'

const kioskSchema = new schema.Entity('kiosks')

type FetchKiosksResponse = {
  kiosks: Record<number, Kiosk>
}

export const fetchKiosks = createAPIThunk<FetchKiosksResponse>(
  'marvel/self-serve/FETCH_KIOSKS',
  () => selfServeApi.get('/self-serve/kiosk/'),
  [kioskSchema]
)

type AuthenticateKioskResponse = {
  accessToken: string
}

type AuthenticateKioskRequest = {
  kioskId: number
  secret: string
  terminalId: number
}

export const authenticateKiosk = createAPIThunk<
  AuthenticateKioskResponse,
  AuthenticateKioskRequest
>('marvel/self-serve/AUTHENTICATE_KIOSK', (args: AuthenticateKioskRequest) =>
  selfServeApi.post(`/self-serve/kiosk/${args.kioskId}/authenticate/`, {
    ...args,
    kioskId: undefined
  })
)

type FetchPaymentStatusResponse = {
  status: 'inProgress' | 'success' | 'failure' | 'idle'
}

export const fetchPaymentStatus = createAPIThunk<
  FetchPaymentStatusResponse,
  undefined
>('marvel/self-serve/FETCH_PAYMENT_STATUS', () =>
  selfServeApi.get(
    `/self-serve/payment/status`,
    undefined,
    getKioskAccessToken()
  )
)
