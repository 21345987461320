import moment from 'moment'
import { schema } from 'normalizr'
import { Performance, PriceLevel, SeatType } from 'shared-types'
import ticketingApi from '../services/ticketingApi'
import { createAPIThunk } from '../shared/actions'

const venueSchema = new schema.Entity('venue')
const seatTypeSchema = new schema.Entity('seatTypes')
const priceLevelSchema = new schema.Entity('priceLevels')
const performanceSchema = new schema.Entity('performances', {
  venue: venueSchema,
  seatTypes: [seatTypeSchema],
  pricing: [priceLevelSchema]
})

type FetchPerformancesListRequest = {
  eventId: string
  discountCode?: string | null
  promoCode?: string | null
  noCache?: boolean
}

type FetchPerformancesListResponse = {
  performances: Record<string, Performance>
  priceLevels: Record<string, PriceLevel>
  seatTypes: Record<string, SeatType>
}

export const fetchPerformances = createAPIThunk<
  FetchPerformancesListResponse,
  FetchPerformancesListRequest
>(
  'marvel/ticketing/FETCH_PERFORMANCES',
  (data, contextId, authToken) =>
    ticketingApi.fetchAll(
      `/event/${data.eventId}/performances/`,
      {
        includeAvailability: true,
        ...(data.discountCode && { discountCode: data.discountCode }),
        ...(data.promoCode && { promoCode: data.promoCode }),
        ...(data.noCache && { noCache: data.noCache }) // Don't use cached response if the customer is logged in or the promo codes won't work
      },
      contextId,
      authToken
    ),
  [performanceSchema],
  (_, state) => {
    const halfAnHourAgo = moment().subtract(30, 'minutes').toDate().getTime()
    const performancesLastFetch =
      state.ticketing.performance.lastFetchPerformances
    return (
      !performancesLastFetch ||
      moment(performancesLastFetch).toDate().getTime() < halfAnHourAgo
    )
  }
)

type FetchPerformanceRequest = {
  performanceId: string
  promoCode?: string | null
  noCache?: boolean
}

type FetchPerformanceResponse = {
  performances?: Record<string, Performance>
  priceLevels?: Record<string, PriceLevel>
  seatTypes?: Record<string, SeatType>
}

export const fetchPerformance = createAPIThunk<
  FetchPerformanceResponse,
  FetchPerformanceRequest
>(
  'marvel/ticketing/FETCH_PERFORMANCE',
  (data, contextId, authToken) =>
    ticketingApi.fetchAll(
      `/performance/${data.performanceId}/`,
      {
        ...(data.promoCode && { promoCode: data.promoCode }),
        ...(data.noCache && { noCache: data.noCache })
      },
      contextId,
      authToken
    ),
  performanceSchema
)

type FetchPerformancesForExchangeRequest = {
  orderId: string
}

type FetchPerformancesForExchangeResponse = {
  performances?: Record<string, Performance>
  priceLevels?: Record<string, PriceLevel>
  seatTypes?: Record<string, SeatType>
}

export const fetchPerformancesForExchange = createAPIThunk<
  FetchPerformancesForExchangeResponse,
  FetchPerformancesForExchangeRequest
>(
  'marvel/ticketing/FETCH_PERFORMANCES_FOR_EXCHANGE',
  (data, contextId, authToken) =>
    ticketingApi.fetchAll(
      `/exchange/${data.orderId}/performances/`,
      null,
      contextId,
      authToken
    ),
  [performanceSchema]
)
