import React, { ReactNode } from 'react'
import styled, { css } from 'styled-components/macro'
import { Icon } from 'marvel-components'
import { IconName, PaddingOptions } from 'shared-types'
import { darken, lighten } from 'utils'
import { IconPrefix } from '@fortawesome/fontawesome-svg-core'

export type ButtonVariation =
  | 'primary'
  | 'secondary'
  | 'danger'
  | 'light'
  | 'sidebar'
  | 'rounded'
  | 'link-button'

export type ButtonSize = 'extra-small' | 'small' | 'large' | 'xlarge'

interface Props {
  icon?: IconName
  size?: ButtonSize
  buttonType?: ButtonVariation
  text?: string
  isBlock?: boolean
  isDisabled?: boolean
  isLoading?: boolean
  isUnderlined?: boolean
  onClick?: (e: React.MouseEvent) => void
  onMouseUp?: (e: React.MouseEvent) => void
  iconPrefix?: IconPrefix
  iconPadding?: PaddingOptions
  isSidebar?: boolean
  children?: ReactNode
  type?: 'submit' | 'reset' | 'button'
}

const ButtonWrapper = styled.button.attrs<{
  buttonType: ButtonVariation
  type: string
}>(props => ({
  type: props.type
}))<{
  rel?: string
  buttonType?: ButtonVariation
  isBlock?: boolean
  size?: ButtonSize
  isLoading?: boolean
  icon?: IconName
  isUnderlined?: boolean
  isSidebar?: boolean
}>(props => {
  const {
    theme: {
      colors: {
        mainColor,
        mainColorComplement,
        secondaryColor,
        secondaryColorComplement,
        alertColor,
        alertColorComplement,
        headingColor,
        borderColor,
        sidebarCopyColor,
        lwButtonColor,
        lwButtonTextColor
      },
      font: { button, copy }
    },
    buttonType,
    isBlock,
    size,
    isLoading,
    icon,
    isUnderlined,
    isSidebar
  } = props

  // Theme
  let backgroundColor = lwButtonColor
  let foregroundColor = lwButtonTextColor
  let border = 'none'
  let font = button
  let borderRadius = '0.125rem'

  switch (buttonType) {
    case 'secondary':
      backgroundColor = secondaryColor
      foregroundColor = secondaryColorComplement
      break
    case 'danger':
      backgroundColor = alertColor
      foregroundColor = alertColorComplement
      break
    case 'light':
      backgroundColor = 'transparent'
      foregroundColor = mainColor
      border = `0.0625rem solid ${mainColor}`
      font = copy
      break
    case 'sidebar':
      backgroundColor = mainColor
      foregroundColor = mainColorComplement
      break
    case 'rounded':
      backgroundColor = '#fff'
      foregroundColor = '#323232'
      border = `0.0625rem solid ${borderColor}`
      break
  }

  // Default
  let padding = '0.75rem 1.5rem'
  let fontSize = '0.875rem'

  switch (size) {
    case 'extra-small':
      fontSize = '0.625rem'
      padding = '0rem 0.5rem'
      break
    case 'small':
      padding = '0.375rem 0.75rem'
      borderRadius = '0.125rem'
      fontSize = '0.75rem'
      break
    case 'large':
      padding = '0.5rem'
      borderRadius = '0.25rem'
      fontSize = '1.5rem'
      break
    case 'xlarge':
      padding = '1rem 1.5rem'
      borderRadius = '0.25rem'
      fontSize = '1.3rem'
      break
  }

  return css`
    background: ${backgroundColor};
    box-sizing: border-box;
    border: ${border};
    border-radius: ${borderRadius};
    outline: none;
    color: ${!icon && isLoading ? 'transparent' : foregroundColor};
    fill: ${foregroundColor};
    font-family: ${font};
    line-height: 1.5rem;
    padding: ${padding};
    display: flex;
    flex-direction: row;
    justify-content: center;
    box-shadow: 0 0 0 0.1px rgba(50, 50, 93, 0.01),
      0 0.125rem 5px rgba(50, 50, 93, 0.1),
      0 1px 0.125rem rgba(50, 50, 93, 0.05);
    width: ${isBlock ? '100%' : 'initial'};
    transition: width 0.4s, background 0.2s;
    position: relative;
    cursor: pointer;
    user-select: none;

    :hover,
    :focus {
      background: ${darken(backgroundColor, 10)};
    }

    :active {
      background: ${darken(backgroundColor, 20)};
    }

    &[disabled] {
      box-shadow: none;
      background: #ccc;
      cursor: default;
      pointer-events: none;
      fill: ${isSidebar ? lighten(mainColor, 40) : lighten(headingColor, 40)};
    }

    ${
      buttonType !== 'link-button' &&
      css`
        text-transform: uppercase;
        font-size: ${fontSize};
      `
    }

    ${
      buttonType === 'link-button' &&
      css`
        font-size: ${fontSize};
        font-family: ${copy};
        background: none;
        color: ${isSidebar ? sidebarCopyColor : headingColor};
        fill: ${isSidebar ? sidebarCopyColor : headingColor};
        padding: 0;
        box-shadow: none;
        justify-content: unset;
        text-decoration: none;
        display: inline-flex;
        text-decoration: ${isUnderlined ? 'underline' : 'none'};
        line-height: 1.5;

        :hover,
        :focus {
          background: none;
          text-decoration: underline;
        }

        :active {
          background: none;
          color: ${isSidebar ? mainColor : darken(headingColor, 20)};
          fill: ${isSidebar ? mainColor : darken(headingColor, 20)};
          text-decoration: underline;
        }

        &[disabled] {
          box-shadow: none;
          background: none;
          cursor: default;
          pointer-events: none;
          color: ${isSidebar
            ? darken(mainColor, 20)
            : lighten(headingColor, 20)};
          fill: ${isSidebar
            ? darken(mainColor, 20)
            : lighten(headingColor, 20)};
        }
      `
    }

      ${
        buttonType === 'rounded' &&
        css`
          border-radius: 4rem;
          text-transform: none;
          font-family: ${copy};
        `
      }
  `
})

export const Button = (props: Props) => {
  const {
    icon,
    text,
    isLoading,
    isDisabled,
    iconPrefix,
    iconPadding = 'default',
    isSidebar,
    children,
    type = 'button'
  } = props

  return (
    <ButtonWrapper
      aria-label={text || icon}
      {...props}
      rel='noopener noreferrer'
      isSidebar={isSidebar}
      onMouseUp={() => {
        if (document && document.activeElement && document.activeElement) {
          ;(document.activeElement as HTMLElement).blur()
        }
      }}
      disabled={!!(isDisabled || isLoading)}
      type={type}
    >
      {!isLoading && icon && (
        <Icon
          icon={icon}
          padding={text ? iconPadding : 'none'}
          prefix={iconPrefix}
        />
      )}
      {isLoading && icon && (
        <Icon
          icon={'circle-notch'}
          isSpinning
          padding={iconPadding}
          prefix={iconPrefix}
        />
      )}
      {children}
      {text}
      {!icon && isLoading && (
        <Icon
          icon={'circle-notch'}
          isSpinning
          padding={'none'}
          isCentre
          prefix={iconPrefix}
        />
      )}
    </ButtonWrapper>
  )
}
