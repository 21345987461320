import React, { useEffect } from 'react'

// Components
import { Layout } from 'marvel-components'

// Containers
import {
  BrandLogoContainer,
  FooterNavContainer,
  PerformanceCalendarContainer,
  PerformanceFilterContainer,
  PerformanceListContainer
} from 'containers/Ticketing'

// Actions
import { clearReservation } from 'modules/basket/actions'

// Selectors
import { eventDetailsSelectors } from 'modules/ticketing/event'
import { selectReservationGuid } from 'modules/basket/selectors'

// Misc
import { useSelector, useDispatch } from 'react-redux'
import { selectTicketsRoute, calendarRoute, performanceListRoute } from 'utils'
import { Route, Switch, useHistory, useRouteMatch } from 'react-router-dom'

// Types
import { SelectPerformanceViewTypes } from 'shared-types'

type Props = {
  eventId: string
}

const SelectPerformanceView: React.FC<Props> = ({
  // Performances
  eventId
}) => {
  const dispatch = useDispatch()

  // Routing
  const history = useHistory()
  const { path } = useRouteMatch()

  // Selectors
  const eventName = useSelector(eventDetailsSelectors.selectEventName)
  const reservationGuid = useSelector(selectReservationGuid)

  // Clear Current Reservation
  // -----------------------------------------
  useEffect(() => {
    dispatch(clearReservation(reservationGuid))
  }, [dispatch, reservationGuid])

  // Change View Click
  // ------------------------------------------
  const handleViewChange = (view: SelectPerformanceViewTypes) => {
    if (view === 'calendar') {
      history.push(calendarRoute(eventId))
    } else {
      history.push(performanceListRoute(eventId))
    }
  }

  // Performance Click
  // ------------------------------------------
  const handlePerformanceClick = (performanceId: string) => {
    history.push(selectTicketsRoute(eventId, performanceId))
  }

  return (
    <Layout
      pageTitle={`Choose your performance - ${eventName} | LW Theatres`}
      renderBrandLogo={<BrandLogoContainer showLogin />}
      renderMain={
        <Switch>
          <Route exact path={path}>
            <PerformanceCalendarContainer
              eventId={eventId}
              onPerformanceClick={handlePerformanceClick}
              onViewChange={handleViewChange}
            />
          </Route>
          <Route exact path={`${path}/performance-list`}>
            <PerformanceListContainer
              eventId={eventId}
              onPerformanceClick={handlePerformanceClick}
              onViewChange={handleViewChange}
            />
          </Route>
        </Switch>
      }
      renderSidebarBottom={<PerformanceFilterContainer />}
      renderFooter={<FooterNavContainer />}
    />
  )
}

export { SelectPerformanceView }
