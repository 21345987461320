import { createSlice, createEntityAdapter } from '@reduxjs/toolkit'

// Utils
import hospitalityApi from 'services/hospitalityApi'
import { createAPIThunk } from 'modules/smart-waiter/shared/actions'
import { slotsEntity } from 'services/hospitalitySchemas'

// Types
import { HospitalitySlot } from 'shared-types'

type SlotsResponse = {
  slots: { [key: string]: HospitalitySlot }
}

type SlotsRequest = {
  siteId: string
  occasionId: string
  adults: number
  dateFrom: string
  dateTo?: string
  children?: number
}

// Actions
export const fetchAll = createAPIThunk<SlotsResponse, SlotsRequest>(
  'marvel/hospitality/FETCH_SLOTS',
  (data: SlotsRequest) =>
    hospitalityApi.fetchAll(
      `site/${data.siteId}/occasion/${data.occasionId}/slots/`,
      {
        adults: data.adults,
        dateFrom: data.dateFrom,
        dateTo: data.dateTo,
        ...(data.children && { children: data.children })
      }
    ),
  slotsEntity
)

// Reducer
export const slotsAdapter = createEntityAdapter<HospitalitySlot>({
  selectId: (slot) => slot.date
})

const slotsSlice = createSlice({
  name: 'slots',
  initialState: slotsAdapter.getInitialState({
    loading: 'idle'
  }),
  reducers: {
    reset: (state) => {
      slotsAdapter.removeAll(state)
    }
  },
  extraReducers: (builder) => {
    builder.addCase(fetchAll.pending, (state, action) => {
      state.loading = 'pending'
    })
    builder.addCase(fetchAll.fulfilled, (state, action) => {
      if (action.payload.slots) {
        slotsAdapter.upsertMany(state, action.payload.slots)
      }
      state.loading = 'idle'
    })
  }
})

export const { reset } = slotsSlice.actions

export default slotsSlice.reducer
