import React, { useMemo } from 'react'

import styled from 'styled-components'
import { media, parseZonalMenu } from 'utils'

// Containers
import { BreadcrumbContainer } from '../../BreadcrumbContainer'

// Components
import { Title, ListItem, List } from 'marvel-components'

// Selectors
import { venueSelectors } from 'modules/smart-waiter/venue'

// Misc
import { useSelector } from 'react-redux'
import { useHistory, useRouteMatch, Redirect } from 'react-router-dom'
import { smartWaiterRoutes } from '../SmartWaiterRoutes'

// Types
import { AppState } from 'modules/types'

const ServiceContainerWrapper = styled.div`
  box-sizing: border-box;
  padding: 1.5rem 0 2.5rem 0;
  ${media.tablet} {
    padding: 1rem 0 1.5rem 0;
    margin-top: 0;
  }
`

const ServiceListDetails = styled.div`
  padding: 0 2%;
  ${media.tablet} {
    padding: 0 3%;
  }
  ${media.phone} {
    padding: 0 5%;
  }
`

const ServiceListWrapper = styled.div`
  margin: 1rem 0;
`

const AddMenuContainer: React.FC = () => {
  // Routing
  const history = useHistory()
  const {
    params: { venueId, salesAreaId }
  } = useRouteMatch<{ venueId: string; salesAreaId: string }>()

  // Selectors
  const selectMissingMenusByVenueId = useMemo(
    () => venueSelectors.makeSelectMissingMenuWithVenueId(),
    []
  )
  const menus = useSelector((state: AppState) =>
    selectMissingMenusByVenueId(state, venueId)
  )

  // Actions
  const handleMenuClick = (menuId: EntityId) => {
    history.push(
      `/smart-waiter/venue/${venueId}/area/${salesAreaId}/service/1/menu/${menuId}/section`
    )
  }

  if (menus.length < 1) {
    return (
      <Redirect
        to={`/smart-waiter/venue/${venueId}/area/${salesAreaId}/basket/checkout`}
      />
    )
  }
  return (
    <ServiceContainerWrapper>
      <ServiceListDetails>
        <BreadcrumbContainer routes={smartWaiterRoutes} />
        <Title
          isCapitalised={false}
          isCentralised={false}
          isBold={true}
          size='medium'
        >
          {`Would you like to add anything from the ${
            parseZonalMenu(menus[0].name).title
          } to your order?`}
        </Title>
      </ServiceListDetails>
      <ServiceListWrapper>
        <List>
          {menus.map(menu => {
            const menuType = parseZonalMenu(menu.name)
            return (
              <ListItem
                key={menu.id}
                title={menuType.title}
                id={menu.id}
                stacked
                onClick={handleMenuClick}
                largeIcon
                imageUrl={menuType.icon}
                icon={true}
              />
            )
          })}
        </List>
      </ServiceListWrapper>
    </ServiceContainerWrapper>
  )
}

export { AddMenuContainer }
