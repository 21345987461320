import 'react-app-polyfill/ie11'
import 'react-app-polyfill/stable'

import React from 'react'
import ReactDOM from 'react-dom'

// Store
import { Provider } from 'react-redux'
import { reduxStore } from 'app/reduxStore'

// Components
import App from 'app/App'

// Tracking utils
import ReactGA from 'react-ga'
import ReactGA4 from 'react-ga4'
import TagManager from 'react-gtm-module'
import * as Sentry from '@sentry/react'
import DotMailer from 'utils/analytics/dotmailer'

// SVG Polyfill
if (!SVGElement.prototype.contains) {
  SVGElement.prototype.contains = HTMLDivElement.prototype.contains
}

// initAdyen()
// Sentry Tracking
if (process.env.NODE_ENV === 'production' && process.env.REACT_APP_SENTRY_DNS) {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DNS,
    environment: `${process.env.REACT_APP_SENTRY_ENV}`,
    release: 'marvel-components@' + process.env.REACT_APP_SENTRY_VERSION,
    autoSessionTracking: true,
    allowUrls: [/booking\.lwtheatres\.co\.uk/i],
    ignoreErrors: [
      // Random plugins/extensions
      'top.GLOBALS',
      // See: http://blog.errorception.com/2012/03/tale-of-unfindable-js-error.html
      'originalCreateNotification',
      'canvas.contentDocument',
      'MyApp_RemoveAllHighlights',
      'http://tt.epicplay.com',
      "Can't find variable: ZiteReader",
      'jigsaw is not defined',
      'ComboSearch is not defined',
      'http://loading.retry.widdit.com/',
      'atomicFindClose',
      // Facebook borked
      'fb_xd_fragment',
      // ISP "optimizing" proxy - `Cache-Control: no-transform` seems to
      // reduce this. (thanks @acdha)
      // See http://stackoverflow.com/questions/4113268
      'bmi_SafeAddOnload',
      'EBCallBackMessageReceived',
      // See http://toolbar.conduit.com/Developer/HtmlAndGadget/Methods/JSInjection.aspx
      'conduitPage'
    ],
    denyUrls: [
      // Facebook flakiness
      /graph\.facebook\.com/i,
      // Facebook blocked
      /connect\.facebook\.net\/en_US\/all\.js/i,
      // Woopra flakiness
      /eatdifferent\.com\.woopra-ns\.com/i,
      /static\.woopra\.com\/js\/woopra\.js/i,
      // Chrome extensions
      /extensions\//i,
      /^chrome:\/\//i,
      // Other plugins
      /127\.0\.0\.1:4001\/isrunning/i, // Cacaoweb
      /webappstoolbarba\.texthelp\.com\//i,
      /metrics\.itunes\.apple\.com\.edgesuite\.net\//i
    ]
  })
}

if (process.env.REACT_APP_GA_KEY && process.env.NODE_ENV === 'production') {
  // GA
  const debug = false
  ReactGA.initialize(`${process.env.REACT_APP_GA_KEY}`, { debug: debug })
  ReactGA.plugin.require('ec')
}

if (
  process.env.REACT_APP_GA4_MEASUREMENT_ID &&
  process.env.NODE_ENV === 'production'
) {
  // GA4
  ReactGA4.initialize(`${process.env.REACT_APP_GA4_MEASUREMENT_ID}`, {
    legacyDimensionMetric: false,
    gtagOptions: { debug_mode: true, send_page_view: false }
  })
}

if (process.env.REACT_APP_DOT_MAILER_TRACKING_ID) {
  DotMailer.initialize(`${process.env.REACT_APP_DOT_MAILER_TRACKING_ID}`)
}

if (process.env.REACT_APP_GTM) {
  const tagManagerArgs = {
    gtmId: `${process.env.REACT_APP_GTM}`
  }
  TagManager.initialize(tagManagerArgs)
}

ReactDOM.render(
  <React.StrictMode>
    <Provider store={reduxStore}>
      <App />
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
)
