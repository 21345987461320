import React, { useContext } from 'react'

// Components
import { Icon, Button, SeatTitle, LazyImage } from 'marvel-components'

// Selectors
import {
  getViewFromSeatUrl,
  selectSeatWithTickets
} from 'modules/seat-map/selectors'

// Actions
import { closeModal } from 'modules/overlay/actions'
import { addSeat, addSeatBlock } from 'modules/basket/actions'
import { ThemeContext } from 'styled-components'

// Utils
import { useDispatch } from 'react-redux'
import { FormattedPrice, getSeatDisplay, fadeIn, media } from 'utils'
import styled from 'styled-components/macro'

// Types
import { useTypedSelector } from 'modules'
import { PriceType } from 'shared-types'

const SeatOverlayWrapper = styled.div`
  padding: 0.5rem 0.25rem;
  background-color: #fff;
  animation: ${fadeIn} 0.2s;
  padding: 1.5rem 1rem 1rem 1rem;

  max-width: 30rem;
  min-width: 30rem;
  ${media.tablet} {
    max-width: unset;
    min-width: unset;
    width: calc(100vmin - 5rem);
  }
`

const SeatOverlayHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  font-family: ${props => props.theme.font.header};
  color: ${props => props.theme.colors.headingColor};
`

const PriceWrapper = styled.div`
  display: flex;
  align-items: center;
  font-size: 1rem;
  line-height: 1.2;
`

const PriceSeparator = styled.span`
  margin-left: 0.2rem;
  margin-right: 0.2rem;
`

const SeatOverlayNotes = styled.div<{ messageBackground: string }>`
  background-color: ${props => props.messageBackground};
  width: 96%;
  padding: 0.35rem 2%;
  color: #111111;
  border-radius: 0.1rem;
  display: flex;
  flex-direction: row;
  font-size: 0.75rem;
  text-transform: none;
  margin-top: 0.625rem;
  font-family: ${props => props.theme.font.copy};
  line-height: 1.25;
`

const SeatOverlayNotesCopy = styled.span`
  margin-left: 0.25rem;
`

const SeatSelectButton = styled(Button)`
  margin-top: 1rem;
`

const SeatPriceButton = styled(Button)`
  margin-top: 1rem;
  text-transform: none;
`

const ViewFromSeatImageWrapper = styled.div`
  width: 100%;
  height: 100%;
  padding: 1rem 0 0;
`

/* const ViewFromSeatImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
` */

export type SeatModalProps = {
  seatId: string
  priceTypeId?: string
}
const SeatModalContainer: React.FC<SeatModalProps> = ({
  seatId,
  priceTypeId
}) => {
  // Actions
  const dispatch = useDispatch()

  // Context
  const theme = useContext(ThemeContext)

  // Selectors
  const seatDetail = useTypedSelector(state =>
    selectSeatWithTickets(state, seatId)
  )

  const imageUrl = useTypedSelector(state => getViewFromSeatUrl(state, seatId))

  if (!seatDetail) return null

  const priceTypes = priceTypeId
    ? [
        seatDetail.priceLevel.priceTypes.find(
          priceType => priceType.id === priceTypeId
        ) || seatDetail.priceLevel.priceTypes[0]
      ]
    : seatDetail.priceLevel.priceTypes

  const seatDisplay = getSeatDisplay(seatDetail.seatType)

  const handleOnSelectSeat = (priceType: PriceType) => {
    const ticket = {
      id: seatDetail.id,
      areaId: seatDetail.areaId,
      performanceId: seatDetail.performanceId,
      priceLevelId: seatDetail.priceLevelGuid,
      priceTypeId: priceType.id
    }
    theme.sociallyDistanced
      ? dispatch(addSeatBlock(ticket))
      : dispatch(addSeat(ticket))
    dispatch(closeModal())
  }

  return (
    <SeatOverlayWrapper>
      <SeatOverlayHeader>
        <SeatTitle
          areaName={seatDetail.areaName}
          seatName={seatDetail.seatName}
          rowName={seatDetail.rowName}
          priceLevelColor={seatDetail.priceLevel.background}
        />
        {priceTypes.length === 1 ? (
          <PriceWrapper>
            <FormattedPrice amount={priceTypes[0].total} currency='GBP' />
          </PriceWrapper>
        ) : null}
      </SeatOverlayHeader>
      {seatDisplay?.icon && seatDisplay?.note && (
        <SeatOverlayNotes messageBackground={seatDisplay.background}>
          <Icon icon={seatDisplay.messageIcon} />
          <SeatOverlayNotesCopy>{seatDisplay.note}</SeatOverlayNotesCopy>
        </SeatOverlayNotes>
      )}
      {imageUrl && (
        <ViewFromSeatImageWrapper>
          <LazyImage
            url={imageUrl}
            alt={`View from seat for seat ${seatDetail.seatName} row ${seatDetail.rowName}`}
          />
        </ViewFromSeatImageWrapper>
      )}
      {priceTypes.length === 1 ? (
        <SeatSelectButton
          text='Select Seat'
          onClick={() => handleOnSelectSeat(priceTypes[0])}
          isBlock={true}
        />
      ) : (
        <>
          {priceTypes.map(priceType => (
            <SeatPriceButton
              key={priceType.id}
              onClick={() => handleOnSelectSeat(priceType)}
              isBlock={true}
            >
              <PriceWrapper>
                {priceType.name}
                <PriceSeparator>-</PriceSeparator>
                <FormattedPrice
                  amount={priceType.total}
                  currency='GBP'
                  isBold={true}
                />
              </PriceWrapper>
            </SeatPriceButton>
          ))}
        </>
      )}
    </SeatOverlayWrapper>
  )
}

export { SeatModalContainer }
