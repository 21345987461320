import { createSlice } from '@reduxjs/toolkit'
import { venuePageAdapter } from './selectors'
import { fetchVenuePages } from './actions'

type AdditionalState = {
  status: 'idle' | 'pending' | 'fulfilled' | 'rejected'
}

const initialState = venuePageAdapter.getInitialState<AdditionalState>({
  status: 'idle'
})

const venuePageSlice = createSlice({
  name: 'venuePage',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(fetchVenuePages.fulfilled, (state, action) => {
        venuePageAdapter.setAll(state, action.payload.venuePages)
        state.status = 'fulfilled'
      })
      .addCase(fetchVenuePages.rejected, state => {
        venuePageAdapter.removeAll(state)
        state.status = 'rejected'
      })
      .addCase(fetchVenuePages.pending, state => {
        state.status = 'pending'
      })
  }
})

export const venuePageActions = {
  fetchVenuePages,
  ...venuePageSlice.actions
}

export default venuePageSlice.reducer
