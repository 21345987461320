// Components
import {
  CopyBlock,
  TicketCard,
  Title,
  Accordion,
  Markdown
} from 'marvel-components'

// Misc
import styled from 'styled-components'
import { RefObject } from 'react'
import { Link } from 'react-router-dom'

// Utils
import { media } from 'utils'
import { basketSelectors } from 'modules/basket'
import { useSelector } from 'react-redux'
import moment from 'moment'
import { faqSelectors } from 'modules/ticketing/manage-orders'

const StyledTitle = styled(Title)`
  margin-bottom: 0.5rem;
`

const ManageWrapper = styled.div`
  padding: 1.5rem;
  background: ${props => props.theme.colors.secondaryBackgroundColor};
  ${media.tablet} {
    padding: 1rem;
    margin-top: 1rem;
  }
`

const Divider = styled.div`
  border-top: 1px solid ${props => props.theme.colors.borderColor};
  margin: 3rem 0 2.5rem 0;
  overflow: visible;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
`

const ReducedMarginDivider = styled(Divider)`
  margin: 2rem 0 2.5rem 0;
`

const YourTicketsTextWrapper = styled.div`
  margin-bottom: 1.5rem;
`

const AccordionWrapper = styled.div`
  margin: 1rem 0 4rem 0;
`

const OrderSuccessLinkWrapper = styled.div`
  margin-top: 2rem;
  font-family: ${props => props.theme.font.copy};
`

interface Props {
  faqRef?: RefObject<HTMLSpanElement> | null
}

const ManageContainer = ({ faqRef }: Props) => {
  const order = useSelector(basketSelectors.selectOrder)
  const faqTitle = useSelector(faqSelectors.selectFaqTitle)
  const faqDescription = useSelector(faqSelectors.selectFaqDescription)
  const faqQuestions = useSelector(faqSelectors.selectFaqQuestions)

  return (
    <ManageWrapper>
      <>
        <YourTicketsTextWrapper>
          <StyledTitle
            isCapitalised={false}
            size='medium'
            isBold
            isCentralised={false}
          >
            Your Tickets
          </StyledTitle>
          <CopyBlock>
            Please have these tickets ready to scan on your mobile device when
            you arrive at the venue.
          </CopyBlock>
        </YourTicketsTextWrapper>
        {order?.basket.ticketReservations.map((reservation, index) => (
          <TicketCard
            key={`ticket-${index}`}
            showName={reservation.name}
            price={reservation.total}
            seat={`${reservation.areaName} ${reservation.seatName} ${reservation.rowName}`}
            date={
              reservation.startDate
                ? moment(reservation.startDate).format(
                    'dddd Do MMM YYYY, hh:mma'
                  )
                : 'N/A'
            }
            barcode={
              reservation.barcode
                ? reservation.barcode
                : { status: 'unavailable' }
            }
          />
        ))}
      </>
      <OrderSuccessLinkWrapper>
        <CopyBlock>
          <Link to={`/order/${order?.transactionGuid}/success`}>
            Go back to your Order Success page
          </Link>
        </CopyBlock>
      </OrderSuccessLinkWrapper>
      <ReducedMarginDivider />
      <>
        <StyledTitle size='medium' isBold isCentralised={false} ref={faqRef}>
          {faqTitle}
        </StyledTitle>
        {faqDescription && (
          <CopyBlock>
            <Markdown>{faqDescription}</Markdown>
          </CopyBlock>
        )}
        <AccordionWrapper>
          {faqQuestions.map(({ id, question, answer }, i) => (
            <Accordion
              key={id}
              id={question}
              title={question}
              content={
                <CopyBlock>
                  <Markdown>{answer}</Markdown>
                </CopyBlock>
              }
            />
          ))}
        </AccordionWrapper>
      </>
    </ManageWrapper>
  )
}

export { ManageContainer }
