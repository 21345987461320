import React from 'react'

//Components
import { Button, CopyBlock, Title } from 'marvel-components'

// Assets
import SmartWaiterLogo from 'assets/sw_logo.png'

// Misc
import styled from 'styled-components'
import { media } from 'utils'

type Props = {
  messageType: 'interval' | 'offline'
}

const SmartWaiterWrapper = styled.div`
  height: 100%;
  min-height: 100vh;
  width: 100%;
  background: #111111;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
`

const SmartWaiterSplashWrapper = styled.div`
  width: 400px;
  min-height: 90vh;
  height: 100%;
  margin: 3rem 0 0 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  ${media.phone} {
    width: 90%;
    justify-content: flex-start;
  }
`
const SmartWaiterSplashImage = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 0 0 0 0;
  ${media.phone} {
    width: 90%;
  }
`
const SmartWaiterSplashImageImage = styled.img`
  width: 150px;
  margin: 0 0 3rem 0;
  ${media.phone} {
    width: 50%;
  }
`

const OfflineTextArea = styled.div``

const OfflineTitle = styled(Title)`
  color: #fff;
`

const OfflineCopyBlock = styled(CopyBlock)`
  color: #fff;
`

const SmartWaiterButton = styled(Button)`
  text-transform: none;
  font-family: ${props => props.theme.font.header};
  font-size: 1rem;
`

const SmartWaiterFooter = styled.div`
  margin: 5rem 0 0 0;
  padding: 0 0 2rem 0;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  font-size: 0.85rem;
  ${media.phone} {
    width: 90%;
  }
`

const SummaryFooter = styled.div`
  font-family: ${props => props.theme.font.copy};
  margin-top: 2rem;
  color: ${props => props.theme.colors.borderColor};
`
const text = {
  offline: {
    title: 'You just missed us',
    description:
      "We're afraid it's too late to order via Smart Waiter. Please go to the nearest bar to order."
  },
  interval: {
    title: 'You just missed us',
    description:
      "We're afraid it's too late to order for the interval via Smart Waiter. Please go to the nearest bar to order."
  }
}

const SmartWaiterOfflineContainer: React.FC<Props> = ({ messageType }) => {
  const { title, description } = text[messageType]

  return (
    <SmartWaiterWrapper>
      <SmartWaiterSplashWrapper>
        <SmartWaiterSplashImage>
          <SmartWaiterSplashImageImage src={SmartWaiterLogo} />
        </SmartWaiterSplashImage>
        <OfflineTextArea>
          <OfflineTitle size='large' isCapitalised={false} isBold={true}>
            {title}
          </OfflineTitle>
          <OfflineCopyBlock isCentralised={true} isSidebar={true}>
            {description}
          </OfflineCopyBlock>
        </OfflineTextArea>

        <SmartWaiterFooter>
          <SmartWaiterButton buttonType='light' isBlock>
            Visit the FAQ
          </SmartWaiterButton>
          <SummaryFooter>LW Theatres © 2022</SummaryFooter>
        </SmartWaiterFooter>
      </SmartWaiterSplashWrapper>
    </SmartWaiterWrapper>
  )
}

export { SmartWaiterOfflineContainer }
