import React, { useState, useEffect } from 'react'
import styled from 'styled-components/macro'
import { useField, FieldConfig, useFormikContext } from 'formik'

// Components
import {
  AddressLookup,
  Button,
  FormInput,
  FormRow,
  Title,
  FormWrapper
} from 'marvel-components'

import { Address } from 'shared-types'

interface Props extends FieldConfig {
  address?: Address
  showManualAddress?: boolean
}

const FormAddressLookupWrapper = styled.div`
  position: relative;
  padding-bottom: 1.6rem;
`

const AddressToggle = styled(Button)`
  position: absolute;
  right: 0;
  bottom: 0.25rem;
`

export const FormAddress = (props: Props) => {
  const { showManualAddress = false, ...fieldProps } = props
  const [isManualAddress, setIsManualAddress] = useState(showManualAddress)
  const [field, meta] = useField(fieldProps.name)
  const { setFieldValue } = useFormikContext()
  const { name } = field
  const isInvalid = !!(meta.touched && meta.error)

  useEffect(() => {
    setIsManualAddress(showManualAddress)
  }, [showManualAddress])

  const handleAddressRetrieve = (address: Address | null) => {
    setFieldValue(name, address, true)
  }

  const handleToggleManualAddress = () => {
    setIsManualAddress(!isManualAddress)
    isManualAddress && handleAddressRetrieve({})
  }

  return (
    <FormAddressLookupWrapper>
      {!isManualAddress && (
        <AddressLookup
          isInvalid={isInvalid}
          onAddressRetrieve={handleAddressRetrieve}
          isRequired
        />
      )}
      {isManualAddress && (
        <>
          <FormWrapper>
            <Title
              isCapitalised={false}
              isCentralised={false}
              isBold={true}
              size='medium'
            >
              Address Details
            </Title>
            <FormInput
              isRequired
              type='text'
              placeholder='Address Line 1'
              name='customer.address.addressLine1'
            />
            <FormInput
              type='text'
              placeholder='Address Line 2'
              name='customer.address.addressLine2'
            />
            <FormInput
              type='text'
              placeholder='Address Line 3'
              name='customer.address.addressLine3'
            />
            <FormRow>
              <FormInput
                isRequired
                type='text'
                placeholder='City'
                name='customer.address.city'
              />
              <FormInput
                type='text'
                placeholder='Region'
                name='customer.address.province'
              />
            </FormRow>
            <FormRow>
              <FormInput
                type='text'
                isRequired
                placeholder='Country'
                name='customer.address.country'
              />
              <FormInput
                type='text'
                isRequired
                placeholder='Post Code'
                name='customer.address.postcode'
              />
            </FormRow>
          </FormWrapper>
        </>
      )}
      <AddressToggle
        text={
          !isManualAddress
            ? 'Address not showing? Add manually'
            : 'Back to Find Address'
        }
        buttonType='link-button'
        size='small'
        onClick={handleToggleManualAddress}
      />
    </FormAddressLookupWrapper>
  )
}
