import React from 'react'

// Selectors
import { authSelectors } from 'modules/ticketing/auth/authSlice'

// Actions
import { authActions } from 'modules/ticketing/auth/authSlice'
import { openModal } from 'modules/overlay/actions'

// Components
import { Login } from 'marvel-components'

// Misc
import { useSelector, useDispatch } from 'react-redux'

type Props = {
  isSmall?: boolean
}
const LoginButtonContainer: React.FC<Props> = ({ isSmall = false }) => {
  const dispatch = useDispatch()

  // Selectors
  const isUserLoggedIn = useSelector(authSelectors.selectIsLoggedIn)

  const handleLoginClick = () =>
    dispatch(
      openModal({
        variant: 'login',
        props: {}
      })
    )
  const handleLogoutClick = () => {
    dispatch(authActions.logout())
  }
  return (
    <Login
      isUserLoggedIn={isUserLoggedIn}
      onLoginClick={handleLoginClick}
      onLogoutClick={handleLogoutClick}
      isSmall={isSmall}
    />
  )
}

export { LoginButtonContainer }
