import { Duration } from 'moment'
import { useEffect, useState } from 'react'

const TRACKED_EVENTS = [
  'mousedown',
  'mousemove',
  'keypress',
  'scroll',
  'touchstart'
]

export const useIsIdle = (
  idleAfter: Duration | null,
  initialState?: boolean
) => {
  const [isIdle, setIsIdle] = useState(initialState || false)

  useEffect(() => {
    let timeout: NodeJS.Timeout | null

    const resetTimeout = () => {
      if (idleAfter) {
        timeout && clearTimeout(timeout)
        timeout = setTimeout(() => {
          setIsIdle(true)
        }, idleAfter.asMilliseconds())
      }
    }

    const handleEvent = () => {
      setIsIdle(false)
      resetTimeout()
    }

    TRACKED_EVENTS.forEach(event => {
      document.addEventListener(event, handleEvent)
    })

    resetTimeout()

    return () => {
      timeout && clearTimeout(timeout)
      TRACKED_EVENTS.forEach(event => {
        document.removeEventListener(event, handleEvent)
      })
    }
  }, [isIdle, setIsIdle, idleAfter])

  return isIdle
}
