import {
  createEntityAdapter,
  createSlice,
  PayloadAction
} from '@reduxjs/toolkit'
import { Kiosk } from 'shared-types'
import {
  authenticateKiosk,
  fetchKiosks,
  fetchPaymentStatus
} from 'modules/self-serve/kiosk/actions'
import {
  clearKioskAccessToken,
  getKioskAccessToken,
  setKioskAccessToken,
  clearContextId
} from '../shared/utils'

export const kioskAdapter = createEntityAdapter<Kiosk>()

type AdditionalState = {
  status: 'idle' | 'pending' | 'fulfilled' | 'rejected'
  accessToken?: string
  selectedKioskId?: number
  paymentStatus: 'idle' | 'pending' | 'fulfilled' | 'rejected'
}

const additionalState: AdditionalState = {
  status: 'idle',
  accessToken: getKioskAccessToken() ?? undefined,
  paymentStatus: 'idle'
}

const initialState = kioskAdapter.getInitialState<AdditionalState>(
  additionalState
)

const kioskSlice = createSlice({
  name: 'kiosk',
  initialState,
  reducers: {
    logout: state => {
      delete state.accessToken
      clearKioskAccessToken()
      clearContextId()
    },
    selectKiosk: (state, action: PayloadAction<number>) => {
      state.selectedKioskId = action.payload
    },
    clearPaymentStatus: state => {
      state.paymentStatus = 'idle'
    }
  },
  extraReducers: builder => {
    builder
      .addCase(fetchKiosks.pending, state => {
        state.status = 'pending'
      })
      .addCase(fetchKiosks.fulfilled, (state, action) => {
        kioskAdapter.setAll(state, action.payload.data.kiosks)
        state.status = 'fulfilled'
      })
      .addCase(fetchKiosks.rejected, state => {
        state.status = 'rejected'
      })
      .addCase(authenticateKiosk.fulfilled, (state, action) => {
        state.accessToken = action.payload.data.accessToken
        setKioskAccessToken(action.payload.data.accessToken)
        clearContextId()
        state.status = 'fulfilled'
      })
      .addCase(fetchPaymentStatus.fulfilled, (state, action) => {
        const serverStatus = action.payload.data.status
        if (serverStatus === 'inProgress') {
          state.paymentStatus = 'pending'
        } else if (serverStatus === 'failure') {
          state.paymentStatus = 'rejected'
        } else if (serverStatus === 'idle') {
          state.paymentStatus = 'idle'
        } else {
          state.paymentStatus = 'fulfilled'
        }
      })
  }
})

export const kioskActions = {
  fetchKiosks,
  fetchPaymentStatus,
  authenticateKiosk,
  ...kioskSlice.actions
}

export default kioskSlice.reducer
