import styled from 'styled-components/macro'

export const FormError = styled.span`
  position: absolute;
  bottom: 0.5rem;
  left: 0;
  color: ${props => props.theme.colors.alertColor};
  font-size: 0.625rem;
  font-family: ${props => props.theme.font.copy};
  white-space: nowrap;
`
