import axios from 'axios'
import { APIResponse } from 'shared-types'

const API_ROOT = process.env.REACT_APP_API_ROOT

const axiosInstance = axios.create({
  baseURL: `${API_ROOT}/hospitality/`,
  timeout: 15000,
  headers: {
    'Content-Type': 'application/json'
  }
})

const hospitalityApi = {
  async fetchAll(url: string, params?: any, accessToken?: string) {
    const result = await axiosInstance.get<APIResponse>(url, {
      params,
      headers: {
        ...(accessToken && { Authorization: `Bearer ${accessToken}` })
      }
    })
    return result.data
  },
  async create(url: string, body: any, accessToken?: string) {
    const result = await axiosInstance.post<APIResponse>(
      url,
      JSON.stringify(body),
      {
        headers: {
          ...(accessToken && { Authorization: `Bearer ${accessToken}` })
        }
      }
    )
    return result.data
  },
  async update(url: string, body: any, accessToken?: string) {
    const result = await axiosInstance.put<APIResponse>(
      url,
      JSON.stringify(body),
      {
        headers: {
          ...(accessToken && { Authorization: `Bearer ${accessToken}` })
        }
      }
    )
    return result.data
  }
}

export default hospitalityApi
