import React from 'react'
import styled from 'styled-components'

//Components
import { Title, CopyBlock, Icon, Link } from 'marvel-components'

import { media, lighten } from 'utils'

const ExchangeSuccessWrapper = styled.div`
  box-sizing: border-box;
  padding: 1.5rem;
  ${media.tablet} {
    padding: 1rem;
    margin-top: 0;
  }
`

const ExchangeSuccessMessageBanner = styled.div`
  padding: 1.5rem;
  background: ${(props) => lighten(props.theme.colors.borderColor, 10)};
  margin: 0 0 1.5rem 0;
`

const ExchangeSuccessMessageHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 1rem;
`
const ExchangeSuccessMessageBody = styled.div`
  margin-bottom: 1rem;
`

const IconWrapper = styled.div`
  background: ${(props) => props.theme.colors.activeColor};
  border-radius: 50%;
  fill: #ffffff;
  width: 1.5rem;
  height: 1.5rem;
  line-height: 1.6rem; /* Note: Due to dodgy awesome font tick */
  text-align: center;
  font-size: 0.875rem;
  margin-left: 0.5rem;
`

const ExchangeContainerSuccess: React.FC = () => {
  return (
    <ExchangeSuccessWrapper>
      <ExchangeSuccessMessageBanner>
        <ExchangeSuccessMessageHeader>
          <Title
            isCapitalised={false}
            isCentralised={false}
            isBold={true}
            size="medium"
          >
            Request Complete
          </Title>
          <IconWrapper>
            <Icon icon={'check'} />
          </IconWrapper>
        </ExchangeSuccessMessageHeader>
        <ExchangeSuccessMessageBody>
          <CopyBlock>
            <p>
              Thank you for submitting your request. A member of our customer
              relations team will be in touch shortly.
            </p>
            <p>
              <Link
                text={`Back to the LW Theatres website`}
                href="https://lwtheatres.co.uk"
                isUnderlined={true}
                size="copy"
              />
            </p>
            <p>
              <Link
                text={`Ticket Exchange Terms and Conditions`}
                href="https://lwtheatres.co.uk/terms-conditions/"
                isUnderlined={true}
                size={'extra-small'}
                target="_blank"
              />
            </p>
          </CopyBlock>
        </ExchangeSuccessMessageBody>
      </ExchangeSuccessMessageBanner>
    </ExchangeSuccessWrapper>
  )
}

export { ExchangeContainerSuccess }
