import React, { useEffect } from 'react'
import styled from 'styled-components/macro'

import { media } from 'utils'

// Components
import {
  BookingFooter,
  CForm,
  basicInitialValues,
  FormSelect,
  FormRow,
  FormInput,
  SubmitValues,
  FormCheckbox
} from 'marvel-components'
import { Field } from 'formik'

// Selectors
import { basketSelectors } from 'modules/smart-waiter/basket'
import { venueSelectors } from 'modules/smart-waiter/venue'
import { optinSelectors } from 'modules/smart-waiter/optin/optinSlice'
import { paymentSelectors } from 'modules/ticketing/payment/paymentSlice'
import { selectBookingFlow } from 'modules/basket/selectors'

// Actions
import {
  createSWOrder,
  orderSelectors,
  updateSWOrder
} from 'modules/smart-waiter/order'
import { openModal } from 'modules/overlay/actions'
import { paymentActions } from 'modules/ticketing/payment/paymentSlice'

// Misc
import { useAppDispatch } from 'app/reduxStore'
import { useSelector } from 'react-redux'
import { Redirect } from 'react-router-dom'
import * as Yup from 'yup'
import { trackSmartWaiterAddPaymentInfo, usePlatform } from 'utils'

// Types
import { AppState } from 'modules/types'

type Props = {
  venueId: EntityId
  salesAreaId: EntityId
}

type AdditionalFormValues = {
  seatName: string
  rowName: string
  seatArea?: { value: number; label: string; id: number }
  optins: { [key: string]: boolean }
}

const CheckoutWrapper = styled.div`
  height: 100%;
  box-sizing: border-box;
  padding: 0 1.5rem 1.5rem 1.5rem;
  background: ${props => props.theme.colors.secondaryBackgroundColor};
  ${media.tablet} {
    padding: 1.5rem 1rem 1rem 1rem;
  }
`

const SeatDetailsInputWrapper = styled.div`
  margin-top: 1rem;
`

const CheckoutContainer: React.FC<Props> = ({ venueId, salesAreaId }) => {
  const dispatch = useAppDispatch()

  // Fetch Payment Methods
  // ------------------------------------------
  useEffect(() => {
    dispatch(paymentActions.fetchPaymentMethods('sw'))
  }, [dispatch])

  // Selectors
  const amount = useSelector(basketSelectors.selectBasketTotal)
  const basket = useSelector((state: AppState) =>
    basketSelectors.selectItemsForReservation(state)
  )
  const areas = useSelector(venueSelectors.selectAreas)
  const paymentMethods = useSelector(paymentSelectors.selectPaymentMethods)
  const paymentChallenge = useSelector(orderSelectors.selectPaymentChallenge)
  const order = useSelector(orderSelectors.selectOrder)
  const selectedAreaId = useSelector(venueSelectors.selectSelectedAreaId)
  const selectedTime = useSelector(venueSelectors.selectedSelectedTime)
  const isOrderLoading = useSelector(orderSelectors.selectIsOrderLoading)

  const optinQuestions = useSelector(optinSelectors.selectAll)

  const platform = usePlatform()
  const bookingFlow = useSelector(selectBookingFlow)
  const basketSummary = useSelector(basketSelectors.selectBasketSummary)

  // Actions
  // Show 3ds Modal if needed
  useEffect(() => {
    if (paymentChallenge && order) {
      dispatch(
        openModal({
          variant: 'three-ds-smart-waiter',
          props: {
            orderId: order.id,
            challenge: paymentChallenge
          }
        })
      )
    }
  }, [paymentChallenge, order, dispatch])

  const handleOnSubmit = (values: SubmitValues<AdditionalFormValues>) => {
    let paymentType = 'credit_card'
    if (values.paymentData) {
      if (values.paymentData.paymentMethod) {
        if (values.paymentData.paymentMethod.brand) {
          paymentType = values.paymentData.paymentMethod.brand
        } else if (values.paymentData.paymentMethod.scheme) {
          paymentType = values.paymentData.paymentMethod.scheme
        }
      }
    }
    // Analytics
    const items = basketSummary.groups
      .map(group =>
        group.items.map(item => ({
          id: String(item.id),
          name: item.title,
          price: item.price,
          quantity: item.quantity
        }))
      )
      .flat()

    trackSmartWaiterAddPaymentInfo({
      platform,
      bookingFlow,
      paymentType,
      price: basketSummary.total,
      items: items
    })

    // Order logic
    if (order) {
      dispatch(
        updateSWOrder({
          orderId: order.id,
          payment: values.paymentData
        })
      )
    } else {
      const optins = Object.keys(values.optins).map((key: string) => ({
        questionId: parseInt(key, 10),
        accepted: values.optins[key]
      }))
      dispatch(
        createSWOrder({
          email: values.email,
          firstName: values.firstName,
          lastName: values.lastName,
          telephoneNumber: values.telephoneNumber,
          venueId,
          salesAreaId,
          amount: amount * 100,
          baskets: basket,
          payment: values.paymentData,
          rowName: values.rowName,
          seatName: values.seatName,
          seatArea: values.seatArea?.id,
          optins,
          performanceDate: selectedTime
        })
      )
    }
  }

  if (
    order &&
    (order.status === 'PAID' ||
      order.status === 'FULFILLED' ||
      order.status === 'FULFILMENT_FAILED')
  ) {
    return (
      <Redirect
        to={`/smart-waiter/venue/${venueId}/area/${salesAreaId}/order`}
      />
    )
  }

  const initialValues = {
    ...basicInitialValues,
    seatName: '',
    rowName: '',
    seatArea: selectedAreaId
      ? areas.find(area => area.id === selectedAreaId)
      : undefined,
    optins: {}
  }
  const additionalValidation = Yup.object().shape({
    seatName: Yup.string()
      .min(1)
      .max(5)
      .required('Please enter your seat name name.'),
    rowName: Yup.string()
      .min(1)
      .max(5)
      .required('Please enter your row name name.'),
    seatArea: Yup.object().required('Please select your seating area')
  })

  return (
    <CheckoutWrapper>
      <CForm<AdditionalFormValues>
        title={'Order Options'}
        onSubmit={handleOnSubmit}
        isLoading={isOrderLoading}
        amount={amount * 100}
        initialValues={initialValues}
        additionalValidation={additionalValidation}
        paymentMethods={paymentMethods}
        additionalFields={
          <>
            <SeatDetailsInputWrapper>
              {areas && (
                <FormSelect
                  name='seatArea'
                  placeholder='Select your seating area'
                  options={areas}
                  isDisabled={!!selectedAreaId}
                />
              )}
              <FormRow mobileNarrow={true}>
                <FormInput
                  name='rowName'
                  type='text'
                  placeholder='Row e.g. A'
                  mobileNarrow={true}
                />
                <FormInput
                  name='seatName'
                  type='text'
                  placeholder='Seat e.g. 17'
                  mobileNarrow={true}
                />
              </FormRow>
            </SeatDetailsInputWrapper>
            {optinQuestions.map(optin => (
              <Field
                key={`optin-${optin.id}`}
                name={`optins.${optin.id}`}
                label={optin.text}
                isLabelVisible={true}
                size={'small'}
                checkboxTheme={'dark'}
                component={FormCheckbox}
              />
            ))}
          </>
        }
      />
      <BookingFooter />
    </CheckoutWrapper>
  )
}

export { CheckoutContainer }
