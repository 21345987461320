import React from 'react'
import styled from 'styled-components/macro'

// Components
import { Title, Button } from 'marvel-components'

// Utils
import { media, darken } from 'utils'

const BannerWrapper = styled.div<{
  backgroundColor?: string
  isCentred?: boolean
  bannerImage?: string
}>`
  margin: 1.5rem 0;
  padding: ${props => (props.bannerImage ? 0 : '1.5rem')};
  background: #111111;
  background-color: ${props =>
    props.backgroundColor ? props.backgroundColor : '#111111'};
  display: flex;
  flex-direction: ${props => (props.isCentred ? 'column' : 'row')};
  align-items: center;
  justify-content: ${props => (props.isCentred ? 'center' : 'flex-start')};
  min-height: ${props => (props.bannerImage ? '225px' : '10.3rem')};
  position: relative;
  ${media.phone} {
    flex-direction: column;
    padding: ${props => (props.bannerImage ? 0 : '1.5rem 5%')};
  }
`

const BannerText = styled.div<{
  isCentred?: boolean
  bannerImage?: string
}>`
  width: ${props =>
    props.isCentred
      ? '100%'
      : props.bannerImage
      ? 'calc(100% - 407px)'
      : 'calc(100% - 192px)'};
  margin-right: ${props =>
    props.isCentred ? 0 : props.bannerImage ? '117px' : '32px'};
  order: ${props => (props.isCentred ? 2 : 1)};
  display: ${props => (props.isCentred ? 'flex' : 'block')};
  padding: ${props => (props.bannerImage ? '0.75rem 0 0.75rem 3%' : '0')};
  flex-direction: column;
  align-items: center;
  ${media.tablet} {
    width: ${props =>
      props.isCentred
        ? '100%'
        : props.bannerImage
        ? 'calc(100% - 387px)'
        : 'calc(100% - 192px)'};
  }
  ${media.phone} {
    width: ${props => (props.bannerImage ? '90%' : '100%')};
    margin-right: 0;
    order: 2;
    padding: ${props => (props.bannerImage ? '1.5rem 5%' : '1rem 0 0 0')};
  }
`

const BannerIconWrapper = styled.div<{ isCentred?: boolean }>`
  width: 160px;
  order: ${props => (props.isCentred ? 1 : 2)};
  ${media.phone} {
    width: 140px;
    order: 1;
  }
`

const BannerIcon = styled.img`
  width: 100%;
`

const BannerImageWrapper = styled.div<{ isCentred?: boolean }>`
  width: 290px;
  order: ${props => (props.isCentred ? 1 : 2)};
  line-height: 1;
  display: flex;
  justify-content: flex-start;
  position: absolute;
  height: 100%;
  right: 0;

  ${media.phone} {
    width: 270px;
    order: 1;
  }

  ${media.phone} {
    width: 100%;
    order: 1;
    height: 250px;
    position: relative;
  }
`

const BannerImageCover = styled.div<{ backgroundColor?: string }>`
  position: absolute;
  width: 117px;
  height: 100%;
  top: 0;
  left: 0;
  background-color: ${props =>
    props.backgroundColor ? props.backgroundColor : '#111111'};
  left: -58px;
  transform: skew(25deg);
  ${media.phone} {
    display: none;
  }
`

const BannerImage = styled.div<{ img?: string }>`
  width: 100%;
  height: 100%;
  background-image: url(${props => props.img});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
`

const BannerMessaging = styled.div`
  margin-bottom: 1.5rem;
`

const BannerCopyBlock = styled.div<{
  isCentred?: boolean
  copyColor?: string
}>`
  color: ${props => (props.copyColor ? props.copyColor : '#fff')};
  font-size: 1rem;
  font-family: ${props => props.theme.font.copy};
  line-height: 1.5;
  text-align: ${props => (props.isCentred ? 'center' : 'left')};
  ${media.phone} {
    text-align: center;
  }
`

const BannerHeading = styled(Title)<{
  isCentred?: boolean
  copyColor?: string
}>`
  color: ${props => (props.copyColor ? props.copyColor : '#fff')};
  line-height: 1.5;
  padding-top: 0;
  text-align: ${props => (props.isCentred ? 'center' : 'left')};
  ${media.phone} {
    text-align: center;
  }
`

const BannerButton = styled(Button)<{
  buttonColor?: string
  buttonCopyColor?: string
}>`
  text-transform: none;
  font-family: ${props => props.theme.font.header};
  font-size: 1rem;
  color: ${props =>
    props.buttonCopyColor ? props.buttonCopyColor : '#111111'};
  background: ${props => (props.buttonColor ? props.buttonColor : '#fff')};
  :hover {
    background: ${props =>
      props.buttonColor ? darken(props.buttonColor, 5) : '#ebebeb'};
    color: ${props =>
      props.buttonCopyColor ? props.buttonCopyColor : '#111111'};
  }
  ${media.phone} {
    width: 100%;
  }
`

type Props = {
  bannerTitle: string
  bannerDescription: string
  bannerCta?: string
  bannerIcon?: string
  bannerImage?: string
  backgroundColor?: string
  buttonColor?: string
  buttonCopyColor?: string
  copyColor?: string
  isCentred?: boolean
  onBannerClick?: () => void
}

const UpsellBanner: React.FC<Props> = ({
  bannerTitle,
  bannerDescription,
  bannerCta,
  bannerIcon,
  bannerImage,
  onBannerClick,
  backgroundColor,
  buttonColor,
  buttonCopyColor,
  copyColor,
  isCentred = false
}) => {
  const handleOrderNowClick = (e: React.MouseEvent) => {
    e.preventDefault()
    onBannerClick && onBannerClick()
  }
  return (
    <BannerWrapper
      backgroundColor={backgroundColor}
      isCentred={isCentred}
      bannerImage={bannerImage}
    >
      <BannerText isCentred={isCentred} bannerImage={bannerImage}>
        <BannerMessaging>
          <BannerHeading
            isCapitalised={false}
            isBold={true}
            isCentralised={true}
            size='large'
            isCentred={isCentred}
            copyColor={copyColor}
          >
            {bannerTitle}
          </BannerHeading>
          <BannerCopyBlock isCentred={isCentred} copyColor={copyColor}>
            {bannerDescription}
          </BannerCopyBlock>
        </BannerMessaging>
        {bannerCta && onBannerClick && (
          <BannerButton
            text={bannerCta}
            onClick={handleOrderNowClick}
            buttonColor={buttonColor}
            buttonCopyColor={buttonCopyColor}
          />
        )}
      </BannerText>
      {bannerIcon && (
        <BannerIconWrapper isCentred={isCentred}>
          <BannerIcon src={bannerIcon} />
        </BannerIconWrapper>
      )}
      {bannerImage && (
        <>
          <BannerImageWrapper isCentred={isCentred}>
            <BannerImageCover backgroundColor={backgroundColor} />
            <BannerImage img={bannerImage} />
          </BannerImageWrapper>
        </>
      )}
    </BannerWrapper>
  )
}

export { UpsellBanner }
