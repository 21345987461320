import { createEntityAdapter, createSelector } from '@reduxjs/toolkit'
import { AppState } from 'modules'
import { EventCollection } from '../shared/types'

export const eventCollectionAdapter = createEntityAdapter<EventCollection>()

const {
  selectAll,
  selectEntities,
  selectById
} = eventCollectionAdapter.getSelectors()

export const selectEventCollectionState = (state: AppState) =>
  state.selfServe.eventCollection

export const selectEventCollection = selectById

export const selectEventCollections = createSelector(
  selectEventCollectionState,
  selectAll
)

export const selectEventCollectionEntities = createSelector(
  selectEventCollectionState,
  selectEntities
)

export const selectIsEventCollectionLoading = (state: AppState) =>
  state.selfServe.eventCollection.status === 'idle' ||
  state.selfServe.eventCollection.status === 'pending'
