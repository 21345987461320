import React from 'react'
import styled from 'styled-components/macro'
import { connect } from 'react-redux'
import { LazyImage } from 'marvel-components'

// Types
import { AppState } from 'modules/types'

export interface MediaOverlayContainerProps {
  mediaType: string
  mediaUrl: string
  description: string
}

const LazyImageWrapper = styled.div``
const StyledIframe = styled.iframe`
  overflow-y: hidden;
  width: 100%;
  height: 100%;
`

const ProductMediaOverlay: React.FC<MediaOverlayContainerProps> = ({
  mediaType,
  mediaUrl,
  description
}) => {
  let content = <span>Cannot Load Media</span>

  switch (mediaType) {
    case 'image':
      content = mediaUrl ? (
        <LazyImageWrapper>
          <LazyImage url={mediaUrl} alt={description} />
        </LazyImageWrapper>
      ) : (
        <span>Cannot Find Media</span>
      )
      break
    case 'youtube':
      content = (
        <StyledIframe
          title='media iframe'
          src={mediaUrl}
          frameBorder='0'
          allow='accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture'
          allowFullScreen
        />
      )
      break
    default:
      break
  }

  return content
}

const mapStateToProps = (
  state: AppState,
  ownProps: MediaOverlayContainerProps
) => {
  const { mediaUrl, mediaType } = ownProps

  return {
    mediaType,
    mediaUrl
  }
}

export const MediaOverlayContainer = connect(mapStateToProps)(
  ProductMediaOverlay
)
