import ReactGA from 'react-ga'

// Utils
import {
  pushTag,
  clearDataLayer,
  addImpression,
  addProduct,
  setCheckoutAction,
  setPurchaseAction,
  slugify,
  trackAddToCart
} from './utils'

import { getItemsFromOrder } from './utilsGA4'
import moment from 'moment'

// types
import { OrderProductItem, Order, Platform, BookingFlow } from 'shared-types'
import {
  TrackEventPagePayload,
  TrackPerformancePagePayload,
  TrackCheckoutPagePayload,
  TrackOrderPagePayload,
  ECAddProduct,
  TrackBasketActionItem
} from './types'
import DotMailer, { CartPhase, CartLineItem } from './dotmailer'

const CART_DELAY = 10

// Step 1: Product Impression - Event Page
export const trackEventView = (action: TrackEventPagePayload) => {
  const {
    eventId,
    eventName,
    venueName,
    view,
    externalId,
    platform,
    bookingFlow,
    performanceDate,
    performanceTime
  } = action
  const url = `/event/${slugify(eventName)}/${view}`

  clearDataLayer()
  pushTag({
    module: 'ticketing',
    platform,
    pageType: 'event',
    showName: eventName,
    performanceVenue: venueName,
    externalId,
    event: 'view_item',
    purchase_flow: bookingFlow,
    referrer: url,
    ecommerce: {
      currency: 'GBP',
      items: [
        {
          item_id: eventId,
          item_name: eventName,
          item_category: `${performanceDate}/${performanceTime}`,
          item_category4: platform,
          item_category5: 'Ticket',
          item_list_name: view,
          item_variant: '',
          quantity: 1
        }
      ]
    }
  })

  addImpression({
    id: eventId,
    name: eventName,
    list: view,
    dimension9: platform,
    dimension10: bookingFlow
  })
}

// Step 2: Select Performance
export const trackPerformanceDetailView = (
  action: TrackPerformancePagePayload
) => {
  const {
    eventId,
    performanceId,
    eventName,
    venueName,
    performanceDate,
    performanceTime,
    externalId,
    platform,
    bookingFlow,
    contextId = '',
    imageUrl,
    availability
  } = action

  clearDataLayer()
  const url = `/event/${slugify(
    eventName
  )}/performance/${performanceDate}-${performanceTime}`

  pushTag({
    module: 'ticketing',
    platform,
    pageType: 'performance',
    showName: eventName,
    performanceVenue: venueName,
    externalId,
    event: 'view_item',
    referrer: url,
    purchase_flow: bookingFlow,
    performanceDate: performanceDate,
    performanceTime: performanceTime
    // ecommerce: {
    //   currency: 'GBP',
    //   items: [
    //     {
    //       item_id: eventId,
    //       item_name: eventName,
    //       item_category: `${performanceDate}/${performanceTime}`,
    //       item_category4: platform,
    //       item_category5: '',
    //       item_variant: '',
    //       quantity: 1
    //     }
    //   ]
    // }
  })

  // GA
  addProduct({
    id: eventId,
    name: eventName,
    category: `${performanceDate}/${performanceTime}`,
    dimension9: platform,
    dimension10: bookingFlow
  })

  ReactGA.plugin.execute('ec', 'setAction', 'detail')

  // Analytics
  ReactGA.pageview(url)

  // // GA4
  // ReactGA4.send({ hitType: 'pageview', page: url })
  // addToCartGA4([
  //   {
  //     item_id: eventId,
  //     item_name: eventName,
  //     item_category: `${performanceDate}/${performanceTime}`,
  //     item_category4: platform,
  //     item_category5: bookingFlow
  //   }
  // ])

  // Dot Digital
  trackDotMailerCart({
    phase: 'CUSTOMER_LOGIN',
    eventId,
    performanceId,
    eventName,
    venueName,
    performanceDate,
    performanceTime,
    contextId,
    imageUrl,
    availability
  })
}

// Step 3a Add to Basket
export const trackAddToBasketTicket = ({
  id,
  price,
  name,
  performanceDate,
  performanceTime,
  platform,
  bookingFlow
}: TrackBasketActionItem) => {
  clearDataLayer()

  pushTag({
    module: 'ticketing',
    platform,
    pageType: 'performance',
    event: 'add_to_cart',
    purchase_flow: bookingFlow,
    ecommerce: {
      currency: 'GBP',
      value: price,
      items: [
        {
          item_id: id,
          item_name: name,
          currency: 'GBP',
          item_category: `${performanceDate}/${performanceTime}`,
          item_category4: platform,
          item_category5: 'Ticket',
          price,
          quantity: 1
        }
      ]
    }
  })
}

// Step 3b Remove from basket
export const trackRemoveFromBasketTicket = ({
  id,
  price,
  name,
  performanceDate,
  performanceTime,
  platform,
  bookingFlow
}: TrackBasketActionItem) => {
  clearDataLayer()

  pushTag({
    module: 'ticketing',
    platform,
    pageType: 'performance',
    event: 'remove_from_cart',
    purchase_flow: bookingFlow,
    ecommerce: {
      currency: 'GBP',
      value: price,
      items: [
        {
          item_id: id,
          item_name: name,
          currency: 'GBP',
          item_category: `${performanceDate}/${performanceTime}`,
          item_category4: platform,
          item_category5: 'Ticket',
          price,
          quantity: 1
        }
      ]
    }
  })
}

// Step 4a Add up-sell to basket
export const trackUpSellAddToBasket = ({
  id,
  name,
  price,
  performanceDate,
  performanceTime,
  platform,
  bookingFlow
}: TrackBasketActionItem) => {
  clearDataLayer()

  if (!name) return

  pushTag({
    module: 'ticketing',
    platform,
    pageType: 'products',
    event: 'add_to_cart',
    purchase_flow: bookingFlow,
    ecommerce: {
      currency: 'GBP',
      value: price,
      items: [
        {
          item_id: id,
          item_name: name,
          currency: 'GBP',
          item_category: `${performanceDate}/${performanceTime}`,
          item_category4: platform,
          item_category5: 'Up-Sell',
          price,
          quantity: 1
        }
      ]
    }
  })
}

// Step 4b Remove up-sell from basket
export const trackUpSellRemoveFromBasket = ({
  id,
  name,
  price,
  performanceDate,
  performanceTime,
  platform,
  bookingFlow
}: TrackBasketActionItem) => {
  clearDataLayer()

  if (!name) return

  trackAddToCart({
    id,
    name,
    dimension9: platform,
    dimension10: bookingFlow
  })

  pushTag({
    module: 'ticketing',
    platform,
    pageType: 'products',
    event: 'remove_from_cart',
    purchase_flow: bookingFlow,
    ecommerce: {
      currency: 'GBP',
      value: price,
      items: [
        {
          item_id: id,
          item_name: name,
          currency: 'GBP',
          item_category: `${performanceDate}/${performanceTime}`,
          item_category4: platform,
          item_category5: 'Up-Sell',
          price,
          quantity: 1
        }
      ]
    }
  })
}

// Step 5 Checkout Page View
export const trackCheckoutPage = (action: TrackCheckoutPagePayload) => {
  const {
    eventName,
    venueName,
    performanceDate,
    performanceTime,
    externalId,
    platform,
    bookingFlow,
    order,
    imageUrl,
    availability
  } = action

  clearDataLayer()

  // Upsell Flow
  const url = performanceDate
    ? `/event/${slugify(
        eventName
      )}/performance/${performanceDate}-${performanceTime}/checkout`
    : `/event/${slugify(eventName)}/up-sell/checkout`

  const items = order
    ? getItemsFromOrder({
        order,
        platform,
        bookingFlow,
        performanceDate,
        performanceTime
      })
    : []

  pushTag({
    module: 'ticketing',
    platform,
    event: 'begin_checkout',
    pageType: 'summary',
    showName: eventName,
    performanceVenue: venueName,
    ...(performanceDate && { performanceDate: performanceDate }),
    ...(performanceTime && { performanceTime: performanceTime }),
    externalId,
    purchase_flow: bookingFlow,
    referrer: url,
    ecommerce: {
      currency: 'GBP',
      value: order?.basket.totalPrice,
      items
    }
  })

  if (order) {
    addProductsFromOrder(
      order,
      platform,
      bookingFlow,
      performanceDate,
      performanceTime
    )
  }

  // GA
  setCheckoutAction(1)

  // Dot Mailer
  if (order) {
    trackDotMailerReservation({
      phase: 'CUSTOMER_LOGIN',
      order,
      eventName,
      imageUrl,
      availability
    })
  }
}

// Step 6 Add Payment
export const trackAddPayment = (
  action: TrackCheckoutPagePayload & { paymentType: string }
) => {
  const {
    performanceDate,
    performanceTime,
    platform,
    bookingFlow,
    order,
    paymentType = 'credit_card'
  } = action

  clearDataLayer()

  const items = order
    ? getItemsFromOrder({
        order,
        platform,
        bookingFlow,
        performanceDate,
        performanceTime
      })
    : []

  pushTag({
    event: 'add_payment_info',
    purchase_flow: bookingFlow,
    ecommerce: {
      payment_type: paymentType,
      currency: 'GBP',
      value: order?.basket.totalPrice,
      items
    }
  })
}

// Step 7 Purchase
export const trackOrderSuccess = (action: TrackOrderPagePayload) => {
  const {
    order,
    eventName,
    performanceDate,
    performanceTime,
    venueName,
    externalId,
    platform,
    bookingFlow,
    imageUrl
  } = action

  // Tag Manager
  // Needs to be pushed before the transaction or the show name doesn't exist
  clearDataLayer()
  const url = `/order/success`

  pushTag({
    module: 'ticketing',
    platform,
    event: 'pageview',
    pageType: 'order',
    showName: eventName,
    performanceVenue: venueName,
    performanceDate: performanceDate,
    performanceTime: performanceTime,
    externalId: externalId,
    referrer: url
  })

  const items = getItemsFromOrder({
    order,
    platform,
    bookingFlow,
    performanceDate,
    performanceTime
  })
  const referenceNumber = order.referenceNumber
  const orderTotal = order.basket.totalPrice
  const deliveryOptionPrice = order.basket.delivery?.price
  pushTag({
    platform,
    event: 'purchase',
    purchase_flow: bookingFlow,
    referrer: url,
    ecommerce: {
      transaction_id: referenceNumber,
      affiliation: 'Marvel',
      value: orderTotal,
      currency: 'GBP',
      tax: 0,
      shipping: deliveryOptionPrice ?? 0,
      items: items
    }
  })

  // EC Analytics
  addProductsFromOrder(
    order,
    platform,
    bookingFlow,
    performanceDate,
    performanceTime
  )

  setPurchaseAction(referenceNumber, orderTotal, deliveryOptionPrice)

  trackDotMailerReservation({
    phase: 'ORDER_COMPLETE',
    order,
    eventName,
    imageUrl
  })
}

// Step 3 Pre Purchase Products
export const trackPrePurchaseProductView = (
  action: TrackPerformancePagePayload
) => {
  const {
    eventName,
    venueName,
    performanceId,
    eventId,
    performanceDate,
    performanceTime,
    externalId,
    platform,
    contextId = '',
    imageUrl,
    availability
  } = action

  const url = `/event/${slugify(
    eventName
  )}/performance/${performanceDate}-${performanceTime}/products`

  pushTag({
    module: 'ticketing',
    platform,
    event: 'pageview',
    pageType: 'products',
    showName: eventName,
    performanceVenue: venueName,
    performanceDate: performanceDate,
    performanceTime: performanceTime,
    externalId: externalId,
    referrer: url
  })

  // Dot Mailer
  trackDotMailerCart({
    phase: 'CUSTOMER_LOGIN',
    eventId,
    performanceId,
    eventName,
    venueName,
    performanceDate,
    performanceTime,
    contextId,
    imageUrl,
    availability
  })
}

export const trackManageOrder = () => {
  const url = `/order/manage`

  ReactGA.pageview(url)
}

// Track Up-sell Products
export const trackUpSellProduct = (action: TrackBasketActionItem) => {
  const { id, name, platform, bookingFlow } = action

  if (!name) return

  addImpression({
    id,
    name,
    dimension9: platform,
    dimension10: bookingFlow
  })
}

// Exchange View
export const trackExchangeView = (action: { eventName: string }) => {
  const { eventName } = action
  const url = `/exchange/${slugify(eventName)}`

  // Analytics
  ReactGA.pageview(url)
}

//Dot Mailer
export const identifyCustomer = (email: string) => {
  DotMailer.identify(email)
}

const addProductsFromOrder = (
  order: Order,
  platform: Platform,
  bookingFlow: BookingFlow,
  performanceDate?: string,
  performanceTime?: string
) => {
  const reservations = [
    ...order.basket.ticketReservations,
    ...order.basket.productReservations
  ]
  const ticketItemsByVariant = reservations.reduce(
    (products: { [id: string]: ECAddProduct }, product: OrderProductItem) => {
      const {
        eventId,
        performanceId,
        areaName,
        priceToPay,
        priceTypeName,
        priceLevelName,
        name
      } = product

      const productId = `${eventId}-${performanceId}-${areaName}-${priceTypeName}-${priceLevelName}`
      return {
        ...products,
        [productId]: {
          id: eventId,
          name: name,
          variant: `${areaName}-${priceTypeName}-${priceLevelName}`,
          price: priceToPay,
          ...(performanceDate && {
            category: `${performanceDate}/${performanceTime}`
          }),
          quantity:
            (products[productId] ? products[productId]?.quantity ?? 0 : 0) + 1,
          dimension3: areaName,
          dimension4: priceLevelName,
          dimension9: platform,
          dimension10: bookingFlow
        }
      }
    },
    {}
  )
  Object.values(ticketItemsByVariant).forEach(product => {
    addProduct(product)
  })
}

type DotMailerCart = {
  phase: CartPhase
  eventId: string
  performanceId: string
  eventName: string
  venueName: string
  imageUrl: string
  performanceDate: string
  performanceTime: string
  contextId: string
  availability?: number
}
const trackDotMailerCart = ({
  phase,
  eventId,
  performanceId,
  eventName,
  venueName,
  imageUrl,
  performanceDate,
  performanceTime,
  contextId,
  availability
}: DotMailerCart) => {
  DotMailer.trackCart({
    cartDelay: CART_DELAY,
    cartPhase: phase,
    currency: 'GBP',
    cartID: contextId,
    grandTotal: 0,
    cartUrl: `${window.location.origin}/event/${eventId}/performance/${performanceId}`,
    lineItems: [
      {
        sku: performanceId,
        name: eventName,
        description: `${eventName} at ${venueName} on ${performanceDate} at ${performanceTime}`,
        category: `${performanceDate} > ${performanceTime}`,
        other: {
          date: performanceDate,
          time: performanceTime,
          availability: availability ? `${availability}` : 'N/A',
          show_date: moment(
            `${performanceDate} ${performanceTime}`
          ).toISOString(),
          show_availability: availability ?? 0
        },
        productUrl: `${window.location.origin}/event/${eventId}/performance/${performanceId}`,
        unitPrice: 0,
        totalPrice: 0,
        imageUrl: imageUrl
      }
    ]
  })
}

type DotMailerReservation = {
  phase: CartPhase
  order: Order
  eventName: string
  imageUrl: string
  availability?: number
}
const trackDotMailerReservation = ({
  phase,
  order,
  eventName,
  imageUrl,
  availability
}: DotMailerReservation) => {
  const reservations = [
    ...order.basket.ticketReservations,
    ...order.basket.productReservations
  ]
  const { eventId, performanceId } = reservations[0]

  const orderItems = reservations.reduce(
    (products: { [id: string]: CartLineItem }, product: OrderProductItem) => {
      const {
        eventId,
        performanceId,
        formattedDate,
        startDate,
        areaName,
        priceToPay,
        priceTypeName,
        priceLevelName,
        name
      } = product

      const productId = `${eventId}-${performanceId}-${areaName}-${priceTypeName}-${priceLevelName}`
      return {
        ...products,
        [productId]: {
          sku: performanceId,
          name: name,
          description: `${eventName} - ${areaName} on ${formattedDate}`,
          quantity:
            (products[productId] ? products[productId]?.quantity ?? 0 : 0) + 1,
          totalPrice:
            (products[productId] ? products[productId]?.totalPrice ?? 0 : 0) +
            priceToPay,
          unitPrice: priceToPay,
          category: `${startDate}`,
          other: {
            date: startDate ?? '',
            availability: availability ? `${availability}` : 'N/A',
            show_date: startDate ? moment(startDate).toISOString() : '',
            show_availability: availability ?? 0
          },
          productUrl: `${window.location.origin}/event/${eventId}/performance/${performanceId}`,
          imageUrl: imageUrl
        }
      }
    },
    {}
  )
  DotMailer.trackCart({
    cartDelay: CART_DELAY,
    cartID: order.transactionGuid,
    cartPhase: phase,
    currency: 'GBP',
    grandTotal: order.basket.totalPrice,
    cartUrl: `${window.location.origin}/event/${eventId}/performance/${performanceId}`,
    lineItems: Object.values(orderItems) || []
  })
}
