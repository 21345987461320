import React from 'react'

// Components
import { SeatList } from './SeatList'

// Types
import { SeatLineItem, SeatListByArea, TicketWithSeat } from 'shared-types'

type Props = {
  seatsByArea: SeatListByArea
  onSeatClick?: (seat: SeatLineItem) => void
  onRemoveSeatClick?: (seat: TicketWithSeat) => void
}
const BasketSeatItems: React.FC<Props> = ({
  seatsByArea,
  onSeatClick,
  onRemoveSeatClick
}) => {
  return (
    <>
      {Object.keys(seatsByArea).map((areaId) => {
        const seatLineItems = seatsByArea[areaId]
        return (
          <SeatList
            key={areaId}
            areaTitle={areaId}
            seatLineItems={seatLineItems}
            onRemoveSeatClick={onRemoveSeatClick}
            onSeatClick={onSeatClick}
          />
        )
      })}
    </>
  )
}

export { BasketSeatItems }
