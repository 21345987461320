import React from 'react'
import styled from 'styled-components/macro'

// Selectors
import { selectReservationGuid } from 'modules/basket/selectors'

// Actions
import { clearReservation } from 'modules/basket/actions'
import { closeModal } from 'modules/overlay/actions'

// Components
import { Title, Button, CopyBlock } from 'marvel-components'

// Utils
import { selectTicketsRoute, media } from 'utils'

// Misc
import { useHistory } from 'react-router'
import { useSelector, useDispatch } from 'react-redux'

const ConfirmClearBasketOverlayWrapper = styled.div`
  padding: 1rem;
  max-width: 23.75rem;

  ${media.tablet} {
    width: 70vw;
  }
`

const StyledButton = styled(Button)`
  margin-top: 1rem;
`

export interface ConfirmClearBasketOverlayContainerProps {
  eventId: string
  performanceId: string
}

const ConfirmClearBasketOverlay: React.FC<ConfirmClearBasketOverlayContainerProps> = ({
  eventId,
  performanceId
}) => {
  const dispatch = useDispatch()
  const history = useHistory()

  // Selectors
  const reservationGuid = useSelector(selectReservationGuid)

  const handleConfirm = () => {
    // Clear selected Seats
    dispatch(clearReservation(reservationGuid))

    // Change Performance
    history.push(selectTicketsRoute(eventId, performanceId))

    // Close Overlay
    dispatch(closeModal())
  }

  const handleCancel = () => {
    dispatch(closeModal())
  }

  return (
    <ConfirmClearBasketOverlayWrapper>
      <Title size='large'>Empty Basket</Title>
      <CopyBlock isCentralised={true}>
        Changing performance will empty your basket. Do you wish to continue?
      </CopyBlock>
      <StyledButton
        text='Confirm'
        onClick={() => handleConfirm()}
        isBlock={true}
        icon='check'
      />
      <StyledButton
        text='Cancel'
        onClick={() => handleCancel()}
        isBlock={true}
        icon='times'
        buttonType='danger'
        size='small'
      />
    </ConfirmClearBasketOverlayWrapper>
  )
}

export { ConfirmClearBasketOverlay }
