import backgroundAsset from './assets/peter_pan_sidebar.jpg'
export const PeterPan = {
  colors: {
    mainBackgroundColor: '#00020e',
    bestAvailablePerformanceColor: '#f7ce51',
    bestAvailablePerformanceColorComplement: '#111111',
    mainColor: '#b6b6b6',
    fadedHighlightColor: '#f1f1f1',
    mainColorComplement: '#111111',
    lwLogoSlashColor: '#ffffff',
    lwBrandColor: '#ffffff',
    lwBrandColorComplement: '#111111',
    numberFilterSelectorColor: '#ffffff',
    numberFilterSelectorCopyColor: '#111111',
    priceSliderHandleColor: '#fcaf17',
    bestAvailableBannerColor: '#0283c3',
    bestAvailableBannerColorComplement: '#ffffff',
    sidebarCopyColor: '#ffffff',
    performanceHoverColor: '#ffd787'
  },
  themeBackgroundColour: '#00020e',
  themeBackgroundAsset: backgroundAsset
}
