// Components
import { WithLoader } from 'marvel-components'

// Containers
import { AuthenticationContainer } from 'containers/Kiosk'

// Actions
import { kioskActions } from 'modules/self-serve/kiosk/kioskSlice'
import { terminalActions } from 'modules/self-serve/terminal/terminalSlice'

// Selectors
import { selectIsKioskLoading } from 'modules/self-serve/kiosk/selectors'
import { selectIsTerminalLoading } from 'modules/self-serve/terminal/selectors'

// misc
import React, { useEffect } from 'react'
import styled from 'styled-components'
import { useAppDispatch } from 'app/reduxStore'
import { useSelector } from 'react-redux'

const AuthenticationWrapper = WithLoader(styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
`)

const AuthenticationView: React.FC = () => {
  const dispatch = useAppDispatch()
  const isKioskLoading = useSelector(selectIsKioskLoading)
  const isTerminalLoading = useSelector(selectIsTerminalLoading)

  useEffect(() => {
    dispatch(kioskActions.fetchKiosks())
  }, [dispatch])

  useEffect(() => {
    dispatch(terminalActions.fetchTerminals())
  }, [dispatch])

  return (
    <AuthenticationWrapper isLoading={isKioskLoading || isTerminalLoading}>
      <AuthenticationContainer />
    </AuthenticationWrapper>
  )
}

export { AuthenticationView }
