import styled, { css } from 'styled-components/macro'
import { lighten } from 'utils'

export const Input = styled.input<{
  isDisabled?: boolean
  mobileNarrow?: boolean
}>((props) => {
  const {
    theme: {
      colors: { copyColor },
      font: { input }
    },
    isDisabled = false,
    mobileNarrow = false
  } = props

  return css`
    border-radius: 0.125rem;
    border: none;
    font-size: 1rem;
    line-height: normal;
    padding: 0.5rem;
    outline: none;
    font-family: ${input};
    flex: 1 0 auto;
    background: #fff;
    color: ${copyColor};
    box-sizing: border-box;
    width: ${mobileNarrow ? 'calc(100% - 20px)' : 'initial'};

    ::placeholder {
      color: ${lighten(copyColor, 40)};
      ${isDisabled &&
      css`
        opacity: 0.4;
      `};
    }

    :-webkit-autofill,
    :-webkit-autofill:hover,
    :-webkit-autofill:focus {
      -webkit-box-shadow: 0 0 0 50px white inset;
      -webkit-text-fill-color: ${copyColor};
    }
    :read-only {
      color: ${lighten(copyColor, 40)};
    }
  `
})
