// Components
import {
  Button,
  FormInput,
  FormLayout,
  FormSelect,
  SelectOptionValue,
  Title
} from 'marvel-components'

// Misc
import { Formik } from 'formik'
import styled from 'styled-components'
import { FC } from 'react'
import { Kiosk, Terminal } from 'shared-types'

const KioskFormLayout = styled(FormLayout)`
  display: flex;
  flex-direction: column;
  border: 1px solid #fff;
  border-radius: 8px;
  padding: 1rem 2rem 2rem 2rem;
`

const KioskTitle = styled(Title)`
  margin-bottom: 1rem;
`

type KioskFormSelectOption = {
  value: number
  label: string
}

export type KioskFormValues = {
  terminal?: KioskFormSelectOption
  kiosk?: KioskFormSelectOption
  secret: string
}

type Props = {
  kiosks: Kiosk[]
  terminals: Terminal[]
  onSubmit: (
    values: KioskFormValues,
    setSubmitting: (isSubmitting: boolean) => void
  ) => void
  onKioskSelect?: (id: number) => void
}

export const FormKioskAuthentication: FC<Props> = ({
  onSubmit,
  onKioskSelect,
  kiosks,
  terminals
}) => {
  return (
    <Formik
      initialValues={{
        terminal: undefined,
        kiosk: undefined,
        secret: ''
      }}
      onSubmit={(values, { setSubmitting }) => onSubmit(values, setSubmitting)}
    >
      {({ isSubmitting, values }) => (
        <KioskFormLayout>
          <KioskTitle size='large' isCentralised={true} isSidebar={true}>
            Self-Serve
          </KioskTitle>
          <FormSelect
            name='kiosk'
            type='text'
            placeholder='Kiosk'
            options={kiosks.map(kiosk => ({
              value: kiosk.id,
              label: kiosk.name
            }))}
            onOptionSelect={(value: SelectOptionValue) =>
              onKioskSelect && onKioskSelect(value?.value)
            }
          />
          <FormSelect
            name='terminal'
            type='text'
            placeholder='Terminal'
            options={terminals.map(terminal => ({
              value: terminal.id,
              label: terminal.name
            }))}
          />
          <FormInput
            name='secret'
            type='password'
            placeholder='Secret'
            autocomplete='password'
          />
          <Button
            type='submit'
            isBlock={true}
            text='Submit'
            size='small'
            isLoading={isSubmitting}
            buttonType='light'
          />
        </KioskFormLayout>
      )}
    </Formik>
  )
}
