// Misc
import { createSelector } from '@reduxjs/toolkit'

// Types
import { AppState } from 'modules/types'

// Adapters
import { productsAdapter } from './reducers'

export const {
  selectById,
  selectIds,
  selectEntities,
  selectAll,
  selectTotal
} = productsAdapter.getSelectors<AppState>(
  (state) => state.smartWaiter.products
)

export const isLoading = (state: AppState) =>
  state.smartWaiter.products.loading === 'pending'

export const selectProductsForChoiceId = (choiceId: React.ReactText) =>
  createSelector(
    [
      (state: AppState) => selectById(state, choiceId),
      (state: AppState) => selectAll(state)
    ],
    (choice, products) => {
      if (choice) {
        const { choices = [] } = choice
        return products.filter((item) => choices.includes(item.id))
      }
      return []
    }
  )
