import React from 'react'

// Actions
import { basketActions } from 'modules/basket'

// Selectors
import { basketSelectors } from 'modules/basket'
import { selectActivePerformanceDate } from 'modules/ticketing/performance/selectors'
import { eventDetailsSelectors } from 'modules/ticketing/event'

// Components
import { TicketItem } from 'marvel-components'

// Misc
import { useDispatch, useSelector } from 'react-redux'
import { useTypedSelector } from 'modules'
import {
  usePlatform,
  trackAddToBasketTicket,
  trackRemoveFromBasketTicket
} from 'utils'
import moment from 'moment'

type Props = {
  priceLevelId: string
  priceTypeId: string
  performanceId: string
  areaId: string
  price: number
  name: string
  capacityAvailable: number
}

const TicketItemContainer: React.FC<Props> = ({
  priceLevelId,
  priceTypeId,
  performanceId,
  areaId,
  price,
  name,
  capacityAvailable
}) => {
  // Actions
  const dispatch = useDispatch()

  const bookingFlow = useSelector(basketSelectors.selectBookingFlow)
  const eventName = useSelector(eventDetailsSelectors.selectEventName)
  const performanceDate = useSelector(selectActivePerformanceDate)
  const eventId = useSelector(eventDetailsSelectors.selectEventDetailsId)
  const platform = usePlatform()

  const handleIncrement = () => {
    dispatch(
      basketActions.addTicket({
        performanceId,
        areaId,
        priceLevelId,
        priceTypeId
      })
    )
    const date = moment.utc(performanceDate)
    trackAddToBasketTicket({
      id: eventId,
      name: eventName,
      price: price,
      performanceDate: date.format('YYYY-MM-DD'),
      performanceTime: date.format('H-mm'),
      platform,
      bookingFlow
    })
  }

  const handleDecrement = () => {
    dispatch(
      basketActions.removeTicket({
        performanceId,
        areaId,
        priceLevelId,
        priceTypeId
      })
    )
    const date = moment.utc(performanceDate)
    trackRemoveFromBasketTicket({
      id: eventId,
      name: eventName,
      price: price,
      performanceDate: date.format('YYYY-MM-DD'),
      performanceTime: date.format('H-mm'),
      platform,
      bookingFlow
    })
  }

  // Selectors
  const totalSelected = useTypedSelector(state =>
    basketSelectors.selectQuantityInBasket(state, {
      performanceId,
      areaId,
      priceLevelId,
      priceTypeId
    })
  )

  return (
    <TicketItem
      name={name}
      price={price}
      totalSelected={totalSelected}
      maxQuantity={capacityAvailable + totalSelected}
      isSoldOut={capacityAvailable + totalSelected === 0}
      onIncrement={handleIncrement}
      onDecrement={handleDecrement}
    />
  )
}

export { TicketItemContainer }
