// Misc
import { useSelector } from 'react-redux'
import { AppState } from 'modules'
import styled from 'styled-components'

// Components
import { Spotlight } from 'marvel-components'

// Selectors
import { eventSelectors } from 'modules/self-serve/event'
import { eventCollectionSelectors } from 'modules/self-serve/event-collection'
import { venueSelectors } from 'modules/self-serve/venue'
import { useHistory } from 'react-router-dom'
import { calendarRoute } from 'utils'

const StyledSpotlight = styled(Spotlight)`
  height: 600px;
`

type Props = {
  eventCollectionId: string
}

const EventSpotlightContainer: React.FC<Props> = ({ eventCollectionId }) => {
  const history = useHistory()

  const eventCollection = useSelector((state: AppState) =>
    eventCollectionSelectors.selectEventCollection(
      state.selfServe.eventCollection,
      eventCollectionId
    )
  )
  const events = useSelector(eventSelectors.selectEventEntities)
  const venues = useSelector(venueSelectors.selectVenueEntities)

  if (!eventCollection) {
    return null
  }

  const viewEventDetails = (eventId: string) => {
    history.push(calendarRoute(eventId))
  }

  return (
    <StyledSpotlight
      slides={eventCollection.events
        .filter(id => {
          const event = events[id]
          const venue = event?.venue && venues[event.venue]
          return event && venue
        })
        .map(id => {
          const event = events[id]!
          const venue = venues[event.venue]!
          return {
            imageUrl: event.image,
            title: event.title,
            description: venue.title,
            onClick: () => {
              viewEventDetails(event.externalId)
            }
          }
        })}
    />
  )
}

export { EventSpotlightContainer }
