import React from 'react'
import styled from 'styled-components/macro'

const LogoImage = styled.img`
  width: 8rem;
`

const LogoButton = styled.button`
  outline: none;
  background: none;
  border: none;
  cursor: pointer;
`

export const StarLogo = () => {
  const verifyStar = () => {
    window.open(
      'http://www.star.org.uk/verify?dn=http://www.lwtheatres.co.uk',
      'sharer',
      'toolbar=no,directories=no,status=no,menubar=no,scrollbars=no,resizable=no,width=560,height=490'
    )
  }

  return (
    <LogoButton onClick={() => verifyStar()}>
      <LogoImage
        src="https://www.star.org.uk/wp-content/uploads/2017/11/star_verification.png"
        alt="Verfied star member"
      />
    </LogoButton>
  )
}
