import React from 'react'

// Components
import { Button } from '../Button'

// Misc
import styled from 'styled-components/macro'
import { media } from 'utils'

interface Props {
  onBackClick: () => void
}

const BackButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 1.5rem;
  ${media.tablet} {
    margin-bottom: 0;
    padding: 1rem;
    background: ${props => props.theme.colors.secondaryBackgroundColor};
  }
`

export const BackButton: React.FC<Props> = ({ onBackClick }) => {
  return (
    <BackButtonWrapper>
      <Button
        text="Back"
        buttonType="link-button"
        icon="arrow-left"
        onClick={onBackClick}
      />
    </BackButtonWrapper>
  )
}
