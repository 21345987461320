import React from 'react'
import styled from 'styled-components/macro'
import { Form, Formik } from 'formik'

// Components
import { FormWrapper, Button, Title, FormSelect } from 'marvel-components'

import { SelectTimeField } from './SelectTimeField'

import * as Yup from 'yup'

const FormSection = styled.div`
  margin-top: 2rem;
`

const StyledTitle = styled(Title)`
  margin-bottom: 0.5rem;
`

const ButtonWrapper = styled.div`
  margin-top: 2rem;
`

type SelectMenuFormProps = {
  onSubmit: (value: SelectMenuFormValues) => void
  eventTimes: { value: string; label: string }[]
  areas: { value: number; label: string; id: number }[]
}

export interface SelectMenuFormValues {
  performanceTime?: { value: string; label: string }
  seatArea?: { value: number; label: string; id: number }
}

const SelectMenuForm = ({
  onSubmit,
  eventTimes,
  areas
}: SelectMenuFormProps) => {
  return (
    <Formik
      initialValues={{ performanceTime: undefined, seatArea: undefined }}
      onSubmit={onSubmit}
      validationSchema={Yup.object().shape({
        performanceTime: Yup.object().required(
          'Please Select your performance time'
        ),
        seatArea: Yup.object().required('Please select your seating area')
      })}
    >
      <Form>
        <FormWrapper>
          <SelectTimeField eventTimes={eventTimes} name={'performanceTime'} />
          <FormSection>
            <StyledTitle
              isCapitalised={false}
              isCentralised={true}
              isBold={false}
              size='medium'
            >
              Where are you sitting?
            </StyledTitle>
            <FormSelect
              name='seatArea'
              placeholder='Select your seat area'
              options={areas}
            />
          </FormSection>
          <ButtonWrapper>
            <Button type='submit' isBlock={true} text='See Menu' />
          </ButtonWrapper>
        </FormWrapper>
      </Form>
    </Formik>
  )
}

export { SelectMenuForm }
