import {
  createEntityAdapter,
  createSlice,
  PayloadAction
} from '@reduxjs/toolkit'
import { PriceLevel } from 'shared-types'
import { fetchPerformance } from './actions'

export const priceLevelAdapter = createEntityAdapter<PriceLevel>()

type AdditionalState = {
  filteredPriceLevels: string[]
}

const initialState = priceLevelAdapter.getInitialState<AdditionalState>({
  filteredPriceLevels: []
})

const priceLevelSlice = createSlice({
  name: 'priceLevel',
  initialState,
  reducers: {
    addPriceLevelFilters: (state, action: PayloadAction<string[]>) => {
      state.filteredPriceLevels = [
        ...state.filteredPriceLevels,
        ...action.payload
      ]
    },
    removePriceLevelFilters: (state, action: PayloadAction<string[]>) => {
      state.filteredPriceLevels = state.filteredPriceLevels.filter(
        (priceLevelId) => !action.payload.includes(priceLevelId)
      )
    },
    setPriceLevelFilters: (state, action: PayloadAction<string[]>) => {
      state.filteredPriceLevels = action.payload
    },
    clearPriceLevelFilters: (state) => {
      state.filteredPriceLevels = []
    }
  },
  extraReducers: (builder) => {
    builder.addCase(fetchPerformance.fulfilled, (state, action) => {
      action.payload.data.priceLevels &&
        priceLevelAdapter.setAll(state, action.payload.data.priceLevels)
    })
  }
})

export const priceLevelActions = {
  ...priceLevelSlice.actions
}

export default priceLevelSlice.reducer
