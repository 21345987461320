import React, { useEffect } from 'react'

// Components
import { Layout } from 'marvel-components'

// Containers
import { BrandLogoContainer, SelectMenuContainer } from 'containers/SmartWaiter'

// Views
import { SalesAreaView, OrderSuccessView } from 'views/SmartWaiter'

// Actions
import { venueActions } from 'modules/smart-waiter/venue/reducers'

// Misc
import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom'
import { useAppDispatch } from 'app/reduxStore'
import { trackSmartWaiterVenue, usePlatform } from 'utils'
import { venueSelectors } from 'modules/smart-waiter/venue'
import { useTypedSelector } from 'modules'

const VenueView: React.FC = () => {
  // Routing
  const {
    path,
    params: { venueId }
  } = useRouteMatch<{ venueId: string }>()

  const dispatch = useAppDispatch()
  const platform = usePlatform()

  // Selectors
  const venueName = useTypedSelector(state =>
    venueSelectors.selectVenueName(state, venueId)
  )

  // Actions
  useEffect(() => {
    dispatch(venueActions.fetchAreasForVenue(venueId))
    dispatch(venueActions.selectVenue(venueId))
  }, [dispatch, venueId])

  // Analytics
  useEffect(() => {
    if (venueName) {
      trackSmartWaiterVenue({
        venueName
      })
    }
  }, [platform, venueName])

  return (
    <Switch>
      <Route exact path={`${path}`}>
        <Layout
          pageTitle={'Select Menu | LW Theatres'}
          renderBrandLogo={<BrandLogoContainer />}
          renderMain={<SelectMenuContainer />}
        />
      </Route>
      <Route
        path={[
          `${path}/area/:salesAreaId/order/:orderId`,
          `${path}/area/:salesAreaId/order`
        ]}
      >
        <OrderSuccessView />
      </Route>
      <Route path={`${path}/area/:salesAreaId`}>
        <SalesAreaView />
      </Route>
      <Route path={`${path}`}>
        <Redirect to={`/smart-waiter/venue/${venueId}`} />
      </Route>
    </Switch>
  )
}

export { VenueView }
