import React from 'react'
import styled from 'styled-components'

//Types
import { SelectPerformanceViewTypes } from 'shared-types'

// Components
import { DateNavigator, PerformanceToggle } from 'marvel-components'

// Misc
import { media } from 'utils'

interface Props {
  rightButton?: React.ReactNode
  // View
  selectedView: SelectPerformanceViewTypes
  onViewChange: (view: 'list' | 'calendar') => void

  // Date Picker
  showPreviousButton: boolean
  showNextButton: boolean
  onPreviousClick(callback?: () => void): void
  onNextClick(callback?: () => void): void
  month?: Date
}

const PerformanceSelectorHeaderWrapper = styled.div`
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 5rem;
  z-index: 1;
  pointer-events: none;

  ${media.tablet} {
    position: sticky;
    height: 3rem;
  }
`

const HeaderSpacer = styled.div`
  flex: 1 0 0;
  width: 22.4rem;
  min-width: 22.4rem;
  max-width: 22.4rem;

  ${media.tablet} {
    display: none;
  }
`

const HeaderContent = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  position: relative;
  align-items: center;
  padding: 0 1.25rem;
  justify-content: space-between;
  pointer-events: auto;

  :before {
    content: '';
    background: #fff;
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    opacity: 0.8;
    z-index: -1;
  }

  ${media.narrowdesktop} {
    justify-content: space-between;
  }

  ${media.tablet} {
    justify-content: center;
  }
`

const HeaderContentBlock = styled.div`
  width: 50%;
  max-width: 50%;
  min-width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;

  :first-child {
    justify-content: flex-start;
    width: 25%;
    max-width: 25%;
    min-width: 25%;
  }

  :last-child {
    justify-content: flex-end;
    width: 25%;
    max-width: 25%;
    min-width: 25%;
  }

  ${media.narrowdesktop} {
    width: 38%;
    max-width: 38%;
    min-width: 38%;

    :first-child {
      justify-content: flex-start;
      width: 33.3%;
      max-width: 33.3%;
      min-width: 33.3%;
    }

    :last-child {
      justify-content: flex-end;
      width: 15%;
      max-width: 15%;
      min-width: 15%;
    }
  }

  ${media.tablet} {
    background: none;
    width: 100%;
    max-width: 100%;
    min-width: 100%;
    :first-child {
      justify-content: flex-start;
      width: 25%;
      max-width: 25%;
      min-width: 25%;
    }

    :last-child {
      justify-content: flex-end;
      width: 25%;
      max-width: 25%;
      min-width: 25%;
    }
  }
`

const RightButtonWrapper = styled.div`
  ${media.tablet} {
    display: none;
  }
`
const PerformanceToggleWrapper = styled.div`
  /* width: 100%; */
  ${media.tablet} {
    display: none;
  }
`

const PerformanceSelectorHeader: React.FC<Props> = ({
  rightButton,
  onViewChange,
  selectedView,
  // Date Picker
  showPreviousButton,
  showNextButton,
  onPreviousClick,
  onNextClick,
  month
}) => {
  return (
    <PerformanceSelectorHeaderWrapper>
      <HeaderSpacer />
      <HeaderContent>
        <HeaderContentBlock>
          <PerformanceToggleWrapper>
            <PerformanceToggle
              onViewChange={onViewChange}
              selectedView={selectedView}
            />
          </PerformanceToggleWrapper>
        </HeaderContentBlock>

        <HeaderContentBlock>
          <DateNavigator
            showPreviousButton={showPreviousButton}
            showNextButton={showNextButton}
            onPreviousClick={onPreviousClick}
            onNextClick={onNextClick}
            month={month}
          />
          {/* <AvailabilityLegend /> */}
        </HeaderContentBlock>
        {rightButton && (
          <HeaderContentBlock>
            <RightButtonWrapper>{rightButton}</RightButtonWrapper>
          </HeaderContentBlock>
        )}
      </HeaderContent>
    </PerformanceSelectorHeaderWrapper>
  )
}

export { PerformanceSelectorHeader }
