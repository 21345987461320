import React from 'react'

// Selectors
import { eventDetailsSelectors } from 'modules/ticketing/event'
import { selectTransactionState } from 'modules/basket/selectors'

// Actions
import { bannerActions } from 'modules/banner'
import { fetchOrder, fetchReservation } from 'modules/basket/actions'

// Components
import { FooterNav, Layout } from 'marvel-components'

// Containers
import {
  BrandLogoContainer,
  TransactionSummaryContainer
} from 'containers/Ticketing'
import { KioskPaymentContainer } from 'containers/Kiosk'

// Misc
import { useSelector, useDispatch } from 'react-redux'
import { useParams, Redirect } from 'react-router-dom'

// Utils
import { checkoutRoute } from 'utils'

type Props = {
  eventId: string
}
const KioskPaymentView: React.FC<Props> = ({ eventId }) => {
  // Routing
  const { transactionGuid } = useParams<{ transactionGuid: string }>()

  // Actions
  const dispatch = useDispatch()

  // Selectors
  const eventName = useSelector(eventDetailsSelectors.selectEventName)
  const transactionState = useSelector(selectTransactionState)

  // Actions
  const handleKioskPaymentComplete = () => {
    dispatch(
      fetchOrder({
        transactionGuid
      })
    )
  }

  const handleKioskPaymentFailure = () => {
    dispatch(
      bannerActions.setBannerContent({
        bannerType: 'error',
        title: 'There was an error with your payment',
        text: `It was not possible to process your payment, please try again`
      })
    )
    dispatch(
      fetchReservation({
        transactionGuid
      })
    )
  }

  if (transactionState === 'Created') {
    return <Redirect to={checkoutRoute(eventId, transactionGuid)} />
  }

  return (
    <Layout
      pageTitle={eventName && `Confirm and Book - ${eventName} | LW Theatres`}
      renderBrandLogo={<BrandLogoContainer />}
      renderSidebarTop={<TransactionSummaryContainer />}
      renderSidebarBottom={<FooterNav />}
      renderMain={
        <KioskPaymentContainer
          onPaymentComplete={handleKioskPaymentComplete}
          onPaymentFailure={handleKioskPaymentFailure}
        />
      }
    />
  )
}
export { KioskPaymentView }
