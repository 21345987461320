import { createAction } from 'redux-actions'
import { createRoutine } from 'redux-saga-routines'
import {
  SET_DISCOUNT_CODE,
  SET_PROMO_CODE,
  FETCH_DISCOUNTED_PERFORMANCE,
  SET_LOTTERY_TOKEN
} from './types'

export const setDiscountCode = createAction(SET_DISCOUNT_CODE)
export const setPromoCode = createAction(SET_PROMO_CODE)
export const setLotteryToken = createAction(SET_LOTTERY_TOKEN)

export const fetchDiscountedPerformance = createRoutine(
  FETCH_DISCOUNTED_PERFORMANCE
)
