// Containers
import { KioskPaymentContainer } from 'containers'
import {
  BrandLogoContainer,
  CheckoutContainer,
  OrderSummaryContainer
} from 'containers/Hospitality'

// Components
import { FooterNav, Layout } from 'marvel-components'

// Actions
import { bannerActions } from 'modules/banner'
import { orderActions, orderSelectors } from 'modules/hospitality/order'

// Misc
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Redirect, useRouteMatch } from 'react-router-dom'
import { usePlatform } from 'utils'

const PaymentView = () => {
  const dispatch = useDispatch()
  const platform = usePlatform()

  // Routing
  const {
    params: { siteId, orderId }
  } = useRouteMatch<{
    siteId: string
    orderId: string
  }>()

  const order = useSelector(orderSelectors.selectOrder)

  const handleKioskPaymentComplete = () => {
    dispatch(orderActions.fetchOrder(orderId))
  }

  const handleKioskPaymentFailure = () => {
    dispatch(
      bannerActions.setBannerContent({
        bannerType: 'error',
        title: 'There was an error with your payment',
        text: `It was not possible to process your payment, please try again`
      })
    )
  }

  // Actions
  if (order && order.status === 'PAID') {
    return (
      <Redirect to={`/hospitality/site/${siteId}/order/${order.event.id}`} />
    )
  }

  return (
    <>
      {platform === 'kiosk' ? (
        <Layout
          pageTitle={`Confirm and Pay - Hospitality | LW Theatres`}
          renderBrandLogo={<BrandLogoContainer />}
          renderSidebarTop={<OrderSummaryContainer hideOnMobile={false} />}
          renderSidebarBottom={<FooterNav />}
          renderMain={
            <KioskPaymentContainer
              onPaymentComplete={handleKioskPaymentComplete}
              onPaymentFailure={handleKioskPaymentFailure}
            />
          }
        />
      ) : (
        <Layout
          pageTitle={`Order Payment - Hospitality | LW Theatres`}
          renderBrandLogo={<BrandLogoContainer />}
          renderSidebarTop={<OrderSummaryContainer />}
          renderSidebarBottom={<FooterNav />}
          renderMainTop={<CheckoutContainer siteId={siteId} />}
        />
      )}
    </>
  )
}

export { PaymentView }
