import React, { useState } from 'react'
import styled from 'styled-components/macro'

// Components
import { Button, Calendar, Title, DateNavigator } from 'marvel-components'

// Types
import { Performance } from 'shared-types'
import { NavbarElementProps } from 'react-day-picker'

// Misc
import { media, lighten, darken, useIsNarrowViewport, usePlatform } from 'utils'
import moment from 'moment'

interface Props {
  selectedPerformanceId: string | null
  onChangePerformance: (performanceId: string) => void
  data: Performance[]
  selectedPerformanceDate?: Date
  isDisabled?: boolean
}

const CalendarWrapper = styled.div`
  position: absolute;
  top: 6rem;
  left: 0;
  background: #ffffff;
  padding: 2%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  z-index: 10;
  margin: 0 2%;
  width: 92%;
  filter: drop-shadow(0 0 0.75rem #000);

  ${media.tablet} {
    top: 3rem;
  }

  :before {
    content: '';
    position: absolute;
    background: #ffffff;
    width: 10px;
    height: 10px;
    left: 47%;
    margin-left: 5px;
    top: -5px;
    transform: rotate(45deg);
  }
`

const DatePickerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 0.25rem;
  position: relative;

  ${media.tablet} {
    ${Title} {
      display: none;
    }
  }
`

const DateSelectorWrapper = styled.div`
  display: flex;
  width: 100%;
  min-height: 2.25rem;
  background: ${props => props.theme.colors.mainColor};
  border-right: 1px solid
    ${props => darken(props.theme.colors.mainBackgroundColor, 5)};
  box-sizing: border-box;

  ${media.tablet} {
    border-right: none;
    border-bottom: 1px solid
      ${props => lighten(props.theme.colors.mainBackgroundColor, 50)};
  }
`

const StyledButton = styled(Button)`
  border-radius: initial;

  :first-child {
    border-right: 1px solid ${props => darken(props.theme.colors.mainColor, 20)};
    ${media.tablet} {
      border-right: 1px solid
        ${props => darken(props.theme.colors.mainColor, 10)};
    }
  }

  :last-child {
    border-left: 1px solid ${props => darken(props.theme.colors.mainColor, 20)};
    ${media.tablet} {
      border-left: 1px solid
        ${props => darken(props.theme.colors.mainColor, 10)};
    }
  }
`

const DatePickerBackground = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1;
`

const SelectedDateButton = styled(Button)`
  border-radius: 0;
  text-transform: none;
  background: ${props => props.theme.colors.mainColor};
`

const CloseButton = styled(Button)`
  border-radius: 0;
  text-transform: uppercase;
  right: 0;
  margin-top: 0.5rem;
`

export const DatePicker = (props: Props) => {
  const {
    selectedPerformanceId,
    data,
    onChangePerformance,
    isDisabled = false,
    selectedPerformanceDate
  } = props
  const [isCalendarVisible, setCalendarVisible] = useState(false)

  const platform = usePlatform()
  const isNarrowViewport = useIsNarrowViewport()

  if (selectedPerformanceId === null) return <DatePickerWrapper />

  let selectedPerformanceIndex = 0
  let selectedPerformance: Performance = data[0]

  for (; selectedPerformanceIndex < data.length; selectedPerformanceIndex++) {
    selectedPerformance = data[selectedPerformanceIndex]
    if (selectedPerformance.id === selectedPerformanceId) break
  }

  const handleNextDateClick = (e: React.MouseEvent, index: number) => {
    if (index >= 0 && index < data.length) setPerformanceId(data[index].id)
  }

  const setPerformanceId = (id: string) => {
    setCalendarVisible(false)
    onChangePerformance(id)
  }

  const handleDateChange = () =>
    !isDisabled && setCalendarVisible(!isCalendarVisible)

  const handlePerformanceClick = (performanceId: string) => {
    setPerformanceId(performanceId)
  }

  const handlePreviousClick = (e: React.MouseEvent) =>
    handleNextDateClick(e, selectedPerformanceIndex - 1)

  const handleNextClick = (e: React.MouseEvent) =>
    handleNextDateClick(e, selectedPerformanceIndex + 1)

  const handleCalendarToggle = () => setCalendarVisible(false)

  return (
    <DatePickerWrapper>
      <Title isSidebar={true}>Date &amp; Time</Title>

      <DateSelectorWrapper>
        {selectedPerformance && (
          <>
            <StyledButton
              icon='chevron-left'
              size={
                platform === 'kiosk' && isNarrowViewport ? undefined : 'small'
              }
              onClick={handlePreviousClick}
              aria-label='reduce number selector'
              isDisabled={selectedPerformanceIndex === 0 || isDisabled}
              buttonType='sidebar'
            />

            <SelectedDateButton
              isBlock={true}
              size={
                platform === 'kiosk' && isNarrowViewport ? undefined : 'small'
              }
              text={moment
                .utc(selectedPerformance.startDate)
                .format('dddd Do MMM YYYY, h:mm a')
                .toString()}
              onClick={handleDateChange}
              buttonType='sidebar'
            />

            <StyledButton
              icon='chevron-right'
              size={
                platform === 'kiosk' && isNarrowViewport ? undefined : 'small'
              }
              onClick={handleNextClick}
              aria-label='increase number selector'
              isDisabled={
                selectedPerformanceIndex === data.length - 1 || isDisabled
              }
              buttonType='sidebar'
            />
            {isCalendarVisible && selectedPerformanceId && (
              <DatePickerBackground onClick={handleCalendarToggle} />
            )}

            {isCalendarVisible && selectedPerformanceId && (
              <CalendarWrapper>
                <Calendar
                  onPerformanceClick={handlePerformanceClick}
                  data={data}
                  selectedPerformanceId={selectedPerformanceId}
                  selectedPerformanceDate={selectedPerformanceDate}
                  isSmallCalendar={true}
                  renderNavBar={(nav: NavbarElementProps) => (
                    <DateNavigator {...nav} isSmallCalendar={true} />
                  )}
                />
                <CloseButton
                  buttonType='link-button'
                  text='Close'
                  onClick={handleCalendarToggle}
                />
              </CalendarWrapper>
            )}
          </>
        )}
      </DateSelectorWrapper>
    </DatePickerWrapper>
  )
}
