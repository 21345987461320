import backgroundAsset from './assets/patp_sidebar.jpg'
export const Patp = {
  colors: {
    mainBackgroundColor: '#4e00af',
    bestAvailablePerformanceColor: '#fcaf17',
    bestAvailablePerformanceColorComplement: '#111111',
    mainColor: '#fcaf17',
    fadedHighlightColor: '#f1f1f1',
    mainColorComplement: '#111111',
    lwLogoSlashColor: '#ffffff',
    lwBrandColor: '#ffffff',
    lwBrandColorComplement: '#111111',
    numberFilterSelectorColor: '#ffffff',
    numberFilterSelectorCopyColor: '#111111',
    priceSliderHandleColor: '#fcaf17',
    bestAvailableBannerColor: '#0283c3',
    bestAvailableBannerColorComplement: '#ffffff',
    sidebarCopyColor: '#ffffff',
    performanceHoverColor: '#ffd787'
  },
  themeBackgroundColour: '#4e00af',
  themeBackgroundAsset: backgroundAsset
}
