import styled from 'styled-components'
import { media } from 'utils'

const CarouselWrapper = styled.div<{ padding: number | string }>`
  display: flex;
  flex-direction: row;
  overflow-x: auto;
  padding: -${props => props.padding};
  &:before {
    content: '';
    background: transparent;
    display: block;
    min-width: 4rem;
    ${media.tablet} {
      min-width: 3rem;
    }
  }
  &:after {
    content: '';
    background: transparent;
    display: block;
    min-width: 4rem;
    ${media.tablet} {
      min-width: 3rem;
    }
  }

  ::-webkit-scrollbar {
    display: none;
  }

  -ms-overflow-style: none;
  scrollbar-width: none;
`

const CarouselItemWrapper = styled.div<{ padding: number | string }>`
  padding: ${props => props.padding};
`

type Props = {
  children: React.ReactElement[]
  padding?: number | string
}

const Carousel: React.FC<Props> = ({
  children,
  padding = '.3rem',
  ...props
}) => {
  return (
    <CarouselWrapper padding={padding} {...props}>
      {children.map((item, i) => (
        <CarouselItemWrapper padding={padding} key={i}>
          {item}
        </CarouselItemWrapper>
      ))}
    </CarouselWrapper>
  )
}

export { Carousel }
