import React from 'react'
import styled, { css } from 'styled-components/macro'
import { PerformanceTile } from './PerformanceTile'
import { CalendarConsumer } from './Calendar'
import { lighten, darken, media } from 'utils'
import { Day } from './types'

interface Props extends Day {
  onDayClick?: (date: Date) => void
  onPerformanceClick: (performanceId: string) => void
  isDisabled?: boolean
}

const DayWrapperBackground = styled.div<{
  isBackgroundVisible: boolean
}>((props) => {
  const {
    theme: {
      colors: { secondaryBackgroundColor }
    },
    isBackgroundVisible
  } = props
  return css`
    min-height: 100%;
    ::before {
      content: '';
      height: 100%;
      width: 100%;
      position: absolute;
      top: 0;
      left: 0;
      background: ${isBackgroundVisible && darken(secondaryBackgroundColor, 3)};
    }
  `
})

const DayWrapper = styled.div<{
  isSmallCalendar: boolean
}>((props) => {
  const { isSmallCalendar } = props

  return css`
    display: flex;
    box-sizing: border-box;
    padding: 0.5rem 0.375rem;
    flex-direction: column;
    min-height: 100%;
    position: relative;
    justify-content: center;
    align-items: center;

    ${media.tablet} {
      flex-direction: column;
      padding: 0.25rem 0.0625rem;
    }

    ${isSmallCalendar &&
    css`
      flex-direction: column;
      padding: 0.25rem 0;
      ${media.tablet} {
        padding: 0.25rem 0.0625rem;
      }
    `}
  `
})

const DayNumber = styled.span<{
  isDisabled?: boolean
  isSmallCalendar?: boolean
}>((props) => {
  const {
    isDisabled,
    theme: {
      colors: { secondaryBackgroundColorComplement, copyColor },
      font: { header }
    },
    isSmallCalendar
  } = props

  return css`
    font-size: 1.125rem;
    text-align: center;
    margin-bottom: 0.5rem;
    color: ${isDisabled
      ? lighten(secondaryBackgroundColorComplement, 40)
      : copyColor};

    ${media.tablet} {
      font-size: 0.875rem;
      font-family: ${header};
    }

    ${media.phone} {
      font-size: 0.75rem;
      margin-bottom: 0.25rem;
      font-family: ${header};
    }

    ${isSmallCalendar &&
    css`
      font-size: 0.75rem;
      margin-bottom: 0.25rem;
      font-family: ${header};
    `}
  `
})

const NoPerformanceMarker = styled.div((props) => {
  const {
    theme: {
      colors: { secondaryBackgroundColorComplement }
    }
  } = props

  return css`
    background: ${lighten(secondaryBackgroundColorComplement, 40)};
    height: 0.125rem;
    width: 20%;
    margin-top: 20%;
  `
})

export const DayTile: React.FC<Props> = ({
  date,
  performances,
  isDisabled = false,
  onDayClick,
  onPerformanceClick
}) => {
  return (
    <CalendarConsumer>
      {(calendarContext) => (
        <DayWrapperBackground
          isBackgroundVisible={
            !isDisabled && performances && performances.length > 0
          }
        >
          <DayWrapper
            onClick={() => onDayClick && onDayClick(date)}
            isSmallCalendar={calendarContext.isSmallCalendar}
          >
            <DayNumber
              isDisabled={
                isDisabled || !performances || performances.length < 0
              }
              isSmallCalendar={calendarContext.isSmallCalendar}
            >
              {date.getDate()}
            </DayNumber>

            {performances &&
              performances.map((performance) => (
                <PerformanceTile
                  key={`performance-${performance.id}`}
                  {...performance}
                  onPerformanceClick={onPerformanceClick}
                />
              ))}

            {!isDisabled && (!performances || performances.length < 0) && (
              <NoPerformanceMarker />
            )}
          </DayWrapper>
        </DayWrapperBackground>
      )}
    </CalendarConsumer>
  )
}
