// Components
import { MainMenu, WithLoader } from 'marvel-components'

// Actions
import { contentActions } from 'modules/self-serve/content/contentSlice'

// Selectors
import {
  selectContentStatus,
  selectNavigationItems
} from 'modules/self-serve/content/selectors'

// Misc
import { useSelector } from 'react-redux'
import { useAppDispatch } from 'app/reduxStore'
import React, { useEffect } from 'react'
import styled from 'styled-components'
import theatreLogo from 'assets/trdl_logo.png'
import { kioskScreenSaver, media, useIsIdle } from 'utils'
import { Redirect } from 'react-router-dom'
import moment from 'moment'
import { FooterContainer } from 'containers/FooterContainer'

const SHOW_SCREEN_SAVER_AFTER_SECONDS = 60

const MenuWrapper = WithLoader(styled.div`
  display: flex;
  flex-direction: column;
  background: ${props => props.theme.colors.kioskPrimaryBackgroundColor};
  color: ${props => props.theme.colors.kioskPrimaryBackgroundColorComplement};
  max-width: 100%;
  width: 100%;
`)

const MenuTopBar = styled.div`
  display: flex;
  justify-content: center;
  padding: 1.5rem 0;
  max-width: 100%;
`

const MenuLogoWrapper = styled.div`
  width: 9rem;
  min-height: 7.1rem;
  ${media.tablet} {
    width: 11rem;
    min-height: 8.6rem;
  }
`

const MenuLogo = styled.img`
  width: 100%;
`

const MenuHeader = styled.div`
  margin: 0 0 1rem 0;
  display: flex;
  justify-content: center;
  max-width: 100%;
  ${media.tablet} {
    margin: 2rem 0 3rem 0;
  }
`

const MenuTitle = styled.h1`
  font-family: ${props => props.theme.font.kioskHeader};
  font-size: 2.5rem;
  font-weight: 600;
  text-align: center;
  ${media.tablet} {
    font-size: 3rem;
  }
`

type Props = {
  withLoader: boolean
}

const MainMenuView: React.FC<Props> = ({ withLoader }) => {
  const navItems = useSelector(selectNavigationItems)
  const dispatch = useAppDispatch()
  const status = useSelector(selectContentStatus)
  const isIdle = useIsIdle(
    moment.duration().add(SHOW_SCREEN_SAVER_AFTER_SECONDS, 'seconds')
  )

  useEffect(() => {
    dispatch(contentActions.fetchNavigationItems())
  }, [dispatch])

  if (isIdle) {
    return <Redirect to={kioskScreenSaver()} />
  }

  return (
    <MenuWrapper
      isLoading={(withLoader && status === 'pending') || status === 'idle'}
    >
      <MenuTopBar>
        <MenuLogoWrapper>
          <MenuLogo src={theatreLogo} />
        </MenuLogoWrapper>
      </MenuTopBar>
      <MenuHeader>
        <MenuTitle>What would you like to do today?</MenuTitle>
      </MenuHeader>
      <MainMenu navItems={navItems} />
      <FooterContainer sticky={true} />
    </MenuWrapper>
  )
}

export { MainMenuView }
