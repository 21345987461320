import { performanceAdapter } from 'modules/ticketing/performance/performanceSlice'

// Modules
import { interactionSelectors } from 'modules/interaction'
import { priceLevelAdapter } from './priceLevelSlice'
import { seatTypeAdapter } from './seatTypeSlice'
import { discountSelectors } from 'modules/discount'

// Types
import {
  PriceLevel,
  PriceType,
  Performance,
  Pricing,
  LegendItems
} from 'shared-types'
import { AppState } from 'modules/types'
import { Dictionary } from '@reduxjs/toolkit'

// Misc
import moment from 'moment'
import { selectId, selectById } from 'modules/entities'
import { createSelector } from '@reduxjs/toolkit'
import { RootState } from 'app/reduxStore'

const getTotalForPriceType = (priceTypes: PriceType[]) =>
  Math.min(...priceTypes.map((priceType: PriceType) => priceType.total))

const getCapacityAvailable = (pricing: Pricing[]) =>
  pricing.reduce(
    (capacityAvailable: number, pricing: Pricing) =>
      capacityAvailable + pricing.capacityAvailable,
    0
  )
const getTotalCapacity = (pricing: Pricing[]) =>
  pricing.reduce(
    (totalCapacity: number, pricing: Pricing) =>
      totalCapacity + pricing.totalCapacity,
    0
  )
const getMinTicketPrice = (pricing?: Pricing[]) => {
  if (!pricing) return 0
  const minPrice = Math.min(
    ...pricing
      .filter((price: Pricing) => price.capacityAvailable > 0)
      .map((price: Pricing) => getTotalForPriceType(price.priceTypes))
  )
  return minPrice === Infinity ? 0 : minPrice
}
const getAvailablePricesForPerformance = (performance: Performance) =>
  performance.availability
    ? performance.availability
        .filter(price => price.capacityAvailable > 0)
        .map(price => getTotalForPriceType(price.priceTypes))
    : []

// New Selectors
// Performance
const performanceAdapterSelectors = performanceAdapter.getSelectors<RootState>(
  state => state.ticketing.performance
)

export const {
  selectEntities: selectPerformanceEntities,
  selectAll: selectPerformances,
  selectIds: selectPerformanceIds,
  selectById: selectPerformanceById
} = performanceAdapterSelectors

export const selectIsLoadingPerformances = (state: AppState) =>
  state.ticketing.performance.fetchManyStatus === 'pending'

export const selectIsPerformancesLoaded = (state: AppState) =>
  state.ticketing.performance.fetchManyStatus === 'fulfilled'

export const selectActivePerformanceId = (state: AppState) =>
  state.ticketing.performance.selectedPerformanceId ?? ''

export const selectActivePerformance = createSelector(
  [selectActivePerformanceId, selectPerformanceEntities],
  (activePerformanceId, performanceEntities) =>
    performanceEntities[activePerformanceId]
)

export const selectPerformanceNotes = createSelector(
  selectPerformanceById,
  performance => performance?.notes
)

export const selectAllPerformances = createSelector(
  selectPerformances,
  performances =>
    performances.map(performance => {
      return {
        ...performance,
        minTicketPrice: getMinTicketPrice(performance.availability),
        ...(performance.availability && {
          percentageAvailable:
            getCapacityAvailable(performance.availability) /
            getTotalCapacity(performance.availability)
        })
      } as Performance
    })
)

export const selectFilteredPerformances = createSelector(
  [
    selectAllPerformances,
    interactionSelectors.selectPerformanceFilters,
    interactionSelectors.selectLowerPricePerformanceFilter,
    interactionSelectors.selectUpperPricePerformanceFilter
  ],
  (performances, filters, minPriceFilter, maxPriceFilter) => {
    const afternoonEveningCutoff = moment({ h: 17, m: 31 })
    return performances.filter((performance: Performance) => {
      if (performance.tags?.find(tag => tag.name === 'Hide')) {
        return false
      }

      // Price Filters
      const prices = getAvailablePricesForPerformance(performance)
      if (minPriceFilter) {
        if (Math.max(...prices) < minPriceFilter) return false
      }
      if (maxPriceFilter) {
        if (Math.min(...prices) > maxPriceFilter) return false
      }

      // Date Filters
      if (filters.includes('all')) return true

      const performanceDateTime = moment.utc(performance.startDate)
      const performanceTime = moment({
        h: performanceDateTime.hours(),
        m: performanceDateTime.minutes()
      })

      if (
        filters.includes('afternoon') &&
        performanceTime.isAfter(afternoonEveningCutoff)
      )
        return false

      if (
        filters.includes('evening') &&
        performanceTime.isBefore(afternoonEveningCutoff)
      )
        return false

      if (
        filters.includes('weekday') &&
        (performanceDateTime.isoWeekday() === 6 ||
          performanceDateTime.isoWeekday() === 7)
      )
        return false

      if (filters.includes('weekend') && performanceDateTime.isoWeekday() < 6)
        return false

      if (filters.includes('best-available')) {
        return !!performance.tags?.find(tag => tag.name === 'Targeted Event')
      }
      if (filters.includes('discount')) {
        if (!performance.isDiscounted) return false
      }
      if (filters.includes('access')) {
        return !!performance.tags?.find(
          tag => tag.name === 'Access Performance'
        )
      }
      return true
    })
  }
)

export const selectFirstPerformanceDate = createSelector(
  selectFilteredPerformances,
  performances =>
    performances.length > 0 ? moment.utc(performances[0].startDate) : undefined
)

export const selectLastPerformanceDate = createSelector(
  selectFilteredPerformances,
  performances =>
    performances.length > 0
      ? moment(performances[performances.length - 1].startDate)
      : undefined
)

export const selectActivePerformanceDate = (state: AppState) =>
  selectActivePerformance(state)?.startDate

export const selectActivePerformanceCapacity = (state: AppState) =>
  selectActivePerformance(state)?.capacityAvailable

const selectMonthString = (state: AppState) =>
  state.ticketing.performance.selectedMonth

// Get the calendar month to show - either the selected month or the first month with performances or today
export const selectActiveMonth = createSelector(
  [selectMonthString, selectFirstPerformanceDate, selectLastPerformanceDate],
  (
    activeMonthString,
    selectFirstPerformanceDate,
    selectLastPerformanceDate
  ) => {
    if (selectFirstPerformanceDate && selectLastPerformanceDate) {
      if (activeMonthString) {
        if (
          new Date(activeMonthString) >= selectFirstPerformanceDate.toDate()
        ) {
          if (
            new Date(activeMonthString) <= selectLastPerformanceDate.toDate()
          ) {
            return new Date(activeMonthString)
          } else {
            return selectLastPerformanceDate.toDate()
          }
        }
      }
      return selectFirstPerformanceDate.toDate()
    }
    return new Date()
  }
)

export const selectPerformancesFilteredByDate = createSelector(
  [selectFilteredPerformances, selectActiveMonth],
  (performances, filterDate) =>
    performances.filter(
      (performance: Performance) =>
        moment(performance.startDate).isSame(filterDate, 'month') &&
        moment(performance.startDate).isSame(filterDate, 'year')
    )
)

export const selectPerformancesForRange = createSelector(
  [
    selectAllPerformances,
    (_: any, startDate: moment.Moment, endDate: string) => startDate,
    (_: any, startDate: moment.Moment, endDate: string) => endDate
  ],
  (performances, startDate, endDate) =>
    performances.filter(
      performance =>
        moment(performance.startDate).isBetween(startDate, endDate),
      'day'
    )
)

export const getLastFetchPerformances = (state: AppState) =>
  state.ticketing.performance.lastFetchPerformances

// Price Levels
// All Price Levels
export const { selectEntities: selectPriceLevels } =
  priceLevelAdapter.getSelectors<RootState>(state => state.ticketing.priceLevel)

export const selectDiscountedPriceLevelEntities = (state: AppState) =>
  state.entities.discountPriceLevels ?? {}

export const selectPriceLevelEntities = createSelector(
  [
    discountSelectors.getIsDiscounted,
    selectPriceLevels,
    selectDiscountedPriceLevelEntities
  ],
  (isDiscounted, priceLevelEntities, discountPriceLevels) =>
    Object.keys(priceLevelEntities).reduce((priceLevels, priceLevelId) => {
      return {
        ...priceLevels,
        [priceLevelId]:
          isDiscounted && discountPriceLevels[priceLevelId]
            ? ({
                ...discountPriceLevels[priceLevelId],
                originalPrice: Math.max(
                  ...priceLevelEntities[priceLevelId]!.priceTypes.map(
                    priceType => priceType.total
                  )
                )
              } as PriceLevel)
            : priceLevelEntities[priceLevelId]
      }
    }, {} as Dictionary<PriceLevel>)
)

export const selectPriceLevelById = createSelector(
  selectPriceLevelEntities,
  selectId,
  selectById
)

export const selectAllPriceLevels = createSelector(
  selectActivePerformance,
  selectPriceLevelEntities,
  (performance, priceLevels) =>
    performance?.pricing?.reduce((pricing, id) => {
      const level = priceLevels[id]
      if (level) pricing.push(level)
      return pricing
    }, [] as PriceLevel[]) || []
)

export const selectAvailablePriceLevels = createSelector(
  [selectAllPriceLevels],
  priceLevels =>
    priceLevels
      .filter(p => p.capacityAvailable > 0)
      .sort((a, b) => a.priceTypes[0].total - b.priceTypes[0].total)
)

export const selectAvailablePriceLevelIds = createSelector(
  [selectAvailablePriceLevels],
  priceLevels => priceLevels.map(priceLevel => priceLevel.id)
)

// Filtered Price Ids
export const selectFilteredPriceLevelIds = (state: AppState) =>
  state.ticketing.priceLevel.filteredPriceLevels

export const selectIsPriceLevelSelected = createSelector(
  selectFilteredPriceLevelIds,
  selectId,
  (prices, id) => prices.includes(id as string)
)

export const selectSelectedPriceLevels = createSelector(
  selectAllPriceLevels,
  selectFilteredPriceLevelIds,
  (priceLevels, filteredIds) =>
    priceLevels
      .filter(priceLevel => !filteredIds.includes(priceLevel.id))
      .sort((a, b) => a.priceTypes[0].total - b.priceTypes[0].total)
)

export const selectSelectedPriceLevelIds = createSelector(
  selectSelectedPriceLevels,
  priceLevels => priceLevels.map(priceLevel => priceLevel.id)
)

// Prices
// Array of all unique prices
export const selectPriceLevelPrices = createSelector(
  selectAllPriceLevels,
  priceLevels => {
    return priceLevels
      .reduce(
        (priceTotals, priceLevel) => [
          ...priceTotals,
          priceLevel.priceTypes[0].total
        ],
        [] as number[]
      )
      .filter((x, i, a) => a.indexOf(x) === i)
      .sort((a, b) => a - b)
  }
)

// // Array of unique filter prices
export const selectSelectedPrices = createSelector(
  selectSelectedPriceLevels,
  priceLevels =>
    priceLevels
      .reduce(
        (priceTotals, priceLevel) => [
          ...priceTotals,
          priceLevel.priceTypes[0].total
        ],
        [] as number[]
      )
      .filter((x, i, a) => a.indexOf(x) === i)
      .sort((a, b) => a - b)
)

// Legend
export const selectLegendItems = createSelector(
  [selectAvailablePriceLevels, selectFilteredPriceLevelIds],
  (priceLevels, filteredIds) =>
    priceLevels.reduce((groupedPriceLevels, priceLevel) => {
      const isHospitality = priceLevel.name.toLocaleLowerCase().includes('vip')
      return {
        ...groupedPriceLevels,
        [priceLevel.background]: {
          ...(groupedPriceLevels[priceLevel.background] || priceLevel),
          priceLevelIds: [
            ...(groupedPriceLevels[priceLevel.background]?.priceLevelIds || []),
            priceLevel.id
          ],
          price: Math.max(...priceLevel.priceTypes.map(price => price.total)),
          isFiltered: filteredIds.includes(priceLevel.id),
          ...(isHospitality && {
            icon: 'star' as const,
            note: 'Includes hospitality'
          })
        }
      }
    }, {} as LegendItems)
)

// Seat Types
export const {
  selectEntities: selectSeatTypeEntities,
  selectById: selectSeatTypeById
} = seatTypeAdapter.getSelectors<RootState>(state => state.ticketing.seatType)

export const getIsBestAvailablePresent = createSelector(
  selectAllPerformances,
  performances =>
    !!performances.find(
      (performance: Performance) =>
        performance.tags &&
        performance.tags.find(tag => tag.name === 'Targeted Event')
    )
)

export const getIsDiscountPresent = createSelector(
  selectAllPerformances,
  performances =>
    !!performances.find((performance: Performance) => performance.isDiscounted)
)

export const getIsAccessPresent = createSelector(
  selectAllPerformances,
  performances =>
    !!performances.find((performance: Performance) =>
      performance.tags?.find(tag => tag.name === 'Access Performance')
    )
)

// Performance filters

export const getTicketPricesForAllPerformances = createSelector(
  selectAllPerformances,
  performances => {
    const prices = performances.reduce(
      (allPrices: number[], performance: Performance) => {
        return [...allPrices, ...getAvailablePricesForPerformance(performance)]
      },
      []
    )
    return Array.from(new Set(prices)).sort((a: number, b: number) => a - b)
  }
)

export const getRecommendationPriceTypeId = createSelector(
  selectSelectedPriceLevels,
  priceLevels => priceLevels[0]?.priceTypes[0]?.id
)
