import React from 'react'

// Types
import { AppState } from 'modules/types'

// Selectors
import { getSector } from 'modules/seat-map/selectors'

// Components
import { SeatMapSector } from 'marvel-components'
import { RowContainer } from './RowContainer'

// Misc
import { useSelector } from 'react-redux'

interface Props {
  sectorId: string
  performanceId: string
  toolTipTarget: any
}

const SectorContainerNM: React.FC<Props> = ({
  sectorId,
  performanceId,
  toolTipTarget
}) => {
  // Selectors
  const sector = useSelector((state: AppState) => getSector(state, sectorId))
  return (
    <>
      {sector && (
        <>
          <SeatMapSector sector={sector}>
            {sector.rows.map((rowId) => (
              <RowContainer
                key={`row-${rowId}`}
                rowId={rowId}
                performanceId={performanceId}
                toolTipTarget={toolTipTarget}
                sectorAngle={sector.angle}
              />
            ))}
          </SeatMapSector>
        </>
      )}
    </>
  )
}

export const SectorContainer = React.memo(SectorContainerNM)
