import { createSlice, createEntityAdapter } from '@reduxjs/toolkit'

// Utils
import hospitalityApi from 'services/hospitalityApi'
import { createAPIThunk } from 'modules/smart-waiter/shared/actions'
import { menusEntity } from 'services/hospitalitySchemas'

// Types
import { HospitalityMenu } from 'shared-types'

type MenusRequest = {
  siteId: string
  occasionId: string
  adults: number
  date: string
  timeslot: string
  children?: number
}

type MenusResponse = {
  menus?: { [key: string]: HospitalityMenu }
}

// Actions
const fetchMenusForSite = createAPIThunk<MenusResponse, MenusRequest>(
  'marvel/hospitality/FETCH_MENUS',
  (data: MenusRequest) =>
    hospitalityApi.fetchAll(
      `site/${data.siteId}/occasion/${data.occasionId}/menus/`,
      {
        adults: data.adults,
        date: data.date,
        time: data.timeslot,
        ...(data.children && { children: data.children })
      }
    ),
  menusEntity
)

// Reducer

export const menusAdapter = createEntityAdapter<HospitalityMenu>({
  sortComparer: (a, b) => a.name.localeCompare(b.name)
})

const menusSlice = createSlice({
  name: 'menus',
  initialState: menusAdapter.getInitialState({
    loading: 'idle'
  }),
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchMenusForSite.pending, (state, action) => {
      state.loading = 'pending'
    })
    builder.addCase(fetchMenusForSite.fulfilled, (state, action) => {
      if (action.payload.menus) {
        menusAdapter.setAll(state, action.payload.menus)
      }
      state.loading = 'idle'
    })
  }
})

export const menuActions = {
  fetchMenusForSite
}

export default menusSlice.reducer
