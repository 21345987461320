// Misc
import { AppState } from 'modules'
import React from 'react'
import styled from 'styled-components'
import { media } from 'utils'

// Components
import { Card, Carousel } from 'marvel-components'

// Selectors
import { useSelector } from 'react-redux'
import { eventSelectors } from 'modules/self-serve/event'
import { eventCollectionSelectors } from 'modules/self-serve/event-collection'
import { venueSelectors } from 'modules/self-serve/venue'
import { useHistory } from 'react-router-dom'
import { calendarRoute } from 'utils'

const EventCollectionWrapper = styled.section`
  margin: 5rem 0 0 0;
  position: relative;
  text-align: center;
  &:first-child {
    margin: 0;
  }
`

const StyledCard = styled(Card)<{ isDark?: boolean }>`
  width: 410px;
  background: ${props =>
    props.isDark
      ? props.theme.colors.venueSecondaryBackgroundColor
      : props.theme.colors.venuePrimaryBackgroundColor} !important;
  color: ${props =>
    props.isDark
      ? props.theme.colors.venueSecondaryCopyColor
      : props.theme.colors.venuePrimaryCopyColor} !important;
  ${media.tablet} {
    width: 318px;
  }
`

const EventCollectionTitle = styled.h1`
  font-size: 1.2rem;
  text-align: left;
  text-transform: uppercase;
  font-family: ${props => props.theme.font.header};
  letter-spacing: 0.3rem;
  margin: 2rem 0 2rem 4.25rem;
  font-weight: normal;
  ${media.tablet} {
    margin: 2rem 0 2rem 3.25rem;
  }
`

type Props = {
  eventCollectionId: string
  isDark?: boolean
}

const EventCollectionContainer: React.FC<Props> = ({
  eventCollectionId,
  isDark
}) => {
  const history = useHistory()

  const eventCollection = useSelector((state: AppState) =>
    eventCollectionSelectors.selectEventCollection(
      state.selfServe.eventCollection,
      eventCollectionId
    )
  )
  const events = useSelector(eventSelectors.selectEventEntities)
  const venues = useSelector(venueSelectors.selectVenueEntities)

  if (!eventCollection) {
    return null
  }

  const viewEventDetails = (eventId?: string) => {
    eventId && history.push(calendarRoute(eventId))
  }

  return (
    <EventCollectionWrapper>
      <EventCollectionTitle>{eventCollection.title}</EventCollectionTitle>
      <Carousel>
        {eventCollection.events
          .filter(id => {
            const event = events[id]
            const venue = event?.venue && venues[event.venue]
            return event && venue && event.public
          })
          .map((id, i) => {
            const event = events[id]!
            const venue = venues[event.venue]!
            return (
              <StyledCard
                key={i}
                title={event.title}
                imageUrl={event.image}
                isDark={isDark}
                value={event.externalId}
                onClick={viewEventDetails}
              >
                {event.date} | {venue.title}
              </StyledCard>
            )
          })}
      </Carousel>
    </EventCollectionWrapper>
  )
}

export { EventCollectionContainer }
