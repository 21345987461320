import React, { useEffect } from 'react'
import styled from 'styled-components'
import { media, usePlatform, useIsNarrowViewport } from 'utils'

// Components
import { Title, InlineSelector, Icon } from 'marvel-components'
import DayPicker from 'react-day-picker'

// Selectors
import { occasionSelectors } from 'modules/hospitality/occasion'
import { reservationSelectors } from 'modules/hospitality/reservation'
import { slotSelectors } from 'modules/hospitality/slot'

// Actions
import { reservationActions } from 'modules/hospitality/reservation'
import { slotActions } from 'modules/hospitality/slot'

// Misc
import { useSelector, useDispatch } from 'react-redux'
import moment from 'moment'

// Types
import { AppState } from 'modules/types'
import { selectSelectedCalendarMonthDate } from 'modules/hospitality/reservation/selectors'
import { selectSelectedMonthFirstSlot } from 'modules/hospitality/slot/selectors'

const AvailabilityContainerWrapper = styled.div<{ platform?: string }>`
  box-sizing: border-box;
  padding: 1.5rem 1.5rem 2.5rem 1.5rem;
  ${media.tablet} {
    padding: 1rem;
    min-height: ${props => (props.platform === 'kiosk' ? '65vh' : 'auto')};
  }
  ${media.phone} {
    padding: 1rem 1rem 7rem 1rem;
  }
`

const PartySizeControlsWrapper = styled.div``

const HospitalityDayPicker = styled(DayPicker)<{ platform?: string }>`
  font-family: ${props => props.theme.font.copy};
  width: 100%;
  outline: 0;
  .DayPicker {
    display: inline-block;
    font-size: 1rem;
  }

  .DayPicker-wrapper {
    position: relative;
    background: #f9f9f9;
    padding: 0.5rem 1.5rem 1.5rem 1.5rem;

    flex-direction: row;

    -webkit-user-select: none;

    -moz-user-select: none;

    -ms-user-select: none;

    user-select: none;
    :focus {
      outline: none;
    }
    border-radius: 2px;
    ${media.tablet} {
      padding: 0 1.5rem 1.5rem 1.5rem;
    }
    ${media.phone} {
      padding: 0 1rem 1rem 1rem;
    }
  }

  .DayPicker-Months {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }

  .DayPicker-Month {
    display: table;
    /* margin: 0 1em; */
    margin-top: 1em;

    border-spacing: 0;
    border-collapse: collapse;
    width: 100%;
    border-spacing: 0.15rem;
    -webkit-user-select: none;

    -moz-user-select: none;

    -ms-user-select: none;

    user-select: none;
  }

  .DayPicker-NavBar {
  }

  .DayPicker-NavButton {
    position: absolute;
    top: 1rem;
    right: 0.75rem;
    left: auto;

    display: inline-block;
    margin-top: 2px;
    width: 1.25em;
    height: 1.25em;
    background-position: center;
    background-size: 50%;
    background-repeat: no-repeat;
    color: #8b9898;
    cursor: pointer;
    :focus {
      outline: none;
    }
    ${media.phone} {
      top: 0.75rem;
      right: 0.5rem;
    }
  }

  .DayPicker-NavButton:hover {
    opacity: 0.8;
  }

  .DayPicker-NavButton--prev {
    margin-right: 1.5em;
    background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACQAAAAwCAYAAAB5R9gVAAAABGdBTUEAALGPC/xhBQAAAVVJREFUWAnN2G0KgjAYwPHpGfRkaZeqvgQaK+hY3SUHrk1YzNLay/OiEFp92I+/Mp2F2Mh2lLISWnflFjzH263RQjzMZ19wgs73ez0o1WmtW+dgA01VxrE3p6l2GLsnBy1VYQOtVSEH/atCCgqpQgKKqYIOiq2CBkqtggLKqQIKgqgCBjpJ2Y5CdJ+zrT9A7HHSTA1dxUdHgzCqJIEwq0SDsKsEg6iqBIEoq/wEcVRZBXFV+QJxV5mBtlDFB5VjYTaGZ2sf4R9PM7U9ZU+lLuaetPP/5Die3ToO1+u+MKtHs06qODB2zBnI/jBd4MPQm1VkY79Tb18gB+C62FdBFsZR6yeIo1YQiLJWMIiqVjQIu1YSCLNWFgijVjYIuhYYCKoWKAiiFgoopxYaKLUWOii2FgkophYp6F3r42W5A9s9OcgNvva8xQaysKXlFytoqdYmQH6tF3toSUo0INq9AAAAAElFTkSuQmCC');
  }

  .DayPicker-NavButton--next {
    background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACQAAAAwCAYAAAB5R9gVAAAABGdBTUEAALGPC/xhBQAAAXRJREFUWAnN119ugjAcwPHWzJ1gnmxzB/BBE0n24m4xfNkTaOL7wOtsl3AXMMb+Vjaa1BG00N8fSEibPpAP3xAKKs2yjzTPH9RAjhEo9WzPr/Vm8zgE0+gXATAxxuxtqeJ9t5tIwv5AtQAApsfT6TPdbp+kUBcgVwvO51KqVhMkXKsVJFXrOkigVhCIs1Y4iKlWZxB1rX4gwlpRIIpa8SDkWmggrFq4IIRaJKCYWnSgnrXIQV1r8YD+1Vrn+bReagysIFfLABRt31v8oBu1xEBttfRbltmfjgEcWh9snUS2kNdBK6WN1vrOWxObWsz+fjxevsxmB1GQDfINWiev83nhaoiB/CoOU438oPrhXS0WpQ9xc1ZQWxWHqUYe0I0qrKCQKjygDlXIQV2r0IF6ViEBxVTBBSFUQQNhVYkHIVeJAtkNsbQ7c1LtzP6FsObhb2rCKv7NBIGoq4SDmKoEgTirXAcJVGkFSVVpgoSrXICGUMUH/QBZNSUy5XWUhwAAAABJRU5ErkJggg==');
  }

  .DayPicker-NavButton--interactionDisabled {
    display: none;
  }

  .DayPicker-Caption {
    display: table-caption;
    margin-bottom: 1rem;
    padding: 0;
    text-align: left;
  }

  .DayPicker-Caption > div {
    font-weight: 400;
    font-size: 1rem;
  }

  .DayPicker-Weekdays {
    display: table-header-group;
    margin-top: 1em;
  }

  .DayPicker-WeekdaysRow {
    display: table-row;
  }

  .DayPicker-Weekday {
    display: table-cell;
    padding: 0.5em 0;
    color: #8b9898;
    text-align: center;
    font-size: 0.875em;
  }

  .DayPicker-Weekday abbr[title] {
    border-bottom: none;
    text-decoration: none;
  }

  .DayPicker-Body {
    display: table-row-group;
    box-sizing: border-box;
  }

  .DayPicker-Week {
    display: table-row;
    box-sizing: border-box;
  }

  .DayPicker-Day {
    display: table-cell;
    padding: 0.5em 0;
    vertical-align: middle;
    text-align: center;
    cursor: pointer;
    border: 1px solid #ccc;
    box-sizing: border-box;
    height: 3rem;
    width: 10%;
    min-width: 10%;
    background: #faf5f1;
    color: #494949;
    :focus {
      outline: none;
    }
    ${media.tablet} {
      height: ${props => (props.platform === 'kiosk' ? '6.5rem' : '2rem')};
    }
    ${media.phone} {
      height: auto;
    }
  }

  .DayPicker-Day:hover {
    color: #111;
    background: #ffe6e1;
  }

  .DayPicker-WeekNumber {
    display: table-cell;
    padding: 0.5em;
    min-width: 1em;
    border-right: 1px solid #eaecec;
    color: #8b9898;
    vertical-align: middle;
    text-align: right;
    font-size: 0.75em;
    cursor: pointer;
  }

  .DayPicker--interactionDisabled .DayPicker-Day {
    cursor: default;
  }

  .DayPicker-Footer {
    padding-top: 0.5em;
  }

  .DayPicker-TodayButton {
    border: none;
    background-color: transparent;
    background-image: none;
    box-shadow: none;
    color: #4a90e2;
    font-size: 0.875em;
    cursor: pointer;
  }

  /* Default modifiers */

  .DayPicker-Day--today {
    font-family: ${props => props.theme.font.header};
    background: #faf5f1;
  }

  .DayPicker-Day--selected {
    color: #111;
    font-family: ${props => props.theme.font.header};
    background: #ffe6e1;
  }

  .DayPicker-Day--outside {
    color: #8b9898;
    cursor: default;
    background-color: #eff1f1;
  }

  .DayPicker-Day--outside:hover {
    background-color: #eff1f1;
    color: #8b9898;
  }

  .DayPicker-Day--disabled {
    color: #dce0e0;
    cursor: default;
    background-color: #eff1f1;
  }

  .DayPicker-Day--disabled:hover {
    background-color: #eff1f1;
    color: #dce0e0;
  }

  /* Example modifiers */

  /* .DayPicker-Day--sunday {
    background-color: #f7f8f8;
  } */

  /* .DayPicker-Day--sunday:not(.DayPicker-Day--today) {
    color: #dce0e0;
  } */

  .DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside) {
    position: relative;
    color: #111;
    font-family: ${props => props.theme.font.header};
    background: #ffe6e1;
  }

  .DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside):hover {
    color: #111;
    font-family: ${props => props.theme.font.header};
    background: #ffe6e1;
  }

  .DayPicker:not(.DayPicker--interactionDisabled)
    .DayPicker-Day:not(.DayPicker-Day--disabled):not(.DayPicker-Day--selected):not(.DayPicker-Day--outside):hover {
    background-color: #f0f8ff;
  }

  /* DayPickerInput */

  .DayPickerInput {
    display: inline-block;
  }

  .DayPickerInput-OverlayWrapper {
    position: relative;
  }

  .DayPickerInput-Overlay {
    position: absolute;
    left: 0;
    z-index: 1;

    background: white;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15);
  }
`

const HospitalityDayPickerWrapper = styled.div`
  position: relative;
`

const HospitalityDayPickerLoadingOverlay = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  fill: #999;
  background: #f5f5f5;
  z-index: 2;
`

const CalendarSpacer = styled.div`
  display: none;
  ${media.tablet} {
    display: block;
    height: 370px;
  }
  ${media.phone} {
    height: 360px;
  }
`

const SLOTS_DEBOUNCE_TIME_MS = 1000

type Props = {
  siteId: string
  occasionId: string
  platform?: string
}
const AvailabilityRequestContainer: React.FC<Props> = ({
  siteId,
  occasionId
}) => {
  // Selectors
  const occasion = useSelector((state: AppState) =>
    occasionSelectors.selectById(state, occasionId)
  )
  const adults = useSelector(reservationSelectors.selectAdultQuantity)
  const children = useSelector(reservationSelectors.selectChildQuantity)
  const selectedDate = useSelector(reservationSelectors.selectSelectedDate)
  const selectedCalendarMonth = useSelector(
    reservationSelectors.selectSelectedCalendarMonth
  )
  const isLoading = useSelector(slotSelectors.selectIsLoading)
  const unavailableSlots = useSelector(
    slotSelectors.selectSelectedMonthUnavailableSlots
  )
  const disabledDates = useSelector(
    slotSelectors.selectSelectedMonthUnavailableSlotDates
  )
  const selectedMonthDate = useSelector(selectSelectedCalendarMonthDate)
  const firstSlot = useSelector(selectSelectedMonthFirstSlot)

  // Actions
  const dispatch = useDispatch()

  const needsFetch = firstSlot === undefined
  useEffect(() => {
    let thunk: any = null

    const timeout = setTimeout(() => {
      if (adults > 0 && needsFetch) {
        const selectedMonth = moment.utc({
          year: selectedCalendarMonth?.year,
          month: selectedCalendarMonth?.month
        })
        const startOfMonth = selectedMonth.startOf('month')
        const today = moment().utc().startOf('day')

        const from = startOfMonth < today ? today : startOfMonth
        const to = from.clone().endOf('month').subtract(1, 'hour')

        thunk = dispatch(
          slotActions.fetchAll({
            siteId,
            occasionId,
            dateFrom: from.toISOString(),
            dateTo: to.toISOString(),
            adults: adults,
            ...(children > 0 && { children: children })
          })
        )
      }
    }, SLOTS_DEBOUNCE_TIME_MS)

    return () => {
      if (thunk) thunk.abort()
      clearTimeout(timeout)
    }
  }, [
    adults,
    children,
    needsFetch,
    dispatch,
    siteId,
    occasionId,
    selectedCalendarMonth?.year,
    selectedCalendarMonth?.month
  ])

  const handleIncrementAdultQuantity = () => {
    dispatch(reservationActions.incrementAdults())
    dispatch(reservationActions.selectDate(''))
    dispatch(slotActions.reset())
  }
  const handleDeIncrementAdultQuantity = () => {
    dispatch(reservationActions.decrementAdults())
    dispatch(reservationActions.selectDate(''))
    dispatch(slotActions.reset())
  }
  const handleIncrementChildQuantity = () => {
    dispatch(reservationActions.incrementChildren())
    dispatch(reservationActions.selectDate(''))
    dispatch(slotActions.reset())
  }
  const handleDeIncrementChildQuantity = () => {
    dispatch(reservationActions.decrementChildren())
    dispatch(reservationActions.selectDate(''))
    dispatch(slotActions.reset())
  }

  const handleDateSelect = (date: Date) => {
    const slotId = moment(date).format('YYYY-MM-DD')
    if (
      date > new Date() &&
      !unavailableSlots.find(slot => slot?.date === slotId)
    ) {
      dispatch(reservationActions.selectDate(date.toISOString()))
    }
  }
  const handleMonthChange = (date: Date) => {
    dispatch(
      reservationActions.selectCalendarMonth({
        year: date.getFullYear(),
        month: date.getMonth()
      })
    )
  }

  const platform = usePlatform()
  const isNarrowViewport = useIsNarrowViewport()

  return (
    <AvailabilityContainerWrapper platform={platform}>
      <Title
        isCapitalised={false}
        isCentralised={false}
        isBold={true}
        size={platform === 'kiosk' && isNarrowViewport ? 'large' : 'medium'}
      >
        {occasion && occasion.name}
      </Title>
      <PartySizeControlsWrapper>
        <Title
          isCapitalised={false}
          isCentralised={false}
          isBold={true}
          size={platform === 'kiosk' && isNarrowViewport ? 'medium' : 'small'}
        >
          Party Size
        </Title>
        <InlineSelector
          title='Adult'
          description='12 years +'
          quantity={adults}
          onIncrement={handleIncrementAdultQuantity}
          onDeIncrement={handleDeIncrementAdultQuantity}
        />
        <InlineSelector
          title='Child'
          description='2 - 12 years'
          quantity={children}
          onIncrement={handleIncrementChildQuantity}
          onDeIncrement={handleDeIncrementChildQuantity}
        />
      </PartySizeControlsWrapper>
      {adults > 0 ? (
        <>
          <Title
            isCapitalised={false}
            isCentralised={false}
            isBold={true}
            size='small'
          >
            Date
          </Title>
          <HospitalityDayPickerWrapper>
            {isLoading || needsFetch ? (
              <HospitalityDayPickerLoadingOverlay>
                <Icon icon='circle-notch' isSpinning size='2x' />
              </HospitalityDayPickerLoadingOverlay>
            ) : null}
            <HospitalityDayPicker
              onDayClick={handleDateSelect}
              onMonthChange={handleMonthChange}
              selectedDays={selectedDate}
              showOutsideDays={false}
              disabledDays={[{ before: new Date() }, ...disabledDates]}
              firstDayOfWeek={1}
              initialMonth={selectedMonthDate.toDate()}
              fromMonth={new Date()}
              platform={platform}
            />
          </HospitalityDayPickerWrapper>
        </>
      ) : (
        <CalendarSpacer />
      )}
    </AvailabilityContainerWrapper>
  )
}

export { AvailabilityRequestContainer }
