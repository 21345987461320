import React, { useEffect } from 'react'

// Components
import { Layout } from 'marvel-components'
// Containers
import {
  BrandLogoContainer,
  CheckoutButtonContainer,
  AddMenuContainer,
  ServiceListContainer,
  BasketSummaryContainer
} from 'containers/SmartWaiter'

// Views
import { ServiceView, BasketView } from 'views/SmartWaiter'

// Actions
import { venueSelectors } from 'modules/smart-waiter/venue'

// Misc
import {
  Route,
  Switch,
  useRouteMatch,
  useHistory,
  Redirect
} from 'react-router-dom'
import { trackSmartWaiterSalesArea, useIsMobileViewport } from 'utils'
import { useTypedSelector } from 'modules'

const SalesAreaView: React.FC = () => {
  // Routing
  const {
    path,
    url,
    params: { venueId, salesAreaId }
  } = useRouteMatch<{
    venueId: string
    salesAreaId: string
  }>()

  const history = useHistory()
  const isMobile = useIsMobileViewport()
  const handleCheckoutClick = () => {
    isMobile
      ? history.push(`${url}/basket`)
      : history.push(`${url}/add-service`)
  }

  // Selectors
  const venueName = useTypedSelector(state =>
    venueSelectors.selectVenueName(state, venueId)
  )
  const salesAreaName = useTypedSelector(state =>
    venueSelectors.selectSalesAreaName(
      state,
      parseInt(venueId),
      parseInt(salesAreaId)
    )
  )
  const selectedTime = useTypedSelector(venueSelectors.selectedSelectedTime)

  // Analytics
  useEffect(() => {
    if (salesAreaName && venueName) {
      trackSmartWaiterSalesArea({
        salesAreaName,
        venueName
      })
    }
  }, [salesAreaName, venueName])

  const handleContinueClick = () => history.push(`${url}/basket/checkout`)

  if (!selectedTime) {
    return <Redirect to={`/smart-waiter/venue/${venueId}`} />
  }
  return (
    <Switch>
      <Route exact path={`${path}/service`}>
        <Layout
          pageTitle={`Pre-Show or Interval | LW Theatres`}
          renderBrandLogo={<BrandLogoContainer />}
          renderSidebarTop={<BasketSummaryContainer hideOnMobile={true} />}
          renderSidebarBottom={
            <CheckoutButtonContainer
              ctaText='Continue to Basket'
              onCheckoutClick={handleCheckoutClick}
            />
          }
          renderMain={<ServiceListContainer />}
        />
      </Route>
      <Route exact path={`${path}/add-service`}>
        <Layout
          pageTitle={`Add to Basket | LW Theatres`}
          renderBrandLogo={<BrandLogoContainer />}
          renderSidebarTop={<BasketSummaryContainer hideOnMobile={true} />}
          renderSidebarBottom={
            <CheckoutButtonContainer
              ctaText='Continue to Checkout'
              onCheckoutClick={handleContinueClick}
            />
          }
          renderMain={<AddMenuContainer />}
        />
      </Route>
      <Route path={`${path}/basket`}>
        <BasketView />
      </Route>
      <Route path={`${path}/service/:serviceId/menu`}>
        <ServiceView onCheckoutClick={handleCheckoutClick} />
      </Route>
    </Switch>
  )
}

export { SalesAreaView }
