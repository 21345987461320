// Misc
import React from 'react'
import styled from 'styled-components'

const ExternalPageOverlayWrapper = styled.div`
  height: 100%;
  overflow: hidden;
`

const ExternalPageOverlayIFrame = styled.iframe`
  width: 100%;
  height: 100%;
  border: none;
`

export type KioskExternalPageOverlayContainerProps = {
  href: string
}

const KioskExternalPageOverlayContainer: React.FC<KioskExternalPageOverlayContainerProps> = ({
  href
}) => {
  return (
    <ExternalPageOverlayWrapper>
      <ExternalPageOverlayIFrame src={href} sandbox='' />
    </ExternalPageOverlayWrapper>
  )
}

export { KioskExternalPageOverlayContainer }
