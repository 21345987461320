import React, { useEffect } from 'react'

// Components
import { SWFooterNav } from 'marvel-components'
import { Layout } from 'marvel-components'

// Containers
import {
  BasketSummaryContainer,
  OrderContainer,
  BrandLogoContainer
} from 'containers/SmartWaiter'

// Selectors
import { fetchSWOrder, orderSelectors } from 'modules/smart-waiter/order'

// Actions
import { resetSW } from 'modules/smart-waiter/shared/actions'

// Misc
import { Redirect, useHistory, useRouteMatch } from 'react-router'
import { useSelector, useDispatch } from 'react-redux'
import { useQuery } from 'utils/hooks'
import { trackSmartWaiterOrderSuccess } from 'utils'
import { basketSelectors } from 'modules/smart-waiter/basket'
import { productSelectors } from 'modules/smart-waiter/product'
import { useTypedSelector } from 'modules'
import { venueSelectors } from 'modules/smart-waiter/venue'

const OrderSuccessView: React.FC = () => {
  const dispatch = useDispatch()
  const order = useSelector(orderSelectors.selectOrder)
  const basket = useSelector(basketSelectors.selectBasket)
  const products = useSelector(productSelectors.selectEntities)
  const history = useHistory()
  const query = useQuery()

  // Routing
  const {
    params: { venueId, salesAreaId, orderId }
  } = useRouteMatch<{
    venueId: string
    salesAreaId: string
    orderId: string
  }>()

  // Selectors
  const venueName = useTypedSelector(state =>
    venueSelectors.selectVenueName(state, venueId)
  )
  const salesAreaName = useTypedSelector(state =>
    venueSelectors.selectSalesAreaName(
      state,
      parseInt(venueId),
      parseInt(salesAreaId)
    )
  )

  const handleOrderAgainClick = (e: React.MouseEvent) => {
    dispatch(resetSW())
    history.push(`/smart-waiter/venue/${venueId}`)
  }

  // Actions
  useEffect(() => {
    orderId && dispatch(fetchSWOrder(orderId))
  }, [orderId, dispatch])

  // Analytics
  useEffect(() => {
    if (order && orderId && salesAreaName && venueName) {
      trackSmartWaiterOrderSuccess({
        order,
        basket,
        products,
        salesAreaName,
        venueName
      })
    }
  }, [basket, order, orderId, products, salesAreaName, venueName])

  if (!order && !orderId) {
    return <Redirect to={`/smart-waiter/venue/${venueId}`} />
  }

  return (
    <Layout
      pageTitle={`Order Confirmation - Smart Waiter | LW Theatres`}
      renderBrandLogo={<BrandLogoContainer />}
      renderSidebarTop={<BasketSummaryContainer showRemoveOption={false} />}
      renderSidebarBottom={<SWFooterNav />}
      renderMainTop={
        <OrderContainer
          venueId={parseInt(venueId)}
          salesAreaId={parseInt(salesAreaId)}
          error3d={query.get('error3d')}
          orderError={query.get('orderError')}
          onOrderAgainClick={handleOrderAgainClick}
        />
      }
    />
  )
}
export { OrderSuccessView }
