import React, { useEffect } from 'react'

// Views
import {
  ExchangeView,
  ExchangeViewSuccess,
  OrderSuccessView
} from 'views/Ticketing'

// Actions
import { eventDetailsActions } from 'modules/ticketing/event'
import { fetchOrder } from 'modules/basket/actions'

// Selectors
import {
  selectOrderEventId,
  selectTransactionState,
  selectReservationGuid
} from 'modules/basket/selectors'
import { eventDetailsSelectors } from 'modules/ticketing/event'

// Utils
import { useSelector, useDispatch } from 'react-redux'
import { useParams, useRouteMatch, Switch, Route } from 'react-router'

const OrderView: React.FC = () => {
  const dispatch = useDispatch()

  // Routing
  const { path } = useRouteMatch()
  const { orderId } = useParams<{ orderId: string }>()

  // Selectors
  const eventId = useSelector(selectOrderEventId)
  const eventDetailsLoaded = useSelector(eventDetailsSelectors.selectIsLoaded)
  const transactionState = useSelector(selectTransactionState)
  const reservationGuid = useSelector(selectReservationGuid)
  // Fetch Order Details - different from fetching the reservation
  // Don't fetch if you have an active post order reservation
  useEffect(() => {
    if (transactionState !== 'CommittedConfirmed' && !reservationGuid) {
      dispatch(
        fetchOrder({
          transactionGuid: orderId
        })
      )
    }
  }, [dispatch, orderId, transactionState, reservationGuid])

  // Fetch Event Details
  // ------------------------------------------
  useEffect(() => {
    eventId &&
      !eventDetailsLoaded &&
      dispatch(
        eventDetailsActions.fetchEventDetails({
          eventId
        })
      )
  }, [dispatch, eventId, eventDetailsLoaded])

  return (
    <Switch>
      <Route exact path={`${path}/exchange`}>
        <ExchangeView />
      </Route>
      <Route exact path={`${path}/exchange/success`}>
        <ExchangeViewSuccess />
      </Route>
      <Route exact path={`${path}/success`}>
        <OrderSuccessView />
      </Route>
    </Switch>
  )
}

export { OrderView }
