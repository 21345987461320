import React from 'react'
import styled, { css } from 'styled-components/macro'

// Components
import { Checkbox } from 'marvel-components'
import { Performance } from 'shared-types'

// Misc
import moment from 'moment'
import { lighten, media } from 'utils'

interface Props {
  performance: Performance
  isSelected: boolean
  fieldName: string
  onClick?: (id: string, isSelected: boolean) => void
}

const PerformanceItemWrapper = styled.div<{
  isSelected?: boolean
}>(props => {
  const {
    theme: {
      colors: { headingColor, borderColor, availabilityBarColor },
      font: { header }
    },
    isSelected
  } = props

  return css`
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    cursor: 'pointer';
    border: 1px ${borderColor} solid;
    background: ${isSelected ? availabilityBarColor : lighten(borderColor, 15)};
    color: ${headingColor};
    padding: 1.5rem 0 1.5rem 0;
    margin: 0.5rem 0;
    font-family: ${header};
    align-items: center;
    box-sizing: border-box;

    ${media.tablet} {
      padding: 1.25rem 0;
      align-items: center;
    }

    ${media.phone} {
      padding: 0.75rem 0;
      margin: 0.5rem 0;
      flex-wrap: wrap;
    }
  `
})

const TitleWrapper = styled.div`
  display: flex;
  width: 66%;
  padding: 0 2%;
  ${media.phone} {
    width: 96%;
    flex: 1 1 100%;
    font-size: 0.8rem;
  }
`

const SelectPerformanceWrapper = styled.div`
  padding: 0 2%;
  width: 26%;
  display: flex;
  justify-content: flex-end;
  ${media.phone} {
    padding: 2% 2% 0 2%;
    flex: 1 1 100%;
    font-size: 0.7rem;
    width: 96%;
    justify-content: flex-start;
  }
`

export const PerformanceItem: React.FC<Props> = ({
  performance,
  onClick,
  fieldName,
  isSelected
}) => {
  const { id, startDate } = performance
  const handleOnChange = (isChecked: boolean) => {
    onClick && onClick(id, isChecked)
  }
  return (
    <PerformanceItemWrapper isSelected={isSelected}>
      <TitleWrapper>
        {moment.utc(startDate)
          .format('dddd Do MMMM YYYY, h:mm a')
          .toString()}
      </TitleWrapper>
      <SelectPerformanceWrapper>
        <Checkbox
          label={'Select Performance'}
          size={'small'}
          onChange={handleOnChange}
          isChecked={isSelected}
          name={fieldName}
        />
      </SelectPerformanceWrapper>
    </PerformanceItemWrapper>
  )
}
