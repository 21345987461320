import axios from 'axios'
import { APIResponse } from 'shared-types'

const API_ROOT = process.env.REACT_APP_API_ROOT

const axiosInstance = axios.create({
  baseURL: `${API_ROOT}/`,
  timeout: 10000,
  headers: {
    'Content-Type': 'application/json'
  }
})

const ticketingApi = {
  async fetchAll(
    url: string,
    params?: any,
    contextId?: string,
    authToken?: string
  ) {
    const result = await axiosInstance.get<APIResponse>(url, {
      params: params,
      headers: {
        'Content-Type': 'application/json',
        ...(contextId && { 'Context-Id': contextId }),
        ...(authToken && { Authorization: `Bearer ${authToken}` })
      }
    })
    return result.data
  },
  async create(url: string, body: any, contextId?: string, authToken?: string) {
    const result = await axiosInstance.post<APIResponse>(
      url,
      JSON.stringify(body),
      {
        headers: {
          'Content-Type': 'application/json',
          ...(contextId && { 'Context-Id': contextId }),
          ...(authToken && { Authorization: `Bearer ${authToken}` })
        }
      }
    )
    return result.data
  },
  async update(url: string, body: any, contextId?: string, authToken?: string) {
    const result = await axiosInstance.put<APIResponse>(
      url,
      JSON.stringify(body),
      {
        headers: {
          'Content-Type': 'application/json',
          ...(contextId && { 'Context-Id': contextId }),
          ...(authToken && { Authorization: `Bearer ${authToken}` })
        }
      }
    )
    return result.data
  }
}

export default ticketingApi
