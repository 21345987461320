import React from 'react'

// Components
import { PricedItem, Title, Button } from 'marvel-components'

// Misc
import styled from 'styled-components'

// Types
import { ProductLineItem } from 'shared-types'

type Props = {
  products: ProductLineItem[]
  onRemoveProduct: (itemId: string) => void
}

const ProductWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;

  &:last-child {
    margin-bottom: 0;
  }
`

const ProductTitle = styled(Title)`
  padding: 0;
`

const BasketProductItems: React.FC<Props> = ({ products, onRemoveProduct }) => {
  return (
    <>
      {products.map((product: ProductLineItem, idx: number) => {
        const { name, quantity, total, itemId } = product
        const handleRemoveProduct = () => {
          onRemoveProduct && onRemoveProduct(itemId)
        }
        return (
          <PricedItem
            key={`priced-item-${idx}`}
            price={total && quantity ? total * quantity : 0}
            isFreeShown={true}
          >
            <ProductWrapper>
              <ProductTitle
                isCapitalised={false}
                isCentralised={false}
                size="medium-large"
                isPadded={false}
                isMultiline={true}
                isSidebar={true}
              >
                {`${name} x ${quantity}`}
              </ProductTitle>
              <Button
                buttonType="link-button"
                size="extra-small"
                isUnderlined={false}
                onClick={handleRemoveProduct}
                text="Remove"
                isSidebar={true}
              />
            </ProductWrapper>
          </PricedItem>
        )
      })}
    </>
  )
}

export { BasketProductItems }
