// Types
import { AppState } from 'modules/types'

// Adapters
import { areasAdapter } from './areaSlice'

// Selectors
export const {
  selectById,
  selectIds,
  selectEntities,
  selectAll,
  selectTotal
} = areasAdapter.getSelectors<AppState>((state) => state.hospitality.areas)

export const selectIsLoading = (state: AppState) =>
  state.hospitality.areas.loading === 'pending'
