import React, { useEffect } from 'react'

// Components
import {
  PrimaryButton,
  StickyFooter,
  BackButton,
  Layout,
  FooterNav
} from 'marvel-components'

// Containers
import {
  BrandLogoContainer,
  AvailabilityResultsContainer,
  AvailabilityRequestContainer,
  SelectMenuContainer
} from 'containers/Hospitality'

// Actions
import { occasionActions } from 'modules/hospitality/occasion'

// Selectors
import {
  reservationActions,
  reservationSelectors
} from 'modules/hospitality/reservation'
import { occasionSelectors } from 'modules/hospitality/occasion'
import { siteSelectors } from 'modules/hospitality/site'

// Misc
import {
  Route,
  Switch,
  useRouteMatch,
  useHistory,
  Redirect
} from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { BasketView } from 'views/Hospitality'
import {
  trackAddToCart,
  trackHospitalityAddToCart,
  trackHospitalityOccasion,
  useIsMobileViewport,
  usePlatform
} from 'utils'
import { useTypedSelector } from 'modules'
import { selectBookingFlow } from 'modules/basket/selectors'
import moment from 'moment'

const OccasionView: React.FC = () => {
  const dispatch = useDispatch()

  // Routing
  const {
    path,
    url,
    params: { siteId, occasionId }
  } = useRouteMatch<{
    siteId: string
    occasionId: string
  }>()
  const history = useHistory()

  // Selectors
  const adults = useSelector(reservationSelectors.selectAdultQuantity)
  const children = useSelector(reservationSelectors.selectChildQuantity)
  const selectedDateString = useSelector(
    reservationSelectors.selectSelectedDateString
  )
  const selectedTimeslot = useSelector(
    reservationSelectors.selectSelectedTimeslot
  )
  const selectedMenuId = useSelector(reservationSelectors.selectSelectedMenuId)
  const selectedMenu = useSelector(reservationSelectors.selectSelectedMenu)
  const reservationTotal = useSelector(
    reservationSelectors.selectReservationTotal
  )
  const occasionName = useTypedSelector(state =>
    occasionSelectors.selectOccasionName(state, occasionId)
  )
  const siteName = useTypedSelector(state =>
    siteSelectors.selectSiteName(state, siteId)
  )
  const bookingFlow = useSelector(selectBookingFlow)
  const platform = usePlatform()
  const isMobile = useIsMobileViewport()

  // Actions
  useEffect(() => {
    dispatch(occasionActions.fetchOccasionsForSite(siteId))
  }, [dispatch, siteId])

  // Analytics
  useEffect(() => {
    if (siteName && occasionName) {
      trackHospitalityOccasion({
        siteName,
        occasionName
      })
    }
  }, [siteName, occasionName])

  const handleCheckAvailability = () => {
    history.push(`${url}/availability`)
  }

  const handleBackClick = () => {
    history.push(`${url}/select-date`)
  }

  const handleConfirmTimeslot = () => {
    history.push(`${url}/menu`)
  }

  const handleConfirmMenu = () => {
    dispatch(reservationActions.addItemToBasket(occasionId))

    const datetime =
      selectedDateString && selectedTimeslot
        ? `${selectedDateString.split('T', 1)}T${selectedTimeslot}:00.000Z`
        : undefined

    // Analytics
    selectedMenu &&
      trackHospitalityAddToCart({
        platform,
        bookingFlow,
        item: {
          id: selectedMenu.id,
          name: selectedMenu.name,
          datetime: datetime
            ? moment(datetime).utc().format('YYYY-MM-DD/HH-mm')
            : undefined,
          price:
            selectedMenu.depositPerAdult * adults +
            selectedMenu.depositPerChild * children,
          quantity: adults + children
        }
      })

    selectedMenu &&
      trackAddToCart({
        id: selectedMenu.id,
        name: selectedMenu.name,
        category: `${selectedDateString}/${selectedTimeslot}`,
        price:
          selectedMenu.depositPerAdult * adults +
          selectedMenu.depositPerChild * children,
        quantity: adults + children,
        dimension10: 'New Purchase'
      })

    isMobile
      ? history.push(`${url}/basket`)
      : history.push(`${url}/basket/checkout`)
  }

  return (
    <Switch>
      <Route path={`${path}/select-date`}>
        <Layout
          pageTitle={`Select Hospitality Date | LW Theatres`}
          renderBrandLogo={<BrandLogoContainer />}
          renderMain={
            <AvailabilityRequestContainer
              siteId={siteId}
              occasionId={occasionId}
            />
          }
          renderSidebarBottom={
            adults > 0 && selectedDateString ? (
              <StickyFooter>
                <PrimaryButton
                  text='Check Availability'
                  onClick={handleCheckAvailability}
                />
                <FooterNav />
              </StickyFooter>
            ) : (
              <FooterNav />
            )
          }
        />
      </Route>
      {adults === 0 && <Redirect to={`${url}/select-date`} />}
      <Route path={`${path}/availability`}>
        <Layout
          pageTitle={`Select Hospitality Date | LW Theatres`}
          renderBrandLogo={<BrandLogoContainer />}
          renderMain={
            <AvailabilityResultsContainer
              siteId={siteId}
              occasionId={occasionId}
              adults={adults}
              children={children}
              selectedDateString={selectedDateString}
            />
          }
          renderSidebarBottom={
            selectedTimeslot ? (
              <StickyFooter>
                <PrimaryButton
                  text={`${selectedTimeslot} - Continue`}
                  onClick={handleConfirmTimeslot}
                  isDisabled={!selectedTimeslot}
                />
                <FooterNav />
              </StickyFooter>
            ) : (
              <FooterNav />
            )
          }
          renderMainTop={<BackButton onBackClick={handleBackClick} />}
        />
      </Route>
      <Route path={`${path}/menu`}>
        <Layout
          pageTitle={`Select Hospitality Menu | LW Theatres`}
          renderBrandLogo={<BrandLogoContainer />}
          renderMain={
            <SelectMenuContainer
              siteId={siteId}
              occasionId={occasionId}
              adults={adults}
              children={children}
              selectedDateString={selectedDateString}
              selectedTimeslot={selectedTimeslot}
            />
          }
          renderSidebarBottom={
            selectedMenuId ? (
              <StickyFooter>
                <PrimaryButton
                  amount={reservationTotal}
                  text={`Continue`}
                  icon={'lock'}
                  onClick={handleConfirmMenu}
                  isDisabled={!selectedMenuId}
                />
                <FooterNav />
              </StickyFooter>
            ) : (
              <FooterNav />
            )
          }
        />
      </Route>
      <Route path={`${path}/basket`}>
        <BasketView />
      </Route>
    </Switch>
  )
}

export { OccasionView }
