import ReactGA4 from 'react-ga4'

// Types
import {
  ECAddImpressionGA4,
  ECAddProductGA4,
  OrderDetailsGA4,
  OrderItemsGA4,
  ECPurchaseGA4
} from './typesGA4'
import { OrderProductItem } from 'shared-types'

export const getItemsFromOrder = ({
  order,
  platform,
  performanceDate,
  performanceTime
}: OrderDetailsGA4) => {
  return order
    ? [
        ...passItemFromOrder({
          items: order.basket.ticketReservations,
          platform,
          performanceDate,
          performanceTime,
          category: 'Ticket'
        }),
        ...passItemFromOrder({
          items: order.basket.productReservations,
          platform,
          performanceDate,
          performanceTime,
          category: 'Up-Sell'
        })
      ]
    : []
}

const passItemFromOrder = ({
  platform,
  performanceDate,
  performanceTime,
  items,
  category
}: OrderItemsGA4) => {
  const passedItems = items.reduce(
    (
      products: { [id: string]: ECAddProductGA4 },
      product: OrderProductItem
    ) => {
      const {
        eventId,
        performanceId,
        areaName,
        priceToPay,
        priceTypeName,
        priceLevelName,
        name
      } = product

      const productId = `${eventId}-${performanceId}-${areaName}-${priceTypeName}-${priceLevelName}`

      return {
        ...products,
        [productId]: {
          item_id: eventId,
          item_name: name,
          currency: 'GBP',
          item_category: `${performanceDate}/${performanceTime}`,
          item_category4: platform,
          item_category5: category,
          price: priceToPay,
          ...(performanceDate && {
            item_category: `${performanceDate}/${performanceTime}`
          }),
          quantity:
            (products[productId] ? products[productId]?.quantity ?? 0 : 0) + 1
        }
      }
    },
    {}
  )

  return Object.values(passedItems)
}

export const purchaseGA4 = ({
  items,
  reference,
  revenue,
  shipping,
  tax
}: ECPurchaseGA4) =>
  ReactGA4.gtag('event', 'purchase', {
    transaction_id: `${reference}`,
    affiliation: 'Marvel',
    value: `${revenue}`,
    tax: `${tax || 0}`,
    shipping: `${shipping || 0}`,
    currency: 'GBP',
    items
  })

export const addToCartGA4 = (items: ECAddProductGA4[]) =>
  ReactGA4.gtag('event', 'add_to_cart', {
    currency: 'GBP',
    items
  })

export const addImpressionGA4 = (items: ECAddImpressionGA4[]) =>
  ReactGA4.gtag('event', 'view_item', {
    currency: 'GBP',
    items
  })
