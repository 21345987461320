import React, { MouseEvent } from 'react'
import styled, { css } from 'styled-components/macro'
import { Button } from 'marvel-components'
import { darken, media } from 'utils'

export type NumberSelectorSize = 'default' | 'large' | 'small'

export interface NumberSelectorProps {
  numberSelectorSize?: NumberSelectorSize
  value: number
  decreaseDisabled?: boolean
  increaseDisabled?: boolean
  onIncrementClick: () => void
  onDecrementClick: () => void
  isThemed?: boolean
  isBlock?: boolean
  isSidebar?: boolean
  hideButtons?: boolean
  isInline?: boolean
}

const NumberSelectorWrapper = styled.div<{
  numberSelectorSize: NumberSelectorSize
  isThemed?: boolean
  isBlock?: boolean
  isSidebar?: boolean
  isInline?: boolean
}>((props) => {
  const { numberSelectorSize } = props
  const {
    theme: {
      colors: { mainBackgroundColor, numberFilterSelectorColor }
    },
    isThemed,
    isBlock,
    isSidebar,
    isInline
  } = props

  return css`
    display: flex;
    align-items: center;

    ${
      isBlock &&
      css`
        flex: 1 auto;
      `
    }

    ${
      isThemed &&
      css`
        background: ${darken(mainBackgroundColor, 10)};
        border-right: 1px solid ${darken(mainBackgroundColor, 5)};
      `
    }

      ${
        isSidebar &&
        css`
          background: ${numberFilterSelectorColor};
          border-right: 1px solid ${darken(mainBackgroundColor, 5)};
        `
      }

    ${
      numberSelectorSize === 'large' &&
      css`
        background: none;
        border-right: none;
        ${media.tablet} {
          background: none;
          border-right: none;
          max-width: none;
          margin-left: 0;
        }
      `
    }

      ${
        isSidebar &&
        css`
          background: ${numberFilterSelectorColor};
          border-right: 1px solid ${darken(mainBackgroundColor, 5)};
          ${media.tablet} {
            background: none;
            border-right: none;
          }
        `
      }


        ${
          isInline &&
          css`
            background: none;
            border-right: none;
          `
        }
  `
})

const NumberTitle = styled.div<{
  numberSelectorSize: NumberSelectorSize
  isThemed?: boolean
  isSidebar?: boolean
  isInline?: boolean
}>((props) => {
  const {
    theme: {
      colors: {
        headingColor,
        sidebarCopyColor,
        copyColor,
        numberFilterSelectorCopyColor
      },
      font: { button }
    },
    numberSelectorSize,
    isThemed,
    isSidebar,
    isInline
  } = props

  return css`
    display: flex;
    flex: 1;
    justify-content: center;
    font-size: 1.2rem;
    font-family: ${button};
    color: ${isThemed ? sidebarCopyColor : copyColor};
    min-width: 4rem;
    padding: 0.375rem 0;
    line-height: 1.25
    ${media.tablet} {
      font-size: 2rem;
    }

    ${media.phone} {
      font-size: 2rem;
      min-width: 3.5rem;
    }

    ${
      numberSelectorSize === 'large' &&
      css`
        font-size: 2.125rem;
        color: ${headingColor};
        ${media.tablet} {
          font-size: 2rem;
        }
        ${media.tablet} {
          font-size: 1.5rem;
        }
      `
    }

    ${
      numberSelectorSize === 'small' &&
      css`
        font-size: 1.5rem;
        color: ${headingColor};
        ${media.tablet} {
          font-size: 1.5rem;
          color: ${headingColor};
        }
      `
    }

      ${
        isSidebar &&
        css`
          color: ${numberFilterSelectorCopyColor};
          ${media.tablet} {
            color: ${sidebarCopyColor};
          }
        `
      }

        ${
          isInline &&
          css`
            color: ${headingColor};
            padding: 0;
            ${media.phone} {
              font-size: 1.5rem;
              min-width: 3.5rem;
            }
          `
        }
  `
})

const SquareButton = styled(Button)<{
  numberSelectorSize: NumberSelectorSize
  isInline?: boolean
}>((props) => {
  const {
    theme: {
      colors: { lwButtonColor, lwButtonTextColor }
    },
    numberSelectorSize,
    isInline
  } = props

  return css`
    border-radius: 0rem;
    ${media.tablet} {
      border-radius: 0.1rem;
    }
    ${numberSelectorSize === 'large' &&
    css`
      background-color: ${lwButtonColor};
      color: ${lwButtonTextColor};
      fill: ${lwButtonTextColor};

      :hover,
      :focus,
      :active {
        background: ${darken(lwButtonColor, 10)};
      }
    `}
    ${isInline &&
    css`
      background-color: ${lwButtonColor};
      color: ${lwButtonTextColor};
      fill: ${lwButtonTextColor};

      :hover,
      :focus,
      :active {
        background: ${darken(lwButtonColor, 10)};
      }
    `}
  `
})

export const NumberSelector = (props: NumberSelectorProps) => {
  const {
    decreaseDisabled = false,
    increaseDisabled = false,
    numberSelectorSize = 'default',
    onIncrementClick,
    onDecrementClick,
    value,
    isThemed = true,
    isBlock = true,
    isSidebar = false,
    hideButtons = false,
    isInline = false
  } = props

  const buttonSize = numberSelectorSize === 'large' ? 'large' : 'small'

  const handleDecrementClick = (e: MouseEvent) => {
    e.stopPropagation()
    onDecrementClick()
  }

  const handleIncrementClick = (e: MouseEvent) => {
    e.stopPropagation()
    onIncrementClick()
  }

  return (
    <NumberSelectorWrapper
      numberSelectorSize={numberSelectorSize}
      isThemed={isThemed}
      isBlock={isBlock}
      isSidebar={isSidebar}
      isInline={isInline}
    >
      {!hideButtons && (
        <SquareButton
          numberSelectorSize={numberSelectorSize}
          icon="minus"
          size={buttonSize}
          isDisabled={decreaseDisabled}
          onClick={handleDecrementClick}
          aria-label="reduce number selector"
          buttonType="sidebar"
          isInline={isInline}
        />
      )}
      <NumberTitle
        numberSelectorSize={numberSelectorSize}
        isThemed={isThemed}
        isSidebar={isSidebar}
        isInline={isInline}
      >
        {value}
      </NumberTitle>
      {!hideButtons && (
        <SquareButton
          icon="plus"
          size={buttonSize}
          isDisabled={increaseDisabled}
          onClick={handleIncrementClick}
          aria-label="increase number selector"
          buttonType="sidebar"
          numberSelectorSize={numberSelectorSize}
          isInline={isInline}
        />
      )}
    </NumberSelectorWrapper>
  )
}
