import { useHistory } from 'react-router-dom'
import styled from 'styled-components'
import { kioskMainMenu } from 'utils'
import menuIcon from 'assets/main_menu_icon.png'
import React from 'react'

const NavLinkWrapper = styled.button`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-decoration: none;
  z-index: 2;
  margin-right: 1.3rem;
  background: none;
  border: none;
  cursor: pointer;
`

const NavImage = styled.img`
  width: 2rem;
  height: 2rem;
`

const NavText = styled.span`
  text-transform: uppercase;
  letter-spacing: 0.1rem;
  font-size: 0.8em;
  margin-top: 0.75em;
  font-family: ${props => props.theme.font.header};
  color: ${props => props.theme.colors.lwBrandColorComplement};
`

type Props = {
  onClick?: () => void
}

const MainMenuNavButton: React.FC<Props> = ({ onClick }) => {
  const history = useHistory()

  const handleClick = () => {
    onClick ? onClick() : history.push(kioskMainMenu())
  }

  return (
    <NavLinkWrapper onClick={handleClick}>
      <NavImage src={menuIcon} />
      <NavText>Menu</NavText>
    </NavLinkWrapper>
  )
}

export { MainMenuNavButton }
