import React from 'react'
import styled from 'styled-components/macro'
import { withFormik, InjectedFormikProps, Form } from 'formik'
import { media } from 'utils'
import { createPasswordValidator } from '../validationSchemas'

// Components
import { FormInput, Button } from 'marvel-components'
import * as Yup from 'yup'

export interface PasswordFormValues {
  password: string
}

interface Props {
  isSubmitDisabled: boolean
  onSubmit: (
    values: PasswordFormValues,
    setSubmitting: (isSubmitting: boolean) => void
  ) => void
}

const FormWrapper = styled.div`
  display: flex;
  flex-direction: row;

  ${media.tablet} {
    flex-direction: column;
  }
`
const PasswordWrapper = styled.div`
  flex: 1 0 auto;
`
const SubmitWrapper = styled.div`
  margin-left: 1rem;

  ${media.tablet} {
    margin-left: 0;
  }
`

const InnerForm: React.SFC<InjectedFormikProps<
  Props,
  PasswordFormValues
>> = props => {
  const { isSubmitting, isSubmitDisabled } = props

  return (
    <Form>
      <FormWrapper>
        <PasswordWrapper>
          <FormInput
            name="password"
            type="password"
            placeholder="Password"
            autocomplete="password"
          />
        </PasswordWrapper>
        <SubmitWrapper>
          <Button
            type="submit"
            isBlock={true}
            text="Create Account"
            size="small"
            isLoading={isSubmitting}
            isDisabled={isSubmitDisabled}
          />
        </SubmitWrapper>
      </FormWrapper>
    </Form>
  )
}

export const PasswordForm = withFormik<Props, PasswordFormValues>({
  mapPropsToValues: () => ({
    password: ''
  }),
  validationSchema: Yup.object().shape({
    password: createPasswordValidator
  }),
  handleSubmit: (values, { props, setSubmitting }) => {
    props.onSubmit(values, setSubmitting)
  }
})(InnerForm)
