import { createSlice, createEntityAdapter } from '@reduxjs/toolkit'

// Utils
import hospitalityApi from 'services/hospitalityApi'
import { createAPIThunk } from 'modules/smart-waiter/shared/actions'
import { areasEntity } from 'services/hospitalitySchemas'

// Types
import { HospitalityArea } from 'shared-types'

type AreasResponse = {
  areas?: { [key: string]: HospitalityArea }
}
// Actions

export const fetchAreasForSite = createAPIThunk<AreasResponse, string>(
  'marvel/hospitality/FETCH_AREAS',
  (siteId: string) => hospitalityApi.fetchAll(`/site/${siteId}/areas/`),
  areasEntity
)

// Reducer

export const areasAdapter = createEntityAdapter<HospitalityArea>({
  sortComparer: (a, b) => a.name.localeCompare(b.name)
})

const areaSlice = createSlice({
  name: 'areas',
  initialState: areasAdapter.getInitialState({
    loading: 'idle'
  }),
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchAreasForSite.pending, (state, action) => {
      state.loading = 'pending'
    })
    builder.addCase(fetchAreasForSite.fulfilled, (state, action) => {
      if (action.payload.areas) {
        areasAdapter.setAll(state, action.payload.areas)
      }
      state.loading = 'idle'
    })
  }
})

export default areaSlice.reducer
