import React, { useState, useEffect, useRef } from 'react'
import styled from 'styled-components/macro'
import moment from 'moment'
import { media } from 'utils'

interface Props {
  countdownMs: number
  onExpiry: () => void

  warningOffsetMs: number
  onWarning: () => void
}

const CountdownTimerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: ${(props) => props.theme.font.copy};
  color: ${(props) => props.theme.colors.headingColor};
  position: relative;
  line-height: 1;
  ${media.tablet} {
    margin-right: 0;
    flex-direction: row;
  }
`

const CountdownTimerTitle = styled.span`
  font-size: 0.75rem;
`

const CountdownTimerTime = styled.span`
  position: absolute;
  left: 63%;
  top: 1rem;
  font-family: ${(props) => props.theme.font.header};
  font-size: 1rem;
  transform: translateX(-2.375rem);
  ${media.tablet} {
    position: relative;
    transform: none;
    left: 0;
    top: 0;
    min-width: 3rem;
    text-align: left;
    padding-left: 0.5rem;
  }
`

const CountdownTimer: React.FC<Props> = ({
  countdownMs,
  warningOffsetMs,
  onExpiry,
  onWarning
}) => {
  const [now, setTime] = useState(moment())
  const [end, setEndTime] = useState(moment().add(countdownMs, 'ms'))
  const [finalCallTime, setFinalCallTime] = useState(
    moment().add(warningOffsetMs, 'ms')
  )
  const [isFinalCallTriggered, setFinalCallTriggered] = useState(false)

  const intervalRef = useRef<NodeJS.Timeout>()

  const updateTime = () => {
    setTime(moment())
  }

  useEffect(() => {
    intervalRef.current = setInterval(updateTime, 1000)
    return () => intervalRef.current && clearInterval(intervalRef.current)
  }, [])

  // Update Expiry Time
  useEffect(() => {
    setEndTime(moment().add(countdownMs, 'ms'))
    setFinalCallTime(moment().add(countdownMs - warningOffsetMs, 'ms'))
  }, [countdownMs, warningOffsetMs])

  useEffect(() => {
    if (now.isAfter(end)) {
      onExpiry()
      intervalRef.current && clearInterval(intervalRef.current)
    }
    if (!isFinalCallTriggered && now.isAfter(finalCallTime)) {
      setFinalCallTriggered(true)
      onWarning()
    }
  }, [
    now,
    end,
    intervalRef,
    isFinalCallTriggered,
    finalCallTime,
    onWarning,
    onExpiry
  ])

  return (
    <CountdownTimerWrapper>
      {moment(now).isBefore(moment(end)) && (
        <>
          <CountdownTimerTitle>Time left to book:</CountdownTimerTitle>
          <CountdownTimerTime>
            {moment(end.diff(now)).format('mm:ss')}
          </CountdownTimerTime>
        </>
      )}
    </CountdownTimerWrapper>
  )
}

export { CountdownTimer }
