import { takeLatest } from 'redux-saga/effects'
import * as actions from './actions'
import * as api from 'services/api'
import { sharedOperations } from 'modules/shared'

// Fetch Discount Performance
// ---------------------------
export function* watcherFetchDiscountedPerformance() {
  yield takeLatest(actions.fetchDiscountedPerformance.TRIGGER, fetchDiscountedPerformance)
}

const fetchDiscountedPerformance = sharedOperations.fetchEntity.bind(
  null,
  actions.fetchDiscountedPerformance,
  api.fetchDiscountedPerformance
)