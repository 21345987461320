import { Divider, DividerContent } from '../Divider/Divider'

// Misc
import styled from 'styled-components/macro'
import { media } from 'utils'

const ListDivider = styled(Divider)`
  ${DividerContent} {
    margin: 0 calc(-2% / 0.96);
    ${media.tablet} {
      padding: 0.5rem 3%;
      margin: 0 calc(-3% / 0.94);
    }
    ${media.phone} {
      padding: 0.5rem 5%;
      margin: 0 calc(-5% / 0.9);
    }
  }
`
export { ListDivider }
