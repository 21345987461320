import React, { useEffect } from 'react'

// Actions
import { performanceActions } from 'modules/ticketing/performance/performanceSlice'

// Selectors
import { authSelectors } from 'modules/ticketing/auth/authSlice'

// Views
import {
  SelectTicketsView,
  PrePurchaseProductsView,
  SelectPerformanceView
} from 'views/Ticketing'

// Misc
import { useDispatch, useSelector } from 'react-redux'
import { Route, Switch, useRouteMatch } from 'react-router-dom'

type Props = {
  eventId: string
  promoCode: string | null
  discountCode: string | null
}

const PerformancesView: React.FC<Props> = ({
  eventId,
  promoCode,
  discountCode
}) => {
  const dispatch = useDispatch()

  // Selectors
  const user = useSelector(authSelectors.selectUser)

  // Routing
  const { path } = useRouteMatch()

  // Fetch Performances
  // ------------------------------------------
  useEffect(() => {
    eventId &&
      dispatch(
        performanceActions.fetchPerformances({
          eventId,
          discountCode: discountCode,
          promoCode: promoCode,
          noCache: !!user?.id
        })
      )
  }, [eventId, discountCode, promoCode, user?.id, dispatch])

  return (
    <>
      <Switch>
        {/* Select Tickets */}
        <Route exact path={`${path}/performance/:performanceId`}>
          <SelectTicketsView />
        </Route>
        {/* Products View */}
        <Route exact path={`${path}/performance/:performanceId/products`}>
          <PrePurchaseProductsView />
        </Route>
        {/* Select Performance */}
        <Route path={`${path}`}>
          <SelectPerformanceView eventId={eventId} />
        </Route>
      </Switch>
    </>
  )
}

export { PerformancesView }
