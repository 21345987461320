// Misc
import {
  createSlice,
  createEntityAdapter,
  PayloadAction
} from '@reduxjs/toolkit'

// Actions
import { fetchMenu } from 'modules/smart-waiter/menu/actions'

// Types
import { MenuItem } from 'shared-types'

export const menuItemAdapter = createEntityAdapter<MenuItem>({
  sortComparer: (a, b) => a.sortOrder - b.sortOrder
})

const menuItemSlice = createSlice({
  name: 'menuItems',
  initialState: menuItemAdapter.getInitialState({
    loading: 'idle',
    minimumAge: 0
  }),
  reducers: {
    setMinimumAge: (state, action: PayloadAction<number>) => {
      state.minimumAge = action.payload
    }
  },
  extraReducers: (builder) => {
    builder.addCase(fetchMenu.pending, (state, action) => {
      state.loading = 'pending'
    })
    builder.addCase(fetchMenu.fulfilled, (state, action) => {
      if (action.payload.items) {
        menuItemAdapter.setAll(state, action.payload.items)
      }
      state.loading = 'idle'
    })
  }
})

// Actions
export const menuItemActions = menuItemSlice.actions

// Reducer
export default menuItemSlice.reducer
