import React, { useEffect } from 'react'

// Components
import {
  Title,
  SelectableListItem,
  List,
  WithLoader,
  Notification
} from 'marvel-components'

// Selectors
import { occasionSelectors } from 'modules/hospitality/occasion'
import { slotSelectors } from 'modules/hospitality/slot'
import { reservationSelectors } from 'modules/hospitality/reservation'

// Actions
import { slotActions } from 'modules/hospitality/slot'
import { reservationActions } from 'modules/hospitality/reservation'

// Misc
import { useSelector, useDispatch } from 'react-redux'
import styled from 'styled-components'
import { media, usePlatform, useIsNarrowViewport } from 'utils'
import moment from 'moment'

// Types
import { AppState } from 'modules/types'

const AvailabilityContainerWrapper = styled.div<{ platform?: string }>`
  box-sizing: border-box;
  padding: 0 1.5rem 2.5rem 1.5rem;
  ${media.tablet} {
    padding: 0 1rem 1rem 1rem;
    min-height: ${props => (props.platform === 'kiosk' ? '65vh' : 'auto')};
  }
`

const TimeslotListWrapper = WithLoader(styled.div`
  margin: 0 0 1rem 0;
`)

const TimeslotListListWrapper = styled.div<{ platform?: string }>`
  ${media.tablet} {
    margin-top: ${props => (props.platform === 'kiosk' ? '1.5rem' : '0')};
  }
`

const BookingPrompt = styled(Notification)`
  background: #eaeaea;
  margin-top: 0;
  ${media.phone} {
    margin: 0 0 1.5rem 0;
  }
`

type Props = {
  siteId: string
  occasionId: string
  adults: number
  children: number
  selectedDateString?: string
  platform?: string
}
const AvailabilityResultsContainer: React.FC<Props> = ({
  siteId,
  occasionId,
  adults,
  children,
  selectedDateString
}) => {
  const dispatch = useDispatch()

  // Selectors
  const occasion = useSelector((state: AppState) =>
    occasionSelectors.selectById(state, occasionId)
  )
  const selectedDate = selectedDateString ? new Date(selectedDateString) : null
  const timeSlot = useSelector(
    (state: AppState) =>
      selectedDate &&
      slotSelectors.selectById(state, moment(selectedDate).format('YYYY-MM-DD'))
  )
  const isLoading = useSelector(slotSelectors.selectIsLoading)
  const selectedTimeslot = useSelector(
    reservationSelectors.selectSelectedTimeslot
  )

  // Actions
  useEffect(() => {
    if (!timeSlot && selectedDateString && adults > 0) {
      dispatch(
        slotActions.fetchAll({
          siteId,
          occasionId,
          dateFrom: selectedDateString,
          adults: adults,
          ...(children > 0 && { children: children })
        })
      )
    }
  }, [
    dispatch,
    siteId,
    occasionId,
    adults,
    children,
    selectedDateString,
    timeSlot
  ])

  const handleSelectTime = (time: EntityId) => {
    const selectedTime = time as string
    dispatch(reservationActions.selectTime(selectedTime))
  }

  const platform = usePlatform()
  const isNarrowViewport = useIsNarrowViewport()

  return (
    <AvailabilityContainerWrapper platform={platform}>
      {occasionId === 'ec389739-b051-4043-b093-33509615213d' && (
        <BookingPrompt
          title={'Booking Info'}
          message={`
          <p>
            If you are also coming to see a show, please book an Afternoon Tea
            sitting that starts at least 2 hours before the performance. That
            way you will have ample time to relax and still get to your seats
            in good time for curtain up.
          </p>
          <p>
            Please note: This is a table reservation for Afternoon Tea, the
            full amount will be due on the day at the end of your experience.
          </p>
          <p>Prices start at £59 for Adults and £35 for Children.</p>
        `}
        />
      )}
      <Title
        isCapitalised={false}
        isCentralised={false}
        isBold={true}
        size={platform === 'kiosk' && isNarrowViewport ? 'large' : 'medium'}
      >
        {occasion && occasion.name}
      </Title>
      {timeSlot ? (
        <TimeslotListWrapper isLoading={isLoading}>
          <Title
            isCapitalised={false}
            isCentralised={false}
            isBold={true}
            size={platform === 'kiosk' && isNarrowViewport ? 'medium' : 'small'}
          >
            {`${moment(timeSlot.date).format(
              'dddd Do MMM YYYY'
            )}, available times:`}
          </Title>
          <TimeslotListListWrapper platform={platform}>
            <List noPadding={true}>
              {timeSlot.times.map((time, idx) => (
                <SelectableListItem
                  key={idx}
                  title={time.time}
                  isDisabled={!time.available || time.isClosed}
                  centered={true}
                  id={time.time}
                  onSelect={handleSelectTime}
                  isSelected={selectedTimeslot === time.time}
                />
              ))}
            </List>
          </TimeslotListListWrapper>
        </TimeslotListWrapper>
      ) : null}
    </AvailabilityContainerWrapper>
  )
}

export { AvailabilityResultsContainer }
