import React from 'react'

// Components
import { Layout } from 'marvel-components'

// Containers
import {
  BrandLogoContainer,
  ExchangeContainerSuccess,
  OrderSummaryContainer
} from 'containers/Ticketing'

const ExchangeViewSuccess: React.FC = () => {
  return (
    <Layout
      pageTitle={`LW Ticket Exchange | LW Theatres`}
      renderBrandLogo={<BrandLogoContainer />}
      renderSidebarTop={<OrderSummaryContainer />}
      renderMain={<ExchangeContainerSuccess />}
    />
  )
}

export { ExchangeViewSuccess }
