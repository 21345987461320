import { fork } from 'redux-saga/effects'
import { combineReducers } from 'redux'
import entities from 'modules/entities'

// Modules
import banner from './banner'
import overlay from './overlay'
import basket, { basketOperations } from 'modules/basket'
import seatMap, { seatMapOperations } from 'modules/seat-map'
import prompts, { promptOperations } from 'modules/prompt'
import interactions from 'modules/interaction'

import optins, { optinOperations } from 'modules/optin'
import discount, { discountOperations } from 'modules/discount'
import { sharedOperations } from 'modules/shared'
import smartWaiter from 'modules/smart-waiter'
import hospitality from 'modules/hospitality'
import ticketing from 'modules/ticketing'
import selfServe from 'modules/self-serve'

import { TypedUseSelectorHook, useSelector, shallowEqual } from 'react-redux'

// Sagas
export function* rootSaga() {
  // Seat Map
  yield fork(seatMapOperations.watcherFetchSeatMap)

  // Products
  yield fork(promptOperations.watcherFetchPrompts)

  // Basket
  yield fork(basketOperations.watcherTriggerReservation)
  yield fork(basketOperations.watcherCreateReservation)
  yield fork(basketOperations.watcherUpdateReservation)
  yield fork(basketOperations.watcherProductAdded)
  yield fork(basketOperations.watcherModifyReservationSuccess)
  yield fork(basketOperations.watcherCreateOrder)
  yield fork(basketOperations.watcherExpireRedirect)
  yield fork(basketOperations.watcherUpdatePaymentDetails)
  yield fork(basketOperations.watchUpdateOrder)
  yield fork(basketOperations.watchUpdateOrderSuccess)
  yield fork(basketOperations.watcherFetchReservation)
  yield fork(basketOperations.watcherClearReservationOnServer)
  yield fork(basketOperations.watcherClearReservation)
  yield fork(basketOperations.watcherCreateExchange)
  yield fork(basketOperations.watcherFetchOrder)
  yield fork(basketOperations.watcherFetchOrderFromToken)
  yield fork(basketOperations.watcherCreateOrderToken)

  // Social Distanced Seating
  yield fork(basketOperations.watcherAddSeatBlock)
  yield fork(basketOperations.watcherRemoveSeatBlock)

  // Optins
  yield fork(optinOperations.watcherFetchOptins)

  // Discount
  yield fork(discountOperations.watcherFetchDiscountedPerformance)

  // API Calls
  yield fork(sharedOperations.watchRequests)
}

// Reducers
const rootReducer = combineReducers({
  interactions,
  entities,
  seatMap,
  overlay,
  basket,
  banner,
  prompts,
  optins,
  discount,
  smartWaiter,
  hospitality,
  ticketing,
  selfServe
})

export type AppState = ReturnType<typeof rootReducer>

// Selectors
export const useTypedSelector: TypedUseSelectorHook<AppState> = useSelector

export const useShallowEqualSelector = <TSelected>(
  selector: (state: AppState) => TSelected
): TSelected => useSelector(selector, shallowEqual)

export default rootReducer
