import React, { useEffect } from 'react'

// Components
import { PrimaryButton, StickyFooter, Layout } from 'marvel-components'

// Containers
import {
  BrandLogoContainer,
  BasketSummaryContainer,
  ProductDetailContainer
} from 'containers/SmartWaiter'

// Actions
import { basketActions } from 'modules/smart-waiter/basket'

// Selectors
import {
  selectCurrentItemTotal,
  selectCurrentItemForBasket
} from 'modules/smart-waiter/basket/selectors'

// Misc
import { useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import {
  trackAddToCart,
  usePlatform,
  trackSmartWaiterViewItem,
  trackSmartWaiterAddToCart
} from 'utils'
import { productSelectors } from 'modules/smart-waiter/product'
import { selectBookingFlow } from 'modules/basket/selectors'
import { AppState } from 'modules'

const ProductView: React.FC = () => {
  // Routing
  const { venueId, salesAreaId, serviceId, menuId, productId } =
    useParams<{
      venueId: string
      salesAreaId: string
      serviceId: string
      menuId: string
      productId: string
    }>()

  const dispatch = useDispatch()
  const history = useHistory()

  // Selector
  const currentItemPrice = useSelector(selectCurrentItemTotal)
  const currentItem = useSelector(selectCurrentItemForBasket)
  const products = useSelector(productSelectors.selectEntities)
  const platform = usePlatform()
  const bookingFlow = useSelector(selectBookingFlow)
  const product = useSelector((state: AppState) =>
    productSelectors.selectById(state, productId)
  )

  // Analytics
  useEffect(() => {
    // Check that the current item and product are the same
    // Current item update happens in child component
    product &&
      product?.id === currentItem?.id &&
      trackSmartWaiterViewItem({
        platform,
        bookingFlow,
        item: {
          id: String(product.id),
          name: product.name,
          price: currentItemPrice
        }
      })
  }, [bookingFlow, currentItem, currentItemPrice, platform, product])

  // Actions
  const handleAddToBasket = () => {
    if (currentItem) {
      dispatch(basketActions.addItemToBasket(currentItem, menuId))

      trackSmartWaiterAddToCart({
        bookingFlow,
        platform,
        item: {
          id: String(currentItem.id),
          name: products[currentItem.id]?.name || `${currentItem.id}`,
          price: currentItem.price,
          quantity: currentItem.quantity
        }
      })

      trackAddToCart({
        id: currentItem.id,
        name: products[currentItem.id]?.name || `${currentItem.id}`,
        price: currentItem.price,
        quantity: currentItem.quantity,
        variant: products[currentItem.id]?.portions.filter(
          portion => portion.id === currentItem.portionId
        )[0]?.name,
        dimension10: 'New Purchase'
      })

      history.push(
        `/smart-waiter/venue/${venueId}/area/${salesAreaId}/service/${serviceId}/menu/${menuId}/section`
      )
    }
  }

  return (
    <Layout
      pageTitle={`Select Product | LW Theatres`}
      renderBrandLogo={<BrandLogoContainer />}
      renderMain={<ProductDetailContainer productId={productId} />}
      renderSidebarTop={<BasketSummaryContainer hideOnMobile={true} />}
      renderSidebarBottom={
        <StickyFooter>
          <PrimaryButton
            text='Add To Basket'
            icon='lock'
            amount={currentItemPrice}
            onClick={handleAddToBasket}
          />
        </StickyFooter>
      }
    />
  )
}

export { ProductView }
