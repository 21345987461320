// Misc
import { useAppDispatch } from 'app/reduxStore'
import React, { useEffect } from 'react'
import { Route, Switch, useRouteMatch } from 'react-router-dom'
import { useIsIdle } from 'utils'
import moment from 'moment'

// Views
import { VenueHomeView } from './VenueHomeView'
import { VenueEventsView } from './VenueEventsView'

// Actions
import { venueActions } from 'modules/self-serve/venue'
import { eventActions } from 'modules/self-serve/event'
import { eventCollectionActions } from 'modules/self-serve/event-collection'
import { venuePageActions } from 'modules/self-serve/venue-page'
import { genreActions } from 'modules/self-serve/genre'
import { openModal } from 'modules/overlay/actions'

const VenueView = () => {
  const { path } = useRouteMatch()
  const dispatch = useAppDispatch()
  const isIdle = useIsIdle(moment.duration().add(60, 'seconds'), false)

  // Actions
  // -------------
  useEffect(() => {
    dispatch(venueActions.fetchVenues())
  }, [dispatch])

  useEffect(() => {
    dispatch(eventActions.fetchEvents())
  }, [dispatch])

  useEffect(() => {
    dispatch(eventCollectionActions.fetchEventCollections())
  }, [dispatch])

  useEffect(() => {
    dispatch(venuePageActions.fetchVenuePages())
  }, [dispatch])

  useEffect(() => {
    dispatch(genreActions.fetchGenres())
  }, [dispatch])

  useEffect(() => {
    if (isIdle) {
      dispatch(
        openModal({
          variant: 'kiosk-inactivity',
          props: {
            redirectAfterSeconds: 60
          }
        })
      )
    }
  }, [dispatch, isIdle])

  return (
    <Switch>
      <Route exact path={`${path}/:venueId`}>
        <VenueHomeView />
      </Route>
      <Route exact path={`${path}/:venueId/event`}>
        <VenueEventsView />
      </Route>
    </Switch>
  )
}

export { VenueView }
