import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { genreAdapter } from './selectors'
import { fetchGenres } from './actions'

type AdditionalState = {
  status: 'idle' | 'pending' | 'fulfilled' | 'rejected'
  selectedGenres: string[]
}

const initialState = genreAdapter.getInitialState<AdditionalState>({
  status: 'idle',
  selectedGenres: []
})

const genreSlice = createSlice({
  name: 'genre',
  initialState,
  reducers: {
    selectGenres: (state, action: PayloadAction<string[]>) => {
      state.selectedGenres = action.payload
    }
  },
  extraReducers: builder => {
    builder
      .addCase(fetchGenres.fulfilled, (state, action) => {
        genreAdapter.setAll(state, action.payload.genres)
        state.status = 'fulfilled'
      })
      .addCase(fetchGenres.rejected, state => {
        genreAdapter.removeAll(state)
        state.status = 'rejected'
      })
      .addCase(fetchGenres.pending, state => {
        state.status = 'pending'
      })
  }
})

export const genreActions = {
  fetchGenres,
  ...genreSlice.actions
}

export default genreSlice.reducer
