import { selectById, selectId } from '../entities'
// Misc
import { createSelector } from '@reduxjs/toolkit'

// Types
import { OptinQuestion } from 'shared-types'
import { AppState } from 'modules/types'

export const selectIsLoading = (state: AppState) =>
  state.optins.loading !== 'idle'

export const selectOptinEntities = (state: AppState) =>
  state.entities.optins ?? {}
export const selectOptinIds = (state: AppState) => state.optins.byId

export const selectOptinById = createSelector(
  selectOptinEntities,
  selectId,
  selectById
)
export const selectAllOptins = createSelector(
  selectOptinEntities,
  selectOptinIds,
  (optins, ids) => ids.map(id => optins[id] as OptinQuestion)
)
