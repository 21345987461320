export const eventIds = {
  development: {
    frozen: 'c0eb0b22-9ed4-e911-a9cf-e30ef57ea91f',
    schoolOfRock: '92430ccf-6456-e711-80db-005056010001',
    phantom: 'f36eaa21-5d2b-e711-80da-005056010001',
    cinderella: 'ba5ebb09-d74c-ea11-a9ca-064c84cbe4ea',
    joseph: 'bbceee63-ebd2-e911-a9ca-064c84cbe4ea',
    josephSd: '2a05666b-fbd3-eb11-830a-d2856258cf1f',
    matilda: '11169c9e-0f0f-e711-80da-005056010001',
    sd: '07ccbae2-92c6-ea11-a9d0-81285d728767',
    elizabethDay: 'cc1915cd-c6e3-ea11-a9d0-81285d728767',
    ranulph: '733d9ff7-0be9-ea11-a9ca-064c84cbe4ea',
    songs: '10ed8234-38ec-ea11-a9ca-064c84cbe4ea',
    wenger: '30d9ae48-49e8-ea11-a9ca-064c84cbe4ea',
    backToTheFuture: '7c05f941-0adb-ea11-a9ca-064c84cbe4ea',
    graham: 'a491f894-b0ee-ea11-a9ca-064c84cbe4ea',
    van: 'c9bc3882-f2c3-e911-a9ca-064c84cbe4ea',
    russellBrand: 'ad1a6c1d-5602-eb11-a9ca-064c84cbe4ea',
    patp: '1a831e85-f3b7-eb11-830a-ffabbca61fd3',
    rickWakeman: '4aab28b5-f806-eb11-a9ca-064c84cbe4ea',
    danielSloss: '63802cdf-7e51-ea11-a9ca-064c84cbe4ea',
    andrewCotter: '2770ba44-cd0e-eb11-a9ca-064c84cbe4ea',
    dawnFrench: '71f2a686-9417-eb11-a9ca-064c84cbe4ea',
    hair: 'fa9dbfcb-7018-eb11-a9ca-064c84cbe4ea',
    houseAndGarage: 'e95c2cf4-9113-eb11-a9ca-064c84cbe4ea',
    hawkwind: 'ed4089e7-eb12-eb11-a9ca-064c84cbe4ea',
    midgeUre: 'e994b503-e212-eb11-a9ca-064c84cbe4ea',
    dualers: 'c7c02820-da12-eb11-a9ca-064c84cbe4ea',
    ministryOfSound: '881b8582-9f25-eb11-a9ca-064c84cbe4ea',
    specialPanto: '8e90305e-762a-eb11-a9ca-064c84cbe4ea',
    bond: 'cd038743-a325-eb11-a9ca-064c84cbe4ea',
    phantomNew: 'bc14ad9b-0933-eb11-a9ca-064c84cbe4ea',
    philWang: '2400dd37-1d83-eb11-82e4-81b2c225e32f',
    leeMead: '3799959c-328b-eb11-82e4-ce2cd4e1f8c9',
    hereComeBoys: '3c98f55f-2c92-eb11-82e4-c2b60cd96b63',
    cinderellaSd: '44a08c51-48cf-eb11-830a-d2856258cf1f',
    keepTheReceipts: '091070e8-4eb9-eb11-830a-f99f54b41ac4',
    trdlTours: 'e67f2c81-bf36-ea11-a9ca-064c84cbe4ea',
    beautyAndTheBeast: '7ef7d6fc-2063-ec11-8350-c651b853079a',
    jackAndTheBeanstalk: '9656f4e2-3188-ec11-8374-b16896526c01',
    lionWitchWardrobe: '29289f19-b1cf-ec11-8396-fb91f64840e8',
    backToTheFutureO2Lounge: 'c0ecc16d-82ad-eb11-830a-c3a414c147a3',
    frozenO2Lounge: '92e447bb-e3ac-eb11-82e5-a851811d4564',
    cinderellaO2Lounge: '65bb44a8-83ad-eb11-830a-c3a414c147a3',
    o2Lounge: 'b871dd05-bd73-ec11-8350-e8de156b358e',
    lehman: '471831c4-aafb-ec11-83ae-934fcaed83da',
    oz: '6a33571d-fe59-ed11-83d1-9b2bf61c319e',
    crazyForYou: 'c1b6ba6a-ad69-ed11-83d1-e5cd89c867cc',
    peterPan: '944cb13d-b8c4-ed11-842c-9000f0e65ce7',
    satse: '2af7e265-e5fa-ed11-8444-b4d8df8cf789'
  },
  production: {
    frozen: '2c62fa9a-9025-ea11-a9ca-064c84cbe4ea',
    schoolOfRock: '92430ccf-6456-e711-80db-005056010001',
    phantom: 'f36eaa21-5d2b-e711-80da-005056010001',
    cinderella: 'ba5ebb09-d74c-ea11-a9ca-064c84cbe4ea',
    joseph: 'bbceee63-ebd2-e911-a9ca-064c84cbe4ea',
    josephSd: '2a05666b-fbd3-eb11-830a-d2856258cf1f',
    matilda: '11169c9e-0f0f-e711-80da-005056010001',
    sd: 'ca196df7-a7c6-ea11-a9ca-064c84cbe4ea',
    elizabethDay: '826c8f0e-d1e6-ea11-a9ca-064c84cbe4ea',
    ranulph: '733d9ff7-0be9-ea11-a9ca-064c84cbe4ea',
    songs: '10ed8234-38ec-ea11-a9ca-064c84cbe4ea',
    wenger: '30d9ae48-49e8-ea11-a9ca-064c84cbe4ea',
    backToTheFuture: '7c05f941-0adb-ea11-a9ca-064c84cbe4ea',
    graham: 'a491f894-b0ee-ea11-a9ca-064c84cbe4ea',
    van: 'c9bc3882-f2c3-e911-a9ca-064c84cbe4ea',
    russellBrand: 'ad1a6c1d-5602-eb11-a9ca-064c84cbe4ea',
    patp: '1a831e85-f3b7-eb11-830a-ffabbca61fd3',
    rickWakeman: '4aab28b5-f806-eb11-a9ca-064c84cbe4ea',
    danielSloss: '63802cdf-7e51-ea11-a9ca-064c84cbe4ea',
    andrewCotter: '2770ba44-cd0e-eb11-a9ca-064c84cbe4ea',
    dawnFrench: '71f2a686-9417-eb11-a9ca-064c84cbe4ea',
    hair: 'fa9dbfcb-7018-eb11-a9ca-064c84cbe4ea',
    houseAndGarage: 'e95c2cf4-9113-eb11-a9ca-064c84cbe4ea',
    hawkwind: 'ed4089e7-eb12-eb11-a9ca-064c84cbe4ea',
    midgeUre: 'e994b503-e212-eb11-a9ca-064c84cbe4ea',
    dualers: 'c7c02820-da12-eb11-a9ca-064c84cbe4ea',
    ministryOfSound: '881b8582-9f25-eb11-a9ca-064c84cbe4ea',
    specialPanto: '8e90305e-762a-eb11-a9ca-064c84cbe4ea',
    bond: 'cd038743-a325-eb11-a9ca-064c84cbe4ea',
    phantomNew: 'bc14ad9b-0933-eb11-a9ca-064c84cbe4ea',
    philWang: '2400dd37-1d83-eb11-82e4-81b2c225e32f',
    leeMead: '3799959c-328b-eb11-82e4-ce2cd4e1f8c9',
    hereComeBoys: '3c98f55f-2c92-eb11-82e4-c2b60cd96b63',
    cinderellaSd: '44a08c51-48cf-eb11-830a-d2856258cf1f',
    keepTheReceipts: '091070e8-4eb9-eb11-830a-f99f54b41ac4',
    trdlTours: 'e67f2c81-bf36-ea11-a9ca-064c84cbe4ea',
    beautyAndTheBeast: '7ef7d6fc-2063-ec11-8350-c651b853079a',
    jackAndTheBeanstalk: '9656f4e2-3188-ec11-8374-b16896526c01',
    lionWitchWardrobe: '29289f19-b1cf-ec11-8396-fb91f64840e8',
    backToTheFutureO2Lounge: 'c0ecc16d-82ad-eb11-830a-c3a414c147a3',
    frozenO2Lounge: '92e447bb-e3ac-eb11-82e5-a851811d4564',
    cinderellaO2Lounge: '65bb44a8-83ad-eb11-830a-c3a414c147a3',
    o2Lounge: 'b871dd05-bd73-ec11-8350-e8de156b358e',
    lehman: '471831c4-aafb-ec11-83ae-934fcaed83da',
    oz: '6a33571d-fe59-ed11-83d1-9b2bf61c319e',
    crazyForYou: 'c1b6ba6a-ad69-ed11-83d1-e5cd89c867cc',
    peterPan: '944cb13d-b8c4-ed11-842c-9000f0e65ce7',
    satse: '2af7e265-e5fa-ed11-8444-b4d8df8cf789'
  }
}
