import React, { useEffect } from 'react'

// Containers
import { ProductChoicesContainer } from './ProductChoicesContainer'
import { BreadcrumbContainer } from '../../BreadcrumbContainer'

// Components
import { OptionListItem, NumberSelector, Divider } from 'marvel-components'

// Selectors
import { productSelectors } from 'modules/smart-waiter/product'
import { basketSelectors } from 'modules/smart-waiter/basket'
import { menuItemSelectors } from 'modules/smart-waiter/menu-item'

// Actions
import { basketActions } from 'modules/smart-waiter/basket'
import { openModal } from 'modules/overlay/actions'

// Misc
import { useSelector } from 'react-redux'
import { useAppDispatch } from 'app/reduxStore'
import { useParams } from 'react-router'
import styled from 'styled-components'
import { media } from 'utils'
import { smartWaiterRoutes } from '../SmartWaiterRoutes'

// Types
import { AppState } from 'modules/types'

interface Props {
  productId: React.ReactText
}

const ProductDetailWrapper = styled.div``

const ListItemDetailsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 0 0;
  margin-bottom: 2rem;
  padding: 1.5rem 2% 0 2%;
  ${media.tablet} {
    padding: 1.5rem 3% 0 3%;
  }
  ${media.phone} {
    padding: 1.5rem 5% 0 5%;
  }
`

const ListItemHeader = styled.span`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`

const ListItemTitleWrapper = styled.span`
  display: flex;
  flex-direction: row;
  align-items: center;
`

const ListItemTitle = styled.span`
  font-size: 1rem;
  font-family: ${(props) => props.theme.font.header};
  padding: 0.5rem 0 0 0;
`

const ListItemAgeWrapper = styled.span`
  margin-left: 0.5rem;
  width: 18px;
  height: 18px;
  line-height: 15px;
  border-radius: 9px;
  border: 2px solid #999;
  box-sizing: border-box;
  color: #999;
  font-size: 11px;
  text-align: center;
  font-family: ${(props) => props.theme.font.header};
`

const ListItemDescription = styled.span`
  font-family: ${(props) => props.theme.font.copy};
  margin: 1rem 0 0 0;
  font-size: 0.875rem;
  line-height: 1.25;
`

const OptionListGroupWrapper = styled.div`
  margin: 1rem 0 1rem 0;
`

const ProductAddToBasket = styled.div`
  margin: 0 0 3rem 0;
  padding: 1rem 0 0 0;
  ${media.tablet} {
    margin: 0 0 2rem 0;
  }
  ${media.phone} {
    margin: 0 0 6rem 0;
    padding: 0;
  }
`

const ProductAddButtonsWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 1rem 0 0 0;
`

const ProductAddButtons = styled.div`
  width: 30%;
  padding: 0 5% 1rem 5%;
  ${media.tablet} {
    width: 40%;
  }
  ${media.phone} {
    width: 100%;
  }
`

const ProductDetailContainer: React.FC<Props> = ({ productId }) => {
  // Dispatch
  const dispatch = useAppDispatch()

  // Routing
  const { venueId, serviceId, menuId, salesAreaId } = useParams<{
    venueId: string
    serviceId: string
    menuId: string
    salesAreaId: string
  }>()

  // Selectors
  const product = useSelector((state: AppState) =>
    productSelectors.selectById(state, productId)
  )
  const quantity = useSelector((state: AppState) =>
    basketSelectors.selectQuantity(state, productId)
  )
  const selectedPortion = useSelector(
    basketSelectors.selectPortionForProduct(productId)
  )
  const currentMinimumAge = useSelector(menuItemSelectors.selectMinimumAge)
  const minimumCustomerAge = product ? product.minimumCustomerAge : 0

  // Set initial Portion
  useEffect(() => {
    if (product && product.portions && product.portions.length > 0) {
      dispatch(
        basketActions.setCurrentItem({
          id: product.id,
          eposId: product.eposId,
          quantity: 1,
          portionId: product.portions[0].id,
          price: product.portions[0].price,
          choices: []
        })
      )
    }
  }, [product, dispatch])

  // Check minimum age
  useEffect(() => {
    const cancelUrl = `/smart-waiter/venue/${venueId}/area/${salesAreaId}/service/${serviceId}/menu/${menuId}/section`
    if (minimumCustomerAge && minimumCustomerAge > currentMinimumAge) {
      dispatch(
        openModal({
          variant: 'confirm-age',
          props: {
            productId: productId,
            age: minimumCustomerAge,
            cancelUrl
          }
        })
      )
    }
  }, [
    venueId,
    salesAreaId,
    serviceId,
    menuId,
    currentMinimumAge,
    productId,
    minimumCustomerAge,
    dispatch
  ])

  const handleSelectPortion = (portionId: number) => {
    if (product && product.portions) {
      dispatch(basketActions.setPortionId({ id: productId, portionId }))
    }
  }
  const handleIncrementQuantity = () => {
    dispatch(
      basketActions.setQuantity({ id: productId, quantity: quantity + 1 })
    )
  }
  const handleDeIncrementQuantity = () => {
    dispatch(
      basketActions.setQuantity({ id: productId, quantity: quantity - 1 })
    )
  }

  if (product) {
    return (
      <ProductDetailWrapper>
        <ListItemDetailsWrapper>
          <BreadcrumbContainer routes={smartWaiterRoutes} />
          <ListItemHeader>
            <ListItemTitleWrapper>
              <ListItemTitle>{product.name}</ListItemTitle>
              {product.minimumCustomerAge && (
                <ListItemAgeWrapper>
                  {product.minimumCustomerAge}
                </ListItemAgeWrapper>
              )}
            </ListItemTitleWrapper>
          </ListItemHeader>
          {product.description && (
            <ListItemDescription>{product.description}</ListItemDescription>
          )}
        </ListItemDetailsWrapper>

        <OptionListGroupWrapper>
          {!!(product.portions && product.portions.length > 1) && (
            <Divider>Options</Divider>
          )}
          {product.portions &&
            product.portions.map((portion) => (
              <OptionListItem
                key={portion.id}
                groupName={`portion-${product.id}`}
                type={'radio'}
                showSelect={!!(product.portions && product.portions.length > 1)}
                id={portion.id}
                name={portion.name}
                price={portion.price}
                isChecked={
                  !!(selectedPortion && selectedPortion.id === portion.id)
                }
                onSelect={handleSelectPortion}
              />
            ))}
        </OptionListGroupWrapper>
        {selectedPortion &&
          selectedPortion.choices.map((choiceId) => (
            <ProductChoicesContainer key={choiceId} productId={choiceId} />
          ))}
        <ProductAddToBasket>
          <Divider isCentred={true} isSpecial={true}>
            How Many?
          </Divider>
          <ProductAddButtonsWrapper>
            <ProductAddButtons>
              <NumberSelector
                value={quantity}
                isBlock={true}
                numberSelectorSize={'large'}
                onIncrementClick={handleIncrementQuantity}
                onDecrementClick={handleDeIncrementQuantity}
              />
            </ProductAddButtons>
          </ProductAddButtonsWrapper>
        </ProductAddToBasket>
      </ProductDetailWrapper>
    )
  }
  return <></>
}

export { ProductDetailContainer }
