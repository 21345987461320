import React from 'react'

// Selectors
import { selectIsModalOpen, selectModal } from 'modules/overlay/selectors'

// Actions
import { closeModal } from 'modules/overlay/actions'

// Components
import { Modal } from 'marvel-components'

// Containers
import {
  ProductItemContainer,
  LoginContainer,
  ThreeDSContainer,
  SeatModalContainer,
  ConfirmRestrictionsModalContainer
} from 'containers/Ticketing'
import {
  ConfirmClearBasketOverlay,
  KioskInactivityOverlayContainer,
  KioskConfirmClearBasketOverlayContainer,
  ThreeDSOverlayContainer,
  KioskExternalPageOverlayContainer,
  AlternativeVenueOverlayContainer,
  MediaOverlayContainer
} from 'containers'
import { ThreeDSContainerHospitality } from 'containers/Hospitality'
import {
  ThreeDSContainerSmartWaiter,
  ConfirmAgeOverlayContainer
} from 'containers/SmartWaiter'

// Misc
import { useSelector, useDispatch } from 'react-redux'
import { useIsMobileViewport } from 'utils'

const ModalContainer: React.FC = () => {
  const dispatch = useDispatch()
  const isMobileViewport = useIsMobileViewport()

  // Selectors
  const isModalOpen = useSelector(selectIsModalOpen)
  const modal = useSelector(selectModal)

  const handleCloseOverlay = () => dispatch(closeModal())

  let content = null
  let width = 'auto'
  let height = 'auto'
  let isOverFlowVisible = true
  let isDark = false
  const isCloseVisible = true
  const shouldCloseOnEsc = true
  const shouldCloseOnOverlayClick = true

  switch (modal?.variant) {
    case 'product':
      content = <ProductItemContainer {...modal.props} />
      width = isMobileViewport ? '320px' : '500px'
      break
    case 'clear-basket':
      content = <ConfirmClearBasketOverlay {...modal.props} />
      break
    case 'three-ds':
      content = <ThreeDSOverlayContainer {...modal.props} />
      width = isMobileViewport ? '250px' : '600px'
      height = '400px'
      break
    case 'login':
      content = <LoginContainer {...modal.props} />
      break
    case 'three-ds-ticketing':
      content = <ThreeDSContainer {...modal.props} />
      width = isMobileViewport ? '250px' : '600px'
      height = '400px'
      break
    case 'three-ds-hospitality':
      content = <ThreeDSContainerHospitality {...modal.props} />
      width = isMobileViewport ? '250px' : '600px'
      height = '400px'
      break
    case 'three-ds-smart-waiter':
      content = <ThreeDSContainerSmartWaiter {...modal.props} />
      width = isMobileViewport ? '250px' : '600px'
      height = '400px'
      break
    case 'seat':
      content = <SeatModalContainer {...modal.props} />
      break
    case 'seat-restrictions':
      content = <ConfirmRestrictionsModalContainer {...modal.props} />
      break
    case 'confirm-age':
      content = <ConfirmAgeOverlayContainer {...modal.props} />
      break
    case 'kiosk-inactivity':
      content = <KioskInactivityOverlayContainer {...modal.props} />
      break
    case 'kiosk-clear-basket':
      content = <KioskConfirmClearBasketOverlayContainer />
      break
    case 'kiosk-external-page':
      content = <KioskExternalPageOverlayContainer {...modal.props} />
      width = '90%'
      height = '80%'
      break
    case 'alternative-venue':
      content = <AlternativeVenueOverlayContainer {...modal.props} />
      break
    case 'media':
      width = '100vw'
      height = 'calc(100vw/16 * 9)'
      isOverFlowVisible = false
      isDark = true
      content = <MediaOverlayContainer {...modal.props} />
      break
  }

  return (
    <Modal
      key={`modal-${modal?.variant}`}
      onCloseOverlay={handleCloseOverlay}
      isOverlayOpen={!!isModalOpen}
      width={width}
      height={height}
      isCloseVisible={isCloseVisible}
      shouldCloseOnEsc={shouldCloseOnEsc}
      shouldCloseOnOverlayClick={shouldCloseOnOverlayClick}
      isOverFlowVisible={isOverFlowVisible}
      isDark={isDark}
    >
      {content}
    </Modal>
  )
}

export { ModalContainer }
