import { takeLatest } from 'redux-saga/effects'
import * as actions from './actions'
import * as api from 'services/api'
import { sharedOperations } from 'modules/shared'

export function* watcherFetchOptins() {
  yield takeLatest(actions.fetchOptins.TRIGGER, fetchOptins)
}

export const fetchOptins = sharedOperations.fetchEntity.bind(
  null,
  actions.fetchOptins,
  api.fetchOptins
)
