import React, { useEffect } from 'react'

// Components
import {
  PrimaryButton,
  BackButton,
  FooterNav,
  StickyFooter,
  Layout
} from 'marvel-components'

// Containers
import {
  BrandLogoContainer,
  BasketContainer,
  CheckoutContainer
} from 'containers/Hospitality'

// Actions
import { optinActions } from 'modules/hospitality/optin'

// Selectors
import { reservationSelectors } from 'modules/hospitality/reservation'
import { occasionSelectors } from 'modules/hospitality/occasion'
import { siteSelectors } from 'modules/hospitality/site'

// Misc
import { Route, Switch, useRouteMatch, useHistory } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { useAppDispatch } from 'app/reduxStore'
import { trackHospitalityBasket, usePlatform } from 'utils'
import { useTypedSelector } from 'modules'

const BasketView: React.FC = () => {
  // Routing
  const {
    path,
    url,
    params: { siteId, occasionId }
  } = useRouteMatch<{
    siteId: string
    occasionId: string
  }>()
  const history = useHistory()
  const dispatch = useAppDispatch()

  // Actions
  useEffect(() => {
    dispatch(optinActions.fetchOptins())
  }, [dispatch])

  // Selectors
  const reservationTotal = useSelector(
    reservationSelectors.selectReservationTotal
  )
  const isBasketEmpty = useSelector(reservationSelectors.selectIsBasketEmpty)
  const occasionName = useTypedSelector(state =>
    occasionSelectors.selectOccasionName(state, occasionId)
  )
  const siteName = useTypedSelector(state =>
    siteSelectors.selectSiteName(state, siteId)
  )
  const menu = useSelector(reservationSelectors.selectSelectedMenu)
  const reservation = useSelector(reservationSelectors.selectCurrentReservation)
  const platform = usePlatform()

  const handleContinueToCheckout = () => history.push(`${url}/checkout`)

  const handleBackClick = () => {
    history.push(`/hospitality/site/${siteId}/occasion/${occasionId}/menu`)
  }

  // Analytics
  useEffect(() => {
    if (occasionName && siteName && reservation && menu) {
      trackHospitalityBasket({
        occasionName,
        siteName,
        reservation,
        menu,
        platform
      })
    }
  }, [menu, occasionName, reservation, siteName, platform])

  return (
    <Switch>
      <Route exact path={`${path}`}>
        <Layout
          pageTitle={`Review Basket - Hospitality | LW Theatres`}
          renderBrandLogo={<BrandLogoContainer />}
          renderSidebarTop={<BasketContainer />}
          renderSidebarBottom={
            <StickyFooter>
              <PrimaryButton
                text='Continue'
                icon={'lock'}
                amount={reservationTotal}
                isDisabled={isBasketEmpty}
                onClick={handleContinueToCheckout}
              />
            </StickyFooter>
          }
        />
      </Route>
      <Route exact path={`${path}/checkout`}>
        <Layout
          pageTitle={`Confirm and Pay - Hospitality | LW Theatres`}
          renderBrandLogo={<BrandLogoContainer />}
          renderSidebarTop={<BasketContainer hideOnMobile={false} />}
          renderSidebarBottom={<FooterNav />}
          renderMainTop={<BackButton onBackClick={handleBackClick} />}
          renderMain={
            <CheckoutContainer siteId={siteId} occasionId={occasionId} />
          }
        />
      </Route>
    </Switch>
  )
}

export { BasketView }
