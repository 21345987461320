import React, { useEffect } from 'react'
import styled from 'styled-components/macro'
import { Field } from 'formik'

import { media, trackHospitalityAddPaymentInfo, usePlatform } from 'utils'

// Components
import {
  BookingFooter,
  FormTextArea,
  CForm,
  SubmitValues,
  basicInitialValues,
  FormCheckbox,
  Title
} from 'marvel-components'

// Selectors
import { reservationSelectors } from 'modules/hospitality/reservation'
import { orderSelectors } from 'modules/hospitality/order'
import { optinSelectors } from 'modules/hospitality/optin'
import { paymentSelectors } from 'modules/ticketing/payment/paymentSlice'

// Actions
import { orderActions } from 'modules/hospitality/order'
import { openModal } from 'modules/overlay/actions'
import { paymentActions } from 'modules/ticketing/payment/paymentSlice'

// Misc
import { useAppDispatch } from 'app/reduxStore'
import { useSelector } from 'react-redux'

// Validation
import * as Yup from 'yup'
import { Redirect } from 'react-router-dom'
import { selectBookingFlow } from 'modules/basket/selectors'

type Props = {
  siteId: EntityId
  occasionId?: EntityId
}

const CheckoutWrapper = styled.div`
  box-sizing: border-box;
  padding: 0 1.5rem 1.5rem 1.5rem;
  background: ${props => props.theme.colors.secondaryBackgroundColor};
  ${media.tablet} {
    padding: 1.5rem 1rem 1rem 1rem;
  }
`

const ResponsesWrapper = styled.div`
  margin-top: 1rem;
`

const ResponsesOptIns = styled.div`
  margin-top: 1rem;
`

type AdditionalFormValues = {
  specialRequest?: string
  optins: { [key: string]: boolean }
}

const CheckoutContainer: React.FC<Props> = ({ siteId, occasionId }) => {
  const dispatch = useAppDispatch()

  // Fetch Payment Methods
  // ------------------------------------------
  useEffect(() => {
    dispatch(paymentActions.fetchPaymentMethods('lv'))
  }, [dispatch])

  const platform = usePlatform()

  const date = useSelector(reservationSelectors.selectSelectedDateAndTime)
  const menuId = useSelector(reservationSelectors.selectSelectedMenuId)
  const menu = useSelector(reservationSelectors.selectSelectedMenu)
  const adults = useSelector(reservationSelectors.selectAdultQuantity)
  const children = useSelector(reservationSelectors.selectChildQuantity)
  const amount = useSelector(reservationSelectors.selectPaymentAmount)
  const isOrderLoading = useSelector(orderSelectors.selectPaymentIsLoading)
  const order = useSelector(orderSelectors.selectOrder)
  const optinQuestions = useSelector(optinSelectors.selectOptins)
  const paymentMethods = useSelector(paymentSelectors.selectPaymentMethods)
  const paymentChallenge = useSelector(orderSelectors.selectPaymentChallenge)
  const bookingFlow = useSelector(selectBookingFlow)

  const handleOnSubmit = async (values: SubmitValues<AdditionalFormValues>) => {
    let paymentType = 'credit_card'
    if (values.paymentData) {
      if (values.paymentData.paymentMethod) {
        if (values.paymentData.paymentMethod.brand) {
          paymentType = values.paymentData.paymentMethod.brand
        } else if (values.paymentData.paymentMethod.scheme) {
          paymentType = values.paymentData.paymentMethod.scheme
        }
      }
    }
    // Analytics
    trackHospitalityAddPaymentInfo({
      platform,
      bookingFlow,
      paymentType,
      item: {
        id: menuId ? String(menuId) : '',
        name: menu?.name ?? '',
        datetime: date.utc().format('YYYY-MM-DD/HH-mm'),
        price: amount,
        quantity: adults + children
      }
    })

    if (order) {
      dispatch(
        orderActions.updateOrder({
          orderId: order.event.id
        })
      )
    } else if (menuId && occasionId && date) {
      const optins = Object.keys(values.optins).map((key: string) => ({
        questionId: parseInt(key, 10),
        accepted: values.optins[key]
      }))

      return dispatch(
        orderActions.createOrder({
          firstName: values.firstName,
          lastName: values.lastName,
          email: values.email,
          telephoneNumber: values.telephoneNumber,
          amount: amount,
          siteId,
          occasionId,
          date: date.toISOString(),
          menuIds: [menuId],
          adults,
          children,
          ...(values.specialRequest && {
            specialRequest: values.specialRequest
          }),
          ...(values.paymentData && { payment: values.paymentData }),
          consent: {
            email: true
          },
          optins
        })
      )
    }
  }

  // Actions
  // Show 3ds Modal if needed
  useEffect(() => {
    if (paymentChallenge && order) {
      dispatch(
        openModal({
          variant: 'three-ds-hospitality',
          props: {
            orderId: order.event.id,
            challenge: paymentChallenge
          }
        })
      )
    }
  }, [paymentChallenge, order, dispatch])

  // Actions
  if (order && platform === 'kiosk') {
    return (
      <Redirect
        to={`/hospitality/site/${siteId}/order/${order.event.id}/payment`}
      />
    )
  }

  if (order && order.status === 'PAID') {
    return (
      <Redirect to={`/hospitality/site/${siteId}/order/${order.event.id}`} />
    )
  }

  const initialValues = {
    ...basicInitialValues,
    specialRequest: '',
    optins: {}
  }

  const additionalValidation = Yup.object().shape({
    specialRequest: Yup.string()
  })

  return (
    <CheckoutWrapper>
      <CForm<AdditionalFormValues>
        title={'Order Options'}
        onSubmit={handleOnSubmit}
        isLoading={isOrderLoading}
        amount={amount}
        initialValues={initialValues}
        additionalValidation={additionalValidation}
        paymentMethods={paymentMethods}
        additionalFields={
          <ResponsesWrapper>
            <FormTextArea
              name='specialRequest'
              placeholder='Any allergies / dietary requirements / special occasions?'
            />
            <Title
              isCapitalised={false}
              isCentralised={false}
              size='medium'
              isBold={true}
            >
              Stay in Touch
            </Title>
            <ResponsesOptIns>
              {optinQuestions.map(optin => (
                <Field
                  key={`optin-${optin.id}`}
                  name={`optins.${optin.id}`}
                  label={optin.text}
                  isLabelVisible={true}
                  size={'small'}
                  checkboxTheme={'dark'}
                  component={FormCheckbox}
                ></Field>
              ))}
            </ResponsesOptIns>
          </ResponsesWrapper>
        }
      />
      <BookingFooter />
    </CheckoutWrapper>
  )
}

export { CheckoutContainer }
