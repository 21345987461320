import { combineReducers } from 'redux'

// Slices
import auth from 'modules/ticketing/auth/authSlice'
import availability from 'modules/ticketing/availability/availabilitySlice'
import performance from 'modules/ticketing/performance/performanceSlice'
import priceLevel from 'modules/ticketing/performance/priceLevelSlice'
import seatType from 'modules/ticketing/performance/seatTypeSlice'
import deliveryOption from 'modules/ticketing/delivery-option/deliveryOptionSlice'
import eventDetails from 'modules/ticketing/event/eventDetailsSlice'
import faq from 'modules/ticketing/manage-orders/faqSlice'
import payment from 'modules/ticketing/payment/paymentSlice'

const ticketing = combineReducers({
  auth,
  eventDetails,
  availability,
  performance,
  priceLevel,
  seatType,
  deliveryOption,
  faq,
  payment
})
export type TicketingState = ReturnType<typeof ticketing>
export default ticketing
