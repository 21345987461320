import React from 'react'

// Components
import { NumberSelector } from 'marvel-components'

// Types

// Misc
import styled from 'styled-components/macro'
import { media, usePlatform, useIsNarrowViewport } from 'utils'

interface Props {
  title?: string
  description?: string
  quantity?: number
  onIncrement?: () => void
  onDeIncrement?: () => void
  platform?: string
}

const InlineSelectorWrapper = styled.div<{ platform?: string }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 0;
  border-bottom: 1px solid;
  border-color: ${props => props.theme.colors.borderColor};
  &:last-child {
    border-bottom: none;
  }
  ${media.tablet} {
    padding: ${props => (props.platform === 'kiosk' ? '2rem 0' : '1rem 0')};
  }
`
const InlineSelectorFieldWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  margin-right: 1rem;
`

const InlineSelectorControlWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-end;
  width: 200px;
  ${media.phone} {
    width: 150px;
  }
`

const InlineSelectorFieldTitle = styled.div<{ platform?: string }>`
  font-family: ${props => props.theme.font.header};
  ${media.tablet} {
    font-size: ${props => (props.platform === 'kiosk' ? '1.5rem' : '1rem')};
  }
`

const InlineSelectorFieldDescription = styled.div`
  margin-top: 0.5rem;
  font-family: ${props => props.theme.font.copy};
  font-size: 0.875rem;
`

export const InlineSelector: React.FC<Props> = ({
  title,
  description,
  quantity = 0,
  onIncrement,
  onDeIncrement
}) => {
  const handleIncrementQuantity = () => {
    onIncrement && onIncrement()
  }
  const handleDeIncrementQuantity = () => {
    onDeIncrement && onDeIncrement()
  }
  const platform = usePlatform()
  const isNarrowViewport = useIsNarrowViewport()
  return (
    <InlineSelectorWrapper platform={platform}>
      <InlineSelectorFieldWrapper>
        <InlineSelectorFieldTitle platform={platform}>
          {title}
        </InlineSelectorFieldTitle>
        <InlineSelectorFieldDescription>
          {description}
        </InlineSelectorFieldDescription>
      </InlineSelectorFieldWrapper>
      <InlineSelectorControlWrapper>
        <NumberSelector
          value={quantity}
          isBlock={true}
          onIncrementClick={handleIncrementQuantity}
          onDecrementClick={handleDeIncrementQuantity}
          decreaseDisabled={quantity < 1}
          numberSelectorSize={
            platform === 'kiosk' && isNarrowViewport ? 'large' : 'default'
          }
          isInline={true}
        />
      </InlineSelectorControlWrapper>
    </InlineSelectorWrapper>
  )
}
