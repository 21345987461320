import { createAsyncThunk } from '@reduxjs/toolkit'
import { bannerActions } from 'modules/banner'
import { normalize, schema } from 'normalizr'
import { MainMenuNavigationItem, ScreenSaverSlide } from 'shared-types'
import navigationApi from '../services/navigationApi'

const navigationItemSchema = new schema.Entity('navigationItems', undefined)
const screenSaverSlideSchema = new schema.Entity('screenSaverSlides', undefined)

type ContentfulNavigationItem = {
  fields: {
    title: string
    image?: {
      fields: {
        file: {
          url: string
        }
      }
    }
    link?: {
      fields: {
        url: string
      }
    }
  }
  sys: {
    id: string
    createdAt: string
    updatedAt: string
  }
}

type ContentfulNavigationList = {
  navigationItem: ContentfulNavigationItem[]
}

export type FetchNavigationItemsResponse = {
  navigationItems: Record<string, MainMenuNavigationItem>
}

export const fetchNavigationItems = createAsyncThunk<
  FetchNavigationItemsResponse,
  undefined
>(
  'marvel/self-serve/FETCH_NAVIGATION_ITEMS',
  async (_, { dispatch, rejectWithValue }) => {
    try {
      const navigationList = (await navigationApi.fetchOne(
        process.env.REACT_APP_SELF_SERVE_NAVIGATION_LIST_ID!,
        {
          include: 2
        }
      )) as ContentfulNavigationList

      if (!navigationList) {
        throw new Error('Failed to fetch the navigation list')
      }

      const navigationItems = navigationList.navigationItem.map(navItem => {
        return {
          ...navItem.fields,
          id: navItem.sys.id,
          image: navItem.fields.image?.fields.file.url,
          link: navItem.fields.link?.fields.url
        }
      })

      const normalized = normalize(navigationItems, [navigationItemSchema])
      return normalized.entities as FetchNavigationItemsResponse
    } catch (err) {
      dispatch(
        bannerActions.setBannerContent({
          bannerType: 'error',
          title: 'Please Note',
          text: 'Failed to fetch the navigation links.'
        })
      )
      return rejectWithValue(err)
    }
  }
)

type ContentfulScreenSaver = {
  title: string
  images: {
    fields: { file: { url: string }; title: string }
    sys: { id: string }
  }[]
}

type FetchScreenSaverSlidesResponse = {
  screenSaverSlides: Record<string, ScreenSaverSlide>
}

export const fetchScreenSaverSlides = createAsyncThunk<
  FetchScreenSaverSlidesResponse,
  undefined
>(
  'marvel/self-serve/FETCH_SCREEN_SAVER_SLIDES',
  async (args, { dispatch, rejectWithValue }) => {
    try {
      const contentfulScreenSaver = (await navigationApi.fetchOne(
        process.env.REACT_APP_SELF_SERVE_SCREEN_SAVER_ID!,
        {
          include: 2
        }
      )) as ContentfulScreenSaver

      if (!contentfulScreenSaver) {
        throw new Error('Failed to fetch the screen saver slides.')
      }

      const screenSaverSlides = contentfulScreenSaver.images.map(img => {
        return {
          id: img.sys.id,
          url: img.fields.file.url,
          title: img.fields.title
        }
      })

      const normalized = normalize(screenSaverSlides, [screenSaverSlideSchema])
      return normalized.entities as FetchScreenSaverSlidesResponse
    } catch (err) {
      dispatch(
        bannerActions.setBannerContent({
          bannerType: 'error',
          title: 'Please Note',
          text: 'Failed to fetch the screen saver slides.'
        })
      )
      return rejectWithValue(err)
    }
  }
)
