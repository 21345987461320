import { combineReducers } from 'redux'

// Slices
import venues from 'modules/smart-waiter/venue'
import services from 'modules/smart-waiter/service'
import images from 'modules/smart-waiter/image'
import menus from 'modules/smart-waiter/menu'
import menuSections from 'modules/smart-waiter/menu-section'
import products from 'modules/smart-waiter/product'
import menuItems from 'modules/smart-waiter/menu-item'
import basket from 'modules/smart-waiter/basket'
import order from 'modules/smart-waiter/order'
import optin from 'modules/smart-waiter/optin/optinSlice'

const smartWaiter = combineReducers({
  venues,
  services,
  images,
  menus,
  menuSections,
  menuItems,
  products,
  basket,
  order,
  optin
})

export type SmartWaiterState = ReturnType<typeof smartWaiter>
export default smartWaiter
