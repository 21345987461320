import React, { useEffect } from 'react'

// Actions
import { eventDetailsActions } from 'modules/ticketing/event'

import { setExternalCustomerId } from 'modules/basket/actions'
import { setPromoCode, setDiscountCode } from 'modules/discount/actions'

// Views
import {
  PerformancesView,
  CheckoutView,
  LotteryView,
  DaySeatsView
} from 'views/Ticketing'

// Misc
import { useDispatch } from 'react-redux'
import {
  Route,
  Switch,
  useParams,
  useRouteMatch,
  useLocation
} from 'react-router-dom'
import { useInactivityModal, usePlatform, useReservationExpire } from 'utils'
import { daySeatPromoCode } from 'utils/day-seat-assets'

const EventDetailsView: React.FC = () => {
  const dispatch = useDispatch()

  // Routing
  const { eventId } = useParams<{ eventId: string }>()
  const { path } = useRouteMatch()
  const location = useLocation()

  const urlParams = new URLSearchParams(location.search)
  const externalId = urlParams.get('externalId')
  const fbp = urlParams.get('sineFbp')
  const fbc = urlParams.get('sineFbc')
  const urlDiscountCode = urlParams.get('discount')
  const urlPromoCode = urlParams.get('promo')
  const daySeatPromoCodeForEvent = daySeatPromoCode(eventId)

  // Selectors
  const platform = usePlatform()

  // External Customer Id
  // ------------------------------------------
  useEffect(() => {
    const externalData = {
      externalId,
      fbp,
      fbc
    }
    externalId && dispatch(setExternalCustomerId(JSON.stringify(externalData)))
  }, [externalId, fbp, fbc, dispatch])

  // Fetch Event Details
  // ------------------------------------------
  useEffect(() => {
    eventId && dispatch(eventDetailsActions.fetchEventDetails({ eventId }))
  }, [eventId, dispatch])

  // Set Promo Code
  // ------------------------------------------
  useEffect(() => {
    eventId &&
      urlPromoCode &&
      dispatch(
        setPromoCode({
          code: urlPromoCode
        })
      )
  }, [eventId, urlPromoCode, dispatch])

  // Set Discount Code
  // ------------------------------------------
  useEffect(() => {
    eventId &&
      urlDiscountCode &&
      dispatch(
        setDiscountCode({
          code: urlDiscountCode
        })
      )
  }, [eventId, urlDiscountCode, dispatch])

  useInactivityModal({
    shouldOpen: platform === 'kiosk',
    payload: {
      variant: 'kiosk-inactivity'
    }
  })
  useReservationExpire()
  return (
    <>
      <Switch>
        {/* Day Seats */}
        {daySeatPromoCodeForEvent && (
          <Route
            path={[
              `${path}/day-seats`,
              `${path}/rush-seats`,
              `${path}/fantastic-fridays`
            ]}
          >
            <DaySeatsView
              eventId={eventId}
              promoCode={daySeatPromoCodeForEvent}
            />
          </Route>
        )}
        <Route path={`${path}/day-seats-access`}>
          <DaySeatsView eventId={eventId} promoCode='disneyaccessdayseats21' />
        </Route>
        <Route path={`${path}/day-seats-level`}>
          <DaySeatsView eventId={eventId} promoCode='disneyleveldayseats' />
        </Route>
        {/* Lotter View */}
        <Route exact path={`${path}/lottery`}>
          <LotteryView />
        </Route>
        {/* Payment Views */}
        <Route path={`${path}/reservation/:transactionGuid`}>
          <CheckoutView eventId={eventId} />
        </Route>
        {/* Select Performance Views */}
        <Route path={path}>
          <PerformancesView
            eventId={eventId}
            promoCode={urlPromoCode}
            discountCode={urlDiscountCode}
          />
        </Route>
      </Switch>
    </>
  )
}

export { EventDetailsView }
