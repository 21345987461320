import React from 'react'

// Components
import { WithLoader, SeatMapOverlay } from 'marvel-components'

// Containers
import { SeatMapContainer, TicketListContainer } from 'containers/Ticketing'

// Selectors
import { selectIsTicketDataLoading } from 'modules/shared/selectors'
import { selectEventDetailsId } from 'modules/ticketing/event/selectors'

// Misc
import { useSelector } from 'react-redux'
import styled from 'styled-components/macro'
import { usePlatform } from 'utils'

// Types
import { SeatingType } from 'shared-types'

// Assets
import LotteryImage from 'assets/lottery_image.png'
import DaySeatsImage from 'assets/day_seats_image.png'
import {
  daySeatCopy,
  daySeatTitle,
  daySeatsSoldOutCopy
} from 'utils/day-seat-assets'

const SelectTicketWrapper = WithLoader(
  styled.div<{ platform?: string }>`
    width: 100%;
    height: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
  `
)

const DaySeatsCopy = styled.div`
  color: ${props => props.theme.colors.sidebarCopyColor};
`

type Props = {
  performanceId: string
  seatingType?: SeatingType
  banner?: string
}

const SelectTicketContainer: React.FC<Props> = ({
  performanceId,
  seatingType,
  banner
}) => {
  const isLoading = useSelector(selectIsTicketDataLoading)
  const platform = usePlatform()
  const eventId = useSelector(selectEventDetailsId)

  let content
  switch (seatingType) {
    case 'ReservedSeating':
      content = <SeatMapContainer performanceId={performanceId} />
      break
    case 'GeneralAdmission':
      content = <TicketListContainer performanceId={performanceId} />
      break
    case 'Lottery':
      content = (
        <SeatMapOverlay
          title={'Congratulations on winning the Lottery'}
          image={banner || LotteryImage}
          copy={
            <>
              <p>
                Please use the plus and minus buttons to the left, or below if
                you're on a mobile device, to select up to 2 lottery tickets.
              </p>
              <p>
                You will automatically be assigned the best seats available at
                point of booking. Unfortunately we will not be able to exchange
                the seats you have been assigned.
              </p>
              <p>
                After completing the lottery ticket purchase you will receive a
                booking confirmation. Your tickets will be sent separately, by
                email, no later than 24 hours prior to your chosen performance.
              </p>
            </>
          }
        />
      )
      break
    case 'DaySeats':
      content = (
        <SeatMapOverlay
          isThemed={true}
          title={daySeatTitle(eventId)}
          image={banner || DaySeatsImage}
          copy={<DaySeatsCopy>{daySeatCopy(eventId)}</DaySeatsCopy>}
        />
      )
      break
    case 'DaySeatsSoldOut':
      content = (
        <SeatMapOverlay
          isThemed={true}
          title={'Sold Out'}
          image={banner || DaySeatsImage}
          copy={<DaySeatsCopy>{daySeatsSoldOutCopy(eventId)}</DaySeatsCopy>}
        />
      )
      break
  }
  switch (performanceId) {
    case 'f71a2b29-dbac-eb11-82e5-a851811d4564':
      content = (
        <SeatMapOverlay
          title={'Welcome competition winners!'}
          image={banner || DaySeatsImage}
          copy={
            <>
              <p>
                Please use the plus and minus buttons to the left, or below if
                you're on a mobile device, to select your 4 free tickets. Please
                check the number of tickets in your basket carefully before
                confirming your booking as you will not be able to check out
                with fewer or more than 4 tickets.
              </p>
              <p>
                You will automatically be assigned the best seats available at
                point of booking. Unfortunately we will not be able to exchange
                the seats you have been assigned.
              </p>
              <p>
                Children under the age of 3 cannot be admitted. Recommended for
                ages 6+. All guests aged 16 or under must be seated next to an
                accompanying adult. All persons attending, regardless of age,
                must have a ticket.
              </p>
            </>
          }
        />
      )
      break
    case '17815ffb-b7e0-eb11-830a-ec3bb53768fe':
      content = (
        <SelectTicketWrapper isLoading={isLoading}>
          <SeatMapOverlay
            title={'Disney Cast Member Tickets'}
            image={banner || DaySeatsImage}
            copy={
              <>
                <p>
                  Please use the plus and minus buttons to the left, or below if
                  you're on a mobile device, to select up to 2 free tickets.
                  Please check the number of tickets in your basket carefully
                  before confirming your booking as you will not be able to
                  check out with more than 2 tickets.
                </p>
                <p>
                  You will automatically be assigned the best seats available at
                  point of booking. Unfortunately we will not be able to
                  exchange the seats you have been assigned.
                </p>
                <p>
                  Children under the age of 3 cannot be admitted. Recommended
                  for ages 6+. All guests aged 16 or under must be seated next
                  to an accompanying adult. All persons attending, regardless of
                  age, must have a ticket.
                </p>
              </>
            }
          />
        </SelectTicketWrapper>
      )
      break
  }

  return (
    <SelectTicketWrapper isLoading={isLoading} platform={platform}>
      {content}
    </SelectTicketWrapper>
  )
}

export { SelectTicketContainer }
