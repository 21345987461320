import React from 'react'
import styled from 'styled-components'

//Components
import { CopyBlock, Link, Title } from 'marvel-components'

//Utils
import { media, lighten, eventIds } from 'utils'

import JosephBannerImage from 'assets/joseph2021.jpg'
import CinderellaBannerImage from 'assets/cinderella_banner.jpg'
import FrozenBannerImage from 'assets/frozen_banner.jpg'
import BttfBannerImage from 'assets/BTTF_banner.jpg'
import PhantomBannerImage from 'assets/phantom_banner.jpg'
import MatildaBannerImage from 'assets/matilda_banner.jpg'

const ExchangeExpiredWrapper = styled.div`
  box-sizing: border-box;
  padding: 1.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  ${media.tablet} {
    padding: 1rem;
    margin-top: 0;
  }
`

const ExchangeExpiredMessageBanner = styled.div`
  padding: 3rem 0;
  width: 100%;
  background: ${props => lighten(props.theme.colors.borderColor, 10)};
  margin: 0 0 1.5rem 0;
  display: flex;
  justify-content: center;
  align-items: center;
  ${media.tablet} {
    padding: 1rem 5%;
    width: 90%;
  }
`

const ExchangeExpiredMessageBody = styled.div`
  margin-bottom: 1rem;
  width: 600px;
  ${media.narrowdesktop} {
    width: 500px;
  }
  ${media.tablet} {
    width: 500px;
  }
  ${media.phone} {
    width: 100%;
  }
`

const ExpiredBanner = styled.img`
  width: 600px;
  margin: 2rem 0;
  ${media.narrowdesktop} {
    width: 500px;
  }
  ${media.tablet} {
    width: 500px;
  }
  ${media.phone} {
    width: 100%;
  }
`

type Props = {
  eventId?: string
}

const ExchangeContainerExpired: React.FC<Props> = ({ eventId }) => {
  const showName = () => {
    switch (eventId) {
      case eventIds.development.joseph:
      case eventIds.production.joseph:
        return 'Joseph and the Amazing Technicolor Dreamcoat'
      case eventIds.development.cinderella:
      case eventIds.production.cinderella:
      case eventIds.development.cinderellaSd:
      case eventIds.production.cinderellaSd:
        return 'Cinderella'
      case eventIds.development.frozen:
      case eventIds.production.frozen:
        return 'Frozen The Musical'
      case eventIds.development.backToTheFuture:
      case eventIds.production.backToTheFuture:
        return 'Back to the Future: The Musical'
      case eventIds.development.matilda:
      case eventIds.production.matilda:
        return 'Matilda The Musical'
      case eventIds.development.phantomNew:
      case eventIds.production.phantomNew:
        return 'The Phantom of the Opera'
      default:
        return ''
    }
  }
  const showEmail = () => {
    switch (eventId) {
      case eventIds.development.backToTheFuture:
      case eventIds.production.backToTheFuture:
        return 'customerservices@adelphitheatre.co.uk'
      default:
        return 'customer.relations@lwtheatres.co.uk'
    }
  }
  const bannerImage = () => {
    switch (eventId) {
      case eventIds.development.joseph:
      case eventIds.production.joseph:
        return JosephBannerImage
      case eventIds.development.cinderella:
      case eventIds.production.cinderella:
      case eventIds.development.cinderellaSd:
      case eventIds.production.cinderellaSd:
        return CinderellaBannerImage
      case eventIds.development.frozen:
      case eventIds.production.frozen:
        return FrozenBannerImage
      case eventIds.development.backToTheFuture:
      case eventIds.production.backToTheFuture:
        return BttfBannerImage
      case eventIds.development.matilda:
      case eventIds.production.matilda:
        return MatildaBannerImage
      case eventIds.development.phantomNew:
      case eventIds.production.phantomNew:
        return PhantomBannerImage
      default:
        return
    }
  }
  return (
    <ExchangeExpiredWrapper>
      <ExchangeExpiredMessageBanner>
        <ExchangeExpiredMessageBody>
          <CopyBlock textSize='large' isCentralised>
            <Title
              isCapitalised={false}
              isCentralised={true}
              isBold={true}
              size='large'
            >
              We're Sorry
            </Title>
            <p>
              {`The refund window for ${showName()}
              has now closed. However, if you’d like to exchange your tickets
              for an alternative performance, please email our Box Office Team
              at`}
              <Link
                text={`${showEmail()}`}
                href={`mailto:${showEmail()}`}
                isUnderlined={true}
                size='large'
              />
              , including your name and booking reference number. A member of
              the team will respond to your query via email as soon as possible.
            </p>
            <p>We look forward to seeing you soon.</p>
            <ExpiredBanner src={bannerImage()} />
            <p>
              <Link
                text={`Go to the LW Theatres website`}
                href='https://lwtheatres.co.uk'
                isUnderlined={true}
                size='copy'
              />
            </p>
          </CopyBlock>
        </ExchangeExpiredMessageBody>
      </ExchangeExpiredMessageBanner>
    </ExchangeExpiredWrapper>
  )
}

export { ExchangeContainerExpired }
