// Misc
import {
  createSlice,
  createEntityAdapter,
  PayloadAction
} from '@reduxjs/toolkit'

// Actions
import { fetchVenues, fetchAreasForVenue } from './actions'

// Types
import { Venue } from 'shared-types'

export const venuesAdapter = createEntityAdapter<Venue>({
  sortComparer: (a, b) => a.name.localeCompare(b.name)
})

interface SelectMenuPayload {
  performanceTime?: { value: string; label: string }
  seatArea?: { value: number; label: string; id: number }
}

type AdditionalState = {
  loading: 'idle' | 'fulfilled' | 'pending' | 'rejected'
  areas: { id: number; name: string; zonalId: number }[]
  selectedVenue?: EntityId
  selectedAreaId?: number
  selectedTime?: string
}

const venuesSlice = createSlice({
  name: 'venues',
  initialState: venuesAdapter.getInitialState<AdditionalState>({
    loading: 'idle',
    areas: [] as { id: number; name: string; zonalId: number }[]
  }),
  reducers: {
    selectVenue: (state, action: PayloadAction<EntityId>) => {
      state.selectedVenue = action.payload
    },
    selectMenu: (state, action: PayloadAction<SelectMenuPayload>) => {
      state.selectedAreaId = action.payload.seatArea?.id
      state.selectedTime = action.payload.performanceTime?.value
    }
  },
  extraReducers: builder => {
    builder.addCase(fetchVenues.pending, (state, action) => {
      state.loading = 'pending'
    })
    builder.addCase(fetchVenues.fulfilled, (state, action) => {
      if (action.payload.venues) {
        venuesAdapter.upsertMany(state, action.payload.venues)
      }
      state.loading = 'idle'
    })
    builder.addCase(fetchAreasForVenue.pending, state => {
      state.areas = []
    })
    builder.addCase(fetchAreasForVenue.fulfilled, (state, action) => {
      state.areas = action.payload
    })
  }
})

export const venueActions = {
  ...venuesSlice.actions,
  fetchAreasForVenue,
  fetchVenues
}

export default venuesSlice.reducer
