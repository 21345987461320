import { APIResponse } from 'shared-types'
import { normalize, Schema } from 'normalizr'
import { createAsyncThunk } from '@reduxjs/toolkit'
import { bannerActions } from 'modules/banner'

type APIReturn<T> = {
  data: T
}

export const createAPIThunk = <Returned, Args = void>(
  typePrefix: string,
  apiCall: (arg: Args) => Promise<APIResponse>,
  normalizeSchema?: Schema
) =>
  createAsyncThunk<APIReturn<Returned>, Args>(
    typePrefix,
    async (args: Args, { dispatch, rejectWithValue }) => {
      try {
        const response = await apiCall(args)
        if (normalizeSchema) {
          const normalized = normalize(response.data, normalizeSchema)
          return { data: normalized.entities }
        }
        return { data: response.data }
      } catch (err) {
        dispatch(
          bannerActions.setBannerContent({
            bannerType: 'error',
            title: 'Please Note',
            text: 'Unable to perform server request'
          })
        )
        return rejectWithValue(err)
      }
    }
  )
