import React from 'react'
import { FormattedNumber } from 'react-intl'
import { Price } from './types'
import styled, { css } from 'styled-components/macro'

const FreeWrapper = styled.span`
  text-transform: uppercase;
`

const FormattedPriceWrapper = styled.div<{ isBold?: boolean }>((props) => {
  const {
    theme: {
      font: { copy, header }
    },
    isBold
  } = props

  return css`
    font-family: ${isBold ? header : copy};
  `
})
type Props = Price
const FormattedPrice: React.FC<Props> = ({
  amount,
  currency,
  isFreeShown = false,
  isZeroShown = false,
  isDecimalsVisible = true,
  isBold = false,
  style = 'currency'
}) => {
  const options = {
    value: amount,
    currency,
    minimumFractionDigits: isDecimalsVisible ? 2 : 0,
    maximumFractionDigits: isDecimalsVisible ? 2 : 0,
    isBold
  }
  const content =
    amount > 0 || amount < 0 ? (
      <FormattedNumber style={style} {...options} />
    ) : isFreeShown ? (
      <FreeWrapper>Free</FreeWrapper>
    ) : isZeroShown ? (
      <FormattedNumber style={style} {...options} />
    ) : null

  return (
    content && (
      <FormattedPriceWrapper isBold={isBold}>{content}</FormattedPriceWrapper>
    )
  )
}

export { FormattedPrice }
