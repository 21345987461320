// Modules
import { AppState } from 'modules/types'
import { seatMapSelectors } from 'modules/seat-map'
import { discountSelectors } from 'modules/discount'
import { compareTickets } from './reducers'
import { promptSelectors } from 'modules/prompt'
import { availabilitySelectors } from 'modules/ticketing/availability/availabilitySlice'
// Types
import {
  Product,
  Reservation,
  Seat,
  Transaction,
  TransactionGroup,
  TicketLineItem,
  SeatListByArea,
  SeatLineItem,
  BaseTicket,
  ProductLineItem,
  PriceLevel,
  Area,
  Row
} from 'shared-types'

// Misc
import moment from 'moment'
import { createSelector } from '@reduxjs/toolkit'
import {
  getRecommendationPriceTypeId,
  selectActivePerformanceId,
  selectPriceLevelEntities,
  selectSelectedPriceLevelIds
} from 'modules/ticketing/performance/selectors'

// Selectors
export const getContextId = (state: AppState) => state.basket.contextId
export const getExternalCustomerId = (state: AppState) =>
  state.basket.externalCustomerId
/*
 * NOTE:
 * Selected Seats: A list of seat/area ids representing the basket
 * Selected Products: A list of product ids + quantities representing the basket
 * Ticket Reservations:  Server side reserved seats
 * Product Reservations: Server side reserved products
 */
// Reservation
//------------
export const selectBasketLoading = (state: AppState) =>
  state.basket.loading === 'loading'
export const selectBasketLoaded = (state: AppState) =>
  state.basket.loading === 'loaded'
export const selectIsBasketDirty = (state: AppState) =>
  state.basket.loading === 'pending'
export const selectBasketIdle = (state: AppState) =>
  state.basket.loading === 'idle'

// Best Available
//------------
export const selectIsBestAvailable = (state: AppState) =>
  state.basket.bestAvailable > 0

// GA Tickets
//------------
export const selectTicketsInBasket = (state: AppState) =>
  state.basket.selectedTickets

export const selectQuantityInBasket = (state: AppState, ticket: BaseTicket) => {
  return (
    selectTicketsInBasket(state).find(t => compareTickets(t, ticket))
      ?.quantity || 0
  )
}

export const selectTicketLineItems = createSelector(
  [selectTicketsInBasket, selectPriceLevelEntities],
  (tickets, priceLevels): TicketLineItem[] =>
    tickets.map(ticket => {
      const priceLevel = priceLevels[ticket.priceLevelId]
      const priceType = priceLevel?.priceTypes.find(
        priceType => priceType.id === ticket.priceTypeId
      )
      return {
        ...ticket,
        name: priceType?.name ?? '',
        price: priceType?.total ?? 0
      }
    })
)

// Seats
//-------------
const selectSeatsInBasket = (state: AppState) => state.basket.selectedSeats
const selectBestAvailable = (state: AppState) => state.basket.bestAvailable

export const selectIsSeatInBasket = (
  state: AppState,
  id: number,
  areaId: string
) =>
  !!state.basket.selectedSeats.find(
    selectedSeat => selectedSeat.id === id && selectedSeat.areaId === areaId
  )

export const selectSeatLineItemsInBasket = createSelector(
  [
    seatMapSelectors.selectSeatEntities,
    seatMapSelectors.selectAllRows,
    seatMapSelectors.selectAllAreas,
    selectPriceLevelEntities,
    seatMapSelectors.selectAllSeatTypes,
    selectSeatsInBasket
  ],
  (seats, rows, areas, priceLevels, seatTypes, seatsInBasket) =>
    seatsInBasket.map(seat => {
      const seatDetail = seats[`${seat.areaId}-${seat.id}`] as Seat
      const row = rows[seatDetail.rowGuid] as Row
      const priceLevel = priceLevels[seatDetail.priceLevelGuid] as PriceLevel
      const area = areas[seat.areaId] as Area
      const priceType = priceLevel?.priceTypes.find(
        priceType => priceType.id === seat.priceTypeId
      )
      const seatType = seatTypes[seatDetail.seatTypeGuid]
      return {
        ...seat,
        rowName: row.name,
        seatName: seatDetail.name,
        price: priceType?.total,
        background: priceLevel.background,
        seatType: seatType,
        areaName: area.name,
        seatId: seatDetail.seatId
      } as SeatLineItem
    })
)

export const selectSeatLineItemsByArea = createSelector(
  [selectSeatLineItemsInBasket],
  seatLineItems =>
    seatLineItems.reduce((acc, curr) => {
      acc[curr.areaName] = [...(acc[curr.areaName] || []), curr]
      return acc
    }, {} as SeatListByArea)
)

// Next seat
export const selectSeatsInBasketWithDetails = createSelector(
  [selectSeatsInBasket, seatMapSelectors.selectSeatEntities],
  (seatsInBasket, allSeats) =>
    seatsInBasket.map(seat => allSeats[`${seat.areaId}-${seat.id}`] as Seat)
)

export const selectNextSeat = createSelector(
  [
    selectSeatsInBasketWithDetails,
    seatMapSelectors.selectAllSeats,
    availabilitySelectors.selectIds
  ],
  (seatsInBasket, allSeats, availableIds) => {
    const lastSeat = seatsInBasket[seatsInBasket.length - 1]
    if (lastSeat) {
      const rowGuid = lastSeat.rowGuid
      const basketSeatIds = seatsInBasket.map(seat => seat.seatId)
      const availableSeats = allSeats.filter(
        seat =>
          availableIds.includes(seat.seatId) &&
          seat.rowGuid === rowGuid &&
          !basketSeatIds.includes(seat.seatId)
      )
      const closestSeat =
        availableSeats.length > 0 &&
        availableSeats.reduce((prev, curr) => {
          return Math.abs(curr.ordinal - lastSeat.ordinal) <
            Math.abs(prev.ordinal - lastSeat.ordinal)
            ? curr
            : prev
        })
      return closestSeat ? closestSeat : undefined
    }
  }
)

// Products
// -----------------------

export const selectSelectedProducts = (state: AppState) =>
  state.basket.selectedProducts

export const selectProductQuantityInBasket = (
  state: AppState,
  itemId: string
) => state.basket.selectedProducts[itemId] ?? 0

export const selectProductLineItems = createSelector(
  selectSelectedProducts,
  promptSelectors.selectProductEntities,
  (productsInBasket, products) =>
    Object.keys(productsInBasket).map(
      itemId =>
        ({
          ...products[itemId],
          quantity: productsInBasket[itemId]
        } as ProductLineItem)
    )
)

// Basket Stats
//------------------

export const selectTicketItemCount = createSelector(
  [selectTicketsInBasket],
  tickets => tickets.length
)

export const selectSeatItemCount = createSelector(
  [selectSeatsInBasket, selectBestAvailable],
  (seats, bestAvailableQty) =>
    bestAvailableQty > 0 ? bestAvailableQty : seats.length
)

export const selectSeatAndTicketItemCount = createSelector(
  [selectTicketItemCount, selectSeatItemCount],
  (ticketCount, seatCount) => ticketCount + seatCount
)

export const selectProductItemCount = createSelector(
  [selectSelectedProducts],
  products => Object.values(products).reduce((acc, curr) => (acc += curr), 0)
)

export const selectAllItemCount = createSelector(
  [selectTicketItemCount, selectSeatItemCount, selectProductItemCount],
  (ticketCount, seatCount, productCount) =>
    ticketCount + seatCount + productCount
)

// Totals
export const selectBasketTotal = createSelector(
  [
    selectTicketsInBasket,
    selectSeatsInBasket,
    selectProductLineItems,
    selectPriceLevelEntities
  ],
  (tickets, seats, products, priceLevels) => {
    const ticketsTotal = tickets.reduce((total, ticket) => {
      const priceType = priceLevels[ticket.priceLevelId]?.priceTypes.find(
        priceType => priceType.id === ticket.priceTypeId
      )
      return (total += ticket.quantity * (priceType?.total || 0))
    }, 0)
    const seatsTotal = seats.reduce((total, seat) => {
      const priceType = priceLevels[seat.priceLevelId]?.priceTypes.find(
        priceType => priceType.id === seat.priceTypeId
      )
      return (total += priceType?.total ?? 0)
    }, 0)
    const productsTotal = products.reduce(
      (acc, cur) => (acc += cur.total * cur.quantity),
      0
    )
    return ticketsTotal + seatsTotal + productsTotal
  }
)

// Discount
export const selectBasketTotalOriginal = createSelector(
  [
    selectTicketsInBasket,
    selectSeatsInBasket,
    selectProductLineItems,
    selectPriceLevelEntities
  ],
  (tickets, seats, products, priceLevels) => {
    const ticketsTotal = tickets.reduce((total, ticket) => {
      const priceLevel = priceLevels[ticket.priceLevelId]
      const priceType = priceLevel?.priceTypes.find(
        priceType => priceType.id === ticket.priceTypeId
      )
      return (total +=
        ticket.quantity * (priceLevel?.originalPrice ?? priceType?.total ?? 0))
    }, 0)
    const seatsTotal = seats.reduce((total, seat) => {
      const priceLevel = priceLevels[seat.priceLevelId]
      const priceType = priceLevel?.priceTypes.find(
        priceType => priceType.id === seat.priceTypeId
      )
      return (total += priceLevel?.originalPrice ?? priceType?.total ?? 0)
    }, 0)
    const productsTotal = products.reduce(
      (acc, cur) => (acc += cur.total * cur.quantity),
      0
    )
    return ticketsTotal + seatsTotal + productsTotal
  }
)
export const selectDiscount = createSelector(
  [selectBasketTotalOriginal, selectBasketTotal],
  (originalTotal, discountedTotal) => originalTotal - discountedTotal
)

// Reservation (Server side reservation)
// -----------------------

export const selectCurrentReservation = (state: AppState) =>
  state.basket.currentTransaction

export const selectReservationEventId = createSelector(
  selectCurrentReservation,
  reservation => reservation?.basket.ticketReservations[0]?.eventId
)
export const selectBasketExpiry = (state: AppState) => state.basket.expiresAt

export const selectBasketExpiryMs = createSelector(
  selectBasketExpiry,
  expiresAt => moment(expiresAt).diff(moment(), 'ms')
)

export const selectBookingFlow = (state: AppState) => state.basket.bookingFlow

export const selectReservationGuid = (state: AppState) =>
  state.basket.transactionId ?? undefined

export const selectIsBookingProtectActive = createSelector(
  selectCurrentReservation,
  reservation => !!reservation?.basket.bookingProtectActive
)
export const selectBookingProtectTotal = createSelector(
  selectCurrentReservation,
  reservation => reservation?.basket.bookingProtectTotal ?? 0
)
export const selectDeliveryOptionId = createSelector(
  selectCurrentReservation,
  reservation => reservation?.basket.delivery?.id
)
export const selectBasketBalanceInPence = createSelector(
  selectCurrentReservation,
  reservation => Math.round((reservation?.basket.balance ?? 0) * 100) * -1
)

// Reservation Request
// -----------------------

const selectBestAvailableReservation = createSelector(
  [
    selectBestAvailable,
    selectActivePerformanceId,
    selectSelectedPriceLevelIds,
    getRecommendationPriceTypeId,
    seatMapSelectors.selectBestAreaForPriceLevels
  ],
  (quantity, performanceId, priceLevelIds, priceTypeId, areaId) =>
    quantity > 0
      ? [
          {
            quantity,
            performanceId,
            priceTypeId,
            priceLevelIds,
            areaId
          }
        ]
      : undefined
)

export const selectReservation = (state: AppState): Reservation => {
  const reservationGuid = selectReservationGuid(state)
  const discount = discountSelectors.getDiscountCodeForBasket(state)
  const bestAvailableReservation = selectBestAvailableReservation(state)
  const linkedTransactionGuid = selectLinkedOrderId(state)
  // Seats
  const seatReservation = selectSeatsInBasket(state).map(ticketWithSeat => ({
    ...ticketWithSeat,
    reservationMode: 'ReservedSeating'
  }))

  // GA Tickets
  const ticketReservations = selectTicketsInBasket(state).map(ticket => ({
    ...ticket,
    reservationMode: 'GeneralAdmission'
  }))

  const allTicketReservations = bestAvailableReservation ?? [
    ...ticketReservations,
    ...seatReservation
  ]

  // Products
  const productReservations = selectProductLineItems(state).map(
    (product: Product) => {
      return {
        id: product.id,
        priceLevelId: product.priceLevelId,
        priceTypeId: product.priceTypeId,
        promptId: product.promptId,
        quantity: product.quantity,
        performanceId: product.performanceId
      }
    }
  )

  // Delivery Option
  const selectedDeliveryOptionId = selectDeliveryOptionId(state)

  // Product Reservations
  const isBookingProtectActive = selectIsBookingProtectActive(state)

  return {
    ...(reservationGuid && { transactionGuid: reservationGuid }),
    ...(linkedTransactionGuid && {
      linkedTransactionGuid: linkedTransactionGuid
    }),
    basket: {
      ticketReservations: allTicketReservations,
      ...(productReservations.length > 0 && {
        productReservations: productReservations
      }),
      ...(selectedDeliveryOptionId && {
        delivery: { id: selectedDeliveryOptionId }
      }),
      ...(isBookingProtectActive && {
        bookingProtectActive: isBookingProtectActive
      }),
      ...(discount && { discountCode: discount })
    }
  }
}

// Order
export const selectOrderToken = (state: AppState) => state.basket.orderToken

export const selectOrderStatus = (state: AppState) => state.basket.orderStatus

export const selectOrderCustomer = (state: AppState) => ({
  firstName: state.basket.order?.customerFirstName,
  lastName: state.basket.order?.customerLastName,
  email: state.basket.order?.customerEmail
})

export const selectPaymentChallenge = (state: AppState) =>
  state.basket.paymentAction

export const selectOrder = (state: AppState) => state.basket.order

export const selectLinkedOrderId = createSelector(selectOrder, order =>
  order?.state === 'CommittedConfirmed' ? order?.transactionGuid : null
)

export const selectTransactionState = (state: AppState) =>
  state.basket.transactionState

export const selectOrderEventId = createSelector(
  selectOrder,
  order => order?.basket.ticketReservations[0]?.eventId
)

export const selectOrderReferenceNumber = createSelector(
  selectOrder,
  order => order?.referenceNumber
)

export const selectOrderTransactionNumber = createSelector(
  selectOrder,
  order => order?.transactionNumber
)

export const selectOrderDeliveryOptionDescription = createSelector(
  selectOrder,
  order => order?.basket.delivery?.description
)

export const selectIsProcessingOrder = (state: AppState) =>
  state.basket.isOrdering

export const selectTransactionSummary = createSelector(
  selectCurrentReservation,
  selectOrder,
  (reservation, order) => {
    const transaction =
      order?.state === 'CommittedConfirmed' ? order : reservation
    if (transaction) {
      const ticketReservations = transaction.basket.ticketReservations.reduce(
        (acc, cur) => {
          acc[cur.performanceId] = {
            id: cur.performanceId,
            name: cur.name,
            subtitle: moment
              .utc(cur.startDate)
              .format('dddd Do MMM YYYY, h:mm a')
              .toString(),
            items: [
              ...(acc[cur.performanceId]?.items || []),
              {
                id: cur.performanceId,
                title: '',
                subtitle: [cur.areaName, cur.rowName, cur.seatName]
                  .filter(Boolean)
                  .join(' '),
                price: cur.total,
                quantity: cur.quantity
              }
            ]
          }
          return acc
        },
        {} as { [key: string]: TransactionGroup }
      )

      const productReservation = transaction.basket.productReservations.reduce(
        (acc, cur) => {
          acc[cur.performanceId] = {
            id: cur.performanceId,
            name: cur.name,
            items: [
              ...(acc[cur.performanceId]?.items || []),
              {
                id: cur.performanceId,
                title: '',
                subtitle: cur.priceTypeName,
                price: cur.total,
                quantity: cur.quantity
              }
            ]
          }
          return acc
        },
        {} as { [key: string]: TransactionGroup }
      )
      const delivery = transaction.basket.delivery
        ? {
            amount: transaction.basket.delivery.price,
            description: transaction.basket.delivery.description
          }
        : undefined
      const bookingProtect = transaction?.basket.bookingProtectActive
        ? {
            amount: transaction.basket.bookingProtectTotal,
            description: 'Booking Protect'
          }
        : undefined

      const discount = transaction.basket.ticketReservations.reduce(
        (total, ticketReservation) =>
          (total += ticketReservation.discount * -1),
        0
      )
      const vouchers = transaction?.basket.giftVouchers?.map(voucher => ({
        amount: voucher.amount * -1,
        description: voucher.name
      }))
      const subtotals = [
        ...(delivery ? [delivery] : []),
        ...(bookingProtect ? [bookingProtect] : [])
      ]

      const subtotalDeduction = delivery?.amount || 0 + discount

      const voucherDeduction = vouchers.reduce(
        (curr, prev) => (curr += prev.amount),
        0
      )
      return {
        groups: [
          ...Object.values(ticketReservations),
          ...Object.values(productReservation)
        ],
        subtotals: [
          {
            amount: transaction.basket.totalPrice - subtotalDeduction,
            description: 'Subtotal'
          },
          ...subtotals,
          ...vouchers,
          ...(discount < 0
            ? [{ amount: discount, description: 'Discount' }]
            : [])
        ],
        total: transaction.basket.totalPrice + voucherDeduction
      } as Transaction
    }
    return { groups: [], total: 0, subtotals: [] } as Transaction
  }
)

// Socially Distanced Seating
// -----------------------

export const selectSeatBlock = createSelector(
  [
    seatMapSelectors.getSeat,
    seatMapSelectors.selectAllSeats,
    availabilitySelectors.selectIds
  ],
  (currentSeat, seats, availableIds) => {
    if (currentSeat) {
      const availableSeats = seats.filter(
        seat =>
          availableIds.includes(seat.seatId) &&
          seat.rowGuid === currentSeat.rowGuid
      )
      let i = currentSeat.ordinal
      const ordinalBlock: number[] = []
      const ordinals = availableSeats.map((seat: Seat) => seat.ordinal)
      while (i <= Math.max(...ordinals)) {
        i++
        if (ordinals.includes(i)) {
          ordinalBlock.push(i)
        } else {
          break
        }
      }
      i = currentSeat.ordinal
      while (i >= Math.min(...ordinals)) {
        i--
        if (ordinals.includes(i)) {
          ordinalBlock.push(i)
        } else {
          break
        }
      }
      return availableSeats
        .filter(seat => ordinalBlock.includes(seat.ordinal))
        .map(seat => seat.id)
    }
  }
)

export const selectSeatBlockToRemove = createSelector(
  [seatMapSelectors.getSeat, selectSeatsInBasketWithDetails],
  (currentSeat, seatsInBasket) => {
    if (currentSeat) {
      const seatsOnRow = seatsInBasket.filter(
        seat => currentSeat.rowGuid === seat.rowGuid
      )
      let i = currentSeat.ordinal
      const ordinalBlock: number[] = []
      const ordinals = seatsOnRow.map((seat: Seat) => seat.ordinal)
      while (i <= Math.max(...ordinals)) {
        i++
        if (ordinals.includes(i)) {
          ordinalBlock.push(i)
        } else {
          break
        }
      }
      i = currentSeat.ordinal
      while (i >= Math.min(...ordinals)) {
        i--
        if (ordinals.includes(i)) {
          ordinalBlock.push(i)
        } else {
          break
        }
      }
      return seatsOnRow
        .filter(seat => ordinalBlock.includes(seat.ordinal))
        .map(seat => seat.id)
    }
  }
)

// Seat Restrictions
// -----------------
export const selectRestrictedSeatsInBasket = createSelector(
  [selectSeatLineItemsInBasket],
  seats =>
    seats.reduce((restrictedSeats, seat) => {
      if (
        seat.seatType?.icon &&
        ['accessible', 'information', 'warning'].includes(seat.seatType.icon)
      ) {
        restrictedSeats = [...restrictedSeats, seat]
      }
      return restrictedSeats
    }, [] as SeatLineItem[])
)

// Gift Voucher
// -----------------
export const selectGiftVoucherLoading = (state: AppState) =>
  state.basket.giftVoucherLoading === 'loading'
