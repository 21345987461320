import styled from 'styled-components/macro'
import { media } from 'utils'

export const FormRow = styled.div<{ mobileNarrow?: boolean }>`
  display: flex;
  flex-direction: row;

  > :first-child {
    margin-right: 12px;
    flex: 1 0 auto;
  }

  > :last-child {
    margin-left: 12px;
    flex: 1 0 auto;
  }

  ${media.tablet} {
    flex-direction: ${props => (props.mobileNarrow ? 'row' : 'column')};

    > :first-child {
      margin-right: ${props => (props.mobileNarrow ? '10px' : 'initial')};
      width: ${props => (props.mobileNarrow ? 'calc(50% - 10px)' : 'initial')};
    }

    > :last-child {
      margin-left: ${props => (props.mobileNarrow ? '10px' : 'initial')};
      width: ${props => (props.mobileNarrow ? 'calc(50% - 10px)' : 'initial')};
    }
  }
`
