// Misc
import { createSelector } from '@reduxjs/toolkit'
import moment from 'moment'
// Types
import { AppState } from 'modules/types'
import { Transaction, TransactionItem } from 'shared-types'

// Selectors
import { menuSelectors } from 'modules/hospitality/menu'
import { occasionSelectors } from 'modules/hospitality/occasion'

export const selectAdultQuantity = (state: AppState) =>
  state.hospitality.reservation.currentReservation.adults

export const selectChildQuantity = (state: AppState) =>
  state.hospitality.reservation.currentReservation.children

export const selectSelectedCalendarMonth = (state: AppState) =>
  state.hospitality.reservation.currentReservation.selectedCalendarMonth

export const selectSelectedCalendarMonthDate = createSelector(
  selectSelectedCalendarMonth,
  calendarMonth => moment(calendarMonth)
)

export const selectSelectedDateString = (state: AppState) =>
  state.hospitality.reservation.currentReservation.selectedDate

export const selectSelectedDate = createSelector(
  selectSelectedDateString,
  dateString => (dateString ? new Date(dateString) : undefined)
)

export const selectSelectedTimeslot = (state: AppState) =>
  state.hospitality.reservation.currentReservation.selectedTimeslot

export const selectSelectedDateAndTime = createSelector(
  [selectSelectedDateString, selectSelectedTimeslot],
  (date, time) => {
    const dateM = moment.utc(date)
    const timeM = moment.utc(time, 'HH:mm')
    return moment.utc({
      date: dateM.date(),
      month: dateM.month(),
      year: dateM.year(),
      hour: timeM.hour(),
      minutes: timeM.minutes()
    })
  }
)

export const selectSelectedMenuId = (state: AppState) =>
  state.hospitality.reservation.currentReservation.selectedMenuId

export const selectSelectedMenu = createSelector(
  [selectSelectedMenuId, menuSelectors.selectAll],
  (menuId, menus) => menus.find(menu => menu.id === menuId)
)
export const selectReservationTotal = createSelector(
  [selectSelectedMenu, selectAdultQuantity, selectChildQuantity],
  (menu, adults, children) =>
    menu ? menu.depositPerAdult * adults + menu.depositPerChild * children : 0
)

export const selectCurrentReservation = (state: AppState) =>
  state.hospitality.reservation.currentReservation

export const selectPaymentAmount = createSelector(
  [selectReservationTotal],
  total => total * 100
)
export const selectIsBasketEmpty = (state: AppState) =>
  !selectSelectedMenuId(state)

export const selectReservations = (state: AppState) =>
  state.hospitality.reservation.reservationsByOccasionId

export const selectBasketSummary = createSelector(
  [
    selectReservations,
    occasionSelectors.selectEntities,
    menuSelectors.selectEntities,
    selectReservationTotal
  ],
  (reservations, occasions, menus, total) => {
    return Object.keys(reservations).reduce(
      (summary, occasionId) => {
        const occasion = occasions[occasionId]
        if (occasion) {
          const group = {
            id: occasion.id,
            name: occasion.name,
            items: reservations[occasionId].reduce((items, item) => {
              const menu = menus[item.selectedMenuId]
              const date = moment(item.selectedDate).format('dddd Do MMM YYYY')
              if (menu) {
                items.push({
                  id: menu.id,
                  title: menu.name,
                  detail: `${date}, ${item.selectedTimeslot}`,
                  subtitle: 'Adult',
                  price: menu.depositPerAdult * item.adults,
                  quantity: item.adults,
                  ...(item.children > 0 && {
                    choices: [
                      {
                        id: menu.id,
                        title: 'Child',
                        price: menu.depositPerChild * item.children,
                        quantity: item.children
                      }
                    ]
                  })
                })
              }
              return items
            }, [] as TransactionItem[])
          }
          return {
            ...summary,
            groups: [...summary.groups, group]
          }
        }
        return summary
      },
      { groups: [], total: total, subtotals: [] } as Transaction
    )
  }
)
