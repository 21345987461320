import React, { useState, useEffect } from 'react'
import styled, { css } from 'styled-components/macro'
import { media } from 'utils'
import { SegmentedControlItem } from './SegmentedControlItem'
import { IconName } from 'shared-types'
import { IconPrefix } from '@fortawesome/fontawesome-svg-core'

export interface SegmentedControlItemProps {
  id: string
  name: string
  price?: number
  imageUrl?: string
  isSelected?: boolean
  description?: string
  width?: number
  icon?: IconName
  iconPrefix?: IconPrefix
  isStacked?: boolean
}

interface Props {
  items: SegmentedControlItemProps[]
  onSelectItem: (id: string) => void
  selectedItemId?: string | null
  isStacked?: boolean
  isReserving?: boolean
}

const SegmentedControlWrapper = styled.div<{
  isStacked: boolean
}>((props) => {
  const { isStacked } = props

  return css`
    display: flex;
    flex-direction: row;
    margin-top: 0.75rem;
    flex-wrap: nowrap;

    ${media.phone} {
      margin-top: ${isStacked ? '0.5rem' : '1rem'};
      flex-direction: ${isStacked ? 'column' : 'row'};
    }
  `
})

export const SegmentedControl = (props: Props) => {
  const {
    items,
    onSelectItem,
    selectedItemId,
    isReserving = false,
    isStacked = false
  } = props
  const [tempSelectedId, setTempSelectedId] = useState<string | null>()

  useEffect(() => {
    tempSelectedId !== null &&
      tempSelectedId === selectedItemId &&
      !isReserving &&
      setTempSelectedId(null)
  }, [isReserving, tempSelectedId, selectedItemId])

  return (
    <SegmentedControlWrapper isStacked={isStacked}>
      {items.map((item) => {
        const {
          id,
          name,
          price,
          imageUrl,
          description,
          icon,
          iconPrefix
        } = item

        const handleDeliveryOptionChange = () => {
          setTempSelectedId(id)
          onSelectItem(id)
        }

        return (
          <SegmentedControlItem
            key={id}
            id={id}
            name={name}
            price={price}
            imageUrl={imageUrl}
            isSelected={selectedItemId === id}
            isTempSelected={tempSelectedId === id}
            onDeliveryOptionChange={handleDeliveryOptionChange}
            description={description}
            width={100 / items.length}
            isStacked={isStacked}
            icon={icon}
            iconPrefix={iconPrefix}
          />
        )
      })}
    </SegmentedControlWrapper>
  )
}
