import { createAsyncThunk } from '@reduxjs/toolkit'
import { bannerActions } from 'modules/banner'
import moment from 'moment'
import { normalize, schema } from 'normalizr'
import contentfulApi from '../services/contentfulApi'
import {
  ContentfulEventCollectionEntity,
  ContentfulVenuePageEntity,
  VenuePage
} from '../shared/types'

const venuePageSchema = new schema.Entity('venuePages')

type FetchVenuePagesResponse = {
  venuePages: Record<string, VenuePage>
}

const excludeShortCollections = (
  eventCollections: ContentfulEventCollectionEntity[]
) => {
  return eventCollections.filter(
    eventCollection =>
      eventCollection.fields.events.filter(event => {
        if (!event.fields.endDate) return true
        const endDate = moment(event.fields.endDate)
        return endDate.utc() >= moment().utc()
      }).length >= 3
  )
}

export const fetchVenuePages = createAsyncThunk<
  FetchVenuePagesResponse,
  undefined
>(
  'marvel/self-serve/FETCH_VENUE_PAGES',
  async (_, { dispatch, rejectWithValue }) => {
    try {
      const venuePages = (await contentfulApi.fetchMany({
        contentType: 'venuePage',
        include: 2
      })) as ContentfulVenuePageEntity[]

      if (!venuePages) {
        throw new Error('Failed to fetch venue pages')
      }
      const venuePagesData = venuePages.map(venuePage => {
        return {
          ...venuePage.fields,
          id: venuePage.sys.id,
          name: venuePage.fields.name,
          globalFeatured: excludeShortCollections(
            venuePage.fields.globalFeatured || []
          ).map(x => x.sys.id),
          spotlight: venuePage.fields.spotlight.sys.id,
          venueFeatured: excludeShortCollections(
            venuePage.fields.venueFeatured || []
          ).map(x => x.sys.id),
          venue: venuePage.fields.venue.sys.id,
          callToAction: venuePage.fields.callToAction
        }
      })
      const normalized = normalize(venuePagesData, [venuePageSchema])
      return normalized.entities as FetchVenuePagesResponse
    } catch (err) {
      dispatch(
        bannerActions.setBannerContent({
          bannerType: 'error',
          title: 'Please Note',
          text: 'Failed to fetch venue pages.'
        })
      )
      return rejectWithValue(err)
    }
  }
)
