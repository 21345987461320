import { combineReducers } from 'redux'
import * as actions from './actions'
import { CLEAR_RESERVATION } from '../basket'
import { RoutineAction } from 'modules/shared'

const byId = (state: string[] = [], action: RoutineAction) => {
  switch (action.type) {
    case actions.fetchOptins.TRIGGER:
    case actions.fetchOptins.FAILURE:
    case CLEAR_RESERVATION:
      return []
    case actions.fetchOptins.SUCCESS:
      return action.payload.response.result as string[]
    default:
      return state
  }
}

const loading = (state = 'idle', action: RoutineAction) => {
  switch (action.type) {
    case actions.fetchOptins.TRIGGER:
      return 'pending'
    case actions.fetchOptins.SUCCESS:
    case actions.fetchOptins.FAILURE:
    case actions.fetchOptins.FULFILL:
      return 'idle'
    default:
      return state
  }
}

export default combineReducers({
  byId,
  loading
})
