import React from 'react'
import styled from 'styled-components/macro'

// Components
import { Title, WithLoader } from 'marvel-components'

// Containers
import { ProductItemContainer } from './ProductItemContainer'
import { BookingHeaderContainer } from 'containers/Ticketing'

// Modules
import { selectAllPrompts, selectIsLoading } from 'modules/prompt/selectors'

// Misc
import { useSelector } from 'react-redux'
import { media, usePlatform } from 'utils'

const PrePurchaseProductsWrapper = styled.div<{ platform?: string }>`
  padding: 1.5rem;

  ${media.tablet} {
    padding: 1rem;

    min-height: ${props => (props.platform === 'kiosk' ? '65vh' : '50vh')};
  }
`

const ProductListWrapper = WithLoader(styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  box-sizing: border-box;
  background: ${props => props.theme.colors.secondaryBackgroundColor};
`)

const ProductsWrapper = styled.div<{ platform?: string }>`
  margin-top: 1rem;
  padding-bottom: 1rem;
  margin-bottom: 1.5rem;
  border-bottom: 0.0625rem solid ${props => props.theme.colors.borderColor};
  display: flex;
  flex-wrap: wrap;

  ${media.tablet} {
    flex-direction: column;
  }
  ${media.phone} {
    margin-top: 0.5rem;
  }
`

const PromptWrapper = styled.div`
  margin-bottom: 1rem;
`

const PrePurchaseProductsContainer: React.FC = () => {
  // Selectors
  const prompts = useSelector(selectAllPrompts)
  const isLoading = useSelector(selectIsLoading)
  const platform = usePlatform()

  return (
    <PrePurchaseProductsWrapper platform={platform}>
      <BookingHeaderContainer />
      <ProductListWrapper isLoading={isLoading}>
        {prompts.length > 0 && (
          <>
            <Title
              isCapitalised={false}
              isCentralised={false}
              isBold={true}
              size='large'
            >
              Make your visit extra special
            </Title>

            {prompts.map(prompt => {
              const { id, products, name } = prompt

              return (
                <PromptWrapper key={`prompt-${id}`}>
                  {name && (
                    <Title
                      isCapitalised={false}
                      isCentralised={false}
                      isBold={true}
                      size='medium'
                      isPadded={true}
                    >
                      {name}
                    </Title>
                  )}
                  {products && (
                    <ProductsWrapper platform={platform}>
                      {products.map(productId => {
                        return (
                          <ProductItemContainer
                            productId={productId}
                            isModal={false}
                            key={productId}
                            isFullWidth={true}
                          />
                        )
                      })}
                    </ProductsWrapper>
                  )}
                </PromptWrapper>
              )
            })}
          </>
        )}
      </ProductListWrapper>
    </PrePurchaseProductsWrapper>
  )
}

export { PrePurchaseProductsContainer }
