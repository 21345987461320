import React from 'react'

// Selectors
import { useSelector } from 'react-redux'

// Components
import { FooterNav } from 'marvel-components'
import { selectAllItemCount } from 'modules/basket/selectors'

const FooterNavContainer: React.FC = () => {
  const basketItemCount = useSelector(selectAllItemCount)
  return basketItemCount === 0 ? <FooterNav /> : null
}

export { FooterNavContainer }
