import { AnyAction } from 'redux'

/* const updateEntity = (state: any, entities: any) => {
  return merge({}, state, entities)
} */

const updateEntities = (state: any, key: any, entities: any) => {
  return Object.assign({}, state, {
    [key]: entities[key]
  })
}
const defaultDataResolver = (action: AnyAction) => {
  if (
    action.payload &&
    action.payload.response &&
    action.payload.response.entities
  ) {
    return action.payload.response.entities
  }
  return null
}

const entities = (state: any = {}, action: AnyAction) => {
  let newState = state
  const entities = defaultDataResolver(action)
  if (entities && typeof entities === 'object') {
    newState = Object.keys(entities).reduce(
      (acc, key) => updateEntities(acc, key, entities),
      newState
    )
  }
  return newState
}

export default entities
