import React from 'react'
import styled from 'styled-components/macro'
import { useField, FieldHookConfig, ErrorMessage } from 'formik'
import {
  Select,
  FormError,
  SelectOption,
  SelectOptionValue
} from 'marvel-components'

const FormSelectWrapper = styled.div`
  position: relative;
  padding-bottom: 1.6rem;
`

interface Props {
  options: SelectOption[]
  isDisabled?: boolean
  onOptionSelect?: (option: SelectOptionValue) => void
}

export const FormSelect: React.FC<
  Props & FieldHookConfig<SelectOptionValue>
> = ({
  name,
  placeholder,
  options,
  onOptionSelect,
  isDisabled,
  ...selectProps
}) => {
  const [field, meta, helpers] = useField(name)
  const handleOnchange = (value: SelectOptionValue) => {
    if (value) {
      helpers.setValue(value)
    }
    onOptionSelect && onOptionSelect(value)
  }
  return (
    <FormSelectWrapper>
      {meta.touched && meta.error ? (
        <ErrorMessage name={name} component={FormError} />
      ) : null}
      <Select
        name={name}
        placeholder={placeholder}
        onChange={handleOnchange}
        options={options}
        value={field.value}
        isDisabled={isDisabled}
      />
    </FormSelectWrapper>
  )
}
