import React from 'react'
import styled from 'styled-components'
import { media, usePlatform } from 'utils'

type Props = {
  id: number | string
  title: string
  isDisabled?: boolean
  description?: string
  minimumCustomerAge?: number
  imageUrl?: string
  largeIcon?: boolean
  backgroundColor?: string
  textColor?: string
  stacked?: boolean
  light?: boolean
  icon?: boolean
  onClick?: (id: string | number) => void
  platform?: string
}

const ListItemWrapper = styled.div<{
  backgroundColor: string
  textColor: string
  stacked: boolean
  light: boolean
  icon: boolean
  largeIcon: boolean
  isDisabled: boolean
  platform?: string
}>`
  display: flex;
  flex-direction: ${props => (props.stacked ? 'column' : 'row')};
  align-items: center;
  justify-content: flex-start;
  flex-wrap: nowrap;
  background: ${props =>
    props.isDisabled ? props.theme.colors.borderColor : props.backgroundColor};
  color: ${props =>
    props.isDisabled ? props.theme.colors.secondaryColor : props.textColor};
  font-family: ${props => props.theme.font.header};
  border-radius: 2px;
  padding: ${props =>
    props.largeIcon ? '2rem 1.5%' : props.icon ? '1rem 1.5%' : '1.5rem 1.5%'};
  width: 49%;
  margin: 0.5rem 0;
  cursor: pointer;
  border: ${props => (props.light ? '1px solid' : 'none')};
  border-color: ${props =>
    props.light ? props.theme.colors.borderColor : 'none'};
  box-sizing: border-box;
  box-shadow: ${props =>
    props.isDisabled
      ? 'none'
      : 'rgba(0, 0, 0, 0.1) 0px 2px 3px 0px, rgba(0, 0, 0, 0.06) 0px 10px 15px 0px'};
  ${media.tablet} {
    width: ${props => (props.platform === 'kiosk' ? '49%' : '100%')};
    padding: ${props =>
      props.platform === 'kiosk'
        ? '3rem 3%'
        : props.largeIcon
        ? '2rem 1.5%'
        : props.icon
        ? '1rem 1.5%'
        : '1.5rem 1.5%'};
  }
  ${media.phone} {
    padding: ${props =>
      props.largeIcon ? '1.5rem 2%' : props.icon ? '0.75rem 5%' : '1.5rem 5%'};
    width: 100%;
  }
`

const ListItemDetailsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 0 0;
`

const ListItemHeader = styled.span`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`

const ListItemTitleWrapper = styled.span`
  display: flex;
  flex-direction: row;
  align-items: center;
`

const ListItemTitleLabel = styled.span``

const ListItemTitle = styled.span`
  font-size: 1rem;
`

const UnavailableBanner = styled.span`
  margin-left: 0.25rem;
`

const ListItemAgeWrapper = styled.span`
  margin-left: 0.5rem;
  width: 18px;
  height: 18px;
  line-height: 15px;
  border-radius: 9px;
  border: 2px solid #999;
  box-sizing: border-box;
  color: #999;
  font-size: 11px;
  text-align: center;
`

const ListItemDescription = styled.span`
  font-family: ${props => props.theme.font.copy};
  margin: 1rem 0 0 0;
  font-size: 0.875rem;
  line-height: 1.25;
`

const ListItemIconWrapper = styled.div<{
  stacked: boolean
  largeIcon: boolean
}>`
  width: ${props => (props.largeIcon ? '100px' : '40px')};
  height: ${props => (props.largeIcon ? '100px' : '40px')};
  margin-right: ${props => (props.stacked ? '0' : '1rem')};
  margin-bottom: ${props => (props.stacked ? '2rem' : '0')};
  display: flex;
  align-items: center;
  ${media.phone} {
    width: ${props => (props.largeIcon ? '90px' : '32px')};
    height: ${props => (props.largeIcon ? '90px' : '32px')};
    margin-bottom: ${props => (props.stacked ? '1.5rem' : '0')};
    margin-right: ${props => (props.stacked ? '0' : '1rem')};
  }
`

const ListItemIcon = styled.img`
  width: 100%;
`

const ListItem: React.FC<Props> = ({
  id,
  title,
  description,
  isDisabled = false,
  minimumCustomerAge,
  imageUrl,
  onClick,
  backgroundColor = '#fff',
  textColor = '#000',
  stacked = false,
  light = true,
  icon = false,
  largeIcon = false
}) => {
  const handleOnClick = (e: React.MouseEvent) => {
    e.preventDefault()
    return !isDisabled && onClick && onClick(id)
  }
  const platform = usePlatform()
  return (
    <ListItemWrapper
      backgroundColor={backgroundColor}
      textColor={textColor}
      onClick={handleOnClick}
      stacked={stacked}
      light={light}
      largeIcon={largeIcon}
      icon={icon}
      isDisabled={isDisabled}
      platform={platform}
    >
      {icon && (
        <ListItemIconWrapper stacked={stacked} largeIcon={largeIcon}>
          <ListItemIcon src={imageUrl} />
        </ListItemIconWrapper>
      )}
      <ListItemDetailsWrapper>
        <ListItemHeader>
          <ListItemTitleWrapper>
            <ListItemTitle>
              {title}
              {isDisabled && (
                <UnavailableBanner>- Unavailable</UnavailableBanner>
              )}
            </ListItemTitle>
            {minimumCustomerAge && (
              <ListItemAgeWrapper>{minimumCustomerAge}</ListItemAgeWrapper>
            )}
          </ListItemTitleWrapper>
          {/* ListItemTitleLabel is for e.g. price */}
          <ListItemTitleLabel></ListItemTitleLabel>
        </ListItemHeader>
        {description && (
          <ListItemDescription>{description}</ListItemDescription>
        )}
      </ListItemDetailsWrapper>
    </ListItemWrapper>
  )
}

export { ListItem }
