import React, { ReactNode, ReactElement } from 'react'
import { media } from 'utils'
import styled from 'styled-components/macro'

// Misc
import Helmet from 'react-helmet'
import { useIsNarrowViewport, usePlatform, themedBackground } from 'utils'

interface Props {
  // Render Props
  renderBrandLogo?: ReactNode
  renderSidebarTop?: ReactNode
  renderSidebarBottom?: ReactNode
  renderMainTop?: ReactElement
  renderMain?: ReactElement
  renderMainBottom?: ReactElement
  renderFooter?: ReactElement
  justifyLeftContent?: boolean
  pageTitle?: string
}

const LayoutWrapper = styled.div`
  /* background: ${props =>
    !props.theme.themeBackgroundColour &&
    props.theme.colors
      .mainBackgroundColor}; This has been removed as I believe it is replicating what the AppBackground Wrappper in AppContainer.tsx is doing */
  width: 100%;
  min-height: 100vh;
  position: relative;
  display: flex;
  flex-direction: row;

  ${media.tablet} {
    flex-direction: column;
  }
`

const MainSection = styled.div<{ platform?: string }>`
  position: relative;
  flex: 3 0 0;
  background: #ffffff;
  width: 100%;

  ${media.tablet} {
    flex: initial;
    height: initial;
    overflow-y: initial;
    margin-left: initial;
  }
`

const ScrollbarSection = styled.div`
  height: 100vh;
  display: flex;
  flex-direction: column;
  width: 22.4rem;
  min-width: 22.4rem;
  max-width: 22.4rem;
  overflow-y: auto;
  position: sticky;
  top: 0;

  ${props =>
    props.theme.themeBackgroundColour
      ? themedBackground
      : `background: ${props.theme.colors.mainBackgroundColor};`}
  ${media.tablet} {
    ${props =>
      props.theme.themeBackgroundColour
        ? themedBackground
        : `background: ${
            props.platform === 'kiosk'
              ? 'white'
              : props.theme.colors.mainBackgroundColor
          };`}
  }

  ${media.tablet} {
    padding-right: initial;
  }
`

const Section = styled.section`
  position: relative;
`

const KioskBottomSection = styled.section`
  position: relative;
  margin-top: auto;
`

const Layout: React.FC<Props> = ({
  renderBrandLogo,
  renderSidebarTop,
  renderMainTop,
  renderMain,
  renderMainBottom,
  renderSidebarBottom,
  renderFooter,
  pageTitle
}) => {
  const isNarrowViewport = useIsNarrowViewport()
  const platform = usePlatform()

  let content = (
    <>
      <ScrollbarSection>
        {renderBrandLogo}
        {renderSidebarTop}
        {renderSidebarBottom}
        {renderFooter}
      </ScrollbarSection>
      <MainSection
        className='layout-main-section'
        id='maincontent'
        platform={platform}
      >
        {renderMainTop}
        {renderMain}
        {renderMainBottom}
      </MainSection>
    </>
  )

  if (isNarrowViewport) {
    content = (
      <>
        {renderBrandLogo}
        {renderMainTop}
        {renderSidebarTop}
        <MainSection
          className='layout-main-section'
          id='maincontent'
          platform={platform}
        >
          {renderMain}
        </MainSection>
        <Section>
          {renderMainBottom}
          {renderSidebarBottom}
        </Section>
        {renderFooter}
      </>
    )
  }

  if (platform === 'kiosk' && isNarrowViewport) {
    content = (
      <>
        <Section>
          {renderBrandLogo}
          {renderMainTop}
          {renderSidebarTop}
        </Section>
        <MainSection
          className='layout-main-section'
          id='maincontent'
          platform={platform}
        >
          {renderMain}
        </MainSection>
        <KioskBottomSection>
          {renderMainBottom}
          {renderSidebarBottom}
        </KioskBottomSection>
        {renderFooter}
      </>
    )
  }

  return (
    <>
      {pageTitle && (
        <Helmet>
          <title>{pageTitle}</title>
        </Helmet>
      )}
      <LayoutWrapper>{content}</LayoutWrapper>
    </>
  )
}

export { Layout }
