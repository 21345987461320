import React from 'react'

// Containers
import { LoginButtonContainer } from 'containers/Ticketing'

// Components
import { BrandLogo, MainMenuNavButton } from 'marvel-components'

// Selectors
import { eventDetailsSelectors } from 'modules/ticketing/event'
import { selectCurrentReservation } from 'modules/basket/selectors'

// Misc
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { useAppDispatch } from 'app/reduxStore'
import { kioskMainMenu, usePlatform } from 'utils'
import { openModal } from 'modules/overlay/actions'

type Props = {
  showLogin?: boolean
}
const BrandLogoContainer: React.FC<Props> = ({ showLogin = false }) => {
  const dispatch = useAppDispatch()
  const platform = usePlatform()
  const history = useHistory()

  const address = useSelector(eventDetailsSelectors.selectVenueAddress)
  const venueName = useSelector(eventDetailsSelectors.selectVenueName)
  const logoName = useSelector(eventDetailsSelectors.selectLogoUrl)
  const logoBackground = useSelector(eventDetailsSelectors.selectLogoBackground)
  const eventId = useSelector(eventDetailsSelectors.selectEventDetailsId)
  const eventName = useSelector(eventDetailsSelectors.selectEventName)
  const reservation = useSelector(selectCurrentReservation)

  const handleReturnToMainMenu = () => {
    if (reservation?.state) {
      dispatch(
        openModal({
          variant: 'kiosk-clear-basket'
        })
      )
    } else {
      history.push(kioskMainMenu())
    }
  }

  return (
    <BrandLogo
      address={address}
      venue={venueName}
      logoName={logoName}
      eventName={eventName}
      backgroundColor={logoBackground}
      lwLogoLink={
        platform === 'kiosk' ? `/event/${eventId}` : 'https://lwtheatres.co.uk/'
      }
      showLogoLink={`/event/${eventId}`}
      loginButton={showLogin ? <LoginButtonContainer isSmall /> : undefined}
      renderNavButton={
        platform === 'kiosk'
          ? () => <MainMenuNavButton onClick={handleReturnToMainMenu} />
          : undefined
      }
    />
  )
}

export { BrandLogoContainer }
