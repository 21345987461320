import React from 'react'
import styled from 'styled-components/macro'
import { LWTheatreLogo, StarLogo } from 'marvel-components'

const BookingFooterWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: auto;
  padding: 1rem 0;
  align-items: center;
`

export const BookingFooter = () => (
  <BookingFooterWrapper>
    <LWTheatreLogo isWaterMark />
    <StarLogo />
  </BookingFooterWrapper>
)
