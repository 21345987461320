import React from 'react'

// Components
import { Icon, SegmentedControl } from 'marvel-components'

// Selectors
import { deliveryOptionSelectors } from 'modules/ticketing/delivery-option'
import {
  selectDeliveryOptionId,
  selectReservationGuid
} from 'modules/basket/selectors'

// Actions
import { updateReservation } from 'modules/basket/actions'

// Misc
import { useSelector, useDispatch } from 'react-redux'

// Utils
import styled from 'styled-components/macro'
import { media } from 'utils'
import { useParams } from 'react-router-dom'

const DeliveryOptionsWrapper = styled.div`
  font-family: ${props => props.theme.font.copy};
  background: #fff;
  border: 0.0625rem solid ${props => props.theme.colors.borderColor};
  display: flex;
  flex-direction: column;
  padding: 1.5rem;
  margin: 0rem 0 0.75rem 0;
  position: relative;

  ${media.phone} {
    padding: 1rem;
    margin: 0.5rem 0 0.75rem 0;
    background: #f1f1f1;
    border: none;
  }
`

const DeliveryOptionSubtitle = styled.span`
  font-size: 0.75rem;
  margin-top: 0.375rem;
`
const DeliveryOptionTitle = styled.div``

const DeliveryOptionPlaceholder = styled.span`
  height: 7rem;
  text-align: center;
`

const DescriptionWrapper = styled.div`
  margin-top: 0.75rem;
  font-size: 0.75rem;
  color: ${props => props.theme.colors.copyColor};
  line-height: 1.25;
  display: none;

  ${media.tablet} {
    display: block;
    ${media.phone} {
      font-size: 0.625rem;
    }
  }
`

const DeliverOptionsContainer: React.FC = () => {
  const { eventId } = useParams<{ eventId: string }>()

  // Selectors
  const {
    selectIsDeliveryOptionLoading,
    selectDeliveryOptions
  } = deliveryOptionSelectors

  const isDeliveryOptionsLoading = useSelector(selectIsDeliveryOptionLoading)
  const deliveryOptions = useSelector(selectDeliveryOptions)
  const selectedDeliveryOptionId = useSelector(selectDeliveryOptionId)
  const selectedDelivery = deliveryOptions.find(
    deliveryOption => deliveryOption.id === selectedDeliveryOptionId
  )
  const reservationGuid = useSelector(selectReservationGuid)
  const isReserving = false

  // Actions
  const dispatch = useDispatch()

  // Delivery Option Change
  // ----------------------------------
  const handleDeliveryOptionChange = (deliveryOptionId: string) => {
    dispatch(
      updateReservation({
        transactionGuid: reservationGuid,
        basket: {
          delivery: { id: deliveryOptionId }
        },
        eventId
      })
    )
  }

  return (
    <DeliveryOptionsWrapper>
      <DeliveryOptionTitle>Ticket Delivery</DeliveryOptionTitle>
      <DeliveryOptionSubtitle>
        Please selected a delivery method
      </DeliveryOptionSubtitle>

      {isDeliveryOptionsLoading && (
        <DeliveryOptionPlaceholder>
          <Icon icon='circle-notch' isSpinning isCentre />
        </DeliveryOptionPlaceholder>
      )}

      {!isDeliveryOptionsLoading && (
        <>
          <SegmentedControl
            isReserving={isReserving}
            items={deliveryOptions}
            onSelectItem={handleDeliveryOptionChange}
            selectedItemId={selectedDeliveryOptionId}
          />

          {selectedDelivery?.description && (
            <DescriptionWrapper
              dangerouslySetInnerHTML={{
                __html: selectedDelivery?.description
              }}
            />
          )}
        </>
      )}
    </DeliveryOptionsWrapper>
  )
}

export { DeliverOptionsContainer }
