import React, { useState, useRef } from 'react'
import styled, { css } from 'styled-components/macro'
import { media, lighten, darken } from 'utils'
import {
  Button,
  Expander,
  CopyBlock,
  Input,
  Title,
  Icon
} from 'marvel-components'

import voucherLogo from 'assets/voucher_icon.png'

interface Props {
  onRedeemClick: (
    code: string,
    onSuccessCallback: () => void,
    onErrorCallback: (errorMessage: string) => void
  ) => void
  isSubmitting?: boolean
}

const VoucherWrapper = styled.div((props) => {
  const {
    theme: {
      font: { copy },
      colors: { borderColor }
    }
  } = props

  return css`
    font-family: ${copy};
    border: 1px solid ${borderColor};
    display: flex;
    flex-direction: column;
    margin: 0.75rem 0 0 0;
    align-items: flex-start;
    position: relative;

    ${media.phone} {
      margin: 0.75rem 5% 2rem 5%;
      position: relative;
      width: 90%;
    }
  `
})

const VoucherHeader = styled.div`
  box-sizing: border-box;
  width: 100%;
  cursor: pointer;
  user-select: none;
  padding: 1rem 1.5rem;

  ${media.phone} {
    padding: 5%;
  }
`

const VoucherTitle = styled(Title)`
  margin: 0.5rem;
  ${media.phone} {
    font-size: 0.875rem;
    text-align: left;
    margin-left: 0.75rem;
  }
`

const VoucherHeaderContent = styled.div`
  display: flex;
  align-items: center;
`

const VoucherContent = styled.div`
  padding: 0 1.5rem 1rem 1.5rem;

  ${media.phone} {
    padding: 0 1rem 1rem 1rem;
  }
`

const VoucherIcon = styled.img`
  width: 3rem;
`

const WarningLabel = styled.div((props) => {
  const {
    theme: {
      colors: { alertColor },
      font: { copy }
    }
  } = props

  return css`
    margin-top: 0.5rem;
    color: ${alertColor};
    font-size: 0.625rem;
    font-family: ${copy};
    word-wrap: break-word;
    width: 100%;
    display: flex;
  `
})

const InputWrapper = styled.div<{ isDisabled?: boolean }>((props) => {
  const {
    isDisabled,
    theme: {
      colors: { borderColor }
    }
  } = props

  return css`
    display: flex;
    position: relative;
    align-items: center;
    flex: 1 0 auto;
    border-radius: 0.125rem;
    border: 1px solid ${borderColor};
    background: #ffffff;
    margin-right: 1.25rem;

    ${media.phone} {
      margin-right: 0.5rem;
    }

    :focus-within {
      border: 0.0625rem solid ${darken(borderColor, 40)};
    }

    ${isDisabled &&
    css`
      border: 0.0625rem solid ${lighten(borderColor, 10)};
    `}
  `
})

const VoucherSubmitWrapper = styled.div`
  display: flex;
  flex: 1;
  margin-top: 1rem;
`
export const Voucher = (props: Props) => {
  const { onRedeemClick, isSubmitting = false } = props

  const [isExpanded, setIsExpanded] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')

  const voucherElement = useRef<HTMLInputElement>(null)

  const handleRedeemClick = () => {
    if (voucherElement.current && voucherElement.current.value !== '') {
      onRedeemClick(
        voucherElement.current.value,
        onSuccessCallback,
        onErrorCallback
      )
    }
  }

  const handleClearError = () => errorMessage !== '' && setErrorMessage('')

  const onSuccessCallback = () => {
    if (voucherElement.current) voucherElement.current.value = ''
  }

  const onErrorCallback = (errorMessage: string) => {
    setErrorMessage(errorMessage)
  }

  return (
    <VoucherWrapper>
      <VoucherHeader onClick={() => setIsExpanded(!isExpanded)}>
        <VoucherHeaderContent>
          <VoucherIcon src={voucherLogo} alt="voucher logo" />
          <VoucherTitle isCapitalised={false} isPadded={false} size="medium">
            Redeem LW Theatres Gift Vouchers
          </VoucherTitle>
          <Icon icon={isExpanded ? 'chevron-up' : 'chevron-down'} />
        </VoucherHeaderContent>
      </VoucherHeader>
      <Expander isExpanded={isExpanded}>
        <VoucherContent>
          <CopyBlock textSize="small">
            Enter your LW Theatres Gift Voucher number into the box below
          </CopyBlock>
          <VoucherSubmitWrapper>
            <InputWrapper>
              <Input
                ref={voucherElement}
                placeholder="Voucher Number"
                onChange={handleClearError}
              />
            </InputWrapper>
            <Button
              onClick={handleRedeemClick}
              isLoading={isSubmitting}
              text="Redeem"
            />
          </VoucherSubmitWrapper>
          {errorMessage !== '' && <WarningLabel>{errorMessage}</WarningLabel>}
        </VoucherContent>
      </Expander>
    </VoucherWrapper>
  )
}
