import React from 'react'

//Components
import { Button } from 'marvel-components'

// Assets
import HospitalityLogo from 'assets/hospitality_logo.png'

// Misc
import styled from 'styled-components'
import { media } from 'utils'
import { useHistory, useRouteMatch } from 'react-router-dom'

const HospitalityWrapper = styled.div`
  height: 100%;
  min-height: 100vh;
  width: 100%;
  background: #111111;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
`

const HospitalitySplashWrapper = styled.div`
  width: 400px;
  min-height: 90vh;
  height: 100%;
  margin: 3rem 0 0 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  ${media.phone} {
    width: 90%;
    justify-content: flex-start;
  }
`
const HospitalitySplashImage = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 0 0 0 0;
  ${media.phone} {
    width: 90%;
  }
`
const HospitalitySplashImageImage = styled.img`
  width: 400px;
  margin: 0 0 3rem 0;
  ${media.phone} {
    width: 100%;
  }
`
const HospitalityButton = styled(Button)`
  text-transform: none;
  font-family: ${props => props.theme.font.header};
  font-size: 1rem;
  fill: #fff;
  color: #fff;
  border-color: #fff;
`

const HospitalityFooter = styled.div`
  margin: 5rem 0 0 0;
  padding: 0 0 2rem 0;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  font-size: 0.85rem;
  ${media.phone} {
    width: 90%;
  }
`

const SummaryFooter = styled.div`
  font-family: ${props => props.theme.font.copy};
  margin-top: 2rem;
  color: ${props => props.theme.colors.borderColor};
`

const HomeContainer: React.FC = () => {
  const history = useHistory()
  const { url } = useRouteMatch()
  const handleOrderNowClick = (e: React.MouseEvent) => {
    e.preventDefault()
    history.push(`${url}/site`)
  }
  return (
    <HospitalityWrapper>
      <HospitalitySplashWrapper>
        <HospitalitySplashImage>
          <HospitalitySplashImageImage src={HospitalityLogo} />
          <HospitalityButton
            buttonType='light'
            isBlock
            onClick={handleOrderNowClick}
          >
            Order Now
          </HospitalityButton>
        </HospitalitySplashImage>
        <HospitalityFooter>
          {/* <HospitalityButton buttonType="light" isBlock>
            FAQ
          </HospitalityButton> */}
          <SummaryFooter>LW Theatres © 2021</SummaryFooter>
        </HospitalityFooter>
      </HospitalitySplashWrapper>
    </HospitalityWrapper>
  )
}

export { HomeContainer }
