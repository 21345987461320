// Components
import { ManageContactDetails } from 'marvel-components'
import { basketSelectors } from 'modules/basket'

// Misc
import { RefObject } from 'react'
import { useSelector } from 'react-redux'

interface Props {
  faqRef?: RefObject<HTMLSpanElement> | null
}
const ManageContactDetailsContainer = ({ faqRef }: Props) => {
  const customer = useSelector(basketSelectors.selectOrderCustomer)

  return (
    <ManageContactDetails
      name={`${customer.firstName ?? ''} ${customer.lastName ?? ''}`}
      email={customer.email ?? ''}
      faqRef={faqRef}
    />
  )
}

export { ManageContactDetailsContainer }
