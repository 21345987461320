import React from 'react'

// Components
import { Title, Icon } from 'marvel-components'

// Utils
import styled from 'styled-components/macro'
import { media } from 'utils'

const Message = styled.div`
  background: #d9edf7;
  border-radius: 0.2rem;
  font-family: ${(props) => props.theme.font.copy};
  padding: 1.5rem;
  margin: 2rem 0 1.5rem 0;
  font-size: 0.875rem;
  line-height: 1.5;
  ${media.phone} {
    padding: 1.5rem;
    margin: 1rem 1rem 1.5rem 1rem;
  }
`

const MessageHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 1rem;
`

const IconWrapper = styled.div`
  background: ${(props) => props.theme.colors.copyColor};
  border-radius: 50%;
  fill: #ffffff;
  width: 1.5rem;
  height: 1.5rem;
  line-height: 1.6rem; /* Note: Due to dodgy awesome font tick */
  text-align: center;
  font-size: 0.875rem;
  margin-left: 0.5rem;
`

type Props = {
  message?: string
  title: string
}
const Notification: React.FC<Props> = ({ message, title, ...props }) => (
  <>
    {message && message !== '' && (
      <Message {...props}>
        <MessageHeader>
          <Title
            isCapitalised={false}
            isCentralised={false}
            size="medium"
            isBold={true}
            isPadded={true}
          >
            {title}
          </Title>
          <IconWrapper>
            <Icon icon={'info'} />
          </IconWrapper>
        </MessageHeader>
        <span dangerouslySetInnerHTML={{ __html: message || '' }} />
      </Message>
    )}
  </>
)

export { Notification }
