import { createSlice } from '@reduxjs/toolkit'
import { eventCollectionAdapter } from './selectors'
import { fetchEventCollections } from './actions'

type AdditionalState = {
  status: 'idle' | 'pending' | 'fulfilled' | 'rejected'
}

const initialState = eventCollectionAdapter.getInitialState<AdditionalState>({
  status: 'idle'
})

const eventCollectionSlice = createSlice({
  name: 'eventCollection',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(fetchEventCollections.fulfilled, (state, action) => {
        eventCollectionAdapter.setAll(state, action.payload.eventCollections)
        state.status = 'fulfilled'
      })
      .addCase(fetchEventCollections.rejected, state => {
        eventCollectionAdapter.removeAll(state)
        state.status = 'rejected'
      })
      .addCase(fetchEventCollections.pending, state => {
        state.status = 'pending'
      })
  }
})

export const eventCollectionActions = {
  fetchEventCollections,
  ...eventCollectionSlice.actions
}

export default eventCollectionSlice.reducer
