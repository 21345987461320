import { useEffect, useCallback } from 'react'

// Selectors
import {
  selectBasketExpiry,
  selectReservationGuid
} from 'modules/basket/selectors'

// Misc
import { useDispatch, useSelector } from 'react-redux'
import moment from 'moment'

// Actions
import { clearReservation } from 'modules/basket/actions'

export const useReservationExpire = () => {
  const dispatch = useDispatch()

  //Selectors
  const expiresAt = useSelector(selectBasketExpiry)
  const countdownMs = moment(expiresAt).diff(moment(), 'ms')
  const reservationGuid = useSelector(selectReservationGuid)

  const stableCallback = useCallback(() => {
    if (reservationGuid) {
      dispatch(clearReservation(reservationGuid))
    }
  }, [reservationGuid, dispatch])

  useEffect(() => {
    const x = setInterval(stableCallback, countdownMs)
    return () => clearInterval(x)
  }, [stableCallback, countdownMs])
}
