import { Duration } from 'moment'
import { useCallback, useEffect } from 'react'

const useInterval = (
  callback: () => void,
  interval: Duration,
  dependencies: unknown[] = []
): void => {
  const stableCallback = useCallback(callback, [callback, ...dependencies])

  useEffect(() => {
    const x = setInterval(stableCallback, interval.asMilliseconds())
    return () => clearInterval(x)
  }, [stableCallback, interval])
}

export { useInterval }
