// Misc
import { createSlice, createEntityAdapter } from '@reduxjs/toolkit'

// Actions
import { venueActions } from 'modules/smart-waiter/venue'

// Types
import { ZImage } from 'shared-types'

export const imagesAdapter = createEntityAdapter<ZImage>()

const imagesSlice = createSlice({
  name: 'images',
  initialState: imagesAdapter.getInitialState({
    loading: 'idle'
  }),
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(venueActions.fetchVenues.pending, (state, action) => {
      state.loading = 'pending'
    })
    builder.addCase(venueActions.fetchVenues.fulfilled, (state, action) => {
      if (action.payload.images) {
        imagesAdapter.upsertMany(state, action.payload.images)
      }
      state.loading = 'idle'
    })
  }
})

export default imagesSlice.reducer
