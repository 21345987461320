import React, { ReactElement, ReactNode } from 'react'
import styled, { css, withTheme, DefaultTheme } from 'styled-components/macro'
import tinycolor from 'tinycolor2'

// Components
import { LWTheatreLogo } from 'marvel-components'

// Misc
import { media, darken, lighten, performanceRoute, usePlatform } from 'utils'

interface Props {
  theme: DefaultTheme
  address?: string
  venue?: string
  logoName?: string
  backgroundColor?: string
  lwLogoLink: string
  showLogoLink?: string
  eventName?: string
  renderNavButton?: () => ReactNode | ReactElement
  platform?: string

  // Auth
  loginButton?: ReactElement
}

const BrandLogoWrapper = styled.div`
  font-family: ${props => props.theme.font.copy};
  text-align: center;
  box-sizing: border-box;
  padding: 0;
  display: flex;
  flex-direction: column;
  ${media.tablet} {
    min-height: initial;
  }
`

const LogoDefaultWrapper = styled.a<{ platform?: string }>`
  width: ${props => (props.platform === 'kiosk' ? '7.5rem' : '12.5rem')};
  padding-left: ${props => (props.platform === 'kiosk' ? '7.5rem' : '8.5rem')};
  text-decoration: none;
  ${media.tablet} {
    padding-left: 33%;
    width: 20rem;
    max-width: 20rem;
    min-width: 20rem;
  }
  ${media.phone} {
    padding-left: 33%;
    width: 14rem;
    max-width: 14rem;
    min-width: 14rem;
    padding-top: 0.25rem;
  }
`

const ShowLogoWrapper = styled(LogoDefaultWrapper)`
  ${media.tablet} {
    padding-left: 40%;
    width: 12rem;
    max-width: 14rem;
    min-width: 14rem;
  }
  ${media.phone} {
    padding-left: 40%;
    width: 10rem;
    max-width: 10rem;
    min-width: 10rem;
  }
`

const LogoDefault = styled.div<{ platform?: string }>`
  background: inherit;
  padding: 0;
  font-family: ${props => props.theme.font.header};
  font-size: ${props => (props.platform === 'kiosk' ? '1rem' : '1.2rem')};
  ${media.tablet} {
    font-size: ${props => (props.platform === 'kiosk' ? '1.5rem' : '1.2rem')};
  }
`

const themedBackground = css`
  background: ${props => props.theme.themeBackgroundColour}
    url(${props => props.theme.themeBackgroundAsset});
  background-repeat: no-repeat;
  background-size: 625px;
  ${media.tablet} {
    background-size: 1081px;
    background-repeat: no-repeat;
    background: ${props => props.theme.themeBackgroundColour}
      url(${props => props.theme.themeBackgroundAsset});
  }
  ${media.phone} {
    background-size: 775px;
  }
`

const LogosWrapper = styled.div<{
  backgroundColor?: string
  includeNavSection?: boolean
}>(props => {
  const {
    theme: {
      colors: { mainBackgroundColor },
      themeBackgroundColour
    },
    backgroundColor = '#fff'
  } = props
  return css`
    display: flex;
    flex-direction: row;
    flex: 1 0 0;
    align-items: center;
    cursor: pointer;
    background: ${themeBackgroundColour ? themedBackground : backgroundColor};
    position: relative;
    min-height: 4.75rem;
    justify-content: flex-start;
    ${backgroundColor === '#fff' &&
    !props.includeNavSection &&
    css`
      border-right: 1px solid ${lighten(mainBackgroundColor, 50)};
    `}
    ${media.tablet} {
      border-right: none;
      min-height: 5.75rem;
    }
    ${media.phone} {
      min-height: 3.75rem;
    }
  `
})

const LWLogoWrapper = styled.a`
  position: absolute;
  width: 12rem;
  height: 100%;
  top: 0;
  left: 0;
  overflow: hidden;
  cursor: pointer;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  ::before {
    z-index: 0;
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    right: 80px;
    display: block;
    width: 400px;
    background: ${props => props.theme.colors.lwBrandColor};
    -webkit-transform: skew(25deg);
    -ms-transform: skew(25deg);
    transform: skew(25deg);
  }
  ${media.tablet} {
    padding: 0;
    width: 13rem;
  }
  ${media.phone} {
    padding: 0;
    width: 11rem;
  }
`

const LWLogoImageWrapper = styled.div`
  display: block;
  position: relative;
  left: 1rem;
  width: 3.75rem;
  ${media.tablet} {
    width: 4rem;
    left: 1rem;
  }
  ${media.phone} {
    width: 3rem;
    left: 1rem;
    top: 0;
  }
`

const LWLogo = styled(LWTheatreLogo)`
  width: 100%;
`

const Logo = styled.img`
  width: 100%;
`

const VenueLoginWrapper = styled.div<{ platform?: string }>`
  z-index: 1;
  display: flex;
  flex-direction: column;
  border-right: 1px solid
    ${props => darken(props.theme.colors.lwBrandColor, 20)};
  background-color: ${props => props.theme.colors.lwBrandColor};
  min-height: 3rem;
  ${media.tablet} {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    border-right: none;
    border-bottom: 1px solid
      ${props => darken(props.theme.colors.lwBrandColor, 20)};
    min-height: ${props => (props.platform === 'kiosk' ? '5rem' : '3rem')};
  }
`

const VenueWrapper = styled.div`
  display: flex;
  flex-direction: column;
  ${media.tablet} {
    align-items: flex-start;
    margin: 0 0 0 1rem;
  }
`

const VenueName = styled.span<{ platform?: string }>`
  color: ${props => props.theme.colors.lwBrandColorComplement};
  font-size: 0.75rem;
  margin-top: 0.5rem;
  font-family: ${props => props.theme.font.header};
  line-height: 1.5;
  ${media.tablet} {
    margin-top: 0;
    font-size: ${props => (props.platform === 'kiosk' ? '1rem' : '0.625rem')};
    font-family: ${props =>
      props.platform === 'kiosk'
        ? props.theme.font.header
        : props.theme.font.copy};
  }
`

const Address = styled.span<{ platform?: string }>`
  color: ${props => darken(props.theme.colors.lwBrandColorComplement, 20)};
  font-size: 0.625rem;
  padding: 0 0 0.5rem 0;
  line-height: 1.5;
  font-family: ${props => props.theme.font.copy};
  ${media.tablet} {
    padding: 0;
    font-size: ${props => (props.platform === 'kiosk' ? '0.9rem' : '0.625rem')};
  }
`

const LoginWrapper = styled.div`
  display: none;
  margin: 0.5rem 0;
  ${media.tablet} {
    display: block;
    margin: 0 1rem 0 0;
  }
`

const NavButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  z-index: 1;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  overflow: hidden;
  width: 115px;
  border-right: 1px solid
    ${props => darken(props.theme.colors.lwBrandColor, 20)};
  ${media.tablet} {
    border-right: none;
  }
  &:after {
    content: '';
    position: absolute;
    top: -140px;
    right: -155px;
    display: block;
    width: 270px;
    height: 270px;
    background: ${props => props.theme.colors.lwBrandColor};
    border-radius: 1000px;
  }
`

export const BrandLogo = withTheme((props: Props) => {
  const {
    address,
    venue,
    logoName,
    backgroundColor,
    lwLogoLink,
    showLogoLink = performanceRoute(),
    eventName,
    renderNavButton,

    // Auth
    loginButton,

    theme: {
      colors: { lwBrandColor }
    }
  } = props

  const logoUrl = `${process.env.PUBLIC_URL}/show_logos/${logoName}`
  const platform = usePlatform()
  return (
    <BrandLogoWrapper>
      <LogosWrapper
        backgroundColor={backgroundColor}
        includeNavSection={renderNavButton !== undefined}
      >
        <LWLogoWrapper href={lwLogoLink}>
          <LWLogoImageWrapper>
            <LWLogo isDark={tinycolor(lwBrandColor).isLight()} />
          </LWLogoImageWrapper>
        </LWLogoWrapper>
        {logoName && (
          <ShowLogoWrapper platform={platform} href={showLogoLink.toString()}>
            <Logo src={logoUrl} alt='brand-logo' />
          </ShowLogoWrapper>
        )}
        {!logoName && (
          <LogoDefaultWrapper
            platform={platform}
            href={showLogoLink.toString()}
          >
            <LogoDefault platform={platform}>{eventName}</LogoDefault>
          </LogoDefaultWrapper>
        )}
        {renderNavButton && (
          <NavButtonWrapper>{renderNavButton()}</NavButtonWrapper>
        )}
      </LogosWrapper>
      <VenueLoginWrapper platform={platform}>
        <VenueWrapper>
          {venue && <VenueName platform={platform}>{venue}</VenueName>}
          {address && <Address platform={platform}>{address}</Address>}
        </VenueWrapper>
        <LoginWrapper>{loginButton}</LoginWrapper>
      </VenueLoginWrapper>
    </BrandLogoWrapper>
  )
})
