import backgroundAsset from './assets/frozen.jpg'
export const Frozen = {
  colors: {
    mainBackgroundColor: '#171846',
    bestAvailablePerformanceColor: '#85cee4',
    bestAvailablePerformanceColorComplement: '#111111',
    mainColor: '#85cee4',
    fadedHighlightColor: '#CAE8F1',
    mainColorComplement: '#111111',
    lwBrandColor: '#ffffff',
    lwBrandColorComplement: '#111111',
    numberFilterSelectorColor: '#ffffff',
    numberFilterSelectorCopyColor: '#111111',
    performanceHoverColor: '#CAE8F1',
    gradientValueColor: '#85cee4',
    gradientPremiumColor: '#386d9c',
    gradientTextColor: '#ffffff',
    availabilityBarColor: '#CAE8F1'
  },
  themeBackgroundAsset: backgroundAsset,
  themeBackgroundColour: '#234f96',
  orderSuccess: {
    showSmartWaiter: false,
    showHospitality: false,
    showGarden: false,
    showCecilBeatonBar: false,
    showTours: false
  }
}
