import { connect } from 'react-redux'

// Modules
import { bannerActions, bannerSelectors } from 'modules/banner'
import { overlaySelectors } from 'modules/overlay'

// Types
import { AppState } from 'modules/types'

// Components
import { Banner } from 'marvel-components'

const mapStateToProps = (state: AppState) => {
  return {
    title: bannerSelectors.getBannerTitle(state),
    text: bannerSelectors.getBannerText(state),
    bannerType: bannerSelectors.getBannerType(state),
    isModalOpen: overlaySelectors.selectIsModalOpen(state)
  }
}

const mapDispatchToProps = {
  onCloseBanner: bannerActions.clearBannerContent
}

export const BannerContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(Banner)
