import React from 'react'
import styled from 'styled-components/macro'

// Selectors
import {
  selectActivePerformanceDate,
  selectAllPerformances
} from 'modules/ticketing/performance/selectors'
import {
  selectBasketLoading,
  selectAllItemCount
} from 'modules/basket/selectors'

// Components
import { DatePicker } from 'marvel-components'

// Utils
import { selectTicketsRoute } from 'utils'

// Misc
import { useSelector, useDispatch } from 'react-redux'
import { media } from 'utils'
import { useHistory } from 'react-router'
import { openModal } from 'modules/overlay/actions'

interface Props {
  eventId: string
  performanceId: string
}

const FilterWrapper = styled.div`
  margin: 0 0 0.5rem 0;
  ${media.tablet} {
    margin: 0;
  }
`

const PerformanceSelectContainer: React.FC<Props> = ({
  eventId,
  performanceId
}) => {
  const dispatch = useDispatch()

  // Selectors
  const history = useHistory()
  const performances = useSelector(selectAllPerformances)
  const isReserving = useSelector(selectBasketLoading)
  const basketItemCount = useSelector(selectAllItemCount)
  const selectedPerformanceDate = useSelector(selectActivePerformanceDate)
  const selectedDate = selectedPerformanceDate
    ? new Date(selectedPerformanceDate)
    : undefined
  // Actions
  const handlePerformanceClick = (newPerformanceId: string) => {
    if (basketItemCount > 0) {
      dispatch(
        openModal({
          variant: 'clear-basket',
          props: {
            eventId: eventId,
            performanceId: newPerformanceId
          }
        })
      )
    } else {
      history.push(selectTicketsRoute(eventId, newPerformanceId))
    }
  }
  return (
    <FilterWrapper>
      <DatePicker
        data={performances}
        selectedPerformanceId={performanceId}
        selectedPerformanceDate={selectedDate}
        onChangePerformance={handlePerformanceClick}
        isDisabled={isReserving}
      />
    </FilterWrapper>
  )
}

export { PerformanceSelectContainer }
