import { useLocation } from 'react-router-dom'

export type Module = 'smart-waiter' | 'hospitality' | 'ticketing'

const useModule = (): Module => {
  const location = useLocation()
  if (location.pathname.startsWith('/hospitality')) {
    return 'hospitality'
  } else if (location.pathname.startsWith('/smart-waiter')) {
    return 'smart-waiter'
  }
  return 'ticketing'
}

export default useModule
