import backgroundAsset from './assets/satse_sidebar2.jpg'
const Satse = {
  colors: {
    mainColor: '#ffffff',
    mainColorComplement: '#111111',
    performanceHoverColor: '#7edafb',
    numberFilterSelectorColor: '#ffffff',
    numberFilterSelectorCopyColor: '#111111',
    priceSliderHandleColor: '#00bcff',
    mainBackgroundColor: '#0194c9',
    lwBrandColor: '#ffffff',
    lwBrandColorComplement: '#111111',
    bestAvailablePerformanceColor: '#85cee4',
    bestAvailablePerformanceColorComplement: '#111111'
  },
  themeBackgroundColour: '#0194c9',
  themeBackgroundAsset: backgroundAsset,
  orderSuccess: {
    showHospitality: true,
    showCecilBeatonBar: true
  }
}

export { Satse }
