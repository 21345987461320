// Adapters
import { imagesAdapter } from './reducers'

// Types
import { AppState } from 'modules/types'

const imageSelectors = imagesAdapter.getSelectors<AppState>(
  (state) => state.smartWaiter.images
)

export { imageSelectors }
